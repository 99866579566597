import { createReducer } from 'redux-act';

import {
  invoiceDownloadFetchFail,
  invoiceDownloadFetchStart,
  invoiceDownloadFetchSuccess,
  invoiceFetchFail,
  invoiceFetchStart,
  invoiceFetchSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './invoice.actions';

export const INITIAL_STATE = {
  isLoading: false,
  error: null,
  data: null,
  isDownloading: false,
  downloadError: null,
};

const REDUCERS = {
  [invoiceFetchStart]: state =>
    Object.assign({}, state, {
      isLoading: true,
      error: null,
    }),
  [invoiceFetchSuccess]: (state, { data }) =>
    Object.assign({}, state, {
      isLoading: false,
      error: null,
      data: { ...state.data, ...data },
    }),
  [invoiceFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      isLoading: false,
      error: error || 'unknown error',
    }),
  [invoiceDownloadFetchStart]: state =>
    Object.assign({}, state, {
      isDownloading: true,
      downloadError: null,
    }),
  [invoiceDownloadFetchSuccess]: state =>
    Object.assign({}, state, {
      isDownloading: false,
      downloadError: null,
    }),
  [invoiceDownloadFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      isDownloading: false,
      downloadError: error || 'unknown error',
    }),
  // (-- APPEND REDUCER ACTION MAPPING HERE --) !!! do not move this comment !!!
};

export default createReducer(REDUCERS, INITIAL_STATE);
