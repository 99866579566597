import React, { PureComponent } from 'react';

import { FormattedMessage } from 'react-intl';

import { CircularLoader } from 'components/Loaders';

import { InnerText, LoaderWrap, PageLoadingWrap } from './Placeholders.styled';

class PageLoadingPlaceholder extends PureComponent {
  static defaultProps = {
    customText: <FormattedMessage id="general.loading.text" />,
    withText: true,
  };
  render() {
    const { customText, withText } = this.props;
    return (
      <PageLoadingWrap>
        <InnerText>
          <LoaderWrap>
            <CircularLoader />
          </LoaderWrap>
          {withText && customText}
        </InnerText>
      </PageLoadingWrap>
    );
  }
}

export default PageLoadingPlaceholder;
