import dayjs from 'dayjs';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import qs from 'query-string';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';

import {
  createClaimSubmission,
  fetchClaimSubmissionDamageCategories,
  fetchClaimSubmissionEmployees,
  fetchClaimSubmissionEmployment,
  fetchClaimSubmissionPartners,
  fetchClaimSubmissionPolicies,
  fetchClaimSubmissionReportingCategories,
  fetchClaimSubmissionTiles,
  updateClaimSubmission,
} from 'api/claimSubmission.api';
import { STEP_IDS, TYPES } from 'constants/claimSubmission';
import { analyticsService } from 'services';
import { addNotification } from 'store/notifications/notifications.actions';
import {
  policyIncapacityCausesFetchFail,
  policyIncapacityCausesFetchStart,
  policyIncapacityCausesFetchSuccess,
} from 'store/policy/policy.slice';
import { getPrevLocation } from 'store/routing/routing.selectors';

import { mapDataToTree } from 'utils/helpers';

import { resetUpload } from '../file/file.actions';
import {
  claimSubmissionCreateFail,
  claimSubmissionCreateStart,
  claimSubmissionCreateSuccess,
  claimSubmissionEmployeesFetchFail,
  claimSubmissionEmployeesFetchStart,
  claimSubmissionEmployeesFetchSuccess,
  claimSubmissionEmploymentFetchFail,
  claimSubmissionEmploymentFetchStart,
  claimSubmissionEmploymentFetchSuccess,
  claimSubmissionPartnersFetchFail,
  claimSubmissionPartnersFetchStart,
  claimSubmissionPartnersFetchSuccess,
  claimSubmissionPoliciesFetchFail,
  claimSubmissionPoliciesFetchStart,
  claimSubmissionPoliciesFetchSuccess,
  claimSubmissionSetSelectedPolicy,
  claimSubmissionTilesFetchFail,
  claimSubmissionTilesFetchStart,
  claimSubmissionTilesFetchSuccess,
  claimSubmissionUpdateFail,
  claimSubmissionUpdateStart,
  claimSubmissionUpdateSuccess,
  damageCategoriesFetchSuccess,
  goToNextStep,
  goToStep,
  reportingCategoriesFetchSuccess,
  resetStepper,
  setNewStep,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './claimSubmission.actions';
import {
  getClaimSubmissionEndpoint,
  getClaimSubmissionSummary,
  getClaimSubmissionTilesEndpoint,
  getDamageCategoriesEndpoint,
  getEmployeesEndpoint,
  getFlowStartTime,
} from './claimSubmission.selectors';

function* claimSubmissionTilesFetchFlow() {
  try {
    const path = yield select(getClaimSubmissionTilesEndpoint);
    const tiles = yield call(fetchClaimSubmissionTiles, path);
    const data = mapDataToTree(tiles.data, null, 1);
    yield put(claimSubmissionTilesFetchSuccess({ data }));
  } catch (error) {
    yield put(claimSubmissionTilesFetchFail({ error }));
  }
}

function* reportingCategoriesFetchFlow(action) {
  const { path } = action.payload;
  const reportingCategories = yield call(fetchClaimSubmissionReportingCategories, path);
  yield put(reportingCategoriesFetchSuccess({ data: reportingCategories.data }));
}

function* damageCategoriesFetchFlow(action) {
  const { segment } = action.payload;
  const path = yield select(getDamageCategoriesEndpoint);
  const params = {
    claimSegmentId: segment,
  };
  const damageCategories = yield call(fetchClaimSubmissionDamageCategories, path, params);
  yield put(damageCategoriesFetchSuccess({ data: damageCategories.data }));
}

function* updateClaimSubmissionFlow(action) {
  const { id, body, type, isEndOfFlow } = action.payload;
  try {
    const path = yield select(getClaimSubmissionEndpoint);
    yield call(updateClaimSubmission, `${path}/${id}`, body);
    if (isEndOfFlow) {
      analyticsService.events.trackClaimsFlowSubmitSuccess(type);
      const startTime = yield select(getFlowStartTime);
      analyticsService.events.trackClaimsFlowSubmissionTime(
        (new Date().getTime() - startTime) / 1000 / 60,
      );
    }

    yield put(claimSubmissionUpdateSuccess({ isEndOfFlow }));
  } catch (error) {
    if (isEndOfFlow) {
      analyticsService.events.trackClaimsFlowSubmitError(type);
    }
    yield put(claimSubmissionUpdateFail({ error }));
  }
}

function* claimSubmissionPartnersFetchFlow(action) {
  try {
    const { path } = action.payload;
    const partners = yield call(fetchClaimSubmissionPartners, path);
    yield put(claimSubmissionPartnersFetchSuccess({ data: partners.data }));
  } catch (error) {
    yield put(claimSubmissionPartnersFetchFail({ error }));
  }
}

function* claimSubmissionPoliciesFetchFlow(action) {
  try {
    const { path } = action.payload;
    const policies = yield call(fetchClaimSubmissionPolicies, path);
    yield put(claimSubmissionPoliciesFetchSuccess({ data: policies.data, meta: policies.meta }));
  } catch (error) {
    yield put(claimSubmissionPoliciesFetchFail({ error }));
  }
}

function* claimSubmissionCreateFlow(action) {
  yield put(claimSubmissionCreateStart());

  const { body, subType, reportingCategoriesPath, summary, segment, employeePath } = action.payload;
  try {
    if (reportingCategoriesPath && subType !== TYPES.WORK_INCAPACITY) {
      yield call(reportingCategoriesFetchFlow, {
        payload: { path: reportingCategoriesPath },
      });
    }

    if (subType === TYPES.BUILDINGS) {
      yield call(damageCategoriesFetchFlow, { payload: { segment } });
    }
    const path = yield select(getClaimSubmissionEndpoint);
    const submission = yield call(createClaimSubmission, path, body);
    yield put(claimSubmissionCreateSuccess({ data: submission.data, summary, employeePath }));
    return true;
  } catch (error) {
    analyticsService.events.trackClaimsFlowSubmitError(subType);
    yield put(claimSubmissionCreateFail({ error }));
    return false;
  }
}

function* goToStepFlow(action) {
  const { stepId } = action.payload;
  const index = Object.values(STEP_IDS).indexOf(stepId);
  const newCompleted = Object.values(STEP_IDS).slice(0, index + 1);
  yield put(setNewStep({ stepId, newCompleted }));
}

function* claimSubmissionEmployeesFetchFlow(action) {
  try {
    const { params } = action.payload;
    const employeesEndpoint = yield select(getEmployeesEndpoint);
    const endpointParsed = qs.parseUrl(employeesEndpoint);
    const { data, meta } = yield call(fetchClaimSubmissionEmployees, endpointParsed.url, {
      ...endpointParsed.query,
      ...params,
    });
    yield put(claimSubmissionEmployeesFetchSuccess({ data, meta }));
  } catch (error) {
    yield put(claimSubmissionEmployeesFetchFail({ error }));
  }
}

function* employmentFetchFlow(action) {
  yield put(claimSubmissionEmploymentFetchStart());
  const { endpoint, params, infoDate } = action.payload;

  try {
    const { data } = yield call(fetchClaimSubmissionEmployment, endpoint, params);
    yield put(claimSubmissionEmploymentFetchSuccess({ data, infoDate }));
    return true;
  } catch (error) {
    yield put(claimSubmissionEmploymentFetchFail({ error }));
    yield put(
      addNotification({
        variant: 'error',
        message: 'general.error.update.text',
      }),
    );
    return false;
  }
}

const prepareMainFlow = values => {
  const valuesNeeded = _pick(values, [
    `${STEP_IDS.STEP_POLICY}.relationships`,
    `${STEP_IDS.STEP_PARTNER}.id`,
    `${STEP_IDS.STEP_POLICY}.id`,
  ]);
  const formBody = {
    data: {
      attributes: {
        status: 'DRAFT',
        claimDomainIds: _get(values[STEP_IDS.STEP_RISK_SPECIFY], 'attributes.claimDomainIds'),
        claimSegmentIds: _get(values[STEP_IDS.STEP_RISK_SPECIFY], 'attributes.claimSegmentIds'),
      },
      relationships: {
        policyHolder: {
          data: {
            id: valuesNeeded[STEP_IDS.STEP_PARTNER].id,
            type: 'client',
          },
        },
        policy:
          valuesNeeded[STEP_IDS.STEP_POLICY].id > 0
            ? {
                data: {
                  id: valuesNeeded[STEP_IDS.STEP_POLICY].id,
                  type: 'policy',
                },
              }
            : null,
      },
    },
  };
  const reportingCategoriesPath = _get(values, `${STEP_IDS.STEP_POLICY}.links.reportingCategories`);
  const employeePath = _get(values, `${STEP_IDS.STEP_POLICY}.links.clientEmployees`);
  const type = _get(values, `${STEP_IDS.STEP_RISK}.attributes.key`, '');
  const subType = _get(values, `${STEP_IDS.STEP_RISK_SPECIFY}.attributes.key`);
  const summary = {
    risk: _get(values, `${STEP_IDS.STEP_RISK}.attributes.title`),
    riskSubType: _get(values, `${STEP_IDS.STEP_RISK_SPECIFY}.attributes.title`),
    partner: _get(values, `${STEP_IDS.STEP_PARTNER}.attributes.name`),
    policy: _get(values, `${STEP_IDS.STEP_POLICY}.attributes.policyNumber`),
    type,
    subType,
  };

  const payload = {
    payload: {
      body: formBody,
      subType,
      reportingCategoriesPath,
      summary,
      segment: _get(values, `${STEP_IDS.STEP_POLICY}.relationships.claimSegment.data.id`),
      employeePath,
    },
  };
  analyticsService.events.trackClaimsFlowStart(subType);
  return payload;
};

function* goToNextStepFlow(action) {
  const { nextStep, values } = action.payload;
  const index = Object.values(STEP_IDS).indexOf(nextStep);
  let canContinue = true;
  if (nextStep === STEP_IDS.STEP_PARTNER) {
    const partnersPath = _get(values[STEP_IDS.STEP_RISK_SPECIFY], 'links.clients');
    yield put(claimSubmissionPartnersFetchStart({ path: partnersPath }));
  }
  if (nextStep === STEP_IDS.STEP_POLICY) {
    const policiesPath = _get(values[STEP_IDS.STEP_PARTNER], 'links.policies');
    yield put(claimSubmissionPoliciesFetchStart({ path: policiesPath }));
  }
  if (nextStep === STEP_IDS.STEP_SUBJECT || nextStep === STEP_IDS.STEP_TPA_GI_EMPLOYEE) {
    yield put(claimSubmissionSetSelectedPolicy({ policy: values[STEP_IDS.STEP_POLICY] }));
    const payload = prepareMainFlow(values);
    canContinue = yield call(claimSubmissionCreateFlow, payload);
  }

  if (nextStep === STEP_IDS.STEP_TPA_GI_INCAPACITY) {
    yield put(
      policyIncapacityCausesFetchStart({
        id: _get(values, 'stepEmployee.employee.selectedItem.relationships.relatedPolicy.data.id'),
      }),
    );
    const { success, fail } = yield race({
      success: take(policyIncapacityCausesFetchSuccess),
      fail: take(policyIncapacityCausesFetchFail),
    });
    if (success) {
      canContinue = true;
    }
    if (fail) {
      canContinue = false;
    }
  }

  if (nextStep === STEP_IDS.STEP_TPA_GI_EMPLOYEE_DATA) {
    const employmentEndpoint = _get(values, 'stepEmployee.employee.selectedItem.links.employment');
    const firstPeriod = _get(values, 'stepIncapacity.periods', [])
      .concat() // avoid mutation
      .sort((date1, date2) => (dayjs(date1.startDate).isAfter(dayjs(date2.startDate)) ? 1 : -1))[0];

    canContinue = yield call(employmentFetchFlow, {
      payload: {
        infoDate: firstPeriod.startDate,
        endpoint: employmentEndpoint,
        params: {
          policyId: _get(
            values,
            'stepEmployee.employee.selectedItem.relationships.relatedPolicy.data.id',
          ),
          incapacityStartDate: firstPeriod.startDate,
          incapacityCauseId: _get(values, 'stepIncapacity.incapacityCause.value'),
        },
      },
    });
  }

  if (nextStep === STEP_IDS.STEP_SUBMIT) {
    const summary = yield select(getClaimSubmissionSummary);
    yield put(
      claimSubmissionUpdateStart({
        type: summary.type,
        id: _get(values, 'id'),
        body: { data: values },
        isEndOfFlow: true,
      }),
    );
  }

  if (canContinue) {
    yield put(
      setNewStep({ stepId: nextStep, newCompleted: Object.values(STEP_IDS).slice(0, index) }),
    );
  }
}

// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

export function* bootClaimSubmission() {
  let prevLocation = yield select(getPrevLocation);
  prevLocation = prevLocation.type || 'DIRECT_LINK';
  analyticsService.events.trackClaimsFlowEntryPoint(prevLocation);

  yield put(resetStepper());
  yield put(resetUpload());
  yield put(claimSubmissionTilesFetchStart());
}

function* claimSubmissionSaga() {
  yield takeLatest(claimSubmissionTilesFetchStart.getType(), claimSubmissionTilesFetchFlow);
  yield takeLatest(claimSubmissionUpdateStart.getType(), updateClaimSubmissionFlow);
  yield takeLatest(claimSubmissionPartnersFetchStart.getType(), claimSubmissionPartnersFetchFlow);
  yield takeLatest(claimSubmissionPoliciesFetchStart.getType(), claimSubmissionPoliciesFetchFlow);
  yield takeLatest(claimSubmissionEmployeesFetchStart.getType(), claimSubmissionEmployeesFetchFlow);
  yield takeLatest(goToStep.getType(), goToStepFlow);
  yield takeLatest(goToNextStep.getType(), goToNextStepFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default claimSubmissionSaga;
