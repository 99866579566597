import React, { useContext } from 'react';

import { BoxProps } from '@material-ui/core';

import { tabContext } from './TabContext';
import { Wrap } from './TabPanel.styled';

export interface Props {
  value: string;
}

const TabPanel: React.FC<Props & BoxProps> = ({ children, value, borderRadius = 8, ...rest }) => {
  const { activeId, hasMounted } = useContext(tabContext);
  return (
    <>
      {hasMounted.includes(value) && (
        <Wrap
          {...rest}
          borderRadius={borderRadius}
          style={{ display: value === activeId ? 'flex' : 'none' }}
        >
          {children}
        </Wrap>
      )}
    </>
  );
};

export default TabPanel;
