import { call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchRisk, fetchRiskDetails } from 'api/risk.api';
import { getPathParam } from 'store/routing/routing.selectors';

import { getDetailLink, getRiskEndpoint } from './risk.selectors';
import {
  riskDetailsFetchFail,
  riskDetailsFetchStart,
  riskDetailsFetchSuccess,
  riskFetchFail,
  riskFetchStart,
  riskFetchSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './risk.slice';
import { RiskDetailsResponse, RiskResponse } from './risk.types';

function* riskDetailsFetchFlow(action: ReturnType<typeof riskDetailsFetchStart>) {
  const id = action.payload;
  try {
    const detailsPath = yield select(getDetailLink, id);
    const details: RiskDetailsResponse = yield call(fetchRiskDetails, detailsPath);

    yield put(riskDetailsFetchSuccess({ data: details.data, id }));
  } catch (error) {
    yield put(riskDetailsFetchFail({ error }));
  }
}

function* riskFetchFlow(action: ReturnType<typeof riskFetchStart>) {
  const id = action.payload;
  try {
    const path = yield select(getRiskEndpoint);
    const risk: RiskResponse = yield call(fetchRisk, path, id);

    yield put(
      riskFetchSuccess({
        data: {
          [id]: { ...risk.data },
        },
        id,
      }),
    );
  } catch (error) {
    yield put(riskFetchFail({ error }));
  } finally {
    yield put(riskDetailsFetchStart(id));
  }
}
// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

export function* bootRiskDetail() {
  const riskId = yield select(getPathParam, 'riskId');
  yield put(riskFetchStart(riskId));
}

function* riskSaga() {
  yield takeLatest(riskFetchStart, riskFetchFlow);
  yield takeLatest(riskDetailsFetchStart, riskDetailsFetchFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default riskSaga;
