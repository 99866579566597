import styled from 'styled-components';

export const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;
`;

export const CheckboxLabel = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export default null;
