import styled from 'styled-components';

export const Counter = styled.div`
  position: absolute;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacing(6)}px;
  height: ${({ theme }) => theme.spacing(6)}px;
  background: ${({ theme }) => theme.palette.neutral01[300]};
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  font-weight: 700;
`;
