import { Ability } from '@casl/ability';

import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { Store } from 'types/store/store.types';

import { UserResponse } from './user.types';

// CURRENT USER
export const getUser = (state: Store) => state.user.data;
export const getUserIsLoading = (state: Store) => state.user.status === 'loading';
export const getUserIsUpdating = (state: Store) => state.user.status === 'updating';
export const getUserError = (state: Store) => state.user.error;
export const getUserModules = (state: Store) => state.user.data?.attributes?.modules;
// CURRENT USER PERMISSIONS
export const getUserAbilities = (state: Store) => state.user.abilities ?? new Ability();

// TUTORIALS
export const getUserTutorials = (state: Store) => state.user.tutorials.data;

// USER BOOKMARKS
export const getUserBookmarks = (state: Store) => state.user.bookmarks.data;
export const getUserBookmarksIsLoading = (state: Store) => state.user.bookmarks.isLoading;
export const getUserBookmarksError = (state: Store) => state.user.bookmarks.error;

// USER UPDATES
export const getUserUpdates = (state: Store) => state.user.updates.data;
export const getUserUpdatesLinks = (state: Store) => state.user.updates.links;
export const getUserUpdatesMeta = (state: Store) => state.user.updates.meta;
export const getUserUpdatesIsLoading = (state: Store) => state.user.updates.isLoading;
export const getUserUpdatesError = (state: Store) => state.user.updates.error;

// USER STATS
export const getUserStats = (state: Store) => state.user.stats?.data;
export const getUserVideoState = (state: Store) => state.user.video;

// USER FAVORITE PARTNERS
export const getUserFavoritePartners = (state: Store) => state.user.favoritePartners.data;
export const getUserFavoritePartnersIdArray = (state: Store) =>
  state.user.favoritePartners.data.reduce((acc: string[], partner) => {
    const id = partner.id;
    if (id) {
      acc.push(id);
    }
    return acc;
  }, []);

export const getUserFavoritePartnersIsLoading = (state: Store) =>
  state.user.favoritePartners.isLoading;
export const getUserFavoritePartnersError = (state: Store) => state.user.favoritePartners.error;

// ENDPOINTS
export const getUserEndpoint = (state: Store) => getEndpoint(state, 'user');

export const getUserAvailabilityEndpoint = (state: Store) =>
  state.endpoints.private.data?.relationships.users.links.availability ?? '';
export const getUserTutorialsEndpoint = (user: UserResponse) => user.data.links.tutorials ?? '';
export const getUserBookmarksEndpoint = (state: Store) => state.user.data?.links?.bookmarks ?? '';
export const getUserStatsEndpoint = (state: Store) => state.user.data?.links?.stats ?? '';
export const getUserUpdatesEndpoint = (state: Store) => state.user.data?.links?.updates ?? '';
export const getUserFavoritePartnersEndpoint = (state: Store) =>
  state.user?.data?.links?.favoritePartners;
