import { createStyles, withStyles } from '@material-ui/core';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

export const Checkbox = withStyles(theme =>
  createStyles({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: theme.palette.neutral01[50],
      zIndex: -1,
      borderRadius: theme.shape.borderRadius,
      border: '2px solid',
      borderColor: theme.palette.neutral01[300],
    },
    checked: {
      background: theme.palette.accent01[100],
      borderColor: theme.palette.brand01[300],
    },
  }),
)(MuiCheckbox);

export const FormControlLabel = withStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      marginLeft: 0,
      marginRight: 0,
      padding: theme.spacing(2, 3),
      border: '2px solid',
      background: theme.palette.neutral01[50],
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.neutral01[300],
      '& * ': {
        fill: theme.palette.neutral01[400],
      },
      '&.checked': {
        background: theme.palette.brand01[800],
        borderColor: theme.palette.brand01[300],
        '& * ': {
          fill: theme.palette.brand01[300],
          stroke: theme.palette.brand01[300],
        },

        '& .MuiFormControlLabel-label, .MuiTypography-h5, .MuiTypography-subtitle2': {
          color: theme.palette.brand01[300],
        },
      },
    },
  }),
)(MuiFormControlLabel);
