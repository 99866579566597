import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import {
  Document,
  DocumentsDownloadFailPayload,
  DocumentsDownloadPayload,
  DocumentsDownloadSuccessPayload,
  DocumentsFetchStartPayload,
  DocumentsFetchSuccessPayload,
  DocumentsResponse,
} from './document.types';

export interface DocumentState {
  isLoading: boolean;
  isDownloading: boolean;
  downloadError: StoreDefaultState['error'];
  error: StoreDefaultState['error'];
  data?: { [index: string]: Document };
  meta?: DocumentsResponse['meta'];
  result: string[];
  recentResult: string[];
  documentsDownloading: string[];
}

const INITIAL_STATE: DocumentState = {
  isLoading: false,
  isDownloading: false,
  downloadError: undefined,
  error: undefined,
  data: undefined,
  result: [],
  recentResult: [],
  documentsDownloading: [],
};

const documentsSlice = createSlice({
  name: 'document',
  initialState: INITIAL_STATE,
  reducers: {
    documentsFetchStart: (state, _action: PayloadAction<DocumentsFetchStartPayload>) => {
      state.isLoading = true;
      state.error = undefined;
    },
    documentsFetchSuccess: (state, { payload }: PayloadAction<DocumentsFetchSuccessPayload>) => {
      state.data = { ...state.data, ...payload.data };
      if (payload.isRecent) {
        state.recentResult = payload.result;
      } else {
        state.result = payload.result;
      }
      state.isLoading = false;
      state.meta = payload.meta;
    },
    documentsFetchFail: (state, { payload }: PayloadAction<{ error: DocumentState['error'] }>) => {
      state.error = payload.error;
      state.isLoading = false;
    },
    documentDownloadFetchStart: (state, { payload }: PayloadAction<DocumentsDownloadPayload>) => {
      state.isDownloading = true;
      state.downloadError = undefined;
      payload.id && state.documentsDownloading.push(payload.id);
    },
    documentDownloadFetchSuccess: (
      state,
      { payload }: PayloadAction<DocumentsDownloadSuccessPayload>,
    ) => {
      state.isDownloading = false;
      state.downloadError = undefined;
      state.documentsDownloading = state.documentsDownloading.filter(id => id !== payload.id);
    },
    documentDownloadFetchFail: (
      state,
      { payload }: PayloadAction<DocumentsDownloadFailPayload>,
    ) => {
      state.isDownloading = false;
      state.downloadError = payload.error;
      state.documentsDownloading = state.documentsDownloading.filter(id => id !== payload.id);
    },
  },
});

export const {
  documentsFetchStart,
  documentsFetchSuccess,
  documentsFetchFail,
  documentDownloadFetchStart,
  documentDownloadFetchSuccess,
  documentDownloadFetchFail,
} = documentsSlice.actions;

export default documentsSlice.reducer;
