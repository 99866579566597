import React from 'react';

import { Box } from '@material-ui/core';

import FormatNumber from 'components/FormatNumber';
import { SummaryField, SummaryTitle } from 'components/FormSummary';

import { StepperState } from '../../AffiliationsCreateStepper';

interface Props {
  onEditClick: () => void;
  wageType?: string;
}

const AdditionalInfoSummary: React.FC<Props & StepperState['stepAdditionalInfo']> = ({
  onEditClick,
  ...values
}) => {
  if (!values.requiresIdentificationNumber && !values.requiresSalaryData) {
    return null;
  }
  return (
    <Box mb={8}>
      <SummaryTitle title="affiliations.add.step.additional_info.title" onEditClick={onEditClick} />
      {values.requiresIdentificationNumber && (
        <SummaryField
          label="claims.add.step.tpa_gi_employee.ssn.label"
          value={
            values.identificationNumber && (
              <FormatNumber number={values.identificationNumber} format="##.##.##-###.##" />
            )
          }
        />
      )}
      {values.requiresSalaryData && (
        <>
          <SummaryField
            label="affiliations.add.step.additional_info.employment_percentage_label"
            value={
              values.employmentPercentage && (
                <>
                  <FormatNumber isNumericString number={`${values.employmentPercentage}`} />%
                </>
              )
            }
          />
          <SummaryField
            label={
              values.wageType === 'MONTHLY'
                ? 'general.gross_wage.text'
                : 'general.gross_wage_hourly.text'
            }
            value={
              values.grossSalary && (
                <>
                  €&nbsp;
                  <FormatNumber
                    isNumericString
                    number={values.grossSalary}
                    decimalScale={values.wageType === 'MONTHLY' ? 2 : 4}
                  />
                </>
              )
            }
          />
          <SummaryField
            label="claims.tpa_gi_employment_condition.label"
            value={values.employmentCondition?.label}
          />
        </>
      )}
    </Box>
  );
};

export default AdditionalInfoSummary;
