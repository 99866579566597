import React from 'react';

import dayjs from 'dayjs';
import { useField } from 'formik';
import { useIntl } from 'react-intl';

import { getFieldErrors } from 'utils/form';

import Field, { FieldProps } from 'components/@inputs/Field';

import DatePickerInput, {
  DatePickerInputProps,
  DatePickerInputSelectedDate,
} from './DatePickerInput';

export type DatePickerInputFieldProps = Omit<Partial<DatePickerInputProps>, 'error'> & FieldProps;

const DatePickerInputField: React.FC<DatePickerInputFieldProps> = ({
  name,
  label,
  bottomLabel,
  placeholder,
  disabled,
  fullWidth,
  isRequired,
  isRangeSelect,
  isFillable = !isRangeSelect,
  inputWidth,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const intl = useIntl();

  const handleChange = (date: DatePickerInputSelectedDate) => {
    helpers.setTouched(true);
    if (!isRangeSelect) {
      helpers.setValue(date.from ? dayjs(date.from).format('YYYY-MM-DD') : null);
    }
    if (isRangeSelect) {
      helpers.setValue({
        from: date.from ? dayjs(date.from).format('YYYY-MM-DD') : undefined,
        to: date.to ? dayjs(date.to).format('YYYY-MM-DD') : undefined,
      });
    }
  };

  const getDate = () => {
    const from = isRangeSelect ? field.value?.from : field.value;
    const to = isRangeSelect ? field.value?.to : field.value;
    return {
      from: from && dayjs(from).isValid() ? dayjs(from).format('YYYY-MM-DD') : null,
      to: to && dayjs(to).isValid() ? dayjs(to).format('YYYY-MM-DD') : null,
    };
  };

  return (
    <Field
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
      errors={getFieldErrors(name, meta)}
      inputWidth={inputWidth}
    >
      <DatePickerInput
        id={name}
        disabled={disabled}
        label={label}
        isRequired={isRequired}
        bottomLabel={bottomLabel}
        placeholder={placeholder && intl.formatMessage({ id: placeholder })}
        isRangeSelect={isRangeSelect}
        isFillable={isFillable}
        {...field}
        {...rest}
        name={name}
        value={getDate()}
        onDayClick={handleChange}
      />
    </Field>
  );
};

export default DatePickerInputField;
