import React, { memo } from 'react';

import usePartnerChildren from 'hooks/usePartners/usePartnerChildren';
import _get from 'lodash/get';

import TagList from 'components/TagList';

export interface Props {
  onPartnerChildClick?: (id: string) => void;
  currentId: string;
  groupId?: string;
}

const PartnerChildren: React.FC<Props> = ({
  groupId,
  currentId,
  onPartnerChildClick = () => {},
}) => {
  const { data: response, isLoading } = usePartnerChildren(currentId, groupId);
  return (
    <TagList
      isLoading={isLoading}
      emptyPlaceholder="-"
      onTagClick={onPartnerChildClick}
      tags={response?.data?.reduce((acc: any, partner) => {
        if (partner.id !== currentId) {
          acc.push({ id: partner.id, value: _get(partner, 'attributes.name') });
        }
        return acc;
      }, [])}
    />
  );
};

export default memo(PartnerChildren);
