import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import {
  InsurantData,
  InsurantsFetchStartPayload,
  InsurantsFetchSuccessPayload,
  InsurantsResponse,
} from './affiliations.types';

export const insurantsAdapter = createEntityAdapter<InsurantData>();

export type AffiliationsState = ReturnType<typeof insurantsAdapter.getInitialState> & {
  meta?: InsurantsResponse['meta'];
  links?: InsurantsResponse['links'];
  status?: 'loading' | 'editing' | 'error';
  result: string[];
  error: StoreDefaultState['error'];
};

export const INITIAL_STATE: AffiliationsState = insurantsAdapter.getInitialState({
  meta: undefined,
  status: undefined,
  error: undefined,
  links: undefined,
  result: [],
});

const affiliationsSlice = createSlice({
  name: 'affiliations',
  initialState: INITIAL_STATE,
  reducers: {
    setInsurants: (state, { payload }: PayloadAction<InsurantsFetchSuccessPayload['data']>) => {
      insurantsAdapter.upsertMany(state, payload);
    },
    insurantsFetchStart: (state, _action: PayloadAction<InsurantsFetchStartPayload>) => {
      state.status = 'loading';
      state.error = undefined;
    },
    insurantsFetchSuccess: (state, { payload }: PayloadAction<InsurantsFetchSuccessPayload>) => {
      insurantsAdapter.upsertMany(state, payload.data);
      state.result = payload.result;
      state.meta = payload.meta;
      state.links = payload.links;
      state.status = undefined;
      state.error = undefined;
    },
    insurantsFetchFail: (
      state,
      { payload }: PayloadAction<{ error: AffiliationsState['error'] }>,
    ) => {
      state.status = undefined;
      state.error = payload.error;
    },
  },
});

export const {
  insurantsFetchStart,
  insurantsFetchSuccess,
  insurantsFetchFail,
  setInsurants,
} = affiliationsSlice.actions;

export default affiliationsSlice.reducer;
