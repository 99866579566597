import React from 'react';

import { Box, Grid } from '@material-ui/core';
import dayjs from 'dayjs';
import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import Button from 'components/@common/Button';
import { FieldArrayWrap } from 'components/@inputs';
import { DatePickerInputField } from 'components/@inputs/DatePicker';
import NumberField from 'components/@inputs/Number/NumberField';
import WorkIncapacityItem from 'components/WorkIncapacityItem/WorkIncapacityItem';

import { FormValue } from './IncapacityPeriodsEdit';

interface Props {
  index: number;
  period: FormValue;
  isEditing: boolean;
  canEdit: boolean;
  isValid: boolean;
  onSave: () => void;
  onEdit: () => void;
}

const WorkIncapacityForm: React.FC<Props> = ({
  index,
  isEditing,
  onSave,
  onEdit,
  period,
  isValid,
  canEdit,
}) => {
  if (!isEditing) {
    return (
      <WorkIncapacityItem
        startDate={_get(period, 'attributes.startDate')}
        endDate={_get(period, 'attributes.endDate')}
        percentage={_get(period, 'attributes.percentage') || 0}
        onEdit={onEdit}
        canDelete={false}
        editDisabled={!canEdit}
      />
    );
  }
  return (
    <FieldArrayWrap>
      <Box mb={8} width="50%">
        <NumberField
          name={`periods[${index}].attributes.percentage`}
          fieldType="percentage"
          placeholder="general.percentage_input.placeholder"
          fullWidth
          isRequired
          label="claims.add.step.tpa_gi_incapacity.period_percentage.label"
          bottomLabel="claims.add.step.tpa_gi_incapacity.period_percentage.bottom_label"
          startAdornment="%"
        />
      </Box>
      <Box marginBottom={8}>
        <Grid container spacing={8}>
          <Grid item sm={12} md={6}>
            <DatePickerInputField
              name={`periods[${index}].attributes.startDate`}
              placeholder="general.datepicker_input.placeholder"
              label="claims.add.step.tpa_gi_incapacity.start_date.label"
              isFillable
              fullWidth
              isRequired
              backdropProps={{
                zIndex: 1499,
              }}
              disableAfter={
                _get(period, 'attributes.endDate')
                  ? dayjs(_get(period, 'attributes.endDate'))
                      .subtract(1, 'day')
                      .toDate()
                  : undefined
              }
              isRangeSelect={false}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <DatePickerInputField
              isFillable
              name={`periods[${index}].attributes.endDate`}
              label="claims.add.step.tpa_gi_incapacity.end_date.label"
              placeholder="general.datepicker_input.placeholder"
              fullWidth
              isRequired
              backdropProps={{
                zIndex: 1499,
              }}
              disableBefore={
                _get(period, 'attributes.startDate')
                  ? dayjs(_get(period, 'attributes.startDate'))
                      .add(1, 'day')
                      .toDate()
                  : undefined
              }
              currentMonthViewed={
                _get(period, 'attributes.startDate') &&
                dayjs(_get(period, 'attributes.startDate')).toDate()
              }
              isRangeSelect={false}
            />
          </Grid>
        </Grid>
      </Box>
      <Button variation="secondary" disabled={!isValid} onClick={onSave}>
        <FormattedMessage id="claims.add.step.tpa_gi_incapacity.add_period.text" />
      </Button>
    </FieldArrayWrap>
  );
};

export default WorkIncapacityForm;
