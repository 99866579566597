import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import {
  RiskCategory,
  Vehicle,
  VehicleCertificate,
  VehicleCertificatesSearchFailPayload,
  VehicleCertificatesSearchPayload,
  VehicleCertificatesSearchSuccessPayload,
} from './vehicleCertificates.types';

export interface VehicleCertificatesState {
  isLoading?: boolean;
  error?: StoreDefaultState['error'];
  data?: { [id: string]: VehicleCertificate };
  result: string[];
  vehicles: {
    data?: { [id: string]: Vehicle };
    riskCategories: { [id: string]: RiskCategory };
    result: string[];
  };
}

const INITIAL_STATE: VehicleCertificatesState = {
  isLoading: undefined,
  error: undefined,
  data: {},
  result: [],
  vehicles: {
    data: {},
    result: [],
    riskCategories: {},
  },
};

const vehicleCertificates = createSlice({
  name: 'vehicleCertificates',
  initialState: INITIAL_STATE,
  reducers: {
    searchStart: (state, _action: PayloadAction<VehicleCertificatesSearchPayload>) => {
      state.isLoading = true;
      state.error = undefined;
      state.result = [];
      state.vehicles.result = [];
    },
    searchSuccess: (state, { payload }: PayloadAction<VehicleCertificatesSearchSuccessPayload>) => {
      state.isLoading = false;
      state.error = undefined;
      state.data = payload.data;
      state.result = payload.result;
      state.vehicles.data = payload.vehiclesData;
      state.vehicles.result = payload.vehiclesResult;
      state.vehicles.riskCategories = payload.riskCategories;
    },
    searchFail: (state, { payload }: PayloadAction<VehicleCertificatesSearchFailPayload>) => {
      state.isLoading = false;
      state.error = payload.error;
    },
    resetSearch: state => {
      state.isLoading = undefined;
      state.result = [];
      state.vehicles.result = [];
    },
  },
});

export const { searchStart, searchSuccess, searchFail, resetSearch } = vehicleCertificates.actions;

export default vehicleCertificates.reducer;
