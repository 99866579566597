import _get from 'lodash/get';
import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';

import { IconLoaderProps } from './IconLoader';

const borderWidth = 3;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoaderWrap = styled.div<Pick<IconLoaderProps, 'size'>>`
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
`;

export const LoaderOuter = styled.div<Pick<IconLoaderProps, 'color'>>`
  position: absolute;
  border: ${({ theme, color }) =>
    `${borderWidth}px solid ${transparentize(0.8, _get(theme.palette, color, ''))}`};
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-right-color: ${({ theme, color }) => _get(theme.palette, color, '')};
  animation: ${rotate360} 1s cubic-bezier(0.62, 0.28, 0.23, 0.99) infinite;
`;

export const LoaderIconWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
