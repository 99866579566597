import React, { PureComponent } from 'react';

import { components } from 'react-select';

import Icon from 'components/Icon';
import { UIcoPijlBoven, UIcoPijlOnder } from 'components/Icons';

export default class DropdownIndicator extends PureComponent {
  render() {
    const {
      selectProps: { menuIsOpen },
    } = this.props;
    return (
      <components.DropdownIndicator {...this.props}>
        <Icon size={20} fill cursor="pointer" spacing={4} color="neutral01.700">
          {menuIsOpen ? <UIcoPijlBoven /> : <UIcoPijlOnder />}
        </Icon>
      </components.DropdownIndicator>
    );
  }
}
