import React from 'react';

import { Box } from '@material-ui/core';

import { SummaryField, SummaryTitle } from 'components/FormSummary';

import { StepperState } from '../../AffiliationsCreateStepper';

interface Props {
  onEditClick: () => void;
}

const AddressSummary: React.FC<Props & StepperState['stepAddress']> = ({
  onEditClick,
  ...values
}) => {
  const location =
    values.country.value === 'BE'
      ? values.locationBE?.selectedItem?.attributes?.name
      : values.location;
  const zipcode =
    values.country.value === 'BE'
      ? values.locationBE?.selectedItem?.attributes?.zipcode
      : values.zipcode;
  const number = [values.number, values.numberExtension].filter(Boolean).join(' ');
  return (
    <Box mb={8}>
      <SummaryTitle title="affiliations.add.step.address.title" onEditClick={onEditClick} />
      <SummaryField
        label="affiliations.add.step.summary.address_label"
        value={[values.street?.inputValue, number].filter(Boolean).join(' ')}
      />
      <SummaryField
        label="affiliations.add.step.summary.place_label"
        value={[zipcode, location].filter(Boolean).join(' ')}
      />
      <SummaryField
        label="affiliations.add.step.summary.country_label"
        value={values.country.label}
      />
      <SummaryField
        nowrap
        label="affiliations.add.step.address.extra_address_label"
        value={values.extra3}
      />
    </Box>
  );
};

export default AddressSummary;
