import React, { Fragment } from 'react';

import { StoreDefaultState } from 'types/store/store.types';

import ErrorPlaceholder from './ErrorPlaceholder';
import LoadingPlaceholder from './LoadingPlaceholder';
import NoDataPlaceholder from './NoDataPlaceholder';
import PageLoadingPlaceholder from './PageLoadingPlaceholder';

// TODO: remove any's

interface Props {
  data?: any;
  isLoading?: StoreDefaultState['isLoading'];
  error?: StoreDefaultState['error'] | boolean;
  LoaderComponent?: React.ComponentType<any>;
  NoDataComponent?: React.ComponentType<any>;
  ErrorComponent?: React.ComponentType<any>;
  type?: 'inline' | 'block' | 'page';
  loaderProps?: any;
  errorProps?: any;
  noDataProps?: any;
  withEmptyState?: boolean;
  withErrorState?: boolean;
}

const Placeholder: React.FC<Props> = ({
  isLoading,
  error,
  data,
  LoaderComponent,
  type = 'block',
  errorProps = {},
  loaderProps = {},
  noDataProps = {},
  withEmptyState = true,
  withErrorState = true,
  NoDataComponent = NoDataPlaceholder,
  ErrorComponent = ErrorPlaceholder,
  children,
}) => {
  const iconSize = type === 'inline' ? 24 : 64;

  if (isLoading) {
    if (LoaderComponent) {
      return <LoaderComponent {...loaderProps} />;
    }
    if (type === 'page') {
      return <PageLoadingPlaceholder {...loaderProps} />;
    }
    return <LoadingPlaceholder {...loaderProps} />;
  }

  if (withErrorState && error) {
    return <ErrorComponent type={type} iconSize={iconSize} {...errorProps} />;
  }

  if (withEmptyState && (!data || (!data.length && !Object.keys(data).length))) {
    return <NoDataComponent type={type} iconSize={iconSize} {...noDataProps} />;
  }
  if (!isLoading && (withErrorState ? !error : true) && (withEmptyState ? data : true)) {
    return <Fragment>{children}</Fragment>;
  }
  return null;
};

export default Placeholder;
