import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { CardProps } from './Card.types';

const cardItemStyle = css<CardProps>`
  padding: ${({ theme, padding }) => padding || `${theme.spacing(1) * 4}px`};
  margin: ${({ m, theme }) => (m / 2) * theme.spacing(1)}px;
  border-radius: ${({ theme }) => theme.spacing(1) / 2}px;
  flex-direction: column;
  overflow: hidden;
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}` : '')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}` : '')};
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}` : '')};
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}` : '')};
  box-shadow: ${({ theme }) =>
    `0 1px 5px 0  ${transparentize(0.95, theme.palette.neutral01[600])}`};
  background: ${({ theme }) => theme.palette.neutral01[50]};
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme, padding }) => padding || `${theme.spacing(1) * 6}px`};
  }
`;

const cardContainerStyle = css<CardProps>`
  flex-direction: ${({ direction }) => direction || 'column'};
  overflow: hidden;
  ${({ shouldWrap }) => (shouldWrap ? 'flex-wrap: wrap' : '')};
  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex-grow: 1;
    flex-direction: ${({ direction }) => direction || 'row'};
    ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}` : '')};
    ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}` : '')};
  }
  margin: ${({ m, theme }) => -(m / 2) * theme.spacing(1)}px;
`;

export const CardWrap = styled.div<CardProps>`
  display: flex;
  flex-grow: ${({ grow }) => grow || 0};
  order: ${({ order }) => (order && order.s) || 1};
  ${({ theme }) => theme.breakpoints.up('md')} {
    order: ${({ order }) => (order || {}).m || 1};
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    order: ${({ order }) => (order || {}).l || 1};
  }
  ${({ isContainer }) => {
    if (isContainer) {
      return cardContainerStyle;
    }
    return cardItemStyle;
  }};
`;

interface CardHeader {
  isCollapsible?: boolean;
}

export const CardHeader = styled.div<CardHeader>`
  cursor: ${({ isCollapsible }) => (isCollapsible ? 'pointer' : 'initial')};
  margin-bottom: ${({ theme }) => theme.spacing(1) * 5}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default null;
