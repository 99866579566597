import { useEffect, useState } from 'react';

//TODO: remove!!
const breakpoints = {
  small: 320,
  medium: 768,
  large: 1280,
  xl: 1920,
};

interface MediaListWatcher {
  small: MediaQueryList;
  medium: MediaQueryList;
  large: MediaQueryList;
  [index: string]: MediaQueryList; // for object.keys(key) forces string if you want to access dynamically
}

export interface MediaList {
  small: boolean;
  medium: boolean;
  large: boolean;
  isTouchDevice: boolean;
}

const initialState = {
  small: false,
  medium: false,
  large: true,
  isTouchDevice: false,
};

const domAvailability =
  typeof window !== 'undefined' && window.document && window.document.createElement;

const mediaList: MediaListWatcher | undefined = domAvailability
  ? {
      small: window.matchMedia(`(max-width: ${breakpoints.medium - 1}px)`),
      medium: window.matchMedia(
        `(min-width: ${breakpoints.medium}px) and (max-width: ${breakpoints.large - 1}px)`,
      ),
      large: window.matchMedia(`(min-width:${breakpoints.large}px)`),
    }
  : undefined;

const useMedia = (): MediaList => {
  const [media, setMedia] = useState(initialState);

  useEffect(() => {
    const checkToucheDevice = () => 'ontouchstart' in window;
    const checkMedia = () => {
      if (mediaList) {
        setMedia({
          small: mediaList.small.matches,
          medium: mediaList.medium.matches,
          large: mediaList.large.matches,
          isTouchDevice: checkToucheDevice(),
        });
      }
    };
    const mediaQueryChanged = () => {
      checkMedia();
    };

    if (mediaList) {
      Object.keys(mediaList).forEach(mediaKey =>
        mediaList[mediaKey].addListener(mediaQueryChanged),
      );
      checkMedia();
    }
    return () => {
      if (mediaList) {
        Object.keys(mediaList).forEach(media => mediaList[media].removeListener(mediaQueryChanged));
      }
    };
  }, []);

  return media;
};

export default useMedia;
