import apiManager from './axios/apiManager';

export const fetchInvoice = (path, id) =>
  apiManager.get(`${path}/${id}`).then(response => response.data);

export const fetchDownloadInvoice = (path, id) =>
  apiManager
    .get(`${path}/${id}/download`, {
      cache: {
        maxAge: 0,
        ignoreCache: true,
      },
      responseType: 'blob',
    })
    .then(response => response);
