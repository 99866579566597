import { Theme } from '@material-ui/core';
import { transparentize } from 'polished';

const createOverrides = (theme: Theme): Theme['overrides'] => ({
  MuiTooltip: {
    popperArrow: {
      '&[x-placement*="bottom"] $arrow': {
        '&::after': {
          marginTop: '-0.3em',
          borderWidth: '0 1.3em 1.3em 1.3em',
          borderColor: `transparent transparent ${theme.palette.neutral01[300]} transparent`,
        },
      },
      '&[x-placement*="bottom-start"] $arrow': {
        left: '24px !important',
        marginLeft: '-0.3em',
      },
      '&[x-placement*="bottom-end"] $arrow': {
        right: '24px !important',
        marginRight: '-0.3em',
      },
      '&[x-placement*="top"] $arrow': {
        '&::after': {
          marginBottom: '-0.3em',
          borderWidth: '1.3em 1.3em 0 1.3em',
          borderColor: `${theme.palette.neutral01[300]} transparent transparent transparent`,
        },
      },
      '&[x-placement*="top-start"] $arrow': {
        left: '24px !important',
        marginLeft: '-0.3em',
      },
      '&[x-placement*="top-end"] $arrow': {
        right: '24px !important',
        marginRight: '-0.3em',
      },
      '&[x-placement*="right"] $arrow': {
        '&::after': {
          marginLeft: '-0.3em',
          marginBottom: '-0.3em',
          borderWidth: '1.3em 1.3em 1.3em 0',
          borderColor: `transparent ${theme.palette.neutral01[300]} transparent transparent`,
        },
      },
      '&[x-placement*="left"] $arrow': {
        '&::after': {
          marginRight: '-0.3em',
          marginBottom: '-0.3em',
          borderWidth: '1.3em 0 1.3em 1.3em',
          borderColor: `transparent transparent transparent ${theme.palette.neutral01[300]}`,
        },
      },
      '&[x-placement*="end"] $arrow': {
        right: theme.spacing(4),
        '&::after': {
          marginLeft: '-0.3em',
          marginBottom: '-0.3em',
          borderWidth: '1.3em 1.3em 1.3em 0',
          borderColor: `transparent ${theme.palette.neutral01[300]} transparent transparent`,
        },
      },
    },
    tooltip: {
      position: 'relative',
      color: theme.palette.neutral01[600],
      backgroundColor: theme.palette.neutral01[50],
      fontSize: 14,
      padding: theme.spacing(4),
      boxShadow: theme.shadows[1],
      [theme.breakpoints.up('sm')]: {
        maxWidth: 350,
      },
    },
    tooltipPlacementBottom: {
      [theme.breakpoints.up('sm')]: {
        margin: '10px 0',
      },
      margin: '10px 0',
    },
    tooltipPlacementTop: {
      [theme.breakpoints.up('sm')]: {
        margin: '10px 0',
      },
      margin: '10px 0',
    },
    tooltipPlacementRight: {
      [theme.breakpoints.up('sm')]: {
        margin: '0 10px',
      },
      margin: '0 10px',
    },
    tooltipPlacementLeft: {
      [theme.breakpoints.up('sm')]: {
        margin: '0 10px',
      },
      margin: '0 10px',
    },
    arrow: {
      color: theme.palette.neutral01[50],
      position: 'absolute',
      fontSize: 4,
      '&::before, &::after': {
        position: 'absolute',
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
      '&:before': {
        zIndex: 1,
      },
    },
  },
  MuiButton: {
    root: {
      '&:hover': {
        backgroundColor: theme.palette.brand01[500],
        color: theme.palette.neutral01[50],
      },
      '&:active': {
        backgroundColor: theme.palette.brand01[700],
        color: theme.palette.neutral01[50],
      },
      backgroundColor: 'transparant',
      border: `1px solid ${theme.palette.brand01[500]}`,
      borderRadius: '4px',
      color: theme.palette.neutral01[50],
      fontWeight: 700,
      padding: '5px',
      '&$disabled': {
        color: theme.palette.neutral01[50],
        backgroundColor: theme.palette.neutral01[800],
      },
    },
  },
  MuiLink: {
    root: {
      fontWeight: 700,
      color: theme.palette.brand01[300],
      '&:hover': {
        color: theme.palette.brand01[500],
      },
      '&:active': {
        color: theme.palette.brand01[700],
      },
      '&[disabled]': {
        color: theme.palette.neutral01[600],
        cursor: 'default',
      },
      '&[class*=MuiTypography-colorError]': {
        color: theme.palette.accent02[400],
        '&:hover': {
          color: theme.palette.accent02[300],
        },
      },
    },
    button: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
    },
  },
  MuiPaper: {
    root: {
      color: theme.palette.neutral01[600],
    },
  },
  MuiTab: {
    root: {
      textTransform: 'none',
      minHeight: 'auto',
      minWidth: 26,
      opacity: 1,
      marginRight: theme.spacing(4),
      padding: theme.spacing(4),
      color: theme.palette.brand01[400],
      fontWeight: 700,
      fontSize: 16,
      backgroundColor: theme.palette.brand01[50],
      '&:hover:not(.Mui-selected)': {
        color: theme.palette.brand01[500],
      },
      [theme.breakpoints.up('sm')]: {
        minWidth: 26,
      },
    },
    textColorInherit: {
      opacity: 1,
    },
    selected: {
      color: theme.palette.brand01[300],
      fontWeight: 700,
      backgroundColor: theme.palette.neutral01[50],
      boxShadow: theme.shadows[1],
    },
    disabled: {
      color: theme.palette.neutral01[500],
      fontWeight: 700,
    },
  },
  MuiTabs: {
    indicator: {
      zIndex: 1,
      backgroundColor: theme.palette.neutral01[50],
    },
  },
  MuiSnackbar: {
    root: {
      [theme.breakpoints.down('sm')]: {
        position: 'absolute !important' as any,
        top: 'auto !important' as any,
        width: '100%',
      },
      borderRadius: '2px',
    },
  },
  MuiSnackbarContent: {
    root: {
      backgroundColor: theme.palette.brand01[300],
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.17)',
    },
  },
  MuiInput: {
    root: {},
    formControl: {
      marginTop: 0,
      'label + &': {
        marginTop: 0,
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      fontSize: 16,
    },
    label: {
      fontSize: 16,
    },
  },
  MuiRadio: {
    root: {
      fontSize: 16,
    },
  },
  MuiInputBase: {
    root: {
      overflow: 'hidden',
      boxSizing: 'border-box',
      border: `2px solid ${theme.palette.neutral01[300]}`,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.neutral01[50],
      padding: theme.spacing(3, 4),
      minHeight: theme.spacing(12),
      lineHeight: 1.5,
      fontSize: 16,
      '&$disabled': {
        backgroundColor: theme.palette.neutral01[300],
        color: theme.palette.neutral01[500],
      },
      '&$focused': {
        border: `2px solid ${theme.palette.brand01[300]}`,
      },
      '&$error': {
        border: `2px solid ${theme.palette.accent02[400]}`,
      },
      '&.autocomplete': {
        backgroundColor: 'red',
      },
    },
    input: {
      padding: 0,
      flex: 1,
    },
    multiline: {
      padding: theme.spacing(3, 4),
    },
  },
  MuiFilledInput: {
    root: {
      backgroundColor: theme.palette.accent01[100],
      '&$focused': {
        backgroundColor: theme.palette.neutral01[50],
      },
      '&$error': {
        backgroundColor: theme.palette.neutral01[50],
      },
      '&:hover': {
        backgroundColor: theme.palette.accent01[100],
      },
    },
    input: {
      padding: 0,
    },
  },
  MuiInputAdornment: {
    root: {
      color: theme.palette.neutral01[500],
    },
  },
  MuiInputLabel: {
    root: {
      '&$error': {
        color: theme.palette.accent02[400],
      },
    },
    shrink: {
      transform: 'none',
    },
    formControl: {
      cursor: 'pointer',
      position: 'relative',
      transform: 'none',
    },
  },
  MuiFormControl: {
    root: {
      maxWidth: '100%',
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: 16,
      color: theme.palette.neutral01[600],
      marginBottom: theme.spacing(2),
      '&$error': {
        color: theme.palette.accent02[400],
      },
      '&$focused': {
        color: 'inherit',
      },
    },
    colorSecondary: {
      color: theme.palette.neutral01[500],
      '&$focused': {
        color: theme.palette.neutral01[500],
      },
    },
  },
  MuiDialog: {
    paper: {
      width: '85%',
      [theme.breakpoints.up('lg')]: {
        width: '65%',
      },
    },
  },
  MuiDialogTitle: {
    root: {
      padding: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      '&:empty': {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4, 8),
      },
    },
  },
  MuiDialogActions: {
    root: {
      padding: theme.spacing(4),
      justifyContent: 'flex-start',
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6, 8),
      },
    },
    spacing: {
      '& > :not(:first-child)': {
        marginLeft: theme.spacing(4),
      },
    },
  },
  MuiDialogContent: {
    root: {
      padding: theme.spacing(0, 4),
      justifyContent: 'flex-start',
      '&:first-child': {
        paddingTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 8),
      },
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: transparentize(0.6, theme.palette.brand01[200]),
    },
  },
  MuiTypography: {
    root: {
      color: theme.palette.neutral01[600],
    },
    gutterBottom: {
      marginBottom: theme.spacing(4),
    },
    body1: {
      color: theme.palette.neutral01[600],
    },
    colorTextPrimary: {
      color: theme.palette.neutral01[600],
    },
    colorTextSecondary: {
      color: theme.palette.neutral01[500],
    },
  },
  MuiAppBar: {
    positionFixed: {
      zIndex: 1301,
      [theme.breakpoints.up('md')]: {
        zIndex: 1100,
      },
      top: 'auto',
    },
    colorPrimary: {
      backgroundColor: 'rgba(255,255,255,0)',
      bottom: 0,
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'rgba(255,255,255,0)',
        bottom: 'auto',
      },
    },
  },
  MuiToolbar: {
    regular: {
      padding: theme.spacing(0),
      [theme.breakpoints.up('xs')]: {
        minHeight: 56,
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 8),
      },
    },
  },
  MuiDrawer: {
    root: {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
    },
    paperAnchorBottom: {
      zIndex: 1300,
      bottom: theme.mixins.headerHeight,
    },
  },
  MuiMenu: {
    list: {
      color: theme.palette.neutral01[600],
    },
    paper: {
      marginTop: theme.spacing(2),
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: 16,
      '&:empty': {
        display: 'none',
      },
      '&$selected': {
        fontWeight: 700,
        backgroundColor: 'transparent',
        color: theme.palette.brand01[400],
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '&:hover': {
        color: theme.palette.brand01[500],
        backgroundColor: 'transparent',
        fontWeight: 700,
      },
    },
    gutters: {
      padding: theme.spacing(3, 4),
    },
  },
  MuiLisItem: {
    button: {
      '&:hover': {
        backgroundColor: 'transparant',
        fontWeight: 700,
      },
    },
  },
  MuiCheckbox: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: theme.spacing(3),
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& svg': {
        width: 24,
        height: 24,
        stroke: theme.palette.neutral01[400],
      },
      '& svg:hover': {
        width: 24,
        height: 24,
        stroke: theme.palette.brand01[500],
      },
    },
    checked: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& svg': {
        stroke: theme.palette.brand01[500],
      },
    },
    disabled: {
      opacity: 0.6,
      '& svg': {
        stroke: theme.palette.neutral01[500],
      },
    },
  },
  MuiTable: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 auto',
    },
  },
  MuiTableHead: {
    root: {
      display: 'block',
      tableLayout: 'fixed',
      background: theme.palette.neutral01[50],
      zIndex: 1,
      borderBottom: `1px solid ${theme.palette.neutral01[300]}`,
    },
  },
  MuiTableBody: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 auto',
      background: theme.palette.neutral01[50],
    },
  },
  MuiTableRow: {
    root: {
      flexShrink: 0,
      display: 'table',
      width: '100%',
      height: theme.mixins.tableRowHeight,
      tableLayout: 'fixed',
    },
  },
  MuiTableCell: {
    root: {
      position: 'relative',
      lineHeight: 1.5,
      padding: theme.spacing(0, 1),
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      borderBottom: 0,
      '&:first-child': {
        paddingLeft: theme.spacing(4),
      },
      '&:last-child': {
        paddingRight: theme.spacing(4),
      },
      '& *, > div *': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 2),
      },
    },
    body: {
      color: theme.palette.brand01[400],
    },
    head: {
      color: theme.palette.brand01[400],
    },
  },
  MuiTablePagination: {
    menuItem: {
      color: theme.palette.brand01[300],
    },
  },
  MuiCard: {
    root: {
      border: 0,
      borderRadius: 8,
      boxShadow: theme.shadows[2],
    },
  },
  MuiCardContent: {
    root: {
      padding: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6),
      },
    },
  },
});

export { createOverrides };
