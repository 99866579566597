import { withStyles } from '@material-ui/core/styles';
import MUITooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';

import { TooltipProps } from './Tooltip';

export const TooltipWrapper = withStyles(theme => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 'inherit',
    padding: '0',
    maxWidth: 'none',
    position: 'relative',
    width: 'auto',
    maxHeight: 'calc(100vh - 38px)',
    '&::after, &::before ': {
      border: 'solid transparent',
      content: '" "',
      height: 0,
      width: 0,
      position: 'absolute',
    },
    '&:after': {
      marginLeft: '-4px',
      borderWidth: '4px',
    },
    '&:before': {
      marginLeft: '-5px',
      borderWidth: '5px',
    },
  },
  popper: {
    opacity: 1,
    '&[x-placement*="bottom"] .tooltip': {
      marginTop: `${theme.spacing(2)}px`,
      '&::after, &::before': {
        bottom: '100%',
      },
      '&::after': {
        borderBottomColor: theme.palette.neutral01[50],
      },
      '&::before': {
        borderBottomColor: theme.palette.neutral01[300],
      },
    },
    '&[x-placement*="top"] .tooltip': {
      marginBottom: `${theme.spacing(2)}px`,
      '&::after, &::before': {
        top: '100%',
      },
      '&::after': {
        borderTopColor: theme.palette.neutral01[50],
      },
      '&::before': {
        borderTopColor: theme.palette.neutral01[300],
      },
    },
    '&[x-placement*="left"] .tooltip , &[x-placement*="start"] .tooltip': {
      '&::after, &:before': {
        left: '20px',
      },
    },
    '&[x-placement*="end"] .tooltip': {
      marginRight: '0',
      '&::after, &::before': {
        right: '20px',
      },
    },
    '&[x-placement*="right"] .tooltip': {
      '&::after, &::before': {
        left: '-5px',
        top: '50%',
        transform: 'translateY(-50%)',
      },
      '&::after': {
        marginLeft: '-3px',
        borderRightColor: theme.palette.neutral01[50],
      },
      '&::before': {
        borderRightColor: theme.palette.neutral01[300],
      },
    },
    '&[x-placement="bottom"] .tooltip': {
      marginTop: `${theme.spacing(2)}px`,
      '&::after, &::before': {
        left: '50%',
      },
      '&::after': {
        borderBottomColor: theme.palette.neutral01[50],
      },
      '&::before': {
        borderBottomColor: theme.palette.neutral01[300],
      },
    },
    '&[x-placement="top"] .tooltip': {
      marginBottom: `${theme.spacing(2)}px`,
      marginRight: `-${theme.spacing(6)}px`,
      '&::after, &::before': {
        left: '50%',
      },
      '&::after': {
        borderTopColor: theme.palette.neutral01[50],
      },
      '&::before': {
        borderTopColor: theme.palette.neutral01[300],
      },
    },
  },
}))(MUITooltip);

export const TooltipContent = styled.div<TooltipProps>`
  max-height: ${({ maxHeight }) => maxHeight};
  max-width: ${({ maxWidth }) => maxWidth};
  overflow: ${({ isOverflowVisible }) => (isOverflowVisible ? 'visible' : 'auto')};
  padding: ${({ theme }) => theme.spacing(4)}px;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.brand01[400]};
  line-height: 1.57;
`;
