import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { Store } from 'types/store/store.types';

export const getDashboardIsLoading = (state: Store) => state.dashboard.isLoading;
export const getDashboardError = (state: Store) => state.dashboard.error;

// DASHBOARD EVENTS
export const getDashboardEvents = (state: Store) => state.dashboard.events.data;
export const getDashboardEventsIsLoading = (state: Store) => state.dashboard.events.isLoading;
export const getDashboardEventsError = (state: Store) => state.dashboard.events.error;
export const getDashboardAllEventsLink = (state: Store) => state.dashboard.events.links?.sourceUrl;

// ENDPOINTS
export const getDashboardEventsEndpoint = (state: Store) => getEndpoint(state, 'events');
