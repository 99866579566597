import React from 'react';

import { Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { LinkButton } from 'components/@common/Link';
import DynamicDetails, { DynamicField } from 'components/DynamicDetails';

import Address from './Address';
import { ContainerProps } from './DetailsContainer';
import PartnerChildren from './PartnerChildren';

interface Props {
  onChildClick: (id?: string) => void;
  isGroup: boolean;
}

const setField = (label: string, value: React.ReactNode): DynamicField => ({
  key: label,
  title: <FormattedMessage id={label} />,
  type: 'TEXT',
  values: [value],
});

const Details: React.FC<Props & ContainerProps> = ({
  data,
  onChildClick,
  currentId,
  isLoading,
  error,
  isGroup,
}) => {
  const sectionGeneralData = {
    title: <FormattedMessage id="clients.details.section.general_data.title" />,
    sections: [
      {
        fields: [
          setField('clients.details.section.general_data.name_label', data?.attributes.name),
          setField('clients.details.section.general_data.address_label', <Address data={data} />),
        ],
      },
    ],
  };

  const sectionGroupData = {
    title: <FormattedMessage id="clients.details.section.group_data.title" />,
    sections: [
      {
        fields: [
          setField(
            'clients.details.section.group_data.mother_label',
            <LinkButton onClick={() => onChildClick(data?.relationships?.group?.data?.id)}>
              {data?.attributes.groupName}
            </LinkButton>,
          ),
          setField(
            'clients.details.section.group_data.motherID_label',
            data?.relationships?.group?.data?.id,
          ),
        ],
      },
    ],
  };

  const sectionGroup = {
    sections: [
      {
        fields: [
          setField(
            'clients.details.section.group_data.group_label',
            <PartnerChildren
              currentId={currentId}
              groupId={!isLoading ? data?.relationships?.group?.data?.id || currentId : undefined}
              onPartnerChildClick={onChildClick}
            />,
          ),
        ],
      },
    ],
  };

  return (
    <Grid container spacing={4}>
      <Grid item sm={12} lg={8}>
        <DynamicDetails
          isLoading={isLoading}
          error={error}
          data={
            isGroup
              ? [sectionGeneralData, sectionGroup]
              : [sectionGeneralData, sectionGroupData, sectionGroup]
          }
        />
      </Grid>
    </Grid>
  );
};

export default Details;
