import React from 'react';

import { Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ClientReportingCategory } from 'store/client/client.types';

import useRouter from 'utils/useRouter';
import useStepperForm from 'utils/useStepperForm';

import { FlowType } from 'pages/affiliations/Create/AffiliationsCreate';
import { BaseDataForm } from 'pages/affiliations/Forms';

import Button from 'components/@common/Button';
import InfoBanner from 'components/InfoBanner';

import { ContainerProps, FormValues } from './BaseDataStepContainer';

interface Props {
  reportingCategories: ClientReportingCategory[];
  saveForm: (values: FormValues) => void;
  isLoading: ContainerProps['isLoading'];
  insurantExists?: boolean;
  flowType: FlowType;
}

const BaseDataStep: React.FC<Props> = ({
  reportingCategories,
  saveForm,
  isLoading,
  insurantExists,
  flowType,
}) => {
  const { handleNextStepClick, canContinue } = useStepperForm(saveForm);
  const { push } = useRouter();

  const handleActionClick = () => {
    push('/affiliations');
  };
  return (
    <>
      <BaseDataForm flowType={flowType} reportingCategories={reportingCategories} />
      <InfoBanner mb={2} isOpen={insurantExists}>
        <div>
          <FormattedMessage
            id="affiliations.add.step.base_data.info.insurant_exists"
            values={{
              action: (
                <>
                  &nbsp;
                  <Link component="button" onClick={handleActionClick}>
                    <FormattedMessage id="affiliations.add.step.base_data.info.insurant_action" />
                  </Link>
                </>
              ),
            }}
          />
        </div>
      </InfoBanner>
      <Button
        data-test-id="affiliations.add.step.base_data.actions.next"
        isLoading={isLoading}
        disabled={canContinue}
        onClick={handleNextStepClick}
      >
        <FormattedMessage id="general.stepper.next" />
      </Button>
    </>
  );
};

export default BaseDataStep;
