import React, { useRef } from 'react';

import { InputProps } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { BACKSPACE, ENTER, TAB } from 'utils/keyCodes';

import { Input } from './TagInput.styled';
import TagInputChip from './TagInputChip';

export interface Props extends Omit<InputProps, 'onChange' | 'value' | 'classes'> {
  onChange: (type: 'REMOVE' | 'ADD', value: string) => void;
  value?: string[];
  inputValue?: string;
  maxItems?: number;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TagInput: React.FC<Props> = ({
  placeholder,
  maxItems,
  id,
  name,
  value = [],
  inputValue = '',
  onChange = () => {},
  onInputChange = () => {},
  onBlur = () => {},
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ([ENTER, TAB].indexOf(e.keyCode) > -1 && inputValue) {
      onChange('ADD', inputValue);
      if (e.keyCode === (TAB || BACKSPACE)) {
        e.preventDefault();
      }
    }
    if (!inputValue && e.keyCode === BACKSPACE) {
      const lastItem = value[value.length - 1];
      onChange('REMOVE', lastItem);
    }
  };

  const deleteSpecificChip = (item: string) => {
    onChange('REMOVE', item);
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onChange('ADD', inputValue);
    onBlur(e);
  };

  const intl = useIntl();

  return (
    <>
      <Input
        id={id}
        name={name}
        fullWidth={true}
        onBlur={handleBlur}
        onChange={onInputChange}
        onKeyDown={handleKeyDown}
        value={inputValue}
        placeholder={
          inputValue.length > 0 ? '' : placeholder && intl.formatMessage({ id: placeholder })
        }
        autoComplete="off"
        spellCheck={false}
        startAdornment={
          <>
            {value.length > 0 &&
              value.map(item => (
                <TagInputChip key={item} item={item} onRemove={deleteSpecificChip} />
              ))}
          </>
        }
        {...rest}
      />
    </>
  );
};

export default TagInput;
