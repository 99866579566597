import styled from 'styled-components';

interface TableWrapProps {
  minHeight?: number;
}

export const TableWrap = styled.div<TableWrapProps>`
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  position: relative;
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}px` : '')};
`;
