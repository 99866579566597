import styled from 'styled-components';

export const HighlightMark = styled.mark`
  background-color: ${({ theme }) => theme.palette.neutral01[300]};
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.neutral01[600]};
`;

export default null;
