import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { QUERY_PARAMS } from 'constants/partners';
import { queryKeys } from 'constants/querykeys';
import { apiService } from 'services';
import { PartnersResponse } from 'services/Api/types/partners.types';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { ErrorTrace, Store } from 'types/store/store.types';

const usePartnerChildren = (partnerId: string, groupId?: string) => {
  const filters = { [QUERY_PARAMS.GROUP_ID]: groupId, [QUERY_PARAMS.ONLY_MY_PARTNERS]: 'false' };
  const endpoint = useSelector((state: Store) => getEndpoint(state, 'partners'));

  const query = useQuery<PartnersResponse, ErrorTrace>(
    [queryKeys.partnerChildren, partnerId, filters],
    () => apiService.partners.fetchAcmaClients(endpoint, filters),
    {
      enabled: !!endpoint && !!groupId,
    },
  );

  return query;
};

export default usePartnerChildren;
