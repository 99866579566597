import { ROUTES } from 'constants/store';

const routesToBeInverted = [ROUTES.DASHBOARD];

const shouldRouteBeInverted = (route: string) => {
  if (routesToBeInverted.includes(route)) {
    return true;
  }
  return false;
};

export default shouldRouteBeInverted;
