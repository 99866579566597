import { connect } from 'react-redux';

import { getUser } from 'store/user/user.selectors';
import { Store } from 'types/store/store.types';

import Page from './Page';

const mapStateToProps = (state: Store) => ({
  user: getUser(state),
});

type MapStateProps = ReturnType<typeof mapStateToProps>;
export type ContainerProps = MapStateProps;

export default connect(mapStateToProps, {})(Page);
