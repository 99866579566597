import React from 'react';

import { FormControlLabelProps } from '@material-ui/core';

import { FormControlLabel, Radio } from './RadioTile.styled';

interface RadioTileProps extends Omit<FormControlLabelProps, 'control' | 'label' | 'onChange'> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: unknown) => void;
}

const RadioTile: React.FC<RadioTileProps> = ({ value, children, onChange = () => {}, ...rest }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e, value);
  };
  return (
    <FormControlLabel
      value={value}
      label={children}
      control={<Radio onChange={handleChange} icon={<></>} checkedIcon={<></>} />}
      {...rest}
    />
  );
};

export default RadioTile;
