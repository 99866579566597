import React, { useContext } from 'react';

import SwipeableViews from 'react-swipeable-views';

import { tabContext } from './TabContext';

interface Props {
  swipeable?: boolean;
}

const TabsPanels: React.FC<Props> = ({ swipeable = false, children }) => {
  const { tabs, handleChange, activeId } = useContext(tabContext);
  const handleChangeIndex = (index: number) => {
    handleChange(tabs[index]);
  };

  if (!swipeable) {
    return <>{children}</>;
  }

  return (
    <SwipeableViews
      enableMouseEvents
      onChangeIndex={handleChangeIndex}
      index={tabs.indexOf(activeId)}
    >
      {children}
    </SwipeableViews>
  );
};

export default TabsPanels;
