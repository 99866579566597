import React from 'react';

import MuiRadioGroup from '@material-ui/core/RadioGroup';

import { Label, RadioButton } from 'components/@inputs';

type RadioGroupOption = {
  label: React.ReactNode;
  value: string;
};

export interface RadioGroupProps {
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode;
  hasError?: boolean;
  value?: string;
  options?: RadioGroupOption[];
  orientation?: 'horizontal' | 'vertical';
  disabled?: boolean;
  fullWidth?: boolean;
  name?: string;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  id,
  name,
  label,
  hasError,
  disabled,
  fullWidth,
  onChange = () => {},
  value = '',
  options = [],
  orientation = 'horizontal',
}) => (
  <>
    {label && <Label hasError={hasError}>{label}</Label>}
    <MuiRadioGroup row={orientation === 'horizontal'} onChange={onChange} value={`${value}`}>
      {options.map(option => (
        <RadioButton
          id={id}
          name={name}
          fullWidth={fullWidth}
          disabled={disabled}
          key={`${option.value}`}
          value={`${option.value}`}
          label={option.label}
          data-test-id={name}
        />
      ))}
    </MuiRadioGroup>
  </>
);

export default RadioGroup;
