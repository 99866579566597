import { connect } from 'react-redux';

import { dialogNo, dialogYes } from 'store/dialog/dialog.actions';
import {
  getCurrentDialog,
  getCurrentDialogOpen,
  getDialogStatus,
} from 'store/dialog/dialog.selectors';

import Dialog from './Dialog';

const mapStateToProps = state => ({
  ...getCurrentDialog(state),
  isOpen: getCurrentDialogOpen(state),
  status: getDialogStatus(state),
});

const mapDispatchToProps = {
  yes: dialogYes,
  no: dialogNo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
