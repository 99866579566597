import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
interface IProps {
  children: (isOverlowing: boolean) => React.ReactNode;
}

const DetectOverflow: React.FC<IProps> = props => {
  const wrapRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const checkOverflow = useCallback(
    _debounce(() => {
      const hasOverflow =
        _get(wrapRef, 'current.clientWidth') < _get(wrapRef, 'current.scrollWidth');
      if (hasOverflow !== isOverflow) {
        setIsOverflow(hasOverflow);
      }
    }, 200),
    [isOverflow],
  );

  useEffect(() => {
    window.addEventListener('resize', checkOverflow);
    checkOverflow();
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [checkOverflow]);
  return <div ref={wrapRef}>{props.children(isOverflow)}</div>;
};

export default memo(DetectOverflow);
