import React from 'react';

import { TableCellProps as MUITableCellProps } from '@material-ui/core/TableCell';
import MuiTableCell from '@material-ui/core/TableCell';
import { FormattedNumber } from 'react-intl';

import { DEFAULT_DATE_FORMAT } from 'constants/store';

import DateFormat from 'components/Date';

import { TableCellInner } from './TableCell.styled';

export interface TableCellProps extends MUITableCellProps {
  type?: 'text' | 'number' | 'date' | 'default';
  dateFormat?: string;
  shouldWrap?: boolean;
  isGreyedOut?: boolean;
}

const TableCell: React.FC<TableCellProps> = ({
  children,
  shouldWrap = false,
  isGreyedOut = false,
  dateFormat = DEFAULT_DATE_FORMAT,
  align = 'left',
  type = 'text',
  component = 'div',
  ...rest
}) => {
  const cellTypeNode = {
    date: children ? <DateFormat withTodayAsText date={`${children}`} format={dateFormat} /> : '-',
    number: typeof children === 'number' ? <FormattedNumber value={children} /> : null,
    text: shouldWrap ? <span>{children}</span> : children,
    default: children,
  };

  return (
    <MuiTableCell component={component} {...rest}>
      <TableCellInner align={align} isGreyedOut={isGreyedOut}>
        {cellTypeNode[type]}
      </TableCellInner>
    </MuiTableCell>
  );
};

export default TableCell;
