import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Select } from 'components/@inputs';

import { PerPageWrap, selectStyle, SelectWrap } from './PerPage.styled';

interface PerPageProps {
  rowsPerPage: number;
  perPageOptions: Array<number>;
  onRowsPerPageChange: (value: number) => void;
}

const PerPage: React.FC<PerPageProps> & { defaultProps: Partial<PerPageProps> } = ({
  rowsPerPage,
  perPageOptions,
  onRowsPerPageChange,
}) => (
  <PerPageWrap>
    <div>
      <FormattedMessage id="claims.table.pagination.per_page" />
    </div>
    <SelectWrap>
      <Select
        isSearchable={false}
        menuPlacement="auto"
        options={perPageOptions.map(option => ({
          value: option,
          label: option,
        }))}
        value={{ value: rowsPerPage, label: rowsPerPage }}
        extraStyles={selectStyle}
        onChange={option => onRowsPerPageChange(option?.value)}
      />
    </SelectWrap>
  </PerPageWrap>
);

PerPage.defaultProps = {
  perPageOptions: [10, 25, 50],
  onRowsPerPageChange: () => {},
};

export default PerPage;
