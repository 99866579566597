import React from 'react';

import { Box } from '@material-ui/core';
import { TableCellProps as MUITableCellProps } from '@material-ui/core/TableCell';
import MuiTableCell from '@material-ui/core/TableCell';

import Icon from 'components/Icon';
import { UIcoPijlBoven, UIcoPijlDubbel, UIcoPijlOnder } from 'components/Icons';

import { TableCellProps } from '../TableCell';
import { TableCellInner } from '../TableCell/TableCell.styled';

export interface TableHeadCellProps extends TableCellProps, MUITableCellProps {
  id?: string;
  isNumeric?: boolean;
  orderBy?: string;
  order?: 'asc' | 'desc';
  label?: React.ReactNode;
  isSortable?: boolean;
  onRequestSort?: (id: string) => void;
}

const SortArrow: React.FC<Pick<TableHeadCellProps, 'id' | 'orderBy' | 'order' | 'isSortable'>> = ({
  id,
  order,
  orderBy,
  isSortable,
}) => {
  if (!isSortable) {
    return null;
  }
  let icon = <UIcoPijlDubbel />;
  if (id === orderBy) {
    if (order === 'asc') {
      icon = <UIcoPijlBoven />;
    } else {
      icon = <UIcoPijlOnder />;
    }
  }
  return (
    <Box bgcolor="neutral01.300" ml={1} borderRadius="50%" clone>
      <Icon size={20}>{icon}</Icon>
    </Box>
  );
};

const TableHeadCell: React.FC<TableHeadCellProps> = ({
  id,
  isNumeric,
  orderBy,
  onRequestSort,
  order,
  isSortable,
  label,
  isGreyedOut = false,
  align = 'left',
  component = 'div',
  ...rest
}) => {
  const handleRequestSort = () => {
    if (isSortable && onRequestSort) {
      onRequestSort(id ?? '');
    }
  };

  return (
    <MuiTableCell
      variant="head"
      key={id}
      sortDirection={orderBy === id ? order : false}
      onClick={handleRequestSort}
      component={component}
      {...rest}
      style={{ cursor: isSortable ? 'pointer' : 'auto', ...rest.style }}
    >
      <TableCellInner isGreyedOut={isGreyedOut} align={align}>
        {label}
        <SortArrow id={id} order={order} orderBy={orderBy} isSortable={isSortable} />
      </TableCellInner>
    </MuiTableCell>
  );
};

TableHeadCell.defaultProps = {
  isNumeric: false,
  onRequestSort: () => {},
  isSortable: false,
  label: '',
  align: 'left',
};

export default TableHeadCell;
