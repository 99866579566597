import React from 'react';

import { BackdropProps } from '@material-ui/core';
import Portal from '@material-ui/core/Portal';

import { BackdropStyled } from './Backdrop.styled';

export interface Props extends BackdropProps {
  enablePortal?: boolean;
  zIndex?: number;
}

const Backdrop: React.FC<Props> = ({
  open = false,
  invisible = true,
  enablePortal = true,
  ...rest
}) => (
  <Portal disablePortal={!enablePortal}>
    <BackdropStyled open={open} invisible={invisible} {...rest} />
  </Portal>
);
export default Backdrop;
