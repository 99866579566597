import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, params => ({
      id: 'general.errors.max',
      values: { max: params.max },
    }))
    .trim()
    .required('general.errors.required'),
  lastName: Yup.string()
    .max(50, params => ({
      id: 'general.errors.max',
      values: { max: params.max },
    }))
    .trim()
    .required('general.errors.required'),
  function: Yup.string()
    .trim()
    .max(100, params => ({
      id: 'general.errors.max',
      values: { max: params.max },
    }))
    .nullable(),
  phoneNumber: Yup.string()
    .max(30, params => ({
      id: 'general.errors.max',
      values: { max: params.max },
    }))
    .nullable(),
  language: Yup.object()
    .shape({})
    .required('general.errors.required'),
});

export { ValidationSchema };
