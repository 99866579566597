import { createAction } from 'redux-act';

import { ROUTES } from 'constants/store';
import storeConfig from 'store';

import { removeEmptyKeysFromObject } from 'utils/helpers';

import { getQuery, getRouteType } from './routing.selectors';

export const goToPage = (type, payload) => ({
  type,
  payload,
});

export const setQuery = payload => {
  const state = storeConfig.store.getState();
  const paramsOld = getQuery(state);
  const newParams = payload;
  const paramsMerged = removeEmptyKeysFromObject({
    ...paramsOld,
    ...newParams,
  });

  return { type: getRouteType(state), query: paramsMerged };
};

export const routeDashboard = createAction(ROUTES.DASHBOARD);
export const routeClaims = createAction(ROUTES.CLAIMS);
export const routeClaimDetail = createAction(ROUTES.CLAIM_DETAIL);
export const routeClaimCreate = createAction(ROUTES.CLAIM_CREATE);
export const routePolicies = createAction(ROUTES.POLICIES);
export const routePolicyDetail = createAction(ROUTES.POLICY_DETAIL);
export const routeRiskDetail = createAction(ROUTES.RISK_DETAIL);
export const routeDocuments = createAction(ROUTES.DATASHARE);
export const routeDocumentCreate = createAction(ROUTES.DATASHARE_CREATE);
export const routeSettings = createAction(ROUTES.SETTINGS);
export const routeInvoices = createAction(ROUTES.INVOICES);
export const routeInvoiceDetail = createAction(ROUTES.INVOICE_DETAIL);
export const routeBulletinBoard = createAction(ROUTES.BULLETINBOARD);
export const routeBulletinBoardDetail = createAction(ROUTES.BULLETINBOARD_DETAIL);
export const routeClaimReportsCreate = createAction(ROUTES.CLAIM_REPORTS_CREATE);
// (-- APPEND ACTIONS HERE --) !!! do not move this comment !!!
