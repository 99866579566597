import React, { useContext } from 'react';

import { Box, MenuItem, Tab as MuiTab, TabProps } from '@material-ui/core';

import { useMedia } from 'components/Media';

import { Counter } from './Tab.styled';
import { tabContext } from './TabContext';
export interface Props extends TabProps {
  value: string;
  count?: number;
}

const TabContent: React.FC<{ count?: number }> = ({ children, count }) => {
  const hasCount = count !== undefined;
  return (
    <Box position="relative" pr={hasCount ? 9 : 0}>
      <Box>{children}</Box>
      {hasCount && <Counter>{count}</Counter>}
    </Box>
  );
};

const Tab: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ value, count, children, disabled, ...rest }, _ref) => {
    const { activeId, handleChange, handleCloseMobileTabMenu, anchorEl } = useContext(tabContext);
    const media = useMedia();
    const handleTabClick = () => {
      if (activeId !== value) {
        handleChange(value);
      }
      if (anchorEl) {
        handleCloseMobileTabMenu();
      }
    };

    if (media.small) {
      return (
        <MenuItem selected={activeId === value} disabled={disabled} onClick={handleTabClick}>
          <TabContent count={count}> {children} </TabContent>
        </MenuItem>
      );
    }

    return (
      <MuiTab
        onClick={handleTabClick}
        id={value}
        value={value}
        disabled={disabled}
        {...rest}
        label={<TabContent count={count}>{children}</TabContent>}
      />
    );
  },
);

export default Tab;
