import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import {
  ContactsFetchStartPayload,
  PartnerContactsResponse,
  PartnerFetchStartPayload,
  PartnerResponse,
} from './partner.types';

export interface PartnerState {
  data?: PartnerResponse['data'];
  status?: 'loading' | 'contactsLoading';
  error: StoreDefaultState['error'];
  links?: {
    self: string;
  };
  contacts: {
    data?: PartnerContactsResponse['data'];
    meta?: PartnerContactsResponse['meta'];
    error: StoreDefaultState['error'];
    status?: 'loading';
  };
}

export const INITIAL_STATE: PartnerState = {
  data: undefined,
  error: undefined,
  status: undefined,
  contacts: {
    data: undefined,
    error: undefined,
    status: undefined,
    meta: undefined,
  },
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState: INITIAL_STATE,
  reducers: {
    partnerFetchStart: (state, _action: PayloadAction<PartnerFetchStartPayload>) => {
      state.status = 'loading';
      state.error = undefined;
    },
    partnerFetchSuccess: (state, { payload }: PayloadAction<PartnerResponse>) => {
      state.status = undefined;
      state.error = undefined;
      state.data = payload.data;
    },
    partnerFetchFail: (state, { payload }: PayloadAction<{ error: PartnerState['error'] }>) => {
      state.status = undefined;
      state.error = payload.error;
    },
    partnerContactsFetchStart: (state, _action: PayloadAction<ContactsFetchStartPayload>) => {
      state.contacts.status = 'loading';
      state.contacts.error = undefined;
    },
    partnerContactsFetchSuccess: (state, { payload }: PayloadAction<PartnerContactsResponse>) => {
      state.contacts.data = payload.data;
      state.contacts.meta = payload.meta;
      state.contacts.status = undefined;
      state.contacts.error = undefined;
    },
    partnerContactsFetchFail: (
      state,
      { payload }: PayloadAction<{ error: PartnerState['contacts']['error'] }>,
    ) => {
      state.status = undefined;
      state.error = payload.error;
    },
  },
});

export const {
  partnerFetchStart,
  partnerFetchSuccess,
  partnerFetchFail,
  partnerContactsFetchStart,
  partnerContactsFetchSuccess,
  partnerContactsFetchFail,
} = partnersSlice.actions;

export default partnersSlice.reducer;
