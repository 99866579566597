import React, { useContext } from 'react';

import { Box, BoxProps, Slide } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { useMedia } from 'components/Media';

import Header from './Header';
import { splitScreenContext } from './SplitScreenContext';
interface Props extends BoxProps {
  value: number;
  children: ((params: { handleGoToNextPanel: () => void }) => React.ReactNode) | React.ReactNode;
  headerVariant?: 'link' | 'main';
  header?: string | { id: string; value: { [index: string]: string } };
  parent?: 'left' | 'right';
}

const SlidePanel = ({
  children,
  value,
  header = '',
  headerVariant = 'main',
  parent,
  ...rest
}: Props) => {
  const { activePanel, handleGoToNextPanel, handleGoToPreviousPanel } = useContext(
    splitScreenContext,
  );
  const media = useMedia();

  const desktopAnimationProps = {
    in: true,
    timeout: 0,
  };
  const mobileAnimationProps = {
    in: activePanel >= value,
    timeout: 200,
  };

  return (
    <Slide direction="left" {...(media.small ? mobileAnimationProps : desktopAnimationProps)}>
      <Box
        display="flex"
        flexDirection="column"
        position={{ xs: 'absolute', md: 'relative', lg: 'relative' }}
        top={0}
        bottom={0}
        left={0}
        right={0}
        {...rest}
      >
        {header && (
          <Header onPreviousClick={handleGoToPreviousPanel} variant={headerVariant}>
            {typeof header === 'string' ? (
              <FormattedMessage id={header} />
            ) : (
              <FormattedMessage {...header} />
            )}
          </Header>
        )}
        <>{typeof children !== 'function' ? children : children({ handleGoToNextPanel })}</>
      </Box>
    </Slide>
  );
};

export default SlidePanel;
