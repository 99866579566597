import { Box } from '@material-ui/core';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { H2 } from 'components/@typography/Titles';

export const SidebarWrap = styled(Box)<{ offset: number }>`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing(1) * 4}px ${({ theme }) => theme.spacing(1) * 4.5}px;
  background: ${({ theme }) => theme.palette.neutral01[50]};
  box-shadow: 0px 4px 5px ${({ theme }) => transparentize(0.6, theme.palette.neutral01[300])};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    align-items: center;
    min-height: ${({ theme }) => theme.mixins.headerHeight}px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    background: 0;
    padding: ${({ theme }) => theme.spacing(1) * 4}px 0;
    box-shadow: none;
    position: static;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: fixed;
    margin-right: ${({ theme }) => theme.spacing(1) * 4}px;
    min-width: 332px;
    max-width: 332px;
    padding: 0;
    height: ${({ offset }) => `calc(100% - ${offset}px)`};
    overflow: hidden;
  }
`;

export const TopWrap = styled.div`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    min-height: ${({ theme }) => theme.spacing(13)}px;
  }
`;

export const Title = styled(H2)`
  margin: 0px 0px 24px 0px;
  display: block;
  color: ${({ theme }) => theme.palette.brand01[400]};
`;
