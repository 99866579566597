import { AxiosError } from 'axios';
import _get from 'lodash/get';

import { Store } from 'types/store/store.types';

export const getAvatar = (state: Store, context: string, id: string): string =>
  _get(state, ['avatars', `${context}.${id}`, 'image']);
export const getAvatarIsLoading = (state: Store, context: string, id: string): boolean =>
  _get(state, ['avatars', `${context}.${id}`, 'isLoading']);
export const getAvatarError = (state: Store, context: string, id: string): AxiosError =>
  _get(state, ['avatars', `${context}.${id}`, 'error']);
export const getAvatarHasFetched = (state: Store, context: string, id: string): number =>
  _get(state, ['avatars', `${context}.${id}`, 'hasFetched']);
