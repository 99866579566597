import React, { PureComponent } from 'react';

import Icon from 'components/Icon';
import { UIcoKalender } from 'components/Icons';

export default class DatePickerEndEndornment extends PureComponent {
  render() {
    const { ...rest } = this.props;
    return (
      <Icon color="brand01.600">
        <UIcoKalender {...rest} />
      </Icon>
    );
  }
}
