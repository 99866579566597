import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTabelCell,
  TableCellProps,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';

// responsive table css only design: https://css-tricks.com/responsive-data-tables/

type TableCellStyledProps = TableCellProps & {
  'data-title'?: string;
};

export const TableCell = withStyles(theme =>
  createStyles<string, TableCellStyledProps>({
    root: {
      padding: theme.spacing(4),
      minHeight: theme.mixins.tableRowHeight,
      height: '100%',
      whiteSpace: 'normal',
      overflow: 'hidden',
      border: `1px solid ${theme.palette.neutral01[300]}`,
      borderBottom: 0,
      color: theme.palette.neutral01[700],
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        position: 'relative',
        paddingLeft: '50%',
        '&:first-child': {
          paddingLeft: '50%',
        },
        '&:before': {
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          content: 'attr(data-title)',
          borderRight: `1px solid ${theme.palette.neutral01[300]}`,
          background: transparentize(0.8, theme.palette.brand01[800]),
          color: theme.palette.brand01[400],
          display: 'flex',
          alignItems: 'center',
          left: 0,
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          width: `calc(50% - ${theme.spacing(4)}px)`,
          height: '100%',
          overflow: 'hidden',
        },
      },
    },
    head: {
      background: transparentize(0.8, theme.palette.brand01[800]),
      color: theme.palette.brand01[400],
    },
  }),
)(MuiTabelCell);

export const TableRow = withStyles(theme =>
  createStyles({
    root: {
      height: 'auto',
      minHeight: theme.mixins.tableRowHeight,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        borderBottom: `1px solid ${theme.palette.neutral01[300]}`,
        marginBottom: theme.spacing(4),
      },
    },
  }),
)(MuiTableRow);

export const Table = withStyles(theme =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
  }),
)(MuiTable);
export const TableBody = withStyles(theme =>
  createStyles({
    root: {
      borderBottom: `1px solid ${theme.palette.neutral01[300]}`,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        borderBottom: 0,
      },
    },
  }),
)(MuiTableBody);
export const TableHead = withStyles(theme =>
  createStyles({
    root: {
      borderBottom: 0,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        /* Hide table headers (but not display: none;, for accessibility) */
        position: 'absolute',
        top: -9999,
        left: -9999,
      },
    },
  }),
)(MuiTableHead);
