import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import {
  AuthCheckFailPayload,
  AuthCheckStartPayload,
  AuthCheckSuccessPayload,
  AuthLogoutFailPayload,
} from './auth.types';

export type AuthState = {
  error: StoreDefaultState['error'];
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  tokens: {
    idToken: string | null;
    accessToken: string | null;
  };
  isLoggingOut: boolean;
};

export const INITIAL_STATE: AuthState = {
  error: undefined,
  isAuthenticated: false,
  isAuthenticating: false,
  isLoggingOut: false,
  tokens: {
    idToken: null,
    accessToken: null,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    authCheckStart: (state, _action: PayloadAction<AuthCheckStartPayload>) => {
      state.error = undefined;
      state.isAuthenticating = true;
    },
    authCheckSuccess: (state, { payload }: PayloadAction<AuthCheckSuccessPayload>) => {
      state.isAuthenticated = true;
      state.isAuthenticating = false;
      state.error = undefined;
      state.tokens.idToken = payload.idToken;
      state.tokens.accessToken = payload.accessToken;
    },
    authCheckFail: (state, { payload }: PayloadAction<AuthCheckFailPayload>) => {
      state.isAuthenticated = false;
      state.isAuthenticating = false;
      state.error = payload.error;
    },
    authLogoutStart: state => {
      state.error = undefined;
      state.isAuthenticated = false;
      state.tokens.accessToken = null;
      state.tokens.idToken = null;
      state.isLoggingOut = true;
    },
    authLogoutSuccess: state => {
      state.error = undefined;
      state.isAuthenticated = false;
      state.tokens.accessToken = null;
      state.tokens.idToken = null;
      state.isLoggingOut = false;
    },
    authLogoutFail: (state, { payload }: PayloadAction<AuthLogoutFailPayload>) => {
      state.error = payload.error;
      state.isAuthenticated = false;
      state.tokens.accessToken = null;
      state.tokens.idToken = null;
      state.isLoggingOut = false;
    },
  },
});

export const {
  authCheckStart,
  authCheckSuccess,
  authCheckFail,
  authLogoutStart,
  authLogoutSuccess,
  authLogoutFail,
} = authSlice.actions;

export default authSlice.reducer;
