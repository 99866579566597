import React, { PureComponent } from 'react';

import { components } from 'react-select';

import { Checkbox } from 'components/@inputs';

import { CheckboxLabel, CheckboxWrap } from './CheckboxOption.styled';

class CheckboxOption extends PureComponent {
  render() {
    const { isSelected, label } = this.props;
    return (
      <components.Option
        {...this.props}
        innerProps={{ ...this.props.innerProps, 'data-test-id': 'select-option-checkbox' }}
      >
        <CheckboxWrap>
          <Checkbox checked={isSelected} />
          <CheckboxLabel>{label}</CheckboxLabel>
        </CheckboxWrap>
      </components.Option>
    );
  }
}
export default CheckboxOption;
