import React from 'react';

import NoSSR from '@material-ui/core/NoSsr';

import Router from 'pages/Router';

import Page from 'components/@layout/Page';
import { PageLoadingPlaceholder } from 'components/Placeholders';

import withConnectors from './connectors';

const App: React.FC<{}> = () => {
  return (
    <NoSSR fallback={<PageLoadingPlaceholder withText={false} />}>
      <Page>
        <Router />
      </Page>
    </NoSSR>
  );
};

export default withConnectors(App);
