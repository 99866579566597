import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { AffiliationPolicy } from 'store/affiliationSubmission/affiliationSubmission.types';

import DateFormat from 'components/Date';

interface Props {
  affiliation: AffiliationPolicy;
}

const AffiliationCardContent: React.FC<Props> = ({ affiliation }) => (
  <>
    <Box>
      <Typography component="span" variant="h5" color="secondary">
        {affiliation?.attributes?.policyNumber}
      </Typography>
    </Box>
    <Typography component="div" variant="subtitle2" color="textSecondary">
      {affiliation?.attributes?.title}
    </Typography>
    {affiliation?.attributes?.minAffiliationStartDate && (
      <Box fontSize={14} component="span" fontWeight={700} lineHeight={1.5}>
        <FormattedMessage
          id="affiliations.add.step.affiliation.min_start_date_label"
          values={{
            date: <DateFormat date={affiliation?.attributes?.minAffiliationStartDate} />,
          }}
        />
      </Box>
    )}
  </>
);

export default AffiliationCardContent;
