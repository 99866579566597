import { createReducer } from 'redux-act';

import {
  blockNavigationGo,
  blockNavigationStart,
  blockNavigationStay,
} from './blockNavigation.actions';

export type BlockNavigationState = {
  navigationIsBlocked: boolean;
};

export const INITIAL_STATE = {
  navigationIsBlocked: false,
};

const reducer = createReducer<BlockNavigationState>({}, INITIAL_STATE);

reducer.on(blockNavigationStart, state => ({
  ...state,
  navigationIsBlocked: true,
}));

reducer.on(blockNavigationGo, (state, { canLeave }) => ({
  ...state,
  navigationIsBlocked: !canLeave,
}));

reducer.on(blockNavigationStay, state => ({
  ...state,
  navigationIsBlocked: false,
}));

export default reducer;
