import styled from 'styled-components';

export const ValueWrap = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 2px 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  input {
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }
`;

export const MultiValueLabel = styled.div`
  color: ${({ theme, hasValues }) =>
    hasValues ? theme.palette.neutral01[600] : theme.palette.neutral01[500]};
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - ${({ theme }) => theme.spacing(2)}px);
  overflow: hidden;
  display: block;
  align-self: stretch;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
`;

export const ValueCounter = styled.span`
  border-radius: 50%;
  display: inline-flex;
  position: absolute;
  right: 0;
  font-size: 14px;
  font-weight: 700;
  width: ${({ theme }) => theme.spacing(6)}px;
  height: ${({ theme }) => theme.spacing(6)}px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.brand01[300]};
  color: ${({ theme }) => theme.palette.neutral01[50]};
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  top: 50%;
  transform: translateY(-50%);
`;
