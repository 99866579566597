import React, { Fragment } from 'react';

import { useTheme } from '@material-ui/core';
import Reactselect, { ActionMeta } from 'react-select';

import { Label } from 'components/@inputs';
import { IconLoader } from 'components/Loaders';
import { useMedia } from 'components/Media';

import { BottomLabel } from '../Field/Field.styled';
import NativeSelect from '../NativeSelect';
import {
  CheckboxOption,
  DropdownIndicator,
  Empty,
  Menu,
  SingleOption,
  ValueContainer,
} from './components';
import styles, { LoadingComponentWrap } from './Select.styled';
import { Option } from './SelectField';

const LoadingIndicator = () => (
  <LoadingComponentWrap>
    <IconLoader size={18} color="brand01.200" />
  </LoadingComponentWrap>
);

interface SelectProps
  extends Omit<React.ComponentProps<typeof Reactselect>, 'options' | 'value' | 'onChange'> {
  options: Option[];
  value?: Option;
  placeholder?: string;
  onChange?: (option: Option, action?: ActionMeta) => void;
  getOptionValue?: (option: Option) => string;
}

const Select: React.FC<SelectProps> = ({
  label = '',
  components = {},
  options = [],
  placeholder = 'Select...',
  extraStyles = {},
  isMulti = false,
  hasHeader = false,
  onChange = () => {},
  withInputHighlight = false,
  openMenuOnFocus = true,
  isOptional = false,
  fallbackOnNative = false,
  getOptionValue = option => option?.value,
  customHeader,
  clearValueClick,
  value,
  hasError,
  bottomLabel,
  withHighlight,
  isLoading,
  isDisabled,
  variant,
  ...rest
}) => {
  const media = useMedia();
  const theme = useTheme();
  if (typeof window === 'undefined') return null;

  if (media.isTouchDevice && fallbackOnNative && !isMulti) {
    return (
      <NativeSelect
        options={options}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        getOptionValue={getOptionValue}
        isDisabled={isDisabled}
        isOptional={isOptional}
        label={label}
        hasError={hasError}
        variant={variant}
      />
    );
  }
  return (
    <Fragment>
      {label && (
        <Label isOptional={isOptional} hasError={hasError} hasValue={!!value}>
          {label}
        </Label>
      )}
      <Reactselect
        backspaceRemovesValue={false}
        {...rest}
        styles={{ ...styles, ...extraStyles }}
        options={options}
        isLoading={isLoading}
        isDisabled={isDisabled}
        placeholder={placeholder}
        closeMenuOnSelect={!isMulti}
        variant={variant}
        blurInputOnSelect={!isMulti}
        hideSelectedOptions={false}
        customHeader={customHeader}
        customTheme={theme}
        withInputHighlight={withInputHighlight}
        hasHeader={hasHeader}
        clearValueClick={clearValueClick}
        withHighlight={withHighlight}
        isMulti={isMulti}
        value={value}
        onChange={(option, action) => onChange(option as Option, action)}
        getOptionValue={getOptionValue}
        openMenuOnFocus={openMenuOnFocus}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: isLoading ? null : DropdownIndicator,
          LoadingIndicator,
          NoOptionsMessage: Empty,
          ClearIndicator: () => null,
          MenuList: Menu,
          ValueContainer,
          Option: isMulti ? CheckboxOption : SingleOption,
          ...components,
        }}
      />
      {bottomLabel && <BottomLabel error={hasError}>{bottomLabel}</BottomLabel>}
    </Fragment>
  );
};

export default Select;
