import React from 'react';

import { FormattedMessage } from 'react-intl';

import { LinkButton } from 'components/@common/Link';
import Icon from 'components/Icon';
import { UIcoCheck, UIcoUpload } from 'components/Icons';
import { IconLoader } from 'components/Loaders';

import { Props as FileProps } from './File';
import { ActionWrap, SuccessIconWrap, UploadIconWrap } from './File.styled';

type Props = FileProps;

const FileActions: React.FC<Props> = ({
  file,
  showRetryButton = true,
  onRetryUploadClick = () => {},
  onDeleteFileClick = () => {},
  errorLabel = 'general.label.retry_upload',
  progress = 0,
  type = 'default',
}) => {
  const typeActionMap = {
    success: (
      <SuccessIconWrap>
        <Icon color="neutral01.50" size={24}>
          <UIcoCheck />
        </Icon>
      </SuccessIconWrap>
    ),
    error: showRetryButton ? (
      <LinkButton onClick={() => onRetryUploadClick(file)} color="brand01.300">
        <FormattedMessage id={errorLabel} />
      </LinkButton>
    ) : null,
    uploading: (
      <UploadIconWrap progress={progress}>
        <IconLoader>
          <UIcoUpload />
        </IconLoader>
      </UploadIconWrap>
    ),
    default: (
      <LinkButton onClick={() => onDeleteFileClick(file)} color="brand01.300">
        <FormattedMessage id="general.label.delete" />
      </LinkButton>
    ),
  };
  return <ActionWrap>{typeActionMap[type]}</ActionWrap>;
};

export default FileActions;
