import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { LinkButton } from 'components/@common/Link';

import { ChipStyled, LinkWrap, TagListWrap, TagValue } from './TagList.styled';
import TagListLoader from './TagListLoader';

export interface TagListProps {
  tags?: {
    id: string;
    value: React.ReactNode;
  }[];
  maxTagsVisible?: number;
  isLoading?: boolean;
  spacing?: number;
  onTagClick?: (id: string) => void;
  emptyPlaceholder?: React.ReactNode;
}

const TagList: React.FC<TagListProps> = ({
  tags = [],
  maxTagsVisible = 10,
  spacing = 4,
  isLoading,
  onTagClick,
  emptyPlaceholder,
}) => {
  const [allTagsShown, setAllTagsShown] = useState(false);
  const visibleTags = !allTagsShown ? tags.slice(0, maxTagsVisible) : tags;
  const toggleShowAll = () => {
    setAllTagsShown(!allTagsShown);
  };
  const handleTagClick = (id: string) => () => {
    if (onTagClick) {
      onTagClick(id);
    }
  };
  return (
    <>
      <TagListWrap spacing={spacing}>
        {!isLoading ? (
          visibleTags.map(tag => (
            <ChipStyled
              key={tag.id}
              onClick={handleTagClick(tag.id)}
              spacing={spacing}
              withIcon={false}
            >
              <TagValue>{tag.value}</TagValue>
            </ChipStyled>
          ))
        ) : (
          <TagListLoader spacing={spacing} />
        )}
      </TagListWrap>
      {!isLoading && maxTagsVisible < tags.length && (
        <LinkWrap>
          <LinkButton onClick={toggleShowAll}>
            <FormattedMessage
              id={`clients.details.client_tags.show_${!allTagsShown ? 'all' : 'less'}`}
            />
          </LinkButton>
        </LinkWrap>
      )}
      {!isLoading && tags.length === 0 && emptyPlaceholder && <> {emptyPlaceholder} </>}
    </>
  );
};

export default TagList;
