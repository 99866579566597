import React, { useEffect, useRef } from 'react';

import { InputBaseProps } from '@material-ui/core';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { ActionMeta } from 'react-select/src/types';

import { getFieldErrors } from 'utils/form';
import { isIntlKeyEmpty } from 'utils/intl.utils';

import Field from 'components/@inputs/Field';

import Select from './Select';

export type Option = {
  label: React.ReactNode;
  value: any;
};

export interface Props extends Omit<InputBaseProps, 'onChange'> {
  name: string;
  label?: string;
  options: Option[];
  getOptionValue?: (option: Option) => string;
  getOptionLabel?: (option: Option) => void;
  fallbackOnNative?: boolean;
  menuPortalTarget?: HTMLElement | null;
  bottomLabel?: string;
  isRequired?: boolean;
  isLoading?: boolean;
  hasHeader?: boolean;
  isMulti?: boolean;
  menuIsOpen?: boolean;
  onChange?: (option: Option, action?: ActionMeta) => void;
  shouldReset?: (value: Option) => boolean;
  isSearchable?: boolean;
}

const SelectField: React.FC<Props> = props => {
  const {
    name,
    label,
    bottomLabel,
    placeholder,
    disabled,
    fullWidth,
    isRequired,
    options = [],
    shouldReset = () => false,
    ...rest
  } = props;
  const intl = useIntl();
  const [field, meta, helpers] = useField(name);
  const errors = getFieldErrors(name, meta);
  const reset = shouldReset(field.value);
  const helpersRef = useRef(helpers); // we need a ref here because helpers from formik changes very often

  useEffect(() => {
    if (reset) {
      helpersRef.current.setValue(null);
    }
  }, [reset, helpersRef]);

  const handleChange: Props['onChange'] = e => {
    helpers.setValue(e);
    helpers.setError(undefined);
  };
  const val = (Array.isArray(field.value) ? field.value : [field.value]).filter(Boolean);

  return (
    <Field
      label={label}
      errors={errors}
      bottomLabel={bottomLabel}
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
    >
      <Select
        id={name}
        isDisabled={disabled}
        error={errors.length > 0}
        fallbackOnNative
        menuPortalTarget={document.body}
        placeholder={
          isIntlKeyEmpty(intl, placeholder) ? placeholder : intl.formatMessage({ id: placeholder })
        }
        options={options}
        {...field}
        {...rest}
        name={name}
        value={field.value || null}
        variant={val.length > 0 ? 'filled' : undefined}
        onBlur={() => {}}
        onChange={(option, action) => handleChange(option, action)}
        data-test-id={name}
      />
    </Field>
  );
};

export default SelectField;
