import styled, { css } from 'styled-components';

export const DatePickerNavWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const hoverStyle = css`
  svg {
    color: ${({ theme }) => theme.palette.brand01[400]};
  }
`;

export const NavigationButton = styled.button<{ isLeft?: boolean }>`
  border: 0;
  position: absolute;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  background: 0;
  color: ${({ theme }) => theme.palette.brand01[300]};
  svg {
    color: ${({ theme }) => theme.palette.brand01[300]};
  }
  ${({ isLeft }) => (isLeft ? 'left' : 'right')}: 0;
  &:hover,
  &:active {
    ${hoverStyle};
  }
`;
