import React from 'react';

import { InputAdornment, InputProps } from '@material-ui/core';

import Icon from 'components/Icon';
import { UIcoSluiten, UIcoZoek } from 'components/Icons';

import { Input } from './TextInput.styled';

export type Props = InputProps & {
  maxChars?: number;
  value?: string;
  variant?: 'filled';
  fieldType?: 'search';
  onClear?: (e: React.MouseEvent) => void;
};

const TextInput: React.FC<Props> = ({
  endAdornment,
  startAdornment,
  maxChars,
  value = '',
  fieldType,
  onClear = () => {},
  ...rest
}) => {
  if (fieldType === 'search') {
    return (
      <Input
        {...rest}
        value={value}
        startAdornment={
          <Icon color="brand01.600" spacing={8}>
            <UIcoZoek focusable="false" />
          </Icon>
        }
        endAdornment={
          value && (
            <Icon color="brand01.600" onClick={onClear} cursor="pointer">
              <UIcoSluiten focusable="false" />
            </Icon>
          )
        }
      />
    );
  }
  return (
    <Input
      startAdornment={
        startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>
      }
      endAdornment={endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>}
      {...rest}
      value={value}
    />
  );
};

export default TextInput;
