import React, { useCallback, useEffect, useRef } from 'react';

import { FormikValues, useFormikContext } from 'formik';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';

import { removeEmptyKeysFromObject } from 'utils/helpers';

interface Props {
  debounce?: number;
  debug?: boolean;
}

const FormikAutoSave: React.FC<Props> = ({ debounce = 200, debug = false }) => {
  const formik = useFormikContext<FormikValues>();
  const { setSubmitting } = formik;
  const setSubmittingRef = useRef(setSubmitting);
  const values = removeEmptyKeysFromObject(formik.values);
  const [lastSaved, setLastSaved] = React.useState<string | null>(null);
  const prevValues = useRef<FormikValues>(removeEmptyKeysFromObject(formik.initialValues));
  const debouncedSubmit = useCallback(
    _debounce(() => {
      formik.submitForm().then(() => {
        setLastSaved(new Date().toISOString());
        formik.setSubmitting(false);
      });
    }, debounce),
    [debounce, formik.submitForm],
  );

  useEffect(() => {
    if (!_isEqual(prevValues.current, values)) {
      setSubmittingRef.current(true);
      debouncedSubmit();
    }
  }, [debouncedSubmit, values]);

  useEffect(() => {
    prevValues.current = values;
  }, [values]);

  if (!debug) {
    return null;
  }

  if (formik.isSubmitting) {
    return <>Saving...</>;
  }

  if (lastSaved !== null) {
    return <>{`Last Saved: ${lastSaved}`}</>;
  }

  return null;
};

export default FormikAutoSave;
