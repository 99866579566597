import React from 'react';

import { FormattedMessage } from 'react-intl';

import AffiliationCardSalary from 'pages/affiliations/Detail/components/Details/AffiliationsData/AffiliationCard/AffiliationCardSalary';

import { SalaryRemoveDialogSummary } from './SalaryRemoveDialog.styled';

const SalaryRemoveDialog = ({ affiliationPolicy, salary }) => {
  return (
    <>
      <FormattedMessage id="affiliation.salary_remove_dialog.text" />
      <SalaryRemoveDialogSummary>
        <AffiliationCardSalary affiliationPolicy={affiliationPolicy} salary={salary} />
      </SalaryRemoveDialogSummary>
    </>
  );
};

export default SalaryRemoveDialog;
