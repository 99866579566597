import { OktaToken, OktaTokenResponseType } from '@okta/okta-auth-js';
import cookie from 'js-cookie';
import { call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects';

import { authService } from 'services';
import { blockNavigationStart } from 'store/blockNavigation/blockNavigation.actions';
import { getImpersonate } from 'store/impersonate/impersonate.selectors';
import { getTranslation } from 'store/intl/intl.selectors';
import { ErrorTrace } from 'types/store/store.types';

import {
  authCheckFail,
  authCheckStart,
  authCheckSuccess,
  authLogoutFail,
  authLogoutStart,
  authLogoutSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './auth.slice';

const AUTH_CODE_GRANT_TYPE = 'authorization_code';
const IMPLICIT_GRANT_TYPE = 'implicit';

export type GrantTypes = typeof AUTH_CODE_GRANT_TYPE | typeof IMPLICIT_GRANT_TYPE;

const responseTypes: {
  [AUTH_CODE_GRANT_TYPE]: OktaTokenResponseType;
  [IMPLICIT_GRANT_TYPE]: OktaTokenResponseType[];
} = {
  [AUTH_CODE_GRANT_TYPE]: 'code',
  [IMPLICIT_GRANT_TYPE]: ['id_token', 'token'],
};

// Saga implementation from https://github.com/dogeared/okta-auth-js-pkce-example/blob/master/src/auth/index.js

export function* validateAccess(action?: ReturnType<typeof authCheckStart>) {
  const { resolve = () => {}, reject = () => {} } = action?.payload || {};
  try {
    const oktaAuth = authService?.okta?._oktaAuth;
    if (!oktaAuth) {
      throw new Error('no auth instance available');
    }
    const idTokenObj = yield call(getIdToken);
    const accessTokenObj = yield call(getAccessToken);

    if (idTokenObj) {
      yield put(
        authCheckSuccess({ accessToken: accessTokenObj.accessToken, idToken: idTokenObj.idToken }),
      );
      yield call(resolve, {
        isAuthenticated: true,
        accessToken: accessTokenObj.accessToken,
      });
    } else {
      if (window.location.href.includes('/auth/callback')) {
        yield call(callback);
        const newAccessTokenObj = yield call(getAccessToken);
        const newIdTokenObj = yield call(getIdToken);
        yield put(
          authCheckSuccess({
            accessToken: newAccessTokenObj.accessToken,
            idToken: newIdTokenObj.idToken,
          }),
        );

        yield call(resolve, {
          isAuthenticated: true,
          accessToken: newAccessTokenObj.accessToken,
        });
      } else {
        oktaAuth.tokenManager.clear();
        // implicit or pkce?
        const grantType = oktaAuth.options.pkce ? AUTH_CODE_GRANT_TYPE : IMPLICIT_GRANT_TYPE;
        yield call(loginOkta, grantType);
      }
    }
  } catch (error) {
    yield put(authCheckFail({ error }));
    yield call(reject);
    throw error;
  }
}

export function loginOkta(grantType: GrantTypes) {
  const oktaAuth = authService.okta._oktaAuth;
  authService.okta.setFromUri(`${window.location.pathname}${window.location.search}`);
  oktaAuth.token.getWithRedirect({
    responseType: responseTypes[grantType],
    scopes: ['openid', 'profile', 'email'],
  });
}

export function* logout() {
  const oktaAuth = authService.okta;
  const impersonate = yield select(getImpersonate);
  try {
    if (!impersonate.impersonating) {
      yield call(cookie.remove, 'NSC_TMAS');
      oktaAuth._oktaAuth.tokenManager.clear();
      yield put(authLogoutSuccess());
      // oktaAuth._oktaAuth.session.close does the call to end the remote sessions: DELETE call to: https://login2.test.vanbreda.be/api/v1/sessions/me
      yield call(oktaAuth._oktaAuth.session.close);
      yield call(validateAccess);
    } else {
      const leaveMessage = yield select(getTranslation, 'logout.impersonate.leave_warning.text');
      yield put(
        blockNavigationStart({
          message: leaveMessage,
          type: 'alert',
        }),
      );
    }
  } catch (error) {
    yield put(authLogoutFail({ error }));
  }
}

export function* callback() {
  // detect code
  const oktaAuth = authService.okta._oktaAuth;
  try {
    const tokens: OktaToken[] = yield call(oktaAuth.token.parseFromUrl);
    tokens.forEach(token => {
      if (token.idToken) {
        oktaAuth.tokenManager.add('id_token', token);
      } else if (token.accessToken) {
        oktaAuth.tokenManager.add('access_token', token);
      }
    });
  } catch (error) {
    window.history.pushState({}, '', '/');
    let errorTrace: ErrorTrace;
    errorTrace = {
      name: 'auth',
      message: error.message,
      code: error.errorCode,
    };

    throw errorTrace;
  }
}

function* getIdToken() {
  const oktaAuth = authService.okta._oktaAuth;
  const token: SagaReturnType<typeof oktaAuth.tokenManager.get> = yield call(
    oktaAuth.tokenManager.get,
    'id_token',
  );
  return token;
}

export function* getAccessToken() {
  const oktaAuth = authService.okta._oktaAuth;
  const token: SagaReturnType<typeof oktaAuth.tokenManager.get> = yield call(
    oktaAuth.tokenManager.get,
    'access_token',
  );
  return token;
}

// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

function* authSaga() {
  yield takeLatest(authCheckStart, validateAccess);
  yield takeLatest(authLogoutStart, logout);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default authSaga;
