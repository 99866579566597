import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Wrap = styled(Box)<{ height?: number; width?: number }>`
  overflow-y: auto;
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.neutral01[50]};
`;
