import _get from 'lodash/get';
import styled from 'styled-components';
interface TitleProps {
  centered?: boolean;
  marginBottom?: number;
  color?: string;
}

const DEFAULT_COLOR = 'neutral01.600';

export const H1 = styled.h1<TitleProps>`
  font-size: 56px;
  line-height: 1.13;
  font-weight: 700;
  ${({ color, theme }) => `color: ${_get(theme.palette, color || DEFAULT_COLOR)}`};
  ${({ centered }) => (centered ? 'text-align: center' : '')};
  margin: 0;
  ${({ marginBottom }) => (marginBottom && `margin-bottom: ${marginBottom}px`) || ''};
`;
export const H2 = styled.h2<TitleProps>`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.33;
  ${({ color, theme }) => `color: ${_get(theme.palette, color || DEFAULT_COLOR)}`};
  ${({ centered }) => (centered ? 'text-align: center' : '')};
  margin: 0;
  ${({ marginBottom }) => (marginBottom && `margin-bottom: ${marginBottom}px`) || ''};
`;

export const H3 = styled.h3<TitleProps>`
  font-weight: 700;
  font-size: 24px;
  line-height: 1.4;
  ${({ color, theme }) => `color: ${_get(theme.palette, color || DEFAULT_COLOR)}`};
  ${({ centered }) => (centered ? 'text-align: center' : '')};
  margin: 0;
  line-height: 1;
  ${({ marginBottom }) => (marginBottom && `margin-bottom: ${marginBottom}px`) || ''};
`;

export const H4 = styled.h4<TitleProps>`
  line-height: 1.5;
  font-weight: 700;
  ${({ color, theme }) => `color: ${_get(theme.palette, color || DEFAULT_COLOR)}`};
  ${({ centered }) => (centered ? 'text-align: center' : '')};
  font-size: 20px;
  margin: 0;
  ${({ marginBottom }) => (marginBottom && `margin-bottom: ${marginBottom}px`) || ''};
`;

export const H5 = styled.h5<TitleProps>`
  line-height: 1.5;
  font-weight: 700;
  font-size: 16px;
  ${({ color, theme }) => `color: ${_get(theme.palette, color || DEFAULT_COLOR)}`};
  ${({ centered }) => (centered ? 'text-align: center' : '')};
  margin: 0;
  ${({ marginBottom }) => (marginBottom && `margin-bottom: ${marginBottom}px`) || ''};
`;

export const H6 = styled.h6<TitleProps>`
  line-height: 1.5;
  font-weight: 700;
  font-size: 12px;
  ${({ color, theme }) => `color: ${_get(theme.palette, color || 'neutral01.600')}`};
  ${({ centered }) => (centered ? 'text-align: center' : '')};
  margin: 0;
  ${({ marginBottom }) => (marginBottom && `margin-bottom: ${marginBottom}px`) || ''};
`;
