import { transparentize } from 'polished';
import styled from 'styled-components';

import { H2 } from '../titles.styled';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  min-height: ${({ theme }) => theme.spacing(20)}px;
  padding: ${({ theme }) => theme.spacing(4)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 0 ${({ theme }) => theme.spacing(4)}px 0;
  }
`;

export const SubTitle = styled.div`
  color: ${({ theme }) => theme.palette.neutral01[600]};
  line-height: 1.5;
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const H2Styled = styled(H2)`
  padding-right: ${({ theme }) => theme.spacing(4)}px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TitleInner = styled.div`
  overflow: hidden;
  white-space: nowrap;
`;

export const IconWrap = styled.div`
  min-width: ${({ theme }) => theme.spacing(24)}px;
`;

export const RightContent = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.mixins.headerHeight}px;
  left: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)}px;
  background: ${({ theme }) => theme.palette.neutral01[50]};
  z-index: 5;
  box-shadow: 0px -4px 5px ${({ theme }) => transparentize(0.6, theme.palette.neutral01[300])};
  & button {
    min-width: 100%;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: auto;
    position: static;
    background: 0;
    box-shadow: none;
    padding: 0;
    width: auto;
    bottom: 0;
    & button {
      min-width: 0;
    }
  }
`;

export default null;
