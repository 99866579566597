import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

const focusStyle = css`
  background-color: ${({ theme }) => transparentize(0.97, theme.palette.brand01[400])};
  color: ${({ theme }) => theme.palette.brand01[500]} !important;
`;

export const MenuItemWrap = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral01[300]};
  padding: ${({ theme }) => ` ${theme.spacing(2)}px ${theme.spacing(4)}px`};
  cursor: pointer;
  ${({ selected }) => (selected ? focusStyle : '')};
`;

export const MenuHeaderWrap = styled.div`
  padding: ${({ theme }) =>
    ` ${theme.spacing(3)}px ${theme.spacing(4)}px  ${theme.spacing(2)}px  ${theme.spacing(4)}px`};
`;

export const DropdownFooter = styled.div`
  background: ${({ theme }) => transparentize(0.8, theme.palette.neutral01[300])};
  padding: ${({ theme }) => ` ${theme.spacing(2)}px ${theme.spacing(4)}px`};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.palette.neutral01[500]};
`;
