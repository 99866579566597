import { useDispatch } from 'react-redux';

import { addNotification as addNotificationAction } from 'store/notifications/notifications.actions';

import { NotificationProps } from 'components/Notification/Notification';

const useNotification = () => {
  const dispatch = useDispatch();
  const addNotification = (options: NotificationProps) => {
    dispatch(addNotificationAction(options));
  };

  return { addNotification };
};

export default useNotification;
