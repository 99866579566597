import 'dayjs/locale/nl-be';
import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import _get from 'lodash/get';
import DayPicker from 'react-day-picker';
import { FormattedMessage, useIntl } from 'react-intl';

import { DEFAULT_DATE_FORMAT } from 'constants/store';

import { isValidDate } from 'utils/helpers';

import Button from 'components/@common/Button';
import Field from 'components/@inputs/Field';
import FormatNumber from 'components/FormatNumber';
import Tooltip from 'components/Tooltip';

import { DatePickerEndAdornment } from './components';
import DatePicker, { DatePickerProps, DatePickerSelectedDate } from './DatePicker';
import { DatePickerInputWrap, InputStyled } from './DatePicker.styled';

enum ChangeEventType {
  DATE = 'DATE_SELECT',
  INPUT = 'INPUT',
}

interface DatePickerInputState {
  month?: Date | null;
  inputTyped?: string;
  isDatePickerOpen?: boolean;
  value: DatePickerInputSelectedDate;
  error: string;
}

export interface DatePickerInputSelectedDate {
  from: Date | string | null;
  to: Date | string | null;
}

export interface DatePickerInputProps extends Omit<DatePickerProps, 'locale' | 'selectedDate'> {
  id?: string;
  disabled?: boolean;
  placement?: string;
  disableBefore?: Date;
  withFooter?: boolean;
  disableAfter?: Date;
  placeholder?: string;
  isRequired?: boolean;
  value?: DatePickerInputSelectedDate;
  onDayClick?: (day: DatePickerInputSelectedDate) => void;
  label?: string;
  isRangeSelect?: boolean;
  hasError?: boolean;
  bottomLabel?: string;
  isFillable?: boolean;
  name?: string;
  onDateSelected?: (params: DatePickerInputState) => void;
  backdropProps?: any;
}

const DatePickerInput: React.FC<DatePickerInputProps> = ({
  id,
  isFillable,
  hasError,
  bottomLabel,
  label,
  disableAfter,
  disableBefore,
  numberOfMonths,
  disabled,
  onDateSelected = () => {},
  onDayClick = () => {},
  disableDays = [],
  value: valProp = {
    from: null,
    to: null,
  },
  name,
  withFooter = true,
  isRangeSelect = true,
  placeholder = '',
  placement = 'bottom-end',
  backdropProps = {},
  currentMonthViewed,
  isRequired,
  ...rest
}) => {
  const intl = useIntl();
  const getInputValue = (
    dateFrom: DatePickerInputSelectedDate['from'],
    dateTo: DatePickerInputSelectedDate['to'],
  ) => {
    if (!dateFrom) return '';
    let from = dateFrom;
    let to = dateTo;

    if (from && typeof dateFrom === 'string') from = dayjs(from).toDate();
    if (to && typeof dateTo === 'string') to = dayjs(to).toDate();
    if (!isValidDate(from)) dateFrom = null;
    if (!isValidDate(to)) dateTo = null;
    if (!to || from >= to) {
      return `${dayjs(from).format(DEFAULT_DATE_FORMAT)}`;
    }
    return `${dayjs(from).format(DEFAULT_DATE_FORMAT)} - ${dayjs(to).format(DEFAULT_DATE_FORMAT)}`;
  };

  const [month, setMonth] = useState<Date | undefined | null>(
    (valProp.from ? dayjs(valProp.from).toDate() : undefined) ||
      (currentMonthViewed ? dayjs(currentMonthViewed).toDate() : undefined),
  );

  useEffect(() => {
    setMonth(
      (valProp.from ? dayjs(valProp.from).toDate() : undefined) ||
        (currentMonthViewed ? dayjs(currentMonthViewed).toDate() : undefined),
    );
  }, [currentMonthViewed, valProp.from]);

  const [inputTyped, setInputTyped] = useState(getInputValue(valProp.from, valProp.to));
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [value, setValue] = useState(valProp);
  const [error, setError] = useState('');

  useEffect(() => {
    const propDateString = getInputValue(valProp.from, valProp.to);
    const stateDateString = getInputValue(value.from, value.to);

    if (propDateString !== stateDateString) {
      setInputTyped(propDateString);
    }
  }, [valProp, value]);

  const getDatePickerValue = (): DatePickerSelectedDate => {
    const { from, to } = value;
    let dateFrom = from ? from : null;
    let dateTo = to ? to : null;

    if (dateFrom && typeof dateFrom === 'string') dateFrom = dayjs(dateFrom).toDate();
    if (dateTo && typeof dateTo === 'string') dateTo = dayjs(dateTo).toDate();

    if (!isValidDate(dateFrom)) dateFrom = null;
    if (!isValidDate(dateTo)) dateTo = null;

    return { from: dateFrom as Date | null, to: dateTo as Date | null };
  };

  const handleDatePickerOpen = () => {
    setIsDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
  };

  const checkInputForDate = (input?: string) => {
    if (!input) {
      return {
        isValid: false,
        date: new Date(),
      };
    }
    const splitted = input.split('/');
    const day = _get(splitted, '0');
    const month = _get(splitted, '1');
    const year = _get(splitted, '2');

    return {
      isValid: ![day, month, year].some(str => (str ? str.indexOf('_') >= 0 : true)),
      date: dayjs(`${year}-${month}-${day}`).toDate(),
    };
  };

  const disabledDateSelected = (day: Date) => {
    const obj = {
      disableDays: disableDays.some(
        (disableDay: string) =>
          disableDay && DayPicker.DateUtils.isSameDay(day, dayjs(disableDay).toDate()),
      ),
      after: disableAfter
        ? DayPicker.DateUtils.isDayAfter(day, dayjs(disableAfter).toDate())
        : false,
      before: disableBefore
        ? DayPicker.DateUtils.isDayBefore(day, dayjs(disableBefore).toDate())
        : false,
    };
    let disabled = false;
    if (obj.after || obj.before || obj.disableDays) {
      disabled = true;
      setError('general.errors.invalid_date_disabled');
    }
    return disabled;
  };

  const handleChange = (type: ChangeEventType) => (event: any = {}) => {
    setError('');
    if (type === ChangeEventType.DATE) {
      // type assertion because we know the event comes from an input
      const evt = event as DatePickerInputSelectedDate;
      const newValue = { ...value, ...evt };

      const newMonth = newValue.from ? dayjs(newValue.from).toDate() : undefined;
      const newInput = getInputValue(newValue.from, newValue.to);
      setValue(newValue);
      setInputTyped(newInput);
      setMonth(newMonth);
      onDayClick(newValue);
      if (!isRangeSelect) {
        handleDatePickerClose();
      }
    }

    if (type === ChangeEventType.INPUT && event.target instanceof HTMLInputElement) {
      // type assertion because we know the event comes from an input
      const evt = event as React.ChangeEvent<HTMLInputElement>;
      const inputVal = evt.target.value;
      const inputChecked = checkInputForDate(inputVal);
      let dateFrom = null;
      if (inputChecked.isValid) {
        dateFrom = inputChecked.date;
        if (dateFrom.toString() === 'Invalid Date' || disabledDateSelected(dateFrom)) {
          dateFrom = null;
        }
        if (!isRangeSelect) {
          handleDatePickerClose();
        }
      }
      const newValue = { from: dateFrom, to: dateFrom };
      setInputTyped(inputVal);
      setMonth(dateFrom);
      setValue(newValue);
      onDayClick(newValue);
    }
  };

  const limit = (val: string, max: string) => {
    let newVal = val;
    if (val.length === 1 && val[0] > max[0]) {
      newVal = `0${newVal}`;
    }

    if (val.length === 2) {
      if (Number(val) === 0) {
        newVal = '01';
      } else if (val > max) {
        newVal = max;
      }
    }

    return newVal;
  };

  const dateFormat = (val: string) => {
    const day = limit(val.substring(0, 2), '31');
    const month = limit(val.substring(2, 4), '12');
    const year = val.substring(4, 8);

    return (
      (day.length ? `${day.padEnd(2, '_')}/` : '__/') +
      (month.length ? `${month.padEnd(2, '_')}/` : '__/') +
      (year.length ? `${year.padEnd(4, '_')}` : '_____')
    );
  };

  const handleYearMonthChange = (month: Date) => {
    setMonth(month);
  };

  const handleBlur = () => {
    const checkedInput = checkInputForDate(inputTyped);
    if (!checkedInput.isValid) {
      setInputTyped('');
    }
  };

  const Input = isFillable ? FormatNumber : InputStyled;
  const extraProps: React.ComponentProps<typeof Input> = isFillable
    ? {
        displayType: 'input',
        customInput: InputStyled,
        format: isFillable ? dateFormat : '',
      }
    : {};
  return (
    <DatePickerInputWrap>
      <Tooltip
        backdropOpen={isDatePickerOpen}
        interactive
        onBackdropClick={handleDatePickerClose}
        placement={placement}
        backdropProps={backdropProps}
        isOverflowVisible
        title={
          <DatePicker
            currentMonthViewed={month}
            locale={intl.locale}
            selectedDate={getDatePickerValue()}
            onDayClick={handleChange(ChangeEventType.DATE)}
            isRangeSelect={isRangeSelect}
            disableDays={disableDays}
            disableAfter={disableAfter}
            disableBefore={disableBefore}
            onYearMonthChange={handleYearMonthChange}
            footerContent={
              isRangeSelect &&
              withFooter && (
                <Button
                  data-test-id="datepicker-dropdown-option-button"
                  onClick={() => {
                    handleDatePickerClose();
                    onDateSelected({ isDatePickerOpen, value, inputTyped, month, error });
                  }}
                  fullWidth={!numberOfMonths || numberOfMonths === 1}
                >
                  <FormattedMessage id="general.datepicker.apply" />
                </Button>
              )
            }
            {...rest}
          />
        }
        open={isDatePickerOpen}
      >
        <div>
          <Field
            name={name || ''}
            label={label}
            errors={error ? [{ id: error }] : undefined}
            bottomLabel={bottomLabel}
            isRequired={isRequired}
            fullWidth
          >
            <Input
              autoComplete="off"
              data-test-id={`${name}_input`}
              placeholder={placeholder}
              value={inputTyped}
              variant={inputTyped && !isDatePickerOpen ? 'filled' : undefined}
              onBlur={handleBlur}
              fullWidth
              onChange={handleChange(ChangeEventType.INPUT)}
              readOnly={!isFillable}
              onFocus={handleDatePickerOpen}
              endAdornment={
                <div onClick={handleDatePickerOpen}>
                  <DatePickerEndAdornment />
                </div>
              }
              number={undefined}
              name={name}
              disabled={disabled}
              id={id}
              {...extraProps}
            />
          </Field>
        </div>
      </Tooltip>
    </DatePickerInputWrap>
  );
};

export default DatePickerInput;
