import React, { useState } from 'react';

import { useField } from 'formik';
import * as Yup from 'yup';

import { FieldError, getFieldErrors } from 'utils/form';

import Field from 'components/@inputs/Field';
import TagInput, { Props as TagInputProps } from 'components/@inputs/TagInput/TagInput';

export interface TagInputFieldProps {
  name: string;
  label?: string;
  bottomLabel?: string;
  isRequired?: boolean;
  withCharCount?: boolean;
  inputValidation?: Yup.Schema<any>;
}

const TagInputField: React.FC<TagInputFieldProps &
  Omit<TagInputProps, 'onInputChange' | 'inputValue' | 'value' | 'onChange' | 'onBlur'>> = ({
  name,
  label,
  bottomLabel,
  placeholder,
  disabled,
  fullWidth = true,
  isRequired,
  inputValidation,
  maxItems,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState<FieldError[] | never[]>([]);
  const errors = getFieldErrors(name, meta);
  const doesNotExceedMax = maxItems ? field.value.length < maxItems : true;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!doesNotExceedMax) return;
    setInputValue(e.target.value);
  };

  const doAction = (type: string, value: string) => {
    if (type === 'ADD') {
      if (!doesNotExceedMax) return;
      const tagSet = new Set([...field.value, value].filter(value => value.trim().length > 0));
      helpers.setValue(Array.from(tagSet));
      setInputValue('');
      setInputError([]);
    }
    if (type === 'REMOVE') {
      setInputError([]);
      helpers.setValue(field.value.filter((chip: string) => chip !== value));
    }
  };

  const handleChange: TagInputProps['onChange'] = (type, value) => {
    if (inputValidation) {
      try {
        inputValidation.validateSync(value, { abortEarly: false, strict: true });
        doAction(type, value);
      } catch (error) {
        if (error.errors) {
          const newMeta = {
            ...meta,
            touched: true,
            error: error.errors[0],
          };
          setInputError(getFieldErrors(name, newMeta));
        }
      }
    } else {
      doAction(type, value);
    }
  };

  const handleBlur = () => {
    setInputValue('');
    setInputError([]);
  };

  const getPlaceholder = () => {
    if (maxItems) {
      return field.value.length >= maxItems ? undefined : placeholder;
    }
    return placeholder;
  };

  return (
    <Field
      label={label}
      errors={[...errors, ...inputError]}
      bottomLabel={bottomLabel}
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
    >
      <TagInput
        id={name}
        disabled={disabled}
        error={inputError.length > 0}
        fullWidth={fullWidth}
        maxItems={maxItems}
        {...field}
        {...rest}
        placeholder={getPlaceholder()}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        data-test-id={name}
      />
    </Field>
  );
};

export default TagInputField;
