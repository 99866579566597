import React from 'react';

import { Box, Tooltip } from '@material-ui/core';

import { ExpertData } from 'services/Api/types/experts.types';

import Avatar from 'components/Avatar';

import ExpertContactInfo from '../ExpertContactInfo';
import { AvatarWrap } from '../Experts.styled';
import ExpertsLoading from '../ExpertsLoading';

interface Props {
  isLoading?: boolean;
  data: ExpertData[];
  inverted: boolean;
  onExpertInfoShow?: (isMobile: boolean, expert: ExpertData) => void;
  onExpertInfoClick?: (infoType: string, id: string) => void;
}

const ExpertsDesktop: React.FC<Props> = ({
  isLoading,
  inverted = false,
  data,
  onExpertInfoShow = () => {},
  onExpertInfoClick = () => {},
}) => {
  const onTooltipOpened = (expert: ExpertData) => () => {
    onExpertInfoShow(false, expert);
  };

  if (isLoading) {
    return <ExpertsLoading />;
  }

  return (
    <Box style={{ cursor: 'pointer' }}>
      {data.map(expert => (
        <AvatarWrap key={expert.id}>
          <Tooltip
            arrow
            interactive
            onOpen={onTooltipOpened(expert)}
            placement="bottom-start"
            title={
              <div>
                <ExpertContactInfo title onExpertInfoClick={onExpertInfoClick} expert={expert} />
              </div>
            }
          >
            <div>
              <Avatar
                title={[expert.attributes.firstName, expert.attributes.lastName]
                  .filter(Boolean)
                  .join(' ')}
                subTitle={expert.attributes.functionName}
                imgSrc={expert.attributes.base64Image}
                inverted={inverted}
                withHover
              />
            </div>
          </Tooltip>
        </AvatarWrap>
      ))}
    </Box>
  );
};

export default ExpertsDesktop;
