import styled from 'styled-components';

export const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
  & > button {
    margin-top: ${({ theme }) => theme.spacing(4)}px;
    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-top: 0;
      &:not(:last-child) {
        margin-right: ${({ theme }) => theme.spacing(4)}px;
      }
    }
  }
`;
