import { sanitizeFilters } from 'hooks/useFilter/useFilter';
import _pick from 'lodash/pick';
import { call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects';

import { QUERY_PARAMS } from 'constants/invoices';
import { analyticsService, apiService } from 'services';
import { getGroupId } from 'store/client/client.selectors';
import { addNotification } from 'store/notifications/notifications.actions';
import { getQuery } from 'store/routing/routing.selectors';

import { downloadFileFromBinaryResponse } from 'utils/helpers';

import { getInvoicesEndpoint } from './invoices.selectors';
import {
  invoicesExportFail,
  invoicesExportStart,
  invoicesExportSuccess,
  invoicesFetchFail,
  invoicesFetchStart,
  invoicesFetchSuccess,
} from './invoices.slice';

function* invoicesFetchFlow(action: ReturnType<typeof invoicesFetchStart>) {
  try {
    const filter = (action.payload && action.payload.filters) || {};
    const path: SagaReturnType<typeof getInvoicesEndpoint> = yield select(getInvoicesEndpoint);
    // pick only the queryparam values defined in constants - according to spec...
    // Because we use groupId for persistent filtering purposes only and this should not be send
    const validFilters = _pick(sanitizeFilters(filter), Object.values(QUERY_PARAMS));
    if (Object.keys(filter).length) {
      analyticsService.events.trackInvoicesFilters(JSON.stringify(validFilters));
    }
    const invoices: SagaReturnType<typeof apiService.invoices.fetchInvoices> = yield call(
      [apiService, apiService.invoices.fetchInvoices],
      path,
      validFilters,
    );

    yield put(invoicesFetchSuccess(invoices));
  } catch (error) {
    yield put(invoicesFetchFail({ error }));
  }
}

function* invoicesExportFetchFlow(action: ReturnType<typeof invoicesExportStart>) {
  try {
    const { filter, fileName } = action.payload;
    const path = yield select(getInvoicesEndpoint);
    const { [QUERY_PARAMS.MAX_PER_PAGE]: maxPerPage, ...filters } = filter;
    const validFilters = _pick(filters, Object.values(QUERY_PARAMS));
    const response: SagaReturnType<typeof apiService.invoices.exportInvoices> = yield call(
      [apiService, apiService.invoices.exportInvoices],
      path,
      validFilters,
    );

    downloadFileFromBinaryResponse(response, fileName);

    yield put(invoicesExportSuccess());
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'invoices.download.error',
      }),
    );
    yield put(invoicesExportFail({ error }));
  }
}

// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

export function* filterInvoices() {
  // is the same as the boot but split it up for tracking and readability
  const currentFilter = yield select(getQuery);
  const groupId = yield select(getGroupId);
  if (groupId) {
    yield put(invoicesFetchStart({ filters: currentFilter }));
  }
}

export function* bootInvoices() {
  const currentFilter = yield select(getQuery);
  const groupId = yield select(getGroupId);
  if (groupId) {
    yield put(invoicesFetchStart({ filters: currentFilter }));
  }
}

function* invoicesSaga() {
  yield takeLatest(invoicesFetchStart, invoicesFetchFlow);
  yield takeLatest(invoicesExportStart, invoicesExportFetchFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default invoicesSaga;
