import React from 'react';

import { Box, DialogActions, DialogContent } from '@material-ui/core';
import { Formik } from 'formik';
import useUpdateClaimWorkResumption from 'hooks/useUpdateClaimWorkResumption/useUpdateClaimWorkResumption';
import _isEqual from 'lodash/isEqual';
import { FormattedMessage } from 'react-intl';

import { analyticsService } from 'services';

import { hasFormErrors } from 'utils/helpers';

import Button from 'components/@common/Button';
import { LinkButton } from 'components/@common/Link';
import { DatePickerInputField } from 'components/@inputs/DatePicker';

import { ValidationSchema } from './WorkResumption.validation';

interface Props {
  initialValues?: FormValues;
  claimId?: string;
  closeModal?: () => void;
}

export interface FormValues {
  workResumptionDate: string;
}

const WorkIncapacityStep: React.FC<Props> = ({
  closeModal = () => {},
  claimId = '',
  initialValues = {
    workResumptionDate: '',
  },
}) => {
  const handleCancel = () => {
    closeModal();
  };

  const { updateWorkResumption, status } = useUpdateClaimWorkResumption(claimId);

  const isUpdating = status === 'loading';

  return (
    <Formik
      validateOnMount
      validationSchema={ValidationSchema}
      onSubmit={(values: FormValues) => {
        analyticsService.events.trackUpdateClaimWorkResumption();
        updateWorkResumption(
          { body: { attributes: values } },
          {
            onSuccess: () => {
              closeModal();
            },
          },
        );
      }}
      initialValues={initialValues}
    >
      {({ values, handleSubmit, errors }) => {
        return (
          <>
            <DialogContent>
              <Box mb={8} maxWidth={400} data-test-id="claim-submission-incapacity-step-container">
                <DatePickerInputField
                  name="workResumptionDate"
                  label="claims.detail.work_resumtion.modal.resumption_date.label"
                  placeholder="general.datepicker_input.placeholder"
                  isFillable
                  fullWidth
                  isRequired
                  isRangeSelect={false}
                  backdropProps={{
                    zIndex: 1499,
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                isLoading={isUpdating}
                type="submit"
                disabled={hasFormErrors(errors) || _isEqual(initialValues, values)}
                onClick={() => handleSubmit()}
              >
                <FormattedMessage id="claims.detail.incapacity_periods.add.modal.update.text" />
              </Button>

              <LinkButton disabled={isUpdating} onClick={handleCancel}>
                <FormattedMessage id="general.label.cancel" />
              </LinkButton>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
};

export default WorkIncapacityStep;
