import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import {
  SharedFileDeleteStartPayload,
  SharedFileDownloadStartPayload,
  SharedFilesFetchStartPayload,
  SharedFilesResponse,
} from './dataShare.types';

export type DataShareState = {
  data?: SharedFilesResponse['data'];
  meta?: SharedFilesResponse['meta'];
  status?: 'loading' | 'downloading' | 'deleting';
  error: StoreDefaultState['error'];
  links?: SharedFilesResponse['links'];
};

export const INITIAL_STATE: DataShareState = {
  data: undefined,
  meta: undefined,
  status: undefined,
  error: undefined,
  links: undefined,
};

const dataShareSlice = createSlice({
  name: 'dataShare',
  initialState: INITIAL_STATE,
  reducers: {
    sharedFilesFetchStart: (state, _action: PayloadAction<SharedFilesFetchStartPayload>) => {
      state.status = 'loading';
      state.error = undefined;
    },
    sharedFilesFetchSuccess: (state, { payload }: PayloadAction<SharedFilesResponse>) => {
      state.status = undefined;
      state.error = undefined;
      state.data = payload.data;
      state.links = payload.links;
      state.meta = payload.meta;
    },
    sharedFilesFetchFail: (
      state,
      { payload }: PayloadAction<{ error: DataShareState['error'] }>,
    ) => {
      state.status = undefined;
      state.error = payload.error;
    },
    downloadSharedFileStart: (state, _action: PayloadAction<SharedFileDownloadStartPayload>) => {
      state.status = 'downloading';
    },
    downloadSharedFileSuccess: state => {
      state.status = undefined;
    },
    downloadSharedFileFail: (state, _action: PayloadAction<{ error: DataShareState['error'] }>) => {
      state.status = undefined;
    },
    deleteSharedFileRequest: (_state, _action: PayloadAction<SharedFileDeleteStartPayload>) => {},
    deleteSharedFileStart: state => {
      state.status = 'deleting';
    },
    deleteSharedFileSuccess: state => {
      state.status = undefined;
    },
    deleteSharedFileFail: (state, _action: PayloadAction<{ error: DataShareState['error'] }>) => {
      state.status = undefined;
    },
  },
});

export const {
  sharedFilesFetchStart,
  sharedFilesFetchSuccess,
  sharedFilesFetchFail,
  downloadSharedFileStart,
  downloadSharedFileSuccess,
  downloadSharedFileFail,
  deleteSharedFileStart,
  deleteSharedFileSuccess,
  deleteSharedFileFail,
  deleteSharedFileRequest,
} = dataShareSlice.actions;

export default dataShareSlice.reducer;
