import _groupBy from 'lodash/groupBy';
import { call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects';

import features from 'config/features';

import { QUERY_PARAMS } from 'constants/partners';
import { analyticsService, apiService } from 'services';
import { getImpersonate } from 'store/impersonate/impersonate.selectors';
import { updateLocale } from 'store/intl/intl.actions';
import { getLocale } from 'store/intl/intl.selectors';
import { addNotification } from 'store/notifications/notifications.actions';
import { setQuery } from 'store/routing/routing.actions';
import { getQuery } from 'store/routing/routing.selectors';

import defineAbilitiesFor from './ability';
import {
  getUserBookmarksEndpoint,
  getUserEndpoint,
  getUserFavoritePartners,
  getUserFavoritePartnersEndpoint,
  getUserStatsEndpoint,
  getUserTutorialsEndpoint,
} from './user.selectors';
import {
  userBookmarksFetchFail,
  userBookmarksFetchStart,
  userBookmarksFetchSuccess,
  userFetchFail,
  userFetchFavoritePartnersFail,
  userFetchFavoritePartnersStart,
  userFetchFavoritePartnersSuccess,
  userFetchStart,
  userFetchStatsFail,
  userFetchStatsStart,
  userFetchStatsSuccess,
  userFetchSuccess,
  userTutorialsFetchFail,
  userTutorialsFetchSuccess,
  userUpdateFail,
  userUpdateFavoritePartnerFail,
  userUpdateFavoritePartnerStart,
  userUpdateFavoritePartnerSuccess,
  userUpdateStart,
  userUpdateSuccess,
} from './user.slice';
import { UserResponse } from './user.types';

function* userTutorialsFetchFlow(user: UserResponse) {
  try {
    const path = getUserTutorialsEndpoint(user);
    if (path) {
      const tutorials: SagaReturnType<typeof apiService.user.fetchUserTutorials> = yield call(
        [apiService, apiService.user.fetchUserTutorials],
        path,
      );
      yield put(
        userTutorialsFetchSuccess({
          tutorials: _groupBy(tutorials.data, 'attributes.module'),
        }),
      );
    }
  } catch (error) {
    yield put(userTutorialsFetchFail({ error }));
  }
}

function* setUserLanguage(user: UserResponse) {
  const locale = yield select(getLocale);
  const userLang = user?.data?.attributes?.language;
  if (locale !== userLang) {
    yield put(updateLocale(userLang));
  }
}

export function* fetchUserFlow() {
  yield put(userFetchStart());

  try {
    const path = yield select(getUserEndpoint);
    const user: SagaReturnType<typeof apiService.user.fetchUser> = yield call(
      [apiService, apiService.user.fetchUser],
      path,
    );
    const impersonate: SagaReturnType<typeof getImpersonate> = yield select(getImpersonate);
    const userType = user?.data?.attributes?.userType;
    const userRole = user?.data?.attributes?.userRole;

    if (features.tutorials) {
      yield call(userTutorialsFetchFlow, user);
    }

    yield call(setUserLanguage, user);
    if (user.data.attributes.cookies.analytics) {
      analyticsService.enableAnalytics();
    }
    const abilities: SagaReturnType<typeof defineAbilitiesFor> = yield call(
      defineAbilitiesFor,
      user,
    );

    analyticsService.setCustomDimension(1, impersonate.impersonating.toString());
    analyticsService.setCustomDimension(2, userType);
    analyticsService.setCustomDimension(3, userRole);

    yield put(userFetchSuccess({ resp: user, abilities }));
  } catch (error) {
    yield put(userFetchFail({ error }));
  }
}

function* updateUserFlow(action: ReturnType<typeof userUpdateStart>) {
  try {
    const path = yield select(getUserEndpoint);
    const user: SagaReturnType<typeof apiService.user.updateUser> = yield call(
      [apiService, apiService.user.updateUser],
      path,
      action.payload,
    );
    yield put(
      addNotification({
        variant: 'success',
        message: 'profile.update.success',
      }),
    );
    yield call(setUserLanguage, user);
    yield put(userUpdateSuccess({ resp: user }));
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'profile.update.error',
      }),
    );
    yield put(userUpdateFail({ error }));
  }
}

function* userBookmarksFetchFlow() {
  try {
    const path = yield select(getUserBookmarksEndpoint);
    const resp: SagaReturnType<typeof apiService.user.fetchUserBookmarks> = yield call(
      [apiService, apiService.user.fetchUserBookmarks],
      path,
    );
    yield put(userBookmarksFetchSuccess({ resp }));
  } catch (error) {
    yield put(userBookmarksFetchFail({ error }));
  }
}

export function* fetchUserFavoritePartnersFlow() {
  yield put(userFetchFavoritePartnersStart());
  try {
    const path = yield select(getUserFavoritePartnersEndpoint);
    const data: SagaReturnType<typeof apiService.user.fetchUserFavoritePartners> = yield call(
      [apiService, apiService.user.fetchUserFavoritePartners],
      path,
    );
    yield put(userFetchFavoritePartnersSuccess({ resp: data }));
  } catch (error) {
    yield put(userFetchFavoritePartnersFail({ error }));
  }
}

function* userUpdateFavoritePartnerFlow(action: ReturnType<typeof userUpdateFavoritePartnerStart>) {
  const { id, type } = action.payload;
  const prevFavoritePartners = yield select(getUserFavoritePartners);
  const query: SagaReturnType<typeof getQuery> = yield select(getQuery);
  const currentPartnerIds: string[] = query?.[QUERY_PARAMS.PARTNER_IDS]?.split(',') ?? [];
  try {
    const path = yield select(getUserFavoritePartnersEndpoint);
    yield put(userUpdateFavoritePartnerSuccess({ id, type }));
    if (type === 'ADD') {
      yield call([apiService, apiService.user.updateUserFavoritePartner], `${path}/${id}`);
    }
    if (type === 'REMOVE') {
      if (Object.keys(query).includes(QUERY_PARAMS.PARTNER_IDS)) {
        yield put(
          setQuery({
            [QUERY_PARAMS.PARTNER_IDS]:
              currentPartnerIds.filter(partnerId => partnerId !== id).join(',') || '-1',
          }),
        );
      }

      yield call([apiService, apiService.user.deleteUserFavoritePartner], `${path}/${id}`);
    }
    analyticsService.events.trackClientsFavorite();
  } catch (error) {
    yield put(userUpdateFavoritePartnerFail({ error, prevFavoritePartners }));
    yield put(
      addNotification({
        variant: 'error',
        message: 'clients.favorites.update.error',
      }),
    );
  }
}

function* userFetchStatsFlow() {
  try {
    const path: SagaReturnType<typeof getUserStatsEndpoint> = yield select(getUserStatsEndpoint);
    const resp: SagaReturnType<typeof apiService.user.fetchUserStats> = yield call(
      [apiService, apiService.user.fetchUserStats],
      path,
    );
    yield put(userFetchStatsSuccess({ resp }));
  } catch (error) {
    yield put(userFetchStatsFail({ error }));
  }
}

function* userSaga() {
  yield takeLatest(userUpdateStart, updateUserFlow);
  yield takeLatest(userBookmarksFetchStart, userBookmarksFetchFlow);
  // yield takeLatest(userFetchUpdatesStart, userFetchUpdatesFlow);
  yield takeLatest(userUpdateFavoritePartnerStart, userUpdateFavoritePartnerFlow);
  yield takeLatest(userFetchStatsStart, userFetchStatsFlow);
}

export default userSaga;
