import React from 'react';

import { Circle, Lines } from '../loaders.styled';
import SkeletonLine from '../SkeletonLine';
import { Wrap } from './AvatarLoader.styled';

interface Props {
  size?: number;
}

const AvatarLoader: React.FC<Props> = ({ size = 37 }) => (
  <div>
    <Wrap>
      {<Circle size={size} />}
      <Lines hasIcon>
        <SkeletonLine height={14} duration={1.5} minWidth={160} delay={0.2} marginBottom={8} />

        <SkeletonLine height={12} opacity={0.5} duration={1.5} maxWidth={112} delay={0.2} />
      </Lines>
    </Wrap>
  </div>
);

export default AvatarLoader;
