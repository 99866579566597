import React from 'react';

import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';

import { DEFAULT_DATE_FORMAT } from 'constants/store';

import { LinkButton } from 'components/@common/Link';
import FormatNumber from 'components/FormatNumber';
import Icon from 'components/Icon';
import { UIcoCheck, UIcoKalender } from 'components/Icons';

import { Actions, Content, IconWrap, Period, Wrap } from './WorkIncapacityItem.styled';

export interface Props {
  percentage?: number;
  startDate?: string;
  endDate?: string;
  resumptionDate?: string;
  background?: string;
  canEdit?: boolean;
  canDelete?: boolean;
  editDisabled?: boolean;
  deleteDisabled?: boolean;
  onEdit?: (e: React.MouseEvent) => void;
  onDelete?: (e: React.MouseEvent) => void;
  incapacityReason?: string;
}

const WorkIncapacityItem: React.FC<Props> = ({
  percentage,
  startDate,
  endDate,
  background,
  resumptionDate,
  editDisabled,
  deleteDisabled,
  incapacityReason,
  canEdit = true,
  canDelete = true,
  onEdit = () => {},
  onDelete = () => {},
}) => {
  const getDates = () => {
    if (!!resumptionDate) {
      return dayjs(resumptionDate).format(DEFAULT_DATE_FORMAT);
    }

    return [startDate, endDate]
      .filter(Boolean)
      .map(date => dayjs(date).format(DEFAULT_DATE_FORMAT))
      .join(' - ');
  };
  const getIcon = () => {
    if (!!resumptionDate) {
      return (
        <IconWrap>
          <Icon size={24}>
            <UIcoCheck />
          </Icon>
        </IconWrap>
      );
    }
    return (
      <Icon size={28} color="brand01.200" spacing={20} margin="right">
        <UIcoKalender />
      </Icon>
    );
  };

  return (
    <Wrap background={background}>
      <Content>
        {getIcon()}
        <div>
          <FormattedMessage
            id={`claims.tpa_gi_work_incapacity_${
              !!resumptionDate ? 'resumption' : 'percentage'
            }.text`}
            values={{ percentage: <FormatNumber isNumericString number={`${percentage || 0}`} /> }}
          />
          <Period>{getDates()}</Period>
          {incapacityReason && (
            <Typography component="div" variant="body1" color="textSecondary">
              {incapacityReason}
            </Typography>
          )}
        </div>
      </Content>
      <Actions>
        {canEdit && (
          <LinkButton disabled={editDisabled} onClick={onEdit}>
            <FormattedMessage id="general.edit.text" />
          </LinkButton>
        )}
        {canDelete && (
          <LinkButton disabled={deleteDisabled} onClick={onDelete}>
            <FormattedMessage id="general.delete.text" />
          </LinkButton>
        )}
      </Actions>
    </Wrap>
  );
};

export default WorkIncapacityItem;
