import React from 'react';

import dayjs from 'dayjs';
import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import { DEFAULT_DATE_FORMAT } from 'constants/store';
import { Employee } from 'store/claimSubmission/types';

import Button from 'components/@common/Button';
import { LinkButton } from 'components/@common/Link';
import { TablePagination } from 'components/@common/Table';
import Avatar from 'components/Avatar';
import { UIcoZoek } from 'components/Icons';
import Placeholder from 'components/Placeholders';
import PolicyStatusBadge from 'components/PolicyStatusBadge';

import { ActionButton } from '../Modal.styled';
import {
  ActionButtons,
  BadgeWrap,
  EmployeeItem,
  EmployeeList,
  FooterInfo,
  ResultLabel,
  ResultsWrap,
  RightContentFooter,
} from './ClaimSubmissionEmployeeAdvancedSearch.styled';
import { ContainerProps } from './ClaimSubmissionEmployeeAdvancedSearchContainer';

interface Props {
  results?: ContainerProps['employees'];
  isLoading?: ContainerProps['isLoading'];
  error?: ContainerProps['error'];
  meta?: ContainerProps['meta'];
  onClose: () => void;
  onCloseAndSelect: () => void;
  onSelectEmployee: (employee: Employee) => void;
  selectedEmployee?: Employee;
  hasSearched: boolean;
  currentPage: number;
  onPageChange: (nextPage: number) => void;
}

const EmployeeListItem: React.FC<{
  item: Employee;
  onSelectEmployee: Props['onSelectEmployee'];
  isSelected: boolean;
}> = ({ item, onSelectEmployee, isSelected }) => {
  const handleClick = () => {
    onSelectEmployee(item);
  };
  return (
    <EmployeeItem isSelected={isSelected} onClick={handleClick}>
      <Avatar
        id={_get(item, 'id')}
        titleSuffix={dayjs(_get(item, 'attributes.birthDate')).format(DEFAULT_DATE_FORMAT) || ''}
        title={`${_get(item, 'attributes.firstName')} ${_get(item, 'attributes.lastName')}`}
        subTitle={_get(item, 'attributes.personnelNumber')}
      />
      {_get(item, 'relationships.relatedClaims') && (
        <BadgeWrap>
          <PolicyStatusBadge label="CLAIMS" />
        </BadgeWrap>
      )}
    </EmployeeItem>
  );
};

const Results: React.FC<Props> = ({
  results,
  onClose,
  isLoading,
  onSelectEmployee,
  onCloseAndSelect,
  selectedEmployee,
  error,
  hasSearched,
  meta,
  currentPage,
  onPageChange,
}) => (
  <>
    <ResultsWrap>
      {_get(meta, 'count', 0) > 0 && _get(meta, 'totalCount', 0) > 0 && (
        <>
          <ResultLabel>
            <FormattedMessage
              id="claims.add.step.tpa_gi_employee.advanced_search.modal.count.text"
              values={{ count: _get(meta, 'count') }}
            />
          </ResultLabel>
          &nbsp;
          <ResultLabel color="neutral01.500">
            <FormattedMessage
              id="claims.add.step.tpa_gi_employee.advanced_search.modal.total_count.text"
              values={{ total_count: _get(meta, 'totalCount') }}
            />
          </ResultLabel>
        </>
      )}
    </ResultsWrap>
    <EmployeeList>
      <Placeholder
        isLoading={isLoading}
        error={error}
        noDataProps={{
          customIcon: <UIcoZoek />,
          customText: (
            <FormattedMessage
              id={`claims.add.step.tpa_gi_employee.advanced_search.modal.no_${
                hasSearched ? '' : 'values_'
              }data.text`}
            />
          ),
        }}
        data={results}
      >
        {results &&
          results.map(employee => (
            <EmployeeListItem
              key={employee.id}
              isSelected={selectedEmployee && selectedEmployee.id === employee.id ? true : false}
              onSelectEmployee={onSelectEmployee}
              item={employee}
            />
          ))}
      </Placeholder>
    </EmployeeList>
    <TablePagination
      onPageChange={({ page }) => {
        onPageChange(page);
      }}
      page={currentPage}
      rowsPerPage={25}
      isSmall
      count={_get(meta, 'totalCount', 0)}
    />
    <RightContentFooter>
      <FooterInfo>
        <FormattedMessage id="claims.add.step.tpa_gi_employee.advanced_search.modal.action_label" />
      </FooterInfo>
      <ActionButtons>
        <ActionButton>
          <LinkButton disabled={isLoading} onClick={onClose}>
            <FormattedMessage id="claims.add.step.tpa_gi_employee.advanced_search.modal.cancel_button" />
          </LinkButton>
        </ActionButton>
        <ActionButton>
          <Button disabled={isLoading || !selectedEmployee} onClick={onCloseAndSelect}>
            <FormattedMessage id="claims.add.step.tpa_gi_employee.advanced_search.modal.select_button" />
          </Button>
        </ActionButton>
      </ActionButtons>
    </RightContentFooter>
  </>
);

export default Results;
