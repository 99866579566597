import React from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import { ReactComponent as UImgBvbrLogo } from 'assets/svg/u_img_bvbr_logo.svg';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/Icon';

interface Props {
  text?: string;
  code?: number | string;
  onActionClick?: (e: React.MouseEvent) => void;
}

const Unavailable: React.FC<Props> = ({
  text = 'general.errors.critical.categories.text',
  code = 0,
  onActionClick = () => {},
}) => (
  <Box p={{ xs: 4, md: 12 }} maxWidth={580} justifySelf="center" marginTop="25vh">
    <Box clone mb={6}>
      <Icon size={80}>
        <UImgBvbrLogo />
      </Icon>
    </Box>
    <Box>
      <Typography variant="h1" color="textPrimary">
        <FormattedMessage id="general.errors.critical.title" />
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        <FormattedMessage id="general.errors.status_code" values={{ code }} />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage
          id={text}
          values={{
            action: (
              <Link component="button" onClick={onActionClick} variant="body1">
                <FormattedMessage id="general.errors.critical.action" />
              </Link>
            ),
          }}
        />
      </Typography>
    </Box>
  </Box>
);

export default Unavailable;
