import React from 'react';

import { SkeletonItem } from './SkeletonLine.styled';

export interface SkeletonLineProps {
  height?: number;
  minWidth?: number;
  duration: number;
  color: string;
  opacity: number;
  maxWidth?: number;
  delay?: number;
  marginBottom?: number;
  borderRadius: number;
}

const SkeletonLine: React.FC<SkeletonLineProps> & {
  defaultProps: Partial<SkeletonLineProps>;
} = ({
  height,
  minWidth,
  duration,
  color,
  opacity,
  maxWidth,
  delay,
  marginBottom,
  borderRadius,
}) => (
  <SkeletonItem
    opacity={opacity}
    color={color}
    duration={duration}
    minWidth={minWidth}
    maxWidth={maxWidth}
    height={height}
    delay={delay}
    marginBottom={marginBottom}
    borderRadius={borderRadius}
  />
);

SkeletonLine.defaultProps = {
  duration: 1.5,
  color: 'neutral01.400',
  opacity: 0,
  borderRadius: 4,
};

export default SkeletonLine;
