import React from 'react';

import { Input as MuiInput } from '@material-ui/core';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Props } from './TextInput';

const filledStyle = (readOnly?: boolean, disabled?: boolean) => css`
  &.root {
    background-color: ${({ theme }) => theme.palette.brand01[800]};
  }
  &.focused {
    background-color: ${({ theme }) => (readOnly || disabled ? '' : theme.palette.neutral01[50])};
  }
  &.error {
    background-color: ${({ theme }) => theme.palette.neutral01[50]};
  }
  &.disabled {
    background-color: ${({ theme }) => transparentize(0.6, theme.palette.neutral01[300])};
  }
  &:hover {
    background-color: 'inherit';
  }
`;

export const Input = styled(({ variant, ...rest }: Props) => (
  <MuiInput
    {...rest}
    classes={{ root: 'root', focused: 'focused', error: 'error', disabled: 'disabled' }}
  />
))`
  ${({ variant, readOnly, disabled }) =>
    variant === 'filled' ? filledStyle(readOnly, disabled) : ''};
`;
