import styled from 'styled-components';

export const Count = styled.div`
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  right: 0;
  font-size: 14px;
  width: ${({ theme }) => theme.spacing(6)}px;
  height: ${({ theme }) => theme.spacing(6)}px;
  min-width: ${({ theme }) => theme.spacing(6)}px;
  min-height: ${({ theme }) => theme.spacing(6)}px;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
  border: 1px solid ${({ theme }) => theme.palette.neutral01[300]};
  color: ${({ theme }) => theme.palette.neutral01[500]} !important;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.brand01[300]};
    background: ${({ theme }) => theme.palette.brand01[300]};
    color: ${({ theme }) => theme.palette.neutral01[50]} !important;
  }
`;

export const Content = styled.div`
  white-space: pre-wrap;
`;
