import React from 'react';

import { Box } from '@material-ui/core';

import TextInputField from 'components/@inputs/TextField/TextInputField';

export type RemarkDataFormValues = {
  remark?: string;
};

const RemarkDataForm: React.FC = () => {
  return (
    <>
      <Box mb={8}>
        <TextInputField
          name="remark"
          fullWidth
          multiline
          rows="5"
          isRequired
          label="affiliations.remark.remark_label"
        />
      </Box>
    </>
  );
};

export default RemarkDataForm;
