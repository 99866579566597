import styled, { css } from 'styled-components';

import { TableCellProps } from './TableCell';

const greyedOutStyle = css`
  color: ${({ theme }) => theme.palette.neutral01[500]};
  opacity: 0.8;
  div {
    color: ${({ theme }) => theme.palette.neutral01[500]};
  }
`;

type CellInnerStyledProps = Required<Pick<TableCellProps, 'align' | 'isGreyedOut'>>;

const alignMap: Record<CellInnerStyledProps['align'], string> = {
  inherit: 'inherit',
  left: 'inherit',
  right: 'flex-end',
  center: 'center',
  justify: 'center',
};

export const TableCellInner = styled.div<CellInnerStyledProps>`
  justify-content: ${({ align }) => alignMap[align]};
  display: flex;
  align-items: center;
  ${({ isGreyedOut }) => (isGreyedOut ? greyedOutStyle : '')};
`;

export default null;
