import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { theme01 as theme } from 'theme';

import GlobalStyle from './global-style';

// unsure which types are correct since it's being reused for the styleguide
export default (Component: React.ReactType) => {
  return (props: unknown) => {
    return (
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <Component {...props} />
          <CssBaseline />
          <GlobalStyle />
        </MuiThemeProvider>
      </ThemeProvider>
    );
  };
};
