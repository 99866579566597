import React from 'react';

import { Box, RadioGroupProps } from '@material-ui/core';

import RadioTile from './RadioTile';
import { RadioGroup } from './RadioTileGroup.styled';
import RadioTileGroupLoading from './RadioTileGroupLoading';

export interface RadioTileGroupProps extends RadioGroupProps {
  isLoading?: boolean;
}

const RadioTileGroup: React.FC<RadioTileGroupProps> = ({
  children,
  isLoading = false,
  onChange,
  value = '',
}) => {
  const tileOptions = React.Children.toArray(children)
    .map(child => {
      if (React.isValidElement(child) && child.type === RadioTile) {
        return React.cloneElement(child, { onChange: onChange });
      }
      return undefined;
    })
    .filter(Boolean);

  if (isLoading) {
    return (
      <Box mt={2}>
        <RadioTileGroupLoading />
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <RadioGroup value={value}>{tileOptions}</RadioGroup>
    </Box>
  );
};

export default RadioTileGroup;
