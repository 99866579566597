import React from 'react';

import { Box, Link } from '@material-ui/core';
import { ReactComponent as UIcoExternalLink } from 'assets/svg/u_ico_external_link.svg';
import { FormattedMessage } from 'react-intl';

import { analyticsService } from 'services';

import Icon from 'components/Icon';

import { RowType } from '../UserTable';

interface Props {
  row: RowType;
  onActionClick: (row: RowType) => void;
}

const ActionsCell: React.FC<Props> = ({ row, onActionClick }) => {
  return (
    <>
      <Box mr={3}>
        <Link
          onClick={() => {
            analyticsService.events.trackClientsDetailUserPartersClick();
          }}
          href={row.links?.userManagement}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="users.table.actions.partners" />
          <Icon color="brand01.300">
            <UIcoExternalLink />
          </Icon>
        </Link>
      </Box>
      <Link onClick={() => onActionClick(row)} component="button" variant="body1">
        <FormattedMessage id="users.table.actions.login_as" />
      </Link>
    </>
  );
};

export default ActionsCell;
