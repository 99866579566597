import React, { Fragment } from 'react';

import _fill from 'lodash/fill';
import { injectIntl } from 'react-intl';

import { H4 } from 'components/@typography/Titles';
import Placeholder from 'components/Placeholders';

import { DropdownFooter, MenuHeaderWrap, MenuItemWrap } from './DropdownMenu.styled';

const DropdownMenu = ({
  intl,
  isLoading,
  error,
  data,
  selectedItem,
  getItemProps,
  highlightedIndex,
  itemRender,
  withHeader,
  inputValue,
  ListItemLoader,
  getMenuProps,
  repeatLoader = 3,
  Footer,
}) => {
  const MenuLoader = () =>
    ListItemLoader ? (
      <Fragment>
        {_fill(Array(repeatLoader), null).map((item, key) => (
          // eslint-disable-next-line react/no-array-index-key
          <MenuItemWrap key={key}>
            <ListItemLoader />
          </MenuItemWrap>
        ))}
      </Fragment>
    ) : null;
  return (
    <div {...getMenuProps()}>
      {withHeader && (
        <MenuHeaderWrap>
          <H4>
            {intl.formatMessage({
              id: 'documents.add.step.recipient.receiver.autocomplete_header',
            })}
          </H4>
        </MenuHeaderWrap>
      )}
      <div>
        <Placeholder
          type="inline"
          withEmptyState={false}
          isLoading={isLoading}
          error={error}
          data={data}
          LoaderComponent={MenuLoader}
        >
          <Fragment>
            {data &&
              data.length > 0 &&
              data.map((item, index) => {
                const itemProps = getItemProps({ item });

                return (
                  <MenuItemWrap
                    {...itemProps}
                    selected={
                      highlightedIndex === index || (selectedItem && selectedItem.id === item.id)
                    }
                    key={item.id}
                  >
                    {itemRender(item, inputValue)}
                  </MenuItemWrap>
                );
              })}
          </Fragment>
          {Footer && <DropdownFooter>{Footer}</DropdownFooter>}
        </Placeholder>
      </div>
    </div>
  );
};

DropdownMenu.defaultProps = {
  selectedItem: {},
  getItemProps: () => {
    // default prop
  },
  repeatLoader: 3,
};

export default injectIntl(DropdownMenu);
