import { connect } from 'react-redux';

import { groupSelectStart } from 'store/client/client.actions';
import { getPartner, getPartnerError, getPartnerIsLoading } from 'store/partner/partner.selectors';
import { goToPage } from 'store/routing/routing.actions';
import { getPathParam } from 'store/routing/routing.selectors';
import { Store } from 'types/store/store.types';

import ClientsDetail from './ClientsDetail';

const mapStateToProps = (state: Store) => ({
  data: getPartner(state),
  error: getPartnerError(state),
  isLoading: getPartnerIsLoading(state),
  currentId: getPathParam(state),
});

const mapDispatchToProps = {
  goToPage,
  selectGroup: groupSelectStart as any, // TODO: remove any once typescript
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type MapDispatchProps = typeof mapDispatchToProps;
export type ContainerProps = MapStateProps & MapDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClientsDetail);
