import ClaimSubmissionEmployeeAdvancedSearch from './ClaimSubmissionEmployeeAdvancedSearch';
import FeedbackForm from './FeedbackForm';
import FlowSubmissionStatus from './FlowSubmissionStatus';
import IncapacityPeriodsEdit from './IncapacityPeriodsEdit';
import WorkResumption from './WorkResumption';

export const MODAL_COMPONENTS = {
  FEEDBACK: FeedbackForm,
  FLOW_SUBMISSION_STATUS: FlowSubmissionStatus,
  EMPLOYEE_ADVANCED_SEARCH: ClaimSubmissionEmployeeAdvancedSearch,
  IC_PERIODS_EDIT: IncapacityPeriodsEdit,
  WORK_RESUMPTION: WorkResumption,
};
