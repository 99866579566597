import React from 'react';

import { Box, Link } from '@material-ui/core';
import { ReactComponent as UIcoExternalLink } from 'assets/svg/u_ico_external_link.svg';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Params } from 'redux-first-router';

import Button from 'components/@common/Button';
import TextInputField from 'components/@inputs/TextField/TextInputField';
import FormikAutoSave from 'components/FormikAutoSave';
import Icon from 'components/Icon';
import { useMedia } from 'components/Media';
interface Props {
  initialValues: Params;
  onFilterChange: (filters: Params) => void;
  userManagementUrl?: string;
}

const UserMgmtButton: React.FC<{ href: string }> = ({ href }) => {
  const media = useMedia();
  const sharedProps = { href, target: '_blank' };
  const content = (
    <>
      <FormattedMessage id="clients.details.actions.new_user" />
      <Icon color="brand01.300">
        <UIcoExternalLink />
      </Icon>
    </>
  );
  if (media.small) {
    return <Link {...sharedProps}>{content}</Link>;
  }
  return (
    <Button variation="secondary" {...sharedProps}>
      {content}
    </Button>
  );
};

const UsersFilter: React.FC<Props> = ({ initialValues, onFilterChange, userManagementUrl }) => (
  <Box p={{ xs: 2, md: 6 }}>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => {
        onFilterChange(values);
      }}
    >
      <>
        <FormikAutoSave debounce={200} />
        <Box flexDirection="row-reverse" display={{ sm: 'block', md: 'flex' }}>
          {userManagementUrl && (
            <Box ml={{ sm: 0, md: 4 }} mb={{ sm: 4, md: 0 }}>
              <UserMgmtButton href={userManagementUrl ?? ''} />
            </Box>
          )}
          <Box flex="1 auto">
            <TextInputField
              fullWidth
              name="q"
              fieldType="search"
              placeholder="users.filter.search.placeholder"
            />
          </Box>
        </Box>
      </>
    </Formik>
  </Box>
);

export default UsersFilter;
