import React from 'react';

import { OptionsObject, useSnackbar } from 'notistack';

import Notification from './Notification';

type Options = Pick<
  OptionsObject,
  'persist' | 'preventDuplicate' | 'content' | 'action' | 'onExited'
>;

export interface CustomOptions extends Options {
  description?: React.ReactNode;
  variant?: 'error' | 'success' | 'warning' | 'info';
  actions?: React.ReactNode[];
  onActionClick?: (index: number, e?: React.MouseEvent) => void;
  onClose?: (e: any, reason: string, key?: string | number) => void;
  messageValues?: { [key: string]: string };
}

/*
  we override the implementation with the exact same properties but pass the options prop to the component
  because notistack doesn't pass the props to the custom content component but we need them in order to display
  a description (if provided) and access the variant property provided
*/

const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const overrideEnqueue = (message: string, options: CustomOptions = {}) => {
    const newOptions = { ...options };
    newOptions.content = (key, message) => <Notification id={key} message={message} {...options} />;
    return enqueueSnackbar(message, newOptions);
  };

  return { enqueueSnackbar: overrideEnqueue, closeSnackbar };
};

export default useNotification;
