import React from 'react';

import { Box, FormGroupProps } from '@material-ui/core';

import CheckboxTile from './CheckboxTile';
import { FormGroup } from './CheckboxTileGroup.styled';
import CheckboxTileGroupLoading from './CheckboxTileGroupLoading';

export interface CheckboxTileGroupProps extends Omit<FormGroupProps, 'onChange'> {
  value?: { [index: string]: boolean };
  name?: string;
  isLoading?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: { [index: string]: unknown }) => void;
}

const CheckboxTileGroup: React.FC<CheckboxTileGroupProps> = ({
  children,
  onChange,
  isLoading,
  value = {},
}) => {
  const tileOptions = React.Children.toArray(children)
    .map(child => {
      if (React.isValidElement(child) && child.type === CheckboxTile) {
        return React.cloneElement<React.ComponentProps<typeof CheckboxTile>>(child, {
          onChange: onChange,
          checked: value.hasOwnProperty(child.props.name),
        });
      }
      return undefined;
    })
    .filter(Boolean);

  if (isLoading) {
    return (
      <Box mt={2}>
        <CheckboxTileGroupLoading />
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <FormGroup>{tileOptions}</FormGroup>
    </Box>
  );
};

export default CheckboxTileGroup;
