import React from 'react';

import { Box } from '@material-ui/core';
import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import { NAV_ITEMS } from 'constants/header';
import { ROUTES } from 'constants/store';
import { analyticsService } from 'services';

import { moduleNames } from 'pages/routes';

import { Sidebar } from 'components/@layout';
import { Content, ContentWrap } from 'components/@layout/Content';
import PageTabs from 'components/@layout/PageTab';
import { SidebarScrollContainer } from 'components/@layout/SidebarLeft';
import { MainTitle } from 'components/@typography/Titles';
import { ActionsMenu } from 'components/Actions';
import Can from 'components/Auth';
import BackLink from 'components/BackLink';
import Experts from 'components/Experts';
import Icon from 'components/Icon';
import { useMedia } from 'components/Media';

import { Wrap } from './ClientsDetail.styled';
import { ContainerProps } from './ClientsDetailContainer';
import { Contacts, Details, Users } from './components';

const PageSubtitle: React.FC<Pick<ContainerProps, 'data'>> = ({ data }) => (
  <>
    <FormattedMessage id="clients.details.subTitle.partner_ID" />
    &nbsp;
    {_get(data, 'id')}
  </>
);

const ClientsDetail: React.FC<ContainerProps> = ({
  isLoading,
  data,
  goToPage,
  selectGroup,
  currentId,
}) => {
  const media = useMedia();

  const isGroup = !_get(data, 'relationships.group.data.id');
  const groupId = isGroup ? _get(data, 'id') : _get(data, 'relationships.group.data.id');

  const handleActionClick = (type: string) => () => {
    const clientId = isGroup ? undefined : _get(data, 'id');
    if (type === 'CLAIMS') {
      analyticsService.events.trackClientsDetailClaimsClick();
    }
    if (type === 'POLICIES') {
      analyticsService.events.trackClientsDetailInsuranceClick();
    }
    selectGroup({ groupId });
    goToPage(type, { query: { groupId, clientIds: clientId } });
  };

  const handleChildClick = (id?: string) => {
    if (currentId !== id) {
      analyticsService.events.trackClientsDetailPartnerClick();
      goToPage(ROUTES.PARTNERS_DETAIL, { id });
    }
  };

  const handleTabChangeCallback = (tabId: string) => {
    analyticsService.events.trackPageDetailTabClick(ROUTES.PARTNERS_DETAIL, tabId);
  };

  const contactsCount = data?.relationships?.contacts?.meta.count || 0;
  const usersCount = data?.relationships?.users?.meta.count || 0;
  return (
    <ContentWrap>
      <Sidebar>
        <Sidebar.Top>
          <BackLink to="/clients" module={moduleNames.clients} />
        </Sidebar.Top>
        <SidebarScrollContainer>
          <Sidebar.Item marginTop={0} stayVisible title="claims.overview.experts_label">
            {groupId && <Experts context="general" groupId={groupId} />}
          </Sidebar.Item>
        </SidebarScrollContainer>
      </Sidebar>
      <Content>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <MainTitle
            isLoading={isLoading}
            title={_get(data, 'attributes.name')}
            subTitle={<PageSubtitle data={data} />}
          />
          <Box px={{ xs: 4, md: 0 }} py={4}>
            <ActionsMenu menuButtonText="client.details.actions.button">
              {[
                ROUTES.POLICIES,
                ROUTES.HC_AFFILIATIONS,
                ROUTES.CLAIMS,
                ROUTES.INVOICES,
                ROUTES.PUBLICATIONS,
                ROUTES.CLAIM_REPORTS,
              ].map(route => (
                <Can key={route} I="visit" a={route}>
                  <Box display="flex" alignItems="center" onClick={handleActionClick(route)}>
                    <Box clone ml={-2}>
                      <Icon color="brand01.200">{NAV_ITEMS[route].icon}</Icon>
                    </Box>
                    <Box ml={1}>
                      <FormattedMessage id={NAV_ITEMS[route].label} />
                    </Box>
                  </Box>
                </Can>
              ))}
            </ActionsMenu>
          </Box>
        </Box>
        <Wrap>
          {/* TODO: fix swipeable styling for flex container */}
          <PageTabs swipeable={false} initialActive="details" onTabChange={handleTabChangeCallback}>
            <PageTabs.Tab value="details">
              <FormattedMessage id="clients.details.tab.details" />
            </PageTabs.Tab>
            <PageTabs.Tab disabled={contactsCount === 0} count={contactsCount} value="contacts">
              <FormattedMessage id="clients.details.tab.contacts" />
            </PageTabs.Tab>
            <PageTabs.Tab count={usersCount} value="users">
              <FormattedMessage id="clients.details.tab.users" />
            </PageTabs.Tab>
            <PageTabs.Panel value="details" pt={6} pb={6} pl={8} pr={8}>
              <Details isGroup={isGroup} onChildClick={handleChildClick} />
            </PageTabs.Panel>
            <PageTabs.Panel value="contacts">
              <Contacts />
            </PageTabs.Panel>
            <PageTabs.Panel value="users">
              <Users
                groupId={groupId}
                userManagementUrl={data?.relationships?.users?.links?.userManagementPlatform}
              />
            </PageTabs.Panel>
          </PageTabs>
        </Wrap>
      </Content>
      {media.small && <Box height={80} />}
    </ContentWrap>
  );
};
export default ClientsDetail;
