import React, { useRef } from 'react';

import useFilter from 'hooks/useFilter/useFilter';
import useUsers from 'hooks/useUsers/useUsers';

import { CategoryListIds, QueryParams } from 'constants/user.constants';
import { analyticsService } from 'services';
import { ExternalUserData } from 'services/Api/types/users.types';

import { UserTable } from 'components/Tables';

import Filter from './Filter';

interface Props {
  groupId?: string;
  userManagementUrl?: string;
}

const Users: React.FC<Props> = ({ groupId, userManagementUrl }) => {
  const defaultQueryParams = useRef({
    [QueryParams.GROUP_ID]: groupId,
    [CategoryListIds.AllUsers]: 'true',
  });

  const { handleFilterChange, filter } = useFilter({
    disablePersist: true,
  });

  const {
    data,
    isFetching,
    error,
    totalResults,
    exportUsers,
    isExporting,
    isPreviousData,
    isLoading,
  } = useUsers({
    ...defaultQueryParams.current,
    ...filter,
  });

  const handleLoginAsUserClick = (row: ExternalUserData) => {
    analyticsService.events.trackClientsDetailUserImpersonate(row.attributes.email);
    window.open(`/impersonate?user_email=${row.attributes.email}`, '_blank');
  };

  return (
    <>
      <Filter
        userManagementUrl={userManagementUrl}
        initialValues={{}}
        onFilterChange={handleFilterChange}
      />
      <UserTable
        rows={data?.data}
        error={error}
        isLoading={(isPreviousData && isFetching) || isLoading}
        disablePersist={true}
        category={CategoryListIds.AllUsers}
        meta={{
          count: totalResults,
        }}
        onFilterChange={handleFilterChange}
        showActions
        onActionClick={handleLoginAsUserClick}
        withExport
        isExporting={isExporting}
        onExportResults={exportUsers}
      />
    </>
  );
};

export default Users;
