import { createReducer } from 'redux-act';

import {
  addClientsToState,
  clientFetchByIdFail,
  clientFetchByIdStart,
  clientFetchByIdSuccess,
  clientFetchFail,
  clientFetchStart,
  clientFetchSuccess,
  clientReportingCategoriesFetchFail,
  clientReportingCategoriesFetchStart,
  clientReportingCategoriesFetchSuccess,
  clientResetSelectedIds,
  clientSearchFail,
  clientSearchStart,
  clientSearchSuccess,
  clientSelectFail,
  clientSelectStart,
  clientSelectSuccess,
  groupFetchFail,
  groupFetchStart,
  groupFetchSuccess,
  groupSearchFail,
  groupSearchStart,
  groupSearchSuccess,
  groupSelectFail,
  groupSelectStart,
  groupSelectSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './client.actions';

export const INITIAL_STATE = {
  data: undefined,
  client: {
    isLoading: false,
    error: undefined,
  },
  group: {
    data: undefined,
    isLoading: false,
    isFiltering: false,
    error: undefined,
    filteredResults: [],
    currentSelected: undefined,
  },
  partner: {
    data: undefined,
    isLoading: false,
    isFiltering: false,
    error: undefined,
    filteredResults: [],
    currentSelected: [],
  },
  reportingCategories: {
    isLoading: false,
    error: undefined,
    data: undefined,
    results: [],
  },
};

const REDUCERS = {
  [clientFetchStart]: state =>
    Object.assign({}, state, {
      partner: {
        ...state.partner,
        isLoading: true,
        isFiltering: false,
        error: undefined,
        currentSelected: [],
        filteredResults: [],
      },
    }),
  [addClientsToState]: (state, { clients }) =>
    Object.assign({}, state, {
      ...state,
      data: {
        ...state.data,
        ...clients,
      },
    }),
  [clientFetchSuccess]: (state, { groupPartners, clientIds, data }) =>
    Object.assign({}, state, {
      data: {
        ...state.data,
        ...data,
      },
      partner: {
        ...state.partner,
        data: groupPartners,
        isLoading: false,
        isFiltering: false,
        error: undefined,
        filteredResults: groupPartners,
        currentSelected: clientIds,
      },
    }),
  [clientFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      partner: {
        ...state.partner,
        isLoading: false,
        error: error || 'unknown error',
      },
    }),
  [clientSearchStart]: state =>
    Object.assign({}, state, {
      partner: {
        ...state.partner,
        isFiltering: true,
        error: undefined,
      },
    }),
  [clientSearchSuccess]: (state, { groupPartners }) =>
    Object.assign({}, state, {
      partner: {
        ...state.partner,
        isFiltering: false,
        error: undefined,
        filteredResults: groupPartners,
      },
    }),
  [clientSearchFail]: (state, { error }) =>
    Object.assign({}, state, {
      partner: {
        ...state.partner,
        isFiltering: false,
        error: error || 'unknown error',
      },
    }),
  [clientSelectStart]: state => Object.assign({}, state, {}),
  [clientSelectSuccess]: (state, { selected }) =>
    Object.assign({}, state, {
      partner: {
        ...state.partner,
        filteredResults: state.partner.data,
        currentSelected: selected || state.partner.data,
      },
    }),
  [clientSelectFail]: state => Object.assign({}, state, {}),
  [clientResetSelectedIds]: (state, { clientIds }) =>
    Object.assign({}, state, {
      partner: {
        ...state.partner,
        filteredResults: state.partner.data,
        currentSelected: clientIds || state.partner.data,
      },
    }),
  [clientResetSelectedIds]: (state, { clientIds }) =>
    Object.assign({}, state, {
      partner: {
        ...state.partner,
        filteredResults: state.partner.data,
        currentSelected: clientIds,
      },
    }),
  [groupSelectStart]: state => Object.assign({}, state, {}),
  [groupSelectSuccess]: (state, { selected }) =>
    Object.assign({}, state, {
      group: {
        ...state.group,
        filteredResults: state.group.data,
        currentSelected: selected,
      },
    }),
  [groupSelectFail]: state => Object.assign({}, state, {}),
  [groupFetchStart]: state =>
    Object.assign({}, state, {
      group: {
        ...state.group,
        isLoading: false,
        isFiltering: false,
        error: undefined,
        currentSelected: undefined,
      },
    }),
  [groupFetchSuccess]: (state, { groupData, data }) =>
    Object.assign({}, state, {
      data: { ...state.data, ...data },
      group: {
        ...state.group,
        data: groupData,
        isLoading: false,
        isFiltering: false,
        error: null,
        filteredResults: groupData,
      },
    }),
  [groupFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      group: {
        ...state.group,
        isLoading: false,
        error: error || 'unknown error',
      },
    }),
  [groupSearchStart]: state =>
    Object.assign({}, state, {
      group: {
        ...state.group,
        isFiltering: true,
        error: undefined,
      },
    }),
  [groupSearchSuccess]: (state, { groupData }) =>
    Object.assign({}, state, {
      group: {
        ...state.group,
        isFiltering: false,
        error: undefined,
        filteredResults: groupData,
      },
    }),
  [groupSearchFail]: (state, { error }) =>
    Object.assign({}, state, {
      group: {
        ...state.group,
        isFiltering: false,
        error: error || 'unknown error',
      },
    }),
  [clientFetchByIdStart]: state =>
    Object.assign({}, state, {
      client: {
        ...state.client,
        isLoading: true,
        error: undefined,
      },
    }),
  [clientFetchByIdSuccess]: (state, { client }) =>
    Object.assign({}, state, {
      data: { ...state.data, ...client },
      client: {
        ...state.client,
        error: undefined,
        isLoading: false,
      },
    }),
  [clientFetchByIdFail]: (state, { error }) =>
    Object.assign({}, state, {
      client: {
        ...state.client,
        isLoading: false,
        error: error || 'unknown error',
      },
    }),
  [clientReportingCategoriesFetchStart]: state =>
    Object.assign({}, state, {
      reportingCategories: {
        ...state.reportingCategories,
        isLoading: true,
        error: undefined,
        results: [],
      },
    }),
  [clientReportingCategoriesFetchSuccess]: (state, { result, data }) =>
    Object.assign({}, state, {
      ...state,
      reportingCategories: {
        ...state.reportingCategories,
        data: { ...(state.reportingCategories.data || {}), ...data },
        isLoading: false,
        error: undefined,
        results: result,
      },
    }),
  [clientReportingCategoriesFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      reportingCategories: {
        ...state.reportingCategories,
        isLoading: false,
        error: error || 'unknown error',
      },
    }),
  // (-- APPEND REDUCER ACTION MAPPING HERE --) !!! do not move this comment !!!
};

export default createReducer(REDUCERS, INITIAL_STATE);
