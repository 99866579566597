import React from 'react';

import { Circle, Lines, Wrap } from '../loaders.styled';
import SkeletonLine from '../SkeletonLine';

interface Props {
  hasIcon?: boolean;
}

const TitleLoader: React.FC<Props> = ({ hasIcon }) => (
  <Wrap hasIcon={hasIcon}>
    {hasIcon && <Circle />}
    <Lines hasIcon={hasIcon}>
      <SkeletonLine duration={1.5} minWidth={160} delay={0.2} height={16} marginBottom={8} />

      <SkeletonLine opacity={0.5} duration={1.5} height={16} maxWidth={112} delay={0.2} />
    </Lines>
  </Wrap>
);

export default TitleLoader;
