import React from 'react';

import { TagStyled } from './Tag.styled';

export interface TagProps {
  type?: 'default' | 'warning' | 'success' | 'alert' | 'inactive';
  smallBadge?: boolean;
  upperCase?: boolean;
  ref?: React.Ref<HTMLSpanElement>;
  onMouseEnter?: (event: React.MouseEvent<HTMLSpanElement>) => void;
}

const Tag: React.FC<TagProps> = React.forwardRef<HTMLSpanElement, TagProps>(
  ({ children, type, smallBadge, ...rest }, ref) => (
    <TagStyled ref={ref} {...rest} smallBadge={smallBadge} type={type}>
      <span>{!smallBadge && children}</span>
    </TagStyled>
  ),
);

Tag.defaultProps = {
  type: 'default',
};

export default Tag;
