import React from 'react';

import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ROUTES } from 'constants/store';

import Can from 'components/Auth';
import Icon from 'components/Icon';
import { UIcoFeedback, UIcoInstellingen, UIcoSignout } from 'components/Icons';

import { LinkButtonStyled, NavItemFlyOutWrap, Spacer } from './HeaderMobile.styled';

interface MenuItem {
  label: React.ReactNode;
  icon: React.ReactNode;
  name: string;
  onClick: (event: React.MouseEvent, value: string) => void;
}

interface Props {
  onMenuItemClick: (event: React.MouseEvent, value: string) => void;
}

const MenuItem: React.FC<MenuItem> = ({ name, label, icon, onClick }) => (
  <LinkButtonStyled
    color="neutral01.600"
    fullWidth
    onClick={(event: React.MouseEvent) => onClick(event, name)}
    hasUnderline={false}
  >
    <Box height={48} display="flex" alignItems="center">
      <Icon color="brand01.200" margin="right" spacing={8}>
        {icon}
      </Icon>

      {typeof label === 'string' ? <FormattedMessage id={label} /> : label}
    </Box>
  </LinkButtonStyled>
);

const MenuFlyOut: React.FC<Props> = ({ onMenuItemClick, children }) => {
  return (
    <Box p={4}>
      {React.Children.toArray(children).length > 0 && (
        <>
          {React.Children.toArray(children).map((child, i) => (
            <NavItemFlyOutWrap key={i}>{child}</NavItemFlyOutWrap>
          ))}
          <Spacer />
        </>
      )}
      <Can I="visit" a="FEEDBACK">
        <MenuItem
          label="general.menu.feedback"
          icon={
            <Icon color="brand01.600">
              <UIcoFeedback />
            </Icon>
          }
          onClick={onMenuItemClick}
          name="FEEDBACK"
        />
      </Can>
      <Can I="visit" a={ROUTES.SETTINGS}>
        <MenuItem
          label="general.menu.settings"
          icon={
            <Icon color="brand01.600">
              <UIcoInstellingen color="brand01.600" />
            </Icon>
          }
          onClick={onMenuItemClick}
          name={ROUTES.SETTINGS}
        />
      </Can>

      <MenuItem
        label="general.menu.logout"
        icon={<UIcoSignout />}
        onClick={onMenuItemClick}
        name="LOGOUT"
      />
    </Box>
  );
};

export default MenuFlyOut;
