// juice for large class setup: https://stackoverflow.com/a/52393598
// import ErrorService from 'services/error';
import { AxiosInstance } from 'axios';

import AffiliationsApi from './AffiliationsApi';
import AffiliationSubmissionApi from './AffiliationSubmissionApi';
import ClaimReportsApi from './ClaimReportsApi';
import ClaimsApi from './ClaimsApi';
import ClientApi from './ClientApi';
import DashboardApi from './DashboardApi';
import DataShareApi from './DataShareApi';
import EndpointsApi from './EndpointsApi';
import ExpertsApi from './ExpertsApi';
import InvoicesApi from './InvoicesApi';
import LocationsApi from './LocationsApi';
import PartnersApi from './PartnersApi';
import PoliciesApi from './PoliciesApi';
import PublicationsApi from './PublicationsApi';
import UserApi from './UserApi';
import UsersApi from './UsersApi';
export type ApiInstanceType = AxiosInstance;

interface Options<ApiInstance> {
  api: ApiInstance;
}

class RestApiService<ApiInstance extends ApiInstanceType> {
  public api: ApiInstance;
  public endpoints: EndpointsApi;
  public affiliation: AffiliationSubmissionApi;
  public affiliations: AffiliationsApi;
  public client: ClientApi;
  public locations: LocationsApi;
  public claims: ClaimsApi;
  public policies: PoliciesApi;
  public dataShare: DataShareApi;
  public partners: PartnersApi;
  public invoices: InvoicesApi;
  public claimReports: ClaimReportsApi;
  public publications: PublicationsApi;
  public user: UserApi;
  public dashboard: DashboardApi;
  public experts: ExpertsApi;
  public users: UsersApi;

  constructor(options: Options<ApiInstance>) {
    this.api = options.api;
    this.endpoints = new EndpointsApi(this.api);
    this.client = new ClientApi(this.api);
    this.locations = new LocationsApi(this.api);
    this.affiliation = new AffiliationSubmissionApi(this.api);
    this.claims = new ClaimsApi(this.api);
    this.policies = new PoliciesApi(this.api);
    this.dataShare = new DataShareApi(this.api);
    this.partners = new PartnersApi(this.api);
    this.invoices = new InvoicesApi(this.api);
    this.affiliations = new AffiliationsApi(this.api);
    this.claimReports = new ClaimReportsApi(this.api);
    this.publications = new PublicationsApi(this.api);
    this.user = new UserApi(this.api);
    this.dashboard = new DashboardApi(this.api);
    this.experts = new ExpertsApi(this.api);
    this.users = new UsersApi(this.api);
  }
}

export default RestApiService;
