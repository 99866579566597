import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import { AutoSizer } from 'react-virtualized';

import SlidePanel from './SlidePanel';
import { splitScreenContext } from './SplitScreenContext';

const { Provider } = splitScreenContext;

interface Props {
  initialPanelActive?: number;
}
interface SplitScreenComposite {
  Panel: typeof SlidePanel;
}

const SplitScreen: React.FC<Props> & SplitScreenComposite = ({
  children,
  initialPanelActive = 0,
}) => {
  const [activePanel, setActivePanel] = useState(initialPanelActive);

  const handleGoToNextPanel = () => {
    const nextActive = activePanel + 1;
    if (React.Children.toArray(children).length > nextActive) {
      setActivePanel(nextActive);
    }
  };

  const handleGoToPreviousPanel = () => {
    const nextActive = activePanel - 1;
    if (nextActive >= 0) {
      setActivePanel(nextActive);
    }
  };

  const getChildrenGroupedBy = (parent: string) => {
    let groupChildren: React.ReactElement[] = [];
    React.Children.forEach(children, child => {
      if (!React.isValidElement(child)) {
        console.warn('only valid react elements are supported');
        return;
      }
      if (child.type === SlidePanel && parent === child.props.parent) {
        groupChildren.push(child);
      }
    });
    return groupChildren;
  };

  return (
    <Provider value={{ activePanel, handleGoToNextPanel, handleGoToPreviousPanel }}>
      <AutoSizer disableWidth>
        {({ height }) => {
          return (
            <Box
              position="relative"
              style={{
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
              alignItems="stretch"
              height={height}
              display={{ xs: 'block', md: 'flex' }}
              flex="1 auto"
            >
              <Box display="flex" flexDirection="column" flex="1 40%">
                {getChildrenGroupedBy('left')}
              </Box>
              <Box
                flex="1 60%"
                boxShadow={1}
                borderRadius={8}
                display="flex"
                flexDirection="column"
                zIndex="1"
                mt={1}
                mb={3}
              >
                {getChildrenGroupedBy('right')}
              </Box>
            </Box>
          );
        }}
      </AutoSizer>
    </Provider>
  );
};

SplitScreen.Panel = SlidePanel;

export default SplitScreen;
