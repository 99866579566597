import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import {
  InvoicesExportStartPayload,
  InvoicesFetchStartPayload,
  InvoicesResponse,
} from './invoices.types';

export type InvoicesState = {
  data?: InvoicesResponse['data'];
  meta?: InvoicesResponse['meta'];
  status?: 'loading' | 'exporting';
  error: StoreDefaultState['error'];
  links?: InvoicesResponse['links'];
};

export const INITIAL_STATE: InvoicesState = {
  data: undefined,
  meta: undefined,
  status: undefined,
  error: undefined,
  links: undefined,
};

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: INITIAL_STATE,
  reducers: {
    invoicesFetchStart: (state, _action: PayloadAction<InvoicesFetchStartPayload>) => {
      state.status = 'loading';
      state.error = undefined;
    },
    invoicesFetchSuccess: (state, { payload }: PayloadAction<InvoicesResponse>) => {
      state.status = undefined;
      state.error = undefined;
      state.data = payload.data;
      state.links = payload.links;
      state.meta = payload.meta;
    },
    invoicesFetchFail: (state, { payload }: PayloadAction<{ error: InvoicesState['error'] }>) => {
      state.status = undefined;
      state.error = payload.error;
    },
    invoicesExportStart: (state, _action: PayloadAction<InvoicesExportStartPayload>) => {
      state.status = 'exporting';
    },
    invoicesExportSuccess: state => {
      state.status = undefined;
    },
    invoicesExportFail: (state, _action: PayloadAction<{ error: InvoicesState['error'] }>) => {
      state.status = undefined;
    },
  },
});

export const {
  invoicesFetchStart,
  invoicesFetchSuccess,
  invoicesFetchFail,
  invoicesExportStart,
  invoicesExportSuccess,
  invoicesExportFail,
} = invoicesSlice.actions;

export default invoicesSlice.reducer;
