import React from 'react';

import { Box, Link, Typography } from '@material-ui/core';

import Icon from 'components/Icon';
import { UIcoPijlLinks } from 'components/Icons';
import { useMedia } from 'components/Media';
interface Props {
  variant: 'main' | 'link';
  onPreviousClick?: () => void;
}

const Header: React.FC<Props> = ({ children, variant, onPreviousClick }) => {
  const media = useMedia();
  if (!media.small && variant === 'link') {
    return null;
  }
  return (
    <Box
      minHeight={56}
      px={4}
      display="flex"
      alignItems="center"
      color="neutral01.300"
      height={56}
      bgcolor={{ xs: 'neutral01.50', md: 'transparent' }}
      boxShadow={{ xs: 1, md: 0 }}
    >
      {variant === 'main' ? (
        <Box clone margin={{ xs: '0 auto', md: 0 }}>
          <Typography component="h1" variant="h2" color="secondary">
            {children}
          </Typography>
        </Box>
      ) : (
        <Box ml={-2}>
          <Link
            underline="none"
            onClick={onPreviousClick}
            component="button"
            color="secondary"
            variant="subtitle1"
          >
            <Icon color="brand01.400" size={24} spacing={4}>
              <UIcoPijlLinks />
            </Icon>
            {children}
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default Header;
