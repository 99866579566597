import mimeTypes from 'data/mimeTypes.json';
import humps from 'humps';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isArray from 'lodash/isArray';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';
import _set from 'lodash/set';
import _transform from 'lodash/transform';
import _unset from 'lodash/unset';
import qs from 'query-string';

export const redirect = (url, target = '_blank') => {
  if (window) {
    window.open(url, target);
  }
};

export const formatDate = (intl, date, DATE_FORMATS, format = 'DD/MM/YYYY') => {
  if (!date) return '';
  const foundFormat = DATE_FORMATS[format] || DATE_FORMATS.default;
  return intl.formatDate(date, {
    ...foundFormat,
  });
};

export const getQueryParamFromUrl = (path, queryParamName) => {
  const { query } = qs.parseUrl(path);
  const camelCased = humps.camelizeKeys(query);
  return _get(camelCased, `${queryParamName}`);
};

export const camelCasePathQueryParams = path => {
  const { query, url } = qs.parseUrl(path);
  const camelCased = humps.camelizeKeys(query);
  return `${url}?${qs.stringify(camelCased)}`;
};

export const formatBytes = (bytes, decimals = 0) => {
  if (bytes < 1024) return `${bytes} Bytes`;
  else if (bytes < 1048576) return `${(bytes / 1024).toFixed(decimals)} KB`;
  else if (bytes < 1073741824) return `${(bytes / 1048576).toFixed(decimals)} MB`;
  return `${(bytes / 1073741824).toFixed(decimals)} GB`;
};

export const getExtensionlessName = name =>
  name
    .split('.')
    .slice(0, -1)
    .join('.');

export const getFileExtension = fileName => {
  const regEx = /(?:\.([^.]+))?$/;
  return regEx.exec(fileName)[1];
};

export const removeEmptyKeysFromObject = object => {
  const newObj = {};
  Object.keys(object).forEach(k => {
    if (object[k] && object[k] !== undefined) newObj[k] = object[k];
  });
  return newObj;
};

export const sanitizeEventToValue = event => {
  const isArray = event instanceof Array;
  let value = event.value || (event.target && event.target.value) || '';
  if (isArray && !value) {
    value = event.map(option => option.value).join(',');
  }
  return value;
};

export const isValidDate = date =>
  date instanceof Date && !Number.isNaN(parseFloat(Date.parse(date)));

export const isIE = () => window.navigator.appVersion.toString().indexOf('.NET') > 0;

// Download the file from binary response
export const downloadFileFromBinaryResponse = (response, fileName) => {
  let fileNameWithExtension = '';
  /*
    content disposition header contains the filename otherwise take the content type header and match it with the correct mime type extensions
    example :
    content-disposition: attachment; filename=Claims.xlsx
    content-type: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  */
  const disposition = response.request.getResponseHeader('Content-Disposition');
  const type = response.request.getResponseHeader('Content-Type');

  if (disposition && disposition.indexOf('attachment') !== -1) {
    // regular expression to get the filename from content disposition header (attachment; filename=Claims.xlsx)
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      fileNameWithExtension = matches[1].replace(/['"]/g, ''); // remove quotes if any
    }
  } else {
    fileNameWithExtension = `${fileName}.${mimeTypes[type]}`;
  }

  // create blob from binary
  let blob = null;
  try {
    blob = new Blob([response.data], { type });
  } catch (e) {
    // Old browser, need to use blob builder
    window.BlobBuilder =
      window.BlobBuilder ||
      window.WebKitBlobBuilder ||
      window.MozBlobBuilder ||
      window.MSBlobBuilder;
    if (window.BlobBuilder) {
      const bb = new BlobBuilder(); //eslint-disable-line
      bb.append(response.data);
      blob = bb.getBlob(type);
    }
  }
  // different for Internet Explorer => msSaveOrOpenBlob
  if (isIE()) {
    window.navigator.msSaveOrOpenBlob(blob, fileNameWithExtension);
  } else {
    // https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
    // create temp link and remove it later along with blob object
    const tempLink = document.createElement('a');

    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.download = fileNameWithExtension;

    document.body.appendChild(tempLink);
    tempLink.click();
    window.URL.revokeObjectURL(blob);
    document.body.removeChild(tempLink);
  }
};

export const mapDataToTree = (data, parentId, level = 2, includeNullParents = false) => {
  const tree = [];

  data.forEach(item => {
    const currentParent = _get(item, 'relationships.parent.data.id', null);
    if (includeNullParents && currentParent === null) {
      const currentItem = item;
      currentItem.level = 2;
      data = data.filter(client => client.id !== item.id);
      mapDataToTree(data, parentId, level + 1, includeNullParents);
      tree.push(item);
    } else if (currentParent === parentId) {
      const currentItem = item;
      currentItem.level = level;
      const children = mapDataToTree(data, currentItem.id, level + 1, includeNullParents);

      if (children.length) {
        currentItem.children = children;
      }

      tree.push(currentItem);
    }
  });
  return tree;
};

export const isValidEmail = email => {
  // taken from yup validation
  // eslint-disable-next-line
  const rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
  return email.search(rEmail) !== -1;
};

/*
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 * https://gist.github.com/Yimiprod/7ee176597fef230d1451
 */
export const diffObject = (object, base) => {
  function changes(object, base) {
    let arrayIndexCounter = 0;
    return _transform(object, function(result, value, key) {
      if (!_isEqual(value, base[key])) {
        const resultKey = _isArray(base) ? arrayIndexCounter++ : key;
        result[resultKey] =
          _isObject(value) && _isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
};

export const randomIntFromInterval = (min = 0, max = 10) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const hasFormErrors = errors =>
  Object.keys(errors)
    .filter(key => errors[key]?.level !== 'warning')
    .some(key => {
      const value = errors[key];
      if (value && typeof value === 'object') {
        return hasFormErrors(value);
      }
      return typeof value !== 'undefined';
    });

export const validateSSN = value => {
  if (!value) {
    return true; // we return valid true here because the field can be optional --> should be catched with other validation if it is required
  }
  let number = value.substring(0, 9);
  const controlNumber = parseInt(value.substring(9, 11), 10);
  const mod = 97;
  const year = parseInt(value.substring(0, 2), 10);
  number = year < 10 ? parseInt(`2${number}`, 10) : parseInt(number, 10);
  return mod - (number % mod) === controlNumber;
};

export const renameObjectKey = (obj, oldKey, newKey) => {
  if (_has(obj, oldKey)) {
    _set(obj, newKey, _get(obj, oldKey));
    _unset(obj, oldKey);
  }
  return obj;
};

export const concatStrings = (strings, seperator = ', ') => {
  if (!strings) return '';
  const arrStr = typeof strings === 'string' ? [strings] : strings;
  return arrStr.filter(Boolean).join(seperator);
};
