import styled from 'styled-components';

export const Wrap = styled.div``;

export const InputWrap = styled.div`
  display: inline-block;
  label span {
    color: ${({ theme }) => theme.palette.neutral01[500]};
  }
  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(4)}px;
  }
`;
