import React from 'react';

import shouldRouteBeInverted from 'utils/invertedRoute';

import { BackgroundWrapper } from './Background.styled';
import { BackgroundProps } from './BackgroundContainer';

const Background: React.FC<BackgroundProps> = ({ route }) => {
  const inverted = shouldRouteBeInverted(route);

  return <BackgroundWrapper $inverted={inverted} />;
};

export default Background;
