import config from 'config/env'; // eslint-disable-line

import apiManager from './axios/apiManager';

const { API_BASE } = config;

export const fetchPublicEndpoints = () =>
  apiManager.get(`${API_BASE}/public_api/`).then(response => response.data);

export const fetchPrivateEndpoints = path => apiManager.get(path).then(response => response.data);

export const fetchAllCategories = path => apiManager.get(path).then(response => response.data);
