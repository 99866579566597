import { transparentize } from 'polished';
import DayPicker from 'react-day-picker';
import styled, { css } from 'styled-components';

import TextField from 'components/@inputs/TextField/TextInput';

const CELLSPACING = '4px';

const startEndStyle = css`
  content: '';
  display: block;
  position: absolute;
  background-color: ${({ theme }) => transparentize(0.95, theme.palette.brand01[500])};
  top: 0;
  z-index: 1;
  width: calc(50% + ${CELLSPACING});
  height: 32px;
`;

export const DayPickerStyled = styled(DayPicker)`
  * {
    outline: none !important;
  }

  color: ${({ theme }) => theme.palette.neutral01[600]};
  line-height: 1;
  .DayPicker-wrapper {
    padding-bottom: 0;
  }
  .DayPicker-Month {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: ${CELLSPACING};
    margin: 0;
    min-width: 265px;
    &:last-child {
      margin-right: 0;
    }
    margin-right: ${({ numberOfMonths = 1 }) => (numberOfMonths > 1 && `16px`) || ''};
  }
  .DayPicker-Day--today {
    color: ${({ theme }) => theme.palette.brand01[400]};
    font-weight: 700;
  }
  .DayPicker-Day {
    padding: 0px;
    outline: none;
    font-weight: 400;
  }
  .DayPicker-Weekdays {
    font-weight: 400;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.neutral01[600]};
  }
  .DayPicker-NavBar {
    position: absolute;
    width: 100%;
    height: 24px;
    margin-top: 0px;
  }
  .DayPicker-NavButton--prev,
  .DayPicker-NavButton--next {
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
  }
  .DayPicker-NavButton--prev {
    left: calc(2 * ${CELLSPACING});
  }
  .DayPicker-NavButton--next {
    right: calc(2 * ${CELLSPACING});
  }

  .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    &:after {
      right: -4px;
      ${({ modifiers = {} }) =>
        modifiers.start && modifiers.end && modifiers.start < modifiers.end && startEndStyle};
    }
  }

  .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    &:before {
      left: 0px;
      ${({ modifiers = {} }) =>
        modifiers.start && modifiers.end && modifiers.start < modifiers.end && startEndStyle};
    }
  }

  .DayPicker-Day--outside {
    color: ${({ theme }) => theme.palette.neutral01[500]};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => theme.palette.brand01[500]};
    font-weight: 700;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: ${({ theme }) => theme.palette.brand01[500]};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => transparentize(0.95, theme.palette.brand01[500])};
    font-weight: 400;
    border-radius: 0;
    color: ${({ theme }) => theme.palette.neutral01[600]};
    &:after {
      ${startEndStyle};
      width: ${CELLSPACING};
      right: -${CELLSPACING};
    }
  }
  .DayPicker-Day--disabled {
    color: ${({ theme }) => theme.palette.neutral01[300]};
  }
`;
export const DatePickerDay = styled.div`
  height: 32px;
  width: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DatePickerWrap = styled.div`
  margin: 0px -4px;
`;

export const DatePickerFooterWrap = styled.div`
  text-align: right;
  margin-top: 8px;
`;

export const DatePickerInputWrap = styled.div`
  position: relative;
`;

export const InputStyled = styled(TextField)`
  && {
    cursor: pointer;
    * {
      cursor: pointer;
    }
  }
`;
