import { HEADERS } from 'constants/apiHeaders';

import { ApiInstanceType } from '.';
import { ExpertContext, ExpertsResponse } from './types/experts.types';
class ExpertsApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchExperts = (
    path: string,
    context: ExpertContext,
    groupId: string,
    queryParams: { [index: string]: string } = {},
  ) =>
    this.api
      .get<ExpertsResponse>(path, {
        params: {
          ...queryParams,
          context: context.toUpperCase(),
        },
        headers: {
          /* manualy set the groupId header here because within
           the clients detail view the groupid header can defer from the main selected group */
          [HEADERS.GROUP_ID]: groupId,
        },
      })
      .then(response => response.data);
}

export default ExpertsApi;
