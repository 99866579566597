import _get from 'lodash/get';
import { Action } from 'redux-act';
import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchApiAvatar } from 'api/avatar.api';
import { AvatarFetchPayload } from 'types/store/avatars/avatars.types';

import {
  avatarFetchFail,
  avatarFetchStart,
  avatarFetchSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './avatars.actions';

function* avatarFetchFlow({ payload }: Action<AvatarFetchPayload>) {
  const { endpoint, context, id } = payload;
  try {
    let image = undefined;
    if (endpoint) {
      const { data } = yield call(fetchApiAvatar, endpoint);
      image = _get(data, 'attributes.base64Image');
    }

    yield put(avatarFetchSuccess({ id, context, data: image }));
  } catch (error) {
    yield put(avatarFetchFail({ error, id, context }));
  }
}
// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

function* avatarsSaga() {
  yield takeEvery(avatarFetchStart.getType(), avatarFetchFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default avatarsSaga;
