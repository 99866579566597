import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';

export const LoaderWrap = styled.div<{ size: number }>`
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
`;
const borderWidth = 4;
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const rotateNeg360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

export const LoaderOuter = styled.div`
  position: absolute;
  border: ${({ theme }) => `${borderWidth}px solid ${theme.palette.brand01[400]}`};
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-top-color: transparent;
  animation: ${rotate360} 1s cubic-bezier(0.62, 0.28, 0.23, 0.99) infinite;
`;
export const LoaderInner = styled.div`
  position: absolute;
  border: ${({ theme }) =>
    `${borderWidth}px solid ${transparentize(0.6, theme.palette.brand01[400])}`};
  border-radius: 50%;
  width: calc(100% - ${borderWidth * 2}px);
  height: calc(100% - ${borderWidth * 2}px);
  left: ${borderWidth}px;
  top: ${borderWidth}px;
  border-top-color: transparent;
  animation: ${rotateNeg360} 1s cubic-bezier(0.62, 0.28, 0.23, 0.99) infinite;
`;
