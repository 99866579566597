import React, { Fragment } from 'react';

import FormGroup from '@material-ui/core/FormGroup';

import { Checkbox, Label } from 'components/@inputs';

interface CheckboxOption {
  label: React.ReactNode;
  value: number | string;
}

export interface CheckboxGroupProps {
  value?: { [index: string]: boolean };
  id?: string;
  options: CheckboxOption[];
  label?: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  hasError?: boolean;
  orientation?: 'horizontal' | 'vertical';
  isOptional?: boolean;
  isDisabled?: boolean;
}

const RadioGroup: React.FC<CheckboxGroupProps> = ({
  id,
  value,
  onChange,
  options,
  label,
  hasError,
  onClick,
  isDisabled = false,
  orientation = 'vertical',
  isOptional,
}) => (
  <Fragment>
    {label && (
      <Label isOptional={isOptional} hasError={hasError} hasValue={!!value}>
        {label}
      </Label>
    )}
    <FormGroup row={orientation === 'horizontal'} onChange={onChange}>
      {options.map(option => (
        <Checkbox
          id={id}
          onClick={onClick}
          disabled={isDisabled}
          key={option.value}
          value={option.value}
          checked={!!value && value[option.value]}
          label={option.label}
        />
      ))}
    </FormGroup>
  </Fragment>
);

RadioGroup.defaultProps = {
  options: [],
  orientation: 'vertical',
};

export default RadioGroup;
