import React from 'react';

import { useField } from 'formik';
import { useIntl } from 'react-intl';

import { getFieldErrors } from 'utils/form';

import Field from 'components/@inputs/Field';

import CheckBox, { CheckboxProps } from './Checkbox';

export interface CheckBoxFieldProps extends Omit<CheckboxProps, 'value' | 'onChange' | 'iconSize'> {
  name: string;
  label?: string;
  bottomLabel?: string;
  isRequired?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  name,
  label = '',
  bottomLabel,
  fullWidth,
  isRequired,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const errors = getFieldErrors(name, meta);
  const intl = useIntl();
  return (
    <Field
      errors={errors}
      bottomLabel={bottomLabel}
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
    >
      <CheckBox
        label={intl.messages.hasOwnProperty(label) ? intl.formatMessage({ id: label }) : label}
        id={name}
        {...field}
        {...rest}
        data-test-id={name}
        checked={field.value}
      />
    </Field>
  );
};

export default CheckBoxField;
