import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import {
  RiskData,
  RiskDetailFetchStartPayload,
  RiskDetailFetchSuccessPayload,
  RiskDetails,
  RiskFetchStartPayload,
  RiskFetchSuccessPayload,
} from './risk.types';

export type RiskStateData = RiskData & { details?: RiskDetails };

export type RiskState = {
  isLoading: StoreDefaultState['isLoading'];
  isDetailsLoading: StoreDefaultState['isLoading'];
  data?: { [index: string]: RiskStateData };
  error: StoreDefaultState['error'];
  detailsError: StoreDefaultState['error'];
};

export const INITIAL_STATE: RiskState = {
  isLoading: false,
  isDetailsLoading: false,
  data: undefined,
  error: undefined,
  detailsError: undefined,
};

const riskSlice = createSlice({
  name: 'risk',
  initialState: INITIAL_STATE,
  reducers: {
    riskFetchStart: (state, _action: PayloadAction<RiskFetchStartPayload>) => {
      state.isLoading = true;
      state.error = undefined;
    },
    riskFetchSuccess: (state, { payload }: PayloadAction<RiskFetchSuccessPayload>) => {
      state.isLoading = false;
      state.data = { ...state.data, ...payload.data };
    },
    riskFetchFail: (state, { payload }: PayloadAction<{ error: RiskState['error'] }>) => {
      state.isLoading = false;
      state.error = payload.error;
    },
    riskDetailsFetchStart: (state, _action: PayloadAction<RiskDetailFetchStartPayload>) => {
      state.isDetailsLoading = true;
      state.detailsError = undefined;
    },
    riskDetailsFetchSuccess: (state, { payload }: PayloadAction<RiskDetailFetchSuccessPayload>) => {
      state.isDetailsLoading = false;
      state.detailsError = undefined;
      const newRecord = state.data?.[payload.id]
        ? {
            [payload.id]: { ...state.data[payload.id], details: payload.data },
          }
        : {};
      state.data = {
        ...state.data,
        ...newRecord,
      };
    },
    riskDetailsFetchFail: (
      state,
      { payload }: PayloadAction<{ error: RiskState['detailsError'] }>,
    ) => {
      state.detailsError = payload.error;
      state.isDetailsLoading = false;
    },
  },
});

export const {
  riskFetchStart,
  riskFetchSuccess,
  riskFetchFail,
  riskDetailsFetchStart,
  riskDetailsFetchSuccess,
  riskDetailsFetchFail,
} = riskSlice.actions;

export default riskSlice.reducer;
