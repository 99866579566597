import React, { PureComponent } from 'react';

//TODO: remove!!
const breakpoints = {
  small: 320,
  medium: 768,
  large: 1280,
  xl: 1920,
};
interface MediaListWatcher {
  small: MediaQueryList;
  medium: MediaQueryList;
  large: MediaQueryList;
  [index: string]: MediaQueryList;
}

export interface MediaList {
  small: boolean;
  medium: boolean;
  large: boolean;
  isTouchDevice: boolean;
}

export interface WithMedia {
  media: MediaList;
}

const domAvailability =
  typeof window !== 'undefined' && window.document && window.document.createElement;

const mediaList: MediaListWatcher | undefined = domAvailability
  ? {
      small: window.matchMedia(`(max-width: ${breakpoints.medium - 1}px)`),
      medium: window.matchMedia(
        `(min-width: ${breakpoints.medium}px) and (max-width: ${breakpoints.large - 1}px)`,
      ),
      large: window.matchMedia(`(min-width:${breakpoints.large}px)`),
    }
  : undefined;

const withMedia = <P extends object>(
  WrappedComponent: React.ComponentType<Omit<P, keyof WithMedia> & WithMedia>,
) =>
  class withMedia extends PureComponent<Omit<P, keyof WithMedia>, WithMedia> {
    state = {
      media: {
        small: false,
        medium: false,
        large: true,
        isTouchDevice: false,
      },
    };

    componentDidMount() {
      if (mediaList) {
        Object.keys(mediaList).forEach(media =>
          mediaList[media].addListener(this.mediaQueryChanged),
        );
        this.mediaList();
      }
    }

    componentWillUnmount() {
      if (mediaList) {
        Object.keys(mediaList).forEach(media =>
          mediaList[media].removeListener(this.mediaQueryChanged),
        );
      }
    }
    checkToucheDevice = () => 'ontouchstart' in window;

    mediaList = () => {
      if (mediaList) {
        this.setState({
          media: {
            small: mediaList.small.matches,
            medium: mediaList.medium.matches,
            large: mediaList.large.matches,
            isTouchDevice: this.checkToucheDevice(),
          },
        });
      }
    };

    mediaQueryChanged = () => {
      this.mediaList();
    };

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };

export default withMedia;
