import React from 'react';

import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import { CategoryListIds } from 'constants/user.constants';

import Tag from 'components/@common/Tag';

import { RowType } from '../UserTable';

interface Props {
  row: RowType;
  category?: string;
}

const StatusCell: React.FC<Props> = ({ row, category }) => {
  const isProfileCompleted = _get(row, 'attributes.profileCompleted');
  const isMainUser = !_get(row, 'relationships.owner.data.id');

  if (!category) {
    return null;
  }

  if (!isProfileCompleted && category === CategoryListIds.MyUsers) {
    return (
      <Tag>
        <FormattedMessage id="users.statuses.invitation_sent" />
      </Tag>
    );
  }
  if (category === CategoryListIds.AllUsers && isMainUser) {
    return (
      <Tag>
        <FormattedMessage id="users.statuses.primary_user" />
      </Tag>
    );
  }
  return null;
};

export default StatusCell;
