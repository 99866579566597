import config from 'config';

import MESSAGES_EN from './en.json';
import MESSAGES_FR from './fr.json';
import MESSAGES_NL from './nl.json';

const { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE } = config;

const locales = ['en', 'nl', 'fr'];

const messages: { [key: string]: { [key: string]: any } } = {
  en: MESSAGES_EN,
  nl: MESSAGES_NL,
  fr: MESSAGES_FR,
};

const getDefaultLocaleMessages = () => messages[DEFAULT_LOCALE];

export { getDefaultLocaleMessages, locales, messages, DEFAULT_LOCALE, DEFAULT_LOCALE_DATE };
