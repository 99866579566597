import React from 'react';

import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { LinkButton } from 'components/@common/Link';
import { H5 } from 'components/@typography/Titles';
import Icon from 'components/Icon';
import {
  UIcoFeedbackError,
  UIcoFeedbackInfo,
  UIcoFeedbackSuccess,
  UIcoFeedbackWarning,
  UIcoSluiten,
} from 'components/Icons';

import { NotificationActions, Wrap } from './Notification.styled';
import useNotification, { CustomOptions } from './useNotification';

export type NotificationProps = CustomOptions & {
  id?: string | number;
  key?: string | number;
  message?: string | React.ReactNode;
  canClose?: boolean;
  fullWidth?: boolean;
  onClose?: (e: React.MouseEvent, reason: string, key?: string | number) => void;
};

const variants = {
  success: {
    icon: UIcoFeedbackSuccess,
    color: 'accent01.400',
    bgColor: '#F3F8EC',
  },
  warning: {
    icon: UIcoFeedbackWarning,
    color: 'accent02.100',
    bgColor: '#FFF4E5',
  },
  error: {
    icon: UIcoFeedbackError,
    color: 'accent02.400',
    bgColor: '#FFF1F0',
  },
  info: {
    icon: UIcoFeedbackInfo,
    color: 'brand01.400',
    bgColor: '#EBF9FF',
  },
};

const Notification: React.FC<NotificationProps> = React.forwardRef<
  HTMLDivElement,
  NotificationProps
>(
  (
    {
      id,
      message,
      description,
      actions,
      canClose = true,
      fullWidth = false,
      messageValues = {},
      onActionClick = () => {},
      variant = 'info',
    },
    ref,
  ) => {
    const { closeSnackbar } = useNotification();
    const handleDismiss = () => {
      closeSnackbar(id);
    };

    const VariantIcon = variants[variant].icon;
    const variantColor = variants[variant].color;
    const bgColor = variants[variant].bgColor;

    return (
      <Wrap fullWidth={fullWidth} bgColor={bgColor} ref={ref} data-test-id={`snackbar-${variant}`}>
        <Box display="flex" padding={4}>
          <Icon color={variantColor} size={32} spacing={10}>
            <VariantIcon />
          </Icon>
          <Box pl={2} pr={2} pt={1} pb={1}>
            {message && (
              <H5 color="neutral01.600">
                {<FormattedMessage id={`${message}`} values={messageValues} />}
              </H5>
            )}
            {(description || actions) && (
              <Box paddingTop={2}>
                {description && (
                  <Box m={0} component="p">
                    {typeof description === 'string' ? (
                      <FormattedMessage id={description} />
                    ) : (
                      description
                    )}
                  </Box>
                )}
                {actions && (
                  <NotificationActions>
                    {actions.map((action, i) => (
                      <LinkButton key={`action${id}${i}`} onClick={e => onActionClick(i, e)}>
                        {typeof action === 'string' ? <FormattedMessage id={action} /> : action}
                      </LinkButton>
                    ))}
                  </NotificationActions>
                )}
              </Box>
            )}
          </Box>

          {canClose && (
            <Box marginLeft="auto">
              <Icon color="brand01.200" onClick={handleDismiss}>
                <UIcoSluiten />
              </Icon>
            </Box>
          )}
        </Box>
      </Wrap>
    );
  },
);

export default Notification;
