import React, { useRef } from 'react';

import { Box, MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import Button from 'components/@common/Button';
import Icon from 'components/Icon';
import { UIcoPijlOnder } from 'components/Icons';
import { useMedia } from 'components/Media';

import { Menu } from './ActionsMenu.styled';
interface Props {
  menuButtonText: string;
}

const Actions: React.FC<Props> = ({ children, menuButtonText }) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const media = useMedia();

  return (
    <Box
      clone
      p={{ xs: 4, md: 0 }}
      position={{ xs: 'fixed', md: 'static' }}
      bgcolor={{ xs: 'neutral01.50', md: 'transparant' }}
      bottom={56}
      left={0}
      right={0}
      zIndex={3}
      boxShadow={{ xs: 2, md: 0 }}
      mt={{ xs: 4, md: 0 }}
    >
      <div ref={boxRef}>
        <Box clone position="relative">
          <Button onClick={handleOpenMenu} fullWidth={media.small}>
            <Box component="span" pr={4}>
              <FormattedMessage id={menuButtonText} />
            </Box>
            <Box clone position="absolute" right={6}>
              <Icon size={24} color="neutral01.50">
                <UIcoPijlOnder />
              </Icon>
            </Box>
          </Button>
        </Box>
        <Menu
          disablePortal
          anchorEl={boxRef.current}
          getContentAnchorEl={null}
          open={menuOpen}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: !media.small ? 'bottom' : 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: !media.small ? 'top' : 'bottom',
            horizontal: 'right',
          }}
          elevation={3}
          marginThreshold={0}
          PaperProps={{
            square: media.small,
          }}
        >
          {React.Children.map(children, (child, index) => (
            <Box key={index} clone textAlign="right">
              <MenuItem>{child}</MenuItem>
            </Box>
          ))}
        </Menu>
      </div>
    </Box>
  );
};

export default Actions;
