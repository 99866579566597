import { AxiosError } from 'axios';
import { createReducer } from 'redux-act';

import {
  avatarFetchFail,
  avatarFetchStart,
  avatarFetchSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './avatars.actions';

interface InitialState {
  [index: string]: {
    isLoading: boolean;
    error?: AxiosError;
    image?: string;
    hasFetched?: number;
  };
}

export const INITIAL_STATE = {};

const reducer = createReducer<InitialState>({}, INITIAL_STATE);

reducer.on(avatarFetchStart, (state, { id, context }) => ({
  ...state,
  [`${context}.${id}`]: {
    isLoading: true,
    error: undefined,
  },
}));

reducer.on(avatarFetchSuccess, (state, { id, context, data }) => ({
  ...state,
  [`${context}.${id}`]: {
    isLoading: false,
    error: undefined,
    image: data,
    hasFetched: 1,
  },
}));

reducer.on(avatarFetchFail, (state, { id, context, error }) => ({
  ...state,
  [`${context}.${id}`]: {
    isLoading: false,
    error: error || 'unknown error',
    hasFetched: 1,
  },
}));

// (-- APPEND REDUCER ACTION MAPPING HERE --) !!! do not move this comment !!!

export default reducer;
