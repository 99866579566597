import { connect } from 'react-redux';

import { goToPage } from 'store/routing/routing.actions';
import { getPrevLocation, getRoutesMap } from 'store/routing/routing.selectors';

import BackLink from './BackLink';

const mapStateToProps = state => ({
  prevLocation: getPrevLocation(state),
  routesMap: getRoutesMap(state),
});
const mapDispatchToProps = {
  goToPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BackLink);
