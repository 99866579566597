import { ReactComponent as UIcoAansprakelijkheid } from 'assets/svg/u_ico_aansprakelijkheid.svg';
import { ReactComponent as UIcoArbeidsOngeschiktheid } from 'assets/svg/u_ico_arbeidsongeschiktheid.svg';
import { ReactComponent as UIcoFallback } from 'assets/svg/u_ico_fallback.svg';
import { ReactComponent as UIcoFinancial } from 'assets/svg/u_ico_financial.svg';
import { ReactComponent as UIcoFire } from 'assets/svg/u_ico_fire.svg';
import { ReactComponent as UIcoGewaarborgdInkomen } from 'assets/svg/u_ico_gewaarborgd_inkomen.svg';
import { ReactComponent as UIcoLAansprakelijkheid } from 'assets/svg/u_ico_l_aansprakelijkheid.svg';
import { ReactComponent as UIcoLArbeidsOngeschiktheid } from 'assets/svg/u_ico_l_arbeidsongeschiktheid.svg';
import { ReactComponent as UIcoLBouw } from 'assets/svg/u_ico_l_bouw.svg';
import { ReactComponent as UIcoLBrand } from 'assets/svg/u_ico_l_brand.svg';
import { ReactComponent as UIcoLFinancial } from 'assets/svg/u_ico_l_financial.svg';
import { ReactComponent as UIcoLGewaarborgdInkomen } from 'assets/svg/u_ico_l_gewaarborgd_inkomen.svg';
import { ReactComponent as UIcoLMarine } from 'assets/svg/u_ico_l_marine.svg';
import { ReactComponent as UIcoLMedicalCare } from 'assets/svg/u_ico_l_medical_care.svg';
import { ReactComponent as UIcoLMultiple } from 'assets/svg/u_ico_l_multiple.svg';
import { ReactComponent as UIcoLPeople } from 'assets/svg/u_ico_l_people.svg';
import { ReactComponent as UIcoLVerhiclesFleet } from 'assets/svg/u_ico_l_vehicles_fleet.svg';
import { ReactComponent as UIcoLZaakverzekering } from 'assets/svg/u_ico_l_zaakverzekering.svg';
import { ReactComponent as UIcoMarine } from 'assets/svg/u_ico_marine.svg';
import { ReactComponent as UIcoMedicalCare } from 'assets/svg/u_ico_medical_care.svg';
import { ReactComponent as UIcoMultiple } from 'assets/svg/u_ico_multiple.svg';
import { ReactComponent as UIcoPeople } from 'assets/svg/u_ico_people.svg';
import { ReactComponent as UIcoSBouw } from 'assets/svg/u_ico_s_bouw.svg';
import { ReactComponent as UIcoVerhiclesFleet } from 'assets/svg/u_ico_verhicles_fleet.svg';
import { ReactComponent as UIcoVerzekeringen } from 'assets/svg/u_ico_verzekeringen.svg';
import { ReactComponent as UIcoZaakverzekering } from 'assets/svg/u_ico_zaakverzekering.svg';

import { DEFAULT_PARAMS } from 'constants/default.queryparams';

export const QUERY_PARAMS = {
  POLICY_CATEGORY_ID: 'policyCategoryId',
  POLICY_ID: 'policyId',
  RISK_ENTITY_CATEGORY: 'riskCategoryIds',
  RISK_ID: 'riskId',
  CLAIM_ID: 'claimId',
  INVOICE_ID: 'invoiceId',
  ...DEFAULT_PARAMS,
};

const DEFAULT_TYPES = {
  ALL: {
    icon: UIcoVerzekeringen,
    iconLarge: UIcoVerzekeringen,
  },
  MULTIPLE: {
    icon: UIcoMultiple,
    iconLarge: UIcoLMultiple,
  },
  DEFAULT: {
    icon: UIcoFallback,
    iconLarge: UIcoFallback,
  },
};

export const TYPES = {
  ...DEFAULT_TYPES,
  BUILDINGS: {
    icon: UIcoFire,
    iconLarge: UIcoLBrand,
  },
  PEOPLE: {
    icon: UIcoPeople,
    iconLarge: UIcoLPeople,
  },
  LIABILITY: {
    icon: UIcoAansprakelijkheid,
    iconLarge: UIcoLAansprakelijkheid,
  },
  MATERIAL: {
    icon: UIcoZaakverzekering,
    iconLarge: UIcoLZaakverzekering,
  },
  FLEET: {
    icon: UIcoVerhiclesFleet,
    iconLarge: UIcoLVerhiclesFleet,
  },
  FINANCIAL_LINES: {
    icon: UIcoFinancial,
    iconLarge: UIcoLFinancial,
  },
  CONSTRUCTION: {
    icon: UIcoSBouw,
    iconLarge: UIcoLBouw,
  },
  FIRE: {
    icon: UIcoFire,
    iconLarge: UIcoLBrand,
  },
  MARINE: {
    icon: UIcoMarine,
    iconLarge: UIcoLMarine,
  },
  HCIC: {
    icon: UIcoArbeidsOngeschiktheid,
    iconLarge: UIcoLArbeidsOngeschiktheid,
  },
  IC: {
    icon: UIcoGewaarborgdInkomen,
    iconLarge: UIcoLGewaarborgdInkomen,
  },
  MC: {
    icon: UIcoMedicalCare,
    iconLarge: UIcoLMedicalCare,
  },
};

export const STATUSES = {
  ACTIVE: { intlKey: `policies.status.active`, badgeColor: 'success' },
  PENDING: {
    intlKey: `policies.status.pending`,
    badgeColor: 'warning',
  },
  CLAIMS: {
    intlKey: `policies.status.pending_claims`,
    badgeColor: 'default',
  },
};

export const STATUS_PENDING = 'PENDING';

export const HIDDEN_FILTERS = ['claimId', 'invoiceId'];
