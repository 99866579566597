import React from 'react';

import { Hidden } from '@material-ui/core';
import useExperts from 'hooks/useExperts/useExperts';

import { analyticsService } from 'services';
import { ExpertContext, ExpertData } from 'services/Api/types/experts.types';

import ExpertsDesktop from './ExpertsDesktop';
import ExpertsMobile from './ExpertsMobile';

interface Props {
  context: ExpertContext;
  id?: string;
  groupId?: string;
  inverted?: boolean;
}

const Experts: React.FC<Props> = ({ context, id, groupId, inverted = false }) => {
  const { data, status } = useExperts(context, id, groupId);

  const handleExpertInfoShow = (isMobile: boolean, expert?: ExpertData) => {
    if (isMobile) {
      analyticsService.events.trackExpertsLinkClick(context.toUpperCase());
    } else {
      const firstName = expert?.attributes.firstName;
      const lastName = expert?.attributes.lastName;
      const expertFullName = [firstName, lastName].filter(Boolean).join(' ');
      analyticsService.events.trackExpertsHoverForDetails(context.toUpperCase(), expertFullName);
    }
  };

  const handleExpertInfoClick = (type: string, id: string) => () => {
    analyticsService.events.trackExpertsCommunicationClick(type, id);
  };

  return (
    <>
      <Hidden mdDown>
        <ExpertsDesktop
          onExpertInfoClick={handleExpertInfoClick}
          onExpertInfoShow={handleExpertInfoShow}
          data={data}
          isLoading={status === 'loading'}
          inverted={inverted}
        />
      </Hidden>
      <Hidden mdUp>
        <ExpertsMobile data={data} isLoading={status === 'loading'} />
      </Hidden>
    </>
  );
};

export default Experts;
