import styled from 'styled-components';

export const LinkWrap = styled.div`
  line-height: 1;
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const InfoWrap = styled.div`
  min-width: 250px;
  color: ${({ theme }) => theme.palette.neutral01[600]};
  line-height: 1.5;
  font-size: 14px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    min-width: 0;
  }
`;
