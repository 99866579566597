import React, { useContext } from 'react';

import { Box, Tabs as MuiTabs, TabsProps } from '@material-ui/core';

import Button from 'components/@common/Button';
import Icon from 'components/Icon';
import { UIcoPijlOnder } from 'components/Icons';
import { useMedia } from 'components/Media';

import { tabContext } from './TabContext';
import { Menu, TabsWrap } from './Tabs.styled';

export type Props = Omit<TabsProps, 'value' | 'onChange'>;

const Tabs: React.FC<Props> = ({ children }) => {
  const {
    activeId,
    tabs,
    handleCloseMobileTabMenu,
    handleOpenMobileTabMenu,
    anchorEl,
  } = useContext(tabContext);

  const getSelectedTabContent = () => {
    const child = React.Children.toArray(children)[tabs.indexOf(activeId)];
    if (!React.isValidElement(child)) {
      return 'No content';
    }
    return child.props.children;
  };
  const media = useMedia();

  if (media.small) {
    return (
      <Box p={3}>
        <Box clone position="relative" pr={8}>
          <Button fullWidth variation="secondary" onClick={handleOpenMobileTabMenu}>
            {getSelectedTabContent()}
            <Box clone position="absolute" right={6} mt={-1 / 2}>
              <Icon size={24} color="brand01.300">
                <UIcoPijlOnder />
              </Icon>
            </Box>
          </Button>
        </Box>
        <Box clone width="100%">
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            onClose={handleCloseMobileTabMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {children}
          </Menu>
        </Box>
      </Box>
    );
  }
  return (
    <TabsWrap>
      <MuiTabs variant={media.small ? 'fullWidth' : 'standard'} value={activeId}>
        {children}
      </MuiTabs>
    </TabsWrap>
  );
};

export default Tabs;
