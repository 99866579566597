import useNotification from 'hooks/useNotification/useNotification';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { queryKeys } from 'constants/querykeys';
import { apiService } from 'services';
import { IncapacityPeriod, IncapacityPeriodResponse } from 'services/Api/types/claim.types';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { ErrorTrace, Store } from 'types/store/store.types';

type ClaimIncapactyPeriodsRequestBody = {
  body: IncapacityPeriod[];
};

const useUpdateClaimIncapacityPeriods = (claimId: string) => {
  const queryClient = useQueryClient();
  const endpoint = useSelector((state: Store) => getEndpoint(state, 'claims'));
  const icPeriodsLink = `${endpoint}/${claimId}/ic/incapacity_periods`;
  const { addNotification } = useNotification();

  const { mutate, ...updateIncapacityPeriodsMutation } = useMutation<
    IncapacityPeriodResponse,
    ErrorTrace,
    ClaimIncapactyPeriodsRequestBody
  >(
    values => apiService.claims.updateClaimIncapacityPeriods(icPeriodsLink, { data: values.body }),
    {
      onMutate: () => {
        const incapacityPeriods = queryClient.getQueryData<IncapacityPeriodResponse>([
          queryKeys.claimIcPeriods,
          claimId,
        ]);
        return { incapacityPeriods };
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.claimIcPeriods, claimId]);
        addNotification({
          variant: 'success',
          message: 'claims.detail.incapacity_periods.add.success.text',
        });
      },
      onError: (_error, _values, context) => {
        const ctx = context as { incapacityPeriods: IncapacityPeriodResponse };

        if (ctx.incapacityPeriods) {
          queryClient.setQueryData([queryKeys.claimIcPeriods, claimId], ctx.incapacityPeriods);
        }

        addNotification({
          variant: 'error',
          message: 'general.error.update.text',
        });
      },
    },
  );
  return {
    ...updateIncapacityPeriodsMutation,
    updateIncapacityPeriods: mutate,
  };
};

export default useUpdateClaimIncapacityPeriods;
