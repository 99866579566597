import dayjs from 'dayjs';
import * as Yup from 'yup';

import { ClientReportingCategory } from 'store/client/client.types';

import { FlowType } from 'pages/affiliations/Create/AffiliationsCreate';

const BaseDataFormValidation = (
  reportingCategories: ClientReportingCategory[],
  flowType: FlowType,
) => {
  const nullValidation = Yup.mixed().nullable();
  let reportingCategoriesRequired = {};
  if (reportingCategories && reportingCategories.length > 0) {
    reportingCategoriesRequired = {
      reportingCategories: Yup.array()
        .required()
        .min(reportingCategories.length)
        .compact(),
    };
  }
  return Yup.object().shape({
    firstName: Yup.string()
      .required('general.errors.required')
      .max(50, params => ({
        id: 'general.errors.max',
        values: { max: params.max },
      })),
    lastName: Yup.string()
      .required('general.errors.required')
      .max(50, params => ({
        id: 'general.errors.max',
        values: { max: params.max },
      })),
    personnelNumberUnknown: flowType === 'MAIN' ? Yup.boolean() : nullValidation,
    personnelNumber:
      flowType === 'MAIN'
        ? Yup.string().when('personnelNumberUnknown', {
            is: val => !val,
            then: Yup.string()
              .required('general.errors.required')
              .max(50, params => ({
                id: 'general.errors.max',
                values: { max: params.max },
              })),
          })
        : nullValidation,
    birthDate: Yup.date()
      .typeError('form.error.date.invalid')
      .min(
        dayjs(new Date())
          .subtract(100, 'year')
          .toDate(),
        params => ({
          id: 'general.errors.date.min',
          values: {
            ...params,
            date: dayjs(new Date(params.min)).format('DD/MM/YYYY'),
          },
        }),
      )
      .max(dayjs(new Date()).format('YYYY-MM-DD'), params => ({
        id: 'general.errors.date.max',
        values: {
          ...params,
          date: dayjs(new Date(params.max))
            .add(1, 'day')
            .format('DD/MM/YYYY'),
        },
      }))
      .required('general.errors.required'),
    gender: Yup.string().required('general.errors.required'),
    communicationLanguage: Yup.object().required('general.errors.required'),
    insurantType:
      flowType === 'FAMILY' ? Yup.object().required('general.errors.required') : nullValidation,
    ...reportingCategoriesRequired,
  });
};

export { BaseDataFormValidation };
