import React from 'react';

import { INITIAL_MAX_PER_PAGE } from 'constants/store';

import { PageActions, PerPage } from './components';
import { PaginationWrap } from './TablePagination.styled';

export interface TablePaginationProps {
  onPageChange?: (params: { page: number }) => void;
  isSmall?: boolean;
  onRowsPerPageChange?: (params: { maxPerPage: number }) => void;
  count?: number;
  CustomRightComponent?: React.ReactNode;
  rowsPerPage?: number;
  page?: number | string;
}

const TablePagination: React.FC<TablePaginationProps> & {
  defaultProps: Partial<TablePaginationProps>;
} = ({
  isSmall,
  CustomRightComponent,
  rowsPerPage,
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
  count = 0,
  page = 1,
}) => (
  <PaginationWrap data-test-id="table-pagination-container">
    {!isSmall && (
      <PerPage
        rowsPerPage={rowsPerPage || INITIAL_MAX_PER_PAGE}
        onRowsPerPageChange={perPage => {
          onRowsPerPageChange({ maxPerPage: perPage });
        }}
      />
    )}
    <PageActions
      isSmall={isSmall}
      onPageChange={(e, p) => {
        onPageChange({ page: p });
      }}
      count={count}
      rowsPerPage={rowsPerPage || INITIAL_MAX_PER_PAGE}
      page={parseInt(`${page}`, 10)}
    />
    {!isSmall && CustomRightComponent}
  </PaginationWrap>
);

TablePagination.defaultProps = {
  onPageChange: () => {},
  onRowsPerPageChange: () => {},
  count: 0,
  page: 1,
};

export default TablePagination;
