import { createReducer } from 'redux-act';

import { SystemState } from 'types/store/system.types';

import { systemFail, systemReady, systemStart } from './system.actions';

const INITIAL_STATE: SystemState = {
  booting: false,
  ready: false,
  error: undefined,
};

const reducer = createReducer<SystemState>({}, INITIAL_STATE);

reducer.on(systemStart, state => ({
  ...state,
  booting: true,
  ready: false,
  error: undefined,
}));

reducer.on(systemReady, state => ({
  ...state,
  booting: false,
  ready: true,
  error: undefined,
}));

reducer.on(systemFail, (state, payload) => ({
  ...state,
  booting: false,
  ready: false,
  error: payload,
}));

export default reducer;
