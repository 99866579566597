import React from 'react';

import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { formatBytes } from 'utils/helpers';
import useStepperForm from 'utils/useStepperForm';

import Button from 'components/@common/Button';
import { FileUploadField } from 'components/@inputs/FileUpload';
import { InputField } from 'components/@inputs/TextField';

import { FileValidation } from './DocumentsStep.validation';
import { ContainerProps, FormValues } from './DocumentsStepContainer';

interface Props {
  fileConfig: ContainerProps['fileConfig'];
  saveForm: (values: FormValues) => void;
}

const DocumentsStep: React.FC<Props> = ({ fileConfig, saveForm }) => {
  const { handleNextStepClick, canContinue } = useStepperForm(saveForm);
  return (
    <div>
      <Box mb={6}>
        <FormattedMessage
          id="affiliations.add.step.upload.description"
          values={{ maxFileSize: formatBytes(fileConfig?.maxFileSize) }}
        />
      </Box>
      <Box mb={8}>
        <FileUploadField
          name="files"
          label="documents.add.step.upload.input_label"
          max={5}
          accept={fileConfig?.allowedFileTypes}
          filterFilesSchema={FileValidation(fileConfig)}
        />
      </Box>
      <Box marginBottom={8}>
        <InputField
          name="remark"
          label="affiliations.add.step.upload.remark_label"
          fullWidth
          withCharCount
          maxChars={2000}
          rows="3"
          multiline
        />
      </Box>
      <Button
        data-test-id="affiliations.add.step.documents.actions.next"
        disabled={canContinue}
        onClick={handleNextStepClick}
      >
        <FormattedMessage id="general.stepper.next" />
      </Button>
    </div>
  );
};

export default DocumentsStep;
