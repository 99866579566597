import React, { Fragment } from 'react';

import { FormattedMessage } from 'react-intl';

const DefaultDialog = () => (
  <Fragment>
    <FormattedMessage id="general.dialog.text" />
  </Fragment>
);

export default DefaultDialog;
