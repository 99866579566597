import React, { PureComponent } from 'react';

import { FormattedMessage } from 'react-intl';

import { SelectDropdownNoOptionsLabel as Label, SelectDropdownWrap } from '../Select.styled';

class Empty extends PureComponent {
  render() {
    return (
      <SelectDropdownWrap>
        <FormattedMessage id="general.select.empty">{t => <Label>{t}</Label>}</FormattedMessage>
      </SelectDropdownWrap>
    );
  }
}

export default Empty;
