import React from 'react';

import { FormattedMessage } from 'react-intl';

import useStepperForm from 'utils/useStepperForm';

import { ContactDataForm } from 'pages/affiliations/Forms';

import Button from 'components/@common/Button';

import { FormValues } from './ContactStepContainer';

interface Props {
  saveForm: (values: FormValues) => void;
  affiliationsLoading?: boolean;
}

const ContactStep: React.FC<Props> = ({ saveForm, affiliationsLoading }) => {
  const { handleNextStepClick, canContinue } = useStepperForm(saveForm);
  return (
    <>
      <ContactDataForm />
      <Button
        data-test-id="affiliations.add.step.contact.actions.next"
        disabled={canContinue}
        onClick={handleNextStepClick}
        isLoading={affiliationsLoading}
      >
        <FormattedMessage id="general.stepper.next" />
      </Button>
    </>
  );
};

export default ContactStep;
