import styled from 'styled-components';

import { FileUploadProps, FileUploadState } from './FileUpload';

// assign to type because clash between prettier and eslint warnings: nexpected newline between template tag and template literal
type StyledProps = Pick<FileUploadProps, 'disabled'> & Pick<FileUploadState, 'highlight'>;

export const DropZone = styled.div<StyledProps>`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(7)}px;
  min-height: ${({ theme }) => theme.spacing(28)}px;
  background: ${({ highlight, theme }) =>
    highlight ? theme.palette.accent01[100] : theme.palette.neutral01[50]};
  border: 2px dashed
    ${({ highlight, theme }) =>
      highlight ? theme.palette.accent01[400] : theme.palette.neutral01[300]};
  display: flex;
  align-items: ${({ highlight }) => (highlight ? 'center' : 'flex-start')};
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.neutral01[600]};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const Placeholder = styled.span<Pick<StyledProps, 'highlight'>>`
  font-weight: 700;
  line-height: 1.5;
  color: ${({ theme, highlight }) =>
    highlight ? theme.palette.accent01[400] : theme.palette.neutral01[600]};
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  position: relative;
  pointer-events: none;
`;
