import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import Button from 'components/@common/Button';

import { PeronalSettingsFields, PrivacySettingsFields } from '../UserSettings/components';
import { ContainerProps } from './CompleteProfileFormContainer';

interface Props {
  hasErrors: boolean;
  onSubmit: () => void;
}

const CompleteProfileForm: React.FC<Props & ContainerProps> = ({
  isUpdating,
  hasErrors,
  onSubmit,
}) => (
  <>
    <Box mb={4}>
      <Typography variant="h3" color="primary" gutterBottom>
        <FormattedMessage id="profile.titles.personal_info" />
      </Typography>
      <PeronalSettingsFields />
    </Box>

    <Box mb={8}>
      <Typography variant="h3" color="primary" gutterBottom>
        <FormattedMessage id="profile.titles.privacy_settings" />
      </Typography>
      <PrivacySettingsFields />
    </Box>

    <Button disabled={hasErrors} isLoading={isUpdating} onClick={onSubmit}>
      <FormattedMessage id="profile.complete.button" />
    </Button>
  </>
);

export default CompleteProfileForm;
