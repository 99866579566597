import React, { useEffect } from 'react';

import useFilter, { UseFilterOptions } from 'hooks/useFilter/useFilter';

import { analyticsService } from 'services';

import { ContainerProps } from './ContactsContainer';
import Table from './ContactsTable';
import Filter from './Filter';

const Contacts: React.FC<ContainerProps> = ({ data, isLoading, error, fetchContacts, meta }) => {
  useEffect(() => {
    fetchContacts({});
  }, [fetchContacts]);

  const filterChange: UseFilterOptions['onFilterCallback'] = filters => {
    fetchContacts({ filters });
  };

  const { handleFilterChange } = useFilter({
    onFilterCallback: filterChange,
    disablePersist: true,
  });

  const handleContactsFilter: typeof handleFilterChange = params => {
    handleFilterChange(params);
    if (params.q) {
      analyticsService.events.trackClientsDetailContactsSearch(params.q);
    }
  };

  return (
    <>
      <Filter initialValues={{}} onFilterChange={handleContactsFilter} />
      <Table
        rows={data}
        isLoading={isLoading}
        error={error}
        onFilterChange={handleFilterChange}
        meta={meta}
      />
    </>
  );
};

export default Contacts;
