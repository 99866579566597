import React from 'react';

import { TableHeadProps as MUITableHeadProps } from '@material-ui/core/TableHead';
import MuiTableHead from '@material-ui/core/TableHead';

const TableHead: React.FC<MUITableHeadProps> = ({ children, component = 'div', ...rest }) => (
  <MuiTableHead component={component} {...rest}>
    {children}
  </MuiTableHead>
);

export default TableHead;
