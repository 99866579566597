import { sanitizeFilters } from 'hooks/useFilter/useFilter';
import _pick from 'lodash/pick';
import { call, put, SagaReturnType, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { ROUTES } from 'constants/store';
import { analyticsService, apiService } from 'services';
import { addNotification } from 'store/notifications/notifications.actions';

import { downloadFileFromBinaryResponse } from 'utils/helpers';
import { getNormalizedJsonApi } from 'utils/jsonapi';

import { QUERY_PARAMS } from 'pages/publications/publications.constants';

import { getPublicationEndpoint } from './publications.selectors';
import {
  publicationDownloadFetchFail,
  publicationDownloadFetchStart,
  publicationDownloadFetchSuccess,
  publicationsFetchFail,
  publicationsFetchStart,
  publicationsFetchSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './publications.slice';
import { PublicationData, PublicationsResponse } from './publications.types';

function* publicationsFetchFlow(action: ReturnType<typeof publicationsFetchStart>) {
  const { filter } = action.payload;
  try {
    const path = yield select(getPublicationEndpoint);
    const validFilters = _pick(sanitizeFilters(filter), Object.values(QUERY_PARAMS));
    const response: SagaReturnType<typeof apiService.publications.fetchPublications> = yield call(
      [apiService, apiService.publications.fetchPublications],
      path,
      validFilters,
    );

    const { entities, result } = getNormalizedJsonApi<
      PublicationsResponse,
      { document: PublicationData }
    >(response);

    if (Object.keys(filter).length) {
      analyticsService.events.trackPublicationsFilters(
        JSON.stringify(filter),
        response?.data?.length > 0,
      );
    }

    yield put(
      publicationsFetchSuccess({
        data: entities.document ?? {},
        result: result.document || [],
        meta: response?.meta,
        links: response?.links,
      }),
    );
  } catch (error) {
    yield put(publicationsFetchFail({ error }));
  }
}
function* publicationDownloadFetchFlow(action: ReturnType<typeof publicationDownloadFetchStart>) {
  const { path, fileName, id } = action.payload;
  try {
    const response: SagaReturnType<typeof apiService.publications.downloadPublication> = yield call(
      [apiService, apiService.publications.downloadPublication],
      path,
    );

    downloadFileFromBinaryResponse(response, fileName);

    analyticsService.events.trackDocumentsDownload(ROUTES.PUBLICATIONS, 'publication');

    yield put(publicationDownloadFetchSuccess({ id }));
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'documents.download.error',
      }),
    );
    yield put(publicationDownloadFetchFail({ error, id }));
  }
}
// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

function* publicationsSaga() {
  yield takeLatest(publicationsFetchStart, publicationsFetchFlow);
  yield takeEvery(publicationDownloadFetchStart, publicationDownloadFetchFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default publicationsSaga;
