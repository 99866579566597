import React from 'react';

import { Hidden, Link } from '@material-ui/core';
import { ReactComponent as UIcoVerzekeringen } from 'assets/svg/u_ico_verzekeringen.svg';
import { FormattedMessage } from 'react-intl';

import { STATUS_PENDING, TYPES } from 'constants/policies';
import { PolicyData } from 'services/Api/types/policies.types';
import { Category } from 'store/categories/categories.types';
import { StoreDefaultState } from 'types/store/store.types';

import PolicyCategoryIcon from 'pages/policies/PolicyCategoryIcon';

import Icon from 'components/Icon';
import IdsTooltip from 'components/IdsTooltip';
import { useMedia } from 'components/Media';
import OverflowTooltip from 'components/OverflowTooltip';
import OverViewTable from 'components/OverviewTable';
import PolicyStatusBadge from 'components/PolicyStatusBadge';
import { ClientCell, OverflowCell } from 'components/Tables/DefaultCells';

import InsurerCell from './InsurerCell';

export type RowType = PolicyData;

interface Props {
  rows?: RowType[];
  isLoading?: StoreDefaultState['isLoading'];
  error?: StoreDefaultState['error'];
  onRowClick?: (event: React.MouseEvent, row: RowType) => void;
  meta?: {
    count: number;
  };
  category?: keyof typeof TYPES;
  disablePersist?: boolean;
  emptyText?: React.ReactNode;
  onActionClick?: (row: RowType) => void;
  currentPage: number;
  orderBy: string;
  onSort: (key: string, order?: string) => void;
  onRowsPerPageChange: (event: { maxPerPage: number }) => void;
  onPageChange: (event: { page: number }) => void;
  maxPerPage: number;
  policyCategories?: { [index: string]: Category };
  isExporting?: boolean;
  onExportResults?: () => void;
}

const PoliciesTable: React.FC<Props> = ({
  rows,
  isLoading,
  error,
  currentPage,
  maxPerPage,
  orderBy,
  onSort,
  onRowsPerPageChange,
  onPageChange,
  onRowClick = () => {},
  meta,
  policyCategories,
  emptyText,
  category,
  isExporting,
  onExportResults = () => {},
}) => {
  const media = useMedia();
  return (
    <OverViewTable<RowType>
      config={[
        {
          sortKey: 'policy_number',
          isSortable: true,
          headerLabel: <FormattedMessage id="policies.table.head.id" />,
          value: 'attributes.policyNumber',
          width: media.small ? 140 : 240,
          cellRender: row => (
            <>
              <Hidden mdDown>
                <Icon color="brand01.600" spacing={8}>
                  <PolicyCategoryIcon categoryIds={row?.relationships?.policyCategories?.data} />
                </Icon>
              </Hidden>
              <IdsTooltip ids={row?.attributes?.policyNumber} />
            </>
          ),
        },
        {
          sortKey: 'title',
          isSortable: true,
          headerLabel: <FormattedMessage id="policies.table.head.name" />,
          value: 'attributes.title',
          cellRender: row => (
            <div>
              {row?.attributes?.status === STATUS_PENDING && (
                <PolicyStatusBadge label={row?.attributes?.status} />
              )}
              {/* <Typography variant="h6" component="div"> */}
              <OverflowCell row={row} selector="attributes.title" />
              {/* </Typography> */}
            </div>
          ),
        },
        {
          sortKey: 'policy_category',
          headerLabel: <FormattedMessage id="policies.table.head.type" />,
          hideOnBreakpoints: ['small'],
          value: 'attributes.type',
          isSortable: true,
          hideWhen: () => category === 'PEOPLE',
          cellRender: row => {
            const relationship = row?.relationships?.policyCategories?.data || [];
            return (
              <OverflowTooltip>
                {relationship
                  .map(
                    relationship => policyCategories?.[relationship?.id ?? '']?.attributes?.title,
                  )
                  .join(', ')}
              </OverflowTooltip>
            );
          },
        },
        {
          sortKey: 'policy_holder',
          headerLabel: <FormattedMessage id="policies.table.head.policyholder" />,
          value: 'attributes.policyHolderName',
          isSortable: true,
          cellRender: row => (
            <OverflowTooltip>
              <ClientCell ids={row?.relationships?.policyHolder?.data?.map(client => client.id)} />
            </OverflowTooltip>
          ),
        },
        {
          sortKey: 'insurer',
          headerLabel: <FormattedMessage id="policies.table.head.insurer" />,
          hideOnBreakpoints: ['small'],
          value: 'attributes.insurer',
          isSortable: true,
          cellRender: row => (
            <OverflowTooltip>
              <InsurerCell ids={row?.relationships?.insurers?.data?.map(insurer => insurer.id)} />
            </OverflowTooltip>
          ),
        },
        {
          sortKey: 'coverages',
          headerLabel: <FormattedMessage id="policies.table.head.category" />,
          hideOnBreakpoints: ['small'],
          value: 'attributes.coverages',
          isSortable: true,
          hideWhen: () => category !== 'PEOPLE',
          cellRender: row => {
            if (!row.attributes?.coverage) return '-';

            const categories =
              typeof row.attributes?.coverage === 'string'
                ? [row.attributes?.coverage]
                : row.attributes?.coverage;
            return <OverflowTooltip>{categories?.join(', ')}</OverflowTooltip>;
          },
        },
        {
          sortKey: 'has_related_claims',
          headerLabel: <FormattedMessage id="policies.table.head.claims" />,
          hideOnBreakpoints: ['small'],
          value: 'attributes.claims',
          isSortable: true,
          cellRender: row => row?.links?.relatedClaims && <PolicyStatusBadge label="CLAIMS" />,
        },
      ]}
      data={rows}
      isLoading={isLoading}
      error={error}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      onRequestSort={onSort}
      page={currentPage}
      orderBy={orderBy}
      maxPerPage={maxPerPage}
      onRowClick={onRowClick}
      emptyIcon={<UIcoVerzekeringen />}
      isRowClickable={row => !!row?.links?.details}
      isRowGreyedOut={row => !row?.links?.details}
      totalRows={meta?.count || 0}
      emptyText={emptyText}
      CustomPaginationRightComponent={
        rows &&
        rows?.length > 0 && (
          <Link component="button" disabled={isExporting} onClick={onExportResults}>
            <FormattedMessage
              id={
                isExporting ? 'claims.table.pagination.exporting' : 'claims.table.pagination.export'
              }
            />
          </Link>
        )
      }
    />
  );
};

export default PoliciesTable;
