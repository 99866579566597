import { DashboardEventsResponse } from 'store/dashboard/dashboard.types';

import { ApiInstanceType } from './';

class DashboardApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchEvents = (path: string) =>
    this.api.get<DashboardEventsResponse>(path).then(response => response.data);
}

export default DashboardApi;
