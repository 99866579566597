import { transparentize } from 'polished';
import styled from 'styled-components';

const style = {
  option: (base, state) => {
    const focusStyle = state.isFocused
      ? {
          backgroundColor: transparentize(0.97, state.selectProps.customTheme.palette.brand01[400]),
          color: state.selectProps.customTheme.palette.neutral01[600],
        }
      : {};
    const selectStyle = state.isSelected
      ? {
          backgroundColor: transparentize(0.95, state.selectProps.customTheme.palette.brand01[400]),
        }
      : {};
    return {
      ...base,
      fontFamily: state.selectProps.customTheme.typography.fontFamily,
      fontWeight: 400,
      fontSize: '16px',
      padding: 0,
      color: state.selectProps.customTheme.palette.neutral01[600],
      backgroundColor: 'transparant',
      '&:hover': {
        color: state.selectProps.customTheme.palette.brand01[500],
        backgroundColor: transparentize(0.95, state.selectProps.customTheme.palette.brand01[400]),
        cursor: 'pointer',
      },
      ...focusStyle,
      ...selectStyle,
    };
  },
  control: (base, state) => {
    const focusedState = state.isFocused
      ? {
          border: `2px solid ${state.selectProps.customTheme.palette.brand01[300]}`,
        }
      : {};
    const disabledStyle = state.isDisabled
      ? {
          backgroundColor: transparentize(
            0.6,
            state.selectProps.customTheme.palette.neutral01[300],
          ),
          color: state.selectProps.customTheme.palette.neutral01[500],
        }
      : {};
    return {
      fontFamily: state.selectProps.customTheme.typography.fontFamily,
      fontWeight: 400,
      fontSize: 16,
      background:
        state.selectProps.variant === 'filled' && !state.isFocused
          ? state.selectProps.customTheme.palette.brand01[800]
          : state.selectProps.customTheme.palette.neutral01[50],
      display: 'flex',
      height: 48,
      paddingLeft: 16,
      borderRadius: 4,
      border: `2px solid ${state.selectProps.customTheme.palette.neutral01[300]}`,
      color: state.selectProps.customTheme.palette.neutral01[600],
      ...focusedState,
      ...disabledStyle,
    };
  },
  menu: (base, state) => ({
    ...base,
    borderRadius: '4px',
    width: 'auto',
    minWidth: '100%',
    right: 0,
    zIndex: 4,
    backgroundColor: state.selectProps.customTheme.palette.neutral01[50],
    boxShadow: state.selectProps.customTheme.shadows[1],
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 1500,
  }),
  group: (base, state) => ({
    ...base,
    padding: 0,
    fontFamily: state.selectProps.customTheme.typography.fontFamily,
    fontWeight: 400,
    fontSize: '16px',
    color: state.selectProps.customTheme.palette.neutral01[600],
    margin: 0,
    borderBottom: `1px solid ${state.selectProps.customTheme.palette.neutral01[300]}`,
    '&:last-child': {
      borderBottom: 0,
    },
  }),
  groupHeading: base => ({
    ...base,
    fontWeight: 700,
    color: '#0d62a5',
    paddingTop: 16,
    paddingBottom: 8,
    fontSize: 16,
    textTransform: 'none',
    '&:empty': {
      display: 'none',
    },
  }),
  input: () => ({
    fontWeight: 400,
    fontSize: '16px',
  }),
  placeholder: (base, state) => ({
    ...base,
    width: '100%',
    color: state.selectProps.customTheme.palette.neutral01[500],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  singleValue: (base, state) => ({
    ...base,
    color: !state.isDisabled
      ? state.selectProps.customTheme.palette.neutral01[600]
      : state.selectProps.customTheme.palette.neutral01[500],
  }),
};

export const SelectDropdownWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  line-height: 1.5;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  & :hover {
    color: ${({ theme }) => theme.palette.brand01[500]};
  }
`;

export const SelectDropdownNoOptionsLabel = styled.span`
  color: ${({ theme }) => theme.palette.neutral01[500]};
  text-align: center;
`;

export const LoadingComponentWrap = styled.div`
  padding: ${({ theme }) => theme.spacing(4)}px;
`;

export default style;
