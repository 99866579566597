import React from 'react';

import _get from 'lodash/get';

import { ContainerProps } from './DetailsContainer';
interface Props {
  data: ContainerProps['data'];
}
const Address: React.FC<Props> = ({ data }) => (
  <>
    <div>
      {_get(data, 'attributes.address.street')}&nbsp;
      {_get(data, 'attributes.address.number')}
    </div>
    <div>
      {_get(data, 'attributes.address.zipcode')}&nbsp;
      {_get(data, 'attributes.address.city')}
    </div>
  </>
);

export default Address;
