import { put, take, takeLatest } from 'redux-saga/effects';

import { ROUTES } from 'constants/store';
import { bannerShow } from 'store/banner/banner.actions';
import { endpointsFetchSuccess } from 'store/endpoint/endpoint.slice';
import { goToPage } from 'store/routing/routing.actions';

import { impersonateSuccess, setImpersonate, unsetImpersonate } from './impersonate.slice';

export function* impersonateFlow({ payload }: ReturnType<typeof setImpersonate>) {
  const impersonateEmailSessionItem = window.sessionStorage.getItem('impersonate');
  if (payload.email !== impersonateEmailSessionItem) {
    window.sessionStorage.setItem('impersonate', payload.email);
  }
  const success = yield take(endpointsFetchSuccess);
  if (success) {
    yield put(impersonateSuccess());
    yield put(
      bannerShow({
        banner: {
          variant: 'info',
          message: { id: 'general.banner.impersonate.text', values: { user_email: payload.email } },
        },
      }),
    );
  }
}

function* unsetImpersonateFlow() {
  window.sessionStorage.removeItem('impersonate');
  yield put(goToPage(ROUTES.DASHBOARD));
}

function* impersonateSaga() {
  yield takeLatest(setImpersonate, impersonateFlow);
  yield takeLatest(unsetImpersonate, unsetImpersonateFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default impersonateSaga;
