import React, { Fragment } from 'react';

import { Box, Typography } from '@material-ui/core';

import { TitleLoader } from 'components/Loaders';

import { IconWrap, RightContent, SubTitle, TitleInner, TitleWrap, Wrap } from './MainTitle.styled';

export interface MainTitleProps {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  icon?: React.ReactNode;
  isLoading?: boolean;
  badge?: React.ReactNode;
  contentRight?: React.ReactNode;
}

const MainTitle: React.FC<MainTitleProps> = ({
  title,
  subTitle,
  icon,
  isLoading,
  badge,
  contentRight,
}) => (
  <Wrap data-test-id="detail-header-container">
    {isLoading ? (
      <TitleLoader hasIcon={!!icon} />
    ) : (
      <Fragment>
        {icon && <IconWrap>{icon}</IconWrap>}

        <TitleInner>
          <TitleWrap>
            <Box clone pr={4} overflow="hidden" textOverflow="ellipsis">
              <Typography variant="h3" component="h2" color="secondary">
                <span>{title}</span>
              </Typography>
            </Box>

            {badge}
          </TitleWrap>

          {subTitle && <SubTitle>{subTitle}</SubTitle>}
        </TitleInner>
        {contentRight && <RightContent>{contentRight}</RightContent>}
      </Fragment>
    )}
  </Wrap>
);

export default MainTitle;
