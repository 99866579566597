import { Ability, AbilityBuilder, AbilityBuilderParts } from '@casl/ability';

import { ROUTES, USER_TYPE } from 'constants/store';

import { UserModule, UserResponse } from './user.types';
type DefineRuleFn = (
  can: AbilityBuilderParts['can'],
  cannot: AbilityBuilderParts['cannot'],
  isUserExternal: boolean,
  options?: UserModule['options'],
) => void;

const defineCustomDocumentAbilities: DefineRuleFn = (can, _cannot, isUserExternal, _options) => {
  can('visit', ROUTES.DATASHARE_CREATE);
  // by default we can access our own documents only an internal vbrb user can access all the documents
  can('access', `${ROUTES.DATASHARE}.MY_DOCUMENTS`);
  can('access', `${ROUTES.DATASHARE}.MY_DOCUMENTS/INCOMING`);
  can('access', `${ROUTES.DATASHARE}.MY_DOCUMENTS/OUTGOING`);
  if (!isUserExternal) {
    // we define the domains ourself so we have a single source of truth
    can('access', `${ROUTES.DATASHARE}.ALL_DOCUMENTS`);
    can('access', `${ROUTES.DATASHARE}.ALL_DOCUMENTS/INCOMING`);
    can('access', `${ROUTES.DATASHARE}.ALL_DOCUMENTS/OUTGOING`);
    can('access', `${ROUTES.DATASHARE_CREATE}.INTERNAL_CREATE_FORM`);
  } else {
    can('access', `${ROUTES.DATASHARE_CREATE}.EXTERNAL_CREATE_FORM`);
  }
};

const defineCustomClientAbilities: DefineRuleFn = (can, cannot, isUserExternal, _options) => {
  if (!isUserExternal) {
    can('visit', ROUTES.PARTNERS);
    can('visit', `${ROUTES.PARTNERS}_DETAIL`);
  } else {
    cannot('visit', ROUTES.PARTNERS);
    cannot('visit', `${ROUTES.PARTNERS}_DETAIL`);
  }
};

const defineCustomPolicyAbilities: DefineRuleFn = (can, _cannot, _isUserExternal, _options) => {
  can('visit', ROUTES.RISK_DETAIL);
};

const definCustomClaimsAbilities: DefineRuleFn = (can, _cannot, _isUserExternal, _options) => {
  can('visit', ROUTES.CLAIM_DOCUMENTS);
};

const defineCustomAffiliationAbilities: DefineRuleFn = (can, _cannot, _isUserExternal, options) => {
  (options?.incomeCareWrite || options?.medicalCareWrite) &&
    can('visit', ROUTES.HC_AFFILIATIONS_CREATE);
  options?.medicalCareRead && can('read', `${ROUTES.HC_AFFILIATIONS_DETAIL}.MC`);
  options?.medicalCareWrite && can('write', `${ROUTES.HC_AFFILIATIONS_DETAIL}.MC`);
  options?.incomeCareRead && can('read', `${ROUTES.HC_AFFILIATIONS_DETAIL}.IC`);
  options?.incomeCareSalaries && can('read', `${ROUTES.HC_AFFILIATIONS_DETAIL}.IC`, 'salaries');
  options?.incomeCareSalaries && can('write', `${ROUTES.HC_AFFILIATIONS_DETAIL}.IC`, 'salaries');
  options?.incomeCareWrite && can('write', `${ROUTES.HC_AFFILIATIONS_DETAIL}.IC`);
};

const definCustomClaimReportsAbilities: DefineRuleFn = (
  can,
  _cannot,
  _isUserExternal,
  _options,
) => {
  can('visit', ROUTES.CLAIM_REPORTS_CREATE);
};

const externalUserAbilities = (can: AbilityBuilderParts['can'], userModules: UserModule[]) => {
  can('visit', ROUTES.SETTINGS);
  can('visit', 'FEEDBACK'); // it isn't a route but has to have user mgmt
  // if a user has access to at least 2 modules

  let visibleModules = userModules?.map(item => item?.key) || [];
  if (visibleModules.includes(ROUTES.CLAIM_CREATE) && visibleModules.includes(ROUTES.CLAIMS)) {
    visibleModules = visibleModules.filter(module => module !== ROUTES.CLAIM_CREATE);
  }

  if (visibleModules.length > 1) {
    can('visit', ROUTES.DASHBOARD);
    can('visit', ROUTES.BULLETINBOARD);
    can('visit', ROUTES.BULLETINBOARD_DETAIL);
  }
};

const internalUserAbilities = (can: AbilityBuilderParts['can']) => {
  can('visit', ROUTES.IMPERSONATE);
};

const additionalUserRights = {
  [ROUTES.DATASHARE]: defineCustomDocumentAbilities,
  [ROUTES.POLICIES]: defineCustomPolicyAbilities,
  [ROUTES.PARTNERS]: defineCustomClientAbilities,
  [ROUTES.HC_AFFILIATIONS]: defineCustomAffiliationAbilities,
  [ROUTES.CLAIMS]: definCustomClaimsAbilities,
  [ROUTES.CLAIM_REPORTS]: definCustomClaimReportsAbilities,
};

const defineAbilitiesFor = (user: UserResponse) => {
  const abilities = AbilityBuilder.define(
    (can: AbilityBuilderParts['can'], cannot: AbilityBuilderParts['cannot']) => {
      const userModules = user?.data?.attributes?.modules ?? [];
      const isUserExternal = user?.data?.attributes?.userType !== USER_TYPE.INTERNAL;
      can('visit', ROUTES.IMPERSONATE);
      userModules.forEach(module => {
        const currentModule = module?.key;
        // if you can visit a main module you can always visit a detail module
        can('visit', currentModule);
        can('visit', `${currentModule}_DETAIL`);
        // custom rights per module
        if (additionalUserRights[currentModule]) {
          additionalUserRights[currentModule](can, cannot, isUserExternal, module.options);
        }

        const userDomains = module?.domains ?? [];
        if (userDomains) {
          userDomains.forEach(domain => {
            can('access', `${currentModule}.${domain?.key}`);
          });
        }
      });
      if (isUserExternal) {
        externalUserAbilities(can, userModules);
      } else {
        internalUserAbilities(can);
      }
    },
  );

  let { rules } = abilities;
  if (abilities.can('visit', ROUTES.CLAIM_CREATE) && !abilities.can('visit', ROUTES.CLAIMS)) {
    /*
        A user can have access to claim submission but not to claims overview so we have to define this check
        so we can check within the header if a user only has access to claim submission the header item is set
        Also in the creation of a claim there will be no backlink and copy will be different based on this
     */
    rules = [...rules, { actions: 'create_only', subject: [ROUTES.CLAIM_CREATE] }];
  }
  return new Ability(rules);
};

export default defineAbilitiesFor;
