import React from 'react';

import _get from 'lodash/get';

import OverflowTooltip from 'components/OverflowTooltip';

interface Props<T> {
  row: T;
  selector: string;
}

const OverflowCell = <T extends {}>({ row, selector }: Props<T>) => {
  const content = _get(row, selector, ' ')?.trim(); // because the fuckers send spaces as json content -_-
  return <OverflowTooltip>{content || '-'}</OverflowTooltip>;
};

export default OverflowCell;
