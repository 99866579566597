import React, { Suspense } from 'react';

import { connect } from 'react-redux';
import { NOT_FOUND } from 'redux-first-router';

import { ROUTE_ORDER, ROUTES } from 'constants/store';
import { goToPage } from 'store/routing/routing.actions';
import { getRouteType } from 'store/routing/routing.selectors';
import { getUserAbilities } from 'store/user/user.selectors';

import useRouter from 'utils/useRouter';

import NotFound from 'pages/common/NotFound';
import Unauthorized from 'pages/common/Unauthorized/Unauthorized';

// View Components
import routes from './routes';

const routesMap = {};
Object.values(ROUTES).forEach(route => {
  routesMap[route] = routes[route];
});
routesMap[NOT_FOUND] = NotFound;

const mapStateToProps = state => ({
  route: getRouteType(state),
  userAbilities: getUserAbilities(state),
});

const mapDispatchToProps = {
  redirect: goToPage,
};

const Container = ({ route, userAbilities, redirect }) => {
  const { push } = useRouter();
  if (route === ROUTES.AUTH) {
    const referrerKey = 'secureRouterReferrerPath';
    const location = (localStorage.getItem(referrerKey) || '').replace(/^(?:\/\/|[^/]+)*\//, '');
    localStorage.removeItem(referrerKey);
    push(`/${location}`);
    return null;
  }
  let Route = routesMap[route] ? routesMap[route] : NotFound;
  if (userAbilities.can('visit', route) || route === NOT_FOUND) {
    return (
      <Suspense fallback={null}>
        <Route />
      </Suspense>
    );
  }

  if (route === ROUTES.DASHBOARD) {
    const nextAccessibleRoute = ROUTE_ORDER.find(orderedRoute =>
      userAbilities.can('visit', orderedRoute),
    );
    if (nextAccessibleRoute) {
      redirect(nextAccessibleRoute);
    } else {
      Route = Unauthorized;
    }
  } else {
    Route = Unauthorized;
  }

  return (
    <Suspense fallback={null}>
      <Route />
    </Suspense>
  );
};

const Routes = connect(mapStateToProps, mapDispatchToProps)(Container);

export default Routes;
