import React from 'react';

import { FormattedMessage } from 'react-intl';
import { pathToAction } from 'redux-first-router';

import { ROUTES } from 'constants/store';

import { LinkButton } from 'components/@common/Link';
import Icon from 'components/Icon';
import { UIcoPijlLinks } from 'components/Icons';

const filterQueryPerType = (type, query = {}) => {
  const { PARTNERS } = ROUTES;

  if (type === PARTNERS) {
    delete query.groupId;
    delete query.clientIds;
  }
  return query;
};

const getActionType = (prevLocation, to, routesMap, withUrlParams) => {
  const { payload: prevPayload, ...rest } = prevLocation;
  const action = pathToAction(to, routesMap);
  const prevMeta = withUrlParams ? rest : {};
  const payload = Object.values(prevPayload).length > 0 ? prevPayload : action.payload;

  const meta = Object.values(prevMeta).length > 0 ? prevMeta : action.meta;
  const query = filterQueryPerType(action.type, meta.query);
  return {
    type: action.type,
    payload: { ...payload, ...meta, query },
  };
};

const BackLink = ({ to, module, goToPage, prevLocation, routesMap, withUrlParams }) => {
  const { type, payload } = getActionType(prevLocation, to, routesMap, withUrlParams);
  return (
    <LinkButton
      data-test-id="backlink-button"
      hoverColor="brand01.400"
      hasUnderline={false}
      color="brand01.400"
      onClick={() => {
        goToPage(type, payload);
      }}
    >
      <Icon size={20} spacing={4}>
        <UIcoPijlLinks />
      </Icon>
      {module ? (
        <FormattedMessage
          id="general.back_link_module.text"
          values={{
            module: <FormattedMessage id={`general.back_link_module.${module}`} />,
          }}
        />
      ) : (
        <FormattedMessage id="general.backLink.text" />
      )}
    </LinkButton>
  );
};

BackLink.defaultProps = {
  withUrlParams: true,
  prevLocation: {
    type: {},
    payload: {},
  },
  routesMap: {},
};

export default BackLink;
