import useNotification from 'hooks/useNotification/useNotification';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { queryKeys } from 'constants/querykeys';
import { apiService } from 'services';
import { ClaimDetailsResponse } from 'services/Api/types/claim.types';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { ErrorTrace, Store } from 'types/store/store.types';
type WorkResumptionRequestBody = {
  body: { attributes: { workResumptionDate: string } };
};

const useUpdateClaimWorkResumption = (claimId: string) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();
  const endpoint = useSelector((state: Store) => getEndpoint(state, 'claims'));
  const icWorkresumptionUpdateLink = `${endpoint}/${claimId}/ic/resumption`;
  const { mutate, ...updateWorkResumptionMutation } = useMutation<
    unknown,
    ErrorTrace,
    WorkResumptionRequestBody
  >(
    values =>
      apiService.claims.updateClaimWorkResumption(icWorkresumptionUpdateLink, {
        data: values.body,
      }),
    {
      onMutate: values => {
        const claimDetails = queryClient.getQueryData<ClaimDetailsResponse>([
          queryKeys.claimDetail,
          claimId,
        ]);
        if (claimDetails) {
          const newDetail = _set<ClaimDetailsResponse>(
            _cloneDeep(claimDetails),
            'data.attributes.ic.workResumptionDate',
            values.body.attributes.workResumptionDate,
          );
          queryClient.setQueryData([queryKeys.claimDetail, claimId], newDetail);
        }
        return { claimDetails };
      },
      onSuccess: () => {
        addNotification({
          variant: 'success',
          message: 'claims.detail.incapacity_periods.add.success.text',
        });
      },
      onError: (_error, _values, context) => {
        const ctx = context as { claimDetails: ClaimDetailsResponse };

        if (ctx.claimDetails) {
          queryClient.setQueryData([queryKeys.claimDetail, claimId], ctx.claimDetails);
        }
        addNotification({
          variant: 'error',
          message: 'general.error.update.text',
        });
      },
    },
  );
  return {
    ...updateWorkResumptionMutation,
    updateWorkResumption: mutate,
  };
};

export default useUpdateClaimWorkResumption;
