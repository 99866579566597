import { PublicationsResponse } from 'store/publications/publications.types';

import { ApiInstanceType } from './';

class PublicationsApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchPublications = (path: string, filters: { [index: string]: string }) =>
    this.api
      .get<PublicationsResponse>(path, {
        params: filters,
      })
      .then(response => response.data);

  downloadPublication = (path: string) =>
    this.api
      .get<Blob>(path, {
        cache: {
          maxAge: 0,
          ignoreCache: true,
        },
        responseType: 'blob',
      })
      .then(response => response);
}

export default PublicationsApi;
