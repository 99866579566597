import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { H1 } from 'components/@typography/Titles';

import { SidebarContentProps } from './SidebarContentBlock';

interface SidebarNavItemProps {
  isTitle?: boolean;
  height?: number;
}

interface SidebarTitleProps {
  variation?: string;
  stayVisible?: boolean;
  inverted: boolean;
}

export const SidebarWrap = styled.div<{ offset: number }>`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing(1) * 4}px ${({ theme }) => theme.spacing(1) * 4.5}px;
  background: ${({ theme }) => theme.palette.neutral01[50]};
  box-shadow: 0px 4px 5px ${({ theme }) => transparentize(0.6, theme.palette.neutral01[300])};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    align-items: center;
    min-height: ${({ theme }) => theme.mixins.headerHeight}px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    background: 0;
    padding: ${({ theme }) => theme.spacing(1) * 4}px 0;
    box-shadow: none;
    position: static;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: fixed;
    margin-right: ${({ theme }) => theme.spacing(1) * 4}px;
    min-width: 332px;
    max-width: 332px;
    padding: 0;
    height: ${({ offset }) => `calc(100% - ${offset}px)`};
    overflow: hidden;
  }
`;

export const Title = styled(H1)`
  margin: 0px 0px 24px 0px;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.palette.neutral01[600]};
  line-height: 1.5;
  margin: 0;
  padding-bottom: ${({ theme }) => theme.spacing(1) * 4}px;
`;

export const TopWrap = styled.div`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    min-height: ${({ theme }) => theme.spacing(1) * 14}px;
  }
`;

const primaryTitleStyle = css`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.neutral01[600]};
  line-height: 1.33;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing(1) * 3}px;
`;

const secondaryTitleStyle = css`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.brand01[400]};
  line-height: 1.57;
`;

export const SideBarTitle = styled.span<
  Omit<SidebarTitleProps, 'inverted'> & { $inverted: boolean }
>`
  display: ${({ stayVisible }) => (stayVisible ? 'block' : 'none')};
  ${({ variation }) => {
    switch (variation) {
      case 'secondary':
        return secondaryTitleStyle;
      default:
        return primaryTitleStyle;
    }
  }};
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: block;
  }
  ${({ theme, $inverted }) => ($inverted ? `color: ${theme.palette.neutral01[50]}` : '')}
`;

export const TitleWrap = styled.div`
  display: none;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: block;
  }
`;

export const SidebarBlock = styled.div<SidebarContentProps>`
  margin-top: ${({ marginTop }) => marginTop}px;
  margin-left: auto;
  color: ${({ theme }) => theme.palette.neutral01[600]};
  line-height: 1.5;
  display: ${({ stayVisible }) => (stayVisible ? 'block' : 'none')};
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-left: 0;
    display: block;
  }
`;

export const SidebarNavItem = styled.div<SidebarNavItemProps>`
  font-weight: ${({ isTitle }) => (isTitle ? 700 : 400)};
  color: ${({ theme }) => theme.palette.neutral01[600]};
  line-height: 1.5;
  height: ${({ theme, height }) => height || theme.mixins.headerHeight}px;
  line-height: 1;
  display: flex;
  align-items: center;
`;

export const SidebarScrollContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex: 1 1;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: block;
  }
`;
