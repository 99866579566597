import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  client: Yup.object()
    .test('', 'general.errors.required', (value = {}) => {
      return Object.values(value).length > 0;
    })
    .required('general.errors.required'),
});

export { ValidationSchema };
