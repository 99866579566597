import styled from 'styled-components';

export const BackLink = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.spacing(2)}px;
`;

export const StepperHeaderWrap = styled.div`
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral01[300]};
  border-top: 1px solid ${({ theme }) => theme.palette.neutral01[300]};
  padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(12)}px`};
  background: ${({ theme }) => theme.palette.neutral01[50]};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StepCount = styled.span`
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.brand01[400]};
`;

export const Counter = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing(4)}px;
`;

export const StepContent = styled.div`
  padding: ${({ theme }) => theme.spacing(4)}px;
`;

export const StepDescription = styled.div`
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.neutral01[600]};
  padding: ${({ theme }) =>
    `${theme.spacing(4)}px ${theme.spacing(4)}px 0
     ${theme.spacing(4)}px`};
`;
