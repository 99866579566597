import apiManager from './axios/apiManager';

export const fetchClient = (path, id) =>
  apiManager.get(`${path}/${id}`).then(response => response.data);

export const fetchClients = (path, params) =>
  apiManager.get(path, { params }).then(response => response.data);

export const fetchClientReportingCategories = (path, params) =>
  apiManager
    .get(path, {
      params,
    })
    .then(response => response.data);
