import React, { Fragment, useEffect, useRef, useState } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { NavLink } from 'redux-first-router-link';

import { NAV_ITEMS } from 'constants/header';
import { ROUTES } from 'constants/store';
import { analyticsService } from 'services';

import shouldRouteBeInverted from 'utils/invertedRoute';

import { useMedia } from 'components/Media';

import NavItem from './components/NavItem';
import UserMenu from './components/UserMenu';
import VbrbLogo from './components/VbrbLogo';
import { ContainerProps } from './HeaderContainer';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

const Header: React.FC<ContainerProps> = ({
  logout,
  userAbilities,
  routesMap,
  showModal,
  user,
  redirect,
  currentRoute,
  route,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const routeRef = useRef(currentRoute);
  useEffect(() => {
    routeRef.current = currentRoute;
  }, [currentRoute]);

  const handleChange = (_event: React.MouseEvent, value: string | number) => {
    setIsMenuOpen(false);
    switch (value) {
      case 'FEEDBACK':
        showModal({
          modalProps: {
            type: 'FEEDBACK',
            headerIntlKey: 'feedback.modal.title',
          },
        });
        analyticsService.events.trackFeedbackItemClick();
        break;
      case 'LOGOUT':
        logout();
        break;
      case 'MORE':
        setIsMenuOpen(!isMenuOpen);
        break;
      default:
        if (value !== routeRef.current) {
          analyticsService.events.trackNavigationHeaderClicks(value);
          redirect(value.toString());
        }
        break;
    }
  };

  const routes: React.ReactNode[] = Object.keys(NAV_ITEMS).reduce(
    (acc: React.ReactNode[], route) => {
      if (route !== ROUTES.CLAIM_CREATE && userAbilities.can('visit', route)) {
        acc = [
          ...acc,
          <NavLink
            key={route}
            onClick={event => handleChange(event, route)}
            shouldDispatch={false}
            exact={route === ROUTES.DASHBOARD}
            to={typeof routesMap[route] === 'string' ? routesMap[route] : routesMap[route]?.path}
            activeClassName="active"
          >
            <NavItem key={route} {...NAV_ITEMS[route]} />
          </NavLink>,
        ];
      }
      if (
        route === ROUTES.CLAIM_CREATE &&
        userAbilities.can('visit', route) &&
        !userAbilities.can('visit', ROUTES.CLAIMS)
      ) {
        acc = [
          ...acc,
          <NavLink
            key={route}
            onClick={event => handleChange(event, route)}
            shouldDispatch={false}
            exact={false}
            to={typeof routesMap[route] === 'string' ? routesMap[route] : routesMap[route]?.path}
            activeClassName="active"
          >
            <NavItem key={route} {...NAV_ITEMS[route]} />
          </NavLink>,
        ];
      }
      return acc;
    },
    [],
  );

  const media = useMedia();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down(400));
  const inverted = shouldRouteBeInverted(route);
  return (
    <Fragment>
      {!media.small && (
        <HeaderDesktop
          Logo={VbrbLogo}
          isUserMenuOpen={isMenuOpen}
          onChange={handleChange}
          userMenu={<UserMenu user={user} onChange={handleChange} />}
          inverted={inverted}
        >
          {routes}
        </HeaderDesktop>
      )}

      {media.small && (
        <HeaderMobile isUserMenuOpen={isMenuOpen} onChange={handleChange} maxItems={isXs ? 3 : 4}>
          {routes}
        </HeaderMobile>
      )}
    </Fragment>
  );
};

export default Header;
