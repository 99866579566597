import React from 'react';

import { TableHeadCell } from 'components/@common/Table';
import OverflowTooltip from 'components/OverflowTooltip';

import { OverviewTableConfigItem } from './OverviewTable.types';

interface OverviewTableHeadProps<IData> {
  initialOrder?: 'asc' | 'desc';
  initialOrderBy?: string;
  onRequestSort?: (key: string, order?: string) => void;
  hidden: boolean;
  cell: OverviewTableConfigItem<IData>;
}

const HeadCell = <P extends {}>({
  cell,
  hidden,
  onRequestSort,
  initialOrder,
  initialOrderBy,
}: OverviewTableHeadProps<P>) => {
  const handleRequestSort = () => {
    if (cell.isSortable && onRequestSort && cell.sortKey) {
      let nextOrderStr = undefined;
      if (initialOrderBy === cell.sortKey) {
        switch (initialOrder) {
          case 'asc':
            nextOrderStr = 'desc';
            break;
          case 'desc':
            nextOrderStr = undefined;
            break;
          default:
            nextOrderStr = 'asc';
            break;
        }
      } else {
        nextOrderStr = 'asc';
      }
      onRequestSort(cell.sortKey, nextOrderStr);
    }
  };

  const getHeaderLabel = () => {
    if (cell.headCellRender) {
      return <OverflowTooltip>{cell.headCellRender(cell)}</OverflowTooltip>;
    }
    return <OverflowTooltip>{cell.headerLabel}</OverflowTooltip>;
  };

  if (hidden) {
    return null;
  }

  return (
    <TableHeadCell
      {...cell.headerCellProps}
      variant="head"
      style={{ width: `${cell.width}px` }}
      id={`${cell.sortKey}`}
      isSortable={!!cell.isSortable}
      order={initialOrder}
      orderBy={initialOrderBy}
      onRequestSort={handleRequestSort}
      label={getHeaderLabel()}
    />
  );
};

export default HeadCell;
