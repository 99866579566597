import { Menu as MuiMenu } from '@material-ui/core';
import styled from 'styled-components';

export const Menu = styled(MuiMenu).attrs({ classes: { paper: 'menu' } })`
  .menu {
    width: 100%;
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    max-width: 100%;
    ${({ theme }) => theme.breakpoints.up('md')} {
      width: auto;
      margin-top: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`;
