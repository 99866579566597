import React from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import { ReactComponent as UIcoLBlocked } from 'assets/svg/u_ico_l_blocked.svg';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/Icon';

interface Props {
  title?: string;
  text?: string;
  code?: number | string;
  description?: React.ReactNode;
  onActionClick?: (e: React.MouseEvent) => void;
}

const Forbidden: React.FC<Props> = ({
  title = 'general.errors.forbidden.title',
  text = 'general.errors.forbidden.text',
  code = 403,
  description,
  onActionClick = () => {},
}) => {
  const body = description ? (
    description
  ) : (
    <FormattedMessage
      id={text}
      values={{
        action: (
          <Link component="button" onClick={onActionClick} variant="body1">
            <FormattedMessage id="general.errors.forbidden.action" />
          </Link>
        ),
      }}
    />
  );
  return (
    <Box p={{ xs: 4, md: 12 }} maxWidth={580} marginTop="25vh">
      <Icon size={100}>
        <UIcoLBlocked />
      </Icon>
      <Box>
        <Typography variant="h1" color="textPrimary">
          <FormattedMessage id={title} />
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          <FormattedMessage id="general.errors.status_code" values={{ code }} />
        </Typography>
        <Typography variant="body1">{body}</Typography>
      </Box>
    </Box>
  );
};

export default Forbidden;
