import { useEffect, useState } from 'react';

const useSolidBackground = () => {
  const [solidBackground, setSolidBackground] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const show = window.pageYOffset > 40;
      if (show) {
        setSolidBackground(true);
      } else {
        setSolidBackground(false);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  return solidBackground;
};

export default useSolidBackground;
