import { Store } from 'types/store/store.types';

export const getRisk = (state: Store, id: string) => state.risk.data?.[id];
export const getRiskIsLoading = (state: Store) => state.risk.isLoading;
export const getRiskError = (state: Store) => state.risk.error;

// RISK DETAILS
export const getRiskDetails = (state: Store, id: string) => getRisk(state, id)?.details;
export const getRiskDetailsIsLoading = (state: Store) =>
  state.risk.isDetailsLoading || getRiskIsLoading(state);
export const getRiskDetailsError = (state: Store) => state.risk.detailsError;

// Links
export const getDetailLink = (state: Store, id: string) => getRisk(state, id)?.links?.details;

export const getRiskEndpoint = (state: Store) =>
  state.endpoints.private?.data?.relationships?.risks?.links?.self;
