import { createSelector } from '@reduxjs/toolkit';

import { getCategoriesForEntity } from 'store/categories/categories.selectors';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { Store } from 'types/store/store.types';

import { notEmpty } from 'utils/typescript.helpers';

import { publicationsAdapter } from './publications.slice';

export const getPublicationCategories = (state: Store) =>
  getCategoriesForEntity(state, 'publicationCategory');

export const publicationsSelector = publicationsAdapter.getSelectors<Store>(
  state => state.publications,
);

export const getPublication = (state: Store, id: string) =>
  publicationsSelector.selectById(state, id);
export const getPublicationsError = (state: Store) => state.publications.error;
export const getPublicationsResult = (state: Store) => state.publications.result;
export const getPublicationsStatus = (state: Store) => state.publications.status;
export const getPublicationsMeta = (state: Store) => state.publications.meta;

export const getPublications = createSelector(
  [(state: Store) => getPublicationsResult(state), (state: Store) => state],
  (results, state) => results?.map(id => getPublication(state, id)).filter(notEmpty),
);

export const getPublicationsDownloading = (state: Store) => state.publications.downloading;

export const getPublicationEndpoint = (state: Store) => getEndpoint(state, 'publications');
