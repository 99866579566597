import React from 'react';

import NumberFormat from 'react-number-format';

const FormatNumber: React.FC<React.ComponentProps<typeof NumberFormat>> = ({ number, ...rest }) => (
  <NumberFormat
    value={number}
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale
    {...rest}
  />
);

FormatNumber.defaultProps = {
  displayType: 'text',
};

export default FormatNumber;
