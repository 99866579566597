import { createNextState } from '@reduxjs/toolkit';
import { SagaReturnType } from 'redux-saga/effects';

import { InsurantData } from 'store/affiliations/affiliations.types';

import {
  initialFormState,
  StepperState,
} from 'pages/affiliations/Create/AffiliationsCreateStepper';

import { getAffiliationSubmissionData } from './affiliationSubmission.selectors';
import { updateInsurantStart, updateVerifyInsurantStart } from './affiliationSubmission.slice';
import {
  AffiliationPolicy,
  InsurantAffiliation,
  InsurantDraft,
} from './affiliationSubmission.types';
export const sanitizeInsurantDraftValues = (
  draft: SagaReturnType<typeof getAffiliationSubmissionData>,
  body: ReturnType<typeof updateVerifyInsurantStart>['payload']['data'],
) => {
  return createNextState(draft, draftState => {
    if (draftState) {
      const {
        communicationLanguage,
        reportingCategories,
        insurantType,
        identificationNumber,
        ...rest
      } = body;
      draftState.attributes = rest;
      draftState.attributes.insurantType = insurantType?.value ?? 'MAIN';
      draftState.attributes.communicationLanguage = communicationLanguage?.value;
      draftState.attributes.reportingCategories = reportingCategories?.map(category => ({
        categoryId: category?.value.categoryId ?? '',
        optionId: category?.value.categoryOptionId ?? '',
      }));
    }
  });
};

export const sanitizeInsurantAffiliationValues = (
  affiliationPolicy: AffiliationPolicy,
  values: Partial<ReturnType<typeof updateInsurantStart>['payload']['values']['stepAffiliation']>,
  valuesAdditional: Partial<
    ReturnType<typeof updateInsurantStart>['payload']['values']['stepAdditionalInfo']
  >,
): InsurantAffiliation => {
  return {
    id: '',
    type: 'insurantAffiliation',
    attributes: {
      startDate: `${values.startDate}`,
      status: 'PROCESSING',
      endDate: values.endDate,
      isBelated: !!values.isBelated,
      belatedDate: values.belatedDate,
      salary: [
        {
          employmentPercentage: valuesAdditional.employmentPercentage,
          employmentConditionKey: valuesAdditional.employmentCondition?.value,
          grossSalary: valuesAdditional.grossSalary,
          wageType: valuesAdditional.wageType,
          isCurrent: true,
        },
      ],
    },
    links: {
      self: '',
    },
    relationships: {
      policy: {
        data: {
          id: affiliationPolicy.id,
          type: affiliationPolicy.type,
        },
      },
      terminationReason: values.reason
        ? {
            data: {
              id: values.reason.value,
              type: 'hc_affiliation_termination_reason',
            },
          }
        : undefined,
    },
  };
};

export const sanitizeInsurantValues = (
  draft: SagaReturnType<typeof getAffiliationSubmissionData>,
  values: ReturnType<typeof updateInsurantStart>['payload']['values'],
) => {
  return createNextState(draft, draftState => {
    if (draftState) {
      const {
        stepBaseData: { communicationLanguage, reportingCategories, insurantType, ...attributes },
        stepAddress: { locationBE, location, zipcode, country, street, ...address },
        stepContact,
        stepAdditionalInfo: { identificationNumber },
        stepDocuments: { remark },
      } = values;
      const city = country.value === 'BE' ? locationBE?.selectedItem?.attributes?.name : location;
      const zip = country.value === 'BE' ? locationBE?.selectedItem?.attributes?.zipcode : zipcode;

      draftState.attributes = attributes;
      draftState.attributes.identificationNumber = identificationNumber;
      draftState.attributes.insurantType = insurantType?.value ?? 'MAIN';
      draftState.attributes.communicationLanguage = communicationLanguage?.value;
      draftState.attributes.reportingCategories = reportingCategories?.map(category => ({
        categoryId: category?.value.categoryId ?? '',
        optionId: category?.value.categoryOptionId ?? '',
      }));
      draftState.attributes.remark = remark;
      draftState.attributes.status = 'PENDING';
      draftState.attributes.contact = stepContact;
      draftState.attributes.address = {
        ...address,
        street: street?.inputValue,
        countryCode: country.value,
        location: city,
        zipcode: zip,
      };
    }
  });
};

export const insurantToInitialStepperState = (insurant: InsurantData, locale: string) => {
  const stepperState: StepperState = initialFormState(locale);
  return createNextState(stepperState, draftState => {
    draftState.stepBaseData.communicationLanguage = {
      value: insurant?.attributes?.communicationLanguage,
    };
    draftState.stepBaseData.firstName = insurant?.attributes?.firstName;
    draftState.stepBaseData.lastName = insurant?.attributes?.lastName;
    draftState.stepBaseData.birthDate = insurant?.attributes?.birthDate;
    draftState.stepBaseData.insurantType = { value: insurant?.attributes?.insurantType, label: '' };
  });
};

export const sanitizeValuesForAddAffiliations = (
  insurant: InsurantDraft | undefined,
  values: ReturnType<typeof updateInsurantStart>['payload']['values'],
) => {
  return createNextState(insurant, draftState => {
    if (draftState) {
      const {
        stepAdditionalInfo: { identificationNumber },
        stepDocuments: { remark },
      } = values;
      draftState.attributes.remark = remark;
      draftState.attributes.status = 'PENDING';
      draftState.attributes.identificationNumber = identificationNumber;
    }
  });
};
