import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import useStepperForm from 'utils/useStepperForm';

import Button from 'components/@common/Button';
import { RadioTile, RadioTileGroupField } from 'components/@inputs/RadioTiles';

import { ContainerProps, FormValues } from './CompanyStepContainer';
interface Props {
  data: ContainerProps['data'];
  isLoading: ContainerProps['isLoading'];
  error: ContainerProps['error'];
  status: ContainerProps['status'];
  saveForm: (values: FormValues) => void;
}

const CompanyStep: React.FC<Props> = ({ data, isLoading, saveForm, status }) => {
  const { handleNextStepClick, canContinue } = useStepperForm(saveForm);
  return (
    <>
      <Box mb={8}>
        <RadioTileGroupField
          fullWidth
          isRequired
          isLoading={isLoading}
          name="client"
          label="affiliations.add.step.company.label"
        >
          {data?.map((client, i) => (
            <RadioTile
              data-test-id={`affiliations.add.step.company.company_${i}`}
              key={client.id}
              value={client}
            >
              <Box>
                <Typography component="span" variant="body1">
                  {client?.attributes.name} |{' '}
                </Typography>
                <Typography component="span" variant="h6">
                  {client?.attributes?.clientNumber}
                </Typography>
              </Box>
              <Typography component="div" variant="subtitle2" color="textSecondary">
                {client?.attributes?.address?.street}&nbsp;{client?.attributes?.address?.number}
                ,&nbsp;
                {client?.attributes?.address?.zipcode}&nbsp;{client?.attributes?.address?.city}
              </Typography>
            </RadioTile>
          ))}
        </RadioTileGroupField>
      </Box>
      <Button
        data-test-id="affiliations.add.step.company.actions.next"
        isLoading={status === 'creating'}
        disabled={canContinue}
        onClick={handleNextStepClick}
      >
        <FormattedMessage id="general.stepper.next" />
      </Button>
    </>
  );
};

export default CompanyStep;
