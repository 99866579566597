import React from 'react';

import { FormattedMessage } from 'react-intl';

import useStepperForm from 'utils/useStepperForm';

import { AddressDataForm } from 'pages/affiliations/Forms';

import Button from 'components/@common/Button';

import { FormValues } from './AddressStepContainer';

interface Props {
  saveForm: (values: FormValues) => void;
}

const AddressStep: React.FC<Props> = ({ saveForm }) => {
  const { handleNextStepClick, canContinue } = useStepperForm(saveForm);

  return (
    <>
      <AddressDataForm />
      <Button
        data-test-id="affiliations.add.step.address.actions.next"
        disabled={canContinue}
        onClick={handleNextStepClick}
      >
        <FormattedMessage id="general.stepper.next" />
      </Button>
    </>
  );
};

export default AddressStep;
