import React from 'react';

import { Box, BoxProps } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { FormattedMessage } from 'react-intl';

import { FieldError } from 'utils/form';

import { BottomLabel } from './Field.styled';
import FieldLabel from './FieldLabel';

export interface FieldProps {
  name: string;
  label?: string | { id: string; values: { [value: string]: string } } | React.ReactNode;
  bottomLabel?: React.ReactNode;
  errors?: FieldError[];
  fullWidth?: boolean;
  isRequired?: boolean;
  withCharCount?: boolean;
  maxChars?: number;
  hasErrors?: boolean;
  color?: 'primary' | 'secondary';
  optionalAbbr?: boolean;
  inputWidth?: BoxProps['width'];
}

const Field: React.FC<FieldProps> = ({
  name,
  label,
  bottomLabel,
  errors = [],
  fullWidth,
  children,
  color = 'primary',
  isRequired = false,
  hasErrors = false,
  optionalAbbr = false,
  inputWidth,
}) => {
  const blockingErrors = errors.filter(err =>
    typeof err !== 'string' ? err?.level !== 'warning' : true,
  );

  const getBottomLabelFormatted = () => {
    if (typeof bottomLabel === 'string') {
      return <FormattedMessage id={bottomLabel} />;
    }
    return bottomLabel;
  };

  return (
    <FormControl
      data-test-id={`${name}_formControl`}
      component="div"
      fullWidth={fullWidth}
      error={errors.length > 0 || hasErrors}
    >
      {label && (
        <InputLabel color={color} htmlFor={name} disableAnimation>
          <FieldLabel label={label} />
          {!isRequired && (
            <>
              &nbsp;
              <FormattedMessage
                id={optionalAbbr ? 'general.label.optional_abbreviation' : 'general.label.optional'}
              />
            </>
          )}
        </InputLabel>
      )}
      <Box width={inputWidth}>{children}</Box>

      {(bottomLabel || errors.length > 0) && (
        <BottomLabel error={blockingErrors.length > 0}>
          {errors.length > 0
            ? errors.map(
                error =>
                  error.id && (
                    <div key={error.id}>
                      <FormattedMessage {...error} />
                    </div>
                  ),
              )
            : getBottomLabelFormatted()}
        </BottomLabel>
      )}
    </FormControl>
  );
};

export default Field;
