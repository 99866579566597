import { connect } from 'react-redux';

import { feedbackCreateStart, feedbackReset } from 'store/feedback/feedback.actions';
import { getFeedbackIsLoading } from 'store/feedback/feedback.selectors';

import FeedbackForm from './FeedbackForm';

const mapStateToProps = state => ({
  isLoading: getFeedbackIsLoading(state),
});
const mapDispatchToProps = {
  createFeedback: feedbackCreateStart,
  resetFeedback: feedbackReset,
};
export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
