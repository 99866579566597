import React, { Fragment } from 'react';

import { LocaleUtils } from 'react-day-picker';

import { Select } from 'components/@inputs';
import { Option } from 'components/@inputs/Select/SelectField';

import { monthSelectStyle, SelectWrap, Wrap, yearSelectStyle } from './DatePickerCaption.styled';

interface DatePickerCaptionProps {
  date: Date;
  hasYearMonthDropdowns?: boolean;
  localeUtils: LocaleUtils;
  locale: string;
  years: Option[];
  months: Option[];
  onChange?: (date: Date) => void;
}

const DatePickerCaption: React.FC<DatePickerCaptionProps> = ({
  date,
  hasYearMonthDropdowns,
  localeUtils,
  locale,
  years,
  months,
  onChange = () => {},
}) => {
  const handleChange = (type: 'month' | 'year') => (e: Option) => {
    switch (type) {
      case 'month':
        onChange(new Date(date.getFullYear(), e.value));
        break;
      case 'year':
        onChange(new Date(e.value, date.getMonth()));
        break;
      default:
        break;
    }
  };

  return (
    <Wrap>
      {hasYearMonthDropdowns ? (
        <SelectWrap>
          <Select
            isSearchable={false}
            extraStyles={monthSelectStyle}
            options={months}
            onChange={handleChange('month')}
            value={months.find(month => month.value === date.getMonth())} // value with find because otherwise selectedvalue won't scroll into view
            placeholder=""
          />
          <Select
            isSearchable={false}
            extraStyles={yearSelectStyle}
            options={years}
            onChange={handleChange('year')}
            value={years.find(year => year.value === date.getFullYear())}
            placeholder=""
          />
        </SelectWrap>
      ) : (
        <Fragment>
          <span data-test-id="datepicker-selected-month-span">
            {localeUtils.getMonths(locale)[date.getMonth()]}
          </span>
          <span data-test-id="datepicker-selected-year-span"> {date.getFullYear()}</span>
        </Fragment>
      )}
    </Wrap>
  );
};

export default DatePickerCaption;
