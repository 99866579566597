/*
 *
 * Mandrill collection
 * Call these methods to track to registered services
 *
 */

import { serviceManager } from '@novemberfive/mandrill';

/*
 *
 * Parameter types
 *
 */

// Type definition for: entry_point

// Type definition for: user_type

// Type definition for: label

// Type definition for: search_location

// Type definition for: source_page

// Type definition for: source_page

/*
 *
 * Events tracking functions
 *
 */

/*
 * Track: ClaimsClick
 * Description: Tracks the amount of times a claim type is clicked in the claim overview
 */

export const trackClaimsClick = claimType => {
  // Format parameters
  let claimTypeFormatted = claimType;
  claimTypeFormatted = claimTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'claims_click',
    {
      category: `claims`,
      action: `click`,
      label: `${claimTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClaimsDetailPolicyButton
 * Description: user clicks on policy button on claim detail page
 */

export const trackClaimsDetailPolicyButton = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'claims_detail_policy_button',
    {
      category: `claims detail policy button`,
      action: `click`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClaimsExport
 * Description: amount of times claims are exported via the xlsx export button
 */

export const trackClaimsExport = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'claims_export',
    {
      category: `claims`,
      action: `export`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClaimsFilters
 * Description: Tracks the filters (incl. search) used on claims overview
 */

export const trackClaimsFilters = filter => {
  // Format parameters
  let filterFormatted = filter;
  filterFormatted = filterFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'claims_filters',
    {
      category: `claims`,
      action: `select filter`,
      label: `${filterFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClaimsFlowAbandon
 * Description: user abandons a new claim flow
 *
 *
 * Parameters:
 *
 *   Name: drop_of_point
 *   Description: track step where flow was abandoned
 *
 *   Name: policy_type
 */

export const trackClaimsFlowAbandon = (dropOfPoint, policyType) => {
  // Format parameters
  let dropOfPointFormatted = dropOfPoint;
  dropOfPointFormatted = dropOfPointFormatted || '';
  let policyTypeFormatted = policyType;
  policyTypeFormatted = policyTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'claims_flow_abandon',
    {
      category: `claims flow`,
      action: `abandon`,
      label: `${dropOfPointFormatted} ${policyTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClaimsFlowConfirmation
 * Description: user receives confirmation message
 */

export const trackClaimsFlowConfirmation = successOrUnsuccess => {
  // Format parameters
  let successOrUnsuccessFormatted = successOrUnsuccess;
  successOrUnsuccessFormatted = successOrUnsuccessFormatted || false;

  // Track to services
  serviceManager.trackWithType(
    'event',
    'claims_flow_confirmation',
    {
      category: `claim flow`,
      action: `confirmation message`,
      label: `${successOrUnsuccessFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClaimsFlowEntryPoint
 * Description: track entry point: claims overview or dashboard
 */

export const trackClaimsFlowEntryPoint = entryPoint => {
  // Format parameters
  let entryPointFormatted = entryPoint;
  entryPointFormatted = entryPointFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'claims_flow_entry_point',
    {
      category: `Claims flow`,
      action: `entry point`,
      label: `${entryPointFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClaimsFlowStart
 * Description: user starts filling in a new claim
 */

export const trackClaimsFlowStart = policyType => {
  // Format parameters
  let policyTypeFormatted = policyType;
  policyTypeFormatted = policyTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'claims_flow_start',
    {
      category: `claims flow`,
      action: `start`,
      label: `${policyTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClaimsFlowSubmissionTime
 * Description: track submission time (start of flow until submission moment)
 */

export const trackClaimsFlowSubmissionTime = time => {
  // Format parameters
  let timeFormatted = time;
  timeFormatted = timeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'claims_flow_submission_time',
    {
      category: `claim_submission_time`,
      action: `timing`,
      label: `${timeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClaimsFlowSubmitError
 * Description: user receives error when trying to submit a new claim
 */

export const trackClaimsFlowSubmitError = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'claims_flow_submit_error',
    {
      category: `claims flow`,
      action: `submit error`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClaimsFlowSubmitSuccess
 * Description: user successfully submits a new claim
 */

export const trackClaimsFlowSubmitSuccess = policyType => {
  // Format parameters
  let policyTypeFormatted = policyType;
  policyTypeFormatted = policyTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'claims_flow_submit_success',
    {
      category: `claims flow`,
      action: `submit success`,
      label: `${policyTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClaimsSearchNoResults
 * Description: tracks the searches that have no results
 */

export const trackClaimsSearchNoResults = zeroResultsQuery => {
  // Format parameters
  let zeroResultsQueryFormatted = zeroResultsQuery;
  zeroResultsQueryFormatted = zeroResultsQueryFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'claims_search_no_results',
    {
      category: `claims`,
      action: `search no results`,
      label: `${zeroResultsQueryFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClientsCategorySelect
 * Description: user selects client category on client overview page
 */

export const trackClientsCategorySelect = (clientCategoryId, clientCategoryName) => {
  // Format parameters
  let clientCategoryIdFormatted = clientCategoryId;
  clientCategoryIdFormatted = clientCategoryIdFormatted || '';
  let clientCategoryNameFormatted = clientCategoryName;
  clientCategoryNameFormatted = clientCategoryNameFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'clients_category_select',
    {
      category: `client category`,
      action: `click`,
      label: `${clientCategoryIdFormatted} ${clientCategoryNameFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClientsDetailClaimsClick
 * Description: user clicks on claims button on client detail page
 */

export const trackClientsDetailClaimsClick = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'clients_detail_claims_click',
    {
      category: `client detail`,
      action: `claims`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClientsDetailInsuranceClick
 * Description: user clicks on insurance button on client detail page
 */

export const trackClientsDetailInsuranceClick = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'clients_detail_insurance_click',
    {
      category: `client detail`,
      action: `insurance`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClientsDetailPartnerClick
 * Description: user clicks on partner in the client detail page
 */

export const trackClientsDetailPartnerClick = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'clients_detail_partner_click',
    {
      category: `client detail`,
      action: `partner`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClientsFavorite
 * Description: user (un)favorites a client
 */

export const trackClientsFavorite = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'clients_favorite',
    {
      category: `client`,
      action: `favorite`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClientsOverviewSearch
 * Description: user performs search on client overview
 */

export const trackClientsOverviewSearch = resultsYesNo => {
  // Format parameters
  let resultsYesNoFormatted = resultsYesNo;
  resultsYesNoFormatted = resultsYesNoFormatted || false;

  // Track to services
  serviceManager.trackWithType(
    'event',
    'clients_overview_search',
    {
      category: `client overview`,
      action: `search`,
      label: `${resultsYesNoFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClientsPartnerContactSearch
 * Description: user performs partner contact search
 */

export const trackClientsPartnerContactSearch = resultsYesNo => {
  // Format parameters
  let resultsYesNoFormatted = resultsYesNo;
  resultsYesNoFormatted = resultsYesNoFormatted || false;

  // Track to services
  serviceManager.trackWithType(
    'event',
    'clients_partner_contact_search',
    {
      category: `client partner contact`,
      action: `search`,
      label: `${resultsYesNoFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DashboardAllEventsClick
 * Description: user clicks on "all events" on dashboard
 */

export const trackDashboardAllEventsClick = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'dashboard_all_events_click',
    {
      category: `all events`,
      action: `click`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DashboardDocumentClick
 * Description: user clicks on document on dashboard
 */

export const trackDashboardDocumentClick = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'dashboard_document_click',
    {
      category: `document`,
      action: `click`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DashboardEasyNavigationClick
 * Description: user clicks on easy navigation item
 */

export const trackDashboardEasyNavigationClick = moduleName => {
  // Format parameters
  let moduleNameFormatted = moduleName;
  moduleNameFormatted = moduleNameFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'dashboard_easy_navigation_click',
    {
      category: `easy navigation`,
      action: `click`,
      label: `${moduleNameFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DashboardEventClick
 * Description: user clicks on event on dashboard
 */

export const trackDashboardEventClick = eventId => {
  // Format parameters
  let eventIdFormatted = eventId;
  eventIdFormatted = eventIdFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'dashboard_event_click',
    {
      category: `event`,
      action: `click`,
      label: `${eventIdFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DashboardExtNewsletterClick
 * Description: user clicks on external newsletter on dashboard
 */

export const trackDashboardExtNewsletterClick = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'dashboard_ext_newsletter_click',
    {
      category: `external newsletter`,
      action: `click`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DashboardQuickActionClick
 * Description: user clicks on quick action on dashboard
 */

export const trackDashboardQuickActionClick = quickAction => {
  // Format parameters
  let quickActionFormatted = quickAction;
  quickActionFormatted = quickActionFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'dashboard_quick_action_click',
    {
      category: `quick action`,
      action: `click`,
      label: `${quickActionFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsCategorySelect
 * Description: user selects document category
 */

export const trackDocumentsCategorySelect = documentGroup => {
  // Format parameters
  let documentGroupFormatted = documentGroup;
  documentGroupFormatted = documentGroupFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_category_select',
    {
      category: `Documents`,
      action: `group select`,
      label: `${documentGroupFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsDelete
 * Description: user deletes document
 */

export const trackDocumentsDelete = documentType => {
  // Format parameters
  let documentTypeFormatted = documentType;
  documentTypeFormatted = documentTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_delete',
    {
      category: `documents`,
      action: `delete`,
      label: `${documentTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsDownload
 * Description: user downloads document
 *
 *
 * Parameters:
 *
 *   Name: page
 *
 *   Name: document_type
 */

export const trackDocumentsDownload = (page, documentType) => {
  // Format parameters
  let pageFormatted = page;
  pageFormatted = pageFormatted || '';
  let documentTypeFormatted = documentType;
  documentTypeFormatted = documentTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_download',
    {
      category: `documents`,
      action: `download`,
      label: `${pageFormatted}, ${documentTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsFilters
 * Description: Tracks the filters (incl. search) used on documents overview
 */

export const trackDocumentsFilters = filter => {
  // Format parameters
  let filterFormatted = filter;
  filterFormatted = filterFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_filters',
    {
      category: `documents`,
      action: `select filter`,
      label: `${filterFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsSearch
 * Description: user performs search on document overview
 */

export const trackDocumentsSearch = searchTerm => {
  // Format parameters
  let searchTermFormatted = searchTerm;
  searchTermFormatted = searchTermFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_search',
    {
      category: `documents`,
      action: `search`,
      label: `${searchTermFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsShareClick
 * Description: user clicks on "share document" button
 */

export const trackDocumentsShareClick = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_share_click',
    {
      category: `documents`,
      action: `share`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsSort
 * Description: user sorts the list of documents
 *
 *
 * Parameters:
 *
 *   Name: sort_parameters
 *
 *   Name: sort_order
 */

export const trackDocumentsSort = (sortParameters, sortOrder) => {
  // Format parameters
  let sortParametersFormatted = sortParameters;
  sortParametersFormatted = sortParametersFormatted || '';
  let sortOrderFormatted = sortOrder;
  sortOrderFormatted = sortOrderFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_sort',
    {
      category: `documents`,
      action: `sort`,
      label: `${sortParametersFormatted}, ${sortOrderFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsUploadCancel
 * Description: user leaves document upload flow before finalizing
 */

export const trackDocumentsUploadCancel = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_upload_cancel',
    {
      category: `documents`,
      action: `upload cancel`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsUploadFails
 *
 *
 * Parameters:
 *
 *   Name: amount_of_documents
 *   Description: number of documents added in 1 upload
 *
 *   Name: document_type
 */

export const trackDocumentsUploadFails = (amountOfDocuments, documentType) => {
  // Format parameters
  let amountOfDocumentsFormatted = amountOfDocuments;
  if (amountOfDocumentsFormatted) {
    amountOfDocumentsFormatted = Math.max(amountOfDocumentsFormatted, 1);
  }
  amountOfDocumentsFormatted = amountOfDocumentsFormatted || '';
  let documentTypeFormatted = documentType;
  documentTypeFormatted = documentTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_upload_fails',
    {
      category: `documents`,
      action: `upload fails`,
      label: `${amountOfDocumentsFormatted}, ${documentTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsUploadSecondaryRecipient
 * Description: user adds secondary recipient
 */

export const trackDocumentsUploadSecondaryRecipient = numberOfRecipients => {
  // Format parameters
  let numberOfRecipientsFormatted = numberOfRecipients;
  if (numberOfRecipientsFormatted) {
    numberOfRecipientsFormatted = Math.max(numberOfRecipientsFormatted, 1);
  }
  numberOfRecipientsFormatted = numberOfRecipientsFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_upload_secondary_recipient',
    {
      category: `documents`,
      action: `secondary recipient`,
      label: `${numberOfRecipientsFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsUploadSuccess
 * Description: user successfully uploads document
 *
 *
 * Parameters:
 *
 *   Name: user_type
 *
 *   Name: upload_time
 *
 *   Name: amount_of_documents
 *   Description: number of documents added in 1 upload
 *
 *   Name: document_type
 */

export const trackDocumentsUploadSuccess = (
  userType,
  uploadTime,
  amountOfDocuments,
  documentType,
) => {
  // Format parameters
  let userTypeFormatted = userType;
  userTypeFormatted = userTypeFormatted || '';
  let uploadTimeFormatted = uploadTime;
  uploadTimeFormatted = uploadTimeFormatted || '';
  let amountOfDocumentsFormatted = amountOfDocuments;
  if (amountOfDocumentsFormatted) {
    amountOfDocumentsFormatted = Math.max(amountOfDocumentsFormatted, 1);
  }
  amountOfDocumentsFormatted = amountOfDocumentsFormatted || '';
  let documentTypeFormatted = documentType;
  documentTypeFormatted = documentTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_upload_success',
    {
      category: `documents`,
      action: `upload success`,
      label: `${userTypeFormatted}, ${uploadTimeFormatted}, ${amountOfDocumentsFormatted}, ${documentTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsUploadTransfer
 * Description: after all documents are uploaded, transfer is sent
 *
 *
 * Parameters:
 *
 *   Name: amount_of_documents
 *
 *   Name: notes_added
 *   Description: logs if notes were added
 */

export const trackDocumentsUploadTransfer = (amountOfDocuments, notesAdded) => {
  // Format parameters
  let amountOfDocumentsFormatted = amountOfDocuments;
  if (amountOfDocumentsFormatted) {
    amountOfDocumentsFormatted = Math.max(amountOfDocumentsFormatted, 1);
  }
  amountOfDocumentsFormatted = amountOfDocumentsFormatted || '';
  let notesAddedFormatted = notesAdded;
  notesAddedFormatted = notesAddedFormatted || false;

  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_upload_transfer',
    {
      category: `documents`,
      action: `transfer`,
      label: `${amountOfDocumentsFormatted}, ${notesAddedFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: DocumentsUploadTransferFails
 * Description: after all documents are uploaded, transfer fails
 */

export const trackDocumentsUploadTransferFails = amountOfDocuments => {
  // Format parameters
  let amountOfDocumentsFormatted = amountOfDocuments;
  if (amountOfDocumentsFormatted) {
    amountOfDocumentsFormatted = Math.max(amountOfDocumentsFormatted, 1);
  }
  amountOfDocumentsFormatted = amountOfDocumentsFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'documents_upload_transfer_fails',
    {
      category: `documents`,
      action: `transfer fails`,
      label: `${amountOfDocumentsFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ExpertsClickForDetails
 * Description: Tracks when a user clicks on the experts link (breakpoints M and S) to see the detals
 */

export const trackExpertsClickForDetails = page => {
  // Format parameters
  let pageFormatted = page;
  pageFormatted = pageFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'experts_click_for_details',
    {
      category: `experts`,
      action: `hover`,
      label: `${pageFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ExpertsCommunicationClick
 * Description: Tracks the amount of times a phone, fax or email is clicked on the experts hover
 *
 *
 * Parameters:
 *
 *   Name: label
 *
 *   Name: expert_id
 *   Description: id of the cliked expert
 */

export const trackExpertsCommunicationClick = (label, expertId) => {
  // Format parameters
  let labelFormatted = label;
  labelFormatted = labelFormatted || '';
  let expertIdFormatted = expertId;
  expertIdFormatted = expertIdFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'experts_communication_click',
    {
      category: `experts`,
      action: `click`,
      label: `${labelFormatted}, ${expertIdFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ExpertsHoverForDetails
 * Description: Tracks when a user hovers over an expert to see the expert contact details (breakpoint L)
 *
 *
 * Parameters:
 *
 *   Name: page
 *   Description: page where the user hovers over an expert on breakpoint L
 *
 *   Name: expert
 *   Description: name of the expert hovered
 */

export const trackExpertsHoverForDetails = (page, expert) => {
  // Format parameters
  let pageFormatted = page;
  pageFormatted = pageFormatted || '';
  let expertFormatted = expert;
  expertFormatted = expertFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'experts_hover_for_details',
    {
      category: `experts`,
      action: `hover`,
      label: `${pageFormatted}, ${expertFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ExpertsLinkClick
 * Description: user clicks on my experts link (size M and S)
 */

export const trackExpertsLinkClick = page => {
  // Format parameters
  let pageFormatted = page;
  pageFormatted = pageFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'experts_link_click',
    {
      category: `experts link`,
      action: `click`,
      label: `${pageFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: FeedbackItemClick
 * Description: user clicks on feedback item behind the account avatar
 */

export const trackFeedbackItemClick = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'feedback_item_click',
    {
      category: `feedback`,
      action: `click`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: FeedbackSubmission
 * Description: user submits feedback successfully
 */

export const trackFeedbackSubmission = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'feedback_submission',
    {
      category: `feedback send`,
      action: `click`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InsuranceClaimsButtonClick
 * Description: user clicks on claims button on detail page
 */

export const trackInsuranceClaimsButtonClick = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'insurance_claims_button_click',
    {
      category: `insurance detail`,
      action: `click claims button`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InsuranceDownloadPolicyDocument
 * Description: user downloads a document
 *
 *
 * Parameters:
 *
 *   Name: document_type
 *   Description: log document type
 *
 *   Name: policy_type
 *   Description: log policy type
 */

export const trackInsuranceDownloadPolicyDocument = (documentType, policyType) => {
  // Format parameters
  let documentTypeFormatted = documentType;
  documentTypeFormatted = documentTypeFormatted || '';
  let policyTypeFormatted = policyType;
  policyTypeFormatted = policyTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'insurance_download_policy_document',
    {
      category: `insurance detail `,
      action: `document download`,
      label: `${documentTypeFormatted}, ${policyTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InsuranceFilter
 * Description: tracks if user uses search in insurance module
 *
 *
 * Parameters:
 *
 *   Name: results_present
 *   Description: Tracks if the search had results or not
 *
 *   Name: search_location
 *   Description: Tracks the location where the search took place
 *
 *   Name: filter
 *   Description: filter used by the user
 */

export const trackInsuranceFilter = (resultsPresent, searchLocation, filter) => {
  // Format parameters
  let resultsPresentFormatted = resultsPresent;
  resultsPresentFormatted = resultsPresentFormatted || false;
  let searchLocationFormatted = searchLocation;
  searchLocationFormatted = searchLocationFormatted || '';
  let filterFormatted = filter;
  filterFormatted = filterFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'insurance_filter',
    {
      category: `insurance overview`,
      action: `search`,
      label: `${searchLocationFormatted}, ${resultsPresentFormatted}, ${filterFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InsuranceLanguageWarning
 * Description: user sees language warning (because policy details are in different language than user language)
 *
 *
 * Parameters:
 *
 *   Name: user_language
 *
 *   Name: policy_language
 */

export const trackInsuranceLanguageWarning = (userLanguage, policyLanguage) => {
  // Format parameters
  let userLanguageFormatted = userLanguage;
  userLanguageFormatted = userLanguageFormatted || '';
  let policyLanguageFormatted = policyLanguage;
  policyLanguageFormatted = policyLanguageFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'insurance_language_warning',
    {
      category: `insurance detail`,
      action: `view language warning`,
      label: `${userLanguageFormatted}, ${policyLanguageFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InsuranceOverviewRiskcategoryFilter
 * Description: user selects risk category on policy overview page
 *
 *
 * Parameters:
 *
 *   Name: risk_category_id
 *   Description: tracks the risk category id
 *
 *   Name: risk_category_name
 *   Description: tracks the risk category name
 */

export const trackInsuranceOverviewRiskcategoryFilter = (riskCategoryId, riskCategoryName) => {
  // Format parameters
  let riskCategoryIdFormatted = riskCategoryId;
  riskCategoryIdFormatted = riskCategoryIdFormatted || '';
  let riskCategoryNameFormatted = riskCategoryName;
  riskCategoryNameFormatted = riskCategoryNameFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'insurance_overview_riskcategory_filter',
    {
      category: `insurance overview`,
      action: `risk category`,
      label: `${riskCategoryIdFormatted}, ${riskCategoryNameFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InsurancePolicyDetailOpen
 * Description: user opens a policy detail screen
 */

export const trackInsurancePolicyDetailOpen = riskCategories => {
  // Format parameters
  let riskCategoriesFormatted = riskCategories;
  riskCategoriesFormatted = riskCategoriesFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'insurance_policy_detail_open',
    {
      category: `insurance detail `,
      action: `policy view`,
      label: `${riskCategoriesFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InsurancePolicyDetailRoute
 * Description: where does the user come from when arriving on the policy detail page
 */

export const trackInsurancePolicyDetailRoute = sourcePage => {
  // Format parameters
  let sourcePageFormatted = sourcePage;
  sourcePageFormatted = sourcePageFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'insurance_policy_detail_route',
    {
      category: `insurance detail`,
      action: `view`,
      label: `${sourcePageFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InsuranceRiskEntityDetailOpen
 * Description: user opens on a risk entity detail screen
 */

export const trackInsuranceRiskEntityDetailOpen = riskEntity => {
  // Format parameters
  let riskEntityFormatted = riskEntity;
  riskEntityFormatted = riskEntityFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'insurance_risk_entity_detail_open',
    {
      category: `insurance detail `,
      action: `risk entity view`,
      label: `${riskEntityFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InvoicePolicyDetailDropdownSelect
 * Description: user makes selection in dropdown of policy details table
 */

export const trackInvoicePolicyDetailDropdownSelect = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'invoice_policy_detail_dropdown_select',
    {
      category: `invoice detail`,
      action: `select from dropdown`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InvoicesClick
 * Description: Tracks the amount of times an invoice type is clicked in the invoice overview
 */

export const trackInvoicesClick = invoiceType => {
  // Format parameters
  let invoiceTypeFormatted = invoiceType;
  invoiceTypeFormatted = invoiceTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'invoices_click',
    {
      category: `invoices`,
      action: `click`,
      label: `${invoiceTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InvoicesDownload
 * Description: amount of times an invoice is downloaded
 *
 *
 * Parameters:
 *
 *   Name: invoice_type
 *
 *   Name: source_page
 *   Description: indicates whether the download is triggered from the overview or the detail page
 */

export const trackInvoicesDownload = (invoiceType, sourcePage) => {
  // Format parameters
  let invoiceTypeFormatted = invoiceType;
  invoiceTypeFormatted = invoiceTypeFormatted || '';
  let sourcePageFormatted = sourcePage;
  sourcePageFormatted = sourcePageFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'invoices_download',
    {
      category: `invoices`,
      action: `download`,
      label: `${invoiceTypeFormatted}, ${sourcePageFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InvoicesExport
 * Description: amount of times a list of invoices is exported via the xlsx export button
 */

export const trackInvoicesExport = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'invoices_export',
    {
      category: `invoices`,
      action: `export`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InvoicesFilters
 * Description: Tracks the filters used on invoices overview
 */

export const trackInvoicesFilters = filter => {
  // Format parameters
  let filterFormatted = filter;
  filterFormatted = filterFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'invoices_filters',
    {
      category: `invoices`,
      action: `filter`,
      label: `${filterFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InvoicesPolicyClick
 * Description: Tracks the amount of times the policy button on a specific invoice type is clicked in the invoice detail
 */

export const trackInvoicesPolicyClick = invoiceType => {
  // Format parameters
  let invoiceTypeFormatted = invoiceType;
  invoiceTypeFormatted = invoiceTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'invoices_policy_click',
    {
      category: `invoices`,
      action: `click policy btn`,
      label: `${invoiceTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: InvoicesSearchNoResults
 * Description: tracks the searches that have no results
 */

export const trackInvoicesSearchNoResults = zeroResultsQuery => {
  // Format parameters
  let zeroResultsQueryFormatted = zeroResultsQuery;
  zeroResultsQueryFormatted = zeroResultsQueryFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'invoices_search_no_results',
    {
      category: `invoices`,
      action: `search no results`,
      label: `${zeroResultsQueryFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: NavigationHeaderClicks
 * Description: Tracks the clicks on the header navigation
 *
 *
 * Parameters:
 *
 *   Name: menu_item_name
 *
 *   Name: user_type
 *   Description: user type
 */

export const trackNavigationHeaderClicks = (menuItemName, userType) => {
  // Format parameters
  let menuItemNameFormatted = menuItemName;
  menuItemNameFormatted = menuItemNameFormatted || '';
  let userTypeFormatted = userType;
  userTypeFormatted = userTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'navigation_header_clicks',
    {
      category: `navigation`,
      action: `click`,
      label: `${menuItemNameFormatted} ${userTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: OnboardingCookies
 * Description: Tracks how many users selects to allow optimalisation cookies
 */

export const trackOnboardingCookies = optimalisationCookies => {
  // Format parameters
  let optimalisationCookiesFormatted = optimalisationCookies;
  optimalisationCookiesFormatted = optimalisationCookiesFormatted || false;

  // Track to services
  serviceManager.trackWithType(
    'event',
    'onboarding_cookies',
    {
      category: `onboarding`,
      action: `cookie setting`,
      label: `${optimalisationCookiesFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: OnboardingNotShowTutorial
 * Description: Counts amount of time a user choses not to show tutorial anymore
 */

export const trackOnboardingNotShowTutorial = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'onboarding_not_show_tutorial',
    {
      category: `onboarding`,
      action: `don't show tutorial`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: OnboardingReviewPersonalInfo
 * Description: Tracks when a user reviews his/her personal info to finish onboarding
 */

export const trackOnboardingReviewPersonalInfo = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'onboarding_review_personal_info',
    {
      category: `onboarding`,
      action: `finish onboarding`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: OnboardingTutorial
 * Description: Tracks when a user views a tutorial
 *
 *
 * Parameters:
 *
 *   Name: titurial_id
 *   Description: The id of the tutorial shown
 *
 *   Name: tutorial_key
 *   Description: tracks the content shown on the tutorial
 */

export const trackOnboardingTutorial = (titurialId, tutorialKey) => {
  // Format parameters
  let titurialIdFormatted = titurialId;
  titurialIdFormatted = titurialIdFormatted || '';
  let tutorialKeyFormatted = tutorialKey;
  tutorialKeyFormatted = tutorialKeyFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'onboarding_tutorial',
    {
      category: `onboarding`,
      action: `view tutorial`,
      label: `${titurialIdFormatted}, ${tutorialKeyFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: SettingsUpdatePersonalInfo
 * Description: Tracks the amount of times a user updates his/her personal info
 */

export const trackSettingsUpdatePersonalInfo = () => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'settings_update_personal_info',
    {
      category: `settings`,
      action: `update personal info`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: TrackPageView
 * Description: Tracked when a page is viewed
 */

export const trackTrackPageView = pageName => {
  // Format parameters
  let pageNameFormatted = pageName;
  pageNameFormatted = pageNameFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'view',
    'track_page_view',
    {
      view: `${pageNameFormatted}`,
    },
    ['googleanalytics_1'],
  );
};

export const trackUserMgmtGroupFilter = group => {
  serviceManager.trackWithType(
    'event',
    'user_management_overview_filter_group',
    {
      category: `user management`,
      action: `group filter`,
      label: `${group}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackUserMgmtFilter = filters => {
  serviceManager.trackWithType(
    'event',
    'user_management_overview_filter',
    {
      category: `user management`,
      action: `filter`,
      label: `${filters}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackUserMgmtAddNewUserButton = () => {
  serviceManager.trackWithType(
    'event',
    'user_management_add_user_button_click',
    {
      category: `user management`,
      action: `add user`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackUserMgmtAddNewUserNextButton = nextStep => {
  serviceManager.trackWithType(
    'event',
    'user_management_add_user_next_step_click',
    {
      category: `user management`,
      action: `add user next step click`,
      label: `${nextStep}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackUserMgmtAddNewUserInviteSuccess = () => {
  serviceManager.trackWithType(
    'event',
    'user_management_add_user_success',
    {
      category: `user management`,
      action: `add user invited`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackUserMgmtAddNewUserInviteFail = () => {
  serviceManager.trackWithType(
    'event',
    'user_management_add_user_fail',
    {
      category: `user management`,
      action: `add user invite failed`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackUserMgmtEditUserSave = () => {
  serviceManager.trackWithType(
    'event',
    'user_management_edit_user_save_changes',
    {
      category: `user management`,
      action: `edit user save changes`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackUserMgmtEditUserDelete = () => {
  serviceManager.trackWithType(
    'event',
    'user_management_edit_user_delete',
    {
      category: `user management`,
      action: `edit user delete`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackTpaGiEmployeeSimpleSearch = searchVal => {
  serviceManager.trackWithType(
    'event',
    'claim_submission_tpa_gi_employee_simple_search',
    {
      category: `tpa-gi-flow`,
      action: `tpa-gi submission search employee`,
      userinteraction: `true`,
      label: `${searchVal}`,
    },
    ['googleanalytics_1'],
  );
};

export const trackTpaGiEmployeeAdvancedSearchClick = () => {
  serviceManager.trackWithType(
    'event',
    'claim_submission_tpa_gi_employee_simple_search',
    {
      category: `tpa-gi-flow`,
      action: `tpa-gi submission advanced search employee`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackTpaGiEmployeeAdvancedSearch = filter => {
  serviceManager.trackWithType(
    'event',
    'claim_submission_tpa_gi_employee_simple_search',
    {
      category: `tpa-gi-flow`,
      action: `tpa-gi submission advanced search employee`,
      userinteraction: `true`,
      label: `${filter}`,
    },
    ['googleanalytics_1'],
  );
};

export const trackEditICPeriod = () => {
  serviceManager.trackWithType(
    'event',
    'claim_detail_ic_edit',
    {
      category: `claim-detail`,
      action: `tpa-gi detail edit incapacity period`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackAddICPeriod = () => {
  serviceManager.trackWithType(
    'event',
    'claim_detail_ic_add',
    {
      category: `claim-detail`,
      action: `tpa-gi detail add incapacity period`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackUpdateClaim = () => {
  serviceManager.trackWithType(
    'event',
    'claim_detail_ic_update',
    {
      category: `claim-detail`,
      action: `tpa-gi detail update incapacity period`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackUpdateClaimWorkResumption = () => {
  serviceManager.trackWithType(
    'event',
    'claim_detail_work_resumption_update',
    {
      category: `claim-detail`,
      action: `tpa-gi detail update work resumption`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackPartnerSwitcherOpened = (type, userType, module) => {
  serviceManager.trackWithType(
    'event',
    'partner_switcher_dropdown_click',
    {
      category: `partner_switcher`,
      action: `click`,
      userinteraction: `true`,
      label: `${type}: userType: ${userType}, module: ${module}`,
    },
    ['googleanalytics_1'],
  );
};

/*
 * Track: ClientsTopMotherChange
 * Description: user changes selection in top mother switcher
 */

export const trackPartnerSwitcherTopMotherChange = (userType, module) => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'partner_switcher_top_mother_change',
    {
      category: `partner_switcher`,
      action: `change`,
      userinteraction: `true`,
      label: `userType: ${userType}, module: ${module}`,
    },
    ['googleanalytics_1'],
  );
};

export const trackPartnerSwitcherClientChange = (userType, module, selected, total) => {
  // Track to services
  serviceManager.trackWithType(
    'event',
    'partner_switcher_partner_change',
    {
      category: `partner_switcher`,
      action: `change`,
      userinteraction: `true`,
      label: `userType: ${userType}, module: ${module}, selected/total: ${selected}/${total}`,
    },
    ['googleanalytics_1'],
  );
};

export const trackPartnerSearch = (type, userType, module, searchValue) => {
  serviceManager.trackWithType(
    'event',
    'partner_switcher_dropdown_click',
    {
      category: `partner_switcher`,
      action: `search`,
      userinteraction: `true`,
      label: `${type}: userType: ${userType}, module: ${module}, search: ${searchValue}`,
    },
    ['googleanalytics_1'],
  );
};

export const trackVehicleCertificateDownload = () => {
  serviceManager.trackWithType(
    'event',
    'vehicle_certificate_download',
    {
      category: `vehicle_certificate`,
      action: `download`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackVehicleCertificateSearch = searchValue => {
  serviceManager.trackWithType(
    'event',
    'vehicle_certificate_search',
    {
      category: `vehicle_certificate`,
      action: `search`,
      userinteraction: `true`,
      label: `${searchValue}`,
    },
    ['googleanalytics_1'],
  );
};

export const trackVehicleCertificateModalOpen = module => {
  serviceManager.trackWithType(
    'event',
    'vehicle_certificate_modal',
    {
      category: `vehicle_certificate`,
      action: `open`,
      userinteraction: `true`,
      label: `${module}`,
    },
    ['googleanalytics_1'],
  );
};

export const trackPageDetailTabClick = (module, tabId, type = 'main') => {
  serviceManager.trackWithType(
    'event',
    'page_tabs_change',
    {
      category: `page_tabs`,
      action: `click`,
      userinteraction: `true`,
      label: `${module}: ${tabId}, ${type}`,
    },
    ['googleanalytics_1'],
  );
};

export const trackClientsDetailContactsSearch = searchValue => {
  serviceManager.trackWithType(
    'event',
    'clients_detail_contacts_search',
    {
      category: `clients_detail_contacts_table_search_input`,
      action: `search`,
      userinteraction: `true`,
      label: `${searchValue}`,
    },
    ['googleanalytics_1'],
  );
};

export const trackClientsDetailUsersSearch = searchValue => {
  serviceManager.trackWithType(
    'event',
    'clients_detail_users_search',
    {
      category: `clients_detail_users_table_search_input`,
      action: `search`,
      userinteraction: `true`,
      label: `${searchValue}`,
    },
    ['googleanalytics_1'],
  );
};

export const trackClientsDetailUserImpersonate = email => {
  serviceManager.trackWithType(
    'event',
    'clients_detail_users_impersonate',
    {
      category: `clients_detail_users_table_impersonate_link`,
      action: `impersonate`,
      userinteraction: `true`,
      label: `${email}`,
    },
    ['googleanalytics_1'],
  );
};

export const trackClientsDetailUserPartersClick = () => {
  serviceManager.trackWithType(
    'event',
    'clients_detail_users_partners',
    {
      category: `clients_detail_users_table_partners_link`,
      action: `click`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackIdentityRedirect = () => {
  serviceManager.trackWithType(
    'event',
    'settings_redirect',
    {
      category: `settings_identity_redirect_button`,
      action: `click`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackGenericFileSearch = (module, searchValue) => {
  serviceManager.trackWithType(
    'event',
    'generic_documents',
    {
      category: `generic_documents_search`,
      label: `${module}: ${searchValue}`,
      action: `search`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackClaimReportsFilters = (filter, resultsPresent) => {
  // Format parameters
  let filterFormatted = filter;
  filterFormatted = filterFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'claim_reports_filter',
    {
      category: `claim_reports`,
      action: `filter`,
      label: `${filterFormatted}, ${resultsPresent}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackClaimReportsFlowAbandon = (dropOfPoint, policyType) => {
  // Format parameters
  let dropOfPointFormatted = dropOfPoint;
  dropOfPointFormatted = dropOfPointFormatted || '';
  let policyTypeFormatted = policyType;
  policyTypeFormatted = policyTypeFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'claim_reports_flow_abandon',
    {
      category: `claim reports flow`,
      action: `abandon`,
      label: `${dropOfPointFormatted} ${policyTypeFormatted}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackPublicationsFilters = (filter, resultsPresent) => {
  // Format parameters
  let filterFormatted = filter;
  filterFormatted = filterFormatted || '';

  // Track to services
  serviceManager.trackWithType(
    'event',
    'publications_filter',
    {
      category: `publications`,
      action: `filter`,
      label: `${filterFormatted}, ${resultsPresent}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackAddClaimDocumentsClick = () => {
  serviceManager.trackWithType(
    'event',
    'claim_documents',
    {
      category: `claim_documents`,
      action: `click`,
      label: 'Add claim documents button click',
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackClaimDocumentsUpload = (fileCount, actionCount) => {
  serviceManager.trackWithType(
    'event',
    'claim_documents',
    {
      category: `claim_documents`,
      action: `upload`,
      label: `files: ${fileCount}, actions: ${actionCount}`,
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};

export const trackGoToClaimsWithTasks = () => {
  serviceManager.trackWithType(
    'event',
    'claim_with_tasks',
    {
      category: `claim_with_tasks`,
      action: `click`,
      label: 'go to claim with tasks',
      userinteraction: `true`,
    },
    ['googleanalytics_1'],
  );
};
