import React, { Fragment } from 'react';

import { eventEmitter } from 'services';

import { useMedia } from 'components/Media';

import { StepperDesktopWrap, StepperMobileWrap } from './Stepper.styled';
import StepperDesktop from './StepperDesktop';
import StepperMobile from './StepperMobile';

export interface StepperStep {
  id: string;
  label: React.ReactNode;
  content: React.ReactNode;
  value?: React.ReactNode;
  description?: React.ReactNode;
}

export interface StepperProps {
  steps: StepperStep[];
  hideSteps?: string[];
  activeStep: string;
  completedSteps: string[];
  disabledSteps?: string[];
  topContent?: React.ReactNode;
  orientation?: 'horizontal' | 'vertical';
  onEditClick: (stepId: string) => void;
  mainTitle?: React.ReactNode;
}

const Stepper: React.FC<StepperProps> = props => {
  const media = useMedia();
  const handleEdit = (stepId: string) => {
    const { onEditClick } = props;
    eventEmitter.emit('stepChange', stepId);
    onEditClick(stepId);
  };
  return (
    <Fragment>
      {!media.small && (
        <StepperDesktopWrap>
          <StepperDesktop {...props} onEditClick={handleEdit} />
        </StepperDesktopWrap>
      )}
      {media.small && (
        <StepperMobileWrap>
          <StepperMobile {...props} onEditClick={handleEdit} />
        </StepperMobileWrap>
      )}
    </Fragment>
  );
};

export default Stepper;
