import { createAction } from 'redux-act';

export const filesSubmissionRecentRecipientsFetchStart = createAction(
  'FILES_SUBMISSION_RECENT_RECIPIENTS_FETCH_START',
);
export const filesSubmissionRecentRecipientsFetchSuccess = createAction(
  'FILES_SUBMISSION_RECENT_RECIPIENTS_FETCH_SUCCESS',
);
export const filesSubmissionRecentRecipientsFetchFail = createAction(
  'FILES_SUBMISSION_RECENT_RECIPIENTS_FETCH_FAIL',
);

export const resetRecipientValidation = createAction('RESET_RECIPIENT_VALIDATION');
export const fileSubmissionCreateStart = createAction('FILE_SUBMISSION_CREATE_START');
export const fileSubmissionCreateSuccess = createAction('FILE_SUBMISSION_CREATE_SUCCESS');
export const fileSubmissionCreateFail = createAction('FILE_SUBMISSION_CREATE_FAIL');
export const goToStep = createAction('GO_TO_STEP');
export const setNewStep = createAction('SET_NEW_STEP');
export const resetStepper = createAction('RESET_STEPPER');
export const goToNextStep = createAction('GO_TO_NEXT_STEP');
export const fileSubmissionUpdateStart = createAction('FILE_SUBMISSION_UPDATE_START');
export const fileSubmissionUpdateSuccess = createAction('FILE_SUBMISSION_UPDATE_SUCCESS');
export const fileSubmissionUpdateFail = createAction('FILE_SUBMISSION_UPDATE_FAIL');
export const fileSubmissionPartnersFetchStart = createAction(
  'FILE_SUBMISSION_PARTNERS_FETCH_START',
);
export const fileSubmissionPartnersFetchSuccess = createAction(
  'FILE_SUBMISSION_PARTNERS_FETCH_SUCCESS',
);
export const fileSubmissionPartnersFetchFail = createAction('FILE_SUBMISSION_PARTNERS_FETCH_FAIL');
export const fileSubmissionPartnerContactsFetchStart = createAction(
  'FILE_SUBMISSION_PARTNER_CONTACTS_FETCH_START',
);
export const fileSubmissionPartnerContactsFetchSuccess = createAction(
  'FILE_SUBMISSION_PARTNER_CONTACTS_FETCH_SUCCESS',
);
export const fileSubmissionPartnerContactsFetchFail = createAction(
  'FILE_SUBMISSION_PARTNER_CONTACTS_FETCH_FAIL',
);
// (-- APPEND ACTIONS HERE --) !!! do not move this comment !!!
