import _isEmpty from 'lodash/isEmpty';
import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { ROUTES } from 'constants/store';
import { initServices } from 'services';
import { fetchTerminationReasonsFlow } from 'store/affiliationSubmission/affiliationSubmission.saga';
import {
  allCategoriesFetchFail,
  allCategoriesFetchStart,
  allCategoriesFetchSuccess,
} from 'store/categories/categories.slice';
import { groupFetchFlow } from 'store/client/client.saga';
import { getGroup } from 'store/client/client.selectors';
import {
  endpointsFetchFail,
  endpointsFetchStart,
  endpointsFetchSuccess,
} from 'store/endpoint/endpoint.slice';
import { setImpersonate } from 'store/impersonate/impersonate.slice';
import { getLocation } from 'store/routing/routing.selectors';
import { fetchUserFavoritePartnersFlow, fetchUserFlow } from 'store/user/user.saga';
import { getUserAbilities, getUserFavoritePartners } from 'store/user/user.selectors';

import { systemFail, systemReady, systemStart } from './system.actions';

function* mainBootstrapFlow() {
  const clients = yield select(getGroup);

  const userFavoritePartners = yield select(getUserFavoritePartners);
  yield call(fetchUserFlow);

  const userAbilities = yield select(getUserAbilities);

  yield put(allCategoriesFetchStart());
  const categoriesFetch = yield take([allCategoriesFetchSuccess, allCategoriesFetchFail]);

  if (categoriesFetch.error !== undefined) {
    yield put(systemFail('categories fetch fail'));
  } else {
    let bootstrapSagas: Array<any> = [];
    if (!clients) {
      bootstrapSagas.push(groupFetchFlow);
    }

    if (userAbilities.can('visit', ROUTES.PARTNERS) && _isEmpty(userFavoritePartners)) {
      bootstrapSagas.push(fetchUserFavoritePartnersFlow);
    }

    if (userAbilities.can('visit', ROUTES.HC_AFFILIATIONS)) {
      bootstrapSagas.push(fetchTerminationReasonsFlow);
    }

    yield all(bootstrapSagas.map((saga: any) => call(saga)));
    yield put(systemReady());
  }
}

function* initApp() {
  try {
    yield call(initServices);
    const currentLocation = yield select(getLocation);

    const impersonateEmailSessionItem = window.sessionStorage.getItem('impersonate');
    if (currentLocation.type === ROUTES.IMPERSONATE || impersonateEmailSessionItem) {
      const email = currentLocation.query?.user_email || impersonateEmailSessionItem;
      yield put(setImpersonate({ email }));
    }

    yield put(endpointsFetchStart());
    const { success, error } = yield race({
      success: take(endpointsFetchSuccess),
      error: take(endpointsFetchFail),
    });

    if (success) {
      yield call(mainBootstrapFlow);
    } else {
      throw error;
    }
  } catch (e) {
    yield put(systemFail(e?.payload?.error || e?.error || e));
  }
}

export default function* watchSystem() {
  yield all([takeLatest(systemStart.getType(), initApp)]);
}
