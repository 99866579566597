import { keyframes } from 'styled-components';

export const placeholderShimmer = keyframes`
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
`;

export default null;
