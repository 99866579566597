import { SnackbarProviderProps } from 'notistack';

export const NOTIFIER_SETTINGS: SnackbarProviderProps = {
  maxSnack: 3,
  autoHideDuration: 3000,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
};
