import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Props } from './File';

const defaultStyles = css`
  background: ${({ theme }) => theme.palette.neutral01[200]};
  .fileIcon svg {
    color: ${({ theme }) => theme.palette.brand01[300]};
  }
  color: ${({ theme }) => theme.palette.brand01[400]};
`;

const successStyles = css`
  background: ${({ theme }) => transparentize(0.9, theme.palette.accent01[400])};
  .fileIcon svg {
    color: ${({ theme }) => theme.palette.accent01[400]};
  }
  color: ${({ theme }) => theme.palette.accent01[400]};
`;

const errorStyles = css`
  background: ${({ theme }) => transparentize(0.95, theme.palette.accent02[400])};
  .fileIcon svg {
    color: ${({ theme }) => theme.palette.accent02[400]};
  }
  color: ${({ theme }) => theme.palette.accent02[400]};
`;

const uploadStyles = css`
  background: ${({ theme }) => theme.palette.brand01[800]};
  .fileIcon svg {
    color: ${({ theme }) => theme.palette.brand01[300]};
  }
  color: ${({ theme }) => theme.palette.brand01[400]};
`;

export const FileWrap = styled.div<Pick<Props, 'type'>>`
  color: ${({ theme }) => theme.palette.neutral01[500]};
  line-height: 1.5;
  font-weight: 400;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  position: relative;
  padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(7)}px`};
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  max-width: 100%;
  ${({ type }) => {
    switch (type) {
      case 'success':
        return successStyles;
      case 'error':
        return errorStyles;
      case 'uploading':
        return uploadStyles;
      default:
        return defaultStyles;
    }
  }};
`;

export const FileName = styled.span<{
  isDisabled?: boolean;
  type?: 'success' | 'error' | 'uploading' | 'default';
}>`
  word-break: break-all;

  font-weight: 700;
  ${({ type }) => {
    switch (type) {
      case 'success':
        return successStyles;
      case 'error':
        return errorStyles;
      case 'uploading':
        return uploadStyles;
      default:
        return defaultStyles;
    }
  }}
`;

export const FileMeta = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  line-height: 1.57;
`;

export const ActionWrap = styled.div`
  margin-left: auto;
  display: inline-block;
`;

const iconStyle = css`
  display: inline-flex;
  transition: all 0.3s;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing(8)}px;
  height: ${({ theme }) => theme.spacing(8)}px;
  & * {
    color: ${({ theme }) => theme.palette.neutral01[50]};
    stroke: ${({ theme }) => theme.palette.neutral01[50]};
  }
`;

export const SuccessIconWrap = styled.div`
  ${iconStyle};
  background: ${({ theme }) => theme.palette.accent01[400]};
`;

export const UploadIconWrap = styled.div<Pick<Props, 'progress'>>`
  display: inline-flex;
  transition: opacity 0.1s linear 0.2s;
  opacity: ${({ progress }) => (progress !== 100 ? 0 : 1)};
`;

export const ProgressBar = styled.div<Pick<Props, 'progress'>>`
  height: 4px;
  background: ${({ theme }) => theme.palette.brand01[300]};
  position: absolute;
  transition: width 0.2s linear, opacity 0.2s linear 0.4s;
  animation-fill-mode: forwards;
  left: 0;
  bottom: 0;
  width: ${({ progress }) => progress}%;
  opacity: ${({ progress }) => (progress !== 100 ? 1 : 0)};
`;

export const FileInfoWrap = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
`;

export const FileInner = styled.div`
  max-width: 100%;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
`;
