import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ImpersonateStartPayload } from './impersonate.types';

export interface ImpersonateState {
  impersonating: boolean;
  email?: string;
  impersonateSuccess?: boolean;
}

const INITIAL_STATE: ImpersonateState = {
  impersonating: false,
  email: undefined,
  impersonateSuccess: undefined,
};

const impersonateSlice = createSlice({
  name: 'impersonate',
  initialState: INITIAL_STATE,
  reducers: {
    unsetImpersonate: state => {
      state.impersonating = true;
      state.email = undefined;
      state.impersonateSuccess = undefined;
    },
    setImpersonate: (state, { payload }: PayloadAction<ImpersonateStartPayload>) => {
      state.impersonating = true;
      state.email = payload.email;
    },
    impersonateSuccess: state => {
      state.impersonateSuccess = true;
    },
  },
});

export const { setImpersonate, unsetImpersonate, impersonateSuccess } = impersonateSlice.actions;

export default impersonateSlice.reducer;
