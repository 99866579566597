import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { history } from 'redux-first-router';
export default class ErrorTrackingService {
  private _appInsights: ApplicationInsights;
  private _reactPlugin: ReactPlugin;

  constructor(key: string) {
    this._reactPlugin = new ReactPlugin();
    this._appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: key,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [this._reactPlugin],
        extensionConfig: {
          [this._reactPlugin.identifier]: {
            history: history(),
          },
        },
      },
    });
    this._appInsights.loadAppInsights();
  }

  get appInsights() {
    return this._appInsights;
  }

  get reactPlugin() {
    return this._reactPlugin;
  }
}
