import React from 'react';

import usePolicyholders from 'hooks/usePolicyholders/usePolicyholders';

interface Props {
  ids: string | string[];
}

const ClientCell: React.FC<Props> = ({ ids }) => {
  const { getPolicyholderByIds } = usePolicyholders();
  const clientIds = typeof ids === 'string' ? [ids] : ids;
  const clients = getPolicyholderByIds(clientIds);
  const clientNames = clients.map(client => client?.attributes.name);
  return <>{clientNames.length > 0 ? clientNames.join(', ') : '-'}</>;
};

export default ClientCell;
