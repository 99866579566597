import styled from 'styled-components';

import { Props } from './Avatar';

export const AvatarTitleSuffix = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  line-height: 1.57;
`;

export const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

export const AvatarWrap = styled.div<Pick<Props, 'size' | 'verticalAlign'>>`
  width: ${({ size }) => `${size}px`};
  min-width: ${({ size }) => `${size}px`};
  max-width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin-top: ${({ theme, verticalAlign }) =>
    verticalAlign === 'center' ? 0 : theme.spacing(2)}px;
`;

export const AvatarDetailsWrap = styled.div`
  flex: 1 auto;
  margin-left: 16px;
`;

export const AvatarTitle = styled.span<{ $inverted: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme, $inverted }) =>
    $inverted ? theme.palette.neutral01[50] : theme.palette.brand01[400]};
`;

export const AvatarsubTitle = styled.span<{ $inverted: boolean }>`
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme, $inverted }) =>
    $inverted ? theme.palette.brand01[600] : theme.palette.neutral01[500]};
  line-height: 1.57;
`;

export const AvatarMidTitle = styled.span`
  display: block;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  color: ${({ theme }) => theme.palette.neutral01[600]};
`;

export const FallbackWrap = styled.span<Required<Pick<Props, 'size'>>>`
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.neutral01[300]};
  font-size: ${({ size }) => size / 2.5}px;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  font-weight: 700;
  line-height: 1;
`;

export const Wrap = styled.div<Pick<Props, 'title' | 'subTitle' | 'verticalAlign' | 'withHover'>>`
  display: ${({ title, subTitle }) => (title || subTitle ? `flex` : `inline-flex`)};
  align-items: ${({ verticalAlign }) => {
    switch (verticalAlign) {
      case 'center':
        return 'center';
      case 'bottom':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  }};
  ${({ withHover, theme }) =>
    withHover &&
    `& :hover {
    ${AvatarTitle} {
      color: ${theme.palette.brand01[500]};
    }

    ${AvatarsubTitle} {
      color: ${theme.palette.brand01[500]};
    }

    ${AvatarMidTitle} {
      color: ${theme.palette.brand01[500]};
    }
  }`}
`;
