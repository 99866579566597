import * as Yup from 'yup';

import { EmploymentCondition } from 'store/affiliationSubmission/affiliationSubmission.types';

const AdditionalInfoFormValidation = (
  wageRequired: boolean,
  identificationNumberRequired: boolean,
  employmentConditions?: EmploymentCondition[] | null,
) => {
  const nullValidation = Yup.mixed().nullable();

  const wageValidation = wageRequired
    ? Yup.number().required('general.errors.required')
    : nullValidation;
  const percentageValidation = wageRequired
    ? Yup.number()
        .min(0.01, params => ({ id: 'general.errors.number.min', values: { min: params.min } }))
        .max(100, params => ({ id: 'general.errors.number.max', values: { min: params.max } }))
        .required('general.errors.required')
    : nullValidation;
  const conditionValidation =
    wageRequired && (employmentConditions || []).length > 0
      ? Yup.object()
          .shape({})
          .required('general.errors.required')
      : nullValidation;
  const identificationNumberValidation = identificationNumberRequired
    ? Yup.string().belgianSSN()
    : nullValidation;

  return Yup.object().shape({
    identificationNumber: identificationNumberValidation,
    grossSalary: wageValidation,
    employmentPercentage: percentageValidation,
    employmentCondition: conditionValidation,
  });
};

export { AdditionalInfoFormValidation };
