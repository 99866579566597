import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface ContentWrap {
  paddingBottom?: number;
}

interface Content {
  withBackground?: boolean;
}

export const ContentWrap = styled.div<ContentWrap>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  ${({ paddingBottom }) => (paddingBottom ? `padding-bottom: ${paddingBottom}px` : '')};
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-bottom: 0;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex-direction: row;
  }
`;

const withBackGroundStyles = css`
  border-radius: ${({ theme }) => theme.spacing(1) / 2}px;
  background-color: ${({ theme }) => theme.palette.neutral01[50]};
  box-shadow: ${({ theme }) => theme.shadows[2]};
`;

export const Content = styled.div<Content>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 auto;
  ${({ withBackground }) => (withBackground ? withBackGroundStyles : '')};
`;

export const Actions = styled.div`
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 0;
    padding-bottom: ${({ theme }) => theme.spacing(1) * 4}px;
    text-align: right;
    display: flex;
    align-items: center;
    &:first-child {
      text-align: left;
    }
    > *:last-child {
      margin-left: auto;
    }
  }
  &:empty {
    padding: 0;
  }
`;

export const ActionsMobile = styled.div`
  display: block;
  position: fixed;
  bottom: ${({ theme }) => theme.mixins.headerHeight}px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette.neutral01[300]};
  height: 80px;
  background-color: ${({ theme }) => theme.palette.neutral01[50]};
  padding: ${({ theme }) => theme.spacing(1) * 4}px;
  box-shadow: 0px -4px 5px ${({ theme }) => transparentize(0.6, theme.palette.neutral01[300])};
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;
