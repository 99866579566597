import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';
import { ReactComponent as UIcoDownload } from 'assets/svg/u_ico_download.svg';
import { FormattedMessage } from 'react-intl';

import { Category } from 'store/categories/categories.types';
import { Document, DocumentsContext } from 'store/document/document.types';
import { StoreDefaultState } from 'types/store/store.types';

import { formatBytes } from 'utils/helpers';

import { FileIcon } from 'components/File';
import Icon from 'components/Icon';
import OverViewTable from 'components/OverviewTable';
import Tooltip from 'components/Tooltip';

import { OverflowCell } from '../DefaultCells';
import { TableTypography } from 'components/@common/Table/TableRow';

type RowType = Document;

interface Props {
  rows?: RowType[];
  context: DocumentsContext;
  isLoading?: StoreDefaultState['isLoading'];
  error?: StoreDefaultState['error'];
  onRowClick?: (event: React.MouseEvent, row: RowType) => void;
  meta?: {
    count: number;
  };
  downloading: string[];
  category?: string;
  disablePersist?: boolean;
  isRowClickable?: boolean;
  emptyText?: React.ReactNode;
  onActionClick?: (row: RowType) => void;
  onDownload: (row: RowType) => void;
  currentPage: number;
  orderBy: string;
  onSort: (key: string, order?: string) => void;
  onRowsPerPageChange: (event: { maxPerPage: number }) => void;
  onPageChange: (event: { page: number }) => void;
  maxPerPage: number;
  categories?: { [index: string]: Category };
}

const DocumentsTable: React.FC<Props> = ({
  rows,
  meta,
  isLoading,
  error,
  onDownload,
  currentPage,
  maxPerPage,
  orderBy,
  onSort,
  onRowsPerPageChange,
  onPageChange,
  context,
  downloading,
  categories,
  emptyText = <FormattedMessage id="documents.table.nodata" />,
}) => {
  const handleDownload = (row: RowType) => () => {
    if (!downloading.includes(row.id)) {
      onDownload(row);
    }
  };

  return (
    <OverViewTable<RowType>
      data={rows}
      isLoading={isLoading}
      error={error}
      emptyText={emptyText}
      config={[
        {
          value: 'attributes.title',
          headerLabel: <FormattedMessage id="general.documents.table.head.type" />,
          sortKey: 'title',
          isSortable: true,
          minWidth: 200,
          cellRender: row => {
            const fileSize =
              typeof row?.attributes?.fileSize === 'number'
                ? formatBytes(row?.attributes?.fileSize)
                : '';
            const extension = row?.attributes?.fileType ?? '';
            return (
              <Box display="flex" overflow="hidden" maxWidth="100%" alignItems="center">
                <FileIcon color="brand01.600" extension={extension?.toLowerCase() ?? ''} />
                <Box pr={2} maxWidth="100%">
                  <OverflowCell
                    row={categories?.[row?.relationships?.category?.data?.id] ?? ''}
                    selector="attributes.title"
                  />
                  <TableTypography component="div" variant="subtitle2" color="textSecondary">
                    {[extension?.toUpperCase(), fileSize].filter(Boolean).join(' - ')}
                  </TableTypography>
                </Box>
              </Box>
            );
          },
        },
        {
          value: 'attributes.description',
          headerLabel: <FormattedMessage id="general.documents.table.head.description" />,
          sortKey: 'description',
          isSortable: true,
          hideOnBreakpoints: ['small'],
          cellRender: row => (
            <Box display="flex" overflow="hidden" maxWidth="100%" alignItems="center">
              <Box pr={2} maxWidth="100%">
                <OverflowCell row={row} selector="attributes.description" />
                <TableTypography component="div" variant="subtitle2" color="textSecondary">
                  {row?.attributes?.descriptionMeta ? (
                    <OverflowCell row={row} selector="attributes.descriptionMeta" />
                  ) : (
                    <>&nbsp;</>
                  )}
                </TableTypography>
              </Box>
            </Box>
          ),
        },
        {
          value: 'attributes.sender',
          headerLabel: <FormattedMessage id="general.documents.table.head.sender" />,
          sortKey: 'sender',
          isSortable: true,
          hideOnBreakpoints: ['small'],
          cellRender: row => <OverflowCell row={row} selector="attributes.sender" />,
        },
        {
          value: 'attributes.receiver',
          headerLabel: <FormattedMessage id="general.documents.table.head.receiver" />,
          sortKey: 'receiver',
          isSortable: true,
          hideOnBreakpoints: ['small'],
          cellRender: row => <OverflowCell row={row} selector="attributes.receiver" />,
        },
        {
          value: 'attributes.creationDate',
          headerLabel: <FormattedMessage id="general.documents.table.head.creation_date" />,
          sortKey: 'creation_date',
          isSortable: true,
          hideOnBreakpoints: ['small'],
          type: 'date',
        },
        {
          value: 'attributes.expirationDate',
          headerLabel: <FormattedMessage id="general.documents.table.head.expiration_date" />,
          sortKey: 'expiration_date',
          isSortable: true,
          hideOnBreakpoints: ['small'],
          type: 'date',
          hideWhen: () => context === 'claims',
        },
        {
          value: 'attributes.phase',
          headerLabel: <FormattedMessage id="general.documents.table.head.phase" />,
          hideOnBreakpoints: ['small'],
          isSortable: true,
          sortKey: 'phase',
          hideWhen: () => context !== 'claims',
        },
        {
          value: 'actions',
          width: 115,
          headerLabel: <FormattedMessage id="general.documents.table.head.actions" />,
          isSortable: false,
          headerCellProps: { align: 'right' },
          cellProps: { align: 'right' },
          cellRender: row => (
            <Box textAlign="center" px={2} minWidth={40}>
              {downloading.includes(row.id) ? (
                <CircularProgress size={16} color="primary" />
              ) : (
                <Tooltip
                  placement="top-end"
                  title={<FormattedMessage id="documents.table.actions.download" />}
                >
                  <Icon
                    cursor="pointer"
                    data-test-id="ico-document-download"
                    onClick={handleDownload(row)}
                    hoverColor="brand01.500"
                    color="brand01.300"
                  >
                    <UIcoDownload />
                  </Icon>
                </Tooltip>
              )}
            </Box>
          ),
        },
      ]}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      onRequestSort={onSort}
      page={currentPage}
      orderBy={orderBy}
      maxPerPage={maxPerPage}
      totalRows={meta?.count || 0}
    />
  );
};

export default DocumentsTable;
