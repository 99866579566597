import React from 'react';

import { LinkStyled } from './Link.styled';

export interface LinkProps {
  color?: string;
  hoverColor?: string;
  disabled?: boolean;
  hasUnderline?: boolean;
  isActive?: boolean;
  to?: string;
  onClick?: (e: React.MouseEvent) => void;
  target?: string;
  tagName?: string;
  fill?: boolean;
  withoutHover?: boolean;
}

const Link: React.FC<LinkProps> & { defaultProps: Partial<LinkProps> } = ({
  children,

  color = 'brand01.300',
  hasUnderline = true,
  disabled = false,
  hoverColor = 'brand01.500',
  fill = false,
  withoutHover = false,
  ...rest
}) => (
  <LinkStyled
    hoverColor={hoverColor}
    disabled={disabled}
    hasUnderline={hasUnderline}
    color={color}
    $fill={fill}
    $withoutHover={withoutHover}
    {...rest}
  >
    {children}
  </LinkStyled>
);

Link.defaultProps = {
  color: 'brand01.300',
  hoverColor: 'brand01.400',
  hasUnderline: true,
  disabled: false,
};

export default Link;
