import { PartnersResponse } from 'services/Api/types/partners.types';
import { PartnerContactsResponse, PartnerResponse } from 'store/partner/partner.types';

import { ApiInstanceType } from '.';
class PartnersApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchAcmaClients = (path: string, filters: { [index: string]: string | undefined }) =>
    this.api
      .get<PartnersResponse>(path, {
        params: filters,
      })
      .then(response => response.data);

  fetchAcmaClient = (path: string) =>
    this.api.get<PartnerResponse>(path).then(response => response.data);

  fetchAcmaClientContacts = (path: string, params: { [index: string]: string }) =>
    this.api
      .get<PartnerContactsResponse>(path, { params })
      .then(response => response.data);
}

export default PartnersApi;
