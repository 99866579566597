import styled from 'styled-components';

import { LinkButton } from 'components/@common/Link';

export const Action = styled.div`
  display: inline-block;
`;

export const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4.5)}px;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: ${props => props.theme.palette.neutral01[600]};
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  overflow: hidden;
`;

export const IconWrap = styled(LinkButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacing(6)}px;
  height: ${({ theme }) => theme.spacing(6)}px;
  border-radius: 50%;
  position: absolute;
  right: ${({ theme }) => theme.spacing(4)}px;
  top: ${({ theme }) => theme.spacing(4)}px;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  padding-top: ${({ theme }) => theme.spacing(6)}px;
`;

export const ActionButton = styled.div`
  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(4)}px;
  }
`;

export const ScrollModalContent = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

export const Wrap = styled.div`
  flex: 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
