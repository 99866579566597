import styled from 'styled-components';

export const Wrap = styled.div<{ bgColor: string; fullWidth: boolean }>`
  min-width: 100%;
  max-width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    min-width: 340px;
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '400px')};
  }
  box-shadow: ${({ theme }) => theme.shadows[2]};
  background: ${({ bgColor }) => bgColor ?? '#fff'};
`;

export const NotificationActions = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(4)}px;
  }
`;
