import React, { useEffect } from 'react';

import { ContainerProps } from './ApiImageAvatarContainer';
import Avatar from './Avatar';

export interface Props {
  id: string;
  context: string;
  apiEndpoint?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  size?: number;
  searchValue?: string;
  midTitle?: string;
  verticalAlign?: 'top' | 'center';
  withTitleOverflowTooltip?: boolean;
}

const ApiImageAvatar: React.FC<Props & ContainerProps> = ({
  fetchAvatar,
  id,
  context,
  apiEndpoint,
  avatar,
  hasFetched,
  ...rest
}) => {
  useEffect(() => {
    if (!avatar && !hasFetched) {
      fetchAvatar({ id, context, endpoint: apiEndpoint });
    }
  }, [apiEndpoint, avatar, context, fetchAvatar, hasFetched, id]);
  return <Avatar {...rest} imgSrc={avatar} />;
};

export default ApiImageAvatar;
