import _get from 'lodash/get';
import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import { fetchPrivateEndpoints, fetchPublicEndpoints } from 'api/endpoint.api';
import { apiService, initAuthService } from 'services';
import { validateAccess } from 'store/auth/auth.saga';

import { endpointsFetchFail, endpointsFetchStart, endpointsFetchSuccess } from './endpoint.slice';

export function* fetchEndpointsFlow() {
  try {
    const { data }: SagaReturnType<typeof apiService.endpoints.fetchPublicEndpoints> = yield call([
      apiService,
      apiService.endpoints.fetchPublicEndpoints,
    ]);
    // make backwards compatible
    const newIssuer = data?.attributes?.identity?.issuer;
    const newClientId = data?.attributes?.identity?.clientId;
    const isNewConfig = !!newIssuer && !!newClientId;
    const issuer = isNewConfig ? newIssuer : data?.relationships?.authorization?.links?.self;
    const oktaClientId = isNewConfig
      ? newClientId
      : data?.attributes?.configuration?.authentication?.oktaClientId;

    const isPkce = isNewConfig;
    yield call(initAuthService, issuer, oktaClientId, isPkce);
    yield call(validateAccess);
    const privateEndpoint = _get(data, 'relationships.api.links.self');
    const {
      data: privateEndpointsData,
    }: SagaReturnType<typeof apiService.endpoints.fetchPrivateEndpoints> = yield call(
      [apiService, apiService.endpoints.fetchPrivateEndpoints],
      privateEndpoint,
    );
    yield put(
      endpointsFetchSuccess({
        data,
        privateEndpointsData,
      }),
    );
  } catch (error) {
    // firefox fix when the redirect happens for the authentication it always throws an error while no error occured...
    if (error.message !== 'Request aborted') {
      yield put(endpointsFetchFail({ error }));
    }
  }
}

function* endpointSaga() {
  yield takeLatest(endpointsFetchStart, fetchEndpointsFlow);
}

export default endpointSaga;
