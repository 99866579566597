import { AxiosError } from 'axios';
import _get from 'lodash/get';

import { Store } from 'types/store/store.types';

export const getCategories = (state: Store): any => _get(state, 'categories.data');
export const getCategoriesIsLoading = (state: Store): boolean =>
  _get(state, 'categories.isLoading');
export const getCategoriesError = (state: Store): AxiosError => _get(state, 'categories.error');

export const getAllCategoriesEndpoint = (state: Store) =>
  _get(state, 'endpoints.private.data.relationships.aggregatedCategories.links.self');

export const getCategoriesForEntity = (state: Store, entity: string) =>
  state.categories.data?.[entity];
