import humps from 'humps';

import { JSONApiRelationshipsObject, JSONApiResource, JSONAPiResponse } from 'types/jsonApi.types';

type NormalizedResult = { [key: string]: string[] };

type NormalizedEntity<Entity extends JSONApiResource> = {
  [key: string]: {
    [id: string]: Entity;
  };
};
// type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

export type NormalizedJsonAPI<Keys extends { [index: string]: JSONApiResource }> = {
  entities: {
    [K in keyof Keys]: {
      [id: string]: Keys[K];
    };
  };

  result: {
    [K in keyof Keys]: string[];
  };
};

export const getNormalizedJsonApi = <
  T extends JSONAPiResponse,
  Keys extends { [index: string]: JSONApiResource }
>(
  response: T,
) => {
  let data;

  if (Array.isArray(response.data)) {
    data = response.data;
  } else {
    data = [response.data];
  }

  const included = response.included || [];

  let result = {};
  let entities = {};

  data.forEach(entity => {
    addResult<typeof entity>(result, entity);
    addEntity<typeof entity>(entities, entity);
  });

  included.forEach(entity => {
    addEntity<typeof entity>(entities, entity);
    addResult<typeof entity>(result, entity);
  });
  const normalized = {
    result,
    entities,
  };
  return normalized as NormalizedJsonAPI<Keys>;
};

export const addResult = <T extends JSONApiResource>(result: NormalizedResult, entity: T) => {
  const { type, id } = entity;
  const camelizedType = humps.camelize(type);
  if (!result[camelizedType]) result[camelizedType] = [];

  result[camelizedType].push(id);
};

export const addEntity = <T extends JSONApiResource>(entities: NormalizedEntity<T>, entity: T) => {
  const { type, id } = entity;
  const camelizedType = humps.camelize(type);
  if (!entities[camelizedType]) entities[camelizedType] = {};

  entities[camelizedType][id] = entity;
};

export const extractRelationships = <T extends JSONApiResource>(entity: T) => {
  const { relationships: responseRelationships } = entity;

  if (!responseRelationships) return undefined;

  let relationships: JSONApiRelationshipsObject = {};
  Object.keys(responseRelationships).forEach(type => {
    relationships[type] = responseRelationships[type];
  });

  return relationships;
};
