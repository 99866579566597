import styled from 'styled-components';

export const PaginationWrap = styled.div`
  position: relative;
  margin-top: auto;
  line-height: 1.5;
  font-weight: 400;
  display: flex;
  align-items: center;
  border-top: ${({ theme }) => `1px solid ${theme.palette.neutral01[300]}`};
  padding: 0 ${({ theme }) => `${theme.spacing(6)}px`};
  min-height: ${({ theme }) => `${theme.spacing(14)}px`};
  height: ${({ theme }) => `${theme.spacing(14)}px`};
  border-radius: 0px 0px ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px`};
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.neutral01[50]};
  color: ${({ theme }) => theme.palette.neutral01[500]};
  font-weight: 400;
  bottom: 0px;
`;

export default null;
