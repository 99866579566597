import _get from 'lodash/get';

import { Store } from 'types/store/store.types';

import { PrivateIndex } from './endpoint.types';

export const getPublicEndpoints = (state: Store) => _get(state, 'endpoints.data');

export const getEndpoint = (state: Store, key: keyof PrivateIndex['relationships']) =>
  state.endpoints.private.data?.relationships[key]?.links?.self ?? '';
