import React from 'react';

import _get from 'lodash/get';
import { transparentize } from 'polished';
import Link from 'redux-first-router-link';
import styled, { css } from 'styled-components';

import { LinkProps } from './Link';
import { LinkButtonProps } from './LinkButton';

const loadingStyle = css<Pick<LinkProps, 'color'>>`
  color: ${({ theme, color }) => transparentize(0.4, _get(theme.palette, color || '', ''))};
  pointer-events: none;
  &:hover {
    cursor: default;
    color: ${({ theme, color }) => transparentize(0.4, _get(theme.palette, color || '', ''))};
  }
`;

const hoverStyle = css<
  Omit<LinkProps, 'fill' | 'withoutHover'> & { $fill?: boolean; $withoutHover?: boolean }
>`
&:hover {
  color: ${({ theme, hoverColor }) =>
    hoverColor ? _get(theme.palette, hoverColor, '') : theme.palette.neutral01[600]};
  & * {
    ${({ theme, hoverColor, $fill }) =>
      $fill
        ? `fill: ${hoverColor ? _get(theme.palette, hoverColor, '') : theme.palette.brand01[500]};`
        : `stroke: ${
            hoverColor ? _get(theme.palette, hoverColor, '') : theme.palette.brand01[500]
          };`}
  }
`;

type LinkStyleProps = Pick<LinkProps, 'color' | 'disabled' | 'hoverColor' | 'fill'> &
  Pick<LinkButtonProps, 'isLoading' | 'fullWidth'>;

const linkStyles = css<
  Omit<LinkStyleProps, 'fill' | 'withoutHover'> & { $fill?: boolean; $withoutHover?: boolean }
>`
  line-height: 1.5;
  font-weight: 700;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 16px;
  color: ${({ theme, color, disabled }) =>
    disabled ? theme.palette.neutral01[500] : _get(theme.palette, color || '', '')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  & * {
    ${({ theme, color, disabled, $fill }) =>
      $fill
        ? `fill: ${disabled ? theme.palette.neutral01[500] : _get(theme.palette, color || '', '')};`
        : `stroke: ${
            disabled ? theme.palette.neutral01[500] : _get(theme.palette, color || '', '')
          };`}
  }
  ${({ $withoutHover }) => (!$withoutHover ? hoverStyle : '')};
  }
  ${({ isLoading }) => (isLoading ? loadingStyle : '')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

export const LinkStyled = styled(
  ({ hasUnderline, color, disabled, hoverColor, $fill, $withoutHover, ...rest }) => (
    <Link {...rest} />
  ),
)<Omit<LinkProps, 'fill'> & { $fill: boolean }>`
  ${linkStyles};
  text-decoration: ${({ hasUnderline }) => (hasUnderline ? 'underline' : 'none')};
`;

export const LinkButtonStyled = styled.button<
  Omit<LinkProps & LinkButtonProps, 'fill' | 'withoutHover'> & {
    $fill?: boolean;
    $withoutHover?: boolean;
  }
>`
  ${linkStyles};
  cursor: pointer;
  background: 0;
  outline: 0;
  padding: 0;
  line-height: 1;
  appearance: none;
  border: 0;
  text-align: left;
  display: inline-flex;
  align-items: center;
  border-bottom: ${({ hasUnderline }) => (hasUnderline ? '1px solid' : 'none')};
`;

export const LoaderWrap = styled.span`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;
