import React from 'react';

import { Box } from '@material-ui/core';

import Link from 'components/@common/Link';
import Icon from 'components/Icon';
import { UImgBvbrLogo } from 'components/Icons';

interface Props {
  size?: number;
}

const VbrbLogo: React.FC<Props> = ({ size = 40 }) => (
  <Box mr={6}>
    <Link fill hoverColor="inherit" to="/">
      <Icon size={size}>
        <UImgBvbrLogo />
      </Icon>
    </Link>
  </Box>
);

export default VbrbLogo;
