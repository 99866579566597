import React, { useRef, useState } from 'react';

import { AppBar, Toolbar } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import useSolidBackground from 'utils/useSolidBackground';

import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import { UIcoPijlOnder } from 'components/Icons';

import {
  HeaderItems,
  HeaderItemsInner,
  HeaderRight,
  HeaderWrap,
  MoreItem,
  NavItemWrap,
  PopperStyled,
  ProfileButton,
} from './HeaderDesktop.styled';
import usePriorityNav from './usePriorityNav';

interface HeaderDesktopProps {
  isUserMenuOpen: boolean;
  userMenu?: React.ReactNode;
  onChange: (event: React.MouseEvent, value: string) => void;
  Logo: React.FC;
  inverted?: boolean;
}

const HeaderDesktop: React.FC<HeaderDesktopProps> = props => {
  const { isUserMenuOpen, onChange, children, userMenu, Logo, inverted = false } = props;
  const profileButtonRef = useRef<HTMLButtonElement>(null);
  const headerItemsOuterRef = useRef<HTMLDivElement>(null);
  const headerItemsRef = useRef<HTMLDivElement>(null);
  const moreButtonRef = useRef<HTMLDivElement>(null);
  const childArray = React.Children.toArray(children);
  const toggleMenu = (event: React.MouseEvent) => {
    onChange(event, 'MORE');
  };
  const [morePopperOpen, setMorePopperOpen] = useState(false);
  const { priorityItems, moreItems } = usePriorityNav(
    headerItemsOuterRef,
    headerItemsRef,
    moreButtonRef,
    childArray,
  );

  const solidBackground = useSolidBackground();

  return (
    <>
      <AppBar data-test-id="header-menu-container" elevation={0}>
        <HeaderWrap $inverted={inverted} $solid={solidBackground}>
          <Logo />
          <HeaderItems ref={headerItemsOuterRef}>
            <HeaderItemsInner ref={headerItemsRef}>
              {priorityItems.map((child, i) => (
                <NavItemWrap key={i} $inverted={inverted}>
                  {child}
                </NavItemWrap>
              ))}
            </HeaderItemsInner>
            {moreItems.length > 0 && (
              <>
                <MoreItem
                  ref={moreButtonRef}
                  onClick={() => setMorePopperOpen(true)}
                  $inverted={inverted}
                >
                  <FormattedMessage id="general.menu.more" />
                  <Icon
                    spacing={8}
                    margin="left"
                    color={inverted ? 'neutral01.50' : 'neutral01.500'}
                    size={24}
                  >
                    <UIcoPijlOnder />
                  </Icon>
                </MoreItem>
                <PopperStyled
                  offset={3}
                  open={morePopperOpen}
                  onBackdropClick={() => setMorePopperOpen(false)}
                  anchorEl={moreButtonRef.current}
                  backdropProps={{ invisible: true }}
                  placement="bottom-end"
                >
                  {moreItems.map((child, i) => (
                    <NavItemWrap onClick={() => setMorePopperOpen(false)} key={i} $inverted={false}>
                      {child}
                    </NavItemWrap>
                  ))}
                </PopperStyled>
              </>
            )}
          </HeaderItems>

          <HeaderRight>
            <ProfileButton
              ref={profileButtonRef}
              data-test-id="header-menu-avatar-button"
              onClick={toggleMenu}
              solid={solidBackground}
              inverted={inverted}
            >
              <Avatar
                verticalAlign="center"
                withTextFallback={false}
                color={solidBackground && inverted ? 'neutral01.50' : 'brand01.400'}
                size={27}
              />
            </ProfileButton>
            <PopperStyled
              offset={-1.5}
              open={isUserMenuOpen}
              onBackdropClick={toggleMenu}
              anchorEl={profileButtonRef.current}
              backdropProps={{ invisible: true }}
              placement="bottom-end"
            >
              <>{userMenu || null}</>
            </PopperStyled>
          </HeaderRight>
        </HeaderWrap>
      </AppBar>
      {/* copy of the toolbar so it can preserve it's space for the fixed item */}
      <Toolbar />
    </>
  );
};

export default HeaderDesktop;
