import React from 'react';

import { useField } from 'formik';

import { getFieldErrors } from 'utils/form';

import Field, { FieldProps } from 'components/@inputs/Field';

import RadioTileGroup, { RadioTileGroupProps } from './RadioTileGroup';

export type RadioTileGroupFieldProps = RadioTileGroupProps & FieldProps;

const RadioTileGroupField: React.FC<RadioTileGroupFieldProps> = ({
  name,
  label,
  bottomLabel,
  fullWidth,
  isRequired,
  children,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const errors = getFieldErrors(name, meta);
  const handleChange = (_e: React.ChangeEvent<HTMLInputElement>, value: unknown) => {
    helpers.setValue(value);
  };

  return (
    <Field
      label={label}
      errors={errors}
      bottomLabel={bottomLabel}
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
    >
      <RadioTileGroup id={name} {...field} onChange={handleChange} {...rest} name={name}>
        {children}
      </RadioTileGroup>
      <RadioTileGroup />
    </Field>
  );
};

export default RadioTileGroupField;
