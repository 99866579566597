//

/*
 *
 * Mandrill config
 * Use these dictionaries to configure and register your services to Mandrill's service manager
 *
 */

/*
 *
 * Service config dictionaries
 *
 */

// provider: googleanalytics
// service id: googleanalytics1
// service name: Google Analytics

export const googleanalytics1 = {
  id: 'googleanalytics_1',
  accountIdProd: 'UA-123218248-2',
  dispatchInterval: 30,
};

export default null;
