import React from 'react';

import { Box } from '@material-ui/core';
import dayjs from 'dayjs';
import { useField } from 'formik';

import { getFieldErrors } from 'utils/form';

import Field from 'components/@inputs/Field';

import DateInput, { Props as DateInputProps } from './DateInput';

export interface DateInputFieldProps extends DateInputProps {
  name: string;
  label?: string;
  bottomLabel?: string;
  isRequired?: boolean;
}

const DateInputField: React.FC<DateInputFieldProps> = ({
  name,
  label,
  bottomLabel,
  disabled,
  isRequired,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const errors = getFieldErrors(name, meta);

  const handleChange = (date?: Date) => {
    helpers.setValue(date && dayjs(date).format('YYYY-MM-DD'));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    helpers.setTouched(true);
  };

  return (
    <Field
      label={label}
      errors={errors}
      bottomLabel={bottomLabel}
      name={name}
      isRequired={isRequired}
    >
      <Box mt={2}>
        <DateInput
          id={name}
          disabled={disabled}
          hasError={errors.length > 0}
          variant={field.value ? 'filled' : undefined}
          {...field}
          {...rest}
          onChange={handleChange}
          onBlur={handleBlur}
          name={name}
        />
      </Box>
    </Field>
  );
};

export default DateInputField;
