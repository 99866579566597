import { QUERY_PARAMS as CLAIMS_QUERY_PARAMS } from 'constants/claims';
import { DEFAULT_PARAMS } from 'constants/default.queryparams';

export const QUERY_PARAMS = {
  ...DEFAULT_PARAMS,
  PUBLICATION_DATE_FROM: 'publicationDateFrom',
  PUBLICATION_DATE_UNTIL: 'publicationDateUntil',
  CLAIM_CATEGORY_ID: CLAIMS_QUERY_PARAMS.CLAIM_CATEGORY_ID,
  DATE_FROM_MIN: 'dateFromMin',
  DATE_FROM_MAX: 'dateFromMax',
};

export const STEP_IDS = {
  STEP_REPORT_TYPE: 'stepReportType',
  STEP_REPORT_PARAMETERS: 'stepReportParameters',
  STEP_SUBMIT: 'stepSubmit',
};

export const PARAMETER_TYPES = {
  SELECT: 'SELECT',
  DATE: 'DATE',
};
