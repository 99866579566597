import { createReducer } from 'redux-act';

import { Props as BannerProps } from 'components/Banner';

import {
  bannerHide,
  bannerShow,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './banner.actions';

export type BannerState = BannerProps;

export const INITIAL_STATE = {
  variant: undefined,
  message: undefined,
  action: undefined,
  onActionClick: undefined,
};

const reducer = createReducer<BannerState>({}, INITIAL_STATE);

reducer.on(bannerShow, (state, { banner }) => ({
  ...state,
  ...banner,
  isOpen: true,
}));

reducer.on(bannerHide, state => ({
  ...state,
  ...INITIAL_STATE,
  isOpen: false,
}));

// (-- APPEND REDUCER ACTION MAPPING HERE --) !!! do not move this comment !!!

export default reducer;
