import styled from 'styled-components';

export const OptionStyled = styled.div`
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(4)}px;
`;

export default null;
