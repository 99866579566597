import { connect } from 'react-redux';

import {
  getPartnerContacts,
  getPartnerContactsError,
  getPartnerContactsIsLoading,
  getPartnerContactsMeta,
} from 'store/partner/partner.selectors';
import { partnerContactsFetchStart } from 'store/partner/partner.slice';
import { Store } from 'types/store/store.types';

import Contacts from './Contacts';

const mapStateToProps = (state: Store) => ({
  data: getPartnerContacts(state),
  isLoading: getPartnerContactsIsLoading(state),
  error: getPartnerContactsError(state),
  meta: getPartnerContactsMeta(state),
});

const mapDispatchToProps = {
  fetchContacts: partnerContactsFetchStart,
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export type ContainerProps = MapStateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
