import React, { useRef } from 'react';

import { useField } from 'formik';
import _get from 'lodash/get';

import { getFieldErrors } from 'utils/form';

import Field from 'components/@inputs/Field';

import Dropdown, { DropdownProps } from './Dropdown';

export interface DropdownFieldProps<Item> extends DropdownProps<Item> {
  name: string;
  label?: string;
  bottomLabel?: string;
  isRequired?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  withSearchIcon?: boolean;
  inputValidation?: (value: string) => Promise<{ isError: boolean; data: Item }>;
}

const DropdownField = <T extends any>({
  name,
  label,
  bottomLabel,
  disabled,
  isRequired,
  inputValidation,
  itemToString = () => '',
  fullWidth = true,
  onChange = () => {},
  onInputChange = () => {},
  onEdit = () => {},
  ...rest
}: DropdownFieldProps<T>) => {
  const [field, meta, helpers] = useField(name);
  const inputErrors = getFieldErrors(name, { ...meta, error: _get(meta, 'error.inputValue') });
  const errors = [...inputErrors];
  const previousInput = useRef({});

  const handleInputChange = (value: string) => {
    const prevValues = field.value || {};
    helpers.setValue({ ...prevValues, inputValue: value });
    onInputChange(value);
  };

  const handleChange: DropdownProps<T>['onChange'] = (item, other) => {
    const prevValues = field.value || {};
    helpers.setValue({ ...prevValues, inputValue: itemToString(item), selectedItem: item });
    helpers.setError(undefined);
    onChange(item, other);
  };

  const handleEdit = (item: T) => {
    helpers.setValue({ inputValue: '', selectedItem: null });
    helpers.setError(undefined);
    onEdit(item);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    if (inputValidation) {
      // we set a timeout here as a hack for the async validation of formik
      setTimeout(async () => {
        if (
          !_get(field.value, 'selectedItem') &&
          _get(field.value, 'inputValue') !== previousInput.current
        ) {
          if (!_get(meta.error, 'inputValue')) {
            const { data, isError } = await inputValidation(_get(field.value, 'inputValue'));
            if (!isError) {
              const prevValues = field.value || {};
              helpers.setValue({ ...prevValues, selectedItem: data });
            } else {
              helpers.setError({ inputValue: data });
            }
          }
          previousInput.current = _get(field.value, 'inputValue');
        }
      }, 10);
    }
  };

  return (
    <Field
      label={label}
      errors={errors}
      bottomLabel={bottomLabel}
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
    >
      <Dropdown<T>
        id={name}
        disabled={disabled}
        itemToString={itemToString}
        onEdit={handleEdit}
        isValid={!!field.value?.selectedItem ? true : rest.isValid}
        {...field}
        {...rest}
        name={name}
        inputValue={field.value?.inputValue}
        value={field.value?.selectedItem || null}
        onBlur={handleBlur}
        onChange={handleChange}
        onInputChange={handleInputChange}
      />
    </Field>
  );
};

export default DropdownField;
