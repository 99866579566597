import styled from 'styled-components';

export const Wrap = styled.div<{ isImpersonating?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  border-color: ${({ theme }) => theme.palette.brand01[300]};
  &:after {
    content: '';
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 9999999;
    border: none;
    ${({ theme }) => theme.breakpoints.up('md')} {
      border: ${({ isImpersonating, theme }) =>
        isImpersonating ? `8px solid ${theme.palette.brand01[300]}` : 'none'};
    }
  }
`;

export const ForbiddenActions = styled.span`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(6)}px;
  & > *:not(last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    margin-right: ${({ theme }) => theme.spacing(4)}px;
  }
`;
