import * as Yup from 'yup';

import { formatBytes, getFileExtension, validateSSN } from './helpers';

const fileSizeValidation = () => {
  Yup.addMethod(Yup.mixed, 'fileSize', function(fileSize, msgfn) {
    const msg = 'fileSize';
    return this.test('fileSize', msg, function(value) {
      const message = msgfn({ fileSize: formatBytes(fileSize), fileName: value.name });
      const { path, createError } = this;
      const isValid = value && value.size <= fileSize;
      return isValid || createError({ path, message });
    });
  });
};

const fileTypeValidation = () => {
  Yup.addMethod(Yup.mixed, 'fileType', function(accept, msgfn) {
    const msg = 'fileType';
    return this.test('fileType', msg, function(value) {
      const message = msgfn({
        fileType: getFileExtension(value.name),
        fileName: value.name,
      });
      const { path, createError } = this;
      const isValid =
        value &&
        accept
          .map((extension: string) => extension.toUpperCase())
          .includes(getFileExtension(value.name).toUpperCase());
      return isValid || createError({ path, message });
    });
  });
};

const belgianSSN = () => {
  Yup.addMethod(Yup.mixed, 'belgianSSN', function(message = 'general.errors.ssn') {
    const msg = 'fileType';
    return this.test('fileType', msg, function(value) {
      const { path, createError } = this;
      const isValid = validateSSN(value);
      return isValid || createError({ path, message });
    });
  });
};

export const initializeCustomYupMethods = () => {
  fileSizeValidation();
  fileTypeValidation();
  belgianSSN();
};
