import React, { useState } from 'react';

import ConditionalWrap from 'components/ConditionalWrap';
import HighlightText from 'components/HighlightText';
import Icon from 'components/Icon';
import { UIcoAvatar } from 'components/Icons';
import OverflowTooltip from 'components/OverflowTooltip';

import {
  AvatarDetailsWrap,
  AvatarImg,
  AvatarMidTitle,
  AvatarsubTitle,
  AvatarTitle,
  AvatarTitleSuffix,
  AvatarWrap,
  FallbackWrap,
  Wrap,
} from './Avatar.styled';

export interface Props {
  id?: string | number;
  imgSrc?: string;
  title?: React.ReactNode;
  verticalAlign?: 'center' | 'bottom' | 'top' | 'default';
  midTitle?: React.ReactNode;
  subTitle?: React.ReactNode;
  titleSuffix?: string;
  searchValue?: string;
  size?: number;
  color?: string;
  withTextFallback?: boolean;
  withTitleOverflowTooltip?: boolean;
  inverted?: boolean;
  withHover?: boolean;
}

const Avatar: React.FC<Props> = ({
  imgSrc = '',
  title = '',
  subTitle = '',
  titleSuffix = '',
  size = 32,
  color = 'brand01.200',
  withTextFallback = true,
  verticalAlign,
  searchValue,
  withTitleOverflowTooltip,
  midTitle,
  id,
  inverted = false,
  withHover = false,
}) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const renderFallBack = () => {
    const titleSplitted =
      typeof title === 'string'
        ? title
            .toString()
            .split(' ')
            .filter(Boolean)
        : ['N', 'A'];
    const firstName = titleSplitted[0] || 'N';
    const lastName = titleSplitted[1] || (titleSplitted[0] ? '' : 'A');
    if (withTextFallback) {
      return (
        <FallbackWrap size={size}>
          <span>{firstName.toUpperCase().charAt(0)}</span>
          <span>{lastName.toUpperCase().charAt(0)}</span>
        </FallbackWrap>
      );
    }
    return (
      <Icon color={color} size={size}>
        <UIcoAvatar />
      </Icon>
    );
  };

  const renderImage = () => {
    if (imgSrc && !imageError) {
      return <AvatarImg onError={handleImageError} alt={`${title}` || 'avatar'} src={imgSrc} />;
    }
    return renderFallBack();
  };

  return (
    <Wrap withHover={withHover} verticalAlign={verticalAlign} id={id ? `${id}` : undefined}>
      <AvatarWrap verticalAlign={verticalAlign} size={size}>
        {renderImage()}
      </AvatarWrap>
      {(title || subTitle) && (
        <AvatarDetailsWrap>
          <AvatarTitle $inverted={inverted}>
            {searchValue ? (
              <HighlightText search={searchValue} text={typeof title === 'string' ? title : ''} />
            ) : (
              <ConditionalWrap
                condition={withTitleOverflowTooltip}
                wrap={children => <OverflowTooltip>{children}</OverflowTooltip>}
              >
                <span>{title}</span>
              </ConditionalWrap>
            )}
            {titleSuffix && (
              <AvatarTitleSuffix>&nbsp;&nbsp;|&nbsp;&nbsp;{titleSuffix}</AvatarTitleSuffix>
            )}
          </AvatarTitle>
          {midTitle && <AvatarMidTitle>{midTitle}</AvatarMidTitle>}
          {subTitle && <AvatarsubTitle $inverted={inverted}>{subTitle}</AvatarsubTitle>}
        </AvatarDetailsWrap>
      )}
    </Wrap>
  );
};

export default Avatar;
