import React from 'react';

import { Box, Grid } from '@material-ui/core';
import { ReactComponent as UIcoFeedbackInfo } from 'assets/svg/u_ico_feedback_info.svg';
import { FormattedMessage } from 'react-intl';
import { Dispatch } from 'redux';

import Icon from 'components/Icon';
import { UIcoFeedbackError, UIcoFeedbackWarning } from 'components/Icons';

import { Action } from './Banner.styled';

export interface Props {
  variant?: 'error' | 'warning' | 'info';
  message?: string | { id: string; values?: { [index: string]: string } };
  action?: string;
  onActionClick?: (e: React.MouseEvent, dispatch?: Dispatch) => void;
  isOpen?: boolean;
  dispatch?: Dispatch;
}

const variants = {
  warning: {
    icon: UIcoFeedbackWarning,
    bgColor: 'accent02.100',
  },
  error: {
    icon: UIcoFeedbackError,
    bgColor: 'accent02.400',
  },
  info: {
    icon: UIcoFeedbackInfo,
    bgColor: 'brand01.300',
  },
};

const BannerContent: React.FC<Props> = ({
  message = 'general.errors.critical.text',
  variant = 'error',
  action = 'general.errors.critical.action',
  onActionClick = () => {},
}) => {
  const VariantIcon = variants[variant].icon;
  return (
    <Grid wrap="nowrap" container spacing={3} justify="center" alignItems="center">
      <Grid item>
        <Icon color="neutral01.50" size={28}>
          <VariantIcon />
        </Icon>
      </Grid>
      <Grid item>
        <Box fontWeight={700} component="span">
          {typeof message === 'string' ? (
            <FormattedMessage
              id={message}
              values={{
                action: (
                  <Action onClick={e => onActionClick(e)}>
                    <FormattedMessage id={action} />
                  </Action>
                ),
              }}
            />
          ) : (
            <FormattedMessage {...message} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const Banner: React.FC<Props> = ({
  message = 'general.errors.critical.text',
  variant = 'error',
  action = 'general.errors.critical.action',
  onActionClick = () => {},
  isOpen = false,
  dispatch,
}) => {
  const variantBg = variants[variant].bgColor;

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Box
        position="fixed"
        zIndex={2}
        top={0}
        width="100%"
        bgcolor={variantBg}
        p={2}
        color="neutral01.50"
      >
        <BannerContent
          message={message}
          variant={variant}
          action={action}
          onActionClick={e => onActionClick(e, dispatch)}
        />
      </Box>
      {/* copy of the banner so it can preserve it's space for the fixed item */}
      <Box p={2} visibility="hidden">
        <BannerContent message={message} variant={variant} action={action} />
      </Box>
    </>
  );
};

export default Banner;
