import { Menu as MuiMenu } from '@material-ui/core';
import styled from 'styled-components';
export const TabsWrap = styled.div`
  padding: 0;
  position: relative;
  top: 0;
  color: ${({ theme }) => theme.palette.brand01[400]};
  &:after {
    content: '';
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(0, 8)};
  }
`;

export const Menu = styled(MuiMenu).attrs({ classes: { paper: 'menuPaper' } })`
  .menuPaper {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    width: 100%;
  }
`;
