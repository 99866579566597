import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled, { css } from 'styled-components';

import { CheckboxProps } from './Checkbox';

const checkedStyle = css`
  color: ${({ theme }) => theme.palette.brand01[500]};
  fill: ${({ theme }) => theme.palette.brand01[500]};
  svg {
    fill: ${({ theme }) => theme.palette.brand01[500]};
    color: ${({ theme }) => theme.palette.brand01[500]};
  }
`;

const disabledStyle = css`
  opacity: 0.6;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  svg {
    fill: ${({ theme }) => theme.palette.neutral01[500]};
    color: ${({ theme }) => theme.palette.neutral01[500]};
  }
`;

export const CheckboxStyled = styled(
  ({
    iconSize,
    withLabel,
    hasError,
    bottomLabel,
    ...rest
  }: Pick<
    CheckboxProps,
    'iconSize' | 'disabled' | 'checked' | 'withLabel' | 'hasError' | 'bottomLabel'
  >) => <Checkbox {...rest} />,
)`
  svg {
    width: ${({ iconSize }) => `${iconSize}px`};
    height: ${({ iconSize }) => `${iconSize}px`};
    fill: ${({ theme }) => theme.palette.brand01[200]};
    ${({ checked }) => (checked ? checkedStyle : '')};
    ${({ disabled }) => (disabled ? disabledStyle : '')};
  }
`;

export const LabelStyled = styled(({ isTitle, padding, ...rest }) => (
  <FormControlLabel {...rest} />
))`
  && {
    display: flex;
    align-items: center;
    margin-right: 0 !important;
    margin-left: 0px !important;
    user-select: none;
    padding: ${({ padding, theme }) => padding || `${theme.spacing(1) * 3}px 0`};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed !important' : 'pointer')};
    line-height: 1.5;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.neutral01[600]};
  }

  & :hover {
    svg {
      fill: ${({ theme }) => theme.palette.brand01[500]};
    }
  }

  ${CheckboxStyled} {
    padding-right: ${({ theme }) => theme.spacing(3)}px;
  }
  span {
    align-self: flex-start;
    line-height: 1.5;
    font-size: 16px;
    font-weight: ${({ isTitle }) => (isTitle ? 700 : 400)};
    color: ${({ theme }) => theme.palette.neutral01[600]};

`;
