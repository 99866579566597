import { getCountriesByLocale } from 'components/CountrySelect/CountrySelect';

export const initialFormState = (currentLocale: string) => {
  const countries = getCountriesByLocale(currentLocale);
  return {
    stepCompany: {
      client: undefined,
    },
    stepBaseData: {
      reportingCategories: [],
    },
    stepAddress: {
      country: {
        value: countries.BE.iso,
        label: countries.BE.name,
      },
    },
    stepContact: {},
    stepAffiliation: {},
    stepAdditionalInfo: {
      requiresSalaryData: false,
      requiresIdentificationNumber: false,
    },
    stepDocuments: {},
    stepSummary: {},
  };
};
