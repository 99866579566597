import { useEffect, useState } from 'react';
import React from 'react';

import { Box } from '@material-ui/core';
import useCurrentUser from 'hooks/useCurrentUser/useCurrentUser';
import { sanitizeFilters } from 'hooks/useFilter/useFilter';
import useModal from 'hooks/useModal/useModal';
import _pick from 'lodash/pick';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { queryKeys } from 'constants/querykeys';
import { QueryParams } from 'constants/user.constants';
import { analyticsService, apiService } from 'services';
import { ExternalUsersResponse } from 'services/Api/types/users.types';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { ErrorTrace, Store } from 'types/store/store.types';
export type UserFilters = {
  q?: string;
  p?: string;
  o?: string;
  maxPerPage?: string;
  ownerId?: string;
  role?: string;
  type?: string;
  groupId?: string;
};

const useUsers = (filters: UserFilters) => {
  const userFilters = _pick(sanitizeFilters<UserFilters>(filters), Object.values(QueryParams));
  const endpoint = useSelector((state: Store) => getEndpoint(state, 'users'));
  const { showModal } = useModal();
  const [totalResults, setTotalResults] = useState(0);
  const { user } = useCurrentUser();
  const userEmail = user?.attributes?.email ?? '';
  const query = useQuery<ExternalUsersResponse, ErrorTrace>(
    [queryKeys.users, userFilters],
    () => apiService.users.fetchExternalUsers(endpoint, userFilters),
    {
      keepPreviousData: true,
      enabled: !!endpoint,
      staleTime: 600000,
      onSuccess: () => {
        if (filters.q) {
          analyticsService.events.trackClientsDetailUsersSearch(filters.q);
        }
      },
    },
  );

  useEffect(() => {
    if (query.data?.meta?.count !== undefined && query.data?.meta?.count !== totalResults) {
      setTotalResults(query.data?.meta?.count);
    }
  }, [query.data, totalResults]);

  const exportQuery = useQuery(
    [queryKeys.usersExport, filters],
    () => apiService.users.exportUsers(endpoint, userFilters),
    {
      enabled: false,
      onSuccess: () => {
        showModal({
          modalProps: {
            type: 'FLOW_SUBMISSION_STATUS',
            contentProps: {
              status: 'success',
              title: 'users.export.success.title',
              description: {
                id: 'users.export.success.description',
                values: {
                  userEmail: (
                    <Box color="neutral01.600" component="span">
                      {userEmail}
                    </Box>
                  ),
                },
              },
              buttonText: 'users.export.success.button',
            },
          },
        });
      },
    },
  );

  const exportUsers = () => {
    exportQuery.refetch();
  };

  return {
    ...query,
    totalResults,
    exportUsers,
    isExporting: exportQuery.isFetching,
  };
};

export default useUsers;
