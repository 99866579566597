import React from 'react';

import Icon from 'components/Icon';
import { UIcoSluiten } from 'components/Icons';

import { ChipWrap } from './Chip.styled';

export interface ChipProps {
  type: 'alert' | 'success' | 'warning' | 'default' | string;
  withIcon: boolean;
  onRemove: (children: React.ReactNode, e: React.MouseEvent) => void;
  icon: React.ReactNode;
  isFocused?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

const Chip: React.FC<ChipProps> & { defaultProps: Partial<ChipProps> } = ({
  children,
  type,
  withIcon,
  onRemove,
  onClick,
  isFocused,
  ...rest
}) => {
  const handleRemove = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRemove(children, e);
  };

  return (
    <ChipWrap isFocused={isFocused} onClick={onClick} type={type} {...rest}>
      {children}
      {withIcon && (
        <Icon
          cursor="pointer"
          color={isFocused ? 'neutral01.50' : 'brand01.300'}
          onClick={handleRemove}
          spacing={8}
          margin="left"
          size={18}
        >
          <UIcoSluiten />
        </Icon>
      )}
    </ChipWrap>
  );
};

Chip.defaultProps = {
  type: 'default',
  withIcon: true,
  icon: <UIcoSluiten />,
  onRemove: () => {},
};

export default Chip;
