import { Toolbar } from '@material-ui/core';
import styled from 'styled-components';

import { LinkButton } from 'components/@common/Link';

export const HeaderWrap = styled(Toolbar)`
  && {
    background-color: ${({ theme }) => theme.palette.neutral01[50]};
  }
`;

export const LinkButtonStyled = styled(LinkButton)`
  height: ${({ theme }) => theme.spacing(12)}px;
  font-weight: 400;
  &:hover {
    font-weight: 700;
    & * {
      color: ${({ theme }) => theme.palette.brand01[400]};
    }
    svg path {
      color: ${({ theme }) => theme.palette.brand01[400]};
      stroke: ${({ theme }) => theme.palette.brand01[400]};
    }
  }
`;

export const NavItemFlyOutWrap = styled.div`
  height: ${({ theme }) => theme.spacing(12)}px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  border-bottom: 2px solid transparent;
  & > * {
    display: flex;
    flex: 1 auto;
    color: ${({ theme }) => theme.palette.neutral01[500]};
    text-decoration: none;
    span {
      margin-right: ${({ theme }) => theme.spacing(2)}px;
    }
    svg path {
      color: ${({ theme }) => theme.palette.brand01[600]};
      stroke: ${({ theme }) => theme.palette.brand01[600]};
    }

    &.active {
      font-weight: 700;
      color: ${({ theme }) => theme.palette.brand01[400]};
      svg path {
        color: ${({ theme }) => theme.palette.brand01[400]};
        stroke: ${({ theme }) => theme.palette.brand01[400]};
      }
    }
  }
`;

export const Spacer = styled.div`
  background: ${({ theme }) => theme.palette.neutral01[400]};
  margin: ${({ theme }) => theme.spacing(2, 0)};
  height: 1px;
`;

export const NavItemWrap = styled.div`
  height: ${({ theme }) => theme.mixins.headerHeight}px;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(4)}px;
  }
  white-space: nowrap;
  transition: all 0.3s ease-out;
  flex-grow: 1;
  display: flex;
  cursor: pointer;
  & > * {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    color: ${({ theme }) => theme.palette.neutral01[500]};
    text-decoration: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: ${({ theme }) => theme.mixins.headerHeight}px;
    padding-right: ${({ theme }) => theme.spacing(1)}px;
    padding-left: ${({ theme }) => theme.spacing(1)}px;
    min-width: 63px;
    font-size: 11px;
    svg path {
      color: ${({ theme }) => theme.palette.brand01[600]};
      stroke: ${({ theme }) => theme.palette.brand01[600]};
    }
    &.active {
      font-weight: 700;
      color: ${({ theme }) => theme.palette.brand01[400]};
      box-shadow: ${({ theme }) => theme.shadows[1]};
      * {
        color: ${({ theme }) => theme.palette.brand01[400]};
        stroke: ${({ theme }) => theme.palette.brand01[400]};
      }
    }
  }
  &:hover {
    color: ${({ theme }) => theme.palette.brand01[400]};
    * {
      color: ${({ theme }) => theme.palette.brand01[400]};
      stroke: ${({ theme }) => theme.palette.brand01[400]};
    }
  }
`;

export default null;
