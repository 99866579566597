import React from 'react';

import { connect } from 'react-redux';

import { getCurrentModal, getCurrentModalOpen } from 'store/modal/modal.selectors';
import { modalHide } from 'store/modal/modal.slice';
import { Store } from 'types/store/store.types';

import { MODAL_COMPONENTS } from './constants';
import Modal, { ModalProps } from './Modal';

export type ModalContentProps<Key extends keyof typeof MODAL_COMPONENTS> = React.ComponentProps<
  typeof MODAL_COMPONENTS[Key]
>;

export interface ConnectedModalProps extends ModalProps {
  type?: keyof typeof MODAL_COMPONENTS;
  contentProps?: ModalContentProps<keyof typeof MODAL_COMPONENTS>;
}
const ConnectedModal: React.FC<ConnectedModalProps> = ({
  isOpen = false,
  closeModal = () => {},
  type,
  contentProps = {},
  ...rest
}) => {
  const ModalContent = type && MODAL_COMPONENTS[type];
  if (!isOpen || !ModalContent) return null;
  return (
    <Modal isOpen closeModal={closeModal} {...rest}>
      <ModalContent {...contentProps} closeModal={closeModal} />
    </Modal>
  );
};

const mapStateToProps = (state: Store) => ({
  ...getCurrentModal(state),
  isOpen: getCurrentModalOpen(state),
});

const mapDispatchToProps = {
  closeModal: modalHide,
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type MapDispatchProps = typeof mapDispatchToProps;
export type ContainerProps = MapStateProps & MapDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedModal);
