import React, { useState } from 'react';

import { useField, yupToFormErrors } from 'formik';
import _get from 'lodash/get';
import * as Yup from 'yup';

import { FieldError, getFieldErrors } from 'utils/form';

import Field from 'components/@inputs/Field';

import FileUpload, { FileUploadProps } from './FileUpload';

export interface FileUploadFieldProps extends FileUploadProps {
  name: string;
  label?: string;
  bottomLabel?: string;
  isRequired?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  max?: number;
  filterFilesSchema?: Yup.ObjectSchema<any>;
}

const FileUploadField: React.FC<FileUploadFieldProps> = ({
  name,
  label,
  bottomLabel,
  disabled,
  filterFilesSchema,
  fullWidth = true,
  isRequired,
  multiple = true,
  onChange,
  ...rest
}) => {
  const [field, _meta, helpers] = useField(name);
  const [containerErrors, setContainerErrors] = useState<FieldError[]>([]);

  const handleChange: FileUploadProps['onChange'] = (files, type, addedOrRemovedFiles) => {
    helpers.setTouched(true);
    let errors: any = [];
    let values = files;
    let addOrRemove = addedOrRemovedFiles;
    try {
      if (filterFilesSchema) {
        filterFilesSchema.validateSync({ [name]: values }, { abortEarly: false, strict: true });
      }
    } catch (error) {
      errors = _get(yupToFormErrors(error), `${name}`);
      values = values.filter(file => {
        const foundFile = errors.find((error: any) => _get(error, 'values.fileName') === file.name);
        const foundFileName = _get(foundFile, 'values.fileName');
        addOrRemove = addOrRemove.filter(addOrRemoveFile => addOrRemoveFile.name !== foundFileName);
        return file.name !== foundFileName;
      });
    }

    const maxFiles = multiple ? rest.max : 1;
    if (maxFiles && values.length > maxFiles) {
      errors = [...errors, { id: 'general.errors.max_files.multiple', values: { max: maxFiles } }];
      values = values.splice(0, maxFiles);
      addOrRemove = addOrRemove.splice(0, maxFiles);
    }
    setContainerErrors(errors);
    if (onChange) {
      onChange(values, type, addOrRemove);
    } else {
      helpers.setValue(values);
    }
  };

  const blockingErrors = getFieldErrors(name, _meta);

  return (
    <Field
      label={label}
      bottomLabel={bottomLabel}
      errors={[...containerErrors.filter(Boolean), ...blockingErrors]}
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
    >
      <FileUpload
        id={name}
        disabled={disabled}
        multiple={multiple}
        {...field}
        {...rest}
        onChange={handleChange}
      />
    </Field>
  );
};

export default FileUploadField;
