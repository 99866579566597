import React from 'react';

import { Backdrop } from '@material-ui/core';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { Props } from './Backdrop';

export const BackdropStyled = styled(({ zIndex, ...rest }: Props) => <Backdrop {...rest} />)`
  && {
    ${({ open, zIndex }) => (open && `z-index: ${zIndex || '1101'}`) || ''};
    background-color:  ${({ theme, invisible }) =>
      !invisible ? transparentize(0.6, theme.palette.brand01[200]) : 'transparent'};
`;
