import { PolicyFilters } from 'hooks/usePolicies/usePolicies';
import { RiskFilters } from 'hooks/useRisks/useRisks';

import { ApiInstanceType } from '.';
import {
  PoliciesResponse,
  PolicyDetailsResponse,
  PolicyResponse,
  RiskCategoriesResponse,
  RisksResponse,
} from './types/policies.types';
class PoliciesApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchPolicies = (path: string, filters: PolicyFilters) =>
    this.api
      .get<PoliciesResponse>(path, {
        params: filters,
      })
      .then(response => response.data);

  exportPolicies = (path: string, filters: { [index: string]: string }) =>
    this.api
      .get<Blob>(path, {
        cache: {
          maxAge: 0,
          ignoreCache: true,
        },
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'blob',
        params: filters,
      })
      .then(response => response);

  fetchPolicy = (path: string, id: string) =>
    this.api.get<PolicyResponse>(`${path}/${id}`).then(response => response.data);

  fetchPolicyDetails = (path: string) =>
    this.api.get<PolicyDetailsResponse>(path).then(response => response.data);

  fetchPolicyRisks = (path: string, riskFilters: RiskFilters) =>
    this.api
      .get<RisksResponse>(path, {
        params: riskFilters,
      })
      .then(response => response.data);

  fetchRiskCategories = (path: string) =>
    this.api.get<RiskCategoriesResponse>(path).then(response => response.data);
}

export default PoliciesApi;
