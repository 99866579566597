import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@material-ui/core';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { EMPLOYEE_QUERYPARAMS } from 'constants/claimSubmission';
import { analyticsService } from 'services';
import { Employee } from 'store/claimSubmission/types';

import { removeEmptyKeysFromObject } from 'utils/helpers';

import SplitScreen from 'components/Templates/SplitScreen';

import { ContainerProps } from './ClaimSubmissionEmployeeAdvancedSearchContainer';
import Results from './Results';
import SearchForm, { FormValues } from './SearchForm';

interface Props {
  closeModal: () => void;
  onSelectEmployee?: (employee: Employee) => void;
}

const ClaimSubmissionEmployeeAdvancedSearch: React.FC<Props & ContainerProps> = ({
  resetEmployees,
  closeModal,
  policies,
  employees,
  selectedPolicy,
  fetchEmployees,
  isLoading,
  error,
  meta,
  onSelectEmployee = () => {},
}) => {
  useEffect(() => {
    resetEmployees();
  }, [resetEmployees]);

  const handleClose = () => {
    resetEmployees();
    closeModal();
  };

  const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(undefined);
  const [hasSearched, setHasSearched] = useState(false);
  const [filter, setFilter] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (!_isEqual(previousFilter.current, filter)) {
      analyticsService.events.trackTpaGiEmployeeAdvancedSearch(JSON.stringify(filter));
      fetchEmployees({ params: filter });
    }
  }, [filter, fetchEmployees]);

  const previousFilter = useRef({});

  useEffect(() => {
    previousFilter.current = filter;
  });

  const handleSearch = (values: FormValues) => {
    const { name, surName, identificationNumber, birthDate, personnelNumber, policy } = values;
    const {
      BIRTH_DATE,
      CLAIM_DOMAIN_ID,
      CLAIM_SEGMENT_ID,
      FIRST_NAME,
      LAST_NAME,
      IDENTIFICATION_NUMBER,
      PERSONNEL_NUMBER,
      POLICY_ID,
      MAX_PER_PAGE,
      PAGE,
    } = EMPLOYEE_QUERYPARAMS;
    const queryParams: {} = removeEmptyKeysFromObject({
      [BIRTH_DATE]: birthDate,
      [CLAIM_DOMAIN_ID]: _get(policy, 'meta.claimDomain.data.id'),
      [CLAIM_SEGMENT_ID]: _get(policy, 'meta.claimSegment.data.id'),
      [POLICY_ID]: _get(policy, 'meta.id'),
      [FIRST_NAME]: surName,
      [LAST_NAME]: name,
      [IDENTIFICATION_NUMBER]: identificationNumber,
      [PERSONNEL_NUMBER]: personnelNumber,
      [MAX_PER_PAGE]: '25',
      [PAGE]: '1',
    });
    setFilter(queryParams);
    setSelectedEmployee(undefined);
    setHasSearched(true);
  };

  const handleEmployeeSelect = (item: Employee) => {
    setSelectedEmployee(item);
  };

  const handleCloseAndSelect = () => {
    if (selectedEmployee) {
      onSelectEmployee(selectedEmployee);
    }
    closeModal();
  };

  return (
    <Box height={{ xs: '100%', md: 'calc(100vh - 150px)' }} position="relative">
      <SplitScreen>
        <SplitScreen.Panel
          parent="left"
          overflow="auto"
          flex="1 auto"
          header="claims.add.step.tpa_gi_employee.advanced_search.modal.title"
          value={0}
          bgcolor={{ xs: 'neutral01.50', md: 'brand01.100' }}
        >
          {({ handleGoToNextPanel }) => (
            <SearchForm
              isLoading={isLoading}
              onSearch={values => {
                handleSearch(values);
                handleGoToNextPanel();
              }}
              selectedPolicy={selectedPolicy}
              policies={policies}
            />
          )}
        </SplitScreen.Panel>
        <SplitScreen.Panel
          parent="right"
          headerVariant="link"
          overflow="hidden"
          flex="1 auto"
          header="claims.add.step.tpa_gi_employee.advanced_search.modal.backlink_text"
          value={1}
          bgcolor={{ xs: 'brand01.100', md: 'neutral01.50' }}
        >
          <Results
            onSelectEmployee={handleEmployeeSelect}
            selectedEmployee={selectedEmployee}
            isLoading={isLoading}
            onClose={handleClose}
            results={employees}
            onCloseAndSelect={handleCloseAndSelect}
            error={error}
            hasSearched={hasSearched}
            meta={meta}
            currentPage={parseInt(filter[EMPLOYEE_QUERYPARAMS.PAGE], 10) || 1}
            onPageChange={nextPage =>
              setFilter({ ...filter, [EMPLOYEE_QUERYPARAMS.PAGE]: `${nextPage}` })
            }
          />
        </SplitScreen.Panel>
      </SplitScreen>
    </Box>
  );
};

export default ClaimSubmissionEmployeeAdvancedSearch;
