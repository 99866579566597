import styled from 'styled-components';

export const Wrap = styled.div`
  box-shadow: 0px 1px 5px rgba(51, 51, 51, 0.1);
  flex: 1 auto;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  margin: 2px;
  background: ${({ theme }) => theme.palette.neutral01[50]};
`;
