import React from 'react';

import { Theme } from '@material-ui/core';
import { ReactComponent as UIcoCheckboxChecked } from 'assets/svg/u_ico_checkbox_checked.svg';
import { ReactComponent as UIcoCheckboxDefault } from 'assets/svg/u_ico_checkbox_default.svg';

import { createCustomMuiTheme } from '../utils/create-mui-theme';
// import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';
import colors from './colors';
import { createOverrides } from './overrides';

const CONFIG_TYPOGRAPHY = {
  BASE_FONT_SIZE: 16,
  RATIO_FACTOR: 1.15,
};

let THEME = createCustomMuiTheme({
  breakpoints: { values: { xs: 300, sm: 320, md: 768, lg: 1280, xl: 1920 } },
  mixins: {
    headerItemHeight: 38,
    headerHeight: 64,
    tableRowHeight: 56,
  },
  palette: {
    primary: { main: colors.brand01[300] },
    secondary: { main: colors.brand01[400] },
    error: { main: colors.accent02[400] },
    text: { primary: colors.neutral01[700] },
    ...colors,
  },
  shadows: ([
    'none',
    '0px 4px 11px rgba(22, 65, 148, 0.08)', // shadow-02
    '0 0 0 1px rgba(51, 51, 51, 0.1), 0 5px 10px rgba(51, 51, 51, 0.1)',
    '0 1px 5px rgba(51, 51, 51, 0.1)', // shadow-01
    ...new Array(22).fill('0 0 0 1px rgba(51, 51, 51, 0.1), 0 5px 10px rgba(51, 51, 51, 0.1)'),
  ] as unknown) as Theme['shadows'], // why: https://github.com/mui-org/material-ui/issues/8209
  shape: {
    borderRadius: 4,
    radius: factor => 4 * factor,
  },
  spacing: 4,
  typography: {
    fontSize: CONFIG_TYPOGRAPHY.BASE_FONT_SIZE,
    fontFamily: ['TheSansLF', 'Verdana', 'sans-serif'].join(','),
    h1: {
      fontSize: 56,
      lineHeight: 1.13,
      fontWeight: 'bold',
      color: colors.neutral01[600],
    },
    h2: {
      fontSize: 32,
      lineHeight: 1.33,
      fontWeight: 'bold',
      color: colors.neutral01[600],
    },
    h3: {
      fontSize: 24,
      lineHeight: 1.4,
      fontWeight: 'bold',
      color: colors.neutral01[600],
    },
    h4: {
      fontSize: 20,
      lineHeight: 1.5,
      fontWeight: 'bold',
      color: colors.neutral01[600],
    },
    h5: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 'bold',
      color: colors.neutral01[600],
    },
    h6: {
      fontSize: 12,
      lineHeight: 1.5,
      fontWeight: 'bold',
      color: colors.neutral01[600],
    },
    body1: {
      fontSize: 16,
      color: colors.neutral01[600],
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: 1.5,
      color: colors.neutral01[500],
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.2,
      fontWeight: 400,
      color: colors.neutral01[500],
    },
  },
  transitions: {
    easing: { easeInOut: 'cubic-bezier(0, 0, 0.2, 1)' },
    duration: { standard: 200 },
  },
  props: {
    MuiInput: {
      fullWidth: true,
      disableUnderline: true,
    },
    MuiCheckbox: {
      icon: <UIcoCheckboxDefault />,
      checkedIcon: <UIcoCheckboxChecked />,
      disableRipple: true,
      edge: 'start',
    },
    MuiFilledInput: {
      fullWidth: true,
      disableUnderline: true,
    },
    MuiInputLabel: {
      // https://github.com/mui-org/material-ui/issues/8436 --> the other way around
      shrink: true,
      disableAnimation: true,
    },
    MuiInputAdornment: {
      disableTypography: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiLink: {
      underline: 'always',
      color: 'secondary',
    },
  },
});

THEME.overrides = createOverrides(THEME);

// const THEME_RESPONSIVE = responsiveFontSizes(THEME, {
//   factor: CONFIG_TYPOGRAPHY.RATIO_FACTOR,
// });

// console.info('MUI theme created', THEME);

export default THEME;
