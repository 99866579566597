import React from 'react';

import { SnackbarProvider } from 'notistack';

import { NOTIFIER_SETTINGS } from 'components/Notification/notifier.constants';

import GlobalStyle from './overrideContainer';

export default <T extends {}>(Component: React.ComponentType<T>) => (props: T) => {
  return (
    <>
      <GlobalStyle />
      <SnackbarProvider
        {...NOTIFIER_SETTINGS}
        classes={{
          containerAnchorOriginBottomRight: 'notistack notistack-container-bottom-right',
        }}
      >
        <Component {...props} />
      </SnackbarProvider>
    </>
  );
};
