import styled from 'styled-components';
export const SalaryContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(5, 5, 3, 5)};
  background-color: ${({ theme }) => theme.palette.brand01[100]};
  margin: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
  border-radius: 5px;
`;

export const SalaryHeader = styled.div`
  margin: ${({ theme }) => theme.spacing(0, 0, 4, 0)};
`;

export const SalaryHeaderLinks = styled.div`
  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(4)}px;
  }
`;
