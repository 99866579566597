import React from 'react';

import Avatar from 'components/Avatar';
import { useMedia } from 'components/Media';

import { RowType } from '../UserTable';

interface Props {
  row: RowType;
}

const NameCell: React.FC<Props> = ({ row }) => {
  const firstName = row.attributes?.firstName;
  const lastName = row.attributes?.lastName;
  const content = [firstName, lastName].filter(Boolean).join(' ');
  const media = useMedia();
  return (
    <Avatar
      id={row.id}
      verticalAlign="center"
      title={content}
      subTitle={media.small ? row.attributes?.email : undefined}
      withTitleOverflowTooltip
    />
  );
};

export default NameCell;
