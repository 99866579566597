import { PartnerType } from 'store/fileSubmission/types';

import { TagProps } from 'components/@common/Tag';

export const STEP_IDS = {
  STEP_DOMAIN: 'stepDomain',
  STEP_RECIPIENT: 'stepRecipient',
  STEP_UPLOAD: 'stepUpload',
  STEP_COMMENTS: 'stepComments',
  STEP_FILE_UPLOAD: 'stepFileUpload',
  STEP_SUBMIT: 'stepSubmit',
};

export const QUERY_PARAMS = {
  DOMAIN: 'domain',
  PARTNER_ID: 'partnerId',
};

export const PARTNER_TYPES: Record<
  PartnerType,
  { intlKey: string; badgeColor: TagProps['type'] }
> = {
  client: { intlKey: 'partner.types.client', badgeColor: 'default' },
  broker: {
    intlKey: 'partner.types.broker',
    badgeColor: 'default',
  },
  insurer: { intlKey: 'partner.types.insurer', badgeColor: 'default' },
};

export default null;
