import React from 'react';

import { connect } from 'react-redux';

import { getRouteType } from 'store/routing/routing.selectors';
import { Store } from 'types/store/store.types';

import Background from './Background';

const BackgroundContainer: React.FC<BackgroundProps> = ({ ...rest }) => {
  return <Background {...rest} />;
};

const mapStateToProps = (state: Store) => ({
  route: getRouteType(state),
});

const mapDispatchToProps = {};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export type BackgroundProps = MapStateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundContainer);
