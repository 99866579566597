import { useDispatch, useSelector } from 'react-redux';
import { Action, Params, pathToAction } from 'redux-first-router';

import { setQuery } from 'store/routing/routing.actions';
import { getLocation, getQuery, getRoutesMap } from 'store/routing/routing.selectors';

const useRouter = () => {
  const dispatch = useDispatch();
  const routesMap = useSelector(getRoutesMap);
  const currentRouterState = useSelector(getLocation);
  const queryParameters = useSelector(getQuery);

  const handleRedirect = (path: string, payload: Action['payload'] = {}) => {
    const action = pathToAction(path, routesMap);
    dispatch({ ...action, payload: { ...action.payload, ...payload } });
  };

  const setQueryParams = (params: Params) => {
    dispatch(setQuery(params));
  };

  return {
    push: handleRedirect,
    queryParameters,
    setQueryParams,
    routesMap,
    routerState: currentRouterState,
  };
};

export default useRouter;
