import React from 'react';

import { Box } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { genders } from 'pages/affiliations/Forms/BaseDataForm';

import DateFormat from 'components/Date';
import { SummaryField, SummaryTitle } from 'components/FormSummary';

import { StepperState } from '../../AffiliationsCreateStepper';

interface Props {
  onEditClick: () => void;
}

const BaseDataSummary: React.FC<Props & StepperState['stepBaseData']> = ({
  onEditClick,
  ...values
}) => {
  const intl = useIntl();
  return (
    <Box mb={8}>
      <SummaryTitle title="affiliations.add.step.base_data.title" onEditClick={onEditClick} />
      <SummaryField
        label="affiliations.add.step.base_data.firstName_label"
        value={values.firstName}
      />
      <SummaryField
        label="affiliations.add.step.base_data.lastName_label"
        value={values.lastName}
      />
      <SummaryField
        label="affiliations.add.step.base_data.personelNumber_label"
        value={values.personnelNumber}
      />
      <SummaryField
        label="affiliations.add.step.base_data.birthDate_label"
        value={values.birthDate && <DateFormat format="DD/MM/YYYY" date={values.birthDate} />}
      />
      <SummaryField
        label="affiliations.add.step.base_data.gender_label"
        value={genders[values.gender ?? '']?.label}
      />
      <SummaryField
        label="affiliations.add.step.base_data.communicationLanguage_label"
        value={intl.formatMessage({
          id: `profile.settings.language.${values.communicationLanguage?.value}`,
        })}
      />
      {(values.reportingCategories || []).map(reportingCategory => (
        <SummaryField
          key={reportingCategory?.value.categoryId}
          isPhraseKey={false}
          label={reportingCategory?.value?.clientReportingCategoryTitle ?? ''}
          value={reportingCategory?.label}
        />
      ))}
    </Box>
  );
};

export default BaseDataSummary;
