import React from 'react';

import { Formik } from 'formik';
import { connect } from 'react-redux';

import {
  getAffiliationClientsFilteredByUserRights,
  getAffiliationSubmissionCompaniesError,
  getAffiliationSubmissionCompaniesIsLoading,
  getAffiliationSubmissionStatus,
} from 'store/affiliationSubmission/affiliationSubmission.selectors';
import { createInsurantStart } from 'store/affiliationSubmission/affiliationSubmission.slice';
import { Client } from 'store/client/client.types';
import { Store } from 'types/store/store.types';

import CompanyStep from './CompanyStep';
import { ValidationSchema } from './CompanyStep.validation';

export type FormValues = {
  client?: Client;
};

type Props = {
  onNextStepClick: (values: FormValues) => void;
  initialValues: FormValues;
  saveForm: (values: FormValues) => void;
};
type ChildProps = React.ComponentProps<typeof CompanyStep>;

const CompanyStepContainer: React.FC<Props & ContainerProps & ChildProps> = ({
  onNextStepClick,
  initialValues,
  data,
  error,
  isLoading,
  saveForm,
  status,
  createInsurant,
}) => {
  const { client: initialClient, ...restInitialValues } = initialValues;
  const client =
    data?.length === 1 ? { client: initialClient ?? data[0] } : { client: initialClient };

  return (
    <Formik<FormValues>
      enableReinitialize
      validateOnMount
      validationSchema={ValidationSchema}
      initialValues={{
        ...client,
        ...restInitialValues,
      }}
      onSubmit={values => {
        createInsurant({
          data: {
            type: 'insurant',
            attributes: { status: 'DRAFT' },
            relationships: { client: { data: { type: 'client', id: values.client?.id ?? '' } } },
          },
          callback: () => {
            onNextStepClick(values);
          },
          shouldFetchReportingCategories: true,
        });
      }}
    >
      <CompanyStep
        status={status}
        saveForm={saveForm}
        data={data}
        isLoading={isLoading}
        error={error}
      />
    </Formik>
  );
};

const mapStateToProps = (state: Store) => ({
  data: getAffiliationClientsFilteredByUserRights(state),
  isLoading: getAffiliationSubmissionCompaniesIsLoading(state),
  error: getAffiliationSubmissionCompaniesError(state),
  status: getAffiliationSubmissionStatus(state),
});
const mapDispatchToProps = {
  createInsurant: createInsurantStart,
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type MapDispatchProps = typeof mapDispatchToProps;
export type ContainerProps = MapStateProps & MapDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyStepContainer);
