import styled from 'styled-components';

import Chip from 'components/@common/Chip';

import { TagListProps } from './TagList';

export const TagListWrap = styled.div<Pick<TagListProps, 'spacing'>>`
  margin: -${({ spacing }) => spacing}px;
`;

export const ChipStyled = styled(Chip)<Pick<TagListProps, 'spacing'>>`
  margin: ${({ spacing }) => spacing}px;
`;

export const TagValue = styled.span`
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
`;

export const SkeletonLineWrap = styled.div<Pick<TagListProps, 'spacing'>>`
  display: inline-block;
  margin: ${({ spacing }) => spacing}px;
`;

export const LinkWrap = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1) * 4}px;
`;
