/**
 * [https://material-ui.com/customization/default-theme/#explore](Default)
 */

import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import _merge from 'lodash/merge';

/**
 * **do not modify**
 * defines default MUI theme properties which are merged w/ optional theme options
 */

const DEFAULT_OPTIONS: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
  direction: 'ltr',
  shape: {
    borderRadius: 8, // default MUI radius
    radius: factor => [0, 4, 8][factor], // custom radiusses (similar to spacing)
  },
  spacing: factor => [0, 8, 16, 24, 32, 40, 48, 72, 96][factor],
  typography: {
    htmlFontSize: 16,
    fontSize: 18,
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: 16,
      },
    },
  },
};

const createCustomMuiTheme = (options: ThemeOptions) =>
  createMuiTheme(_merge(DEFAULT_OPTIONS, options));

export { createCustomMuiTheme };
