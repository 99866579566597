import { generateStore } from 'packages/redux';
import { Store } from 'redux';
import { createFilter } from 'redux-persist-transform-filter';

import config from 'config';

import reducers from './reducers';
import sagas from './sagas';
import { systemStart } from './system/system.actions';

const saveUserSubset = createFilter('user', ['data.attributes.cookies']); // TODO: add to redux documentation
const saveAuthSubset = createFilter('auth', ['tokens']);
const storeConfig = generateStore(sagas, reducers, {
  initApp: (store: Store) => {
    // start booting process by firing redux action after redux persist is done
    store.dispatch(systemStart());
  },
  enableDevTools: config.ENABLE_DEV_TOOLS,
  persistConfig: {
    key: config.LOCAL_STORAGE_ID,
    whitelist: ['intl', 'user', 'auth'],
    transforms: [saveUserSubset, saveAuthSubset],
  },
});

export default storeConfig;
