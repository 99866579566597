import React from 'react';

import { useField } from 'formik';

import { getFieldErrors } from 'utils/form';

import Field from 'components/@inputs/Field';

import RadioGroup, { RadioGroupProps } from './RadioGroup';

export interface RadioGroupFieldProps extends RadioGroupProps {
  name: string;
  label?: string;
  bottomLabel?: string;
  isRequired?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

const RadioGroupField: React.FC<RadioGroupFieldProps> = ({
  name,
  label,
  bottomLabel,
  disabled,
  fullWidth,
  isRequired,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const errors = getFieldErrors(name, meta);

  return (
    <Field
      label={label}
      errors={errors}
      bottomLabel={bottomLabel}
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
    >
      <RadioGroup
        id={name}
        disabled={disabled}
        fullWidth={fullWidth}
        {...field}
        {...rest}
        name={name}
      />
    </Field>
  );
};

export default RadioGroupField;
