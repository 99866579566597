import { InsurantResponse } from 'store/affiliation/affiliation.types';
import { InsurantsResponse } from 'store/affiliations/affiliations.types';
import { InsurantDraftRequestBody } from 'store/affiliationSubmission/affiliationSubmission.types';

import { ApiInstanceType } from './';

class AffiliationsApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchInsurant(path: string, id: string) {
    return this.api.get<InsurantResponse>(`${path}/${id}`).then(response => response.data);
  }

  fetchInsurants(path: string, params: { [index: string]: string | boolean }) {
    return this.api
      .get<InsurantsResponse>(path, {
        params,
      })
      .then(response => response.data);
  }

  updateInsurant(endpoint: string, body: InsurantDraftRequestBody) {
    return this.api.put<InsurantResponse>(endpoint, body).then(resp => resp.data);
  }

  fetchRequestInsuranceCertificates(
    endpoint: string,
    ids: string[] = [],
    mailSettings: { insuredParty: boolean; employer: boolean },
  ) {
    return this.api
      .get(endpoint, {
        params: {
          insurantIds: ids.join(','),
          insuredParty: mailSettings.insuredParty,
          employer: mailSettings.employer,
        },
      })
      .then(response => response.data);
  }
}

export default AffiliationsApi;
