import { all, call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects';

import env from 'config';

import { apiService } from 'services';
import { clientReportingCategoriesFetchFlow } from 'store/client/client.saga';
import { getGroupId } from 'store/client/client.selectors';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { addNotification } from 'store/notifications/notifications.actions';

import {
  getAffiliationPoliciesEndpoint,
  getAffiliationSubmissionData,
  getFlowContext,
} from './affiliationSubmission.selectors';
import {
  createInsurantFail,
  createInsurantStart,
  createInsurantSuccess,
  fetchAffiliationPoliciesFail,
  fetchAffiliationPoliciesStart,
  fetchAffiliationPoliciesSuccess,
  fetchAffiliationTerminationReasonsFail,
  fetchAffiliationTerminationReasonsStart,
  fetchAffiliationTerminationReasonsSuccess,
  fetchCompaniesFail,
  fetchCompaniesStart,
  fetchCompaniesSuccess,
  updateInsurantFail,
  updateInsurantStart,
  updateInsurantSuccess,
  updateVerifyInsurantFail,
  updateVerifyInsurantStart,
  updateVerifyInsurantSuccess,
} from './affiliationSubmission.slice';
import {
  sanitizeInsurantAffiliationValues,
  sanitizeInsurantDraftValues,
  sanitizeInsurantValues,
  sanitizeValuesForAddAffiliations,
} from './sanitizeData';

function* companiesFetchFlow() {
  try {
    const endpoint = yield select(getEndpoint, 'clients');
    const groupId = yield select(getGroupId);
    const { data } = yield call([apiService, apiService.client.fetchClients], endpoint, {
      isHealthcare: true,
      parentId: groupId,
    });
    yield put(fetchCompaniesSuccess({ data }));
  } catch (error) {
    yield put(fetchCompaniesFail({ error }));
  }
}

function* createInsurantFlow({ payload }: ReturnType<typeof createInsurantStart>) {
  try {
    const endpoint = yield select(getEndpoint, 'insurants');
    const { data }: SagaReturnType<typeof apiService.affiliation.create> = yield call(
      [apiService, apiService.affiliation.create],
      endpoint,
      payload.data,
    );
    if (payload.shouldFetchReportingCategories) {
      yield call(clientReportingCategoriesFetchFlow, {
        payload: {
          clientId: payload.data.relationships.client.data.id,
        },
      });
    }

    yield call(payload.callback);
    yield put(createInsurantSuccess({ data }));
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'general.error.update.text',
      }),
    );

    yield put(createInsurantFail({ error }));
  }
}

function* updateVerifyInsurantFlow({ payload }: ReturnType<typeof updateVerifyInsurantStart>) {
  try {
    const { verifyUnique, data: body, callback } = payload;
    const endpoint = yield select(getEndpoint, 'insurants');
    const draft: SagaReturnType<typeof getAffiliationSubmissionData> = yield select(
      getAffiliationSubmissionData,
    );
    const nextDraft = sanitizeInsurantDraftValues(draft, body);
    if (nextDraft) {
      const { data, meta }: SagaReturnType<typeof apiService.affiliation.update> = yield call(
        [apiService, apiService.affiliation.update],
        `${endpoint}/${draft?.id}`,
        { data: nextDraft, meta: { verifyUnique: !!verifyUnique } },
      );
      yield put(
        updateVerifyInsurantSuccess({ data, meta: { ...meta, isUnique: !!meta?.isUnique } }),
      );
      callback(!!meta?.isUnique);
    }
  } catch (error) {
    yield put(updateVerifyInsurantFail({ error }));
  }
}

function* updateInsurantFlow({ payload }: ReturnType<typeof updateInsurantStart>) {
  try {
    const endpoint = yield select(getEndpoint, 'insurants');
    const insurantDraft: SagaReturnType<typeof getAffiliationSubmissionData> = yield select(
      getAffiliationSubmissionData,
    );
    const flowContext: SagaReturnType<typeof getFlowContext> = yield select(getFlowContext);
    const body =
      flowContext?.type === 'AFFILIATION'
        ? sanitizeValuesForAddAffiliations(insurantDraft, payload.values)
        : sanitizeInsurantValues(insurantDraft, payload.values);
    if (body) {
      const {
        stepAffiliation: { affiliations, ...rest },
        stepAdditionalInfo: { ...salary },
      } = payload.values;
      yield all(
        Object.values(affiliations || {}).map(affiliation =>
          call(
            [apiService, apiService.affiliation.createInsurantAffiliation],
            `${endpoint}/${body?.id}/affiliations`,
            sanitizeInsurantAffiliationValues(affiliation, rest, salary),
          ),
        ),
      );
      let insurantUpdateResponse:
        | SagaReturnType<typeof apiService.affiliation.update>
        | undefined = undefined;

      insurantUpdateResponse = yield call(
        [apiService, apiService.affiliation.update],
        `${endpoint}/${body?.id}`,
        { data: body },
      );
      yield put(updateInsurantSuccess({ data: insurantUpdateResponse?.data }));
    } else {
      throw new Error('no body');
    }
  } catch (error) {
    yield put(updateInsurantFail({ error }));
  }
}

function* fetchAffilionPoliciesFlow({ payload }: ReturnType<typeof fetchAffiliationPoliciesStart>) {
  try {
    const endpoint: SagaReturnType<typeof getAffiliationPoliciesEndpoint> = yield select(
      getAffiliationPoliciesEndpoint,
    );
    const insurantDraft: SagaReturnType<typeof getAffiliationSubmissionData> = yield select(
      getAffiliationSubmissionData,
    );
    const {
      data,
    }: SagaReturnType<typeof apiService.affiliation.fetchAffiliationPolicies> = yield call(
      [apiService, apiService.affiliation.fetchAffiliationPolicies],
      endpoint,
      insurantDraft?.id ?? '',
      insurantDraft?.relationships?.client?.data?.id ?? '',
    );
    if (payload.callback) {
      yield call(payload.callback);
    }

    yield put(fetchAffiliationPoliciesSuccess({ data }));
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'general.error.text',
      }),
    );
    yield put(fetchAffiliationPoliciesFail({ error }));
  }
}

export function* fetchTerminationReasonsFlow() {
  try {
    const endpoint = yield select(getEndpoint, 'affiliationTerminationReasons');
    const {
      data,
    }: SagaReturnType<typeof apiService.affiliation.fetchAffiliationTerminationReasons> = yield call(
      [apiService, apiService.affiliation.fetchAffiliationTerminationReasons],
      endpoint || `${env.API_BASE}/api/hc/affiliation_termination_reasons`,
    );
    yield put(fetchAffiliationTerminationReasonsSuccess({ data }));
  } catch (error) {
    yield put(fetchAffiliationTerminationReasonsFail({ error }));
    throw error;
  }
}

function* affiliationSubmissionSaga() {
  yield takeLatest(fetchCompaniesStart, companiesFetchFlow);
  yield takeLatest(createInsurantStart, createInsurantFlow);
  yield takeLatest(updateVerifyInsurantStart, updateVerifyInsurantFlow);
  yield takeLatest(fetchAffiliationPoliciesStart, fetchAffilionPoliciesFlow);
  yield takeLatest(fetchAffiliationTerminationReasonsStart, fetchTerminationReasonsFlow);
  yield takeLatest(updateInsurantStart, updateInsurantFlow);
}

export default affiliationSubmissionSaga;
