export default {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || '',

  // api
  API_BASE: process.env.REACT_APP_API_BASE || '',
  API_CLIENT_URL: process.env.REACT_APP_API_CLIENT_URL || '',
  API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL || '',

  // analytics
  ANALYTICS_GOOGLE_TRACKING_ID: process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID || '',
  ANALYTICS_GOOGLE_DEBUG: true,

  // error tracking
  SENTRY: process.env.REACT_APP_SENTRY || '',

  // dev
  PORT: process.env.REACT_APP_PORT || 3000,
  ENABLE_DEV_TOOLS: process.env.REACT_APP_ENABLE_DEV_TOOLS || true,
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID || 'nf-vbrb-0002',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY || 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE || 'en',
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE || 'nl',
  APPLICATION_INSIGHTS_KEY: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY || '',
};
