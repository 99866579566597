import React, { useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import useLocations from 'hooks/useLocations/useLocations';
import useLocationsStreets from 'hooks/useLocations/useLocationStreets';

import { LocationData, LocationStreetData } from 'services/Api/types/locations.types';

import { DropdownField } from 'components/@inputs/Dropdown';
import { InputField } from 'components/@inputs/TextField';
import HighlightText from 'components/HighlightText';

import { AddressFormValues } from './AddressDataForm';

const AddressFieldBE: React.FC = () => {
  const [locationField] = useField('locationBE');
  const { setValues, values } = useFormikContext<AddressFormValues>();
  const selectedLocationId = locationField.value?.selectedItem?.id;

  const [search, setSearch] = useState('');
  const [streetSearch, setStreetSearch] = useState('');

  const { data: locations, status: locationStatus } = useLocations(search);
  const { data: locationStreets, status: locationStreetsStatus } = useLocationsStreets(
    selectedLocationId,
    streetSearch,
  );

  const handleEditClick = () => {
    setStreetSearch('');
    setValues(
      {
        ...values,
        locationBE: undefined,
        street: undefined,
        number: '',
        numberExtension: '',
      },
      false,
    );
  };

  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <DropdownField<LocationData>
          name="locationBE"
          label="affiliations.add.step.address.zipcode_place_label"
          onEdit={handleEditClick}
          isRequired
          onInputChange={setSearch}
          itemToString={({ attributes: { name, zipcode } }) => `${zipcode} | ${name}`}
          itemToOption={({ attributes: { name, zipcode } }, inputValue) => (
            <Box py={2}>
              <HighlightText search={inputValue} text={`${zipcode} | ${name}`} />
            </Box>
          )}
          ListItemLoader={null}
          isLoading={locationStatus === 'loading'}
          options={locations?.data ?? []}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <DropdownField<LocationStreetData>
          name="street"
          label="affiliations.add.step.address.street_label"
          isRequired
          withSearchIcon={false}
          readOnlyOnSelection={true}
          disabled={!selectedLocationId}
          onInputChange={setStreetSearch}
          itemToString={({ attributes: { name } }) => `${name}`}
          itemToOption={({ attributes: { name } }, inputValue) => (
            <Box py={2}>
              <HighlightText search={inputValue} text={`${name}`} />
            </Box>
          )}
          ListItemLoader={null}
          isLoading={locationStreetsStatus === 'loading'}
          options={locationStreets?.data ?? []}
        />
      </Grid>
      <Grid item xs>
        <InputField
          fullWidth
          isRequired
          disabled={!selectedLocationId}
          name="number"
          label="affiliations.add.step.address.number_label"
        />
      </Grid>
      <Grid item xs>
        <InputField
          fullWidth
          disabled={!selectedLocationId}
          name="numberExtension"
          label="affiliations.add.step.address.bus_label"
        />
      </Grid>
    </Grid>
  );
};

export default AddressFieldBE;
