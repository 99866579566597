import React from 'react';

import { ReactComponent as UIcoLFallback } from 'assets/svg/u_ico_l_fallback.svg';
import { ReactComponent as UIcoSFallback } from 'assets/svg/u_ico_s_fallback.svg';
import { useSelector } from 'react-redux';

import { TYPES } from 'constants/policies';
import { getCategoriesForEntity } from 'store/categories/categories.selectors';
import { Store } from 'types/store/store.types';
interface Props {
  categoryIds?:
    | {
        id: string;
        type: string;
      }[]
    | {
        id: string;
        type: string;
      };
  variant?: 'small' | 'large';
}

const PolicyCategoryIcon: React.FC<Props> = ({ categoryIds, variant = 'small' }) => {
  const policyCategories = useSelector((state: Store) =>
    getCategoriesForEntity(state, 'policyCategory'),
  );
  const categories = Array.isArray(categoryIds) ? categoryIds : [categoryIds];

  let policyCategory: keyof typeof TYPES = 'DEFAULT';
  if (categoryIds) {
    policyCategory =
      categories?.length === 1
        ? (policyCategories?.[categories[0]?.id ?? '-1']?.attributes?.key as keyof typeof TYPES) ??
          'DEFAULT'
        : 'MULTIPLE';
  }
  let Icon = TYPES[policyCategory]?.icon ?? UIcoSFallback;
  if (variant === 'large') {
    Icon = TYPES[policyCategory]?.iconLarge ?? UIcoLFallback;
  }

  return <Icon />;
};

export default PolicyCategoryIcon;
