import React, { memo } from 'react';

import Tooltip from 'components/Tooltip';

import DetectOverflow from './DetectOverflow';

interface IProps {}

const OverflowTooltip: React.FC<IProps> = ({ children, ...rest }) => {
  const renderTooltip = () => (
    <Tooltip maxWidth="250px" title={<div>{children}</div>} {...rest}>
      <span>{children}</span>
    </Tooltip>
  );
  return (
    <DetectOverflow>
      {isOverflowing => (isOverflowing ? renderTooltip() : <span>{children}</span>)}
    </DetectOverflow>
  );
};

export default memo(OverflowTooltip);
