import React, { useState } from 'react';

import { Box, DialogActions, DialogContent } from '@material-ui/core';
import { FieldArray, Formik, FormikProps } from 'formik';
import useUpdateClaimIncapacityPeriods from 'hooks/useClaimIncapacityPeriods/useUpdateClaimIncapacityPeriods';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { FormattedMessage } from 'react-intl';

import { analyticsService } from 'services';
import { IncapacityPeriod } from 'services/Api/types/claim.types';

import { hasFormErrors } from 'utils/helpers';

import Button from 'components/@common/Button';
import { LinkButton } from 'components/@common/Link';
import { Form } from 'components/@inputs';
import { BottomLabel } from 'components/@inputs/Field/Field.styled';

import { ButtonWrap } from './IncapacityPeriods.styled';
import { ValidationSchema } from './IncapacityPeriodsEdit.validation';
import WorkIncapacityForm from './IncapacityPeriodsEditForm';

interface Props {
  initialValues?: FormValues;
  editPeriod?: IncapacityPeriod;
  claimId?: string;
  closeModal?: () => void;
}

export type FormValue = IncapacityPeriod;

export interface FormValues {
  periods: FormValue[];
  overlap?: string;
}

const WorkIncapacityStep: React.FC<Props> = ({
  initialValues = {
    periods: [],
  },
  closeModal = () => {},
  editPeriod,
  claimId = '',
}) => {
  const { updateIncapacityPeriods, status } = useUpdateClaimIncapacityPeriods(claimId);

  const isUpdating = status === 'loading';
  const [editingIndex, setEditingIndex] = useState<number | undefined>(
    editPeriod && initialValues.periods?.indexOf(editPeriod),
  );

  const [valuesToUpdate, setValuesToUpdate] = useState<{ [index: number]: FormValue }>({});

  const handleCancel = () => {
    closeModal();
  };

  const handleSaveItem = (
    validateForm: FormikProps<FormValues>['validateForm'],
    period: FormValue,
    index: number,
  ) => () => {
    const isUpdated = !_isEqual(initialValues?.periods?.[index], period);

    if (isUpdated) {
      setValuesToUpdate({ ...valuesToUpdate, [index]: period });
    } else {
      const { [index]: val, ...rest } = valuesToUpdate;
      setValuesToUpdate(rest);
    }
    validateForm();
    setEditingIndex(undefined);
  };
  const handleEditItem = (
    index: number,
    validateForm: FormikProps<FormValues>['validateForm'],
    values: FormValues,
  ) => () => {
    validateForm(values);
    setEditingIndex(index);
  };

  return (
    <Formik<FormValues>
      validateOnMount
      validationSchema={ValidationSchema}
      onSubmit={() => {
        analyticsService.events.trackUpdateClaim();
        updateIncapacityPeriods(
          {
            body: Object.values(valuesToUpdate),
          },
          {
            onSuccess: () => {
              closeModal();
            },
          },
        );
      }}
      initialValues={initialValues}
    >
      {({ values, handleSubmit, errors, validateForm }) => {
        return (
          <>
            <DialogContent>
              <Form data-test-id="claim-submission-incapacity-step-container">
                <FieldArray
                  name="periods"
                  render={() => {
                    return (
                      <>
                        {values?.periods?.map((period, i) => {
                          return (
                            <WorkIncapacityForm
                              key={i}
                              period={period}
                              canEdit={editingIndex === undefined}
                              index={i}
                              onSave={handleSaveItem(validateForm, period, i)}
                              onEdit={handleEditItem(i, validateForm, values)}
                              isEditing={editingIndex === i}
                              isValid={!hasFormErrors(_get(errors, `periods.${i}`, {}))}
                            />
                          );
                        })}
                      </>
                    );
                  }}
                />
              </Form>
            </DialogContent>
            <DialogActions>
              <div>
                {errors.overlap && (
                  <Box clone mb={4}>
                    <BottomLabel error>
                      <FormattedMessage id={errors.overlap} />
                    </BottomLabel>
                  </Box>
                )}
                <ButtonWrap>
                  <Button
                    isLoading={isUpdating}
                    type="submit"
                    disabled={
                      hasFormErrors(errors) ||
                      editingIndex !== undefined ||
                      Object.values(valuesToUpdate).length === 0
                    }
                    onClick={() => handleSubmit()}
                  >
                    <FormattedMessage id="claims.detail.incapacity_periods.add.modal.update.text" />
                  </Button>
                  <LinkButton disabled={isUpdating} onClick={handleCancel}>
                    <FormattedMessage id="general.label.cancel" />
                  </LinkButton>
                </ButtonWrap>
              </div>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
};

export default WorkIncapacityStep;
