import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import { AutoSizer } from 'react-virtualized';

import { useMedia } from 'components/Media';

import Tab from './Tab';
import { tabContext } from './TabContext';
import TabPanel from './TabPanel';
import TabsPanels from './TabPanels';
import Tabs from './Tabs';

interface Props {
  initialActive?: string;
  Tabs?: React.ReactElement[];
  swipeable?: boolean;
  onTabChange?: (tabId: string) => void;
}

interface TabsComposite {
  Tab: typeof Tab;
  Panel: typeof TabPanel;
}

const { Provider } = tabContext;

const PageTabs: React.FC<Props> & TabsComposite = ({
  children,
  swipeable = true,
  initialActive = '',
  onTabChange = () => {},
}) => {
  const [activeId, setActiveId] = useState(initialActive);
  const [hasMounted, setHasMounted] = useState<string[]>([initialActive]);

  const handleChange = (id: string) => {
    setActiveId(id);
    onTabChange(id);
    setHasMounted(Array.from(new Set([...hasMounted, id])));
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpenMobileTabMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMobileTabMenu = () => {
    setAnchorEl(null);
  };

  const media = useMedia();

  let panelChildren: React.ReactElement[] = [];
  let tabChildren: React.ReactElement[] = [];
  let tabs: string[] = [];
  React.Children.forEach(children, child => {
    if (!React.isValidElement(child)) {
      console.warn('only valid react elements are supported');
      return;
    }

    switch (child.type) {
      case Tab: {
        tabChildren.push(child);
        tabs.push(child.props.value);
        break;
      }
      case TabPanel: {
        panelChildren.push(child);
        break;
      }
      default:
        console.warn('only supported components of type: Tab, TabPanel are supported');
    }
  });

  return (
    <Provider
      value={{
        activeId,
        handleChange,
        tabs,
        anchorEl,
        handleOpenMobileTabMenu,
        handleCloseMobileTabMenu,
        hasMounted,
      }}
    >
      <AutoSizer
        disableWidth
        disableHeight={media.small}
        style={{ flex: '1 auto', display: 'flex', flexDirection: 'column' }}
      >
        {({ height }) => {
          return (
            <Box
              height={height}
              display="flex"
              bgcolor="neutral01.200"
              flexDirection="column"
              flex="1 auto"
              borderRadius={8}
            >
              <Tabs>{tabChildren}</Tabs>
              <TabsPanels swipeable={swipeable}>{panelChildren}</TabsPanels>
            </Box>
          );
        }}
      </AutoSizer>
    </Provider>
  );
};

PageTabs.Tab = Tab;
PageTabs.Panel = TabPanel;

export default PageTabs;
