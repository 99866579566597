import { all } from 'redux-saga/effects';

import affiliationSaga from './affiliation/affiliation.saga';
import affiliationsSaga from './affiliations/affiliations.saga';
import affiliationSubmissionSaga from './affiliationSubmission/affiliationSubmission.saga';
import authSaga from './auth/auth.saga';
import avatarsSaga from './avatars/avatars.saga';
import blockNavigationSaga from './blockNavigation/blockNavigation.saga';
import categoriesSaga from './categories/categories.saga';
import claimsReportsSaga from './claimReports/claimReports.saga';
import claimSubmissionSaga from './claimSubmission/claimSubmission.saga';
import clientSaga from './client/client.saga';
import dashboardSaga from './dashboard/dashboard.saga';
import dataShareSaga from './dataShare/dataShare.saga';
import documentSaga from './document/document.saga';
import endpointSaga from './endpoint/endpoint.saga';
import feedbackSaga from './feedback/feedback.saga';
import fileSaga from './file/file.saga';
import fileSubmissionSaga from './fileSubmission/fileSubmission.saga';
import impersonateSaga from './impersonate/impersonate.saga';
import invoiceSaga from './invoice/invoice.saga';
import invoicesSaga from './invoices/invoices.saga';
import partnerSaga from './partner/partner.saga';
import policySaga from './policy/policy.saga';
import publicationsSaga from './publications/publications.saga';
import riskSaga from './risk/risk.saga';
import routingSaga from './routing/routing.saga';
import watchSystem from './system/system.saga';
import tutorialSaga from './tutorial/tutorial.saga';
import userSaga from './user/user.saga';
import vehicleCertificatesSaga from './vehicleCertificates/vehicleCertificates.saga';

export default function* sagas() {
  yield all([
    watchSystem(),
    endpointSaga(),
    authSaga(),
    routingSaga(),
    userSaga(),
    tutorialSaga(),
    documentSaga(),
    fileSaga(),
    clientSaga(),
    feedbackSaga(),
    claimSubmissionSaga(),
    policySaga(),
    riskSaga(),
    dashboardSaga(),
    dataShareSaga(),
    fileSubmissionSaga(),
    partnerSaga(),
    invoicesSaga(),
    invoiceSaga(),
    avatarsSaga(),
    blockNavigationSaga(),
    categoriesSaga(),
    affiliationsSaga(),
    impersonateSaga(),
    vehicleCertificatesSaga(),
    affiliationSubmissionSaga(),
    affiliationSaga(),
    claimsReportsSaga(),
    publicationsSaga(),
  ]);
}
