import React from 'react';

import { Circle, Lines } from '../loaders.styled';
import SkeletonLine from '../SkeletonLine';
import { Wrap } from './ListItemLoader.styled';

const ListItemLoader = () => (
  <div>
    <Wrap>
      {<Circle size={18} />}
      <Lines hasIcon>
        <SkeletonLine duration={1.5} minWidth={160} height={12} delay={0.2} marginBottom={0} />
      </Lines>
    </Wrap>
  </div>
);

export default ListItemLoader;
