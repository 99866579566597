import React from 'react';

import { FormattedMessage } from 'react-intl';

import { FlowType } from 'pages/affiliations/Create/AffiliationsCreate';
import { STEP_IDS } from 'pages/affiliations/Create/steps.constants';

import Button from 'components/@common/Button';

// import { CheckboxField } from 'components/@inputs/Checkbox';
// import { SummaryTitle } from 'components/FormSummary';
import { StepperState } from '../../AffiliationsCreateStepper';
import AdditionalInfoSummary from './AdditionalInfoSummary';
import AddressSummary from './AddressSummary';
import AffiliationsSummary from './AffiliationsSummary';
import BaseDataSummary from './BaseDataSummary';
import ContactSummary from './ContactSummary';
import DocumentsSummary from './DocumentsSummary';
import { ButtonsWrap } from './SummaryStep.styled';

interface Props {
  formValues: StepperState;
  onEditClick: (stepId: string) => void;
  flowType: FlowType;
  submitButtonText: string;
  onNextStepClick: () => void;
}

const SummaryStep: React.FC<Props> = ({
  onEditClick,
  formValues,
  flowType,
  submitButtonText,
  onNextStepClick,
}) => {
  const {
    STEP_BASE_DATA,
    STEP_ADDRESS,
    STEP_CONTACT,
    STEP_AFFILIATION,
    STEP_DOCUMENTS,
    STEP_ADDITIONAL_INFO,
  } = STEP_IDS;
  const {
    stepBaseData,
    stepAddress,
    stepContact,
    stepAffiliation,
    stepAdditionalInfo,
    stepDocuments,
  } = formValues;

  const handleEditClick = (step: string) => {
    onEditClick(step);
  };

  return (
    <>
      {flowType !== 'AFFILIATION' && (
        <BaseDataSummary {...stepBaseData} onEditClick={() => handleEditClick(STEP_BASE_DATA)} />
      )}
      {flowType === 'MAIN' && (
        <>
          <AddressSummary {...stepAddress} onEditClick={() => handleEditClick(STEP_ADDRESS)} />
          <ContactSummary {...stepContact} onEditClick={() => handleEditClick(STEP_CONTACT)} />
        </>
      )}
      <AffiliationsSummary
        {...stepAffiliation}
        onEditClick={() => handleEditClick(STEP_AFFILIATION)}
      />
      <AdditionalInfoSummary
        {...stepAdditionalInfo}
        onEditClick={() => handleEditClick(STEP_ADDITIONAL_INFO)}
      />
      <DocumentsSummary {...stepDocuments} onEditClick={() => handleEditClick(STEP_DOCUMENTS)} />
      <ButtonsWrap>
        <Button
          data-test-id="affiliations.add.step.summary.actions.submit"
          onClick={onNextStepClick}
        >
          <FormattedMessage id={submitButtonText} />
        </Button>
      </ButtonsWrap>
    </>
  );
};

export default SummaryStep;
