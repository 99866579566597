import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import _get from 'lodash/get';
import styled, { css } from 'styled-components';

import { Props } from './Label';
export const InputLabelstyled = styled(
  ({ children, hasValue, hasError, color, isBold, whiteSpace, ...rest }: Props) => (
    <InputLabel {...rest}>{children}</InputLabel>
  ),
)`
  && {
    position: static;
    transform: none;
    white-space: ${({ whiteSpace }) => whiteSpace};
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
    line-height: 1.5;
    ${({ theme, hasError, isBold }) => {
      if (hasError) {
        // error style
        return css`
          color: ${theme.palette.accent02[400]};
        `;
      }
      if (isBold) {
        return css`
          color: ${theme.palette.neutral01[600]};
          font-weight: 700;
        `;
      }
      // default style
      return css`
        color: ${theme.palette.neutral01[600]};
      `;
    }}
    ${({ color, theme }) => (color ? `color: ${_get(theme.palette, color, '')}` : '')};
`;

export const LabelWrap = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;
