import { createStyles, withStyles } from '@material-ui/core';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiRadio from '@material-ui/core/Radio';

export const Radio = withStyles(theme =>
  createStyles({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: theme.palette.neutral01[50],
      zIndex: -1,
      borderRadius: theme.shape.borderRadius,
      border: '1px solid',
      borderColor: theme.palette.neutral01[300],
    },
    checked: {
      color: theme.palette.brand01[300],
      background: theme.palette.brand01[800],
      border: '2px solid',
      borderColor: theme.palette.brand01[300],
    },
  }),
)(MuiRadio);

export const FormControlLabel = withStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      marginLeft: 0,
      marginRight: 0,
      padding: theme.spacing(2, 3),
      '& .checked': {
        color: theme.palette.brand01[300],
        background: theme.palette.brand01[800],
        border: '2px solid',
        borderColor: theme.palette.brand01[300],
      },
    },
  }),
)(MuiFormControlLabel);
