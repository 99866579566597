import React, { PureComponent } from 'react';

import _get from 'lodash/get';

import UploadFile from './UploadFileContainer';

class FileUploader extends PureComponent {
  static defaultProps = {
    groupFiles: [],
    fileGroups: {},
    onFileUploadComplete: () => {},
    onRetry: () => {},
  };

  componentDidMount = () => {
    const { uploadPath, initFileUpload, groupFiles } = this.props;

    /* groupName necessary for mutiple uploads from different input on same page and same name of files --> otherwise no unique key for selectors
    for example: input 1 : file-1.jpg, input 2: file-1.jpg... Both need to be uploaded at the same time
    */
    Object.keys(groupFiles).forEach(key => {
      initFileUpload({
        files: groupFiles[key] || [],
        path: uploadPath,
        groupName: key,
      });
    });
  };

  componentDidUpdate = () => {
    const {
      isCompleted,
      onFileUploadComplete,
      failedFiles,
      uploadedFiles,
      groupFiles,
    } = this.props;
    const totalFilesCount = Object.values(groupFiles).reduce(
      (acc, fileArray) => acc + (fileArray || []).length,
      0,
    );
    const completedFilesCount = Object.values(uploadedFiles).length;
    if (isCompleted && totalFilesCount === completedFilesCount) {
      onFileUploadComplete({
        uploadedFiles,
        failedFiles,
      });
    }
  };

  handleFileRetry = (groupName, index) => file => {
    const { uploadFile, uploadPath, onRetry } = this.props;
    onRetry(groupName, file);
    uploadFile({ file, path: uploadPath, groupName, index });
  };

  render() {
    const { groupFiles, children, failedFiles, uploadedFiles, isCompleted } = this.props;
    return (
      <div>
        {Object.keys(groupFiles).map(key =>
          _get(groupFiles, `${key}`, []).map((file, index) => (
            <UploadFile
              index={index}
              onRetry={this.handleFileRetry(key, index)}
              key={`${file.name}${index}`}
              file={file}
              groupName={key}
            />
          )),
        )}
        {children({ uploadedFiles, failedFiles, isCompleted })}
      </div>
    );
  }
}

export default FileUploader;
