import React, { PureComponent } from 'react';

import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { messages } from 'services/i18n/config';
import { getLocale } from 'store/intl/intl.selectors';
import { IntlState } from 'types/store/intl.types';
import { Store } from 'types/store/store.types';

const locales: { [key: string]: { value: string } } = {
  en: { value: 'en-GB' },
  nl: { value: 'nl-BE' },
  fr: { value: 'fr' },
};

interface Props {
  locale: IntlState['locale'];
}

const mapStateToProps = (state: Store) => ({
  locale: getLocale(state),
});

export default (Component: React.FC) =>
  connect(mapStateToProps)(
    class extends PureComponent<Props> {
      render() {
        const { locale } = this.props;
        return (
          <IntlProvider locale={locales[locale].value} key={locale} messages={messages[locale]}>
            <Component {...this.props} />
          </IntlProvider>
        );
      }
    },
  );
