import { createReducer } from 'redux-act';

import {
  dialogHide,
  dialogNo,
  dialogShow,
  dialogYes,
  setDialogStatus,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './dialog.actions';

export const INITIAL_STATE = {
  currentDialog: null,
  isOpen: false,
  status: undefined,
};

const REDUCERS = {
  [dialogYes]: state => Object.assign({}, state, {}),
  [dialogNo]: state => Object.assign({}, state, {}),
  [dialogShow]: (state, { currentDialog }) =>
    Object.assign({}, state, {
      isOpen: true,
      currentDialog,
    }),
  [dialogHide]: state =>
    Object.assign({}, state, {
      currentDialog: null,
      isOpen: false,
    }),
  [setDialogStatus]: (state, { status }) =>
    Object.assign({}, state, {
      status,
    }),
  // (-- APPEND REDUCER ACTION MAPPING HERE --) !!! do not move this comment !!!
};

export default createReducer(REDUCERS, INITIAL_STATE);
