import { connect } from 'react-redux';

import {
  getFileAttempts,
  getFileError,
  getFileIsUploading,
  getFileProgress,
} from 'store/file/file.selectors';
import { Store } from 'types/store/store.types';

import UploadFile from './UploadFile';

export interface ContainerProps {
  file: File;
  groupName: string;
  index: number;
}

const mapStateToProps = (state: Store, { file, groupName, index }: ContainerProps) => ({
  isUploading: getFileIsUploading(state, `${groupName}.${file.name}.${index}`),
  error: getFileError(state, `${groupName}.${file.name}.${index}`),
  progress: getFileProgress(state, `${groupName}.${file.name}.${index}`),
  uploadAttempts: getFileAttempts(state, `${groupName}.${file.name}.${index}`),
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);
