import { useQueryClient } from 'react-query';

import { queryKeys } from 'constants/querykeys';
import { Insurer } from 'services/Api/types/misc.types';

import { notEmpty } from 'utils/typescript.helpers';

const useInsurers = () => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<{ [id: string]: Insurer }>([queryKeys.insurers]);

  const getInsurerById = (insurerId: string) => {
    return data?.[insurerId];
  };

  const getInsurersByIds = (insurerIds: string[]) => {
    return insurerIds.map(insurerId => data?.[insurerId]).filter(notEmpty);
  };

  const setInsurers = (insurers: { [index: string]: Insurer }) => {
    queryClient.setQueryData([queryKeys.insurers], { ...data, ...insurers });
  };

  return { data: data ?? {}, getInsurerById, getInsurersByIds, setInsurers };
};

export default useInsurers;
