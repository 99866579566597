import React from 'react';

import MuiTableBody, { TableBodyProps as MUITableBodyProps } from '@material-ui/core/TableBody';

const TabelBody: React.FC<MUITableBodyProps> = ({
  children,
  component = 'div',
  ...rest
}: MUITableBodyProps) => (
  <MuiTableBody component={component} {...rest}>
    {children}
  </MuiTableBody>
);

export default TabelBody;
