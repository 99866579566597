import config from 'config/env';

import { PrivateIndexResponse, PublicIndexResponse } from 'store/endpoint/endpoint.types';

import { ApiInstanceType } from './';
class EndpointsApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchPublicEndpoints = () =>
    this.api
      .get<PublicIndexResponse>(`${config.API_BASE}/public_api/`)
      .then(response => response.data);

  fetchPrivateEndpoints = (path: string) =>
    this.api.get<PrivateIndexResponse>(path).then(response => response.data);

  fetchAllCategories = (path: string) => this.api.get(path).then(response => response.data);
}

export default EndpointsApi;
