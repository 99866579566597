import React, { useEffect, useRef } from 'react';

import useFilter, { sanitizeFilters } from 'hooks/useFilter/useFilter';
import { connect } from 'react-redux';

import { DEFAULT_PARAMS } from 'constants/default.queryparams';
import { getCategoriesForEntity } from 'store/categories/categories.selectors';
import {
  getDocumentIsDownloading,
  getDocuments,
  getDocumentsDownloading,
  getDocumentsError,
  getDocumentsIsLoading,
  getDocumentsMeta,
} from 'store/document/document.selectors';
import { documentDownloadFetchStart, documentsFetchStart } from 'store/document/document.slice';
import { Document, DocumentsContext } from 'store/document/document.types';
import { Store } from 'types/store/store.types';

import DocumentsFilter from './DocumentsFilter';
import DocumentsTable from './DocumentsTable';

const CONTEXT_QUERY_PARAM: {
  claims: 'claimId';
  policies: 'policyId';
  invoices: undefined;
  support: undefined;
  general: undefined;
  healthcare: undefined;
} = {
  claims: 'claimId',
  policies: 'policyId',
  invoices: undefined,
  support: undefined,
  general: undefined,
  healthcare: undefined,
};

const QUERY_PARAMS: {
  DATE_FROM: 'dateFrom';
  DATE_TO: 'dateTo';
} = {
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
};

interface Props {
  context: DocumentsContext;
  id: string;
}

const DocumentsTableContainer: React.FC<Props & ContainerProps> = ({
  context,
  fetchDocuments,
  id,
  data,
  isLoading,
  downloadDocument,
  error,
  meta,
  categories,
  documentsDownloading,
}) => {
  const {
    handlePageChange,
    handleSortChange,
    handleMaxPerPageChange,
    filter,
    handleFilterChange,
  } = useFilter({
    disablePersist: true,
  });
  const currentPage = filter[DEFAULT_PARAMS.PAGE] || 1;
  const sort = filter[DEFAULT_PARAMS.ORDER];
  const maxPerPage = filter[DEFAULT_PARAMS.MAX_PER_PAGE] || 10;

  const idQueryParam = CONTEXT_QUERY_PARAM[context];
  const idQuery = idQueryParam ? { [idQueryParam]: id } : {};
  const idQueryRef = useRef(idQuery);
  useEffect(() => {
    fetchDocuments({
      maxPerPage,
      ...sanitizeFilters<typeof filter>(filter),
      context,
      ...idQueryRef.current,
    });
  }, [context, fetchDocuments, filter, id, maxPerPage]);

  const handleDownload = (row: Document) => {
    downloadDocument({
      id: row.id,
      fileName: row.attributes.fileName,
      path: row.links.download,
    });
  };

  return (
    <>
      <DocumentsFilter
        categories={categories}
        onFilterChange={({ documentDate, ...rest }) => {
          const values = {
            [QUERY_PARAMS.DATE_FROM]: documentDate?.from,
            [QUERY_PARAMS.DATE_TO]: documentDate?.to,
            ...rest,
          };
          handleFilterChange(values);
        }}
        initialValues={{}}
        context={context}
      />
      <DocumentsTable
        rows={data}
        isLoading={isLoading}
        meta={meta}
        error={error}
        onDownload={handleDownload}
        onSort={handleSortChange}
        onPageChange={handlePageChange}
        orderBy={sort}
        onRowsPerPageChange={handleMaxPerPageChange}
        currentPage={currentPage}
        maxPerPage={maxPerPage}
        downloading={documentsDownloading}
        context={context}
        categories={categories}
      />
    </>
  );
};

const mapStateToProps = (state: Store) => ({
  data: getDocuments(state),
  isLoading: getDocumentsIsLoading(state),
  error: getDocumentsError(state),
  isDownloading: getDocumentIsDownloading(state),
  meta: getDocumentsMeta(state),
  documentsDownloading: getDocumentsDownloading(state),
  categories: getCategoriesForEntity(state, 'documentCategory'),
});

const mapDispatchToProps = {
  fetchDocuments: documentsFetchStart,
  downloadDocument: documentDownloadFetchStart,
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export type ContainerProps = MapStateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTableContainer);
