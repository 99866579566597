import { createReducer } from 'redux-act';

import {
  fileUploadFail,
  fileUploadProgress,
  fileUploadStart,
  fileUploadSuccess,
  initFileUpload,
  resetUpload,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './file.actions';

export const INITIAL_STATE = {
  succeeded: 0,
  failed: 0,
  total: 0,
  uploading: false,
  files: {},
};

const REDUCERS = {
  [fileUploadStart]: (state, { file, groupName, index }) =>
    Object.assign({}, state, {
      failed: state.failed > 0 ? state.failed - 1 : 0,
      files: {
        ...state.files,
        [`${groupName}.${file.name}.${index}`]: {
          progress: 0,
          attempts: state.files[`${groupName}.${file.name}.${index}`]
            ? state.files[`${groupName}.${file.name}.${index}`].attempts + 1
            : 0,
          isUploading: true,
          error: null,
        },
      },
    }),
  [fileUploadSuccess]: (state, { file, data, groupName, index }) =>
    Object.assign({}, state, {
      files: {
        ...state.files,
        [`${groupName}.${file.name}.${index}`]: {
          ...state.files[`${groupName}.${file.name}.${index}`],
          progress: 100,
          isUploading: false,
          error: null,
          data,
        },
      },
      succeeded: state.succeeded + 1,
    }),
  [fileUploadFail]: (state, { error, file, groupName, index }) =>
    Object.assign({}, state, {
      files: {
        ...state.files,
        [`${groupName}.${file.name}.${index}`]: {
          ...state.files[`${groupName}.${file.name}.${index}`],
          isUploading: false,
          error: error || 'unknown error',
        },
      },
      failed: state.failed + 1,
    }),
  [fileUploadProgress]: (state, { file, progress, groupName, index }) =>
    Object.assign({}, state, {
      files: {
        ...state.files,
        [`${groupName}.${file.name}.${index}`]: {
          ...state.files[`${groupName}.${file.name}.${index}`],
          isUploading: true,
          error: null,
          progress,
        },
      },
    }),
  [initFileUpload]: (state, { files }) =>
    Object.assign({}, state, {
      succeeded: state.succeeded,
      failed: state.failed,
      total: state.total + files.length,
      files: { ...state.files },
    }),
  [resetUpload]: state =>
    Object.assign({}, state, {
      ...INITIAL_STATE,
    }),
  // (-- APPEND REDUCER ACTION MAPPING HERE --) !!! do not move this comment !!!
};

export default createReducer(REDUCERS, INITIAL_STATE);
