import dayjs from 'dayjs';
import * as Yup from 'yup';

import { EmploymentCondition } from 'store/affiliationSubmission/affiliationSubmission.types';

const SalaryDataFormValidation = (
  employmentConditions?: EmploymentCondition[],
  startDate?: Date,
) => {
  const nullValidation = Yup.mixed().nullable();

  const wageValidation = Yup.number()
    .min(0.01, params => ({ id: 'general.errors.number.min', values: { min: params.min } }))
    .required('general.errors.required');

  const percentageValidation = Yup.number()
    .min(0.0, params => ({ id: 'general.errors.number.min', values: { min: params.min } }))
    .max(100, params => ({ id: 'general.errors.number.max', values: { min: params.max } }))
    .required('general.errors.required');

  const conditionValidation =
    (employmentConditions || []).length > 0
      ? Yup.object()
          .shape({})
          .required('general.errors.required')
      : nullValidation;

  const startDateValidation = Yup.date()
    .required('general.errors.required')
    .typeError('general.errors.date.invalid');

  if (!!startDate)
    startDateValidation.min(startDate, params => ({
      id: 'general.errors.date.min',
      values: {
        ...params,
        date: dayjs(new Date(params.min)).format('DD/MM/YYYY'),
      },
    }));

  return Yup.object().shape({
    grossSalary: wageValidation,
    employmentPercentage: percentageValidation,
    employmentCondition: conditionValidation,
    startDate: startDateValidation,
  });
};

export { SalaryDataFormValidation };
