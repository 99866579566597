import _isEqual from 'lodash/isEqual';
import { call, cancel, fork, put, select, spawn, take, takeLatest } from 'redux-saga/effects';

import { ROUTES } from 'constants/store';
import { analyticsService } from 'services';
import { getNavigationIsBlocked } from 'store/blockNavigation/blockNavigation.selectors';
import { clientResetSelectedIdsStart } from 'store/client/client.actions';
import { bootInvoiceDetail } from 'store/invoice/invoice.saga';
import { bootInvoices, filterInvoices } from 'store/invoices/invoices.saga';
import { bootPartner } from 'store/partner/partner.saga';

import { diffObject } from 'utils/helpers';

import { bootClaimSubmission } from '../claimSubmission/claimSubmission.saga';
import { bootDashboard } from '../dashboard/dashboard.saga';
import { bootDataShare, filerDataShare } from '../dataShare/dataShare.saga';
import { bootRiskDetail } from '../risk/risk.saga';
import { systemReady } from '../system/system.actions';
import { checkTutorial } from '../tutorial/tutorial.saga';
import { getUserAbilities } from '../user/user.selectors';
import {
  getPath,
  getPrevLocationQuery,
  getPrevPath,
  getQuery,
  getRouteType,
} from './routing.selectors';

// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

const routesSagaMap = {
  [ROUTES.DASHBOARD]: {
    boot: bootDashboard,
  },
  [ROUTES.CLAIM_DETAIL]: {
    boot: undefined,
  },
  [ROUTES.CLAIM_CREATE]: {
    boot: bootClaimSubmission,
  },
  [ROUTES.POLICIES]: {
    boot: undefined,
  },
  [ROUTES.POLICY_DETAIL]: {
    boot: undefined,
  },
  [ROUTES.BULLETINBOARD]: {
    boot: undefined,
  },
  [ROUTES.BULLETINBOARD_DETAIL]: {
    boot: undefined,
  },
  [ROUTES.RISK_DETAIL]: {
    boot: bootRiskDetail,
  },
  [ROUTES.DATASHARE]: {
    boot: bootDataShare,
    queryChange: filerDataShare,
  },
  [ROUTES.PARTNERS_DETAIL]: {
    boot: bootPartner,
  },
  [ROUTES.INVOICES]: {
    boot: bootInvoices,
    queryChange: filterInvoices,
  },
  [ROUTES.INVOICE_DETAIL]: {
    boot: bootInvoiceDetail,
  },
  [ROUTES.HC_AFFILIATIONS_CREATE]: {
    boot: undefined,
  },
  [ROUTES.VEHICLE_CERTIFICATES]: {
    boot: undefined,
  },
  [ROUTES.CLAIM_REPORTS_CREATE]: {
    boot: undefined,
  },
};

function* initialRoutingFlow(userAbilities) {
  const initialRoute = yield select(getRouteType);

  if (routesSagaMap[initialRoute]?.boot && userAbilities.can('visit', initialRoute)) {
    yield spawn(routesSagaMap[initialRoute].boot);
  }
  analyticsService.events.trackTrackPageView(initialRoute);
  yield fork(checkTutorial, initialRoute);
}

function* routingFlow({ type }) {
  const userAbilitiesNext = yield select(getUserAbilities);
  let currentNavigateTask;
  let currentQueryTask;

  function* cancelCurrentNavigateTask() {
    if (currentNavigateTask) {
      yield cancel(currentNavigateTask);
      currentNavigateTask = null;
    }
  }
  function* spawnNavigateTask(route) {
    if (routesSagaMap[route]?.boot && userAbilitiesNext.can('visit', route)) {
      currentNavigateTask = yield spawn(routesSagaMap[route].boot);
    }
  }
  function* cancelCurrentQueryTask() {
    if (currentQueryTask) {
      yield cancel(currentQueryTask);
      currentQueryTask = null;
    }
  }
  function* spawnQueryTask(route, paramsChanged) {
    if (routesSagaMap[route].queryChange && userAbilitiesNext.can('visit', route)) {
      currentQueryTask = yield spawn(routesSagaMap[route].queryChange, paramsChanged);
    }
  }
  const currentPath = yield select(getPath);
  const prevPath = yield select(getPrevPath);
  const isSameRoute = prevPath === currentPath;
  const queryParams = yield select(getQuery);
  const prevQueryParams = yield select(getPrevLocationQuery);

  const isNavBlocked = yield select(getNavigationIsBlocked);
  if (isNavBlocked) {
    return;
  }
  if (!isSameRoute) {
    yield call(cancelCurrentQueryTask);
    yield call(cancelCurrentNavigateTask);
    yield put(clientResetSelectedIdsStart());
    yield call(spawnNavigateTask, type);
    // do other tasks if not the same route
    analyticsService.events.trackTrackPageView(type);
    yield fork(checkTutorial, type);
  }

  const queryChange = !_isEqual(queryParams, prevQueryParams) && isSameRoute;
  const paramsChanged = diffObject(queryParams, prevQueryParams);
  if (queryChange) {
    yield call(cancelCurrentQueryTask);
    yield call(spawnQueryTask, type, paramsChanged);
  }
}

function* routingSaga() {
  yield take(systemReady);

  /*
    we need to do logic for initial routing here because
    the action has already been dispatched before system ready so there can is no take(ROUTE) anymore in the while loop...
    BEWARE: the routing flow with the while true will still be called but skipped because there is no type so it just continues
  */

  const userAbilities = yield select(getUserAbilities);

  yield call(initialRoutingFlow, userAbilities);
  yield takeLatest(Object.keys(routesSagaMap), routingFlow);
}

export default routingSaga;
