import apiManager from './axios/apiManager';

export const uploadFile = (path, file, onUploadProgress, params = {}) => {
  const data = new FormData();
  data.append('files', file, file.name);
  return apiManager
    .post(path, data, {
      params,
      onUploadProgress,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 300000,
    })
    .then(response => response.data);
};

export default null;
