import React from 'react';

import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { LinkButton } from 'components/@common/Link';
import { H3 } from 'components/@typography/Titles';

interface SummaryTitleProps {
  title: string;
  suffix?: React.ReactNode;
  onEditClick?: (e: React.MouseEvent) => void;
}

const SummaryTitle: React.FC<SummaryTitleProps> = ({ title, onEditClick, suffix = '' }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    <H3 color="brand01.400" marginBottom={16}>
      <FormattedMessage id={title} />
      {suffix}
    </H3>
    {onEditClick && (
      <LinkButton data-test-id={`${title}.edit`} onClick={onEditClick}>
        <FormattedMessage id="general.stepper.edit" />
      </LinkButton>
    )}
  </Box>
);

export default SummaryTitle;
