import React, { useState } from 'react';

import { Box, ClickAwayListener, Link, Tooltip, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ExpertData } from 'services/Api/types/experts.types';

import Avatar from 'components/Avatar';

import ExpertContactInfo from '../ExpertContactInfo';
import { AvatarWrap } from '../Experts.styled';
import { DetailContentWrap } from './ExpertsMobile.styled';

interface Props {
  isLoading?: boolean;
  data: ExpertData[];
  onExpertInfoShow?: (isMobile: boolean, expert?: ExpertData) => void;
  onExpertInfoClick?: (infoType: string, id: string) => void;
}

const ExpertsMobile: React.FC<Props> = ({
  data,
  onExpertInfoShow = () => {},
  onExpertInfoClick = () => {},
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const onTooltipOpened = () => () => {
    onExpertInfoShow(false);
  };

  const toggleTooltip = () => {
    setTooltipOpen(prevState => !prevState);
  };

  const closeTooltip = () => {
    setTooltipOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={closeTooltip}>
      <div>
        <Tooltip
          interactive
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          disableHoverListener
          disableFocusListener
          onOpen={onTooltipOpened()}
          open={tooltipOpen}
          placement="bottom-end"
          title={
            <>
              <Typography variant="h4" color="primary">
                <FormattedMessage id="claims.experts.my_experts" />
              </Typography>
              {data.map(expert => (
                <DetailContentWrap key={expert.id}>
                  <AvatarWrap>
                    <Avatar
                      title={[expert.attributes.firstName, expert.attributes.lastName]
                        .filter(Boolean)
                        .join(' ')}
                      subTitle={expert.attributes.functionName}
                      imgSrc={expert.attributes.base64Image}
                    />
                  </AvatarWrap>
                  <ExpertContactInfo
                    hasEmptyLabel={false}
                    onExpertInfoClick={onExpertInfoClick}
                    expert={expert}
                  />
                </DetailContentWrap>
              ))}
            </>
          }
        >
          <Link onClick={toggleTooltip} component="button" variant="body1">
            <Box component="span" overflow="hidden" textOverflow="ellipsis">
              <FormattedMessage id="claims.experts.my_experts" />
              &nbsp;{`(${(data && data.length) || 0})`}
            </Box>
          </Link>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default ExpertsMobile;
