import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import {
  PublicationData,
  PublicationDownloadFailPayload,
  PublicationDownloadPayload,
  PublicationDownloadSuccessPayload,
  PublicationsFetchStartPayload,
  PublicationsFetchSuccessPayload,
  PublicationsResponse,
} from './publications.types';

export const publicationsAdapter = createEntityAdapter<PublicationData>();

export type PublicationsState = ReturnType<typeof publicationsAdapter.getInitialState> & {
  error: StoreDefaultState['error'];
  meta: PublicationsResponse['meta'];
  result: string[];
  downloading: string[];
  status?: 'loading' | 'filtering' | 'downloading';
};

const INITIAL_STATE: PublicationsState = publicationsAdapter.getInitialState({
  error: undefined,
  status: undefined,
  result: [],
  downloading: [],
  meta: {
    count: 0,
    periods: [],
  },
});

const publicationsSlice = createSlice({
  name: 'publications',
  initialState: INITIAL_STATE,
  reducers: {
    publicationsFetchStart: (state, { payload }: PayloadAction<PublicationsFetchStartPayload>) => {
      state.status = payload.isFiltering ? 'filtering' : 'loading';
      state.meta.periods = payload.isFiltering ? state.meta.periods : [];
      state.error = undefined;
    },
    publicationsFetchSuccess: (
      state,
      { payload }: PayloadAction<PublicationsFetchSuccessPayload>,
    ) => {
      publicationsAdapter.upsertMany(state, payload.data);
      state.result = payload.result;
      state.meta.count = payload?.meta?.count;
      if (state.status === 'loading') {
        state.meta.periods = payload?.meta?.periods;
      }
      state.status = undefined;
    },
    publicationsFetchFail: (
      state,
      { payload }: PayloadAction<{ error: PublicationsState['error'] }>,
    ) => {
      state.error = payload.error;
      state.status = undefined;
    },
    publicationDownloadFetchStart: (
      state,
      { payload }: PayloadAction<PublicationDownloadPayload>,
    ) => {
      payload.id && state.downloading.push(payload.id);
    },
    publicationDownloadFetchSuccess: (
      state,
      { payload }: PayloadAction<PublicationDownloadSuccessPayload>,
    ) => {
      state.downloading = state.downloading.filter(id => id !== payload.id);
    },
    publicationDownloadFetchFail: (
      state,
      { payload }: PayloadAction<PublicationDownloadFailPayload>,
    ) => {
      state.downloading = state.downloading.filter(id => id !== payload.id);
    },
  },
});

export const {
  publicationsFetchStart,
  publicationsFetchSuccess,
  publicationsFetchFail,
  publicationDownloadFetchStart,
  publicationDownloadFetchSuccess,
  publicationDownloadFetchFail,
} = publicationsSlice.actions;

export default publicationsSlice.reducer;
