import humps from 'humps';

import {
  AffiliationPoliciesResponse,
  AffiliationTerminationReasonsResponse,
  InsurantAffiliation,
  InsurantAffiliationResponse,
  InsurantCreateStartPayload,
  InsurantDraftRequestBody,
  InsurantDraftResponse,
} from 'store/affiliationSubmission/affiliationSubmission.types';

import { ApiInstanceType } from '.';

class AffiliationApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  create(endpoint: string, body: InsurantCreateStartPayload['data']) {
    return this.api
      .post<InsurantDraftResponse>(endpoint, { data: body })
      .then(resp => resp.data);
  }

  update(endpoint: string, body: InsurantDraftRequestBody) {
    // decamilize keys manualy here because humps by default doesnt snake case numbers, this is for the address fields 'extra_1, extra_2, extra_3
    return this.api
      .put<InsurantDraftResponse>(endpoint, humps.decamelizeKeys(body, { split: /(?=[A-Z0-9])/ }))
      .then(resp => resp.data);
  }

  fetchAffiliationPolicies(endpoint: string, insurantId: string, clientId: string) {
    return this.api
      .get<AffiliationPoliciesResponse>(endpoint, { params: { insurantId, clientId } })
      .then(response => response.data);
  }

  fetchAffiliationTerminationReasons(endpoint: string) {
    return this.api
      .get<AffiliationTerminationReasonsResponse>(endpoint)
      .then(response => response.data);
  }

  fetchInsurantAffiliations(endpoint: string) {
    return this.api.get<InsurantAffiliationResponse>(endpoint).then(response => response.data);
  }

  createInsurantAffiliation(endpoint: string, body: InsurantAffiliation) {
    return this.api
      .post<InsurantAffiliationResponse>(endpoint, { data: body })
      .then(response => response.data);
  }
  updateAffiliation(endpoint: string, body: InsurantAffiliation) {
    return this.api
      .put<InsurantAffiliationResponse>(endpoint, { data: body })
      .then(resp => resp.data);
  }
}

export default AffiliationApi;
