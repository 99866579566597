import * as Yup from 'yup';

const AddressFormValidation = Yup.object().shape({
  country: Yup.object().required('general.errors.required'),
  zipcode: Yup.string().when('country.value', {
    is: val => val !== 'BE',
    then: Yup.string()
      .required('general.errors.required')
      .max(10, params => ({
        id: 'general.errors.max',
        values: { max: params.max },
      })),
  }),
  location: Yup.string().when('country.value', {
    is: val => val !== 'BE',
    then: Yup.string()
      .required('general.errors.required')
      .max(50, params => ({
        id: 'general.errors.max',
        values: { max: params.max },
      })),
  }),
  locationBE: Yup.object().when('country.value', {
    is: val => val === 'BE',
    then: Yup.object().shape({
      selectedItem: Yup.object().required('general.errors.required'),
    }),
  }),
  street: Yup.object().shape({
    inputValue: Yup.string()
      .required('general.errors.required')
      .max(50, params => ({ id: 'general.errors.max', values: { max: params.max } })),
  }),
  number: Yup.string()
    .required('general.errors.required')
    .max(10, params => ({
      id: 'general.errors.max',
      values: { max: params.max },
    })),
  numberExtension: Yup.string().max(10, params => ({
    id: 'general.errors.max',
    values: { max: params.max },
  })),
  extra1: Yup.string().max(50, params => ({
    id: 'general.errors.max',
    values: { max: params.max },
  })),
  extra2: Yup.string().max(50, params => ({
    id: 'general.errors.max',
    values: { max: params.max },
  })),
  extra3: Yup.string().max(50, params => ({
    id: 'general.errors.max',
    values: { max: params.max },
  })),
});

export { AddressFormValidation };
