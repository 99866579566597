import { createAction } from 'redux-act';

export type BlockNavigationStartPayload = {
  message: string;
  callback?: (canLeave: boolean) => void;
  leaveConfirmedCallback?: () => void;
  type?: 'alert' | 'confirm' | 'default';
};
export type BlockNavigationGoPayload = {
  canLeave: boolean;
};

export const blockNavigationStart = createAction<BlockNavigationStartPayload>(
  'BLOCK_NAVIGATION_START',
);
export const blockNavigationGo = createAction<BlockNavigationGoPayload>('BLOCK_NAVIGATION_GO');
export const blockNavigationStay = createAction('BLOCK_NAVIGATION_STAY');
