import styled from 'styled-components';

interface FormProps {
  spacing?: number;
}

export const Form = styled.div<FormProps>`
  padding: ${({ theme, spacing = 0 }) => theme.spacing(1) * (spacing / 1.5)}px;
`;

interface GroupProps {
  alignItems?: string;
}

export const Group = styled.div<GroupProps>`
  display: block;
  flex-wrap: wrap;
  align-items: ${({ alignItems }) => alignItems || 'flex-end'};
  margin-left: -${({ theme }) => theme.spacing(1) * 4}px;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
  }
`;

interface InputWrapProps {
  width?: string;
  marginBottom?: number;
}

export const InputWrap = styled.div<InputWrapProps>`
  min-width: 100%;
  max-width: 100%;
  margin-bottom: ${({ theme, marginBottom }) => marginBottom || `${theme.spacing(1) * 8}px`};
  ${({ theme }) => theme.breakpoints.up('md')} {
    min-width: ${({ width }) => width};
    max-width: ${({ width }) => width};
  }
  flex: 1;
  padding-left: ${({ theme }) => theme.spacing(1) * 4}px;
`;

interface FieldArrayWrapProps {
  isAction?: boolean;
}

export const FieldArrayWrap = styled.div<FieldArrayWrapProps>`
  position: relative;
  background: ${({ theme }) => theme.palette.neutral01[50]};
  padding: ${({ theme }) => `${theme.spacing(1) * 8}px ${theme.spacing(1) * 6}px`};
  margin-bottom: ${({ theme }) => theme.spacing(1) * 2}px;
  cursor: ${({ isAction }) => (isAction ? 'pointer' : 'default')};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  border: 1px solid ${({ theme }) => theme.palette.neutral01[200]};
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

export const ErrorLabel = styled.div`
  font-weight: 400;
  line-height: 1.57;
  letter-spacing: 0.2px;
  font-size: 14px;
  margin-top: 2px;
  color: ${({ theme }) => theme.palette.accent02[400]};
`;
