import React, { createRef, PureComponent } from 'react';

import { BACKSPACE, ENTER, SPACE, TAB } from 'utils/keyCodes';

import { Input } from 'components/@inputs';

import { AdornmentWrap } from './TagInput.styled';
import TagInputChip from './TagInputChip';

const addChipKeys = [ENTER, SPACE, TAB];

class TagInput extends PureComponent {
  static defaultProps = {
    onChange: () => {
      // default prop
    },
    validation: () => true,
  };

  inputRef = createRef();

  state = {
    inputValue: '',
    currentChips: this.props.initialValue || [],
  };

  deleteSpecificChip = item => {
    const { currentChips } = this.state;
    const { onChange } = this.props;
    this.setState(
      {
        currentChips: currentChips.filter(chip => chip !== item),
      },
      () => {
        if (this.inputRef) {
          this.inputRef.current.focus();
        }
        onChange(item, 'REMOVE', this.state.currentChips);
      },
    );
  };

  deleteLastChip = () => {
    const { currentChips } = this.state;
    const { onChange } = this.props;
    const lastItem = currentChips[currentChips.length - 1];
    this.setState(
      {
        currentChips: currentChips.slice(0, currentChips.length - 1),
      },
      () => {
        onChange(lastItem, 'REMOVE', this.state.currentChips);
      },
    );
  };

  addChip = () => {
    const { currentChips, inputValue } = this.state;
    const { maxChips, onChange, validation } = this.props;
    const value = inputValue.trim();
    const isNotAlreadyAdded = currentChips.indexOf(value) === -1;
    const doesNotExceedMax = maxChips ? currentChips.length < maxChips : true;
    const hasValueAndPassesValidation = validation(value) && value;

    if (hasValueAndPassesValidation && isNotAlreadyAdded && doesNotExceedMax) {
      this.setState(
        {
          inputValue: '',
          currentChips: [...currentChips, value],
        },
        () => {
          onChange(value, 'ADD', this.state.currentChips);
        },
      );
      return true;
    }
    return false;
  };

  handleKeyDown = e => {
    const { inputValue } = this.state;

    if (addChipKeys.indexOf(e.keyCode) > -1) {
      const hasAddedChip = this.addChip();
      if (hasAddedChip && e.keyCode === TAB) {
        e.preventDefault();
      }
    }
    if (!inputValue && e.keyCode === BACKSPACE) {
      e.preventDefault();
      this.deleteLastChip();
    }
  };

  handleInputChange = e => {
    const { currentChips } = this.state;
    const { maxChips } = this.props;
    if (maxChips ? currentChips.length < maxChips : true) {
      this.setState({
        inputValue: e.target.value,
      });
    }
  };

  handleBlur = () => {
    const hasAddedChip = this.addChip();
    if (!hasAddedChip) {
      this.setState({
        inputValue: '',
      });
    }
  };

  render() {
    const { inputValue, currentChips } = this.state;
    const { onChange, placeholder, maxChips, initialValue, validation, ...rest } = this.props;
    return (
      <Input
        {...rest} // we want the logic to keep working so props can't override components logic
        shouldFitContainer
        onBlur={this.handleBlur}
        onChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        value={inputValue}
        autoHeight={currentChips.length > 0}
        customRenderStartAdornment
        placeholder={currentChips.length > 0 ? '' : placeholder}
        inputProps={{
          autoComplete: 'off',
        }}
        padding="8px 12px"
        startAdornment={
          <AdornmentWrap>
            {currentChips.length > 0 &&
              currentChips.map(item => (
                <TagInputChip key={item} item={item} onRemove={this.deleteSpecificChip} />
              ))}
          </AdornmentWrap>
        }
        inputRef={this.inputRef}
      />
    );
  }
}

export default TagInput;
