import 'react-day-picker/lib/style.css';
import React, { Fragment, useState } from 'react';

import dayjs from 'dayjs';
import DayPicker, { DayModifiers, Modifier, NavbarElementProps } from 'react-day-picker';
import { useIntl } from 'react-intl';

import { useMedia } from 'components/Media';

import { DatePickerCaption, DatePickerNav } from './components';
import {
  DatePickerDay,
  DatePickerFooterWrap,
  DatePickerWrap,
  DayPickerStyled,
} from './DatePicker.styled';

export interface DatePickerSelectedDate {
  from: Date | null;
  to: Date | null;
}

export interface DatePickerProps {
  numberOfMonths?: number;
  footerContent?: React.ReactNode;
  locale: string;
  hasYearMonthDropdowns?: boolean;
  disableAfter?: Date;
  disableBefore?: Date;
  selectedDate: DatePickerSelectedDate;
  currentMonthViewed?: Date | null;
  onYearMonthChange?: (date: Date) => void;
  isRangeSelect?: boolean;
  onDayClick?: (day: DatePickerSelectedDate) => void;
  disableDays?: string[];
  disableDaysModifier?: Modifier[];
}

const DatePicker: React.FC<DatePickerProps> = ({
  footerContent,
  locale,
  currentMonthViewed,
  onYearMonthChange,
  isRangeSelect = true,
  selectedDate = { from: null, to: null },
  numberOfMonths = 1,
  hasYearMonthDropdowns = true,
  disableAfter: disableAfterProp,
  disableBefore: disableBeforeProp,
  onDayClick = () => {},
  disableDays = [],
  disableDaysModifier,
}) => {
  const intl = useIntl();
  const media = useMedia();

  const [enteredTo, setEnteredTo] = useState(selectedDate.to);

  const disableAfter =
    disableAfterProp ||
    dayjs()
      .add(5, 'year')
      .toDate();

  const disableBefore =
    disableBeforeProp ||
    dayjs()
      .subtract(30, 'year')
      .toDate();

  const getYearsSelect = () => {
    const fromYear = new Date(disableBefore).getFullYear();
    const toYear = new Date(disableAfter).getFullYear();

    const years = [];
    const fromMonth = new Date(fromYear, 11);
    const toMonth = new Date(toYear, 11);
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i);
    }
    return years.map(year => ({ label: `${year}`, value: year }));
  };

  const getMonthsSelect = () =>
    Array.from(Array(12).keys()).map(i => ({
      label: intl.formatDate(new Date(new Date().getFullYear(), i, 1), {
        month: 'long',
      }),
      value: i,
    }));

  const getWeekDays = (type: string) =>
    Array.from(Array(7).keys()).map(i =>
      intl.formatDate(new Date(1970, 1, i + 1), {
        weekday: type,
      }),
    );

  const years = getYearsSelect();
  const months = getMonthsSelect();

  const renderDay = (day: Date) => {
    const date = day.getDate();
    return (
      <DatePickerDay>
        <span>{date}</span>
      </DatePickerDay>
    );
  };

  const isSelectingFirstDay = (from: Date | null, to: Date | null, day: Date) => {
    const isBeforeFirstDay = from && DayPicker.DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayMouseEnter = (day: Date) => {
    const { from, to } = selectedDate;
    if (isRangeSelect && !media.isTouchDevice && !isSelectingFirstDay(from, to, day)) {
      setEnteredTo(day);
    }
  };

  const reset = () => {
    setEnteredTo(null);
    onDayClick({
      from: null,
      to: null,
    });
  };

  const handleDayClick = (day: Date, dayProps: DayModifiers) => {
    if (!dayProps.disabled) {
      const { from, to } = selectedDate;
      if (!!(from && to && day >= from && day <= to)) {
        reset();
        return;
      }
      if (isSelectingFirstDay(from, to, day) || !isRangeSelect) {
        setEnteredTo(null);
        onDayClick({
          from: day,
          to: isRangeSelect ? null : day,
        });
      } else {
        setEnteredTo(day);
        onDayClick({ from, to: day });
      }
    }
  };

  const getDisabledDays = () => [
    ...disableDays.map(day => dayjs(day).toDate()),
    {
      after: disableAfter,
    },
    {
      before: disableBefore,
    },
  ];

  const { from, to } = selectedDate;
  const modifiers = { start: from, end: enteredTo } as any;
  // typecasting here not that great but otherwise I would need to overwrite the whole declaration because they type from and to as required but in the package itself it is not required...
  const selectedDays = [from, { from, to: isRangeSelect ? enteredTo : to }] as Modifier[];

  return (
    <Fragment>
      <DatePickerWrap>
        <DayPickerStyled
          locale={locale}
          localeUtils={DayPicker.LocaleUtils}
          showOutsideDays
          weekdaysShort={getWeekDays('short')}
          weekdaysLong={getWeekDays('long')}
          firstDayOfWeek={1}
          month={currentMonthViewed ? currentMonthViewed : undefined}
          navbarElement={(navBarProps: NavbarElementProps) => (
            <DatePickerNav
              disableAfter={disableAfter}
              disableBefore={disableBefore}
              dateUtils={DayPicker.DateUtils}
              {...navBarProps}
            />
          )}
          captionElement={({ date, localeUtils }) => (
            <DatePickerCaption
              date={date}
              locale={locale}
              localeUtils={localeUtils}
              hasYearMonthDropdowns={hasYearMonthDropdowns}
              onChange={onYearMonthChange}
              years={years}
              months={months}
            />
          )}
          disabledDays={[...getDisabledDays(), ...(disableDaysModifier || [])]}
          renderDay={renderDay}
          modifiers={modifiers}
          selectedDays={selectedDays}
          onDayClick={handleDayClick}
          numberOfMonths={numberOfMonths}
          onDayMouseEnter={handleDayMouseEnter}
        />
      </DatePickerWrap>
      {footerContent && <DatePickerFooterWrap>{footerContent}</DatePickerFooterWrap>}
    </Fragment>
  );
};

export default DatePicker;
