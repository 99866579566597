import { sanitizeFilters } from 'hooks/useFilter/useFilter';
import _pick from 'lodash/pick';
import { call, put, SagaReturnType, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { QUERY_PARAMS } from 'constants/claimReports';
import { ROUTES } from 'constants/store';
import { analyticsService, apiService } from 'services';
import { addNotification } from 'store/notifications/notifications.actions';

import { downloadFileFromBinaryResponse } from 'utils/helpers';
import { getNormalizedJsonApi } from 'utils/jsonapi';

import { getClaimReportEndpoint } from './claimReports.selectors';
import {
  claimReportDownloadFetchFail,
  claimReportDownloadFetchStart,
  claimReportDownloadFetchSuccess,
  claimReportsFetchFail,
  claimReportsFetchStart,
  claimReportsFetchSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './claimReports.slice';
import { ClaimReportData, ClaimReportsResponse } from './claimReports.types';

function* claimReportsFetchFlow(action: ReturnType<typeof claimReportsFetchStart>) {
  const { filter } = action.payload;
  try {
    const path = yield select(getClaimReportEndpoint);
    const validFilters = _pick(sanitizeFilters(filter), Object.values(QUERY_PARAMS));
    const response: SagaReturnType<typeof apiService.claimReports.fetchClaimReports> = yield call(
      [apiService, apiService.claimReports.fetchClaimReports],
      path,
      validFilters,
    );
    const { entities, result } = getNormalizedJsonApi<
      ClaimReportsResponse,
      { document: ClaimReportData }
    >(response);

    if (Object.keys(filter).length) {
      analyticsService.events.trackClaimReportsFilters(
        JSON.stringify(filter),
        response?.data?.length > 0,
      );
    }

    yield put(
      claimReportsFetchSuccess({
        data: entities.document ?? {},
        result: result.document || [],
        meta: response?.meta,
        links: response?.links,
      }),
    );
  } catch (error) {
    yield put(claimReportsFetchFail({ error }));
  }
}
function* claimReportDownloadFetchFlow(action: ReturnType<typeof claimReportDownloadFetchStart>) {
  const { path, fileName, id } = action.payload;
  try {
    const response: SagaReturnType<typeof apiService.claimReports.downloadClaimReport> = yield call(
      [apiService, apiService.claimReports.downloadClaimReport],
      path,
    );

    analyticsService.events.trackDocumentsDownload(ROUTES.CLAIM_REPORTS, 'claimReport');

    downloadFileFromBinaryResponse(response, fileName);
    yield put(claimReportDownloadFetchSuccess({ id }));
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'documents.download.error',
      }),
    );
    yield put(claimReportDownloadFetchFail({ error, id }));
  }
}
// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

function* claimReportSaga() {
  yield takeLatest(claimReportsFetchStart, claimReportsFetchFlow);
  yield takeEvery(claimReportDownloadFetchStart, claimReportDownloadFetchFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default claimReportSaga;
