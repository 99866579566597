import { ClaimReportsResponse } from 'store/claimReports/claimReports.types';

import { ApiInstanceType } from './';
import { ClaimReportCreateFormBody, ClaimReportTypesResponse } from './types/claim.types';
class ClaimReportsApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchClaimReports = (path: string, filters: { [index: string]: string }) =>
    this.api
      .get<ClaimReportsResponse>(path, {
        params: filters,
      })
      .then(response => response.data);

  downloadClaimReport = (path: string) =>
    this.api
      .get<Blob>(path, {
        cache: {
          maxAge: 0,
          ignoreCache: true,
        },
        responseType: 'blob',
      })
      .then(response => response);

  fetchClaimReportTypes = (path: string) =>
    this.api.get<ClaimReportTypesResponse>(path).then(response => response.data);

  createClaimReportRequest = (path: string, formBody: ClaimReportCreateFormBody) =>
    this.api.post(path, formBody).then(response => response);
}

export default ClaimReportsApi;
