import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';
import _pick from 'lodash/pick';
import { createSelector } from 'reselect';

import { mapDataToTree } from 'utils/helpers';

export const getAllClients = state => _get(state, 'client.data');
export const getClientById = (state, id) => _get(state, `client.data.${id}`);

export const sortAndGroup = clients => {
  const sorted = _orderBy(
    clients || [],
    [client => (client?.attributes?.name || '').toLowerCase()],
    ['asc'],
  );
  const { hc, normal } = _groupBy(sorted, client => {
    return client?.attributes?.isHealthcare ? 'hc' : 'normal';
  });

  const { parents, children } = _groupBy([].concat(normal, hc), client => {
    return client?.relationships?.parent?.data?.id ? 'children' : 'parents';
  });

  return [].concat(parents, children).filter(Boolean);
};

// TOP MOTHERS
export const getGroupId = state => _get(state, 'client.group.currentSelected');
export const getGroup = state => getClientById(state, getGroupId(state));
export const getGroupIsLoading = state => _get(state, 'client.group.isLoading');
export const getGroupIsFiltering = state => _get(state, 'client.group.isFiltering');
export const getGroupError = state => _get(state, 'client.group.error');
export const getGroupIds = state => _get(state, 'client.group.data');
export const getFilteredGroupIds = state => _get(state, 'client.group.filteredResults');
export const getGroupData = createSelector(
  [getFilteredGroupIds, state => state],
  (groupData, state) => sortAndGroup((groupData || []).map(id => getClientById(state, id))),
);

// PARTNERS

export const getPartnerIds = state => _get(state, 'client.partner.data');
export const getPartnersIsLoading = state => _get(state, 'client.partner.isLoading');
export const getPartnersIsFiltering = state => _get(state, 'client.partner.isFiltering');
export const getCurrentSelectedPartnerIds = state => _get(state, 'client.partner.currentSelected');
export const getFilteredPartnerIds = state => _get(state, 'client.partner.filteredResults');
export const getPartners = createSelector(
  [getFilteredPartnerIds, state => state],
  (groupPartners, state) => sortAndGroup((groupPartners || []).map(id => getClientById(state, id))),
);

// REPORTING CATEGORIES
export const getClientReportingCategoriesError = state =>
  _get(state, 'client.reportingCategories.error');
export const getClientReportingCategoriesIsLoading = state =>
  _get(state, 'client.reportingCategories.isLoading');
export const getClientReportingCategoryById = (state, id) =>
  _get(state, `client.reportingCategories.data.${id}`);
export const getClientReportingCategoriesResults = state =>
  _get(state, `client.reportingCategories.results`);

export const getClientReportingCategoriesData = state => state.client.reportingCategories?.data;

export const getClientReportingCategoriesDataByResult = createSelector(
  [getClientReportingCategoriesResults, getClientReportingCategoriesData],
  (results, data) => _pick(data, results),
);

export const getClientReportingCategories = createSelector(
  [getClientReportingCategoriesResults, state => state],
  (results, state) => (results || []).map(id => getClientReportingCategoryById(state, id)),
);

// ENDPOINT
export const getClientEndpoint = state =>
  _get(state, 'endpoints.private.data.relationships.clients.links.self');

export const getValidClientIdsFromArray = (ids, clientIds) =>
  clientIds &&
  clientIds.reduce((acc, clientId) => {
    if (ids && ids.includes(clientId)) {
      acc.push(clientId);
    }
    return acc;
  }, []);

export const getValidGroupId = (ids, groupId) => (ids.includes(groupId) ? groupId : undefined);

export const getPartnersTree = createSelector(
  [getGroupId, getPartners],
  (groupId, partnersData) =>
    partnersData && mapDataToTree(_cloneDeep(partnersData), groupId, 2, true),
);

export const getClientIsLoading = state => _get(state, 'client.isClientLoading');

export const getClientError = state => _get(state, 'client.clientError');
