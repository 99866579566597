import React from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import {
  AffiliationPolicy,
  InsurantAffiliation,
  InsurantAffiliationSalary,
} from 'store/affiliationSubmission/affiliationSubmission.types';

import { getEmploymentCondition } from 'utils/employmentCondition';

import FormatNumber from 'components/FormatNumber';
import { SummaryField } from 'components/FormSummary';

import { SalaryContainer, SalaryHeader, SalaryHeaderLinks } from './AffiliationCardSalary.styled';

interface Props {
  affiliationPolicy: AffiliationPolicy;
  affiliation?: InsurantAffiliation;
  salary: InsurantAffiliationSalary;
  onSalaryEdit?: (
    affiliation: InsurantAffiliation,
    affiliationPolicy: AffiliationPolicy,
    salary: InsurantAffiliationSalary,
  ) => void;
}

const AffiliationCardSalary: React.FC<Props> = ({
  affiliationPolicy,
  affiliation,
  salary,
  onSalaryEdit = () => {},
}) => {
  const { edit: canEdit } = affiliation?.links || {};

  const canEditAffiliationSalary =
    !!canEdit && !!salary?.canEdit && affiliation?.attributes?.status !== 'REFUSED';

  return (
    <SalaryContainer>
      <SalaryHeader>
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <Box flex="1 auto">
            <Box clone display="flex" alignItems="center">
              <Typography variant="h6" color="textPrimary" component="h6">
                {salary?.isCurrent ? (
                  <FormattedMessage id="affiliation.card.current_employment" />
                ) : (
                  <FormattedMessage id="affiliation.card.future_employment" />
                )}
              </Typography>
            </Box>
          </Box>

          {affiliation && (
            <SalaryHeaderLinks>
              {canEditAffiliationSalary && (
                <Link
                  data-test-id="affiliation.card.actions.edit"
                  component="button"
                  onClick={() => onSalaryEdit(affiliation, affiliationPolicy, salary)}
                >
                  <FormattedMessage id="general.button.edit" />
                </Link>
              )}
            </SalaryHeaderLinks>
          )}
        </Box>
      </SalaryHeader>
      <SummaryField
        nowrap
        label="affiliations.add.step.additional_info.employment_percentage_label"
        value={
          salary?.employmentPercentage != null && (
            <>
              <FormatNumber isNumericString number={`${salary.employmentPercentage}`} />%
            </>
          )
        }
      />
      <SummaryField
        label="claims.tpa_gi_employment_condition.label"
        value={
          getEmploymentCondition(
            affiliationPolicy.attributes?.employmentConditions,
            salary?.employmentConditionKey,
          )?.title
        }
      />
      <SummaryField
        label={
          salary?.wageType === 'MONTHLY'
            ? 'general.gross_wage.text'
            : 'general.gross_wage_hourly.text'
        }
        value={
          salary?.grossSalary && (
            <>
              €&nbsp;
              <FormatNumber
                isNumericString
                number={salary?.grossSalary}
                decimalScale={salary?.wageType === 'MONTHLY' ? 2 : 4}
              />
            </>
          )
        }
      />
      <SummaryField
        nowrap
        label="affiliation.card.start_date_salary"
        type="date"
        value={salary?.startDate}
      />
    </SalaryContainer>
  );
};

export default AffiliationCardSalary;
