import React from 'react';

import { FormattedMessage } from 'react-intl';

import { STATUSES } from 'constants/policies';

import Tag from 'components/@common/Tag';

const PolicyStatusBadge = ({ label, ...rest }) => (
  <Tag {...rest} upperCase type={(STATUSES[label] && STATUSES[label].badgeColor) || 'warning'}>
    <FormattedMessage
      id={(STATUSES[label] && STATUSES[label].intlKey) || 'claims.filter.dropdowns.unknown_status'}
    />
  </Tag>
);

export default PolicyStatusBadge;
