import { Store } from 'types/store/store.types';

// INCAPACITY_CAUSES
export const getPolicyIncapacityCauses = (state: Store) => state.policy.incapacityCauses.data;

export const getPolicyIncapacityCausesIsLoading = (state: Store) =>
  state.policy.incapacityCauses.isLoading;

export const getPolicyIncapacityCausesError = (state: Store) => state.policy.incapacityCauses.error;

// ENDPOINTS

export const getPoliciesEndpoint = (state: Store) =>
  state.endpoints.private?.data?.relationships?.policies?.links?.self;
