import React, { useRef } from 'react';

import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { Description, SidebarWrap, Title, TitleWrap, TopWrap } from './SidebarLeft.styled';

export interface SidebarLeftProps {
  topContent?: React.ReactNode;
  title?: React.ReactNode;
  titleDescription?: React.ReactNode;
  content?: React.ReactNode;
}

const SidebarLeft: React.FC<SidebarLeftProps> = ({
  topContent,
  title,
  titleDescription,
  content,
}) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  // we need the offset because it is fixed and needs to scroll internally, the banner component can vary in height...
  const offsetTop = sidebarRef.current?.getBoundingClientRect().top || 104;

  return (
    <div ref={sidebarRef}>
      <SidebarWrap offset={offsetTop + 32}>
        {topContent && <TopWrap> {topContent} </TopWrap>}
        {(title || titleDescription) && (
          <TitleWrap>
            {title && (
              <Title>{typeof title === 'string' ? <FormattedMessage id={title} /> : title}</Title>
            )}
            {titleDescription && <Description>{titleDescription}</Description>}
          </TitleWrap>
        )}
        {content}
      </SidebarWrap>
      <Box width={332} mr={4} />
    </div>
  );
};

export default SidebarLeft;
