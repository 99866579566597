import React, { PureComponent } from 'react';

import { moduleNames } from 'pages/routes';

import Card from 'components/@layout/Card';
import { Content, ContentWrap } from 'components/@layout/Content';
import SidebarLeft from 'components/@layout/SidebarLeft';
import { MainTitle } from 'components/@typography/Titles';
import BackLink from 'components/BackLink';

class Unauthorized extends PureComponent {
  render() {
    return (
      <ContentWrap>
        <SidebarLeft topContent={<BackLink to="/" module={moduleNames.dashboard} />} />
        <Content>
          <MainTitle title="Unauthorized" />
          <Card shouldWrap isContainer>
            <Card grow={1} direction="column">
              <span>You do not have the rights to view this page</span>
            </Card>
          </Card>
        </Content>
      </ContentWrap>
    );
  }
}
export default Unauthorized;
