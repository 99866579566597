import { sanitizeFilters } from 'hooks/useFilter/useFilter';
import _pick from 'lodash/pick';
import qs from 'query-string';
import { history } from 'redux-first-router';
import { call, put, SagaReturnType, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { QUERY_PARAMS } from 'constants/files';
import { apiService } from 'services';
import { analyticsService } from 'services';
import { addNotification } from 'store/notifications/notifications.actions';
import { UserData } from 'store/user/user.types';
import { addUsersToState } from 'store/users/users.actions';

import { downloadFileFromBinaryResponse } from 'utils/helpers';
import { getNormalizedJsonApi } from 'utils/jsonapi';

import dialogSaga from '../dialog/dialog.saga';
import { getQuery, getRouteType } from '../routing/routing.selectors';
import { getFilesEndpoint } from './dataShare.selectors';
import {
  deleteSharedFileFail,
  deleteSharedFileRequest,
  deleteSharedFileStart,
  deleteSharedFileSuccess,
  downloadSharedFileFail,
  downloadSharedFileStart,
  downloadSharedFileSuccess,
  sharedFilesFetchFail,
  sharedFilesFetchStart,
  sharedFilesFetchSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './dataShare.slice';

function* sharedFilesFetchFlow(action: ReturnType<typeof sharedFilesFetchStart>) {
  try {
    const filter = (action.payload && action.payload.filters) || {};
    const path = yield select(getFilesEndpoint);
    const validFilters = _pick(sanitizeFilters(filter), Object.values(QUERY_PARAMS));
    const filters = {
      ...validFilters,
      [QUERY_PARAMS.ONLY_MY_FILES]: filter[QUERY_PARAMS.ONLY_MY_FILES] === 'false' ? 'false' : '',
    };
    const filesResponse: SagaReturnType<typeof apiService.dataShare.fetchSharedFiles> = yield call(
      [apiService, apiService.dataShare.fetchSharedFiles],
      path,
      filters,
    );
    const normalized = getNormalizedJsonApi<typeof filesResponse, { user: UserData }>(
      filesResponse,
    );

    yield put(addUsersToState({ users: normalized.entities.user }));
    yield put(sharedFilesFetchSuccess(filesResponse));
  } catch (error) {
    yield put(sharedFilesFetchFail({ error }));
  }
}

function* sharedFileDownloadFlow(action: ReturnType<typeof downloadSharedFileStart>) {
  try {
    const { path, fileName, docType } = action.payload;
    const location = yield select(getRouteType);
    const response: SagaReturnType<typeof apiService.dataShare.downloadSharedFile> = yield call(
      [apiService, apiService.dataShare.downloadSharedFile],
      path,
    );
    downloadFileFromBinaryResponse(response, fileName);
    analyticsService.events.trackDocumentsDownload(location, docType);
    yield put(downloadSharedFileSuccess());
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'documents.download.error',
      }),
    );
    yield put(downloadSharedFileFail({ error }));
  }
}

function* sharedFileDeleteFlow(action: ReturnType<typeof deleteSharedFileRequest>) {
  const { file, path, docType } = action.payload;
  const confirmed = yield call(dialogSaga, {
    dialogProps: {
      type: 'FILE_DELETE',
      contentProps: { file },
      headerIntlKey: 'documents.file_delete_dialog.header',
      buttonYesIntlKey: 'documents.file_delete_dialog.button',
    },
  });
  if (confirmed) {
    yield put(deleteSharedFileStart());
    try {
      const currentFilter: SagaReturnType<typeof getQuery> = yield select(getQuery);
      yield call([apiService, apiService.dataShare.deleteSharedFile], path);

      // if we are on page 1 just refetch otherwise reroute and the router will handle the refetch
      if (currentFilter[QUERY_PARAMS.PAGE] && currentFilter[QUERY_PARAMS.PAGE] !== '1') {
        history().replace({
          search: `?${qs.stringify({ ...currentFilter, [QUERY_PARAMS.PAGE]: undefined })}`,
        });
      } else {
        yield put(sharedFilesFetchStart({ filters: currentFilter }));
      }

      yield take([sharedFilesFetchSuccess, sharedFilesFetchSuccess]);
      analyticsService.events.trackDocumentsDelete(docType);
      yield put(deleteSharedFileSuccess());
    } catch (error) {
      yield put(
        addNotification({
          variant: 'error',
          message: 'documents.delete.error',
        }),
      );
      yield put(deleteSharedFileFail({ error }));
    }
  }
}

// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

export function* bootDataShare() {
  const currentFilter = yield select(getQuery);

  yield put(sharedFilesFetchStart({ filters: currentFilter }));
}

export function* filerDataShare() {
  const currentFilter = yield select(getQuery);
  const {
    [QUERY_PARAMS.MAX_PER_PAGE]: maxPerPage,
    [QUERY_PARAMS.PAGE]: page,
    ...filter
  } = currentFilter;

  currentFilter[QUERY_PARAMS.ONLY_MY_FILES] =
    filter[QUERY_PARAMS.ONLY_MY_FILES] === 'false' ? 'false' : undefined;
  if (Object.keys(filter).length) {
    analyticsService.events.trackDocumentsFilters(JSON.stringify(filter));
  }

  yield put(sharedFilesFetchStart({ filters: currentFilter }));
}

function* filesSaga() {
  yield takeLatest(sharedFilesFetchStart, sharedFilesFetchFlow);
  yield takeEvery(downloadSharedFileStart, sharedFileDownloadFlow);
  yield takeLatest(deleteSharedFileRequest, sharedFileDeleteFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default filesSaga;
