import React from 'react';

import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { analyticsService } from 'services';
import { getUser, getUserIsUpdating } from 'store/user/user.selectors';
import { userUpdateStart } from 'store/user/user.slice';
import { Store } from 'types/store/store.types';

import { hasFormErrors } from 'utils/helpers';

import CompleteProfileForm from './CompleteProfileForm';
import { ValidationSchema } from './CompleteProfileForm.validation';

export type FormValues = {
  firstName: string;
  lastName: string;
  function: string;
  phoneNumber: string;
  cookies: {
    personal: boolean;
    analytics?: boolean;
  };
  language?: {
    label: string;
    value: string;
  };
};

const CompleteProfileFormContainer: React.FC<ContainerProps> = props => {
  const intl = useIntl();
  const { user, updateUser } = props;
  const handleSubmit = (values: FormValues) => {
    const reqBody = {
      data: {
        ...user,
        attributes: {
          ...user?.attributes,
          ...values,
          language: values.language?.value,
        },
      },
    };
    updateUser(reqBody);
    if (!values.cookies.analytics) {
      analyticsService.disableAnalytics();
    } else {
      analyticsService.enableAnalytics();
    }
    analyticsService.events.trackOnboardingReviewPersonalInfo();
  };

  const initialValues = {
    firstName: user?.attributes?.firstName ?? '',
    lastName: user?.attributes?.lastName ?? '',
    function: user?.attributes?.['function'] ?? '',
    phoneNumber: user?.attributes?.phoneNumber ?? '',
    email: user?.attributes?.email,
    cookies: {
      personal: user?.attributes?.cookies?.personal || true,
      analytics: user?.attributes?.cookies?.analytics,
    },
    language: {
      value: user?.attributes?.language ?? '',
      label: intl.formatMessage({
        id: `profile.settings.language.${user?.attributes?.language}`,
      }),
    },
  };

  return (
    <Formik
      validateOnMount
      validationSchema={ValidationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ errors, handleSubmit }) => {
        const hasErrors = hasFormErrors(errors);
        return <CompleteProfileForm hasErrors={hasErrors} onSubmit={handleSubmit} {...props} />;
      }}
    </Formik>
  );
};

const mapStateToProps = (state: Store) => ({
  user: getUser(state),
  isUpdating: getUserIsUpdating(state),
});
const mapDispatchToProps = {
  updateUser: userUpdateStart as any,
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export type ContainerProps = MapStateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(CompleteProfileFormContainer);
