import { call, put, takeLatest } from 'redux-saga/effects';

import { blockNavigationGo, blockNavigationStart } from './blockNavigation.actions';

function* blockNavigationFlow(action: ReturnType<typeof blockNavigationStart>) {
  const { message, callback, leaveConfirmedCallback, type } = action.payload;
  const dialog = type === 'alert' ? window.alert : window.confirm;
  const canLeave = dialog(message);

  if (canLeave && leaveConfirmedCallback) {
    leaveConfirmedCallback();
  }
  if (callback) {
    yield call(callback, !!canLeave);
  }
  yield put(blockNavigationGo({ canLeave: !!canLeave }));
}
// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

function* blockNavigationSaga() {
  yield takeLatest(blockNavigationStart.getType(), blockNavigationFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default blockNavigationSaga;
