import React from 'react';

import Icon from 'components/Icon';
import { UIcoInfo } from 'components/Icons';
import Tooltip from 'components/Tooltip';

import { HintIconWrap } from './HintTooltip.styled';

interface Props {
  hint: React.ReactNode;
}

const HintTooltip: React.FC<Props> = ({ hint }) => (
  <Tooltip placement="right" title={<div>{hint}</div>}>
    <HintIconWrap>
      <Icon size={20} color="brand01.400">
        <UIcoInfo />
      </Icon>
    </HintIconWrap>
  </Tooltip>
);

export default HintTooltip;
