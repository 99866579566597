import { createBrowserHistory } from 'history';
import qs from 'qs';
import { connectRoutes } from 'redux-first-router';

import { STEP_IDS as claimSubmissionSteps } from 'constants/claimSubmission';
import { STEP_IDS as fileSubmissionSteps } from 'constants/fileSubmission';
import { ROUTES } from 'constants/store';
import { analyticsService } from 'services';
import { messages } from 'services/i18n/config';
import storeConfig from 'store';
import { getAffiliationSubmissionStatus } from 'store/affiliationSubmission/affiliationSubmission.selectors';
import { getClaimReportRequestStatus } from 'store/claimReports/claimReports.selectors';
import {
  getClaimSubmissionError,
  getCurrentStep as getCurrentStepClaimSubmission,
} from 'store/claimSubmission/claimSubmission.selectors';
import {
  getCurrentStep as getCurrentStepFileSubmission,
  getFileSubmissionError,
} from 'store/fileSubmission/fileSubmission.selectors';
import { getLocale } from 'store/intl/intl.selectors';

export const routesPathMap = {
  [ROUTES.AUTH]: '/auth/callback',
  [ROUTES.IMPERSONATE]: '/impersonate',
  [ROUTES.DASHBOARD]: '/',
  [ROUTES.CLAIMS]: '/claims',
  // to match only numbers so it doesn't conflict with /new
  [ROUTES.CLAIM_DETAIL]: '/claims/:id(\\d+)',
  [ROUTES.CLAIM_DOCUMENTS]: {
    path: '/claims/:id(\\d+)/documents',
  },
  [ROUTES.CLAIM_CREATE]: {
    path: '/claims/new',
    confirmLeave: state => {
      const currentStep = getCurrentStepClaimSubmission(state);
      const error = getClaimSubmissionError(state);
      const userLocale = getLocale(state);
      if (currentStep !== claimSubmissionSteps.STEP_SUBMIT && !error) {
        return {
          leaveConfirmedCallback: () => analyticsService.events.trackClaimsFlowAbandon(currentStep),
          message: messages[userLocale]['general.warning.leave_page'],
        };
      }
    },
  },
  [ROUTES.POLICIES]: '/policies',
  [ROUTES.POLICY_DETAIL]: '/policies/:id(\\d+)',
  [ROUTES.RISK_DETAIL]: '/policies/:id(\\d+)/risks/:riskId(\\d+)',
  [ROUTES.DATASHARE]: '/documents',
  [ROUTES.DATASHARE_CREATE]: {
    path: '/documents/new',
    confirmLeave: state => {
      const currentStep = getCurrentStepFileSubmission(state);
      const error = getFileSubmissionError(state);
      const userLocale = getLocale(state);
      if (currentStep !== fileSubmissionSteps.STEP_SUBMIT && !error) {
        return {
          leaveConfirmedCallback: analyticsService.events.trackDocumentsUploadCancel,
          message: messages[userLocale]['general.warning.leave_page'],
        };
      }
    },
  },
  [ROUTES.SETTINGS]: '/settings',
  [ROUTES.INVOICES]: '/invoices',
  [ROUTES.INVOICE_DETAIL]: '/invoices/:id(\\d+)',
  [ROUTES.PARTNERS]: '/clients',
  [ROUTES.PARTNERS_DETAIL]: '/clients/:id(\\d+)',
  [ROUTES.HC_AFFILIATIONS]: '/affiliations',
  [ROUTES.HC_AFFILIATIONS_CREATE]: {
    path: '/affiliations/new',
    confirmLeave: state => {
      const userLocale = getLocale(state);
      const status = getAffiliationSubmissionStatus(state);
      if (status !== 'completed') {
        return {
          message: messages[userLocale]['general.warning.leave_page'],
        };
      }
    },
  },
  [ROUTES.VEHICLE_CERTIFICATES]: '/vehicle-certificates',
  [ROUTES.HC_AFFILIATIONS_DETAIL]: '/affiliations/:id',
  [ROUTES.CLAIM_REPORTS]: '/claim-reports',
  [ROUTES.CLAIM_REPORTS_CREATE]: {
    path: '/claim-reports/new',
    confirmLeave: state => {
      const userLocale = getLocale(state);
      const status = getClaimReportRequestStatus(state);
      if (status !== 'completed') {
        return {
          message: messages[userLocale]['general.warning.leave_page'],
        };
      }
    },
  },
  [ROUTES.PUBLICATIONS]: '/publications',
  [ROUTES.BULLETINBOARD]: '/bulletinboard',
  [ROUTES.BULLETINBOARD_DETAIL]: '/bulletinboard/:id(\\d+)',
  // --plop_insert_route--
};

const options = {
  createHistory: typeof document !== 'undefined' ? createBrowserHistory : undefined,
  querySerializer: {
    parse: values => {
      return qs.parse(values, { decoder: c => c });
    },
    stringify: values => qs.stringify(values, { arrayFormat: 'indices' }),
  },
  displayConfirmLeave: ({ message, leaveConfirmedCallback }, callback) => {
    storeConfig.store.dispatch({
      type: 'BLOCK_NAVIGATION_START',
      payload: { message, callback, leaveConfirmedCallback },
    });
  },
};

export const { reducer, middleware, enhancer } = connectRoutes(routesPathMap, options);
