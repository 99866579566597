import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
const queryConfig = {
  refetchOnWindowFocus: false,
  retry: 2,
  cacheTime: Infinity,
};
const queryClient = new QueryClient({ defaultOptions: { queries: queryConfig } });

export default <T extends {}>(Component: React.ComponentType<T>) => (props: T) => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        {false && (
          <ReactQueryDevtools initialIsOpen={true} />
        ) /* set this to true for debugging purposes */}
        <Component {...props} />
      </QueryClientProvider>
    </>
  );
};
