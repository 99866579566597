import React from 'react';

import { useField } from 'formik';

import { getFieldErrors } from 'utils/form';

import Field, { FieldProps } from 'components/@inputs/Field';

import CheckboxTileGroup, { CheckboxTileGroupProps } from './CheckboxTileGroup';

export type CheckboxTileGroupFieldProps = CheckboxTileGroupProps & FieldProps;

const CheckboxTileGroupField: React.FC<CheckboxTileGroupFieldProps> = ({
  name,
  label,
  bottomLabel,
  fullWidth,
  isRequired,
  children,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const errors = getFieldErrors(name, meta);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: { [index: string]: unknown },
  ) => {
    if (e.target.checked) {
      helpers.setValue({ ...field.value, ...value });
    } else {
      const { [Object.keys(value)[0]]: _omit, ...rest } = field.value;
      helpers.setValue(rest);
    }

    if (!meta.touched) {
      helpers.setTouched(true);
    }
  };

  return (
    <Field
      label={label}
      errors={errors}
      bottomLabel={bottomLabel}
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
    >
      <CheckboxTileGroup
        id={name}
        name={name}
        {...field}
        onChange={handleChange}
        {...rest}
        data-test-id={name}
      >
        {children}
      </CheckboxTileGroup>
      <CheckboxTileGroup />
    </Field>
  );
};

export default CheckboxTileGroupField;
