import React, { Fragment, isValidElement } from 'react';

import dayjs from 'dayjs';
import { FormattedDate, FormattedRelativeTime } from 'react-intl';

import DATE_FORMATS from './dateFormats';

interface Props {
  date: number | string | Date;
  format?: string;
  withTodayAsText?: boolean;
}

const DateFormat: React.FC<Props> = ({ date, format = 'DD/MM/YYYY', withTodayAsText = false }) => {
  const getFormattedDate = () => {
    let equalFormat = format;
    let containsHours = false;
    if (withTodayAsText) {
      containsHours = equalFormat.includes('HH:mm');
      const isToday = dayjs(date).isSame(dayjs(), 'date');
      equalFormat = isToday ? 'timeAgo' : format;
    }

    let foundFormat = DATE_FORMATS[equalFormat];
    if (!foundFormat) foundFormat = DATE_FORMATS.default;

    if (isValidElement(foundFormat)) return foundFormat;
    let formattedDate = <Fragment>{dayjs(date).format(format)}</Fragment>;
    if (equalFormat === 'timeAgo') {
      formattedDate = (
        <Fragment>
          <FormattedRelativeTime unit="day" value={0} numeric="auto" />
          {containsHours && (
            <Fragment>
              ,&nbsp;
              <FormattedDate value={date} {...DATE_FORMATS['HH:mm']} />
            </Fragment>
          )}
        </Fragment>
      );
    }

    return formattedDate;
  };

  return <span>{getFormattedDate()}</span>;
};

export default DateFormat;
