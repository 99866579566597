import React, { Fragment, Suspense } from 'react';

import { Box } from '@material-ui/core';
import _get from 'lodash/get';

import CompleteProfile from 'pages/profile/CompleteProfile';

import Header from 'components/@layout/Header';
import Dialog from 'components/Dialog';
import ConnectedModal from 'components/Modal';
import { Notifier } from 'components/Notification';

import BackgroundContainer from '../Background/BackgroundContainer';
import { Container } from './Page.styled';
import { ContainerProps } from './PageContainer';

const Page: React.FC<ContainerProps> = ({ children, user }) => {
  const Content = () => {
    if (!_get(user, 'attributes.profileCompleted')) {
      return (
        <Suspense fallback={null}>
          <CompleteProfile />
        </Suspense>
      );
    }

    return (
      <Fragment>
        <BackgroundContainer />
        <Dialog />
        <ConnectedModal />
        {/*
          tutorials feature won't be coming soon so pick up once vbrb is ready (if ever)
          <Tutorial />
        */}
        <Header />
        <Container>{children}</Container>
        <Box display={{ xs: 'block', md: 'none' }} height={56} />
      </Fragment>
    );
  };

  return (
    <>
      <Notifier />
      <Content />
    </>
  );
};

export default Page;
