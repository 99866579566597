import _get from 'lodash/get';
import { normalize, schema } from 'normalizr';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import {
  createFileSubmission,
  fetchFileSubmissionPartnerContacts,
  fetchFileSubmissionPartners,
  fetchFileSubmissionRecentRecipients,
  updateFileSubmission,
} from 'api/fileSubmission.api';
import { QUERY_PARAMS, STEP_IDS } from 'constants/fileSubmission';
import {
  DEBOUNCE,
  PARTNER_CONTACTS_SCHEMA_NAME,
  PARTNER_SCHEMA_NAME,
  ROUTES,
} from 'constants/store';
import { analyticsService } from 'services';
import { resetUpload } from 'store/file/file.actions';
import { getUserAbilities } from 'store/user/user.selectors';

import {
  filesSubmissionRecentRecipientsFetchFail,
  filesSubmissionRecentRecipientsFetchStart,
  filesSubmissionRecentRecipientsFetchSuccess,
  fileSubmissionCreateFail,
  fileSubmissionCreateStart,
  fileSubmissionCreateSuccess,
  fileSubmissionPartnerContactsFetchFail,
  fileSubmissionPartnerContactsFetchStart,
  fileSubmissionPartnerContactsFetchSuccess,
  fileSubmissionPartnersFetchFail,
  fileSubmissionPartnersFetchStart,
  fileSubmissionPartnersFetchSuccess,
  fileSubmissionUpdateFail,
  fileSubmissionUpdateStart,
  fileSubmissionUpdateSuccess,
  goToNextStep,
  goToStep,
  setNewStep,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './fileSubmission.actions';
import {
  getFileSubmissionData,
  getFileSubmissionEndpoint,
  getFileSubmissionPartnerDomain,
  getFileSubmissionPartnersEndpoint,
  getFileSubmissionRecentRecipientsEndpoint,
  getFileSubmissionUpdateEndpoint,
} from './fileSubmission.selectors';

const formBody = {
  data: {
    type: 'file_transfer',
    attributes: {},
  },
};

function* filesSubmissionRecentRecipientsFetchFlow(action) {
  try {
    const partnerId = _get(action, 'payload.partnerId');
    const domain = _get(action, 'payload.domain');
    const path = yield select(getFileSubmissionRecentRecipientsEndpoint);
    const { data } = yield call(fetchFileSubmissionRecentRecipients, path, {
      [QUERY_PARAMS.PARTNER_ID]: partnerId,
      [QUERY_PARAMS.DOMAIN]: domain,
    });
    yield put(filesSubmissionRecentRecipientsFetchSuccess({ data }));
  } catch (error) {
    yield put(filesSubmissionRecentRecipientsFetchFail({ error }));
  }
}

function* fileSubmissionCreateFlow(action) {
  yield put(fileSubmissionCreateStart());
  try {
    const { body } = action.payload;
    const path = yield select(getFileSubmissionEndpoint);
    const { data } = yield call(createFileSubmission, path, body);
    yield put(fileSubmissionCreateSuccess({ data }));
    return true;
  } catch (error) {
    yield put(fileSubmissionCreateFail({ error }));
    return false;
  }
}

function* fileSubmissionUpdateFlow(action) {
  const { body, files, notesAdded } = action.payload;
  try {
    const path = yield select(getFileSubmissionUpdateEndpoint);
    const { data } = yield call(updateFileSubmission, path, body);
    analyticsService.events.trackDocumentsUploadTransfer(files.length, notesAdded);
    yield put(fileSubmissionUpdateSuccess({ data }));
    return true;
  } catch (error) {
    analyticsService.events.trackDocumentsUploadTransferFails(files.length);
    yield put(fileSubmissionUpdateFail({ error }));
    return false;
  }
}

function* goToNextStepFlow(action) {
  const { nextStep, values } = action.payload;
  const index = Object.values(STEP_IDS).indexOf(nextStep);
  let canContinue = true;
  const submissionData = yield select(getFileSubmissionData);
  const userAbilities = yield select(getUserAbilities);
  if (nextStep === STEP_IDS.STEP_RECIPIENT) {
    const domain = _get(submissionData, 'attributes.domain');
    const selectedDomain = values[STEP_IDS.STEP_DOMAIN].domain;
    const canAccess = userAbilities.can(
      'access',
      `${ROUTES.DATASHARE_CREATE}.EXTERNAL_CREATE_FORM`,
    );
    if (canAccess && domain !== selectedDomain) {
      yield put(filesSubmissionRecentRecipientsFetchStart({ domain: selectedDomain }));
    }

    if (domain !== selectedDomain) {
      formBody.data.attributes = { domain: selectedDomain };
      canContinue = yield call(fileSubmissionCreateFlow, { payload: { body: formBody } });
    }
  }

  if (nextStep === STEP_IDS.STEP_UPLOAD) {
    yield put(resetUpload());
  }

  if (nextStep === STEP_IDS.STEP_SUBMIT) {
    yield put(fileSubmissionUpdateStart(values));
  }

  if (canContinue) {
    yield put(
      setNewStep({ stepId: nextStep, newCompleted: Object.values(STEP_IDS).slice(0, index) }),
    );
  }
}

function* goToStepFlow(action) {
  const { stepId } = action.payload;
  const index = Object.values(STEP_IDS).indexOf(stepId);
  const newCompleted = Object.values(STEP_IDS).slice(0, index + 1);
  yield put(setNewStep({ stepId, newCompleted }));
}

function* fileSubmissionPartnersFetchFlow(action) {
  try {
    yield delay(DEBOUNCE);
    const { search } = action.payload;
    const path = yield select(getFileSubmissionPartnersEndpoint);
    const { data } = yield call(fetchFileSubmissionPartners, path, {
      q: search,
    });
    const partnerScheme = new schema.Entity(PARTNER_SCHEMA_NAME);
    const partnerSchema = [partnerScheme];
    const normalized = normalize(data, partnerSchema);
    yield put(
      fileSubmissionPartnersFetchSuccess({ data: normalized.entities[PARTNER_SCHEMA_NAME] }),
    );
  } catch (error) {
    yield put(fileSubmissionPartnersFetchFail({ error }));
  }
}

function* fileSubmissionPartnerContactsFetchFlow(action) {
  try {
    const { path, search } = action.payload;
    const domain = yield select(getFileSubmissionPartnerDomain);
    yield delay(DEBOUNCE);
    const { data } = yield call(fetchFileSubmissionPartnerContacts, path, {
      q: search,
      domain,
    });
    const partnerContactsScheme = new schema.Entity(PARTNER_CONTACTS_SCHEMA_NAME);
    const partnerContactsSchema = [partnerContactsScheme];
    const normalized = normalize(data, partnerContactsSchema);
    yield put(
      fileSubmissionPartnerContactsFetchSuccess({
        data: normalized.entities[PARTNER_CONTACTS_SCHEMA_NAME],
      }),
    );
  } catch (error) {
    yield put(fileSubmissionPartnerContactsFetchFail({ error }));
  }
}
// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

function* fileSubmissionSaga() {
  yield takeLatest(
    filesSubmissionRecentRecipientsFetchStart.getType(),
    filesSubmissionRecentRecipientsFetchFlow,
  );
  yield takeLatest(fileSubmissionUpdateStart.getType(), fileSubmissionUpdateFlow);
  yield takeLatest(goToStep.getType(), goToStepFlow);
  yield takeLatest(goToNextStep.getType(), goToNextStepFlow);
  yield takeLatest(fileSubmissionPartnersFetchStart.getType(), fileSubmissionPartnersFetchFlow);
  yield takeLatest(
    fileSubmissionPartnerContactsFetchStart.getType(),
    fileSubmissionPartnerContactsFetchFlow,
  );
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default fileSubmissionSaga;
