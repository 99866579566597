export const STEP_IDS = {
  STEP_COMPANY: 'stepCompany',
  STEP_BASE_DATA: 'stepBaseData',
  STEP_ADDRESS: 'stepAddress',
  STEP_CONTACT: 'stepContact',
  STEP_AFFILIATION: 'stepAffiliation',
  STEP_ADDITIONAL_INFO: 'stepAdditionalInfo',
  STEP_DOCUMENTS: 'stepDocuments',
  STEP_SUMMARY: 'stepSummary',
  STEP_FILE_UPLOAD: 'stepFileUpload',
};
