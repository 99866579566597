import { call, put, SagaReturnType, select, take, takeLatest } from 'redux-saga/effects';

import { apiService } from 'services';
import { getUserBookmarks } from 'store/user/user.selectors';
import {
  userBookmarksFetchFail,
  userBookmarksFetchStart,
  userBookmarksFetchSuccess,
  userFetchStatsStart,
} from 'store/user/user.slice';

import { getDashboardEvents, getDashboardEventsEndpoint } from './dashboard.selectors';
import {
  dashboardEventsFetchFail,
  dashboardEventsFetchStart,
  dashboardEventsFetchSuccess,
  dashboardItemsFetchFail,
  dashboardItemsFetchStart,
  dashboardItemsFetchSuccess,
} from './dashboard.slice';

function* dashboardItemsFetchFlow() {
  try {
    yield put(userFetchStatsStart());
    const userBookmarks: SagaReturnType<typeof getUserBookmarks> = yield select(getUserBookmarks);
    const dashboardEvents: SagaReturnType<typeof getDashboardEvents> = yield select(
      getDashboardEvents,
    );

    if (!userBookmarks) {
      yield put(userBookmarksFetchStart());
      yield take([userBookmarksFetchSuccess, userBookmarksFetchFail]);
    }
    // if (!((userUpdates ?? []).length > 0)) {
    //   yield put(userFetchUpdatesStart({}));
    //   yield take([userFetchUpdatesFail, userFetchUpdatesSuccess]);
    // }

    if (!dashboardEvents) {
      yield put(dashboardEventsFetchStart());
      yield take([dashboardEventsFetchSuccess, dashboardEventsFetchFail]);
    }

    yield put(dashboardItemsFetchSuccess());
  } catch (error) {
    yield put(dashboardItemsFetchFail({ error }));
  }
}

function* dashboardEventsFetchFlow() {
  try {
    const path = yield select(getDashboardEventsEndpoint);
    const resp: SagaReturnType<typeof apiService.dashboard.fetchEvents> = yield call(
      [apiService, apiService.dashboard.fetchEvents],
      path,
    );
    yield put(dashboardEventsFetchSuccess({ resp }));
  } catch (error) {
    yield put(dashboardEventsFetchFail({ error }));
  }
}

export function* bootDashboard() {
  yield put(dashboardItemsFetchStart());
}

function* dashboardSaga() {
  yield takeLatest(dashboardItemsFetchStart, dashboardItemsFetchFlow);
  yield takeLatest(dashboardEventsFetchStart, dashboardEventsFetchFlow);
}

export default dashboardSaga;
