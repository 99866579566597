import React from 'react';

import { ReactComponent as UIcoFileDatabase } from 'assets/svg/u_ico_file_database.svg';
import { ReactComponent as UIcoFileOther } from 'assets/svg/u_ico_file_other.svg';
import { ReactComponent as UIcoFilePdf } from 'assets/svg/u_ico_file_pdf.svg';
import { ReactComponent as UIcoFilePicture } from 'assets/svg/u_ico_file_picture.svg';
import { ReactComponent as UIcoFilePpt } from 'assets/svg/u_ico_file_ppt.svg';
import { ReactComponent as UIcoFileSpreadsheet } from 'assets/svg/u_ico_file_spreadsheet.svg';
import { ReactComponent as UIcoFileText } from 'assets/svg/u_ico_file_text.svg';
import { ReactComponent as UIcoFileVideo } from 'assets/svg/u_ico_file_video.svg';
import { ReactComponent as UIcoFileZip } from 'assets/svg/u_ico_file_zip.svg';

export const FILE_TYPES: { [key: string]: { icon: React.ComponentType<{}> } } = {
  TEXTFILES: {
    icon: UIcoFileText,
  },
  SPREADSHEETS: {
    icon: UIcoFileSpreadsheet,
  },
  PRESENTATIONS: {
    icon: UIcoFilePpt,
  },
  IMAGES: {
    icon: UIcoFilePicture,
  },
  DATABASES: {
    icon: UIcoFileDatabase,
  },
  VIDEOS: {
    icon: UIcoFileVideo,
  },
  ZIP: {
    icon: UIcoFileZip,
  },
  PDF: {
    icon: UIcoFilePdf,
  },
  OTHER: {
    icon: UIcoFileOther,
  },
};

export const EXTENSION_FILE_TYPES: { [key: string]: string } = {
  txt: 'TEXTFILES',
  rtf: 'TEXTFILES',
  doc: 'TEXTFILES',
  docx: 'TEXTFILES',
  pages: 'TEXTFILES',
  odt: 'TEXTFILES',
  csv: 'SPREADSHEETS',
  xls: 'SPREADSHEETS',
  xlsx: 'SPREADSHEETS',
  numbers: 'SPREADSHEETS',
  ods: 'SPREADSHEETS',
  pps: 'PRESENTATIONS',
  ppsx: 'PRESENTATIONS',
  ppt: 'PRESENTATIONS',
  pptx: 'PRESENTATIONS',
  key: 'PRESENTATIONS',
  odp: 'PRESENTATIONS',
  png: 'IMAGES',
  gif: 'IMAGES',
  jpg: 'IMAGES',
  jpeg: 'IMAGES',
  bmp: 'IMAGES',
  tif: 'IMAGES',
  tiff: 'IMAGES',
  mdb: 'DATABASES',
  accdb: 'DATABASES',
  odb: 'DATABASES',
  webm: 'VIDEOS',
  mpg: 'VIDEOS',
  mp2: 'VIDEOS',
  mpeg: 'VIDEOS',
  mpe: 'VIDEOS',
  mpv: 'VIDEOS',
  ogg: 'VIDEOS',
  avi: 'VIDEOS',
  wmv: 'VIDEOS',
  mov: 'VIDEOS',
  zip: 'ZIP',
  pdf: 'PDF',
  xps: 'PDF',
};

export default null;
