import React from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { ExpertData } from 'services/Api/types/experts.types';

import Icon from 'components/Icon';
import { UIcoMail, UIcoMobile, UIcoTelefoon } from 'components/Icons';

import { InfoWrap, LinkWrap } from './ExpertContactInfo.styled';

interface Props {
  hasEmptyLabel?: boolean;
  expert: ExpertData;
  title?: boolean;
  onExpertInfoClick?: (infoType: string, id: string) => void;
}

const ExpertContactInfo: React.FC<Props> = ({
  expert,
  title,
  hasEmptyLabel = true,
  onExpertInfoClick = () => {},
}) => {
  const { email, phoneNumber, mobileNumber } = expert.attributes;
  const hasDetails = email || phoneNumber || mobileNumber;
  return (
    <InfoWrap>
      {title && (
        <Box mb={2}>
          <Typography variant="subtitle2" color="textSecondary">
            <FormattedMessage id="claims.experts.contact_details" />
          </Typography>
        </Box>
      )}
      {hasDetails ? (
        <div>
          {email && (
            <LinkWrap>
              <Icon color="brand01.600" size={20} margin="right" spacing={8}>
                <UIcoMail />
              </Icon>
              <Link
                onClick={() => onExpertInfoClick('EMAIL', expert.id)}
                variant="body1"
                href={`mailto:${email}`}
              >
                {email}
              </Link>
            </LinkWrap>
          )}
          {phoneNumber && (
            <LinkWrap>
              <Icon color="brand01.600" size={20} margin="right" spacing={8}>
                <UIcoTelefoon />
              </Icon>
              {phoneNumber}
            </LinkWrap>
          )}
          {mobileNumber && (
            <LinkWrap>
              <Icon color="brand01.600" size={20} margin="right" spacing={8}>
                <UIcoMobile />
              </Icon>
              {mobileNumber}
            </LinkWrap>
          )}
        </div>
      ) : (
        hasEmptyLabel && <FormattedMessage id="claims.experts.no_contact_info" />
      )}
    </InfoWrap>
  );
};

export default ExpertContactInfo;
