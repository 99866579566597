import React from 'react';

import { Box, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import DateFormat from 'components/Date';

import { FieldContent } from './SummaryField.styled';

interface SummaryFieldProps {
  label: string | { id: string; values: { [index: string]: string } };
  value?: React.ReactNode;
  isPhraseKey?: boolean;
  type?: 'date' | 'text';
  nowrap?: boolean;
  prewrap?: boolean;
}

const SummaryField: React.FC<SummaryFieldProps> = ({
  value,
  label,
  type,
  nowrap = false,
  prewrap = false,
  isPhraseKey = true,
}) => {
  if (!value || !value.toString()?.trim()) {
    return null;
  }
  const getPhraseProps = () => {
    if (typeof label === 'string') {
      return { id: label };
    }
    return label;
  };
  return (
    <Box mb={4}>
      <Grid container spacing={3}>
        <Grid item xs={nowrap ? 5 : 12} md={5}>
          <FieldContent pr={2} color="brand01.400">
            {isPhraseKey ? <FormattedMessage {...getPhraseProps()} /> : label}
          </FieldContent>
        </Grid>
        <Grid item xs={nowrap ? 7 : 12} md={7}>
          <FieldContent whiteSpace={prewrap ? 'pre-wrap' : 'normal'} color="neutral01.700">
            {type === 'date' && value && typeof value === 'string' ? (
              <DateFormat format="DD/MM/YYYY" date={value} />
            ) : (
              value
            )}
          </FieldContent>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SummaryField;
