import useClients from 'hooks/useClients/useClients';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { queryKeys } from 'constants/querykeys';
import { apiService } from 'services';
import { ExpertContext } from 'services/Api/types/experts.types';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { Store } from 'types/store/store.types';

export const contextQueryParam: Partial<Record<ExpertContext, string>> = {
  claims: 'claim_id',
  invoices: 'invoice_id',
  policies: 'policy_id',
};

const useExperts = (context: ExpertContext, id?: string, providedGroupId?: string) => {
  const { groupId: selectedGroupId } = useClients();
  const groupId = providedGroupId ?? selectedGroupId;
  const endpoint = useSelector((state: Store) => getEndpoint(state, 'experts'));
  const queryParam = contextQueryParam[context];
  const expertQuery = useQuery(
    [queryKeys.experts, context, id ?? ''],
    () =>
      apiService.experts.fetchExperts(
        endpoint,
        context,
        groupId,
        queryParam ? { [queryParam]: id ?? '' } : {},
      ),
    {
      enabled: !!endpoint,
      staleTime: 600000,
      cacheTime: Infinity,
    },
  );
  return {
    ...expertQuery,
    data: expertQuery?.data?.data ?? [],
  };
};

export default useExperts;
