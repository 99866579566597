import { createAction } from 'redux-act';

import { NotificationProps } from 'components/Notification/Notification';

export type CloseNotificationPayload = number | string | undefined;

export const addNotification = createAction(
  'ENQUEUE_SNACKBAR',
  (notification: NotificationProps) => {
    const key = notification.id || new Date().getTime() + Math.random();
    return {
      ...notification,
      key,
    };
  },
);

export const closeNotification = createAction<CloseNotificationPayload>('CLOSE_SNACKBAR');

// (-- APPEND ACTIONS HERE --) !!! do not move this comment !!!
