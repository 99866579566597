import { transparentize } from 'polished';
import styled from 'styled-components';

import { NativeSelectgProps } from './NativeSelect';

export const Select = styled.select<Pick<NativeSelectgProps, 'variant'>>`
  appearance: none;
  padding: 0 ${({ theme }) => theme.spacing(4)}px;
  height: 48px;
  background: transparent;
  border: 0;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 400;
  font-size: 16px;
  outline: 0;
  width: 100%;
  border: 1px solid;
  border-radius: 0;
  color: ${({ theme, disabled }) =>
    !disabled ? theme.palette.neutral01[600] : theme.palette.neutral01[500]};
  background: ${({ theme, disabled }) => {
    if (disabled) {
      return transparentize(0.6, theme.palette.neutral01[300]);
    }
    return theme.palette.neutral01[50];
  }};
  border: 1px solid ${({ theme }) => theme.palette.neutral01[300]};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  &::-ms-expand {
    display: none;
  }
  &:focus::-ms-value {
    color: ${({ theme }) => theme.palette.neutral01[600]};
    background: transparent;
  }
  &:not(:focus) {
    background: ${({ theme, variant }) => {
      if (variant === 'filled') {
        return theme.palette.accent01[100];
      }
    }};
  }
`;

export const SelectWrap = styled.div`
  position: relative;
`;

export const SelectArrow = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing(2)}px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const SelectPlaceholder = styled.div`
  font-weight: 400;
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  position: absolute;
  left: ${({ theme }) => theme.spacing(4)}px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

export default null;
