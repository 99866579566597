import React from 'react';

export interface Context {
  activePanel: number;
  handleGoToNextPanel: () => void;
  handleGoToPreviousPanel: () => void;
}

export const splitScreenContext = React.createContext<Context>({
  activePanel: 0,
  handleGoToNextPanel: () => {},
  handleGoToPreviousPanel: () => {},
});
