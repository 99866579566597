import styled from 'styled-components';

export const DialogAction = styled.div`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing(8)}px;
`;

export const DialogHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4.5)}px;
  display: flex;
  align-items: center;
`;

export const DialogContent = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(6)}px;
  line-height: 1.5;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.neutral01[600]};
`;

export const IconWrap = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacing(8)}px;
  height: ${({ theme }) => theme.spacing(8)}px;
  margin-right: ${({ theme }) => theme.spacing(4)}px;
`;
