import React, { Fragment } from 'react';

import { BackdropProps } from '@material-ui/core';
import { TooltipProps as MUITooltipProps } from '@material-ui/core/Tooltip';

import Backdrop from 'components/Backdrop';

import { TooltipContent, TooltipWrapper } from './Tooltip.styled';

export interface TooltipProps extends Omit<MUITooltipProps, 'title'> {
  isOverflowVisible?: boolean;
  backdropOpen?: boolean;
  backdropProps?: BackdropProps;
  maxWidth?: string;
  maxHeight?: string;
  title?: any;
  placement?: any;
  children: any;
  onBackdropClick?: (event: React.MouseEvent) => void;
}

const Tooltip: React.FC<TooltipProps> = ({
  isOverflowVisible = false,
  onBackdropClick = () => {},
  backdropOpen = false,
  backdropProps = {
    invisible: true,
  },
  placement = 'bottom-start',
  maxWidth = '100%',
  maxHeight = '400px',
  children,
  title,
  ...rest
}) => {
  return (
    <Fragment>
      <TooltipWrapper
        classes={{
          tooltip: 'tooltip',
        }}
        disableFocusListener
        placement={placement}
        title={
          <TooltipContent
            data-test-id="tooltip-modal"
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            isOverflowVisible={isOverflowVisible}
          >
            {title}
          </TooltipContent>
        }
        {...rest}
      >
        {children}
      </TooltipWrapper>
      {backdropOpen && (
        <Backdrop {...backdropProps} onClick={onBackdropClick} open={backdropOpen} />
      )}
    </Fragment>
  );
};

export default Tooltip;
