import React from 'react';

import { Grid } from '@material-ui/core';

import { InputField } from 'components/@inputs/TextField';

const AddressFieldOther: React.FC = () => (
  <Grid container spacing={8}>
    <Grid item xs={12}>
      <Grid container spacing={8}>
        <Grid item xs={12} md={3}>
          <InputField
            fullWidth
            name="zipcode"
            label="affiliations.add.step.address.zipcode_label"
            isRequired
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <InputField
            fullWidth
            name="location"
            label="affiliations.add.step.address.place_label"
            isRequired
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={7}>
      <InputField
        fullWidth
        name="street.inputValue"
        label="affiliations.add.step.address.street_label"
        isRequired
      />
    </Grid>
    <Grid item xs>
      <InputField
        fullWidth
        isRequired
        name="number"
        label="affiliations.add.step.address.number_label"
      />
    </Grid>
    <Grid item xs>
      <InputField
        fullWidth
        name="numberExtension"
        label="affiliations.add.step.address.bus_label"
      />
    </Grid>
  </Grid>
);

export default AddressFieldOther;
