import _get from 'lodash/get';
import { LocationState, Params } from 'redux-first-router';

import { Store } from 'types/store/store.types';

export const getLocation = (state: Store): LocationState => _get(state, 'location');
export const getRouteType = (state: Store) => _get(state, 'location.type');
export const getQuery = (state: Store): Params => _get(state, 'location.query', {});
export const getSubQuery = (state: Store, name: string): string =>
  _get(state, `location.query.${name}`, '');
export const getPrevLocationQuery = (state: Store) => _get(state, 'location.prev.query', {});
export const getRoutesMap = (state: Store) => _get(state, 'location.routesMap');
export const getPrevLocation = (state: Store) => _get(state, 'location.prev');
export const getPrevType = (state: Store) => _get(state, 'location.prev.type');
export const getPrevPath = (state: Store) => _get(state, 'location.prev.pathname');
export const getPath = (state: Store) => _get(state, 'location.pathname');
export const getPayload = (state: Store) => _get(state, 'location.payload');
export const getPrevPayload = (state: Store) => _get(state, 'location.prev.payload');
export const getPathParam = (state: Store, paramName = 'id'): string =>
  _get(state, `location.payload.${paramName}`);
