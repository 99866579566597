export const DEBOUNCE = 400;
export const INITIAL_MAX_PER_PAGE = 25;
export const CLIENT_SCHEMA_NAME = 'client';
export const CLIENT_REPORTING_SCHEMA_NAME = 'clientReportingCategory';
export const INSURER_SCHEMA_NAME = 'insurer';
export const EXPERT_SCHEMA_NAME = 'expert';
export const POLICY_CATEGORY_SCHEMA_NAME = 'policyCategory';
export const CLAIM_CATEGORY_SCHEMA_NAME = 'claimCategory';
export const INVOICE_CATEGORY_SCHEMA_NAME = 'invoiceCategory';
export const FILE_CATEGORY_SCHEMA_NAME = 'sharedFileCategory';
export const USER_SCHEMA_NAME = 'user';
export const PARTNER_SCHEMA_NAME = 'partner';
export const PARTNER_CONTACTS_SCHEMA_NAME = 'partnerContacts';
export const USER_UPDATES_SCHEMA_NAME = 'userUpdates';
export const WORK_INCAPACITY_SCHEMA_NAME = 'workIncapacity';

// ROUTES
export const ROUTES = {
  DASHBOARD: 'GENERAL',
  CLAIMS: 'CLAIMS',
  CLAIM_DETAIL: 'CLAIMS_DETAIL',
  CLAIM_CREATE: 'CLAIM_SUBMISSION',
  CLAIM_DOCUMENTS: 'CLAIM_DOCUMENTS',
  INVOICES: 'INVOICES',
  INVOICE_DETAIL: 'INVOICES_DETAIL',
  POLICIES: 'INSURANCE',
  POLICY_DETAIL: 'INSURANCE_DETAIL',
  RISK_DETAIL: 'RISK_DETAIL',
  SETTINGS: 'SETTINGS',
  DATASHARE: 'DATACOM',
  DATASHARE_CREATE: 'DOCUMENT_SUBMISSION',
  AUTH: 'AUTH',
  PARTNERS: 'CLIENTS',
  PARTNERS_DETAIL: 'CLIENTS_DETAIL',
  HC_AFFILIATIONS: 'HC_AFFILIATIONS',
  HC_AFFILIATIONS_DETAIL: 'HC_AFFILIATIONS_DETAIL',
  HC_AFFILIATIONS_CREATE: 'HC_AFFILIATIONS_SUBMISSION',
  IMPERSONATE: 'IMPERSONATE_CALLBACK',
  VEHICLE_CERTIFICATES: 'GREEN_CARDS',
  CLAIM_REPORTS: 'CLAIM_REPORTS',
  PUBLICATIONS: 'PUBLICATIONS',
  BULLETINBOARD: 'BULLETINBOARD',
  BULLETINBOARD_DETAIL: 'BULLETINBOARD_DETAIL',
  CLAIM_REPORTS_CREATE: 'CLAIM_REPORTS_CREATE',
  FEEDBACK: 'FEEDBACK',
  // --plop_insert_route--
};

export const ROUTE_ORDER = [
  ROUTES.DASHBOARD,
  ROUTES.PARTNERS,
  ROUTES.POLICIES,
  ROUTES.VEHICLE_CERTIFICATES,
  ROUTES.HC_AFFILIATIONS,
  ROUTES.CLAIMS,
  ROUTES.CLAIM_CREATE,
  ROUTES.INVOICES,
  ROUTES.DATASHARE,
  ROUTES.CLAIM_REPORTS,
  ROUTES.CLAIM_REPORTS_CREATE,
  ROUTES.PUBLICATIONS,
  ROUTES.BULLETINBOARD,
];

export const USER_TYPE = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL',
};

export const USER_ROLE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
