import React from 'react';

import { Box, BoxProps, Typography } from '@material-ui/core';

interface Props extends Omit<BoxProps, 'title'> {
  title?: React.ReactNode;
  description?: React.ReactNode;
  isSubSection?: boolean;
  filledIn?: boolean;
}

const Section: React.FC<Props> = ({
  title,
  description,
  isSubSection,
  filledIn = false,
  children,
  ...rest
}) => {
  const titleVariant = isSubSection ? 'h4' : 'h3';
  return (
    <Box
      mb={6}
      bgcolor={filledIn ? 'neutral01.200' : 'transparent'}
      borderRadius={8}
      border={filledIn ? '2px solid #F8F8F8' : 0}
      {...rest}
    >
      <Box ml={filledIn ? 6 : 0} pt={filledIn ? 6 : 0}>
        <Typography variant={titleVariant} color="secondary" gutterBottom>
          {title}
        </Typography>
        {description && (
          <Box component="p" whiteSpace="prewrap" color="neutral01.500" mt={0} mb={4}>
            {description}
          </Box>
        )}
      </Box>

      <>{children}</>
    </Box>
  );
};

export default Section;
