import React from 'react';

import { Box } from '@material-ui/core';

import { SummaryField, SummaryTitle } from 'components/FormSummary';

import { StepperState } from '../../AffiliationsCreateStepper';

interface Props {
  onEditClick: () => void;
}

const ContactSummary: React.FC<Props & StepperState['stepContact']> = ({
  onEditClick,
  ...values
}) => (
  <Box mb={8}>
    <SummaryTitle title="affiliations.add.step.contact.title" onEditClick={onEditClick} />
    <SummaryField
      label="affiliations.add.step.contact.email_professional"
      value={values.emailProfessional || '-'}
    />
    <SummaryField
      label="affiliations.add.step.contact.mobile_phone_label"
      value={values.phoneMobile || '-'}
    />
    <SummaryField
      label="affiliations.add.step.contact.phone_label"
      value={values.phoneLandline || '-'}
    />
  </Box>
);

export default ContactSummary;
