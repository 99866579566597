import { AuthService as OktaAuthService, OktaOptions } from '@okta/okta-react';
import { history } from 'redux-first-router';

export default class AuthService {
  private _config: OktaOptions;
  private _okta: OktaAuthService;
  constructor(issuer: string, oktaClientId: string, isPkce = true) {
    this._config = this.createConfig(issuer, oktaClientId, isPkce);
    this._okta = new OktaAuthService(this._config);
  }

  get config() {
    return this._config;
  }

  set config(conf) {
    this._config = conf;
  }

  get okta() {
    return this._okta;
  }

  set okta(okta) {
    this._okta = okta;
  }

  async isAuthenticated() {
    // Checks if there is a current accessToken in the TokenManger.
    return !!(await this._okta.tokenManager.get('accessToken'));
  }

  createConfig = (issuer: string, oktaClientId: string, isPkce = true) => {
    return {
      issuer,
      history: history(),
      clientId: oktaClientId,
      redirectUri: `${window.location.origin}/auth/callback/`,
      pkce: isPkce,
      tokenManager: {
        autoRenew: false,
      },
    };
  };
}
