import _merge from 'lodash/merge';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { persistCombineReducers, PersistConfig, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { enhancer as routeEnhancer } from 'store/routing/routing.reducer';

import { middlewares, sagaMiddleware } from './middlewares';

type TClearAction = {
  type: string;
  whitelist: Array<string>;
};

type TClearConfig = {
  actions?: Array<TClearAction>;
};

interface Options {
  enableDevTools: string | boolean;
  persistConfig: PersistConfig;
  clearConfig?: TClearConfig;
  initApp?: (store: Store) => void;
}

type OptionsParameters = Omit<Options, 'persistConfig'> & {
  persistConfig?: Omit<PersistConfig, 'storage'>;
};

let devToolsExtension = (f: any) => f;

const defaultOptions: Options = {
  enableDevTools: true,
  persistConfig: {
    key: 'root',
    storage,
    version: 1,
    debug: true,
    whitelist: [],
  },
  clearConfig: {
    actions: [],
  },
};

const generateStore = (sagas: any, reducers: any, options: OptionsParameters) => {
  const opts = _merge(defaultOptions, options);

  if (
    opts.enableDevTools &&
    (global as any).window &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
  ) {
    // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
    devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__();
  }

  const enhancer = compose(routeEnhancer, applyMiddleware(...middlewares), devToolsExtension);

  const persistConfig = opts.persistConfig;

  const persistedReducer = persistReducer(
    persistConfig,
    persistCombineReducers(persistConfig, reducers),
  );
  const store = createStore(persistedReducer, enhancer as any);
  const persistor = persistStore(store);

  sagaMiddleware.run(sagas);
  // @ts-ignore opts.initApp does exist in the default props
  const initApp = () => opts.initApp(store);
  return { store, persistor, initApp };
};

export default generateStore;
