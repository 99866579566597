import React from 'react';

import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { NavItem as Item } from 'types/header/header.types';

import Icon from 'components/Icon';

const NavItem: React.FC<Item> = ({ icon, label }) => (
  <>
    <Box mr={{ xs: 0, md: 2 }}>
      {icon && (
        <Icon hoverColor="brand01.400" size={24}>
          {icon}
        </Icon>
      )}
    </Box>
    <Box>
      <FormattedMessage id={label} />
    </Box>
  </>
);

export default NavItem;
