import React from 'react';

import dayjs from 'dayjs';
import { DateUtils } from 'react-day-picker';

import Icon from 'components/Icon';
import { UIcoPijlLinks, UIcoPijlRechts } from 'components/Icons';

import { DatePickerNavWrap, NavigationButton } from './DatePickerNav.styled';

interface DatePickerNavProps {
  onPreviousClick: () => void;
  onNextClick: () => void;
  nextMonth: Date;
  previousMonth: Date;
  disableBefore: Date;
  disableAfter: Date;
  dateUtils: typeof DateUtils;
}

const DatePickerNav: React.FC<DatePickerNavProps> = ({
  onPreviousClick,
  onNextClick,
  nextMonth,
  previousMonth,
  disableBefore,
  disableAfter,
  dateUtils,
}) => (
  <DatePickerNavWrap>
    {!dateUtils.isDayBefore(
      previousMonth,
      dayjs(disableBefore)
        .subtract(1, 'month')
        .toDate(),
    ) && (
      <NavigationButton isLeft onClick={() => onPreviousClick()}>
        <Icon size={20}>
          <UIcoPijlLinks />
        </Icon>
      </NavigationButton>
    )}
    {!dateUtils.isDayAfter(nextMonth, disableAfter) && (
      <NavigationButton onClick={() => onNextClick()}>
        <Icon size={20}>
          <UIcoPijlRechts />
        </Icon>
      </NavigationButton>
    )}
  </DatePickerNavWrap>
);

export default DatePickerNav;
