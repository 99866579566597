import { getCategoriesForEntity } from 'store/categories/categories.selectors';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { Store } from 'types/store/store.types';

export const getInvoices = (state: Store) => state.invoices.data;
export const getInvoicesIsLoading = (state: Store) => state.invoices.status === 'loading';
export const getInvoicesError = (state: Store) => state.invoices.error;
export const getInvoicesMeta = (state: Store) => state.invoices.meta;

export const getInvoicesIsExporting = (state: Store) => state.invoices.status === 'exporting';

export const getInvoiceCategories = (state: Store) =>
  getCategoriesForEntity(state, 'invoiceCategory');

export const getInvoicesEndpoint = (state: Store): string => getEndpoint(state, 'invoices');
