import { useEffect, useRef, useState } from 'react';

import _throttle from 'lodash/throttle';

const usePriorityNav = (
  outerRef: React.RefObject<HTMLDivElement>,
  innerRef: React.RefObject<HTMLDivElement>,
  moreItemRef: React.RefObject<HTMLDivElement>,
  items: React.ReactNode[],
) => {
  const [priorityItems, setPriorityItems] = useState<typeof items>(items);
  const [moreItems, setMoreItems] = useState<typeof items>([]);

  let widthArray = useRef<number[]>([]);

  const getItemsWidthArray = () => {
    if (innerRef && innerRef.current) {
      return Array.from(innerRef.current.children).map(child => {
        return child.getBoundingClientRect().width + 32;
      });
    }
    return [0];
  };

  const getWidthOfRef = <T extends HTMLElement>(ref: React.RefObject<T>) => {
    if (ref && ref.current) {
      return ref.current.clientWidth;
    }
    return 0;
  };

  const howManyItemsInMenuArray = (offset = 32, minimumNumberInNav = 3) => {
    let totalWidth = offset;

    const outerWidth = getWidthOfRef(outerRef);
    for (let i = 0; i < widthArray.current.length; i++) {
      if (totalWidth + widthArray.current[i] > outerWidth) {
        return i < minimumNumberInNav ? minimumNumberInNav : i;
      } else {
        totalWidth += widthArray.current[i];
      }
    }

    return items.length;
  };

  const updateNavigation = () => {
    const newItemVisible = howManyItemsInMenuArray();
    const navItems = [...items];
    const prioItems = navItems.slice(0, newItemVisible);
    const moreItems =
      prioItems.length !== navItems.length ? navItems.slice(newItemVisible, navItems.length) : [];
    setPriorityItems(prioItems);
    setMoreItems(moreItems);
  };

  const updateThrottled = useRef(_throttle(updateNavigation, 100));
  const updateNavigationRef = useRef(updateNavigation);
  const widthArrayFn = useRef(getItemsWidthArray);

  useEffect(() => {
    widthArray.current = widthArrayFn.current();
    updateNavigationRef.current();
    const update = updateThrottled.current;
    window.addEventListener('resize', update);
    return () => {
      window.removeEventListener('resize', update);
    };
  }, []);

  return { priorityItems, moreItems };
};
export default usePriorityNav;
