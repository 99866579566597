import _get from 'lodash/get';
import { normalize, schema } from 'normalizr';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import env from 'config';

import { fetchClient, fetchClientReportingCategories, fetchClients } from 'api/client.api';
import { CLIENT_REPORTING_SCHEMA_NAME, CLIENT_SCHEMA_NAME, DEBOUNCE } from 'constants/store';
import { getQuery } from 'store/routing/routing.selectors';

import {
  clientFetchByIdFail,
  clientFetchByIdStart,
  clientFetchByIdSuccess,
  clientFetchFail,
  clientFetchStart,
  clientFetchSuccess,
  clientReportingCategoriesFetchFail,
  clientReportingCategoriesFetchStart,
  clientReportingCategoriesFetchSuccess,
  clientResetSelectedIds,
  clientResetSelectedIdsStart,
  clientSearchFail,
  clientSearchStart,
  clientSearchSuccess,
  clientSelectFail,
  clientSelectStart,
  clientSelectSuccess,
  groupFetchFail,
  groupFetchStart,
  groupFetchSuccess,
  groupSearchFail,
  groupSearchStart,
  groupSearchSuccess,
  groupSelectFail,
  groupSelectStart,
  groupSelectSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './client.actions';
import {
  getClientById,
  getClientEndpoint,
  getGroupId,
  getPartnerIds,
  getValidClientIdsFromArray,
  getValidGroupId,
} from './client.selectors';

export function* setValidClientIdsFromQuery() {
  let { clientIds } = yield select(getQuery);
  const ids = yield select(getPartnerIds);
  if (clientIds) {
    const clientIdsArray = clientIds.split(',').map(id => id.trim());
    clientIds = getValidClientIdsFromArray(ids, clientIdsArray);
  } else {
    clientIds = ids;
  }
  yield put(clientResetSelectedIds({ clientIds }));
}

export function* clientFetchFlow({ payload }) {
  yield put(clientFetchStart());
  try {
    const { groupId } = payload;
    const path = yield select(getClientEndpoint);
    const { data } = yield call(fetchClients, path, { includeChildren: true, parentId: groupId });
    const groupscheme = new schema.Entity(CLIENT_SCHEMA_NAME);
    const clientSchema = [groupscheme];
    const normalized = normalize(data, clientSchema);
    const ids = normalized.result;
    yield put(
      clientFetchSuccess({
        groupPartners: ids,
        data: normalized.entities[CLIENT_SCHEMA_NAME],
      }),
    );
  } catch (error) {
    yield put(clientFetchFail({ error }));
  }
}

function* clientSearchFlow(action) {
  try {
    yield delay(DEBOUNCE);
    const id = yield select(getGroupId);
    const path = yield select(getClientEndpoint);
    const { search } = action.payload;
    const groupPartners = yield call(fetchClients, path, {
      includeChildren: true,
      parentId: id,
      q: search,
    });
    const groupscheme = new schema.Entity(CLIENT_SCHEMA_NAME);
    const clientSchema = [groupscheme];
    const normalized = normalize(groupPartners.data, clientSchema);
    yield put(clientSearchSuccess({ groupPartners: normalized.result }));
  } catch (error) {
    yield put(clientSearchFail({ error }));
  }
}

function* setSelectedPartnersFlow({ payload }) {
  const { clientIds } = payload;
  try {
    yield put(
      clientSelectSuccess({
        selected: clientIds,
      }),
    );
  } catch (error) {
    yield put(clientSelectFail({ error }));
  }
}

function* clientFetchByIdFlow(action) {
  try {
    const id = action.payload;
    const path = yield select(getClientEndpoint);
    const client = yield call(fetchClient, path, id);
    yield put(
      clientFetchByIdSuccess({
        client: { [client.data.id]: { ...client.data } },
      }),
    );
  } catch (error) {
    yield put(clientFetchByIdFail({ error }));
  }
}

function* groupSelectFlow({ payload }) {
  const { groupId } = payload;
  try {
    yield put(
      groupSelectSuccess({
        selected: groupId,
      }),
    );
    yield call(clientFetchFlow, { payload: { groupId } });
    yield put(clientResetSelectedIdsStart());
  } catch (error) {
    yield put(groupSelectFail({ error }));
  }
}

export function* groupFetchFlow() {
  try {
    const path = yield select(getClientEndpoint);
    const { data } = yield call(fetchClients, path, {
      includeChildren: 'false', // set explicit to string otherwise falsy values will be removed
      parentId: 'null',
    });
    const groupscheme = new schema.Entity(CLIENT_SCHEMA_NAME);
    const clientSchema = [groupscheme];
    const normalized = normalize(data, clientSchema);
    const { groupId: groupIdQueryParam } = yield select(getQuery);
    const validGroupIdParam = getValidGroupId(normalized.result, groupIdQueryParam);
    const groupId = data.length === 1 ? data[0].id : validGroupIdParam;
    if (groupId) {
      yield call(groupSelectFlow, { payload: { groupId } });
    }
    yield put(
      groupFetchSuccess({
        groupData: normalized.result,
        data: normalized.entities[CLIENT_SCHEMA_NAME],
      }),
    );
  } catch (error) {
    yield put(groupFetchFail({ error }));
  }
}

function* groupSearchFlow(action) {
  try {
    yield delay(DEBOUNCE);
    const path = yield select(getClientEndpoint);
    const { search } = action.payload;
    const groupData = yield call(fetchClients, path, {
      includeChildren: 'false',
      parentId: 'null',
      q: search,
    });
    const groupscheme = new schema.Entity(CLIENT_SCHEMA_NAME);
    const clientSchema = [groupscheme];
    const normalized = normalize(groupData.data, clientSchema);
    yield put(groupSearchSuccess({ groupData: normalized.result }));
  } catch (error) {
    yield put(groupSearchFail({ error }));
  }
}

export function* clientReportingCategoriesFetchFlow(action) {
  try {
    const { clientId } = action.payload;
    const client = yield select(getClientById, clientId);
    const endpoint =
      _get(client, 'links.reportingCategories') ||
      `${env.API_BASE}/api/clients/${clientId}/reporting_categories`;

    const { data } = yield call(fetchClientReportingCategories, endpoint);
    const categoriesSchema = [new schema.Entity(CLIENT_REPORTING_SCHEMA_NAME)];
    const normalized = normalize(data, categoriesSchema);
    const { result, entities } = normalized;
    yield put(
      clientReportingCategoriesFetchSuccess({
        data: entities[CLIENT_REPORTING_SCHEMA_NAME],
        result,
      }),
    );
  } catch (error) {
    yield put(clientReportingCategoriesFetchFail({ error }));
  }
}

// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

function* clientSaga() {
  yield takeLatest(clientSearchStart.getType(), clientSearchFlow);
  yield takeLatest(clientFetchByIdStart.getType(), clientFetchByIdFlow);
  yield takeLatest(clientSelectStart.getType(), setSelectedPartnersFlow);
  yield takeLatest(groupFetchStart.getType(), groupFetchFlow);
  yield takeLatest(groupSearchStart.getType(), groupSearchFlow);
  yield takeLatest(groupSelectStart.getType(), groupSelectFlow);
  yield takeLatest(clientResetSelectedIdsStart.getType(), setValidClientIdsFromQuery);
  yield takeLatest(
    clientReportingCategoriesFetchStart.getType(),
    clientReportingCategoriesFetchFlow,
  );
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default clientSaga;
