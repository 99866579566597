import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { STEP_IDS } from 'constants/claimReports';
import { StoreDefaultState } from 'types/store/store.types';

import {
  ClaimReportData,
  ClaimReportDownloadFailPayload,
  ClaimReportDownloadPayload,
  ClaimReportDownloadSuccessPayload,
  ClaimReportsFetchStartPayload,
  ClaimReportsFetchSuccessPayload,
  ClaimReportsResponse,
} from './claimReports.types';

export const claimReportsAdapter = createEntityAdapter<ClaimReportData>();

export type ClaimReportsState = ReturnType<typeof claimReportsAdapter.getInitialState> & {
  error: StoreDefaultState['error'];
  meta: ClaimReportsResponse['meta'];
  result: string[];
  downloading: string[];
  status?: 'loading' | 'filtering' | 'downloading';
  stepper: {
    currentStep: string;
    status?: 'creating' | 'completed';
  };
};

const initialStep = STEP_IDS.STEP_REPORT_TYPE;

const INITIAL_STATE: ClaimReportsState = claimReportsAdapter.getInitialState({
  error: undefined,
  status: undefined,
  result: [],
  downloading: [],
  meta: {
    count: 0,
    periods: [],
  },
  stepper: {
    currentStep: initialStep,
    status: undefined,
  },
});

const claimReportsSlice = createSlice({
  name: 'claimReports',
  initialState: INITIAL_STATE,
  reducers: {
    claimReportsFetchStart: (state, { payload }: PayloadAction<ClaimReportsFetchStartPayload>) => {
      state.status = payload.isFiltering ? 'filtering' : 'loading';
      state.meta.periods = payload.isFiltering ? state.meta.periods : [];
      state.error = undefined;
    },
    claimReportsFetchSuccess: (
      state,
      { payload }: PayloadAction<ClaimReportsFetchSuccessPayload>,
    ) => {
      claimReportsAdapter.upsertMany(state, payload.data);
      state.result = payload.result;
      state.meta.count = payload?.meta?.count;
      if (state.status === 'loading') {
        state.meta.periods = payload?.meta?.periods;
      }
      state.status = undefined;
    },
    claimReportsFetchFail: (
      state,
      { payload }: PayloadAction<{ error: ClaimReportsState['error'] }>,
    ) => {
      state.error = payload.error;
      state.status = undefined;
    },
    claimReportDownloadFetchStart: (
      state,
      { payload }: PayloadAction<ClaimReportDownloadPayload>,
    ) => {
      payload.id && state.downloading.push(payload.id);
    },
    claimReportDownloadFetchSuccess: (
      state,
      { payload }: PayloadAction<ClaimReportDownloadSuccessPayload>,
    ) => {
      state.downloading = state.downloading.filter(id => id !== payload.id);
    },
    claimReportDownloadFetchFail: (
      state,
      { payload }: PayloadAction<ClaimReportDownloadFailPayload>,
    ) => {
      state.downloading = state.downloading.filter(id => id !== payload.id);
    },
    resetClaimReportRequest: state => {
      state.stepper.currentStep = initialStep;
      state.stepper.status = undefined;
    },
    createClaimReportRequest: state => {
      state.stepper.status = 'creating';
    },
    createClaimReportRequestSuccess: state => {
      state.stepper.status = 'completed';
    },
  },
});

export const {
  claimReportsFetchStart,
  claimReportsFetchSuccess,
  claimReportsFetchFail,
  claimReportDownloadFetchStart,
  claimReportDownloadFetchSuccess,
  claimReportDownloadFetchFail,
  resetClaimReportRequest,
  createClaimReportRequest,
  createClaimReportRequestSuccess,
} = claimReportsSlice.actions;

export default claimReportsSlice.reducer;
