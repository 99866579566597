import React, { PureComponent } from 'react';

import { DialogActions, DialogContent } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import Button from 'components/@common/Button';
import { Form, Group, Input, InputWrap } from 'components/@inputs';

import withFormik from './withFormik';

class FeedbackForm extends PureComponent {
  componentDidMount = () => {
    const { resetFeedback, validateForm } = this.props;
    resetFeedback();
    validateForm();
  };
  handleFeedBackSend = () => {
    const { values, createFeedback } = this.props;
    const body = {
      data: {
        attributes: {
          ...values,
        },
      },
    };
    createFeedback({ body });
  };

  render() {
    const { values, handleChange, isLoading, errors } = this.props;
    return (
      <>
        <DialogContent>
          <div>
            <Group>
              <InputWrap>
                <FormattedMessage id="feedback.modal.description" />
              </InputWrap>
            </Group>
            <Form>
              <Group>
                <InputWrap>
                  <Input
                    isOptional
                    name="comment"
                    rows="5"
                    multiline
                    onChange={handleChange}
                    value={values.comment}
                    shouldFitContainer
                    withCharCount
                    maxChars={5000}
                  />
                </InputWrap>
              </Group>
            </Form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={Object.values(errors).length !== 0}
            isLoading={isLoading}
            onClick={this.handleFeedBackSend}
          >
            <FormattedMessage id="feedback.modal.send" />
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default withFormik(FeedbackForm);
