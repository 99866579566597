import React from 'react';

import { FormattedMessage } from 'react-intl';

import useStepperForm from 'utils/useStepperForm';

import { AdditionalInfoForm } from 'pages/affiliations/Forms';

import Button from 'components/@common/Button';

import { FormValues } from './AdditionalInfoStepContainer';

interface Props {
  saveForm: (values: FormValues) => void;

  wageType: string;
  wageDecimals: number;
  employmentConditions?: {
    key: string;
    title: string;
  }[];
}

const AdditionalInfoStep: React.FC<Props> = ({
  saveForm,
  wageType,
  wageDecimals,
  employmentConditions,
}) => {
  const { handleNextStepClick, canContinue } = useStepperForm(saveForm);
  return (
    <div>
      <AdditionalInfoForm
        wageType={wageType}
        wageDecimals={wageDecimals}
        employmentConditions={employmentConditions}
      />
      <Button
        data-test-id="affiliations.add.step.additional_info.actions.next"
        disabled={canContinue}
        onClick={handleNextStepClick}
      >
        <FormattedMessage id="general.stepper.next" />
      </Button>
    </div>
  );
};

export default AdditionalInfoStep;
