import { Ability } from '@casl/ability';
import { useSelector } from 'react-redux';

import { getUserAbilities } from 'store/user/user.selectors';

const useAuth = () => {
  const userAbilities: Ability = useSelector(getUserAbilities);
  const canAccess = (subject: string) => userAbilities.can('visit', subject);
  const can = (action: string, subject: string, field?: string) =>
    userAbilities.can(action, subject, field);
  const userRights = userAbilities.rules;

  return {
    Ability: userAbilities,
    userRights,
    canAccess,
    can,
  };
};

export default useAuth;
