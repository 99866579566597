import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { Store } from 'types/store/store.types';

// PARTNER

export const getPartner = (state: Store) => state.partner.data;
export const getPartnerIsLoading = (state: Store) => state.partner.status === 'loading';
export const getPartnerError = (state: Store) => state.partner.error;

//PARTNER CONTACTS

export const getPartnerContacts = (state: Store) => state.partner.contacts.data;
export const getPartnerContactsIsLoading = (state: Store) =>
  state.partner.contacts.status === 'loading';
export const getPartnerContactsError = (state: Store) => state.partner.contacts.error;
export const getPartnerContactsMeta = (state: Store) => state.partner.contacts.meta;

// ENDPOINTS

export const getPartnerEndpoint = (state: Store): string => getEndpoint(state, 'partners');

export const getPartnerContactsEndpoint = (state: Store) =>
  state.partner?.data?.links?.partnerContacts || '';
