import React from 'react';

import { Box } from '@material-ui/core';

import { FileInfo } from 'components/File';
import { SummaryField, SummaryTitle } from 'components/FormSummary';

import { StepperState } from '../../AffiliationsCreateStepper';

interface Props {
  onEditClick: () => void;
}

const DocumentsSummary: React.FC<Props & StepperState['stepDocuments']> = ({
  onEditClick,
  ...values
}) => (
  <Box mb={8}>
    <SummaryTitle title="affiliations.add.step.documents.title" onEditClick={onEditClick} />
    <SummaryField
      label="documents.add.step.upload.input_label"
      value={values.files?.map((file, index) => (
        <Box mb={2} key={index}>
          <FileInfo withFileMeta={false} nameWithExtension={true} file={file} />
        </Box>
      ))}
    />
    <SummaryField label="affiliations.add.step.upload.remark_label" value={values.remark || '-'} />
  </Box>
);

export default DocumentsSummary;
