import { createStyles, withStyles } from '@material-ui/core';
import MuiRadioGroup from '@material-ui/core/RadioGroup';

export const RadioGroup = withStyles(theme =>
  createStyles({
    root: {
      '& > :not(:last-child)': {
        marginBottom: theme.spacing(4),
      },
    },
  }),
)(MuiRadioGroup);
