import _get from 'lodash/get';
import { createSelector } from 'reselect';

export const getFileProgress = (state, id) => _get(state, ['file', 'files', id, 'progress']);

export const getFileIsUploading = (state, id) => _get(state, ['file', 'files', id, 'isUploading']);

export const getFileError = (state, id) => _get(state, ['file', 'files', id, 'error']);

export const getFileAttempts = (state, id) => _get(state, ['file', 'files', id, 'attempts']);

export const getIsFileUploadSucceeded = state =>
  _get(state, 'file.succeeded') === _get(state, 'file.total');

export const getIsFileUploadCompleted = state =>
  _get(state, 'file.succeeded') + _get(state, 'file.failed') === _get(state, 'file.total');

export const getTotalFiles = state => _get(state, 'file.total');

export const getSucceededFiles = state => _get(state, 'file.succeeded');

const getAllFiles = state => _get(state, 'file.files', {});

export const getFailedFiles = createSelector([getAllFiles], files =>
  Object.keys(files).reduce((res, key) => {
    if (!!files[key].error && !files[key].isUploading) {
      res[key] = files[key];
    }
    return res;
  }, {}),
);

export const getUploadedFiles = createSelector([getAllFiles], files =>
  Object.keys(files).reduce((res, key) => {
    if (!files[key].error && !files[key].isUploading) {
      res[key] = files[key];
    }
    return res;
  }, {}),
);
