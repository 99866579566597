import { createReducer } from 'redux-act';

import { IntlState } from 'types/store/intl.types';

import { updateLocale } from './intl.actions';
import { getBrowserLanguage } from './utils';

const INITIAL_STATE = {
  locale: getBrowserLanguage(),
};

const reducer = createReducer<IntlState>({}, INITIAL_STATE);

reducer.on(updateLocale, (state, locale) => ({
  ...state,
  locale,
}));

export default reducer;
