export const queryKeys = {
  insurantFamilyMembers: 'insurantFamilyMembers',
  insuranceCertificates: 'insuranceCertificates',
  claimTasks: 'claimTasks',
  claimFinancialFlows: 'claimFinancialFlows',
  claimFileTransfer: 'claimFileTransfer',
  claimTimeline: 'claimTimeline',
  claimIcPeriods: 'claimIcPeriods',
  claimWorkResumtion: 'claimWorkResumtion',
  claims: 'claims',
  claim: 'claim',
  claimDetail: 'claimDetail',
  claimReportTypes: 'claimReportTypes',
  claimReportRequest: 'claimReportRequest',
  claimsExport: 'claimsExport',
  experts: 'experts',
  locations: 'locations',
  partners: 'partners',
  partnerChildren: 'partnerChildren',
  users: 'users',
  usersExport: 'usersExport',
  policies: 'policies',
  policiesExport: 'policiesExport',
  policy: 'policy',
  policyDetail: 'policyDetail',
  insurers: 'insurers',
  policyHolders: 'policyHolders',
  risks: 'risks',
  riskCategories: 'riskCategories',
  bulletinboard: 'bulletinboardUpdates',
  bulletinboardSearch: 'bulletinboardSearch',
  bulletinboardDetail: 'bulletinboard',
  bulletinboardVideoCall: 'publicationBulletinBoardVideoCall',
  bulletinboardUpdates: 'publicationBulletinboardUpdates',
  publicationTypes: 'publicationTypes',
};
