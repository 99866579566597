import React from 'react';

import { IconLoader } from 'components/Loaders';

import { LinkProps } from './Link';
import { LinkButtonStyled, LoaderWrap } from './Link.styled';

export interface LinkButtonProps {
  isLoading?: boolean;
  fullWidth?: boolean;
}

const LinkButton: React.FC<LinkProps & LinkButtonProps> = React.forwardRef<
  HTMLButtonElement,
  LinkProps & LinkButtonProps
>(
  (
    {
      children,
      isLoading,
      color = 'brand01.300',
      hasUnderline = true,
      disabled = false,
      hoverColor = 'brand01.500',
      ...rest
    },
    ref,
  ) => (
    <>
      <LinkButtonStyled
        ref={ref}
        disabled={disabled}
        hoverColor={hoverColor}
        isLoading={isLoading}
        hasUnderline={hasUnderline}
        color={color}
        type="button"
        {...rest}
      >
        {children}
      </LinkButtonStyled>
      {isLoading && (
        <LoaderWrap>
          <IconLoader size={16} color="brand01.300" />
        </LoaderWrap>
      )}
    </>
  ),
);

LinkButton.defaultProps = {
  hoverColor: 'brand01.400',
  color: 'brand01.300',
  hasUnderline: true,
  disabled: false,
};

export default LinkButton;
