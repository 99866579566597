import React from 'react';

import { FormattedMessage } from 'react-intl';

import { CircularLoader } from 'components/Loaders';

import { InnerText, LoaderWrap, Wrap } from './Placeholders.styled';

export interface Props {
  customText?: string | React.ReactNode;
  size?: number;
}

const LoadingPlaceholder: React.FC<Props> = ({ customText, size }) => (
  <Wrap>
    <InnerText>
      <LoaderWrap>
        <CircularLoader size={size} />
      </LoaderWrap>
      {customText}
    </InnerText>
  </Wrap>
);

LoadingPlaceholder.defaultProps = {
  customText: <FormattedMessage id="general.loading.text" />,
};

export default LoadingPlaceholder;
