import React from 'react';

import MuiPopper from '@material-ui/core/Popper';
import styled, { css } from 'styled-components';

const fullScreenStyle = css`
  position: fixed !important;
  transform: none !important;
  margin-left: 0 !important;
  top: ${({ offset }) => offset}px !important;
  height: calc(100% - ${({ theme, offset }) => offset + theme.mixins.headerHeight + 8}px);
  width: 100%;
  .popperContent {
    box-shadow: none;
  }
`;
export const PopperStyled = styled(({ isFullScreenOnMobile, media, width, offset, ...rest }) => (
  <MuiPopper {...rest} />
))`
  && {
    display: ${({ open }) => (open ? `block` : `none`)};
    z-index: 1499;
    border-radius: ${({ theme }) => theme.spacing(2)}px;
    ${({ width }) => (width ? `width: ${width}` : '')};
    ${({ media, isFullScreenOnMobile }) =>
      media.small && isFullScreenOnMobile ? fullScreenStyle : ''};
    .popperContent {
      opacity: 1 !important;
      transform: none !important;
    }
    &[x-placement*='bottom'] {
      .popperContent {
        margin-top: ${({ theme }) => theme.spacing(2)}px;
        &:after,
        &:before {
          bottom: 100%;
        }
        &:after {
          border-bottom-color: ${({ theme }) => theme.palette.neutral01[50]};
        }
        &:before {
          border-bottom-color: ${({ theme }) => theme.palette.neutral01[300]};
        }
      }
    }
    &[x-placement*='top'] {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
      .popperContent {
        &:after,
        &:before {
          top: 100%;
        }
        &:after {
          border-top-color: ${({ theme }) => theme.palette.neutral01[50]};
        }
        &:before {
          border-top-color: ${({ theme }) => theme.palette.neutral01[300]};
        }
      }
    }
    &[x-placement*='left'],
    &[x-placement*='start'] {
      .popperContent {
        &:after,
        &:before {
          left: 20px;
        }
      }
    }
    &[x-placement*='right'],
    &[x-placement*='end'] {
      .popperContent {
        &:after,
        &:before {
          right: 20px;
        }
      }
    }
  }
`;

const maxHeightStyle = css`
  max-height: ${({ maxHeight }) => maxHeight}px;
  overflow: auto;
`;

export const PopperContent = styled.div`
  position: relative;
  background: #fff;
  ${({ maxHeight }) => (maxHeight ? maxHeightStyle : '')};

  z-index: 2;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.palette.neutral01[600]};

  height: 100%;
  &:after,
  &:before {
    border: solid transparent;
    ${({ withArrow }) => (withArrow && `content: ''`) || ''};
    height: 0;
    width: 0;
    position: absolute;
  }
  &:after {
    margin-left: -4px;
    border-width: 4px;
  }
  &:before {
    margin-left: -5px;
    border-width: 5px;
  }
`;

export default null;
