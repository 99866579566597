import { call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchDocuments, fetchDownloadDocument } from 'api/document.api';
import { addNotification } from 'store/notifications/notifications.actions';

import { downloadFileFromBinaryResponse } from 'utils/helpers';
import { getNormalizedJsonApi } from 'utils/jsonapi';

import { getDocumentEndpoint } from './document.selectors';
import {
  documentDownloadFetchFail,
  documentDownloadFetchStart,
  documentDownloadFetchSuccess,
  documentsFetchFail,
  documentsFetchStart,
  documentsFetchSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './document.slice';
import { Document, DocumentsResponse } from './document.types';

function* documentsFetchFlow(action: ReturnType<typeof documentsFetchStart>) {
  const { context, ...rest } = action.payload;
  try {
    const path = yield select(getDocumentEndpoint);

    const documents = yield call(fetchDocuments, path, {
      ...rest,
      context: context.toUpperCase(),
    });
    const { entities, result } = getNormalizedJsonApi<DocumentsResponse, { document: Document }>(
      documents,
    );

    yield put(
      documentsFetchSuccess({
        data: entities.document,
        result: result.document || [],
        isRecent: !!rest.recentOnly,
        meta: documents?.meta,
      }),
    );
  } catch (error) {
    yield put(documentsFetchFail({ error }));
  }
}
function* documentDownloadFetchFlow(action: ReturnType<typeof documentDownloadFetchStart>) {
  const { path, fileName, id } = action.payload;
  try {
    const response = yield call(fetchDownloadDocument, path);
    downloadFileFromBinaryResponse(response, fileName);
    yield put(documentDownloadFetchSuccess({ id }));
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'documents.download.error',
      }),
    );
    yield put(documentDownloadFetchFail({ error, id }));
  }
}
// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

function* documentSaga() {
  yield takeLatest(documentsFetchStart, documentsFetchFlow);
  yield takeLatest(documentDownloadFetchStart, documentDownloadFetchFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default documentSaga;
