import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getBanner } from 'store/banner/banner.selectors';
import { Store } from 'types/store/store.types';

import Banner from './Banner';

const mapStateToProps = (state: Store) => ({
  ...getBanner(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
