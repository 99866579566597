import React from 'react';

import countries from 'data/countries.json';
import { useIntl } from 'react-intl';

import { Select } from 'components/@inputs';

const localeIdMap = {
  nl: '1',
  fr: '2',
  en: '3',
};

export const getCountriesByLocale = locale => {
  const localeId = localeIdMap[locale.split('-')[0]];
  const countriesLocale = countries[localeId] || {};
  return countriesLocale;
};

const CountrySelect = ({ variant, ...rest }) => {
  const intl = useIntl();
  const countriesLocale = getCountriesByLocale(intl.locale);
  const options = Object.values(countriesLocale)
    .map(country => ({
      value: country.iso,
      label: country.name,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  return (
    <Select
      backspaceRemovesValue
      menuPlacement="bottom"
      withHighlight
      isSearchable
      isClearable
      options={options}
      variant={variant}
      menuPortalTarget={document.body}
      getOptionLabel={option => countriesLocale[option.value]?.name}
      placeholder={intl.formatMessage({
        id: 'general.select.country.placeholder',
      })}
      {...rest}
    />
  );
};

export default CountrySelect;
