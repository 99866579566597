import React, { Fragment } from 'react';

import { Box } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/Icon';
import { UIcoCheck } from 'components/Icons';

import { StepperProps } from '../Stepper';
import {
  DefaultStepIcon,
  ModifyLink,
  StepConnector,
  StepContent,
  StepLabel,
  StepLabelInner,
  StepLabelText,
  Steps,
  StepValue,
  TopContentWrap,
} from './StepperDesktop.styled';

export interface StepIconProps {
  isActive: boolean;
  isCompleted: boolean;
  number: number;
}

const StepIcon: React.FC<StepIconProps> = ({ isActive, isCompleted, number }) => (
  <DefaultStepIcon completed={isCompleted} active={isActive}>
    {!isActive && isCompleted ? (
      <Icon size={24}>
        <UIcoCheck />
      </Icon>
    ) : (
      number
    )}
  </DefaultStepIcon>
);

const StepperDesktop: React.FC<StepperProps> = ({
  steps,
  orientation,
  completedSteps,
  disabledSteps = [],
  activeStep,
  onEditClick,
  hideSteps,
  topContent,
}) => (
  <Fragment>
    <TopContentWrap>{topContent}</TopContentWrap>
    <Steps orientation={orientation} connector={<StepConnector />}>
      {steps
        .filter(step => hideSteps?.indexOf(step.id) === -1)
        .map((step, i) => {
          const isStepActive = step.id === activeStep;
          const currentStepNumer = i + 1;
          const isStepCompleted = completedSteps.indexOf(step.id) !== -1;
          const isStepDisabled = disabledSteps.indexOf(step.id) !== -1;
          return (
            <Step
              key={step.id}
              completed={isStepCompleted}
              active={isStepActive}
              disabled={isStepDisabled}
            >
              <StepLabel
                icon={
                  <StepIcon
                    isCompleted={isStepCompleted}
                    number={currentStepNumer}
                    isActive={isStepActive}
                  />
                }
              >
                <StepLabelInner>
                  <StepLabelText completed={isStepCompleted} active={isStepActive}>
                    {step.label}
                  </StepLabelText>
                  <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="flex-end">
                    {step.value && activeStep !== step.id && <StepValue>{step.value}</StepValue>}
                    {isStepCompleted && !isStepDisabled && !isStepActive && (
                      <ModifyLink
                        data-test-id="stepper-modify-link"
                        disabled={isStepDisabled}
                        onClick={() => onEditClick(step.id)}
                      >
                        <FormattedMessage id="general.stepper.edit" />
                      </ModifyLink>
                    )}
                  </Box>
                </StepLabelInner>
              </StepLabel>
              <StepContent>{step.content}</StepContent>
            </Step>
          );
        })}
    </Steps>
  </Fragment>
);

StepperDesktop.defaultProps = {
  orientation: 'vertical',
  onEditClick: () => {},
  hideSteps: [],
  disabledSteps: [],
};

export default StepperDesktop;
