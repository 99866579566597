import { ApiInstanceType } from './';

class ClientApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchClient(path: string, id: string) {
    return this.api.get(`${path}/${id}`).then(response => response.data);
  }

  fetchClients(path: string, params: { [index: string]: string | boolean }) {
    return this.api.get(path, { params }).then(response => response.data);
  }

  fetchClientReportingCategories(path: string, params: { [index: string]: string }) {
    return this.api
      .get(path, {
        params,
      })
      .then(response => response.data);
  }
}

export default ClientApi;
