import React from 'react';

import { Formik } from 'formik';
import _isEqual from 'lodash/isEqual';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
  getAffiliationSubmissionAffiliationsIsLoading,
  getAffiliationSubmissionMeta,
  getAffiliationSubmissionStatus,
  getFlowContext,
} from 'store/affiliationSubmission/affiliationSubmission.selectors';
import {
  fetchAffiliationPoliciesStart,
  updateVerifyInsurantStart,
} from 'store/affiliationSubmission/affiliationSubmission.slice';
import { InsurantDraft } from 'store/affiliationSubmission/affiliationSubmission.types';
import { getClientReportingCategories } from 'store/client/client.selectors';
import { ClientReportingCategory } from 'store/client/client.types';
import { Store } from 'types/store/store.types';

import { BaseDataFormValidation, BaseDataFormValues } from 'pages/affiliations/Forms';

import BaseDataStep from './BaseDataStep';

export type FormValues = BaseDataFormValues;

type Props = {
  onNextStepClick: (values: FormValues) => void;
  initialValues: FormValues;
  data?: InsurantDraft;
  reportingCategories?: ClientReportingCategory[];
};
type ChildProps = React.ComponentProps<typeof BaseDataStep>;

const BaseDataStepContainer: React.FC<ContainerProps & Props & ChildProps> = ({
  onNextStepClick,
  initialValues,
  reportingCategories = [],
  saveForm,
  isLoading,
  status,
  updateVerifyInsurant,
  getAffiliations,
  meta,
  flowType,
  flowContext,
  data,
}) => {
  const intl = useIntl();
  // a insurant needs to be checked if it exists or not but the user can continue even if it not uniquement
  const hasBeenChecked = meta?.isUnique !== undefined && status === 'updated';
  const handleNextStepClick = (values: FormValues) => {
    if (flowType === 'MAIN') {
      onNextStepClick(values);
    } else {
      getAffiliations({
        callback: () => {
          onNextStepClick(values);
        },
      });
    }
  };
  const handleUpdateCallback = (isUnique: boolean, values: FormValues) => {
    if (isUnique || status === 'updated') {
      handleNextStepClick(values);
    } else if (hasBeenChecked) {
      handleNextStepClick(values);
    } else {
      saveForm(values);
    }
  };

  const handleUpdate = (values: FormValues, verifyUnique: boolean) => {
    updateVerifyInsurant({
      data: values,
      verifyUnique: verifyUnique,
      callback: isUnique => {
        handleUpdateCallback(isUnique, values);
      },
    });
  };

  const communicationLanguage = flowContext?.communicationLanguage?.value
    ? { value: flowContext?.communicationLanguage?.value ?? '' }
    : { value: intl.locale?.split('-')[0] };

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={{
        communicationLanguage,
        ...initialValues,
        reportingCategories: Object.values(reportingCategories).map(category => {
          const reportingCategory = data?.attributes.reportingCategories?.find(
            cat => cat.categoryId === category.id,
          );
          const option = category?.attributes?.options?.find(
            opt => opt.id === reportingCategory?.optionId,
          );
          if (!reportingCategory) return null;
          return {
            value: {
              categoryId: category.id,
              categoryOptionId: option?.id ?? '',
              clientReportingCategoryTitle: category?.attributes?.title,
            },
            label: option?.title ?? '',
          };
        }),
      }}
      validationSchema={BaseDataFormValidation(reportingCategories, flowType)}
      onSubmit={values => {
        if (!_isEqual(initialValues, values)) {
          handleUpdate(values, true);
        } else {
          handleNextStepClick(values);
        }
      }}
    >
      <BaseDataStep
        insurantExists={hasBeenChecked && !meta?.isUnique}
        isLoading={isLoading}
        saveForm={saveForm}
        reportingCategories={reportingCategories}
        flowType={flowType}
      />
    </Formik>
  );
};

const mapStateToProps = (state: Store) => ({
  reportingCategories: getClientReportingCategories(state),
  status: getAffiliationSubmissionStatus(state),
  isLoading:
    getAffiliationSubmissionStatus(state) === 'updating' ||
    getAffiliationSubmissionAffiliationsIsLoading(state),
  meta: getAffiliationSubmissionMeta(state),
  flowContext: getFlowContext(state),
});

const mapDispatchToProps = {
  updateVerifyInsurant: updateVerifyInsurantStart,
  getAffiliations: fetchAffiliationPoliciesStart,
};

type MapStateProps = Omit<ReturnType<typeof mapStateToProps>, 'reportingCategories'>;
type MapDispatchProps = typeof mapDispatchToProps;
export type ContainerProps = MapStateProps & MapDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(BaseDataStepContainer);
