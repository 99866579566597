import { createReducer } from 'redux-act';

import {
  feedbackCreateFail,
  feedbackCreateStart,
  feedbackCreateSuccess,
  feedbackReset,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './feedback.actions';

export const INITIAL_STATE = {
  isLoading: false,
  error: null,
  isCompleted: false,
};

const REDUCERS = {
  [feedbackReset]: state =>
    Object.assign({}, state, {
      ...INITIAL_STATE,
    }),
  [feedbackCreateStart]: state =>
    Object.assign({}, state, {
      isLoading: true,
      error: null,
      isCompleted: false,
    }),
  [feedbackCreateSuccess]: state =>
    Object.assign({}, state, {
      isLoading: false,
      error: null,
      isCompleted: true,
    }),
  [feedbackCreateFail]: (state, { error }) =>
    Object.assign({}, state, {
      isLoading: false,
      error: error || 'unknown error',
      isCompleted: true,
    }),
  // (-- APPEND REDUCER ACTION MAPPING HERE --) !!! do not move this comment !!!
};

export default createReducer(REDUCERS, INITIAL_STATE);
