import React from 'react';

import { FormattedMessage } from 'react-intl';

import Icon from 'components/Icon';
import {
  UIcoPijlLinks,
  UIcoPijlLinksDubbel,
  UIcoPijlRechts,
  UIcoPijlRechtsDubbel,
} from 'components/Icons';

import { NavigationButton, NavigationIndicator, PageActionsWrap } from './PageActions.styled';

interface PageActionProps {
  onPageChange: (event: React.MouseEvent, page: number) => void;
  rowsPerPage: number;
  count: number;
  page: number;
  isSmall?: boolean;
}

const nextDisabled = (page: number, count: number, rows: number): boolean =>
  page >= Math.ceil(count / rows);

const prevDisabled = (page: number): boolean => page === 1;

const NavigationIcon: React.FC<{ isDisabled: boolean }> = ({ isDisabled, children }) => (
  <Icon
    color={!isDisabled ? 'brand01.300' : 'neutral01.300'}
    hoverColor={!isDisabled ? 'brand01.500' : 'neutral01.300'}
    size={22}
    cursor={!isDisabled ? 'pointer' : 'initial'}
  >
    {children}
  </Icon>
);

const PageActions: React.FC<PageActionProps> = ({
  onPageChange,
  rowsPerPage,
  count,
  page,
  isSmall,
}) => {
  const isNextDisabled = nextDisabled(page, count, rowsPerPage);
  const isPrevDisabled = prevDisabled(page);
  return (
    <PageActionsWrap isSmall={isSmall}>
      <NavigationButton left disabled={isPrevDisabled} onClick={event => onPageChange(event, 1)}>
        <NavigationIcon isDisabled={isPrevDisabled}>
          <UIcoPijlLinksDubbel />
        </NavigationIcon>
      </NavigationButton>
      <NavigationButton
        left
        disabled={isPrevDisabled}
        onClick={event => onPageChange(event, page - 1)}
      >
        <NavigationIcon isDisabled={isPrevDisabled}>
          <UIcoPijlLinks />
        </NavigationIcon>
      </NavigationButton>
      <NavigationIndicator>
        <FormattedMessage
          id="general.table.pagination_text"
          values={{
            result: `${count === 0 ? 0 : (page - 1) * rowsPerPage + 1} - ${Math.min(
              count,
              page * rowsPerPage,
            )}`,
            pages: `${count}`,
          }}
        />
      </NavigationIndicator>
      <NavigationButton disabled={isNextDisabled} onClick={event => onPageChange(event, page + 1)}>
        <NavigationIcon isDisabled={isNextDisabled}>
          <UIcoPijlRechts />
        </NavigationIcon>
      </NavigationButton>
      <NavigationButton
        disabled={isNextDisabled}
        onClick={event => onPageChange(event, Math.ceil(count / rowsPerPage))}
      >
        <NavigationIcon isDisabled={isNextDisabled}>
          <UIcoPijlRechtsDubbel />
        </NavigationIcon>
      </NavigationButton>
    </PageActionsWrap>
  );
};
PageActions.defaultProps = {
  onPageChange: () => {},
};
export default PageActions;
