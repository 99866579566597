import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import { Categories, CategoriesFetchSuccessPayload } from './categories.types';

export interface CategoriesState extends StoreDefaultState {
  data?: Categories;
}

const INITIAL_STATE: CategoriesState = {
  isLoading: false,
  error: undefined,
  data: undefined,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: INITIAL_STATE,
  reducers: {
    allCategoriesFetchStart: state => {
      state.isLoading = true;
      state.error = undefined;
    },
    allCategoriesFetchSuccess: (
      state,
      { payload }: PayloadAction<CategoriesFetchSuccessPayload>,
    ) => {
      state.isLoading = false;
      state.error = undefined;
      state.data = payload.data;
    },
    allCategoriesFetchFail: (
      state,
      { payload }: PayloadAction<{ error: CategoriesState['error'] }>,
    ) => {
      state.error = payload.error;
    },
  },
});

export const {
  allCategoriesFetchStart,
  allCategoriesFetchSuccess,
  allCategoriesFetchFail,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
