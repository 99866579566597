import React, { Fragment } from 'react';

import { FormattedMessage } from 'react-intl';

import { FileInfo } from 'components/File';

import { FileWrap } from './FileDeleteDialog.styled';

const FileDeleteDialog = ({ file }) => (
  <Fragment>
    <FormattedMessage id="documents.file_delete_dialog.text" />
    <FileWrap>
      <FileInfo type={file.type} file={file} />
    </FileWrap>
  </Fragment>
);

export default FileDeleteDialog;
