import { put, race, take } from 'redux-saga/effects';

import { dialogHide, dialogNo, dialogShow, dialogYes } from './dialog.actions';

function* dialogSaga(payload) {
  const { dialogProps } = payload;

  yield put(dialogShow({ currentDialog: dialogProps }));

  const { yes } = yield race({
    yes: take(dialogYes),
    no: take(dialogNo),
  });

  yield put(dialogHide());

  return !!yes;
}

export default dialogSaga;
