import React from 'react';

import { Formik } from 'formik';
import { connect } from 'react-redux';

import { getAffiliationSubmissionAffiliationsIsLoading } from 'store/affiliationSubmission/affiliationSubmission.selectors';
import { fetchAffiliationPoliciesStart } from 'store/affiliationSubmission/affiliationSubmission.slice';
import { Store } from 'types/store/store.types';

import { ContactDataFormValues, ContactDataValidationSchema } from 'pages/affiliations/Forms';

import ContactStep from './ContactStep';

export type FormValues = ContactDataFormValues;

type Props = {
  onNextStepClick: (values: FormValues) => void;
  initialValues: FormValues;
};
type ChildProps = React.ComponentProps<typeof ContactStep>;

const ContactStepContainer: React.FC<Props & ContainerProps & ChildProps> = ({
  onNextStepClick,
  initialValues,
  saveForm,
  getAffiliations,
  affiliationsLoading,
}) => {
  return (
    <Formik<FormValues>
      validateOnMount
      validationSchema={ContactDataValidationSchema}
      initialValues={initialValues}
      onSubmit={values => {
        getAffiliations({
          callback: () => {
            onNextStepClick(values);
          },
        });
      }}
    >
      <ContactStep saveForm={saveForm} affiliationsLoading={affiliationsLoading} />
    </Formik>
  );
};

const mapStateToProps = (state: Store) => ({
  affiliationsLoading: getAffiliationSubmissionAffiliationsIsLoading(state),
});

const mapDispatchToProps = {
  getAffiliations: fetchAffiliationPoliciesStart,
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type MapDispatchProps = typeof mapDispatchToProps;
export type ContainerProps = MapStateProps & MapDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContactStepContainer);
