import {
  ClaimDetailsResponse,
  ClaimFinancialFlowsResponse,
  ClaimResponse,
  ClaimTasksCreateFormBody,
  ClaimTasksCreateUpdateResponse,
  ClaimTasksResponse,
  ClaimTasksUpdateFormBody,
  IncapacityPeriod,
  IncapacityPeriodResponse,
  WorkResumptionRequestBody,
} from 'services/Api/types/claim.types';
import { ClaimsResponse } from 'services/Api/types/claims.types';
import { TimelineResponse } from 'services/Api/types/claim.types';

import { ApiInstanceType } from './';
class ClaimsApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchClaims = (path: string, filters: { [index: string]: string }) =>
    this.api
      .get<ClaimsResponse>(path, {
        params: filters,
      })
      .then(response => response.data);

  exportClaims = (path: string, filters: { [index: string]: string }) =>
    this.api
      .get<Blob>(path, {
        cache: {
          maxAge: 0,
          ignoreCache: true,
        },
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'blob',
        params: filters,
      })
      .then(response => response);

  fetchTimeline = (path: string) =>
    this.api.get<TimelineResponse>(path).then(response => response.data);

  fetchClaim = (path: string, id: string) =>
    this.api.get<ClaimResponse>(`${path}/${id}`).then(response => response.data);

  fetchClaimDetails = (path: string) =>
    this.api.get<ClaimDetailsResponse>(path).then(response => response.data);

  fetchClaimIncapacityPeriods = (path: string) =>
    this.api.get<IncapacityPeriodResponse>(path).then(response => response.data);

  updateClaimIncapacityPeriods = (path: string, body: { data: IncapacityPeriod[] }) =>
    this.api.put<IncapacityPeriodResponse>(path, body).then(response => response.data);

  updateClaimWorkResumption = (path: string, body: WorkResumptionRequestBody) =>
    this.api.put(path, body).then(response => response.data);

  fetchClaimTasks = (path: string) =>
    this.api.get<ClaimTasksResponse>(path).then(response => response.data);

  createClaimFileTransfer = (path: string, formBody: ClaimTasksCreateFormBody) =>
    this.api.post<ClaimTasksCreateUpdateResponse>(path, formBody).then(response => response.data);

  updateClaimFileTransfer = (path: string, formBody: ClaimTasksUpdateFormBody) =>
    this.api.put<ClaimTasksCreateUpdateResponse>(path, formBody).then(response => response.data);

  fetchClaimFinancialFlows = (path: string) =>
    this.api.get<ClaimFinancialFlowsResponse>(path).then(response => response.data);
}

export default ClaimsApi;
