import React from 'react';

import { IcoCheckboxChecked, IcoCheckboxUnchecked } from 'components/Icons';

import { CheckboxStyled, LabelStyled } from './Checkbox.styled';

export interface CheckboxProps {
  id?: string;
  label?: React.ReactNode;
  value: number | string;
  checked?: boolean;
  disabled?: boolean;
  iconSize: number;
  isTitle?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  height?: number;
  fullWidth?: boolean;
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
  disableRipple?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  withLabel?: boolean;
  hasError?: boolean;
  bottomLabel?: React.ReactNode;
  padding?: string;
}

const Checkbox: React.FC<CheckboxProps> & { defaultProps: Partial<CheckboxProps> } = ({
  id,
  label = '',
  value,
  checked,
  disabled,
  iconSize,
  isTitle,
  onChange,
  height,
  fullWidth,
  padding,
  onClick,
  ...rest
}) => (
  <div onClick={onClick}>
    <LabelStyled
      label={label}
      value={value}
      // or check instead of default props because react complains : A component is changing a controlled input of type checkbox to be uncontrolled.
      checked={!!checked}
      disabled={disabled}
      onChange={onChange}
      isTitle={isTitle}
      height={height}
      padding={padding}
      control={<CheckboxStyled iconSize={iconSize} {...rest} />}
    />
  </div>
);

Checkbox.defaultProps = {
  icon: <IcoCheckboxUnchecked />,
  checkedIcon: <IcoCheckboxChecked />,
  iconSize: 24,
  height: 24,
  isTitle: false,
  disableRipple: true,
  fullWidth: false,
};

export default Checkbox;
