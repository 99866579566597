import { Styles } from 'react-select/src/styles';
import styled from 'styled-components';

export const selectStyle: Styles = {
  control: () => ({
    display: 'flex',
    height: 24,
    paddingLeft: 0,
    border: `none`,
  }),
  singleValue: (base, state) => ({
    ...base,
    maxWidth: 'auto',
    color: state.selectProps.customTheme.palette.neutral01[500],
  }),
};

export const SelectWrap = styled.div`
  min-width: 66px;
  margin-left: ${({ theme }) => `${theme.spacing(1) * 2}px`};
`;

export const PerPageWrap = styled.div`
  display: flex;
`;

export default null;
