import { connect } from 'react-redux';
import { SimpleActionCreator } from 'redux-act';

import {
  closeNotification,
  CloseNotificationPayload,
} from 'store/notifications/notifications.actions';
import { NotificationsState } from 'store/notifications/notifications.reducer';
import { getNotifications } from 'store/notifications/notifications.selectors';
import { Store } from 'types/store/store.types';

import Notifier from './Notifier';

export interface ContainerProps {
  notifications: NotificationsState['notifications'];
  removeSnackbar: SimpleActionCreator<CloseNotificationPayload>;
}

const mapStateToProps = (state: Store) => ({
  notifications: getNotifications(state),
});
const mapDispatchToProps = {
  removeSnackbar: closeNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifier);
