import { createStyles, withStyles } from '@material-ui/core';
import MuiFormGroup from '@material-ui/core/FormGroup';

export const FormGroup = withStyles(theme =>
  createStyles({
    root: {
      '& > :not(:last-child)': {
        marginBottom: theme.spacing(4),
      },
    },
  }),
)(MuiFormGroup);
