import { Ability } from '@casl/ability';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import {
  FetchFavoritePartnersSuccessPayload,
  FetchUserBookmarksSuccessPayload,
  FetchUserStatsSuccessPayload,
  FetchUserTutorialsSuccessPayload,
  UpdateFavoritePartnerFail,
  UpdateFavoritePartnerStartPayload,
  UpdateFavoritePartnerSuccessPayload,
  UpdateUserStartPayload,
  UpdateUserSuccessPayload,
  UserBookmarksResponse,
  UserFavoritePartnersResponse,
  UserFetchSuccessPayload,
  UserResponse,
  UserStatsResponse,
  UserTutorialData,
  UserUpdatesResponse,
  UserVideoCarourselItem,
} from './user.types';

export type UserState = {
  data?: UserResponse['data'];
  status?: 'loading' | 'updating';
  error?: StoreDefaultState['error'];
  updateError: StoreDefaultState['error'];
  tutorials: {
    data: { [key: string]: UserTutorialData[] };
    isLoading: StoreDefaultState['isLoading'];
    error: StoreDefaultState['error'];
  };
  abilities?: Ability;
  bookmarks: StoreDefaultState & {
    data?: UserBookmarksResponse['data'];
    links?: UserBookmarksResponse['links'];
    meta?: UserBookmarksResponse['meta'];
  };
  updates: StoreDefaultState & {
    data?: UserUpdatesResponse['data'];
    links?: UserUpdatesResponse['links'];
    meta?: UserUpdatesResponse['meta'];
  };
  favoritePartners: StoreDefaultState & {
    data: UserFavoritePartnersResponse['data'];
    links?: UserFavoritePartnersResponse['links'];
    meta?: UserFavoritePartnersResponse['meta'];
  };
  stats: StoreDefaultState & {
    data?: UserStatsResponse['data'];
  };
  video: UserVideoCarourselItem;
};

export const INITIAL_STATE: UserState = {
  data: undefined,
  error: undefined,
  status: undefined,
  updateError: undefined,
  abilities: undefined,
  tutorials: {
    data: {},
    isLoading: false,
    error: undefined,
  },
  bookmarks: {
    isLoading: false,
    error: undefined,
    data: undefined,
  },
  updates: {
    isLoading: false,
    error: undefined,
    data: [],
    links: undefined,
    meta: undefined,
  },
  favoritePartners: {
    isLoading: false,
    error: undefined,
    data: [],
    links: undefined,
    meta: undefined,
  },
  stats: {
    isLoading: false,
    error: undefined,
    data: undefined,
  },
  video: {
    open: false,
    videoKey: '',
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    userFetchStart: state => {
      state.status = 'loading';
      state.error = undefined;
    },
    userFetchSuccess: (state, { payload }: PayloadAction<UserFetchSuccessPayload>) => {
      state.status = undefined;
      state.error = undefined;
      state.abilities = payload.abilities;
      state.data = payload.resp.data;
    },
    userFetchFail: (state, { payload }: PayloadAction<{ error: StoreDefaultState['error'] }>) => {
      state.error = payload.error;
      state.status = undefined;
      state.abilities = undefined;
      state.data = undefined;
    },
    userUpdateStart: (state, _action: PayloadAction<UpdateUserStartPayload>) => {
      state.status = 'updating';
      state.updateError = undefined;
    },
    userUpdateSuccess: (state, { payload }: PayloadAction<UpdateUserSuccessPayload>) => {
      state.status = undefined;
      state.data = payload.resp.data;
    },
    userUpdateFail: (state, { payload }: PayloadAction<{ error: StoreDefaultState['error'] }>) => {
      state.updateError = payload.error;
      state.status = undefined;
    },
    userTutorialsFetchSuccess: (
      state,
      { payload }: PayloadAction<FetchUserTutorialsSuccessPayload>,
    ) => {
      state.tutorials.error = undefined;
      state.tutorials.isLoading = false;
      state.tutorials.data = payload.tutorials;
    },
    userTutorialsFetchFail: (
      state,
      { payload }: PayloadAction<{ error: StoreDefaultState['error'] }>,
    ) => {
      state.tutorials.isLoading = false;
      state.tutorials.error = payload.error;
    },
    userTutorialsRemove: (state, { payload }: PayloadAction<{ module: string }>) => {
      const { [payload.module]: _tutorialToRemove, ...rest } = state.tutorials.data;
      state.tutorials.data = rest;
    },
    userBookmarksFetchStart: state => {
      state.bookmarks.isLoading = true;
      state.bookmarks.error = undefined;
    },
    userBookmarksFetchSuccess: (
      state,
      { payload }: PayloadAction<FetchUserBookmarksSuccessPayload>,
    ) => {
      state.bookmarks.isLoading = false;
      state.bookmarks.error = undefined;
      state.bookmarks.data = payload.resp.data;
      state.bookmarks.meta = payload.resp.meta;
      state.bookmarks.links = payload.resp.links;
    },
    userBookmarksFetchFail: (
      state,
      { payload }: PayloadAction<{ error: StoreDefaultState['error'] }>,
    ) => {
      state.bookmarks.error = payload.error;
      state.bookmarks.isLoading = false;
    },
    userFetchFavoritePartnersStart: state => {
      state.favoritePartners.isLoading = true;
      state.favoritePartners.error = undefined;
    },
    userFetchFavoritePartnersSuccess: (
      state,
      { payload }: PayloadAction<FetchFavoritePartnersSuccessPayload>,
    ) => {
      state.favoritePartners.isLoading = false;
      state.favoritePartners.error = undefined;
      state.favoritePartners.data = payload.resp.data;
      state.favoritePartners.meta = payload.resp.meta;
      state.favoritePartners.links = payload.resp.links;
    },
    userFetchFavoritePartnersFail: (
      state,
      { payload }: PayloadAction<{ error: StoreDefaultState['error'] }>,
    ) => {
      state.favoritePartners.isLoading = false;
      state.favoritePartners.error = payload.error;
    },
    userUpdateFavoritePartnerStart: (
      _state,
      _action: PayloadAction<UpdateFavoritePartnerStartPayload>,
    ) => {},
    userUpdateFavoritePartnerSuccess: (
      state,
      { payload }: PayloadAction<UpdateFavoritePartnerSuccessPayload>,
    ) => {
      state.favoritePartners.error = undefined;
      if (payload.type === 'ADD') {
        state.favoritePartners.data.push({ id: payload.id, type: 'partner' });
      }
      if (payload.type === 'REMOVE') {
        state.favoritePartners.data = state.favoritePartners.data.filter(
          partner => partner.id !== payload.id,
        );
      }
    },
    userUpdateFavoritePartnerFail: (
      state,
      { payload }: PayloadAction<UpdateFavoritePartnerFail>,
    ) => {
      state.favoritePartners.data = payload.prevFavoritePartners;
      state.favoritePartners.error = payload.error;
      state.favoritePartners.isLoading = false;
    },
    userFetchStatsStart: state => {
      state.stats.isLoading = true;
      state.stats.error = undefined;
    },
    userFetchStatsFail: (
      state,
      { payload }: PayloadAction<{ error: StoreDefaultState['error'] }>,
    ) => {
      state.stats.isLoading = false;
      state.stats.error = payload.error;
    },
    userFetchStatsSuccess: (state, { payload }: PayloadAction<FetchUserStatsSuccessPayload>) => {
      state.stats.isLoading = false;
      state.stats.error = undefined;
      state.stats.data = payload.resp.data;
    },
    UserHandleVideoCarouselItem: (state, { payload }: PayloadAction<UserVideoCarourselItem>) => {
      state.video.open = payload.open;
      state.video.videoKey = payload.videoKey;
    },
  },
});

export const {
  userFetchStart,
  userFetchSuccess,
  userFetchFail,
  userUpdateStart,
  userUpdateSuccess,
  userUpdateFail,
  userTutorialsFetchSuccess,
  userTutorialsFetchFail,
  userTutorialsRemove,
  userBookmarksFetchStart,
  userBookmarksFetchSuccess,
  userBookmarksFetchFail,
  userFetchFavoritePartnersStart,
  userFetchFavoritePartnersSuccess,
  userFetchFavoritePartnersFail,
  userUpdateFavoritePartnerStart,
  userUpdateFavoritePartnerSuccess,
  userUpdateFavoritePartnerFail,
  userFetchStatsStart,
  userFetchStatsFail,
  userFetchStatsSuccess,
  UserHandleVideoCarouselItem,
} = userSlice.actions;

export default userSlice.reducer;
