import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import { DashboardEventsFetchSuccessPayload, DashboardEventsResponse } from './dashboard.types';

export type DashboardState = {
  isLoading: StoreDefaultState['isLoading'];
  error: StoreDefaultState['error'];
  events: {
    isLoading: StoreDefaultState['isLoading'];
    error: StoreDefaultState['error'];
    data?: DashboardEventsResponse['data'];
    links?: DashboardEventsResponse['links'];
    meta?: DashboardEventsResponse['meta'];
  };
};

export const INITIAL_STATE: DashboardState = {
  isLoading: false,
  error: undefined,
  events: {
    isLoading: false,
    data: undefined,
    error: undefined,
    links: undefined,
    meta: undefined,
  },
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: INITIAL_STATE,
  reducers: {
    dashboardItemsFetchStart: state => {
      state.isLoading = true;
      state.error = undefined;
    },
    dashboardItemsFetchSuccess: state => {
      state.isLoading = false;
      state.error = undefined;
    },
    dashboardItemsFetchFail: (
      state,
      { payload }: PayloadAction<{ error: StoreDefaultState['error'] }>,
    ) => {
      state.isLoading = false;
      state.error = payload.error;
    },
    dashboardEventsFetchStart: state => {
      state.events.isLoading = true;
      state.events.error = undefined;
    },
    dashboardEventsFetchSuccess: (
      state,
      { payload }: PayloadAction<DashboardEventsFetchSuccessPayload>,
    ) => {
      state.events.isLoading = false;
      state.events.error = undefined;
      state.events.data = payload.resp.data;
      state.events.links = payload.resp.links;
      state.events.meta = payload.resp.meta;
    },
    dashboardEventsFetchFail: (
      state,
      { payload }: PayloadAction<{ error: StoreDefaultState['error'] }>,
    ) => {
      state.events.isLoading = false;
      state.events.error = payload.error;
    },
  },
});

export const {
  dashboardItemsFetchStart,
  dashboardItemsFetchSuccess,
  dashboardItemsFetchFail,
  dashboardEventsFetchStart,
  dashboardEventsFetchSuccess,
  dashboardEventsFetchFail,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
