import { AxiosError } from 'axios';
import { createAction } from 'redux-act';

import { AvatarFetchPayload } from 'types/store/avatars/avatars.types';

export const avatarFetchStart = createAction<AvatarFetchPayload>('AVATAR_FETCH_START');
export const avatarFetchSuccess = createAction<{ id: string; context: string; data?: string }>(
  'AVATAR_FETCH_SUCCESS',
);
export const avatarFetchFail = createAction<{ error: AxiosError; context: string; id: string }>(
  'AVATAR_FETCH_FAIL',
);

// (-- APPEND ACTIONS HERE --) !!! do not move this comment !!!
