import { connect } from 'react-redux';

import { authLogoutStart } from 'store/auth/auth.slice';
import { modalShow } from 'store/modal/modal.slice';
import { goToPage } from 'store/routing/routing.actions';
import { getRoutesMap, getRouteType } from 'store/routing/routing.selectors';
import { getUser, getUserAbilities } from 'store/user/user.selectors';
import { Store } from 'types/store/store.types';

import Header from './Header';

const mapStateToProps = (state: Store) => ({
  routesMap: getRoutesMap(state),
  route: getRouteType(state),
  userAbilities: getUserAbilities(state),
  user: getUser(state),
  currentRoute: getRouteType(state),
});

const mapDispatchToProps = {
  logout: authLogoutStart,
  redirect: goToPage as any,
  showModal: modalShow,
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export type ContainerProps = MapStateProps & DispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(Header);
