import { useEffect, useRef, useState } from 'react';

import { FormikValues } from 'formik';
import _omit from 'lodash/omit';
import { useDispatch, useSelector } from 'react-redux';
import { Option } from 'react-select/src/filters';

import { DEFAULT_PARAMS as QUERY_PARAMS } from 'constants/default.queryparams';
import { setQuery } from 'store/routing/routing.actions';
import { getQuery } from 'store/routing/routing.selectors';

type FilterValue = undefined | string | Option | Option[];

export type UseFilterOptions = {
  omitPersist?: string[];
  onFilterCallback?: (values: { [index: string]: FilterValue }) => void;
  disablePersist?: boolean;
};

const defaultOptions: Required<UseFilterOptions> = {
  omitPersist: [],
  disablePersist: false,
  onFilterCallback: () => {},
};

const useFilter = (options: UseFilterOptions = defaultOptions) => {
  const opts = { ...defaultOptions, ...options };

  const dispatch = useDispatch();
  const queryParameters = useSelector(getQuery);
  const initialValuesRef = useRef<typeof queryParameters>(_omit(queryParameters, opts.omitPersist));
  const [initialValues, setInitialValues] = useState<typeof queryParameters>(
    _omit(initialValuesRef.current, opts.omitPersist),
  );
  const [filter, setFilter] = useState(queryParameters);

  useEffect(() => {
    initialValuesRef.current = queryParameters;
  }, [queryParameters]);

  const handleHistoryEvent = () => {
    setFilter(_omit(initialValuesRef.current, opts.omitPersist));
    setInitialValues(_omit(initialValuesRef.current, opts.omitPersist));
  };

  const historyEventRef = useRef(handleHistoryEvent);

  useEffect(() => {
    const handleHistory = historyEventRef.current;
    window.addEventListener('popstate', handleHistory);
    return () => {
      window.removeEventListener('popstate', handleHistory);
    };
  }, []);

  const handleFilterChange = (newFilter: FormikValues) => {
    let currentFilters = { ...filter, ...newFilter };
    if (!newFilter[QUERY_PARAMS.PAGE]) {
      currentFilters = { ...currentFilters, [QUERY_PARAMS.PAGE]: undefined };
    }
    opts.onFilterCallback(currentFilters);
    if (!opts.disablePersist) {
      dispatch(setQuery(_omit(currentFilters, opts.omitPersist)));
    }

    setFilter(currentFilters);
  };

  const handleSortChange = (col: string, order?: string) => {
    if (col && order) {
      handleFilterChange({ [QUERY_PARAMS.ORDER]: `${col}:${order}` });
    } else {
      handleFilterChange({ [QUERY_PARAMS.ORDER]: undefined });
    }
  };

  const handlePageChange = ({ page }: { page: number }) => {
    if (page > 1) {
      handleFilterChange({ [QUERY_PARAMS.PAGE]: `${page}` });
    } else {
      handleFilterChange({ [QUERY_PARAMS.PAGE]: undefined });
    }
  };

  const handleMaxPerPageChange = (event: { maxPerPage: number }) => {
    handleFilterChange({ [QUERY_PARAMS.MAX_PER_PAGE]: `${event.maxPerPage}` });
  };

  return {
    handlePageChange,
    handleSortChange,
    handleMaxPerPageChange,
    handleFilterChange,
    filter,
    initialValues,
  };
};

export const sanitizeFilters = <T extends {}>(filters: T) => {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    const type = value instanceof Array ? 'array' : typeof value;

    switch (type) {
      case 'array': {
        acc = {
          ...acc,
          [key]: (value as any)
            .filter(Boolean)
            .map((option: any) => option.value)
            .join(','),
        }; // TODO: fix typing once refactor constants and shit
        break;
      }
      case 'object': {
        acc = { ...acc, [key]: value && (value as any).value };
        break;
      }
      default:
        acc = { ...acc, [key]: value };
    }

    return acc;
  }, {});
};

export default useFilter;
