import React, { PureComponent } from 'react';

import Downshift from 'downshift';
import _get from 'lodash/get';
import { injectIntl } from 'react-intl';

import { BACKSPACE, ENTER } from 'utils/keyCodes';

import { LinkButton } from 'components/@common/Link';
import { Input } from 'components/@inputs';
import Icon from 'components/Icon';
import { UIcoZoek } from 'components/Icons';
import { IconLoader } from 'components/Loaders';
import Popper from 'components/Popper';

import { DropdownMenu } from './components';
import { BottomLabel } from './Dropdown.styled';

class Dropdown extends PureComponent {
  inputRef = React.createRef(null);
  lastCheckedValue = '';

  state = {
    inputValue: this.props.initialValue,
    selectedItem: {},
  };

  static defaultProps = {
    initialValue: '',
    onItemSelected: () => {
      // default prop
    },
    onInputValidation: () => {
      // default prop
    },
    onInputChange: () => {
      // default prop
    },
    onReset: () => {
      // default prop
    },
    itemRender: item => <div>{_get(item, 'id')}</div>,
    itemToString: () => '',
    minInputLength: 3,
  };

  componentDidUpdate = () => {
    const { selectedItem, inputValue } = this.state;
    const { clearValueIf } = this.props;
    if (clearValueIf) {
      if ((selectedItem && Object.keys(selectedItem).length > 0) || inputValue.length > 0) {
        this.handleEditClick();
      }
    }
  };

  componentDidMount = () => {
    const { initialValue } = this.props;
    this.lastCheckedValue = initialValue;
  };

  handleInputChange = e => {
    const { onInputChange } = this.props;
    const { value } = e.target;
    this.setState(
      {
        inputValue: value,
      },
      () => {
        onInputChange(value);
      },
    );
  };

  handleToggleSuggestions = () => {
    const { isSuggestionsOpen } = this.state;
    this.setState({
      isSuggestionsOpen: !isSuggestionsOpen,
    });
  };

  doValidation = () => {
    const { inputValue } = this.state;
    const { onInputValidation, isInputValid } = this.props;
    const currentValue = inputValue && inputValue.trim();
    if (!isInputValid && inputValue.trim().length > 0 && currentValue !== this.lastCheckedValue) {
      onInputValidation(inputValue);
    }
    this.lastCheckedValue = inputValue;
  };

  handleKeyDown = e => {
    const { selectedItem, inputValue } = this.state;
    if (e.keyCode === ENTER) {
      this.doValidation();
    }
    if (selectedItem.length && !inputValue.length && e.keyCode === BACKSPACE) {
      this.setState({
        selectedItem: {},
      });
    }
  };

  handleChange = item => {
    const { isValidating, onItemSelected, itemToString } = this.props;

    if (!isValidating && item) {
      onItemSelected(item);
      this.setState({
        inputValue: itemToString(item),
        selectedItem: item,
      });
    }
  };

  handleEditClick = () => {
    const { onReset } = this.props;
    this.lastCheckedValue = '';
    this.setState(
      {
        inputValue: '',
        selectedItem: {},
      },
      () => onReset(),
    );
  };

  handleBlur = () => {
    this.doValidation();
  };

  checkPopperShow = () => {
    const { isInputValid, recentItems, options, minInputLength, isDataLoading } = this.props;
    const { inputValue } = this.state;
    if (!isInputValid) {
      if (inputValue && inputValue.length >= minInputLength) {
        return inputValue.length >= minInputLength && _get(options, 'length', -1) > 0;
      }
      return isDataLoading || _get(recentItems, 'length', -1) > 0;
    }
    return false;
  };

  render() {
    const {
      intl,
      isDataLoading,
      dataError,
      isLoading,
      isValidating,
      label,
      placeholder,
      isInputValid,
      recentItems,
      itemRender,
      itemToString,
      bottomLabel,
      disabled,
      options,
      ListItemLoader,
      Footer,
    } = this.props;

    const { selectedItem, inputValue: inputVal } = this.state;

    return (
      <div>
        <Downshift
          id="vbrbEmailSuggestions"
          itemToString={itemToString}
          inputValue={inputVal}
          onSelect={this.handleChange}
        >
          {({
            getInputProps,
            isOpen,
            inputValue,
            getItemProps,
            highlightedIndex,
            openMenu,
            getMenuProps,
          }) => {
            const { value, onChange, onBlur, ...rest } = getInputProps({
              onKeyDown: this.handleKeyDown,
            });

            return (
              <div>
                <div ref={this.inputRef}>
                  <Input
                    inputProps={{
                      ...rest,
                    }}
                    onFocus={openMenu}
                    shouldFitContainer
                    label={label}
                    placeholder={placeholder}
                    withInputHighlight
                    hasValue={isInputValid}
                    onBlur={this.handleBlur}
                    onChange={this.handleInputChange}
                    value={inputValue}
                    hasError={isInputValid === false}
                    disabled={disabled}
                    readOnly={isValidating || isInputValid}
                    startAdornment={
                      <Icon color="brand01.200">
                        <UIcoZoek />
                      </Icon>
                    }
                    endAdornment={
                      isLoading || isValidating ? (
                        <IconLoader size={18} color="brand01.200" />
                      ) : (
                        isInputValid && (
                          <LinkButton onClick={this.handleEditClick}>
                            {intl.formatMessage({ id: 'general.edit.text' })}
                          </LinkButton>
                        )
                      )
                    }
                  />
                </div>
                {bottomLabel && (
                  <BottomLabel hasError={isInputValid === false}>{bottomLabel}</BottomLabel>
                )}
                {this.checkPopperShow() && (
                  <Popper
                    withArrow={false}
                    open={isOpen}
                    anchorEl={_get(this.inputRef, 'current')}
                    backdropProps={{ invisible: true }}
                    width={`${_get(this.inputRef, 'current.clientWidth', 0)}px`}
                    maxHeight={300}
                  >
                    <DropdownMenu
                      highlightedIndex={highlightedIndex}
                      selectedItem={selectedItem}
                      isLoading={isDataLoading}
                      error={dataError}
                      withHeader={!inputVal}
                      data={inputVal ? options : recentItems}
                      getItemProps={getItemProps}
                      itemRender={itemRender}
                      inputValue={inputVal}
                      ListItemLoader={ListItemLoader}
                      getMenuProps={getMenuProps}
                      Footer={Footer}
                    />
                  </Popper>
                )}
              </div>
            );
          }}
        </Downshift>
      </div>
    );
  }
}

export default injectIntl(Dropdown);
