import React from 'react';

import { FormattedMessage } from 'react-intl';

import Icon from 'components/Icon';
import { UIcoError } from 'components/Icons';

import { H3Styled, InnerText, InnerWrap, Wrap } from './Placeholders.styled';

export interface Props {
  customText?: React.ReactNode;
  customIcon?: React.ReactNode;
  headerText?: React.ReactNode;
  iconSize?: number;
  type?: 'inline' | 'block' | 'page';
  maxWidth?: string;
}

const ErrorPlaceholder: React.FC<Props> = ({
  customText = <FormattedMessage id="general.error.text" />,
  headerText = <FormattedMessage id="general.error.page.text" />,
  customIcon = <UIcoError />,
  iconSize = 64,
  type = 'block',
}) => {
  const getIconColor = () => {
    switch (type) {
      case 'page':
        return 'brand01.400';
      default:
        return 'brand01.200';
    }
  };

  return (
    <Wrap context={type}>
      <InnerWrap context={type}>
        <Icon size={iconSize} color={getIconColor()}>
          {customIcon}
        </Icon>
        {type === 'page' && <H3Styled>{headerText}</H3Styled>}
        <InnerText context={type}>{customText}</InnerText>
      </InnerWrap>
    </Wrap>
  );
};
export default ErrorPlaceholder;
