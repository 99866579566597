import React from 'react';

import FormatNumber from 'components/FormatNumber';

import TextInput from '../TextField/TextInput';

const NumberFormat = ({ fieldType = 'default', ...rest }) => {
  let customTypeProps = {};
  if (fieldType === 'percentage') {
    customTypeProps = {
      isAllowed: values => {
        const { floatValue, value } = values;
        if (floatValue !== undefined) {
          return floatValue >= 0 && floatValue <= 100 && value.split('.')[0].length <= 3;
        }
        return true;
      },
      allowNegative: false,
      startAdornment: '%',
    };
  }
  return (
    <FormatNumber {...customTypeProps} {...rest} customInput={TextInput} displayType="input" />
  );
};

export default NumberFormat;
