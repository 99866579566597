import { Color } from '@material-ui/core';

const brand01: Color = {
  50: '#f7f8fa', // bg
  100: '#fafafa',
  200: '#4A4A4A', // global2
  300: '#2E6BE0', // global3
  400: '#164194', // global1
  500: '#E50670', // global4
  600: '#8AAAE9', // global5
  700: '#8C223E', // global6
  800: '#EFF5FF', // global7
  900: 'radial-gradient(493.6% 585.95% at 54.81% 38.69%, rgba(40, 112, 251, 0.8) 0%, rgba(40, 112, 251, 0) 100%), #164194', // bg radient
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

const brand02: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

const neutral01: Color = {
  50: '#FFFFFF', // neutral1
  100: '#F7F7F7', // neutral8
  200: '#F8F8F8', // neutral7
  300: '#E7E8F1', // neutral6
  400: '#BCBCBC', // neutral5
  500: '#848484', // neutral4
  600: '#4A4A4A', // neutral3
  700: '#000000', // neutral2
  800: '#DEE4EA', // disabled button
  900: 'rgba(255,255,255,0)', // transparant
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

const accent01: Color = {
  50: '#e7e8f1', // highlightBg
  100: '#FAFCF7', // inputHighlight
  200: '',
  300: '#60b766', // tagSuccess
  400: '#4ACA1D', // alert2
  500: '',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

const accent02: Color = {
  50: '#f6c065', // tagWarning
  100: '#FE9800', // warning1
  200: '#ff6464', // warning
  300: '#ff3d3d', // tagAlert
  400: '#E00202', // alert1
  500: '#FF7360', // Orange1
  600: '#FFF4E5', // notif warning bg
  700: '#F3F8EC', // notif success bg
  800: '#FFF1F0', // notif error bg
  900: '#E1751E', // warning-02
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

const accent03: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '#6596cb', // tagDefault
  500: '',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

const accent04: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export default { brand01, brand02, neutral01, accent01, accent02, accent03, accent04 };
