import React from 'react';

import { LoaderInner, LoaderOuter, LoaderWrap } from './CircularLoader.styled';

interface Props {
  size?: number;
}

const CircularLoader: React.FC<Props> = ({ size = 64 }) => (
  <LoaderWrap size={size}>
    <LoaderInner />
    <LoaderOuter />
  </LoaderWrap>
);

export default CircularLoader;
