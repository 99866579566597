import apiManager from './axios/apiManager';

export const fetchClaimSubmissionTiles = path =>
  apiManager.get(path).then(response => response.data);

export const fetchClaimSubmissionReportingCategories = (path, params) =>
  apiManager
    .get(path, {
      params,
    })
    .then(response => response.data);

export const fetchClaimSubmissionDamageCategories = (path, params) =>
  apiManager
    .get(path, {
      params,
    })
    .then(response => response.data);

export const updateClaimSubmission = (path, body) =>
  apiManager.put(path, body).then(response => response);

export const fetchClaimSubmissionPartners = path =>
  apiManager.get(path).then(response => response.data);

export const fetchClaimSubmissionPolicies = path =>
  apiManager.get(path).then(response => response.data);

export const createClaimSubmission = (path, body) =>
  apiManager.post(path, body).then(response => response.data);

export const fetchClaimSubmissionEmployees = (path, params) =>
  apiManager.get(path, { params }).then(response => response.data);

export const fetchClaimSubmissionEmployment = (path, params) =>
  apiManager.get(path, { params }).then(response => response.data);
