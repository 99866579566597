import { createSelector } from '@reduxjs/toolkit';

import { insurantsSelector } from 'store/affiliations/affiliations.selectors';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { Store } from 'types/store/store.types';

import {
  affiliationAdapter,
  affiliationPoliciesAdapter,
  insurantAdapter,
} from './affiliation.slice';

export const insurantSelector = insurantAdapter.getSelectors<Store>(state => state.affiliation);

export const getInsurant = (state: Store, id: string) => insurantSelector.selectById(state, id);
export const getInsurantError = (state: Store) => state.affiliation.error;
export const getInsurantStatus = (state: Store) => state.affiliation.status;
export const getInsurantIsLoading = (state: Store) => getInsurantStatus(state) === 'loading';

export const getInsurantFamilyMembersResult = (state: Store) =>
  state.affiliation.familyMembers.result;

export const getInsurantFamilyMembers = createSelector(
  [
    (state: Store, id: string) => getInsurantFamilyMembersResult(state)[id] ?? [],
    (state: Store) => state,
  ],
  (results, state) => results?.map(id => insurantsSelector.selectById(state, id)).filter(Boolean),
);

export const getInsurantFamilyMembersError = (state: Store) =>
  state.affiliation.familyMembers.error;
export const getInsurantFamilyMembersIsLoading = (state: Store) =>
  state.affiliation.familyMembers.status === 'loading';

export const getAffiliationEndpoint = (state: Store): string => getEndpoint(state, 'insurants');

export const affiliationsSelector = affiliationAdapter.getSelectors<Store>(
  state => state.affiliation.affiliations,
);

export const affiliationPolicySelector = affiliationPoliciesAdapter.getSelectors<Store>(
  state => state.affiliation.affiliationPolicies,
);

export const getInsurantAffiliationsResult = (state: Store) =>
  state.affiliation.affiliations.result;

export const getInsurantAffiliations = createSelector(
  [getInsurantAffiliationsResult, (state: Store) => state],
  (results, state) => results.map(id => affiliationsSelector.selectById(state, id)),
);

export const getInsurantAffiliationsStatus = (state: Store) =>
  state.affiliation.affiliations.status;
export const getInsurantAffiliationsError = (state: Store) => state.affiliation.affiliations.error;

export const getAffiliationPolicy = (state: Store, id: string) =>
  affiliationPolicySelector.selectById(state, id);
