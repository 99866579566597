import './fonts.css';

import { createGlobalStyle } from 'styled-components';
export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    font-size: 16px;
  }
  body {
    color: ${({ theme }) => theme.palette.neutral01[600]};
  }
`;
