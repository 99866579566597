import { Input as MuiInput } from '@material-ui/core';
import styled from 'styled-components';

const TAG_SPACING = 1 / 2;

export const ChipWrap = styled.label`
  padding: 0;
  background: 0;
  border: 0;
  outline: 0;
  margin: ${({ theme }) => theme.spacing(TAG_SPACING)}px;
`;

export const AdornmentWrap = styled.span`
  margin-top: -${({ theme }) => theme.spacing(0)}px;
`;

// we want to make sure it stays hidden!
export const VisibilyHiddenInput = styled.input`
  appearance: 0 !important;
  width: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
  display: inline !important;
  outline: 0 !important;
  border: 0 !important;
`;

export const Input = styled(MuiInput).attrs({
  classes: { root: 'root tagInput-root', input: 'tagInput-input' },
})`
  &.tagInput-root {
    line-height: 1;
    padding: ${({ theme }) => theme.spacing(2)}px;
    flex-wrap: wrap;
    & .tagInput-input {
      flex-grow: 1;
      text-overflow: ellipsis;
      width: 0;
      min-width: 30px;
      padding: ${({ theme }) => theme.spacing(1)}px;
    }
  }
`;
