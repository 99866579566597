import React from 'react';

import { RadioProps } from '@material-ui/core';

import {
  LabelStyled,
  RadioIconChecked,
  RadioIconUnChecked,
  RadioStyled,
} from './RadioButton.styled';

export interface Props extends RadioProps {
  label?: React.ReactNode;
  height?: number;
  isTitle?: boolean;
  iconSize?: number;
  fullWidth?: boolean;
}

const RadioButton: React.FC<Props> = ({
  label,
  disabled,
  onChange,
  checked,
  fullWidth: _fullWidth,
  value = '',
  height = 24,
  isTitle = false,
  iconSize = 18,
  ...rest
}) => (
  <LabelStyled
    label={label}
    value={`${value}`}
    checked={checked}
    disabled={disabled}
    onChange={onChange}
    isTitle={isTitle}
    height={height}
    control={
      <RadioStyled
        icon={<RadioIconUnChecked iconSize={iconSize} />}
        checkedIcon={<RadioIconChecked iconSize={iconSize} />}
        iconSize={iconSize}
        disableRipple
        {...rest}
      />
    }
  />
);

export default RadioButton;
