import React from 'react';

import { useField } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { getFieldErrors } from 'utils/form';

import Field, { FieldProps } from 'components/@inputs/Field';

import TextInput, { Props as TextInputProps } from './TextInput';

const TextInputField: React.FC<FieldProps & TextInputProps> = ({
  name,
  label,
  bottomLabel,
  placeholder,
  disabled,
  fullWidth,
  isRequired,
  withCharCount,
  optionalAbbr,
  maxChars,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const errors = getFieldErrors(name, meta);
  const intl = useIntl();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = maxChars ? e.target.value.substring(0, maxChars) : e.target.value;
    helpers.setValue(value);
  };

  const handleClear = () => {
    helpers.setValue('');
  };

  const bottomLabelCharCount = maxChars && (
    <FormattedMessage
      id="general.chararcters.count"
      values={{ length: (field.value || '').length, max: maxChars }}
    />
  );
  return (
    <Field
      label={label}
      errors={errors}
      bottomLabel={withCharCount ? bottomLabelCharCount : bottomLabel}
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
      optionalAbbr={optionalAbbr}
    >
      <TextInput
        id={name}
        disabled={disabled}
        error={errors.length > 0}
        placeholder={placeholder && intl.formatMessage({ id: placeholder })}
        fullWidth={fullWidth}
        maxChars={maxChars}
        onClear={handleClear}
        variant={field.value ? 'filled' : undefined}
        {...field}
        {...rest}
        name={name}
        onChange={handleChange}
        data-test-id={name}
      />
    </Field>
  );
};

export default TextInputField;
