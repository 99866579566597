import React from 'react';

import { Typography } from '@material-ui/core';
import MuiTableRow, { TableRowProps as MuiTableRowProps } from '@material-ui/core/TableRow';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { TableCellInner } from '../TableCell/TableCell.styled';

export interface TableRowProps extends MuiTableRowProps {
  enableHover?: boolean;
  withRowHighlight?: boolean;
}

export const TableTypography = styled(Typography).attrs({ component: 'div' })``;

const TableRowStyled = styled(
  ({ children, component = 'div', enableHover, withRowHighlight, ...rest }: TableRowProps) => (
    <MuiTableRow component={component} {...rest}>
      {children}
    </MuiTableRow>
  ),
)`
  && {
    &:hover {
      cursor: ${({ enableHover }) => (enableHover ? `pointer` : `inherit`)};
      background-color: ${({ theme, enableHover, withRowHighlight }) =>
        (enableHover || withRowHighlight) && transparentize(0.95, theme.palette.brand01[400])};

      ${TableCellInner} {
        color: ${({ theme, enableHover }) =>
          enableHover ? theme.palette.brand01[500] : 'inherit'};
      }
      span div {
        color: ${({ theme, enableHover }) =>
          enableHover ? theme.palette.brand01[500] : 'inherit'};
      }
      ${TableTypography} {
        color: ${({ theme, enableHover }) =>
          enableHover ? theme.palette.brand01[500] : 'inherit'};
      }

      svg {
        color: ${({ theme, enableHover }) =>
          enableHover ? theme.palette.brand01[500] : 'inherit'};
      }
    }
  }
`;

export default TableRowStyled;
