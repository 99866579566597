import React from 'react';

import { Formik } from 'formik';
import { connect } from 'react-redux';

import {
  getAffiliationSubmissionAffiliations,
  getAffiliationSubmissionAffiliationsIsLoading,
  getAffiliationTerminationReasons,
} from 'store/affiliationSubmission/affiliationSubmission.selectors';
import { Store } from 'types/store/store.types';

import { AffiliationFormValidation, AffiliationFormValues } from 'pages/affiliations/Forms';

import AffiliationStep from './AffiliationStep';

export type FormValues = AffiliationFormValues;

type Props = {
  onNextStepClick: (values: FormValues) => void;
  initialValues: FormValues;
  birthDate: string;
  isMainInsurant?: boolean;
};
type ChildProps = Omit<React.ComponentProps<typeof AffiliationStep>, 'values'>;

const AffiliationStepContainer: React.FC<Props & ContainerProps & ChildProps> = ({
  onNextStepClick,
  initialValues,
  saveForm,
  birthDate,
  isMainInsurant,
  affiliations = [],
  ...rest
}) => {
  return (
    <Formik<FormValues>
      validateOnMount
      validationSchema={AffiliationFormValidation(birthDate, isMainInsurant)}
      initialValues={initialValues}
      onSubmit={() => {}}
    >
      <AffiliationStep
        {...rest}
        onNextStepClick={onNextStepClick}
        affiliations={affiliations}
        saveForm={saveForm}
      />
    </Formik>
  );
};

const mapStateToProps = (state: Store) => ({
  affiliations: getAffiliationSubmissionAffiliations(state),
  terminationReasons: getAffiliationTerminationReasons(state),
  affiliationsLoading: getAffiliationSubmissionAffiliationsIsLoading(state),
});
const mapDispatchToProps = {};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type MapDispatchProps = typeof mapDispatchToProps;
export type ContainerProps = MapStateProps & MapDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(AffiliationStepContainer);
