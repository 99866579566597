import React from 'react';

import Icon from 'components/Icon';

import { LoaderIconWrap, LoaderOuter, LoaderWrap } from './IconLoader.styled';

export interface IconLoaderProps {
  size: number;
  color: string;
}

const IconLoader: React.FC<IconLoaderProps> & { defaultProps: Partial<IconLoaderProps> } = ({
  size,
  children,
  color,
}) => (
  <LoaderWrap size={size}>
    <LoaderOuter color={color} />
    <LoaderIconWrap>
      <Icon color={color} size={size * 0.65}>
        {children}
      </Icon>
    </LoaderIconWrap>
  </LoaderWrap>
);

IconLoader.defaultProps = {
  size: 32,
  color: 'brand01.300',
};

export default IconLoader;
