import React from 'react';

export default (
  BoundaryComponent: React.ComponentType, //
) => (
  Component: any, //
) => (
  props: {}, //
) => (
  <BoundaryComponent>
    <Component {...props} />
  </BoundaryComponent>
);
