import React from 'react';

import { SkeletonLine } from 'components/Loaders';

const RadioTileGroupLoading = () => (
  <>
    {Array.from(Array(4).keys()).map(key => (
      <SkeletonLine
        key={key}
        height={51}
        duration={1}
        opacity={0.6}
        marginBottom={key !== 3 ? 12 : 0}
      />
    ))}
  </>
);

export default RadioTileGroupLoading;
