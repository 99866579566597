import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { TagProps } from './Tag';

const warningStyle = css`
  background-color: ${({ theme }) => theme.palette.accent02[100]};
  color: ${({ theme }) => theme.palette.neutral01[50]};
`;

const alertStyle = css`
  background-color: ${({ theme }) => theme.palette.brand01[500]};
  color: ${({ theme }) => theme.palette.neutral01[50]};
`;

const successStyle = css`
  background-color: ${({ theme }) => theme.palette.accent01[400]};
  color: ${({ theme }) => theme.palette.neutral01[50]};
`;

const inactiveStyle = css`
  background-color: ${({ theme }) => transparentize(0.9, theme.palette.neutral01[500])};
  color: ${({ theme }) => transparentize(0.2, theme.palette.neutral01[500])};
`;

const defaultStyle = css`
  background-color: ${({ theme }) => theme.palette.brand01[400]};
  color: ${({ theme }) => theme.palette.neutral01[50]};
`;

const normalStyle = css`
  padding: 0 4px;
  border-radius: 2px;
`;

const smallStyle = css`
  width: 16px;
  border-radius: 50%;
`;

export const TagStyled = styled.span<TagProps>`
  letter-spacing: 0.2px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 900;
  text-overflow: ellipsis;
  height: 16px;
  font-size: 11px;
  text-transform: ${({ upperCase }) => (upperCase ? 'uppercase' : 'none')};
  ${({ smallBadge }) => (smallBadge ? smallStyle : normalStyle)};
  ${({ type }) => {
    switch (type) {
      case 'alert':
        return alertStyle;
      case 'warning':
        return warningStyle;
      case 'success':
        return successStyle;
      case 'inactive':
        return inactiveStyle;
      default:
        return defaultStyle;
    }
  }};
`;

export default null;
