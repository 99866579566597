import { SharedFilesResponse } from 'store/dataShare/dataShare.types';

import { ApiInstanceType } from './';

class DataShareApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchSharedFiles = (path: string, params: { [index: string]: string }) =>
    this.api
      .get<SharedFilesResponse>(path, {
        params,
        cache: {
          maxAge: 0,
          ignoreCache: true,
        },
      })
      .then(response => response.data);

  deleteSharedFile = (path: string) =>
    this.api
      .delete(path, {
        cache: {
          maxAge: 0,
          ignoreCache: true,
        },
      })
      .then(response => response);

  downloadSharedFile = (path: string) =>
    this.api
      .get<Blob>(path, {
        cache: {
          maxAge: 0,
          ignoreCache: true,
        },
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'blob',
      })
      .then(response => response);
}

export default DataShareApi;
