import { call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchAllCategories } from 'api/endpoint.api';

import { getNormalizedJsonApi } from 'utils/jsonapi';

import { getAllCategoriesEndpoint } from './categories.selectors';
import {
  allCategoriesFetchFail,
  allCategoriesFetchStart,
  allCategoriesFetchSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './categories.slice';
import { CategoriesResponse, Category } from './categories.types';

export function* allCategoriesFetchFlow() {
  const endpoint = yield select(getAllCategoriesEndpoint);
  try {
    const data = yield call(fetchAllCategories, endpoint);
    const { entities } = getNormalizedJsonApi<CategoriesResponse, { [index: string]: Category }>(
      data,
    );
    const { aggregatedCategory, ...categories } = entities;
    yield put(allCategoriesFetchSuccess({ data: categories }));
  } catch (error) {
    if (!endpoint) {
      console.warn('no endpoint for categories!');
    }
    yield put(allCategoriesFetchFail({ error }));
  }
}
// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

function* categoriesSaga() {
  yield takeLatest(allCategoriesFetchStart, allCategoriesFetchFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default categoriesSaga;
