import _get from 'lodash/get';
import styled from 'styled-components';

import { IconProps } from './Icon';

const IconInner = styled.span<IconProps>`
  display: inline-block;
  line-height: 1;
  cursor: ${({ cursor }) => cursor};
  ${({ margin, spacing }) => spacing && `margin-${margin}: ${spacing}px`};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  color: ${({ color, theme }) => _get(theme.palette, color || '', '')};
  vertical-align: middle;
  svg {
    ${({ rotation }) => (rotation && `transform: rotate(${rotation}deg)`) || ''};
    pointer-events: none;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
  }
  }
  ${({ hoverColor, theme }) =>
    hoverColor
      ? `
      &:hover {
        color: ${_get(theme.palette, hoverColor, '')};
      };
    `
      : ''};
`;

IconInner.displayName = 'IconInner';

export default IconInner;
