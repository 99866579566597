import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { UIcoLogo } from 'components/Icons';

import CompleteProfileForm from './CompleteProfileFormContainer';

const CompleteProfile: React.FC = () => (
  <Box margin="0 auto" maxWidth={592} pb={6}>
    <Box textAlign="center" p={12}>
      <Box ml={-4} clone height={100}>
        <UIcoLogo />
      </Box>
    </Box>

    <Box bgcolor="neutral01.50" boxShadow={2} p={{ xs: 4, md: 8 }}>
      <Typography variant="h2" color="primary" gutterBottom>
        <FormattedMessage id="profile.settings.account_title" />
      </Typography>
      <Typography variant="body1" color="textPrimary" gutterBottom>
        <FormattedMessage id="profile.complete.description" />
      </Typography>
      <CompleteProfileForm />
    </Box>
  </Box>
);

export default CompleteProfile;
