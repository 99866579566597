import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StoreDefaultState } from 'types/store/store.types';

import {
  EndpointsFetchFailPayload,
  EndpointsFetchSuccessPayload,
  PrivateIndex,
  PublicIndex,
} from './endpoint.types';

export type EndpointState = {
  data?: PublicIndex;
  error?: StoreDefaultState['error'];
  isLoading: StoreDefaultState['isLoading'];
  private: {
    data?: PrivateIndex;
  };
};

export const INITIAL_STATE: EndpointState = {
  data: undefined,
  error: undefined,
  isLoading: false,
  private: {
    data: undefined,
  },
};

const endpointsSlice = createSlice({
  name: 'endpoints',
  initialState: INITIAL_STATE,
  reducers: {
    endpointsFetchStart: state => {
      state.isLoading = true;
    },
    endpointsFetchSuccess: (state, { payload }: PayloadAction<EndpointsFetchSuccessPayload>) => {
      state.isLoading = false;
      state.data = payload.data;
      state.private.data = payload.privateEndpointsData;
    },
    endpointsFetchFail: (state, { payload }: PayloadAction<EndpointsFetchFailPayload>) => {
      state.isLoading = false;
      state.error = payload.error;
    },
  },
});

export const {
  endpointsFetchStart,
  endpointsFetchSuccess,
  endpointsFetchFail,
} = endpointsSlice.actions;

export default endpointsSlice.reducer;
