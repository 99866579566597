import React, { PureComponent } from 'react';

import { FormattedMessage } from 'react-intl';
import { components } from 'react-select';

import { LinkButton } from 'components/@common/Link';

import { MenuHeader } from './Menu.styled';

class Menu extends PureComponent {
  clearValues = event => {
    event.preventDefault();
    const { clearValue } = this.props;
    clearValue();
  };
  render() {
    const {
      children,
      hasValue,
      selectProps: { customHeader, clearValueClick, hasHeader },
    } = this.props;
    return (
      <components.MenuList {...this.props}>
        {hasValue && hasHeader && (
          <MenuHeader
            innerProps={{ ...this.props.innerProps, 'data-test-id': 'select-menu-header' }}
          >
            {customHeader || (
              <LinkButton onClick={clearValueClick || this.clearValues}>
                <FormattedMessage id="general.select.clear_selection" />
              </LinkButton>
            )}
          </MenuHeader>
        )}
        {children}
      </components.MenuList>
    );
  }
}
export default Menu;
