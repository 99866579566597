import * as Yup from 'yup';

export const RemarkDataFormValidation = Yup.object().shape({
  remark: Yup.string()
    .typeError('general.errors.required')
    .required('general.errors.required')
    .max(2000, params => ({
      id: 'general.errors.max',
      values: { max: params.max },
    })),
});
