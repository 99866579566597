import { sanitizeFilters } from 'hooks/useFilter/useFilter';
import { call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects';

import { apiService } from 'services';
import { getGroupId } from 'store/client/client.selectors';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';

import { getNormalizedJsonApi } from 'utils/jsonapi';

import {
  insurantsFetchFail,
  insurantsFetchStart,
  insurantsFetchSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './affiliations.slice';
import { InsurantData } from './affiliations.types';

function* fetchAffiliationsFlow({ payload }: ReturnType<typeof insurantsFetchStart>) {
  try {
    const groupId = yield select(getGroupId);
    if (groupId) {
      const { filters } = payload;

      const endpoint: string = yield select(getEndpoint, 'insurants');

      const response: SagaReturnType<typeof apiService.affiliations.fetchInsurants> = yield call(
        [apiService, apiService.affiliations.fetchInsurants],
        endpoint,
        sanitizeFilters(filters),
      );

      const normalized = getNormalizedJsonApi<typeof response, { insurant: InsurantData }>(
        response,
      );

      yield put(
        insurantsFetchSuccess({
          data: normalized.entities?.insurant ?? {},
          result: normalized.result?.insurant ?? [],
          meta: response?.meta,
          links: response?.links,
        }),
      );
    }
  } catch (error) {
    yield put(insurantsFetchFail({ error }));
  }
}

function* affiliationsSaga() {
  yield takeLatest(insurantsFetchStart, fetchAffiliationsFlow);
}

export default affiliationsSaga;
