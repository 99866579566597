import React from 'react';

import { Box, Grid } from '@material-ui/core';
import { useField } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { ClientReportingCategory } from 'store/client/client.types';

import { FlowType } from 'pages/affiliations/Create/AffiliationsCreate';

import { CheckboxField } from 'components/@inputs/Checkbox';
import { DateInputField } from 'components/@inputs/DateInput';
import { NumberField } from 'components/@inputs/Number';
import { RadioGroupField } from 'components/@inputs/RadioGroup';
import { SelectField } from 'components/@inputs/Select';
import { InputField } from 'components/@inputs/TextField';

interface Props {
  flowType: FlowType;
  reportingCategories: (ClientReportingCategory | null)[];
  isEdit?: boolean;
  hasIdentificationNumber?: boolean;
}

export type BaseDataFormValues = {
  firstName?: string;
  lastName?: string;
  personnelNumber?: string;
  personnelNumberUnknown?: boolean;
  birthDate?: string;
  gender?: string;
  identificationNumber?: string;
  communicationLanguage?: {
    value: string;
    label?: React.ReactNode;
  };
  reportingCategories?: ({
    value: {
      categoryId: string;
      categoryOptionId: string;
      clientReportingCategoryTitle: string;
    };
    label: string;
  } | null)[];
  insurantType?: {
    value: 'MAIN' | 'PARTNER' | 'CHILD';
    label: string;
  };
};

export const genders: { [gender: string]: { value: string; label: React.ReactNode } } = {
  M: {
    value: 'M',
    label: <FormattedMessage id="general.gender_male" />,
  },
  V: {
    value: 'V',
    label: <FormattedMessage id="general.gender_female" />,
  },
  X: {
    value: 'X',
    label: <FormattedMessage id="general.gender_other" />,
  },
};

export const languages: { [gender: string]: { value: string; label: string } } = {
  en: { label: 'profile.settings.language.en', value: 'en' },
  nl: { label: 'profile.settings.language.nl', value: 'nl' },
  fr: { label: 'profile.settings.language.fr', value: 'fr' },
  de: { label: 'profile.settings.language.de', value: 'de' },
};

const BaseDataForm: React.FC<Props> = ({
  flowType,
  reportingCategories,
  isEdit,
  hasIdentificationNumber,
}) => {
  const intl = useIntl();
  const [personelNumberUnknown] = useField('personnelNumberUnknown');

  return (
    <>
      <Box mb={6}>
        <Grid container spacing={8}>
          <Grid item xs={12} md={6}>
            <InputField
              isRequired
              name="firstName"
              label="affiliations.add.step.base_data.firstName_label"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              isRequired
              name="lastName"
              label="affiliations.add.step.base_data.lastName_label"
              fullWidth
            />
          </Grid>
          {flowType === 'MAIN' && (
            <Grid item xs={12} md={6}>
              <InputField
                isRequired
                disabled={personelNumberUnknown.value}
                name="personnelNumber"
                label="affiliations.add.step.base_data.personelNumber_label"
                fullWidth
              />
              <Box mt={2}>
                <CheckboxField
                  name="personnelNumberUnknown"
                  label="affiliations.add.step.base_data.personelNumber_unknown_label"
                />
              </Box>
            </Grid>
          )}
          {isEdit && hasIdentificationNumber && (
            <Grid item xs={12} md={6}>
              <NumberField
                isRequired
                name="identificationNumber"
                label="claims.add.step.tpa_gi_employee.ssn.label"
                fullWidth
                format="##.##.##-###.##"
                mask="_"
                disabled
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <DateInputField
              isRequired
              name="birthDate"
              label="affiliations.add.step.base_data.birthDate_label"
            />
          </Grid>
          <Grid item xs={12}>
            <RadioGroupField
              name="gender"
              label="affiliations.add.step.base_data.gender_label"
              isRequired
              options={Object.values(genders)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectField
              isRequired
              name="communicationLanguage"
              label="affiliations.add.step.base_data.communicationLanguage_label"
              getOptionLabel={option =>
                intl.formatMessage({ id: `profile.settings.language.${option.value}` })
              }
              options={Object.values(languages).map(language => ({
                value: language.value,
                label: intl.formatMessage({ id: language.label }),
              }))}
              menuPortalTarget={document.body}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      {flowType === 'FAMILY' && !isEdit && (
        <Box mb={8}>
          <Grid container spacing={8}>
            <Grid item xs={12} md={6}>
              <SelectField
                isRequired
                name="insurantType"
                label="affiliations.add.step.base_data.relationship_label"
                options={[
                  {
                    value: 'PARTNER',
                    label: intl.formatMessage({
                      id: 'affiliations.add.step.base_data.relationship_option_partner',
                    }),
                  },
                  {
                    value: 'CHILD',
                    label: intl.formatMessage({
                      id: 'affiliations.add.step.base_data.relationship_option_child',
                    }),
                  },
                ]}
                menuPortalTarget={document.body}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box mb={8}>
        <Grid container spacing={8}>
          <>
            {reportingCategories.map((category, i) => (
              <Grid item sm={12} md={6} key={category?.id ?? ''}>
                <SelectField
                  isRequired
                  name={`reportingCategories[${i}]`}
                  label={category?.attributes?.title}
                  getOptionValue={option => option.value.categoryOptionId}
                  options={(category?.attributes?.options ?? []).map(option => ({
                    value: {
                      categoryId: category?.id,
                      categoryOptionId: option.id,
                      clientReportingCategoryTitle: category?.attributes?.title,
                    },
                    label: option.title,
                  }))}
                  menuPortalTarget={document.body}
                  fullWidth
                />
              </Grid>
            ))}
          </>
        </Grid>
      </Box>
    </>
  );
};

export default BaseDataForm;
