import affiliationReducer from './affiliation/affiliation.slice';
import affiliationsReducer from './affiliations/affiliations.slice';
import affiliationSubmissionReducer from './affiliationSubmission/affiliationSubmission.slice';
import authReducer from './auth/auth.slice';
import avatarsReducer from './avatars/avatars.reducer';
import bannerReducer from './banner/banner.reducer';
import blockNavigationReducer from './blockNavigation/blockNavigation.reducer';
import categoriesReducer from './categories/categories.slice';
import claimReportsReducer from './claimReports/claimReports.slice';
import claimSubmissionReducer from './claimSubmission/claimSubmission.reducer';
import clientReducer from './client/client.reducer';
import dashboardReducer from './dashboard/dashboard.slice';
import dataShareReducer from './dataShare/dataShare.slice';
import dialogReducer from './dialog/dialog.reducer';
import documentReducer from './document/document.slice';
import endpointReducer from './endpoint/endpoint.slice';
import feedbackReducer from './feedback/feedback.reducer';
import fileReducer from './file/file.reducer';
import fileSubmissionReducer from './fileSubmission/fileSubmission.reducer';
import impersonateReducer from './impersonate/impersonate.slice';
import intlReducer from './intl/intl.reducer';
import invoiceReducer from './invoice/invoice.reducer';
import invoicesReducer from './invoices/invoices.slice';
import modalReducer from './modal/modal.slice';
import notificationsReducer from './notifications/notifications.reducer';
import partnerReducer from './partner/partner.slice';
import policyReducer from './policy/policy.slice';
import publicationReducer from './publications/publications.slice';
import riskReducer from './risk/risk.slice';
import { reducer as routingReducer } from './routing/routing.reducer';
import systemReducer from './system/system.reducer';
import tutorialReducer from './tutorial/tutorial.reducer';
import userReducer from './user/user.slice';
import usersReducer from './users/users.reducer';
import vehicleCertificatesReducer from './vehicleCertificates/vehicleCertificates.slice';

const reducers = {
  system: systemReducer,
  intl: intlReducer,
  endpoints: endpointReducer,
  auth: authReducer,
  location: routingReducer,
  user: userReducer,
  users: usersReducer,
  tutorial: tutorialReducer,
  dialog: dialogReducer,
  document: documentReducer,
  file: fileReducer,
  modal: modalReducer,
  client: clientReducer,
  feedback: feedbackReducer,
  claimSubmission: claimSubmissionReducer,
  policy: policyReducer,
  risk: riskReducer,
  dataShare: dataShareReducer,
  fileSubmission: fileSubmissionReducer,
  dashboard: dashboardReducer,
  partner: partnerReducer,
  invoices: invoicesReducer,
  invoice: invoiceReducer,
  avatars: avatarsReducer,
  navigationBlock: blockNavigationReducer,
  categories: categoriesReducer,
  notifications: notificationsReducer,
  banner: bannerReducer,
  affiliations: affiliationsReducer,
  impersonate: impersonateReducer,
  vehicleCertificates: vehicleCertificatesReducer,
  affiliationSubmission: affiliationSubmissionReducer,
  affiliation: affiliationReducer,
  claimReports: claimReportsReducer,
  publications: publicationReducer,
};

export default reducers;
