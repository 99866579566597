import styled from 'styled-components';

import { placeholderShimmer } from './animations.styled';

export const Wrap = styled.div<{ hasIcon?: boolean }>`
  display: flex;
  align-items: center;
  padding-left: ${({ hasIcon }) => (hasIcon ? `16px` : 0)};
`;

export const Circle = styled.div<{ size?: number }>`
  width: ${({ theme, size }) => size || theme.spacing(16)}px;
  height: ${({ theme, size }) => size || theme.spacing(16)}px;
  border-radius: 50%;

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeholderShimmer};
  animation-timing-function: linear;
  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.palette.neutral01[400]} 8%, ${theme.palette.neutral01[200]}  18%, ${theme.palette.neutral01[400]} 33%)`};
  background-size: 800px 104px;
`;

export const Lines = styled.div<{ hasIcon?: boolean }>`
  margin-left: ${({ hasIcon }) => (hasIcon ? `16px` : 0)};
`;
