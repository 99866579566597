import React, { Fragment } from 'react';

import { AvatarLoader } from 'components/Loaders';

import { AvatarWrap } from './Experts.styled';

const ExpertsLoading: React.FC = () => (
  <Fragment>
    <AvatarWrap>
      <AvatarLoader />
    </AvatarWrap>
    <AvatarWrap>
      <AvatarLoader />
    </AvatarWrap>
    <AvatarWrap>
      <AvatarLoader />
    </AvatarWrap>
  </Fragment>
);

export default ExpertsLoading;
