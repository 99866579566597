import React from 'react';

import MUIButton from '@material-ui/core/Button';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Props } from './Button';

const baseStyle = css<Pick<Props, 'isLoading'>>`
  align-items: center;
  border-radius: 4px;
  padding: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(5)}px`};
  height: 48px;
  line-height: 1.6;
  display: inline-block;
  font-weight: 700;
  text-transform: none;
  max-width: 100%;
  border: 0;
  transition: all 150ms ease-in-out;
  ${({ isLoading }) => (isLoading ? 'pointer-events: none' : '')};
  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const loadingStyle = (bg: string) => css`
  background-color: ${transparentize(0.2, bg)};
  cursor: wait;
  &:hover {
    background-color: ${transparentize(0.2, bg)};
  }
`;

export const BaseButton = styled(({ isLoading, ...rest }) => <MUIButton disableRipple {...rest} />)`
  && {
    ${baseStyle};
    color: ${({ theme }) => theme.palette.neutral01[50]};
    background-color: ${({ theme }) => theme.palette.brand01[300]};
    ${({ isLoading, theme }) => (isLoading ? loadingStyle(theme.palette.brand01[300]) : '')};
    &:hover {
      background-color: ${({ theme }) => theme.palette.brand01[500]};
    }
  }
`;

export const DangerButton = styled(BaseButton)`
  && {
    color: ${({ theme }) => theme.palette.neutral01[50]};
    background-color: ${({ theme }) => theme.palette.accent02[400]};
  }
`;

export const SecondaryButton = styled(BaseButton)`
  && {
    background-color: ${({ theme }) => theme.palette.brand01[800]};
    color: ${({ theme }) => theme.palette.brand01[300]};
    line-height: 1.3;
    ${({ disabled }) =>
      disabled
        ? css`
            color: ${({ theme }) => theme.palette.neutral01[400]} !important;
            background-color: ${({ theme }) => theme.palette.neutral01[200]} !important;
          `
        : ''};

    &:hover {
      color: ${({ theme }) => theme.palette.brand01[500]};
      background-color: ${({ theme }) => theme.palette.brand01[800]};
    }
    &:active {
      color: ${({ theme }) => theme.palette.brand01[500]};
      background-color: ${({ theme }) => theme.palette.brand01[800]};
    }
  }
`;

export const LoaderWrap = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContent = styled.span``;
