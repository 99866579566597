import dayjs from 'dayjs';
import * as Yup from 'yup';

import { validateSSN } from 'utils/helpers';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().min(1),
  surName: Yup.string().min(1),
  personnelNumber: Yup.string().min(1),
  birthDate: Yup.date()
    .typeError('form.error.date.invalid')
    .max(dayjs(new Date()).format('YYYY-MM-DD'), params => ({
      id: 'general.errors.date.max',
      values: {
        ...params,
        date: dayjs(new Date(params.max)).format('DD/MM/YYYY'),
      },
    })),
  identificationNumber: Yup.string()
    .min(11, 'general.errors.ssn')
    .test('id-number', 'general.errors.ssn', value => {
      if ((value || '').length < 11) {
        return true;
      }
      return validateSSN(value);
    }),
});

export { ValidationSchema };
