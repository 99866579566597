import { call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects';

import { apiService } from 'services';
import { getPathParam } from 'store/routing/routing.selectors';

import { getPartnerContactsEndpoint, getPartnerEndpoint } from './partner.selectors';
import {
  partnerContactsFetchFail,
  partnerContactsFetchStart,
  partnerContactsFetchSuccess,
  partnerFetchFail,
  partnerFetchStart,
  partnerFetchSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './partner.slice';

function* partnerFetchFlow({ payload }: ReturnType<typeof partnerFetchStart>) {
  try {
    const { id } = payload;
    const path = yield select(getPartnerEndpoint);
    const partner: SagaReturnType<typeof apiService.partners.fetchAcmaClient> = yield call(
      [apiService, apiService.partners.fetchAcmaClient],
      `${path}/${id}`,
    );
    yield put(partnerFetchSuccess(partner));
  } catch (error) {
    yield put(partnerFetchFail({ error }));
  }
}

function* partnerContactsFetchFlow({ payload }: ReturnType<typeof partnerContactsFetchStart>) {
  try {
    const { filters = {} } = payload;
    const path: SagaReturnType<typeof getPartnerContactsEndpoint> = yield select(
      getPartnerContactsEndpoint,
    );
    const data: SagaReturnType<typeof apiService.partners.fetchAcmaClientContacts> = yield call(
      [apiService, apiService.partners.fetchAcmaClientContacts],
      path,
      filters,
    );
    yield put(partnerContactsFetchSuccess(data));
  } catch (error) {
    yield put(partnerContactsFetchFail({ error }));
  }
}

// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

export function* bootPartner() {
  const id = yield select(getPathParam);
  yield put(partnerFetchStart({ id }));
}

function* partnerSaga() {
  yield takeLatest(partnerContactsFetchStart, partnerContactsFetchFlow);
  yield takeLatest(partnerFetchStart, partnerFetchFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default partnerSaga;
