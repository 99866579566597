import * as Yup from 'yup';

import { ContainerProps } from './DocumentsStepContainer';

export const FileValidation = (fileConfig: ContainerProps['fileConfig']) => {
  return Yup.object().shape({
    files: Yup.array().of(
      Yup.mixed()
        .fileSize(fileConfig?.maxFileSize ?? 0, params => ({
          id: 'general.errors.file_size',
          values: { ...params },
        }))
        .fileType(fileConfig?.allowedFileTypes ?? [], params => ({
          id: 'general.errors.file_type',
          values: { ...params },
        })),
    ),
  });
};

export const ValidationSchema = Yup.object().shape({
  remark: Yup.string().max(2000, params => ({
    id: 'general.errors.max',
    values: { max: params.max },
  })),
});
