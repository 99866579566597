import { useAsyncDebounce } from 'hooks/useAsyncDebounce/useAsyncDebounce';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { queryKeys } from 'constants/querykeys';
import { apiService } from 'services';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { Store } from 'types/store/store.types';

const useLocationStreets = (locationId: string, searchValue: string = '') => {
  const endpoint = useSelector((state: Store) => getEndpoint(state, 'locations'));

  const locationsQuery = useQuery(
    [queryKeys.locations, searchValue],
    useAsyncDebounce(
      () => apiService.locations.fetchLocations(`${endpoint}/${locationId}/streets`, searchValue),
      400,
    ),
    {
      retry: 0,
      enabled: !!endpoint && !!locationId && searchValue.length >= 3,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );
  return locationsQuery;
};

export default useLocationStreets;
