import { DEFAULT_PARAMS } from 'constants/default.queryparams';

export const STATUSES = {
  OPEN: { intlKey: 'invoices.filter.dropdowns.open', badgeColor: 'default' },
  PARTIALLY_PAID: { intlKey: 'invoices.filter.dropdowns.partially_paid', badgeColor: 'warning' },
  CANCELLATION: { intlKey: 'invoices.filter.dropdowns.cancellation', badgeColor: 'default' },
  PAID: {
    intlKey: 'invoices.filter.dropdowns.paid',
    badgeColor: 'success',
  },
  INNING_INSURER: { intlKey: 'invoices.filter.dropdowns.inning_insurer', badgeColor: 'default' },
  CANCELLATION_ENTRY: {
    intlKey: 'invoices.filter.dropdowns.cancellation_entry',
    badgeColor: 'default',
  },
  OVERDUE: { intlKey: 'invoices.filter.dropdowns.overdue', badgeColor: 'alert' },
};

export const QUERY_PARAMS = {
  DATE_FROM: 'dateFrom',
  DATE_UNTIL: 'dateUntil',
  TYPE: 'invoiceCategoryIds',
  STATUS: 'status',
  ...DEFAULT_PARAMS,
};
