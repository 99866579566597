import { ReactComponent as UIcoAuditrapporten } from 'assets/svg/u_ico_auditrapporten.svg';
import { ReactComponent as UIcoBenchmarks } from 'assets/svg/u_ico_benchmarks.svg';
import { ReactComponent as UIcoFileText } from 'assets/svg/u_ico_file_text.svg';
import { ReactComponent as UIcoSFallback } from 'assets/svg/u_ico_s_fallback.svg';
import { ReactComponent as UIcoWhitepapers } from 'assets/svg/u_ico_whitepapers.svg';

import { DEFAULT_PARAMS } from 'constants/default.queryparams';
export const QUERY_PARAMS = {
  ...DEFAULT_PARAMS,
  PUBLICATION_DATE_FROM: 'publicationDateFrom',
  PUBLICATION_DATE_UNTIL: 'publicationDateUntil',
  PUBLICATION_CATEGORY_ID: 'publicationCategoryId',
  PERIOD: 'period',
};

export const TYPES = {
  ALL: {
    icon: UIcoFileText,
  },
  DEFAULT: {
    icon: UIcoSFallback,
  },
  BENCHMARKS: {
    icon: UIcoBenchmarks,
  },
  AUDIT_REPORTS: {
    icon: UIcoAuditrapporten,
  },
  WHITEPAPERS: {
    icon: UIcoWhitepapers,
  },
};
