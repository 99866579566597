import * as Yup from 'yup';

const ContactDataValidationSchema = Yup.object().shape({
  emailProfessional: Yup.string()
    .trim()
    .email('general.errors.email')
    .max(60, params => ({
      id: 'general.errors.max',
      values: { max: params.max },
    })),
  phoneMobile: Yup.string()
    .max(60, params => ({
      id: 'general.errors.max',
      values: { max: params.max },
    }))
    .matches(/^(\+)?([ 0-9]){1,60}$/, 'general.errors.phone'),
  phoneLandline: Yup.string()
    .max(60, params => ({
      id: 'general.errors.max',
      values: { max: params.max },
    }))
    .matches(/^(\+)?([ 0-9]){1,60}$/, 'general.errors.phone'),
});

export { ContactDataValidationSchema };
