import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InsurantData } from 'store/affiliations/affiliations.types';
import {
  AffiliationPolicy,
  InsurantAffiliation,
} from 'store/affiliationSubmission/affiliationSubmission.types';
import { StoreDefaultState } from 'types/store/store.types';

import {
  AddFamilyMemberPayload,
  AffiliationPoliciesFetchStartPayload,
  AffiliationPoliciesFetchSuccessPayload,
  AffiliationsFetchStartPayload,
  AffiliationsFetchSuccessPayload,
  FamilyMembersFetchStartPayload,
  FamilyMembersFetchSuccessPayload,
  InsurantEditStartPayload,
  InsurantEditSuccessPayload,
  InsurantFetchStartPayload,
  InsurantFetchSuccessPayload,
  UpdateAffiliationStartPayload,
  UpdateAffiliationSuccessPayload,
} from './affiliation.types';

export const affiliationAdapter = createEntityAdapter<InsurantAffiliation>();
export const affiliationPoliciesAdapter = createEntityAdapter<AffiliationPolicy>();
export const insurantAdapter = createEntityAdapter<InsurantData>();

export type AffiliationState = ReturnType<typeof insurantAdapter.getInitialState> & {
  status?: 'loading' | 'editing' | 'error';
  error: StoreDefaultState['error'];
  familyMembers: {
    result: { [insurantId: string]: string[] };
    status?: 'loading';
    error: StoreDefaultState['error'];
  };
  affiliations: ReturnType<typeof affiliationAdapter.getInitialState> & {
    status?: 'loading' | 'updating';
    error: StoreDefaultState['error'];
    result: string[];
  };
  affiliationPolicies: ReturnType<typeof affiliationPoliciesAdapter.getInitialState> & {
    status?: 'loading';
    error: StoreDefaultState['error'];
  };
};

export const INITIAL_STATE: AffiliationState = insurantAdapter.getInitialState({
  error: undefined,
  status: undefined,
  familyMembers: {
    result: {},
    error: undefined,
    status: undefined,
  },
  affiliations: affiliationAdapter.getInitialState({
    status: undefined,
    error: undefined,
    result: [],
  }),
  affiliationPolicies: affiliationPoliciesAdapter.getInitialState({
    status: undefined,
    error: undefined,
  }),
});

const affiliationSlice = createSlice({
  name: 'affiliation',
  initialState: INITIAL_STATE,
  reducers: {
    insurantFetchStart: (state, _action: PayloadAction<InsurantFetchStartPayload>) => {
      state.status = 'loading';
      state.error = undefined;
    },
    insurantFetchSuccess: (state, { payload }: PayloadAction<InsurantFetchSuccessPayload>) => {
      state.status = undefined;
      state.error = undefined;
      insurantAdapter.upsertMany(state, payload.data);
    },
    insurantFetchFail: (
      state,
      { payload }: PayloadAction<{ error: AffiliationState['error'] }>,
    ) => {
      state.error = payload.error;
      state.status = 'error';
    },
    familyMembersFetchStart: (state, _action: PayloadAction<FamilyMembersFetchStartPayload>) => {
      state.familyMembers.status = 'loading';
      state.familyMembers.error = undefined;
    },
    familyMembersFetchSuccess: (
      state,
      { payload }: PayloadAction<FamilyMembersFetchSuccessPayload>,
    ) => {
      state.familyMembers.status = undefined;
      state.familyMembers.error = undefined;
      state.familyMembers.result = { ...state.familyMembers.result, ...payload.result };
    },
    familyMembersFetchFail: (
      state,
      { payload }: PayloadAction<AffiliationState['familyMembers']['error']>,
    ) => {
      state.familyMembers.error = payload;
      state.familyMembers.status = undefined;
    },
    insurantEditStart: (state, _action: PayloadAction<InsurantEditStartPayload>) => {
      state.status = 'editing';
    },
    insurantEditSuccess: (state, { payload }: PayloadAction<InsurantEditSuccessPayload>) => {
      state.status = undefined;
      insurantAdapter.updateOne(state, {
        id: payload.data.id,
        changes: payload.data,
      });
    },
    insurantEditFail: (state, _action: PayloadAction<{ error: AffiliationState['error'] }>) => {
      state.status = undefined;
    },
    addFamilyMember: (_state, _action: PayloadAction<AddFamilyMemberPayload>) => {},
    affiliationsFetchStart: (state, _action: PayloadAction<AffiliationsFetchStartPayload>) => {
      state.affiliations.status = 'loading';
      state.affiliations.result = [];
    },
    affiliationsFetchSuccess: (
      state,
      { payload }: PayloadAction<AffiliationsFetchSuccessPayload>,
    ) => {
      affiliationAdapter.upsertMany(state.affiliations, payload.entities.insurantAffiliation ?? {});
      affiliationPoliciesAdapter.upsertMany(
        state.affiliationPolicies,
        payload.entities.hcPolicy ?? {},
      );
      state.affiliations.error = undefined;
      state.affiliations.status = undefined;
      state.affiliations.result = payload.result.insurantAffiliation ?? [];
    },
    affiliationsFetchFail: (
      state,
      { payload }: PayloadAction<{ error: AffiliationState['affiliations']['error'] }>,
    ) => {
      state.affiliations.error = payload.error;
      state.affiliations.status = undefined;
    },
    affiliationPoliciesFetchStart: (
      state,
      _action: PayloadAction<AffiliationPoliciesFetchStartPayload>,
    ) => {
      state.affiliationPolicies.status = 'loading';
    },
    affiliationPoliciesFetchSuccess: (
      state,
      { payload }: PayloadAction<AffiliationPoliciesFetchSuccessPayload>,
    ) => {
      state.affiliationPolicies.status = undefined;
      state.affiliationPolicies.error = undefined;
      affiliationPoliciesAdapter.upsertMany(state.affiliationPolicies, payload.entities ?? {});
    },
    affiliationPoliciesFetchFail: (
      state,
      { payload }: PayloadAction<{ error: AffiliationState['affiliationPolicies']['error'] }>,
    ) => {
      state.affiliationPolicies.error = payload.error;
      state.affiliationPolicies.status = undefined;
    },
    affiliationUpdateStart: (state, _action: PayloadAction<UpdateAffiliationStartPayload>) => {
      state.affiliations.status = 'updating';
    },
    affiliationUpdateSuccess: (
      state,
      { payload }: PayloadAction<UpdateAffiliationSuccessPayload>,
    ) => {
      state.affiliations.status = undefined;
      affiliationAdapter.updateOne(state.affiliations, {
        id: payload.affiliation.id,
        changes: payload.affiliation,
      });
    },
    affiliationUpdateFail: (
      state,
      { payload }: PayloadAction<{ error: AffiliationState['affiliationPolicies']['error'] }>,
    ) => {
      state.affiliations.status = undefined;
      state.affiliationPolicies.error = payload.error;
    },
  },
});

export const {
  insurantFetchStart,
  insurantFetchSuccess,
  insurantFetchFail,
  familyMembersFetchStart,
  familyMembersFetchSuccess,
  familyMembersFetchFail,
  insurantEditStart,
  insurantEditSuccess,
  insurantEditFail,
  addFamilyMember,
  affiliationsFetchStart,
  affiliationsFetchSuccess,
  affiliationsFetchFail,
  affiliationPoliciesFetchStart,
  affiliationPoliciesFetchSuccess,
  affiliationPoliciesFetchFail,
  affiliationUpdateStart,
  affiliationUpdateSuccess,
  affiliationUpdateFail,
} = affiliationSlice.actions;

export default affiliationSlice.reducer;
