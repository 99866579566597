import React from 'react';

import { Formik } from 'formik';
import { connect } from 'react-redux';

import { getAffiliationSubmissionData } from 'store/affiliationSubmission/affiliationSubmission.selectors';
import { Store } from 'types/store/store.types';

import { AdditionalInfoFormValidation, AdditionalInfoFormValues } from 'pages/affiliations/Forms';

import AdditionalInfoStep from './AdditionalInfoStep';

export type FormValues = AdditionalInfoFormValues;

type Props = {
  onNextStepClick: (values: FormValues) => void;
  initialValues: FormValues;
};
type ChildProps = Omit<
  React.ComponentProps<typeof AdditionalInfoStep>,
  'wageType' | 'wageDecimals'
>;

const AdditionalInfoStepContainer: React.FC<Props & ContainerProps & ChildProps> = ({
  onNextStepClick,
  initialValues,
  saveForm,
  data,
}) => {
  const wageType = initialValues.wageType || 'MONTHLY';
  const wageDecimals = wageType === 'MONTHLY' ? 2 : 4;
  const employmentConditions = initialValues.employmentConditions;

  return (
    <Formik<FormValues>
      validateOnMount
      validationSchema={AdditionalInfoFormValidation(
        !!initialValues.requiresSalaryData,
        !!initialValues.requiresIdentificationNumber,
        employmentConditions,
      )}
      initialValues={{
        identificationNumber: data?.attributes?.identificationNumber?.toString(),
        grossSalary: initialValues.grossSalary?.toString(),
        employmentPercentage: initialValues.employmentPercentage?.toString(),
        ...initialValues,
      }}
      onSubmit={values => {
        onNextStepClick({
          wageType,
          ...values,
        });
      }}
    >
      <AdditionalInfoStep
        wageType={wageType}
        wageDecimals={wageDecimals}
        employmentConditions={employmentConditions}
        saveForm={saveForm}
      />
    </Formik>
  );
};

const mapStateToProps = (state: Store) => ({
  data: getAffiliationSubmissionData(state),
});
const mapDispatchToProps = {};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type MapDispatchProps = typeof mapDispatchToProps;
export type ContainerProps = MapStateProps & MapDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfoStepContainer);
