import { createReducer } from 'redux-act';

import { addUsersToState } from './users.actions';
import { UsersState } from './users.types';

export const INITIAL_STATE = {
  data: undefined,
};

const reducer = createReducer<UsersState>({}, INITIAL_STATE);

reducer.on(addUsersToState, (state, { users }) => ({
  ...state,
  data: {
    ...state.data,
    ...users,
  },
}));

export default reducer;
