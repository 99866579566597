import MuiStepConnector from '@material-ui/core/StepConnector';
import MuiStepContent from '@material-ui/core/StepContent';
import MuiStepIcon from '@material-ui/core/StepIcon';
import MuiStepLabel from '@material-ui/core/StepLabel';
import MuiStepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';

import { LinkButton } from 'components/@common/Link';

const ICON_SIZE = 33;

export const Steps = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiStepper);

export const StepContent = withStyles(theme => ({
  root: {
    marginTop: 0,
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(2.5) + ICON_SIZE,
    paddingRight: 0,
    marginLeft: ICON_SIZE / 2 - 1,
    borderLeft: `1px solid ${theme.palette.neutral01[300]}`,
  },
  last: {
    borderLeft: 'none',
  },
}))(MuiStepContent);

export const StepLabel = withStyles(theme => ({
  iconContainer: {
    paddingRight: ICON_SIZE / 2 - 1,
  },
  labelContainer: {
    paddingLeft: theme.spacing(3),
  },
}))(MuiStepLabel);

export const StepConnector = withStyles(theme => ({
  root: {
    padding: 0,
    marginLeft: ICON_SIZE / 2 - 1,
  },
  line: {
    borderColor: theme.palette.neutral01[300],
  },
}))(MuiStepConnector);

export const StepIcon = withStyles(theme => ({
  root: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}))(MuiStepIcon);

const activeIconStyle = css`
  width: ${ICON_SIZE + 6}px;
  height: ${ICON_SIZE + 6}px;
  margin-left: -3px;
  margin-right: -3px;
  background: ${({ theme }) => theme.palette.brand01[300]};
  color: ${({ theme }) => theme.palette.neutral01[50]};
`;

const defaultIconStyle = css`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  background: ${({ theme }) => theme.palette.brand01[800]};
  color: ${({ theme }) => theme.palette.brand01[400]};
`;

const completedIconStyle = css`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  background: ${({ theme }) => theme.palette.accent01[400]};
  & * {
    color: ${({ theme }) => theme.palette.neutral01[50]};
    stroke: ${({ theme }) => theme.palette.neutral01[50]};
  }
`;

export const DefaultStepIcon = styled.div<{ active: boolean; completed: boolean }>`
  display: inline-flex;
  transition: all 0.3s;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 700;
  ${({ active, completed }) => {
    if (active) {
      return activeIconStyle;
    }
    if (completed) {
      return completedIconStyle;
    }
    return defaultIconStyle;
  }};
`;

export const StepLabelInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StepLabelText = styled.div<{ active: boolean; completed: boolean }>`
  font-size: 20px;
  line-height: 1.4;
  font-weight: 700;
  color: ${({ active, completed, theme }) => {
    if (active) {
      return `${theme.palette.brand01[300]}`;
    }
    if (completed) {
      return `${theme.palette.neutral01[600]}`;
    }
    return `${theme.palette.neutral01[500]}`;
  }};
`;

export const StepValue = styled.span`
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.neutral01[600]};
  text-align: right;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

export const ModifyLink = styled(LinkButton)`
  margin-left: ${({ theme }) => theme.spacing(1) * 3}px;
`;

export const TopContentWrap = styled.div`
  display: block;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: none;
  }
`;
