import { InvoicesResponse } from 'store/invoices/invoices.types';

import { ApiInstanceType } from './';

class InvoicesApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchInvoices = (path: string, filters: { [index: string]: string }) =>
    this.api
      .get<InvoicesResponse>(path, {
        params: filters,
      })
      .then(response => response.data);

  exportInvoices = (path: string, filters: { [index: string]: string }) =>
    this.api
      .get<Blob>(path, {
        cache: {
          maxAge: 0,
          ignoreCache: true,
        },
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'blob',
        params: filters,
      })
      .then(response => response);
}

export default InvoicesApi;
