import styled, { css } from 'styled-components';

export const PageActionsWrap = styled.div<{ isSmall?: boolean }>`
  display: flex;
  justify-content: center;
  position: absolute;
  height: 24px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${({ isSmall, theme }) => (isSmall ? `calc(100% - ${theme.spacing(1) * 8}px)` : 'auto')};
`;

const hoverStyle = css`
  color: ${({ theme }) => theme.palette.brand01[400]};
  svg: {
    stroke: ${({ theme }) => theme.palette.brand01[500]};
  }
`;

const disabledStyle = css`
  color: ${({ theme }) => theme.palette.neutral01[300]};
`;

export const NavigationButton = styled.button<{ left?: boolean; disabled?: boolean }>`
  border: 0;
  background: 0;
  outline: 0;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.brand01[200]};
  padding: 0;
  margin: ${({ theme, left }) =>
    left ? `0 ${theme.spacing(1) * 2}px 0 0` : `0 0 0 ${theme.spacing(1) * 2}px`};
  &:hover,
  &:active {
    ${({ disabled }) => (!disabled && hoverStyle) || ''};
  }
  &:last-child {
    margin: 0;
  }
  ${({ disabled }) => (disabled && disabledStyle) || ''};
`;

export const NavigationIndicator = styled.div`
  margin: 0 auto;
  width: calc(100% - ${({ theme }) => theme.spacing(1) * 2}px);
  text-align: center;
`;
