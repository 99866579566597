import React from 'react';

import { FormattedMessage } from 'react-intl';

import { SidebarBlock, SideBarTitle } from './SidebarLeft.styled';

export interface SidebarContentProps {
  title?: string;
  marginTop?: number;
  stayVisible?: boolean;
  inverted?: boolean;
}

const SidebarContentBlock: React.FC<SidebarContentProps> = ({
  title,
  children,
  marginTop,
  inverted = false,
  ...rest
}) => (
  <SidebarBlock marginTop={marginTop} {...rest}>
    {title && (
      <SideBarTitle $inverted={inverted}>
        <FormattedMessage id={title} />
      </SideBarTitle>
    )}
    <div>{children}</div>
  </SidebarBlock>
);

SidebarContentBlock.defaultProps = {
  marginTop: 24,
};

export default SidebarContentBlock;
