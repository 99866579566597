import { call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects';

import { apiService } from 'services';
import { setInsurants } from 'store/affiliations/affiliations.slice';
import { InsurantData } from 'store/affiliations/affiliations.types';
import {
  createInsurantStart,
  restartAffiliationSubmission,
} from 'store/affiliationSubmission/affiliationSubmission.slice';
import {
  AffiliationPolicy,
  InsurantAffiliation,
} from 'store/affiliationSubmission/affiliationSubmission.types';
import { insurantToInitialStepperState } from 'store/affiliationSubmission/sanitizeData';
import { clientReportingCategoriesFetchStart } from 'store/client/client.actions';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { getIntl } from 'store/intl/intl.selectors';
import { addNotification } from 'store/notifications/notifications.actions';

import { getNormalizedJsonApi } from 'utils/jsonapi';

import { getAffiliationEndpoint } from './affiliation.selectors';
import {
  addFamilyMember,
  affiliationPoliciesFetchFail,
  affiliationPoliciesFetchStart,
  affiliationPoliciesFetchSuccess,
  affiliationsFetchFail,
  affiliationsFetchStart,
  affiliationsFetchSuccess,
  affiliationUpdateFail,
  affiliationUpdateStart,
  affiliationUpdateSuccess,
  familyMembersFetchFail,
  familyMembersFetchStart,
  familyMembersFetchSuccess,
  insurantEditFail,
  insurantEditStart,
  insurantEditSuccess,
  insurantFetchFail,
  insurantFetchStart,
  insurantFetchSuccess,
} from './affiliation.slice';

function* fetchFamilyMembersFlow({ payload }: ReturnType<typeof familyMembersFetchStart>) {
  try {
    const endpoint: SagaReturnType<typeof getAffiliationEndpoint> = yield select(
      getAffiliationEndpoint,
    );
    const response: SagaReturnType<typeof apiService.affiliations.fetchInsurants> = yield call(
      [apiService, apiService.affiliations.fetchInsurants],
      endpoint,
      { mainInsurantId: payload.insurantId },
    );
    const normalized = getNormalizedJsonApi<typeof response, { insurant: InsurantData }>(response);
    yield put(setInsurants(normalized.entities?.insurant ?? {}));
    yield put(
      familyMembersFetchSuccess({
        result: { [payload.insurantId]: normalized.result?.insurant ?? [] },
      }),
    );
  } catch (error) {
    yield put(familyMembersFetchFail(error));
  }
}

function* fetchAffiliationPoliciesFlow({
  payload,
}: ReturnType<typeof affiliationPoliciesFetchStart>) {
  try {
    const endpoint: string = yield select(getEndpoint, 'hcAffiliatePolicies');
    const response: SagaReturnType<typeof apiService.affiliation.fetchAffiliationPolicies> = yield call(
      [apiService, apiService.affiliation.fetchAffiliationPolicies],
      endpoint,
      payload.insurantId,
      payload.clientId,
    );
    const normalized = getNormalizedJsonApi<typeof response, { hcPolicy: AffiliationPolicy }>(
      response,
    );
    yield put(affiliationPoliciesFetchSuccess({ entities: normalized.entities.hcPolicy }));
  } catch (error) {
    yield put(affiliationPoliciesFetchFail({ error }));
  }
}

function* fetchAffiliationsFlow({ payload }: ReturnType<typeof affiliationsFetchStart>) {
  try {
    const response: SagaReturnType<typeof apiService.affiliation.fetchInsurantAffiliations> = yield call(
      [apiService, apiService.affiliation.fetchInsurantAffiliations],
      payload.endpoint,
    );
    const normalized = getNormalizedJsonApi<
      typeof response,
      { insurantAffiliation: InsurantAffiliation; hcPolicy: AffiliationPolicy }
    >(response);
    yield put(
      affiliationsFetchSuccess({
        entities: normalized.entities,
        result: normalized.result,
      }),
    );
  } catch (error) {
    yield put(affiliationsFetchFail({ error }));
  }
}

function* fetchInsurantFlow({ payload }: ReturnType<typeof insurantFetchStart>) {
  try {
    const { id } = payload;

    const endpoint: SagaReturnType<typeof getAffiliationEndpoint> = yield select(
      getAffiliationEndpoint,
    );

    const response: SagaReturnType<typeof apiService.affiliations.fetchInsurant> = yield call(
      [apiService, apiService.affiliations.fetchInsurant],
      endpoint,
      id,
    );
    const normalized = getNormalizedJsonApi<typeof response, { insurant: InsurantData }>(response);
    yield put(insurantFetchSuccess({ data: normalized.entities.insurant }));
    // TODO: remove any once ts is done
    yield put(
      (clientReportingCategoriesFetchStart as any)({
        clientId: response.data?.relationships?.client?.data?.id ?? '',
      }),
    );
  } catch (error) {
    yield put(insurantFetchFail({ error }));
  }
}

function* editInsurantFlow({ payload }: ReturnType<typeof insurantEditStart>) {
  try {
    const endpoint: SagaReturnType<typeof getAffiliationEndpoint> = yield select(
      getAffiliationEndpoint,
    );
    const meta = payload.verifyUnique ? { verifyUnique: true } : undefined;
    const response: SagaReturnType<typeof apiService.affiliations.updateInsurant> = yield call(
      [apiService, apiService.affiliation.update],
      `${endpoint}/${payload.body?.id}`,
      { data: payload.body, meta: meta },
    );
    if (response?.meta?.isUnique !== false) {
      yield put(
        addNotification({
          variant: 'success',
          message: 'insurant.edit.success.text',
        }),
      );
    }
    yield put(insurantEditSuccess({ data: response.data }));
    yield call(payload.callback, response);
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'insurant.edit.error.text',
      }),
    );
    yield put(insurantEditFail({ error }));
  }
}

function* addFamilyMemberFlow({ payload }: ReturnType<typeof addFamilyMember>) {
  try {
    const intl: ReturnType<typeof getIntl> = yield select(getIntl);
    const createFormBody: ReturnType<typeof createInsurantStart>['payload']['data'] = {
      type: 'insurant',
      attributes: { status: 'DRAFT' },
      relationships: {
        client: { data: { type: 'client', id: payload.insurant?.relationships?.client?.data?.id } },
        mainInsurant: {
          data: {
            id:
              payload?.insurant?.relationships?.mainInsurant?.data?.id ??
              payload.insurant?.id ??
              '',
            type: 'insurant',
          },
        },
      },
    };
    yield put(
      createInsurantStart({
        data: createFormBody,
        callback: () => {},
        shouldFetchReportingCategories: false,
      }),
    );
    yield put(
      restartAffiliationSubmission({
        type: 'FAMILY',
        values: insurantToInitialStepperState(payload.insurant, intl.locale),
      }),
    );
    if (payload.callback) {
      yield call(payload.callback);
    }
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'general.error.update.text',
      }),
    );
  }
}

function* updateAffiliationFlow({ payload }: ReturnType<typeof affiliationUpdateStart>) {
  try {
    const response: SagaReturnType<typeof apiService.affiliation.updateAffiliation> = yield call(
      [apiService, apiService.affiliation.updateAffiliation],
      payload.endpoint,
      payload.affiliation,
    );
    if (payload.callback) {
      yield call(payload.callback, response.data);
    }
    yield put(
      addNotification({
        variant: 'success',
        message: 'affiliation.edit.success.text',
      }),
    );
    yield put(affiliationUpdateSuccess({ affiliation: response.data }));
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'affiliation.edit.error.text',
      }),
    );
    yield put(affiliationUpdateFail({ error }));
  }
}

function* affiliationSaga() {
  yield takeLatest(insurantFetchStart, fetchInsurantFlow);
  yield takeLatest(familyMembersFetchStart, fetchFamilyMembersFlow);
  yield takeLatest(insurantEditStart, editInsurantFlow);
  yield takeLatest(addFamilyMember, addFamilyMemberFlow);
  yield takeLatest(affiliationsFetchStart, fetchAffiliationsFlow);
  yield takeLatest(affiliationPoliciesFetchStart, fetchAffiliationPoliciesFlow);
  yield takeLatest(affiliationUpdateStart, updateAffiliationFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default affiliationSaga;
