import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { FieldProps } from './Field';

interface Props {
  label: FieldProps['label'];
}

const FieldLabel: React.FC<Props> = ({ label }) => {
  const intl = useIntl();
  if (typeof label === 'string') {
    if (intl.messages.hasOwnProperty(label)) {
      return <FormattedMessage id={label} />;
    } else {
      return <>{label}</>;
    }
  }
  if (React.isValidElement(label)) {
    return label;
  }
  if (
    typeof label === 'object' &&
    !Array.isArray(label) &&
    typeof label !== 'function' &&
    label?.hasOwnProperty('id')
  ) {
    return <FormattedMessage {...label} />;
  }
  return null;
};

export default FieldLabel;
