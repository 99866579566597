import React, { useCallback, useEffect, useRef } from 'react';

import { ContainerProps } from './NotifierContainer';
import useNotification from './useNotification';

const Notifier: React.FC<ContainerProps> = ({ notifications, removeSnackbar }) => {
  const { enqueueSnackbar } = useNotification();

  const displayed = useRef<(string | number | undefined)[]>([]);

  const storeDisplayed = useCallback((id?: string | number) => {
    displayed.current = [...displayed.current, id];
  }, []);

  const removeDisplayed = (id?: string | number) => {
    displayed.current = displayed.current.filter(key => id !== key);
  };

  const handleClose = useCallback(
    (id?: string | number) => {
      removeSnackbar(id);
      removeDisplayed(id);
    },
    [removeSnackbar],
  );

  useEffect(() => {
    notifications.forEach(({ key, message, ...rest }) => {
      if (displayed.current.includes(key)) return;
      enqueueSnackbar(`${message}`, {
        ...rest,
        onExited: () => {
          handleClose(key);
        },
      });
      storeDisplayed(key);
    });
  }, [notifications, enqueueSnackbar, storeDisplayed, handleClose]);

  return null;
};

export default Notifier;
