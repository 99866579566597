import styled, { css } from 'styled-components';

import { H3 } from 'components/@typography/Titles';

const pageStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Wrap = styled.div<{ context?: 'inline' | 'page' | 'block' }>`
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.brand01[50]};
  ${({ context }) => {
    switch (context) {
      case 'page':
        return `${pageStyle}`;
      case 'inline':
        return `
          align-items: flex-start;
          justify-content: flex-start;
          background: none;
        `;
      default:
        return `
          align-items: center;
          justify-content: center;
          background: none;
        `;
    }
  }};
`;

const inlineStyle = css`
  flex-direction: row;
  align-self: flex-start;
  max-width: 100%;
  margin-left: ${({ theme }) => theme.spacing(1) * 2}px;
`;

const defaultStyle = css`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 auto;
`;

export const InnerWrap = styled.div<{ context?: 'inline' | 'page' | 'block' }>`
  display: flex;
  svg {
    color: ${({ theme }) => theme.palette.neutral01[400]};
  }
  ${({ context }) => {
    switch (context) {
      case 'inline':
        return inlineStyle;
      default:
        return defaultStyle;
    }
  }};
`;

export const InnerText = styled.div<{ context?: 'inline' | 'page' | 'block'; maxWidth?: string }>`
  color: ${({ theme }) => theme.palette.neutral01[400]};
  line-height: 1.5;
  font-weight: 400;
  text-align: ${({ context }) => (context === 'inline' ? 'left' : 'center')};
  font-size: ${({ context }) => (context === 'inline' ? '14px' : 'inherit')};
  margin-top: ${({ theme, context }) => (context === 'inline' ? '0' : theme.spacing(2))}px;
  margin-left: ${({ theme, context }) => (context === 'inline' ? theme.spacing(2) : '0')}px;
  max-width: ${({ context, maxWidth }) =>
    context === 'inline' ? 'none' : maxWidth || 'calc(100% - 4px)'};
`;

export const PageLoadingWrap = styled.div`
  flex: 1 auto;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${({ theme }) => theme.palette.brand01[50]};
`;

export const LoaderWrap = styled.div`
  margin-bottom: 20px;
`;

export const H3Styled = styled(H3)`
  text-align: center;
`;
