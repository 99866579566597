import styled, { css } from 'styled-components';

import { LinkButton } from 'components/@common/Link';

export const UserActionsWrap = styled.div`
  padding: ${({ theme }) => theme.spacing(4)}px 0;
`;

export const UserMail = styled.div`
  font-weight: 400;
  line-height: 1.5;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  padding: 0 ${({ theme }) => theme.spacing(4)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const hoverStyle = css`
  background: ${({ theme }) => theme.palette.neutral01[200]};
  color: ${({ theme }) => theme.palette.brand01[500]};

  svg path {
    color: ${({ theme }) => theme.palette.brand01[500]};
    stroke: ${({ theme }) => theme.palette.brand01[500]};
  }
`;

const activeStyle = css`
  color: ${({ theme }) => theme.palette.brand01[500]};
  & svg path {
    color: ${({ theme }) => theme.palette.brand01[500]};
    stroke: ${({ theme }) => theme.palette.brand01[500]};
  }
`;
const linkStyle = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ theme }) => theme.spacing(10)}px;
  padding: ${({ theme }) => theme.spacing(0, 4)};
  &:hover {
    ${hoverStyle};
  }
`;

export const LinkButtonStyled = styled(LinkButton)`
  ${linkStyle};
  ${({ isActive }) => (isActive ? activeStyle : '')};
`;

export default null;
