import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@material-ui/core';
import dayjs from 'dayjs';

import { DEFAULT_DATE_FORMAT } from 'constants/store';

import { BACKSPACE } from 'utils/keyCodes';

import Field from 'components/@inputs/Field';
import { TextInput } from 'components/@inputs/TextField';

import { Wrap } from './DatInput.styled';

export interface Props {
  label?: React.ReactNode;
  hasError?: boolean;
  isOptional?: boolean;
  id?: string;
  value?: string;
  variant?: 'filled';
  name?: string;
  onChange?: (date?: Date) => void;
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const DateInput: React.FC<Props> = ({
  value,
  hasError,
  onChange = () => {},
  onBlur = () => {},
  variant,
  ...rest
}) => {
  const dayRef = useRef<HTMLInputElement>();
  const monthRef = useRef<HTMLInputElement>();
  const yearRef = useRef<HTMLInputElement>();
  const getNodeByString = (field: string) => {
    switch (field) {
      case 'month':
        return monthRef.current;
      case 'year':
        return yearRef.current;
      case 'day':
        return dayRef.current;
      default:
        return undefined;
    }
  };
  const initialVal =
    value && dayjs(value).isValid()
      ? dayjs(value)
          .format(DEFAULT_DATE_FORMAT)
          .split('/')
      : '';
  const [day, setDay] = useState(initialVal[0] || '');
  const [month, setMonth] = useState(initialVal[1] || '');
  const [year, setYear] = useState(initialVal[2] || '');
  const onChangeRef = useRef(onChange);
  useEffect(() => {
    if (
      year &&
      year.length === 4 &&
      month &&
      parseInt(month, 10) > 0 &&
      month.length >= 1 &&
      day &&
      parseInt(day, 10) > 0 &&
      day.length >= 1
    ) {
      const date = dayjs(`${year}/${month}/${day}`);
      if (date.isValid()) {
        onChangeRef.current(date.toDate());
      } else {
        onChangeRef.current(undefined);
      }
    } else {
      onChangeRef.current(undefined);
    }
  }, [year, day, month]);

  const checkValue = (str: string, max: number): string => {
    if (str.charAt(0) !== '0' || str === '00') {
      let num = parseInt(str);
      if (isNaN(num)) return '';
      if (num <= 0) num = 1;
      if (num > max) num = max;
      str =
        num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
          ? '0' + num
          : num.toString();
    }
    return str;
  };
  let hitTimesEmpty = 0;
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === BACKSPACE) {
      e.preventDefault();
      const activeNode = document.activeElement;
      let node = undefined;
      if (activeNode) {
        if (activeNode.id === 'month' && month.length === 0) {
          if (hitTimesEmpty === 1) {
            node = getNodeByString('day');
            hitTimesEmpty = 0;
          } else {
            hitTimesEmpty += 1;
          }
        }
        if (activeNode.id === 'year' && year.length === 0) {
          if (hitTimesEmpty === 1) {
            node = getNodeByString('month');
          } else {
            hitTimesEmpty += 1;
          }
        }
        if (node) {
          node.focus();
        }
      }
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      year &&
      year.length === 4 &&
      month &&
      parseInt(month, 10) > 0 &&
      month.length >= 1 &&
      day &&
      parseInt(day, 10) > 0 &&
      day.length >= 1
    ) {
      onBlur(e);
      const date = dayjs(`${year}/${month}/${day}`);
      if (date.isValid()) {
        onChange(date.toDate());
      }
    }
  };

  return (
    <Wrap data-test-id={rest.name}>
      <Box marginRight={4} width="54px" display="inline-block">
        <Field
          hasErrors={hasError}
          isRequired
          fullWidth
          name="day"
          color="secondary"
          label="general.input.day.label"
        >
          <TextInput
            id="day"
            name="day"
            inputRef={dayRef}
            error={hasError}
            variant={variant}
            fullWidth
            value={day}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newVal = checkValue(e.target.value.substring(0, 2), 31);
              setDay(newVal);
              if (e.target.value.length >= 2 && monthRef.current) {
                monthRef.current.focus();
                if (!month.length) {
                  setMonth(e.target.value.substring(2));
                }
              }
            }}
            onBlur={handleBlur}
          />
        </Field>
      </Box>

      <Box marginRight={4} width="54px" display="inline-block">
        <Field
          hasErrors={hasError}
          isRequired
          fullWidth
          name="month"
          color="secondary"
          label="general.input.month.label"
        >
          <TextInput
            id="month"
            name="month"
            variant={variant}
            inputRef={monthRef}
            value={month}
            onKeyUp={handleKeyUp}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newVal = checkValue(e.target.value.substring(0, 2), 12);
              setMonth(newVal);
              if (e.target.value.length >= 2 && yearRef.current) {
                yearRef.current.focus();
                if (!year.length) {
                  setYear(e.target.value.substring(2));
                }
              }
            }}
            onBlur={handleBlur}
          />
        </Field>
      </Box>
      <Box marginRight={4} width="108px" display="inline-block">
        <Field
          hasErrors={hasError}
          isRequired
          fullWidth
          name="year"
          color="secondary"
          label="general.input.year.label"
        >
          <TextInput
            id="year"
            name="year"
            variant={variant}
            inputRef={yearRef}
            value={year}
            onKeyUp={handleKeyUp}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setYear(e.target.value.substring(0, 4));
            }}
            onBlur={handleBlur}
          />
        </Field>
      </Box>
    </Wrap>
  );
};

export default DateInput;
