import React from 'react';

import { EXTENSION_FILE_TYPES, FILE_TYPES } from 'constants/file';

import Icon, { IconProps } from 'components/Icon';

interface Props extends IconProps {
  extension: string;
}

const FileIcon: React.FC<Props> = ({ extension, ...rest }) => {
  const type = EXTENSION_FILE_TYPES[extension] || 'OTHER';
  const TypeIcon = FILE_TYPES[type].icon;
  return (
    <Icon className="fileIcon" spacing={16} {...rest}>
      <TypeIcon />
    </Icon>
  );
};

export default FileIcon;
