import React from 'react';

import IconInner from './Icon.styled';

export interface IconProps {
  size?: number;
  color?: string;
  cursor?: string;
  rotation?: number;
  spacing?: number;
  margin?: string;
  hoverColor?: string;
  onClick?: (event: React.MouseEvent) => void;
  focusable?: string;
  className?: string;
}

const Icon: React.FC<IconProps> = React.forwardRef<HTMLSpanElement, IconProps>(
  (
    {
      children,
      cursor,
      size = 24,
      color = 'inherit',
      rotation = 0,
      spacing = 0,
      margin = 'right',
      ...rest
    },
    ref,
  ) => (
    <IconInner
      focusable="false"
      ref={ref}
      size={size}
      color={color}
      cursor={cursor}
      rotation={rotation}
      spacing={spacing}
      margin={margin}
      {...rest}
    >
      {children}
    </IconInner>
  ),
);

export default Icon;
