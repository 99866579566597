// @ts-ignore
import { serviceManager } from '@novemberfive/mandrill';
// @ts-ignore
import GoogleAnalyticsService from '@novemberfive/mandrill-service-google-analytics';

import * as mandrillConfig from './config/config.mandrill';
import * as events from './config/events';
import loadAnalytics from './loadAnalytics';

class AnalyticsService {
  private _trackingId: string;
  private _enabled: boolean = false;
  private _events = events;
  constructor(trackingId: string) {
    this._trackingId = trackingId;
    this._events = events;
    this.initAnalytics();
  }

  get trackingId() {
    return this._trackingId;
  }

  set trackingId(trackingId) {
    this._trackingId = trackingId;
  }

  get enabled() {
    return this._enabled;
  }

  set enabled(enabled: boolean) {
    this._enabled = enabled;
  }

  get events() {
    return this._events;
  }

  initAnalytics() {
    if (!(window as any).ga) {
      console.info('init tracking');
      loadAnalytics({ debug: false });
    }
  }

  enableAnalytics() {
    console.info('enable tracking');
    const win = window as any;
    win[`ga-disable-${this.trackingId}`] = false;

    win.ga('create', this.trackingId, 'auto');

    const googleAnalyticsServiceConfig = mandrillConfig.googleanalytics1;
    const googleAnalyticsService = new GoogleAnalyticsService(googleAnalyticsServiceConfig.id);
    serviceManager.registerService(googleAnalyticsService);
  }

  disableAnalytics() {
    console.info('disable tracking');
    const win = window as any;
    win[`ga-disable-${this.trackingId}`] = true;
  }

  setCustomDimension(index: number, value: string) {
    const win = window as any;
    win.ga('set', `dimension${index}`, value);
  }
}

export default AnalyticsService;
