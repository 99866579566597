import React from 'react';

import { FileWrap, ProgressBar } from './File.styled';
import FileActions from './FileActions';
import FileInfo from './FileInfo';

export interface Props {
  file: File;
  onDeleteFileClick?: (file: File) => void;
  onRetryUploadClick?: (file: File) => void;
  progress?: number;
  type?: 'success' | 'error' | 'uploading' | 'default';
  withProgressBar?: boolean;
  showRetryButton?: boolean;
  errorLabel?: string;
}

const File: React.FC<Props> = props => {
  const { file, progress = 0, type, withProgressBar = true } = props;
  return (
    <FileWrap type={type} key={file.name}>
      <FileInfo file={file} type={type} />
      <FileActions {...props} />
      {type === 'uploading' && withProgressBar && <ProgressBar progress={progress} />}
    </FileWrap>
  );
};

export default File;
