import React, { PureComponent } from 'react';

import { DialogActions, DialogContent, Link } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import Button from 'components/@common/Button';
import Icon from 'components/Icon';
import { UIcoError } from 'components/Icons';
import { Modal } from 'components/Modal';

import { DIALOG_COMPONENTS } from './constants';
import { IconWrap } from './Dialog.styled';

class Dialog extends PureComponent {
  static defaultProps = {
    type: 'DEFAULT',
    contentProps: {},
    headerIntlKey: null,
    buttonYesIntlKey: 'general.label.yes',
    buttonNoIntlKey: 'general.label.cancel',
    onConfirm: () => {},
    onCancel: () => {},
    isLoading: false,
  };
  handleClick = confirm => () => {
    const { yes, no, onConfirm, onCancel } = this.props;
    if (confirm) {
      yes();
      onConfirm();
    } else {
      no();
      onCancel();
    }
  };
  // TODO: make variant alerts, info,... for now only alert is in scope
  render() {
    const {
      isOpen,
      type,
      contentProps,
      headerIntlKey,
      buttonYesIntlKey,
      buttonNoIntlKey,
      status,
    } = this.props;
    if (!isOpen) return null;
    const Content = DIALOG_COMPONENTS[type];
    return (
      <Modal
        isOpen
        titleColor="textPrimary"
        icon={
          <IconWrap>
            <Icon size={32} color="accent02.400">
              <UIcoError />
            </Icon>
          </IconWrap>
        }
        headerIntlKey={headerIntlKey}
        closeModal={this.handleClick(false)}
      >
        <DialogContent>
          <Content {...contentProps} />
        </DialogContent>
        <DialogActions>
          <Button
            variation="alert"
            onClick={this.handleClick(true)}
            isLoading={status === 'loading'}
          >
            <FormattedMessage id={buttonYesIntlKey} />
          </Button>
          <Link component="button" onClick={this.handleClick(false)}>
            <FormattedMessage id={buttonNoIntlKey} />
          </Link>
        </DialogActions>
      </Modal>
    );
  }
}

export default Dialog;
