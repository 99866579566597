import styled, { css } from 'styled-components';

const invertedStyle = css`
  border-width: 0 0 110vh 100vw;
  border-color: transparent transparent transparent rgba(22, 65, 148, 1);
  ${({ theme }) => theme.breakpoints.down('sm')} {
    border-width: 0 0 110vh 170vw;
  }
`;

const defaultStyle = css`
  border-width: 0 0 75vh 75vw;
  border-color: transparent transparent transparent rgba(255, 255, 255, 1);
`;

export const BackgroundWrapper = styled.div<{ $inverted: boolean }>`
  &:after {
    position: fixed;
    content: ' ';
    display: block;
    left: -90px;
    width: 100%;
    border-style: solid;
    z-index: -99;
    ${({ $inverted }) => ($inverted ? invertedStyle : defaultStyle)}
  }
`;
