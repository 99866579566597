import axios from 'axios';
import humps from 'humps';
import _get from 'lodash/get';

import { HEADERS } from 'constants/apiHeaders';
import storeConfig from 'store';
import { getAccessToken as getAccessTokenFromRedux } from 'store/auth/auth.selectors';
import { authCheckStart } from 'store/auth/auth.slice';
import { getGroupId } from 'store/client/client.selectors';
import { getImpersonate } from 'store/impersonate/impersonate.selectors';
import { getLocale as getLocaleFromRedux } from 'store/intl/intl.selectors';

import { removeEmptyKeysFromObject } from 'utils/helpers';

// import cache from './cache';

let refreshing = false;

const BLACKLIST_GROUPID_HEADER = ['/api/clients'];

const getAccessToken = () => {
  const state = storeConfig.store.getState();
  const accessToken = getAccessTokenFromRedux(state);
  return accessToken || '';
};

const getLocale = () => {
  const state = storeConfig.store.getState();
  return getLocaleFromRedux(state);
};

const getStatus = error => {
  const code = error.code || _get(error, ['response', 'status']);
  const errorResponse = error.response;
  const path = error.config.url
    .replace(/\/$/, '')
    .split('/')
    .pop();
  if (!code && (path === 'api' || path === 'public_api')) {
    return 401; // this is for the vbrb netscalar which doesn't send a response, will be remove in the future hopefully
  }
  return errorResponse.status;
};

const createError = error => {
  return {
    code: getStatus(error),
    message: error.message,
    stack: error.stack,
    response: error.response,
  };
};

const axiosApi = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-oAuth-Refresh-Type': 'httpOnlyCookie',
  },
  // adapter: cache.adapter,
  transformRequest: [
    data => {
      if (!(data instanceof FormData)) {
        // convert all body keys to snake_case
        data = humps.decamelizeKeys(data);

        // convert all body objects to strings
        if (typeof data === 'object') {
          data = JSON.stringify(data);
        }
      }

      return data;
    },
  ],
  transformResponse: [
    data => {
      try {
        // convert all body keys to camelCase
        if (typeof data !== 'object' && data) {
          data = JSON.parse(data);
        }
        if (!(data instanceof Blob)) {
          data = humps.camelizeKeys(data);
        }
        return data;
      } catch (error) {
        return humps.camelizeKeys(data);
      }
    },
  ],
});

// Add a request interceptor
axiosApi.interceptors.request.use(
  axiosConfig => {
    const { params } = axiosConfig;
    axiosConfig.params = params && removeEmptyKeysFromObject(humps.decamelizeKeys(params));
    const locale = getLocale();
    const state = storeConfig.store.getState();
    const impersonateState = getImpersonate(state);
    if (locale) {
      axiosConfig.headers = {
        ...axiosConfig.headers,
        'Accept-Language': locale,
      };
    }

    if (impersonateState.impersonating && impersonateState.email) {
      axiosConfig.headers[HEADERS.IMERSONATE] = impersonateState.email;
    }

    const groupId = getGroupId(storeConfig.store.getState());
    if (
      !axiosConfig.headers[HEADERS.GROUP_ID] &&
      groupId &&
      BLACKLIST_GROUPID_HEADER.some(path => axiosConfig.url.indexOf(path) < 0)
    ) {
      axiosConfig.headers[HEADERS.GROUP_ID] = groupId;
    }

    const acessToken = getAccessToken();
    if (acessToken) {
      axiosConfig.headers.Authorization = `Bearer ${getAccessToken()}`;
    }
    return axiosConfig;
  },
  error => {
    // Do something with request error
    Promise.reject(error);
  },
);

axiosApi.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    const errorFormatted = createError(error);
    if (errorFormatted.code === 401) {
      if (!refreshing) {
        refreshing = true;
        return new Promise((resolve, reject) => {
          storeConfig.store.dispatch(authCheckStart(resolve, reject));
        })
          .then(params => {
            refreshing = false;
            originalRequest.headers.Authorization = `Bearer ${params.accessToken}`;
            return axios(originalRequest);
          })
          .catch(() => {
            refreshing = false;
            return Promise.reject(errorFormatted);
          });
      }
    }

    return Promise.reject(errorFormatted);
  },
);

export default axiosApi;
