import _get from 'lodash/get';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { Props } from './WorkIncapacityItem';

export const Wrap = styled.div<Pick<Props, 'background'>>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing(4, 5)};
  background: ${({ theme, background }) =>
    transparentize(0.8, _get(theme.palette, background || 'neutral01.300', ''))};
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.neutral01[600]};
  line-height: 1.5;
`;

export const Period = styled.div`
  color: ${({ theme }) => theme.palette.neutral01[500]};
  line-height: 1.5;
  font-weight: 400;
`;

export const Actions = styled.div`
  margin-left: auto;
  button:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(4)}px;
  }
`;

export const IconWrap = styled.div`
  display: inline-flex;
  transition: all 0.3s;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 20px;
  background: ${({ theme }) => theme.palette.accent01[400]};
  & * {
    color: ${({ theme }) => theme.palette.neutral01[50]};
    stroke: ${({ theme }) => theme.palette.neutral01[50]};
  }
`;
