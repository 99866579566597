import { connect } from 'react-redux';

import { getPartner, getPartnerError, getPartnerIsLoading } from 'store/partner/partner.selectors';
import { getPathParam } from 'store/routing/routing.selectors';
import { Store } from 'types/store/store.types';

import Details from './Details';

export interface ContainerProps {
  data?: ReturnType<typeof getPartner>;
  currentId: ReturnType<typeof getPathParam>;
  isLoading: ReturnType<typeof getPartnerIsLoading>;
  error: ReturnType<typeof getPartnerError>;
}

const mapStateToProps = (state: Store) => ({
  data: getPartner(state),
  error: getPartnerError(state),
  isLoading: getPartnerIsLoading(state),
  currentId: getPathParam(state),
});

export default connect(mapStateToProps, {})(Details);
