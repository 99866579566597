import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Client } from 'store/client/client.types';
import { StoreDefaultState } from 'types/store/store.types';

import {
  AffiliationPoliciesFetchStartPayload,
  AffiliationPoliciesResponse,
  AffiliationTerminationReason,
  AffiliationUpdateStartPayload,
  FlowContext,
  InsurantCreateStartPayload,
  InsurantDraft,
  InsurantDraftResponse,
  RestartFlowPayload,
  UpdateInsurantStartPayload,
} from './affiliationSubmission.types';

export const affiliationTerminationReasonAdapter = createEntityAdapter<
  AffiliationTerminationReason
>();

export type AffiliationSubmissionState = {
  data?: InsurantDraft;
  meta?: InsurantDraftResponse['meta'];

  status?:
    | 'updating'
    | 'creating'
    | 'created'
    | 'updated'
    | 'verified'
    | 'finalizing'
    | 'completed';
  error: StoreDefaultState['error'];
  flowContext?: FlowContext;
  companies: {
    isLoading: StoreDefaultState['isLoading'];
    error: StoreDefaultState['error'];
    data?: Client[];
  };
  affiliations: {
    isLoading: StoreDefaultState['isLoading'];
    error: StoreDefaultState['error'];
    data?: AffiliationPoliciesResponse['data'];
  };
  terminationReasons: ReturnType<typeof affiliationTerminationReasonAdapter.getInitialState> & {
    isLoading: StoreDefaultState['isLoading'];
    error: StoreDefaultState['error'];
  };
};

export const INITIAL_STATE: AffiliationSubmissionState = {
  data: undefined,
  meta: undefined,
  status: undefined,
  error: undefined,
  flowContext: undefined,
  companies: {
    isLoading: false,
    error: undefined,
    data: undefined,
  },
  affiliations: {
    data: undefined,
    isLoading: false,
    error: undefined,
  },
  terminationReasons: affiliationTerminationReasonAdapter.getInitialState({
    isLoading: false,
    error: undefined,
  }),
};

const affiliationSubmissionSlice = createSlice({
  name: 'affiliationSubmission',
  initialState: INITIAL_STATE,
  reducers: {
    resetAffiliationSubmission: state => {
      state.data = undefined;
      state.meta = undefined;
      state.status = undefined;
      state.error = undefined;
      state.affiliations.data = undefined;
      state.flowContext = undefined;
    },
    restartAffiliationSubmission: (state, { payload }: PayloadAction<RestartFlowPayload>) => {
      state.status = undefined;
      if (state.flowContext?.type !== payload.type || payload.type === 'AFFILIATION') {
        state.flowContext = {
          ...payload?.values?.stepBaseData,
          type: payload.type,
          mainInsurantId: payload.mainInsurantId,
        };
      }
      if (payload.insurant) {
        state.data = payload.insurant;
      }
    },
    fetchCompaniesStart: state => {
      state.companies.isLoading = true;
      state.companies.error = undefined;
    },
    fetchCompaniesSuccess: (
      state,
      { payload }: PayloadAction<{ data: AffiliationSubmissionState['companies']['data'] }>,
    ) => {
      state.companies.isLoading = false;
      state.companies.error = undefined;
      state.companies.data = payload.data;
    },
    fetchCompaniesFail: (
      state,
      { payload }: PayloadAction<{ error: AffiliationSubmissionState['companies']['error'] }>,
    ) => {
      state.companies.error = payload.error;
      state.companies.isLoading = false;
    },
    createInsurantStart: (state, _action: PayloadAction<InsurantCreateStartPayload>) => {
      state.status = 'creating';
      state.data = undefined;
      state.error = undefined;
    },
    createInsurantSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{ data: AffiliationSubmissionState['data']; updateStatus?: boolean }>,
    ) => {
      state.data = payload.data;
      state.error = undefined;
      state.status = payload.updateStatus === false ? state.status : 'created';
    },
    createInsurantFail: (
      state,
      { payload }: PayloadAction<{ error: AffiliationSubmissionState['error'] }>,
    ) => {
      state.data = undefined;
      state.error = payload.error;
      state.status = undefined;
    },
    updateVerifyInsurantStart: (state, _action: PayloadAction<AffiliationUpdateStartPayload>) => {
      state.status = 'updating';
      state.error = undefined;
    },
    updateVerifyInsurantSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: AffiliationSubmissionState['data'];
        meta: AffiliationSubmissionState['meta'];
      }>,
    ) => {
      state.data = payload.data;
      state.error = undefined;
      state.status = 'updated';
      state.meta = payload.meta;
    },
    updateVerifyInsurantFail: (
      state,
      { payload }: PayloadAction<{ error: AffiliationSubmissionState['error'] }>,
    ) => {
      state.data = undefined;
      state.error = payload.error;
      state.status = undefined;
    },
    fetchAffiliationPoliciesStart: (
      state,
      _action: PayloadAction<AffiliationPoliciesFetchStartPayload>,
    ) => {
      state.affiliations.isLoading = true;
      state.affiliations.error = undefined;
    },
    fetchAffiliationPoliciesSuccess: (
      state,
      { payload }: PayloadAction<{ data: AffiliationSubmissionState['affiliations']['data'] }>,
    ) => {
      state.affiliations.isLoading = false;
      state.affiliations.error = undefined;
      state.affiliations.data = payload.data;
    },
    fetchAffiliationPoliciesFail: (
      state,
      { payload }: PayloadAction<{ error: AffiliationSubmissionState['affiliations']['error'] }>,
    ) => {
      state.affiliations.error = payload.error;
      state.affiliations.isLoading = false;
    },
    fetchAffiliationTerminationReasonsStart: state => {
      state.terminationReasons.isLoading = true;
      state.terminationReasons.error = undefined;
    },
    fetchAffiliationTerminationReasonsSuccess: (
      state,
      { payload }: PayloadAction<{ data: AffiliationTerminationReason[] }>,
    ) => {
      affiliationTerminationReasonAdapter.upsertMany(state.terminationReasons, payload.data);
    },
    fetchAffiliationTerminationReasonsFail: (
      state,
      {
        payload,
      }: PayloadAction<{ error: AffiliationSubmissionState['terminationReasons']['error'] }>,
    ) => {
      state.terminationReasons.error = payload.error;
      state.terminationReasons.isLoading = false;
    },
    updateInsurantStart: (state, _action: PayloadAction<UpdateInsurantStartPayload>) => {
      state.status = 'finalizing';
    },
    updateInsurantSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: AffiliationSubmissionState['data'];
      }>,
    ) => {
      state.status = 'completed';
      state.data = payload.data;
    },
    updateInsurantFail: (
      state,
      { payload }: PayloadAction<{ error: AffiliationSubmissionState['error'] }>,
    ) => {
      state.status = 'completed';
      state.error = payload.error;
    },
  },
});

export const {
  fetchCompaniesStart,
  fetchCompaniesSuccess,
  fetchCompaniesFail,
  createInsurantStart,
  createInsurantSuccess,
  createInsurantFail,
  updateVerifyInsurantStart,
  updateVerifyInsurantSuccess,
  updateVerifyInsurantFail,
  fetchAffiliationPoliciesStart,
  fetchAffiliationPoliciesSuccess,
  fetchAffiliationPoliciesFail,
  fetchAffiliationTerminationReasonsStart,
  fetchAffiliationTerminationReasonsSuccess,
  fetchAffiliationTerminationReasonsFail,
  updateInsurantStart,
  updateInsurantSuccess,
  updateInsurantFail,
  resetAffiliationSubmission,
  restartAffiliationSubmission,
} = affiliationSubmissionSlice.actions;

export default affiliationSubmissionSlice.reducer;
