import React, { useRef } from 'react';

import { Box, BoxProps, Typography } from '@material-ui/core';

import { SidebarWrap, Title, TopWrap } from './Sidebar.styled';
import SidebarContentBlock from './SidebarContentBlock';

interface Composite {
  Top: React.FC;
  Item: React.FC<React.ComponentProps<typeof SidebarContentBlock>>;
  Title: React.FC;
  Description: React.FC;
}

const Description: React.FC = ({ children }) => (
  <Typography component="p" variant="body1" gutterBottom>
    {children}
  </Typography>
);

const Sidebar: React.FC<BoxProps> & Composite = ({ children, ...rest }) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  // we need the offset because it is fixed and needs to scroll internally, the banner component can vary in height...
  const offsetTop = sidebarRef.current?.getBoundingClientRect().top || 104;
  return (
    <div ref={sidebarRef}>
      <SidebarWrap {...rest} offset={offsetTop}>
        {children}
      </SidebarWrap>
      <Box width={332} mr={4} />
    </div>
  );
};

Sidebar.Top = TopWrap;
Sidebar.Item = SidebarContentBlock;
Sidebar.Title = Title;
Sidebar.Description = Description;

export default Sidebar;
