import React from 'react';

import { connect } from 'react-redux';

import { getAffiliationSubmissionData } from 'store/affiliationSubmission/affiliationSubmission.selectors';
import { fetchCompaniesStart } from 'store/affiliationSubmission/affiliationSubmission.slice';
import { resetUpload } from 'store/file/file.actions';
import { getLocale } from 'store/intl/intl.selectors';
import { Store } from 'types/store/store.types';

import AffiliationsCreateStepper, { StepperState } from './AffiliationsCreateStepper';

type ChildProps = Omit<React.ComponentProps<typeof AffiliationsCreateStepper>, 'onSubmit'>;
interface Props {
  onSubmit: (values: StepperState) => void;
}
const AffiliationsCreateStepperContainer: React.FC<ContainerProps & ChildProps & Props> = ({
  flowType,
  data,
  ...rest
}) => {
  return <AffiliationsCreateStepper {...rest} data={data} flowType={flowType} />;
};

const mapStateToProps = (state: Store) => ({
  locale: getLocale(state),
  data: getAffiliationSubmissionData(state),
});
const mapDispatchToProps = {
  fetchCompanies: fetchCompaniesStart,
  resetFileUpload: resetUpload,
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type MapDispatchProps = typeof mapDispatchToProps;
export type ContainerProps = MapStateProps & MapDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(AffiliationsCreateStepperContainer);
