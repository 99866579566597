import apiManager from './axios/apiManager';

export const fetchFileSubmissionRecentRecipients = (path, params) =>
  apiManager
    .get(path, {
      params,
    })
    .then(response => response.data);

export const updateFileSubmission = (path, body) =>
  apiManager.put(path, body).then(response => response.data);

export const createFileSubmission = (path, body) =>
  apiManager.post(path, body).then(response => response.data);

export const validateRecipient = (path, body) =>
  apiManager.put(path, body).then(response => response.data);

export const fetchFileSubmissionPartners = (path, params) =>
  apiManager
    .get(path, {
      params,
    })
    .then(response => response.data);

export const fetchFileSubmissionPartnerContacts = (path, params) =>
  apiManager
    .get(path, {
      params,
    })
    .then(response => response.data);
