const formats: {
  [index: string]: {
    day?: string;
    month?: string;
    year?: string;
    hour?: string;
    minute?: string;
    weekday?: string;
  };
} = {
  'HH:mm': {
    hour: '2-digit',
    minute: 'numeric',
  },
  'DD/MM/YYYY': {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
  'DD/MM/YYYY HH:mm': {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: 'numeric',
  },
  'dddd DD/MM/YYYY': {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    weekday: 'long',
  },
  timeAgo: {},
  default: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: 'numeric',
  },
};

export default formats;
