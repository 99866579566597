import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import styled, { css } from 'styled-components';

import { Props } from './RadioButton';

const checkedStyle = css`
  color: ${({ theme }) => theme.palette.brand01[300]};
`;

const disabledStyle = css`
  opacity: 0.6;
  cursor: not-allowed;
`;

const radioCircleStyle = css<{ iconSize: number }>`
  width: ${({ iconSize }) => iconSize}px;
  height: ${({ iconSize }) => iconSize}px;
  border: 2px solid ${({ theme }) => theme.palette.neutral01[400]};
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.neutral01[50]};
  position: relative;
  &:after {
    content: '';
    background: ${({ theme }) => theme.palette.neutral01[50]};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${({ iconSize }) => iconSize / 3}px;
    height: ${({ iconSize }) => iconSize / 3}px;
    border-radius: 50%;
  }
`;

export const RadioIconUnChecked = styled.div`
  ${radioCircleStyle};
`;

export const RadioStyled = styled(({ iconSize, ...rest }: Props) => <Radio {...rest} />)`
  svg {
    width: ${({ iconSize }) => `${iconSize}px`};
    height: ${({ iconSize }) => `${iconSize}px`};
    color: ${({ theme }) => theme.palette.brand01[200]};
    ${({ disabled }) => (disabled ? disabledStyle : '')};
    ${({ checked }) => (checked ? checkedStyle : '')};
  }
  & :hover {
    ${RadioIconUnChecked} {
      background: ${({ theme }) => theme.palette.brand01[500]};
      border-color: ${({ theme }) => theme.palette.brand01[500]};
    }
  }
`;

export const LabelStyled = styled(({ isTitle, ...rest }) => <FormControlLabel {...rest} />)`
  && {
    display: flex;
    align-items: center;
    margin-left: 0px !important;
    margin-right: ${({ theme }) => theme.spacing(6)}px !important;
    user-select: none;
    cursor: pointer;
    padding: ${({ theme }) => theme.spacing(3)}px 0;
    line-height: 1.5;
    color: ${({ theme }) => theme.palette.neutral01[600]};
    line-height: 1;
    & :hover {
      ${RadioIconUnChecked} {
        background: ${({ theme }) => theme.palette.brand01[500]};
        border-color: ${({ theme }) => theme.palette.brand01[500]};
      }
    }
  }

  ${RadioStyled} {
    padding: 0;
    padding-right: ${({ theme }) => theme.spacing(3)}px;
  }
  span {
    color: ${({ theme }) => theme.palette.neutral01[600]};
    line-height: 1.5;
    font-weight: ${({ isTitle }) => (isTitle ? 700 : 400)};
    font-size: 16px;
  }
`;

export const RadioIconChecked = styled.div`
  ${radioCircleStyle};
  background: ${({ theme }) => theme.palette.brand01[500]};
  border-color: ${({ theme }) => theme.palette.brand01[500]};
`;
