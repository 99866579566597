import React from 'react';

import { FormattedMessage } from 'react-intl';

import useStepperForm from 'utils/useStepperForm';

import { FlowType } from 'pages/affiliations/Create/AffiliationsCreate';
import { AffiliationDataForm } from 'pages/affiliations/Forms';

import Button from 'components/@common/Button';

import { ContainerProps, FormValues } from './AffiliationStepContainer';

interface Props {
  saveForm: (values: FormValues) => void;
  onNextStepClick: (values: FormValues) => void;
  affiliations: ContainerProps['affiliations'];
  terminationReasons: ContainerProps['terminationReasons'];
  affiliationsLoading?: boolean;
  flowType: FlowType;
}

const AffiliationStep: React.FC<Props> = ({
  affiliations,
  affiliationsLoading,
  terminationReasons,
  saveForm,
  onNextStepClick,
}) => {
  const { handleNextStepClick, canContinue } = useStepperForm(saveForm, true, onNextStepClick);
  return (
    <>
      <AffiliationDataForm
        affiliationsLoading={affiliationsLoading}
        affiliations={affiliations}
        terminationReasons={terminationReasons}
      />
      <Button
        data-test-id="affiliations.add.step.affiliation.actions.next"
        disabled={canContinue}
        onClick={handleNextStepClick}
      >
        <FormattedMessage id="general.stepper.next" />
      </Button>
    </>
  );
};

export default AffiliationStep;
