import _get from 'lodash/get';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const ContentWrap = styled.div`
  display: flex;
  height: 100%;
  min-height: calc(100vh - 200px);
  position: relative;
  overflow-x: hidden;
`;

export const ContentHeader = styled.div`
  color: ${({ theme }) => theme.palette.neutral01[500]};
  line-height: 1.5;
  font-weight: 400;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral01[300]};
  border-top: 1px solid ${({ theme }) => theme.palette.neutral01[300]};
  padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(12)}px`};
  background: ${({ theme }) => theme.palette.neutral01[50]};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 65px;
`;

export const BackLink = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.spacing(2)}px;
  line-height: 1;
`;

export const Content = styled.div<{ isLeft?: boolean }>`
  display: flex;
  background: ${({ theme }) => theme.palette.neutral01[50]};
  flex-direction: column;
  flex: 1 50%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  ${({ theme }) => theme.breakpoints.up('md')} {
    position: relative;
    background: ${({ theme, isLeft }) =>
      isLeft ? transparentize(0.8, theme.palette.neutral01[300]) : theme.palette.neutral01[50]};
  }
`;

export const RightContentFooter = styled.div`
  box-shadow: 0 1px 5px 0px rgba(51, 51, 51, 0.1);
  padding: ${({ theme }) => `${theme.spacing(1) * 5}px ${theme.spacing(1) * 4}px`};
  display: block;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
  }
`;

export const FooterInfo = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.57;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  padding-right: ${({ theme }) => theme.spacing(1) * 4}px;
  flex: 1 50%;
  margin-bottom: ${({ theme }) => theme.spacing(1) * 4}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
  }
`;

export const EmployeeItem = styled.div<{ isSelected?: boolean }>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.accent01[100] : 'transparent'};
  border: 1px solid
    ${({ isSelected, theme }) =>
      isSelected
        ? ` ${theme.palette.brand01[300]}`
        : `${transparentize(0.8, theme.palette.neutral01[500])}`};
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(4)}px`};
  margin-bottom: ${({ theme }) => theme.spacing(1) * 2}px;
  flex-shrink: 0;
  overflow: hidden;
  flex-wrap: wrap;
`;

export const EmployeeList = styled.div`
  padding: ${({ theme }) => theme.spacing(6)}px;
  padding-top: 0;
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const BadgeWrap = styled.div`
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  margin-left: auto;
`;

export const Heading = styled.div`
  padding: 0;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(8, 7, 0)};
  }
`;

export const ResultsWrap = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(6)}px;
`;

export const ResultLabel = styled.span<{ color?: string }>`
  color: ${({ theme, color }) => _get(theme.palette, color || 'neutral01.600')};
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
`;
