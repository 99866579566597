type Listener = any;

export default class EventEmitter {
  private _events: { [key: string]: Listener[] };

  constructor() {
    this._events = {};
  }

  get events() {
    return this._events;
  }

  set events(events) {
    this._events = events;
  }

  on(eventName: string, listener: Listener) {
    if (!this._events[eventName]) this._events[eventName] = [];
    this._events[eventName] = [listener];
  }

  remove(eventName: string) {
    if (this._events[eventName]) {
      delete this._events[eventName];
    }
  }

  emit(eventName: string, ...data: any) {
    if (!this._events[eventName]) return;
    this._events[eventName].forEach(event => {
      event(data);
    });
  }
}
