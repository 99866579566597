import { call, put, select, takeLatest } from 'redux-saga/effects';

import { createFFeedback } from 'api/feedback.api';
import { analyticsService } from 'services';
import { addNotification } from 'store/notifications/notifications.actions';

import { modalHide } from '../modal/modal.slice';
import {
  feedbackCreateFail,
  feedbackCreateStart,
  feedbackCreateSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './feedback.actions';
import { getFeedbackEndpoint } from './feedback.selectors';

function* feedbackCreateFlow(action) {
  try {
    const { body } = action.payload;
    const path = yield select(getFeedbackEndpoint);
    analyticsService.events.trackFeedbackSubmission();
    yield call(createFFeedback, path, body);
    yield put(
      addNotification({
        variant: 'success',
        message: 'feedback.modal.success.title',
        description: 'feedback.modal.success.text',
      }),
    );
    yield put(modalHide());
    yield put(feedbackCreateSuccess());
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'feedback.modal.error.text',
      }),
    );
    yield put(feedbackCreateFail({ error }));
  }
}
// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

function* feedbackSaga() {
  yield takeLatest(feedbackCreateStart.getType(), feedbackCreateFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default feedbackSaga;
