import React from 'react';

import { ButtonProps } from '@material-ui/core';

import { IconLoader } from 'components/Loaders';

import {
  BaseButton,
  ButtonContent,
  DangerButton,
  LoaderWrap,
  SecondaryButton,
} from './Button.styled';

export interface Props extends ButtonProps {
  variation?: string;
  isLoading?: boolean;
}

const Button: React.FC<Props> = ({ children, variation, isLoading, ...rest }) => {
  switch (variation) {
    case 'secondary': {
      return (
        <SecondaryButton isLoading={isLoading} {...rest}>
          {isLoading ? (
            <LoaderWrap>
              <IconLoader size={20} color="brand01.300" />
            </LoaderWrap>
          ) : (
            <ButtonContent>{children}</ButtonContent>
          )}
        </SecondaryButton>
      );
    }
    case 'alert': {
      return (
        <DangerButton isLoading={isLoading} {...rest}>
          {isLoading ? (
            <LoaderWrap>
              <IconLoader size={20} color="neutral01.50" />
            </LoaderWrap>
          ) : (
            <ButtonContent>{children}</ButtonContent>
          )}
        </DangerButton>
      );
    }
    default:
      return (
        <BaseButton isLoading={isLoading} {...rest}>
          {isLoading ? (
            <LoaderWrap>
              <IconLoader size={20} color="neutral01.50" />
            </LoaderWrap>
          ) : (
            <ButtonContent>{children}</ButtonContent>
          )}
        </BaseButton>
      );
  }
};

export default Button;
