import React from 'react';

import { formatBytes, getExtensionlessName, getFileExtension } from 'utils/helpers';

import OverflowTooltip from 'components/OverflowTooltip';

import { FileInfoWrap, FileInner, FileMeta, FileName } from './File.styled';
import FileIcon from './FileIcon';

interface Props {
  file: Partial<File>;
  nameWithExtension?: boolean;
  withFileMeta?: boolean;
  customFileMeta?: React.ReactNode;
  isDisabled?: boolean;
  type?: 'success' | 'error' | 'uploading' | 'default';
}

const FileInfo: React.FC<Props> = ({
  file,
  nameWithExtension = false,
  withFileMeta = true,
  customFileMeta = null,
  type = 'default',
}) => {
  const name = (getFileExtension(file.name) || '')?.toLowerCase();
  const sizeFormatted = typeof file.size === 'number' ? formatBytes(file.size) : '';
  const meta = [name, sizeFormatted].filter(Boolean).join(' - ');
  return (
    <FileInfoWrap>
      <FileIcon
        color="brand01.400"
        extension={(getFileExtension(file.name) || '')?.toLowerCase()}
      />
      <FileInner>
        <FileName type={type}>
          <OverflowTooltip>
            {nameWithExtension ? file.name : getExtensionlessName(file.name)}
          </OverflowTooltip>
        </FileName>
        {withFileMeta && <FileMeta>{customFileMeta || meta}</FileMeta>}
      </FileInner>
    </FileInfoWrap>
  );
};

export default FileInfo;
