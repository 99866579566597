import React from 'react';

import { InputBaseProps } from '@material-ui/core';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { ActionMeta, ValueType } from 'react-select/src/types';

import { getFieldErrors } from 'utils/form';

import Field from 'components/@inputs/Field';

import CountrySelect from './CountrySelect';

type Option = {
  label: string;
  value: any;
};
// TODO: refactor typings after form is done
export interface Props extends Omit<InputBaseProps, 'onChange'> {
  name: string;
  label?: string;
  getOptionValue?: (option: Option) => void;
  fallbackOnNative?: boolean;
  menuPortalTarget?: HTMLElement;
  bottomLabel?: string;
  isRequired?: boolean;
  onChange?: (option: ValueType<Option>, action: ActionMeta) => void;
}

const CountrySelectField: React.FC<Props> = props => {
  const { name, label, bottomLabel, placeholder, disabled, fullWidth, isRequired, ...rest } = props;
  const intl = useIntl();
  const [field, meta, helpers] = useField(name);
  const errors = getFieldErrors(name, meta);
  const val = (Array.isArray(field.value) ? field.value : [field.value]).filter(Boolean);
  return (
    <Field
      label={label}
      errors={errors}
      bottomLabel={bottomLabel}
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
    >
      <CountrySelect
        id={name}
        isDisabled={disabled}
        variant={val.length > 0 ? 'filled' : undefined}
        error={errors.length > 0}
        fallbackOnNative
        placeholder={placeholder && intl.formatMessage({ id: placeholder })}
        {...rest}
        {...field}
        name={name}
        onChange={helpers.setValue}
      />
    </Field>
  );
};

export default CountrySelectField;
