import React, { Fragment } from 'react';

interface ConditionalWrapProps {
  condition?: boolean;
  wrap: (wrappedComponent: React.ReactNode) => React.ReactNode;
}

const ConditionalWrap: React.FC<ConditionalWrapProps> = ({ condition, wrap, children }) => (
  <Fragment>{condition ? wrap(children) : children}</Fragment>
);

export default ConditionalWrap;
