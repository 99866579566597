import { useQueryClient } from 'react-query';

import { queryKeys } from 'constants/querykeys';
import { Client } from 'store/client/client.types';

import { notEmpty } from 'utils/typescript.helpers';

const usePolicyholders = () => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<{ [id: string]: Client }>([queryKeys.policyHolders]);

  const getPolicyholderById = (clientId: string) => {
    return data?.[clientId];
  };
  const getPolicyholderByIds = (clientIds: string[]) => {
    return clientIds.map(clientId => data?.[clientId]).filter(notEmpty);
  };

  const setPolicyholders = (clients: { [index: string]: Client }) => {
    queryClient.setQueryData([queryKeys.policyHolders], { ...data, ...clients });
  };

  return { data: data ?? {}, getPolicyholderById, getPolicyholderByIds, setPolicyholders };
};

export default usePolicyholders;
