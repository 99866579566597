import React from 'react';

import { createCanBoundTo } from '@casl/react';

import { ContainerProps } from './CanContainer';

export interface Props {
  I: string;
  a: string;
}

const Can: React.FC<Props & ContainerProps> = ({ userAbilities, ...rest }) => {
  const Comp: React.ElementType = createCanBoundTo(userAbilities);
  return <Comp {...rest} />;
};

export default Can;
