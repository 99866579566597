import { createReducer } from 'redux-act';

import { NotificationProps } from 'components/Notification/Notification';

import {
  addNotification,
  closeNotification,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './notifications.actions';

export type NotificationsState = {
  notifications: NotificationProps[];
};

export const INITIAL_STATE = {
  notifications: [],
};

const reducer = createReducer<NotificationsState>({}, INITIAL_STATE);

reducer.on(addNotification, (state, notification) => ({
  ...state,
  notifications: [...state.notifications, notification],
}));

reducer.on(closeNotification, (state, key) => ({
  ...state,
  notifications: state.notifications.filter(notification => notification.key !== key),
}));

// (-- APPEND REDUCER ACTION MAPPING HERE --) !!! do not move this comment !!!

export default reducer;
