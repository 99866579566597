import React from 'react';

import { ReactComponent as UIcoAansluitingen } from 'assets/svg/u_ico_aansluitingen.svg';
import { ReactComponent as UIcoAnalyse } from 'assets/svg/u_ico_analyse.svg';
import { ReactComponent as UIcoDashboardClaimReports } from 'assets/svg/u_ico_dashboard_claim_reports.svg';
import { ReactComponent as UIcoDashboardDamageClaim } from 'assets/svg/u_ico_dashboard_damage_claim.svg';
import { ReactComponent as UIcoDashboardDatashare } from 'assets/svg/u_ico_dashboard_datashare.svg';
import { ReactComponent as UIcoDashboardDocuments } from 'assets/svg/u_ico_dashboard_documents.svg';
import { ReactComponent as UIcoDashboardInsuranceCertificates } from 'assets/svg/u_ico_dashboard_insurance_certificates.svg';
import { ReactComponent as UIcoDashboardInsurancePolicies } from 'assets/svg/u_ico_dashboard_insurance_policies.svg';
import { ReactComponent as UIcoDashboardInsured } from 'assets/svg/u_ico_dashboard_insured.svg';
import { ReactComponent as UIcoDashboardInvoices } from 'assets/svg/u_ico_dashboard_invoices.svg';
import { ReactComponent as UIcoDataShare } from 'assets/svg/u_ico_datashare.svg';
import { ReactComponent as UIcoFacturen } from 'assets/svg/u_ico_facturen.svg';
import { ReactComponent as UIcoFileText } from 'assets/svg/u_ico_file_text.svg';
import { ReactComponent as UIcoGreenCard } from 'assets/svg/u_ico_groene_kaarten.svg';
import { ReactComponent as UIcoKlanten } from 'assets/svg/u_ico_klanten.svg';
import { ReactComponent as UIcoLSchade } from 'assets/svg/u_ico_l_schade.svg';
import { ReactComponent as UIcoOverzicht } from 'assets/svg/u_ico_overzicht.svg';
import { ReactComponent as UIcoSchade } from 'assets/svg/u_ico_schade.svg';
import { ReactComponent as UIcoVerzekeringen } from 'assets/svg/u_ico_verzekeringen.svg';

import { ROUTES } from 'constants/store';
import { NavItems } from 'types/header/header.types';

export const NAV_ITEMS: NavItems = {
  [ROUTES.DASHBOARD]: {
    label: 'general.menu.dashboard',
    icon: <UIcoOverzicht />,
  },
  [ROUTES.PARTNERS]: {
    label: 'general.menu.clients',
    icon: <UIcoKlanten />,
  },
  [ROUTES.POLICIES]: {
    label: 'general.menu.policies',
    icon: <UIcoVerzekeringen />,
    iconLarge: <UIcoDashboardInsurancePolicies />,
  },
  [ROUTES.VEHICLE_CERTIFICATES]: {
    label: 'general.menu.green_cards',
    icon: <UIcoGreenCard />,
    iconLarge: <UIcoDashboardInsuranceCertificates />,
  },
  [ROUTES.HC_AFFILIATIONS]: {
    label: 'general.menu.affiliations',
    icon: <UIcoAansluitingen />,
    iconLarge: <UIcoDashboardInsured />,
  },
  [ROUTES.CLAIMS]: {
    label: 'general.menu.damage',
    icon: <UIcoSchade />,
    iconLarge: <UIcoDashboardDamageClaim />,
  },
  [ROUTES.CLAIM_CREATE]: {
    label: 'general.menu.damage',
    icon: <UIcoSchade />,
    iconLarge: <UIcoLSchade />,
  },
  [ROUTES.DATASHARE]: {
    label: 'general.menu.documents',
    icon: <UIcoDataShare />,
    iconLarge: <UIcoDashboardDatashare />,
  },
  [ROUTES.INVOICES]: {
    label: 'general.menu.invoices',
    icon: <UIcoFacturen />,
    iconLarge: <UIcoDashboardInvoices />,
  },
  [ROUTES.CLAIM_REPORTS]: {
    label: 'general.menu.claim_reports',
    icon: <UIcoAnalyse />,
    iconLarge: <UIcoDashboardClaimReports />,
  },
  [ROUTES.PUBLICATIONS]: {
    label: 'general.menu.publications',
    icon: <UIcoFileText />,
    iconLarge: <UIcoDashboardDocuments />,
  },
};

export default null;
