import styled from 'styled-components';

export const AvatarWrap = styled.div`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    cursor: pointer;
  }
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  align-items: center;
  min-height: 56px;
  &:last-child {
    margin-bottom: 0;
  }
`;
