import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 ${({ theme }) => theme.spacing(8)}px;
    padding-bottom: ${({ theme }) => theme.spacing(6)}px;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: ${({ theme }) => theme.breakpoints.values.xl}px;
    padding-top: ${({ theme }) => theme.spacing(6)}px;
  }
`;

export const PageWrap = styled.div`
  min-height: 100vh;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.brand01[50]};
`;
