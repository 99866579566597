import React from 'react';

import { Table as MuiTable, TableProps as MuiTableProps } from '@material-ui/core';

export type TableProps = MuiTableProps;

const Table: React.FC<TableProps> = ({ children, component = 'div', ...rest }) => (
  <MuiTable component={component} {...rest}>
    {children}
  </MuiTable>
);

export default Table;
