import React from 'react';

import { useField } from 'formik';

import { getFieldErrors } from 'utils/form';

import Field from 'components/@inputs/Field';

import CheckboxGroup, { CheckboxGroupProps } from './CheckboxGroup';

export interface CheckboxGroupFieldProps extends CheckboxGroupProps {
  name: string;
  label?: string;
  bottomLabel?: string;
  isRequired?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

const CheckboxGroupField: React.FC<CheckboxGroupFieldProps> = ({
  name,
  label,
  bottomLabel,
  disabled,
  fullWidth,
  isRequired,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const errors = getFieldErrors(name, meta);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const prevValues = field.value || {};
    helpers.setValue({ ...prevValues, [e.target.value]: e.target.checked });
  };

  return (
    <Field
      label={label}
      errors={errors}
      bottomLabel={bottomLabel}
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
    >
      <CheckboxGroup
        id={name}
        name={name}
        isDisabled={disabled}
        {...field}
        {...rest}
        data-test-id={name}
        onChange={handleChange}
      />
    </Field>
  );
};

export default CheckboxGroupField;
