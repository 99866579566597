export enum QueryParams {
  SEARCH = 'q',
  ORDER = 'o',
  MAX_PER_PAGE = 'maxPerPage',
  PAGE = 'p',
  TYPE = 'type',
  ROLE = 'role',
  GROUP_ID = 'groupId',
  OWNER_ID = 'ownerId',
  ALL_USERS = 'allUsers',
}

export const StepIds = {
  StepInfo: 'stepInfo',
  StepRights: 'stepRights',
};

export enum CategoryListIds {
  MyUsers = 'myUsers',
  AllUsers = 'allUsers',
}
