import { connect } from 'react-redux';

import { avatarFetchStart } from 'store/avatars/avatars.actions';
import { getAvatar, getAvatarHasFetched } from 'store/avatars/avatars.selectors';
import { AvatarFetchPayload } from 'types/store/avatars/avatars.types';
import { Store } from 'types/store/store.types';

import ApiImageAvatar, { Props } from './ApiImageAvatar';

export interface ContainerProps {
  fetchAvatar: (payload: AvatarFetchPayload) => void;
  avatar?: string;
  hasFetched?: number;
}

const mapStateToProps = (state: Store, { id, context }: Props) => ({
  avatar: getAvatar(state, context, id),
  hasFetched: getAvatarHasFetched(state, context, id),
});
const mapDispatchToProps = {
  fetchAvatar: avatarFetchStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiImageAvatar);
