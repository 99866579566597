import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { ChipProps } from './Chip';

const warningStyle = css`
  background-color: ${({ theme }) => theme.palette.accent02[50]};
  color: ${({ theme }) => theme.palette.neutral01[50]};
`;

const alertStyle = css`
  background-color: ${({ theme }) => transparentize(0.2, theme.palette.accent02[300])};
  color: ${({ theme }) => theme.palette.neutral01[50]};
`;

const successStyle = css`
  background-color: ${({ theme }) => transparentize(0.8, theme.palette.accent01[300])};
  color: ${({ theme }) => transparentize(0.2, theme.palette.accent01[300])};
`;

const defaultStyle = css<Pick<ChipProps, 'isFocused'>>`
  background-color: ${({ theme, isFocused }) =>
    isFocused ? theme.palette.brand01[300] : theme.palette.brand01[800]};
  color: ${({ theme, isFocused }) =>
    isFocused ? theme.palette.neutral01[50] : theme.palette.brand01[300]};
`;

export const ChipWrap = styled.span<Pick<ChipProps, 'type' | 'onClick' | 'isFocused'>>`
  color: ${({ theme }) => theme.palette.brand01[400]};
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'inherit')};
  height: ${({ theme }) => theme.spacing(6.5)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 2px;
  max-width: 100%;
  ${({ type }) => {
    switch (type) {
      case 'alert':
        return alertStyle;
      case 'warning':
        return warningStyle;
      case 'success':
        return successStyle;
      default:
        return defaultStyle;
    }
  }};
`;

export default null;
