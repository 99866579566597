import { useSelector } from 'react-redux';

import { getGroupId } from 'store/client/client.selectors';

const useClients = () => {
  const groupId: string = useSelector(getGroupId);
  return {
    groupId,
  };
};

export default useClients;
