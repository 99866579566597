import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { FieldMetaProps, FormikValues } from 'formik';
import _get from 'lodash/get';

import { IncapacityPeriod } from 'services/Api/types/claim.types';

export type FieldError = {
  level?: 'warning' | 'error';
  id: string;
  values?: { [key: string]: string };
};

export type ErrorType =
  | (string | { level?: 'warning' | 'error'; id: string; values?: { [key: string]: string } })
  | undefined
  | never;

type FieldMeta = FieldMetaProps<Text> & {
  error: ErrorType;
};

export const getFieldErrors = <T = FormikValues>(
  name: string,
  field: FieldMetaProps<T>,
): FieldError[] => {
  const error = field.error as FieldMeta['error'];
  const isTouched = field.touched;

  if (error && isTouched) {
    if (typeof error === 'string') {
      return [{ id: error, values: { field: name } }];
    }
    return [{ id: error.id, values: { field: name, ...error.values }, level: error?.level }];
  }

  return [];
};

export const validateIncapacityPeriodsOverlap = <T = IncapacityPeriod>(
  periods: T[],
  endDateSelector: string = 'attributes.endDate',
  startDateSelector: string = 'attributes.startDate',
) => {
  dayjs.extend(isSameOrAfter);
  return periods
    .sort((date1: T, date2: T) =>
      dayjs(_get(date1, startDateSelector)).isAfter(dayjs(_get(date2, startDateSelector))) ? 1 : -1,
    )
    .reduce(
      (
        acc: {
          overlap: boolean;
          ranges: { previous: T; current: T }[];
        },
        period,
        index,
        arr,
      ) => {
        if (index === 0) {
          return acc;
        }
        const previous = arr[index - 1];
        const previousEnd: Date = _get(previous, endDateSelector);
        const currentStart: Date = _get(period, startDateSelector);
        if (!previousEnd || !currentStart) return acc;
        const overlap = dayjs(previousEnd).isSameOrAfter(dayjs(currentStart));
        if (overlap) {
          acc.overlap = true;
          acc.ranges.push({
            previous,
            current: period,
          });
        }
        return acc;
      },
      { overlap: false, ranges: [] },
    );
};

export const defaultFileValidation = {};
