export const STEP_IDS = {
  STEP_RISK: 'stepRisk',
  STEP_RISK_SPECIFY: 'stepRiskSpecify',
  STEP_PARTNER: 'stepPartner',
  STEP_POLICY: 'stepPolicy',
  STEP_SUBJECT: 'stepSubject',
  STEP_CIRCUMSTANCES: 'stepCircumstances',
  STEP_COUNTERPARTIES: 'stepCounterparties',
  STEP_ESTIMATE: 'stepEstimate',
  STEP_DOCUMENTS: 'stepDocuments',
  STEP_RECAP: 'stepRecap',
  STEP_DOCUMENT_UPLOAD: 'stepDocumentUpload',
  STEP_SUBMIT: 'stepSubmit',
  STEP_TPA_GI_EMPLOYEE: 'stepTpaGiEmployee',
  STEP_TPA_GI_INCAPACITY: 'stepTpaGiInCapacity',
  STEP_TPA_GI_EMPLOYEE_DATA: 'stepTpaGiEmployeeData',
  STEP_TPA_GI_SUMMARY: 'stepTpaGiSummary',
};

export const TYPES = {
  MARINE: 'MARINE',
  BUILDINGS: 'CONSTRUCTION',
  WORK_INCAPACITY: 'HCIC',
};

export const EMPLOYEE_QUERYPARAMS = {
  MAX_PER_PAGE: 'max_per_page',
  SEARCH: 'q',
  POLICY_ID: 'policy_id',
  CLAIM_DOMAIN_ID: 'claim_domain_id',
  CLAIM_SEGMENT_ID: 'claim_segment_id',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  PERSONNEL_NUMBER: 'personnel_number',
  BIRTH_DATE: 'birth_date',
  IDENTIFICATION_NUMBER: 'identification_number',
  PAGE: 'p',
};

export const MAIN_FLOW_STEPS = [
  STEP_IDS.STEP_SUBJECT,
  STEP_IDS.STEP_CIRCUMSTANCES,
  STEP_IDS.STEP_COUNTERPARTIES,
  STEP_IDS.STEP_ESTIMATE,
  STEP_IDS.STEP_DOCUMENTS,
  STEP_IDS.STEP_RECAP,
  STEP_IDS.STEP_DOCUMENT_UPLOAD,
  STEP_IDS.STEP_SUBMIT,
];

export const DRILLDOWN_FLOW_STEPS = [
  STEP_IDS.STEP_RISK,
  STEP_IDS.STEP_RISK_SPECIFY,
  STEP_IDS.STEP_PARTNER,
  STEP_IDS.STEP_POLICY,
];

export const TPGA_GI_STEPS = [
  STEP_IDS.STEP_TPA_GI_EMPLOYEE,
  STEP_IDS.STEP_TPA_GI_INCAPACITY,
  STEP_IDS.STEP_TPA_GI_EMPLOYEE_DATA,
  STEP_IDS.STEP_TPA_GI_SUMMARY,
];
