import React from 'react';

import { Checkbox, FormControlLabelProps } from '@material-ui/core';

import { FormControlLabel } from './CheckboxTile.styled';

interface CheckboxTileProps extends Omit<FormControlLabelProps, 'control' | 'label' | 'onChange'> {
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: { [index: string]: unknown },
    checked: boolean,
  ) => void;
  name: string;
}

const CheckboxTile: React.FC<CheckboxTileProps> = ({
  value,
  children,
  name,
  checked,
  onChange = () => {},
  ...rest
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange(e, { [name]: value }, checked);
  };
  return (
    <FormControlLabel
      classes={{ root: checked ? 'checked' : '' }}
      checked={checked}
      value={value}
      label={children}
      control={<Checkbox onChange={handleChange} />}
      {...rest}
    />
  );
};

export default CheckboxTile;
