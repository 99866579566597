import React, { Fragment } from 'react';

import Collapse from '@material-ui/core/Collapse';

import { H3 } from 'components/@typography/Titles';
import ConditionalWrap from 'components/ConditionalWrap';
import Icon from 'components/Icon';
import { UIcoPijlBoven, UIcoPijlOnder } from 'components/Icons';

import { CardHeader, CardWrap } from './Card.styled';
import { CardProps } from './Card.types';

const Card = ({
  title,
  isCollapsed,
  isCollapsible,
  isContainer,
  collapsedHeight,
  headerContent,
  withCollapseIcon,
  onHeaderClick,
  children,
  ...rest
}: CardProps) => (
  <ConditionalWrap
    condition={!isContainer && isCollapsible}
    wrap={wrappedChildren => (
      <Collapse collapsedHeight={collapsedHeight} in={!isCollapsed} timeout="auto">
        {wrappedChildren}
      </Collapse>
    )}
  >
    <CardWrap isContainer={isContainer} {...rest}>
      {(isCollapsible || title || headerContent) && (
        <CardHeader onClick={onHeaderClick} isCollapsible={isCollapsible}>
          {title && <H3> {title}</H3>}
          {headerContent}
          {withCollapseIcon && isCollapsible && (
            <Icon>{isCollapsed ? <UIcoPijlOnder /> : <UIcoPijlBoven />}</Icon>
          )}
        </CardHeader>
      )}

      <Fragment>{children}</Fragment>
    </CardWrap>
  </ConditionalWrap>
);

Card.defaultProps = {
  m: 4,
  order: {},
  isContainer: false,
  isCollapsed: false,
  isCollapsible: false,
  collapsedHeight: '75px',
  withCollapseIcon: true,
  onHeaderClick: () => {},
};

export default Card;
