import React, { memo } from 'react';

import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import Forbidden from 'pages/common/Forbidden';
import Unavailable from 'pages/common/Unavailable';

import Button from 'components/@common/Button';
import Banner from 'components/Banner';
import Placeholder from 'components/Placeholders';

import { ForbiddenActions, Wrap } from './System.styled';
import { ContainerProps } from './SystemContainer';

const PageHead: React.FC<{ isImpersonating: boolean }> = ({ isImpersonating }) => {
  if (isImpersonating) {
    return (
      <Helmet titleTemplate="%s | VanbredaConnect" defaultTitle="VanbredaConnect">
        <title>DEMO</title>
        <meta name="description" content="VanbredaConnect demo impersonate" />
        <link
          rel="shortcut icon"
          href="img/impersonate_favicon.ico"
          sizes="48x48 32x32 24x24 16x16"
        />
      </Helmet>
    );
  }
  return (
    <Helmet titleTemplate="%s | VanbredaConnect" defaultTitle="VanbredaConnect">
      <meta name="description" content="VanbredaConnect" />
    </Helmet>
  );
};

const System: React.FC<ContainerProps> = props => {
  const intl = useIntl();
  const { children, error, isLoading, impersonate, logout } = props;
  const errorCode = typeof error !== 'string' ? error?.code : undefined;
  const errorName = typeof error !== 'string' ? error?.name : error;
  const isForbidden = errorCode === 403;
  const isImpersonating = impersonate.impersonating;
  const impersonateSuccess = impersonate.impersonateSuccess;
  const handleActioClick = () => {
    window.location.href = '/';
  };

  const ForbiddenError = () => {
    if (isImpersonating) {
      return (
        <Forbidden
          text="general.errors.impersonate.forbidden.text"
          onActionClick={handleActioClick}
        />
      );
    }
    return <Forbidden />;
  };

  const SystemFailError = () => {
    if (errorName === 'auth' && errorCode === 'access_denied') {
      return (
        <Forbidden
          title="general.errors.no_access.title"
          code={403}
          description={
            <>
              <FormattedMessage id="general.errors.no_access.text" />
              <ForbiddenActions>
                <Button href={`mailto:${intl.formatMessage({ id: 'general.helpdesk.email' })}`}>
                  <FormattedMessage id="general.errors.no_access.action_1" />
                </Button>
                <Button
                  onClick={() => {
                    logout();
                  }}
                >
                  <FormattedMessage id="general.errors.no_access.action_2" />
                </Button>
              </ForbiddenActions>
            </>
          }
        />
      );
    }
    return <Unavailable code={errorCode} onActionClick={handleActioClick} />;
  };

  return (
    <>
      <PageHead isImpersonating={isImpersonating} />
      <Wrap isImpersonating={isImpersonating && impersonateSuccess}>
        <Banner />
        <Box
          display="flex"
          flexDirection="column"
          flex="1 auto"
          position="relative"
          minHeight="100vh"
        >
          <Placeholder
            isLoading={isLoading}
            ErrorComponent={isForbidden ? ForbiddenError : SystemFailError}
            error={error}
            withEmptyState={false}
          >
            {children}
          </Placeholder>
        </Box>
      </Wrap>
    </>
  );
};

export default memo(System);
