import React, { PureComponent } from 'react';

import { components } from 'react-select';

import HighlightText from 'components/HighlightText';

import { OptionStyled } from './SingleOption.styled';

class SingleOption extends PureComponent {
  render() {
    const { label, selectProps } = this.props;
    return (
      <div>
        <components.Option
          {...this.props}
          innerProps={{ ...this.props.innerProps, 'data-test-id': 'select-option-single' }}
        >
          <OptionStyled>
            {selectProps.withHighlight ? (
              <HighlightText search={selectProps.inputValue} text={label} />
            ) : (
              label
            )}
          </OptionStyled>
        </components.Option>
      </div>
    );
  }
}
export default SingleOption;
