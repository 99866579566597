import { compose } from 'redux';

import RootBoundary from 'components/@boundaries/RootBoundary';
import withBoundary from 'components/@boundaries/withBoundary';

import connectIntl from './intl';
import connectNotistack from './notistack';
import connectReactQuery from './react-query';
import connectRedux from './redux';
import connectSystem from './system';
import connectTheme from './theme';

export default compose(
  withBoundary(RootBoundary),
  connectTheme,
  connectRedux,
  connectReactQuery,
  connectIntl,
  connectNotistack,
  connectSystem,
);
