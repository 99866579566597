import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PolicyData } from 'services/Api/types/policies.types';
import { PolicyDetails } from 'services/Api/types/policies.types';
import { StoreDefaultState } from 'types/store/store.types';

import { PolicyIncapacityCause, PolicyIncapacityCausesFetchStartPayload } from './policy.types';
export type PolicyStateData = PolicyData & { details?: PolicyDetails };

export type PolicyState = {
  incapacityCauses: {
    isLoading: StoreDefaultState['isLoading'];
    error: StoreDefaultState['error'];
    data: PolicyIncapacityCause[];
  };
};

export const INITIAL_STATE: PolicyState = {
  incapacityCauses: {
    isLoading: false,
    error: undefined,
    data: [],
  },
};

const policySlice = createSlice({
  name: 'policy',
  initialState: INITIAL_STATE,
  reducers: {
    policyIncapacityCausesFetchStart: (
      state,
      _action: PayloadAction<PolicyIncapacityCausesFetchStartPayload>,
    ) => {
      state.incapacityCauses.isLoading = true;
      state.incapacityCauses.error = undefined;
      state.incapacityCauses.data = [];
    },
    policyIncapacityCausesFetchSuccess: (
      state,
      { payload }: PayloadAction<{ data: PolicyState['incapacityCauses']['data'] }>,
    ) => {
      state.incapacityCauses.isLoading = false;
      state.incapacityCauses.error = undefined;
      state.incapacityCauses.data = payload.data;
    },
    policyIncapacityCausesFetchFail: (
      state,
      { payload }: PayloadAction<{ error: PolicyState['incapacityCauses']['error'] }>,
    ) => {
      state.incapacityCauses.isLoading = false;
      state.incapacityCauses.error = payload.error;
    },
  },
});

export const {
  policyIncapacityCausesFetchStart,
  policyIncapacityCausesFetchSuccess,
  policyIncapacityCausesFetchFail,
} = policySlice.actions;

export default policySlice.reducer;
