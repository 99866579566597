import { withFormik } from 'formik';
import * as Yup from 'yup';

export const formikEnhancer = withFormik({
  mapPropsToValues: props => {
    const { initialValues: values } = props;
    const initialValues = values || {};
    return {
      comment: initialValues.comments || '',
    };
  },
  validationSchema: () =>
    Yup.object().shape({
      comment: Yup.string()
        .required()
        .max(5000),
    }),
});

export default formikEnhancer;
