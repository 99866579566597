import React, { useEffect, useRef } from 'react';

import { Box } from '@material-ui/core';
import { useFormikContext } from 'formik';
import usePrevious from 'hooks/usePrevious/usePrevious';

import { InputField } from 'components/@inputs/TextField';
import { CountrySelectField } from 'components/CountrySelect';

import AddressFieldBE from './AddressFieldBE';
import AddressFieldOther from './AddressFieldOther';

export type AddressFormValues = {
  street?: {
    inputValue?: string;
    selectedItem?: {
      attributes: { name: string };
      id: string;
      type: string;
    };
  };
  number?: string;
  numberExtension?: string;
  zipcode?: string;
  location?: string;
  locationBE?: {
    inputValue?: string;
    selectedItem?: {
      attributes: { name: string; zipcode: string };
      id: string;
      type: string;
    };
  };
  country: {
    value: string;
    label: string;
  };
  extra1?: string;
  extra2?: string;
  extra3?: string;
};

interface Props {
  isEdit?: boolean;
}

const AddressDataForm: React.FC<Props> = ({ isEdit = false }) => {
  const formik = useFormikContext<AddressFormValues>();
  const formikRef = useRef(formik);
  const country = formik.values?.country;
  const prevCountry = usePrevious(formik.values.country);

  useEffect(() => {
    if (prevCountry && prevCountry.value !== country.value) {
      formikRef.current = {
        ...formikRef.current,
        values: { ...formikRef.current.values, country },
      };
      formikRef.current.resetForm({
        values: {
          country: formikRef.current.values.country,
        },
      });
      setTimeout(async () => {
        // fucking formik validation and out of sync errros
        // why didn't react hook form exist when this project started --> cries in a corner
        formikRef?.current.validateForm();
      }, 10);
    }
  }, [country, prevCountry]);

  return (
    <>
      <Box mb={8}>
        <CountrySelectField
          fullWidth
          isRequired
          name="country"
          label="affiliations.add.step.address.country_label"
        />
      </Box>

      <Box mb={8}>{country.value === 'BE' ? <AddressFieldBE /> : <AddressFieldOther />}</Box>

      {isEdit && (
        <>
          {' '}
          <Box mb={8}>
            <InputField
              fullWidth
              name="extra1"
              label="affiliations.add.step.address.extra_1_address_label"
            />
          </Box>
          <Box mb={8}>
            <InputField
              fullWidth
              name="extra2"
              label="affiliations.add.step.address.extra_2_address_label"
            />
          </Box>{' '}
        </>
      )}
      <Box mb={8}>
        <InputField
          fullWidth
          name="extra3"
          label="affiliations.add.step.address.extra_address_label"
        />
      </Box>
    </>
  );
};

export default AddressDataForm;
