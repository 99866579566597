import * as Yup from 'yup';

import { validateIncapacityPeriodsOverlap } from 'utils/form';

const ValidationSchema = Yup.object()
  .shape({
    periods: Yup.array().of(
      Yup.object().shape({
        attributes: Yup.object().shape({
          percentage: Yup.number()
            .min(0.01, params => ({ id: 'general.errors.number.min', values: { min: params.min } }))
            .max(100, params => ({ id: 'general.errors.number.max', values: { min: params.max } }))
            .required('general.errors.required'),
          startDate: Yup.date()
            .typeError('general.errors.required')
            .required('general.errors.required'),
          endDate: Yup.date()
            .typeError('general.errors.required')
            .required('general.errors.required'),
        }),
      }),
    ),
  })
  .test('dates', 'error', function(value) {
    if (!value) return true;

    const checkedPeriods = validateIncapacityPeriodsOverlap([...value.periods]);
    return (
      !checkedPeriods.overlap ||
      this.createError({
        // Formik will use the error "path" as the key in the errors; set this to whatever you want
        path: 'overlap',
        message: 'general.errors.periods_overlap',
        params: { dates: checkedPeriods.ranges },
      })
    );
  });

export { ValidationSchema };
