import React from 'react';

import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { NumberFormatProps } from 'react-number-format';

import { getFieldErrors } from 'utils/form';

import Field, { FieldProps } from 'components/@inputs/Field';
import { Props as TextInputProps } from 'components/@inputs/TextField/TextInput';

import NumberInput from './Number';
import { NumberFormatValue } from './types';

export interface NumberFieldProps extends FieldProps, Omit<NumberFormatProps, 'type' | 'name'> {
  type?: string;
}

const NumberField: React.FC<NumberFieldProps & Omit<TextInputProps, 'fieldType'>> = ({
  name,
  label,
  bottomLabel,
  placeholder,
  disabled,
  fullWidth,
  isRequired,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const errors = getFieldErrors(name, meta);
  const intl = useIntl();

  const handleChange = (e: NumberFormatValue) => {
    helpers.setValue(e.value);
  };

  return (
    <Field
      label={label}
      errors={errors}
      bottomLabel={bottomLabel}
      name={name}
      fullWidth={fullWidth}
      isRequired={isRequired}
    >
      <NumberInput
        id={name}
        disabled={disabled}
        isNumericString
        error={errors.length > 0}
        placeholder={placeholder && intl.formatMessage({ id: placeholder })}
        fullWidth={fullWidth}
        variant={field.value ? 'filled' : undefined}
        {...field}
        {...rest}
        onChange={() => {}}
        name={name}
        onValueChange={handleChange}
        data-test-id={name}
      />
    </Field>
  );
};

export default NumberField;
