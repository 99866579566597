import React from 'react';

import { StoreDefaultState } from 'types/store/store.types';

import { notEmpty } from 'utils/typescript.helpers';

import Placeholder from 'components/Placeholders';

import { DynamicField, DynamicTable, Section } from './components';
import DynamicDetailsLoading from './DynamicDetailsLoading';
import { DynamicContent } from './types';

interface Props {
  isLoading?: boolean;
  error?: StoreDefaultState['error'];
  data?: DynamicContent | DynamicContent[];
  filledIn?: boolean;
}

const DynamicDetails: React.FC<Props> = ({ data, isLoading, error, filledIn = false }) => {
  const arrData = Array.isArray(data) ? data : [data];
  const contents = arrData.filter(notEmpty);
  return (
    <Placeholder
      LoaderComponent={DynamicDetailsLoading}
      isLoading={isLoading}
      error={error}
      type="inline"
      data={data}
      withEmptyState={false}
    >
      {contents.map((mainSection, key) => {
        return (
          <Section
            key={`mainSection${key}`}
            title={mainSection.title}
            description={mainSection.description}
            filledIn={filledIn}
            p={{ xs: 4, md: 0 }}
          >
            {mainSection.sections?.map((section, i) => (
              <Section
                key={`section${i}`}
                isSubSection
                title={section?.title}
                description={section?.description}
              >
                {!section.headers ? (
                  section.fields?.map((field, i) => (
                    <DynamicField field={field} key={field?.key ?? `field${i}`} />
                  ))
                ) : (
                  <DynamicTable fields={section.fields} headers={section.headers} />
                )}
              </Section>
            ))}
          </Section>
        );
      })}
    </Placeholder>
  );
};

export default DynamicDetails;
