import styled from 'styled-components';

import { Theme } from 'types/branding.types';

export const Wrap = styled.div`
  font-size: 14px;
  line-height: 1.57;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  font-weight: 400;
  display: table-caption;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(1) * 3}px;
`;

export const SelectWrap = styled.div`
  display: flex;
  padding: 0 ${({ theme }) => theme.spacing(1) * 7}px;
  justify-content: center;
`;

const selectStyle = {
  control: () => ({
    display: 'flex',
    justifyContent: 'center',
    height: 24,
    paddingLeft: 0,
    border: 'none',
  }),
  singleValue: () => ({
    maxWidth: 'auto',
    textAlign: 'right',
    width: '88%',
    color: (theme: Theme) => theme.palette.neutral01[500],
  }),
};

export const monthSelectStyle = {
  control: () => ({
    ...selectStyle.control(),
    width: 100,
    marginRight: 8,
  }),
  dropdownIndicator: () => ({
    padding: 0,
  }),
  singleValue: () => ({ ...selectStyle.singleValue() }),
};

export const yearSelectStyle = {
  control: () => ({
    ...selectStyle.control(),
    width: 70,
  }),
  dropdownIndicator: () => ({
    padding: 0,
  }),
  singleValue: () => ({ ...selectStyle.singleValue() }),
};
