import React from 'react';

import { FormattedMessage } from 'react-intl';

import { ROUTES } from 'constants/store';
import { UserData } from 'store/user/user.types';

import Can from 'components/Auth';
import Icon from 'components/Icon';
import { UIcoFeedback, UIcoInstellingen, UIcoSignout } from 'components/Icons';

import { LinkButtonStyled, UserActionsWrap, UserMail } from './UserMenu.styled';

interface Props {
  user?: UserData;
  onChange: (event: React.MouseEvent, value: string) => void;
}

const UserMenu: React.FC<Props> = ({ user, onChange }) => {
  return (
    <UserActionsWrap>
      <UserMail>{user?.attributes?.email}</UserMail>
      <Can I="visit" a="FEEDBACK">
        <LinkButtonStyled
          onClick={(event: React.MouseEvent) => onChange(event, 'FEEDBACK')}
          color="neutral01.500"
          hasUnderline={false}
        >
          <Icon color="brand01.600" margin="right" spacing={8}>
            <UIcoFeedback />
          </Icon>
          <FormattedMessage id="general.menu.feedback" />
        </LinkButtonStyled>
      </Can>
      <Can I="visit" a={ROUTES.SETTINGS}>
        <LinkButtonStyled
          color="neutral01.500"
          onClick={(event: React.MouseEvent) => onChange(event, 'SETTINGS')}
          hasUnderline={false}
        >
          <Icon color="brand01.600" margin="right" spacing={8}>
            <UIcoInstellingen />
          </Icon>
          <FormattedMessage id="general.menu.settings" />
        </LinkButtonStyled>
      </Can>
      <LinkButtonStyled
        onClick={(event: React.MouseEvent) => onChange(event, 'LOGOUT')}
        color="neutral01.500"
        hasUnderline={false}
      >
        <Icon color="brand01.600" margin="right" spacing={8}>
          <UIcoSignout />
        </Icon>
        <FormattedMessage id="general.menu.logout" />
      </LinkButtonStyled>
    </UserActionsWrap>
  );
};
export default UserMenu;
