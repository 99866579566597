import { call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchDownloadInvoice, fetchInvoice } from 'api/invoice.api';
import { ROUTES } from 'constants/store';
import { getGroupId } from 'store/client/client.selectors';
import { getInvoicesEndpoint } from 'store/invoices/invoices.selectors';
import { addNotification } from 'store/notifications/notifications.actions';
import { goToPage } from 'store/routing/routing.actions';
import { getPathParam } from 'store/routing/routing.selectors';

import { downloadFileFromBinaryResponse } from 'utils/helpers';

import {
  invoiceDownloadFetchFail,
  invoiceDownloadFetchStart,
  invoiceDownloadFetchSuccess,
  invoiceFetchFail,
  invoiceFetchStart,
  invoiceFetchSuccess,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './invoice.actions';

function* invoiceFetchFlow(action) {
  const id = action.payload;
  try {
    const path = yield select(getInvoicesEndpoint);
    const invoice = yield call(fetchInvoice, path, id);
    yield put(
      invoiceFetchSuccess({
        data: {
          relationships: invoice.relationships,
          [id]: { ...invoice.data },
        },
      }),
    );
  } catch (error) {
    yield put(invoiceFetchFail({ error }));
  }
}
function* invoiceDownloadFetchFlow(action) {
  const { id, fileName } = action.payload;
  try {
    const path = yield select(getInvoicesEndpoint);
    const response = yield call(fetchDownloadInvoice, path, id);
    downloadFileFromBinaryResponse(response, fileName);
    yield put(invoiceDownloadFetchSuccess());
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'invoices.download.error',
      }),
    );
    yield put(invoiceDownloadFetchFail({ error }));
  }
}
// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

export function* bootInvoiceDetail() {
  const groupId = yield select(getGroupId);
  if (groupId) {
    const invoiceId = yield select(getPathParam);
    yield put(invoiceFetchStart(invoiceId));
  } else {
    yield put(goToPage(ROUTES.INVOICES));
  }
}

function* invoiceSaga() {
  yield takeLatest(invoiceFetchStart.getType(), invoiceFetchFlow);
  yield takeLatest(invoiceDownloadFetchStart.getType(), invoiceDownloadFetchFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default invoiceSaga;
