import { createSelector } from '@reduxjs/toolkit';

import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { Store } from 'types/store/store.types';

import { notEmpty } from 'utils/typescript.helpers';

import { claimReportsAdapter } from './claimReports.slice';

export const claimReportsSelector = claimReportsAdapter.getSelectors<Store>(
  state => state.claimReports,
);

export const getClaimReport = (state: Store, id: string) =>
  claimReportsSelector.selectById(state, id);
export const getClaimReportsError = (state: Store) => state.claimReports.error;
export const getClaimReportsResult = (state: Store) => state.claimReports.result;
export const getClaimReportsStatus = (state: Store) => state.claimReports.status;
export const getClaimReportsMeta = (state: Store) => state.claimReports.meta;

export const getClaimReports = createSelector(
  [(state: Store) => getClaimReportsResult(state), (state: Store) => state],
  (results, state) => results?.map(id => getClaimReport(state, id)).filter(notEmpty),
);

export const getClaimReportRequestStatus = (state: Store) => state.claimReports.stepper.status;

export const getClaimReportsDownloading = (state: Store) => state.claimReports.downloading;
export const getClaimReportEndpoint = (state: Store) => getEndpoint(state, 'claimReports');
