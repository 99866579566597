import _get from 'lodash/get';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { placeholderShimmer } from '../animations.styled';
import { SkeletonLineProps } from './SkeletonLine';

export const SkeletonItem = styled.div<SkeletonLineProps>`
  width: 100%;
  ${({ minWidth }) => (minWidth && `min-width: ${minWidth}px`) || ''};
  ${({ maxWidth }) => (maxWidth && `max-width: ${maxWidth}px`) || ''};
  ${({ marginBottom }) => (marginBottom && `margin-bottom:  ${marginBottom}px`) || ''};
  height: ${({ theme, height }) => height || theme.spacing(3)}px;
  animation-duration: ${({ duration }) => `${duration}`}s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeholderShimmer};
  animation-timing-function: linear;
  background: ${({ theme, color, opacity }) =>
    `linear-gradient(to right, ${transparentize(opacity, _get(theme.palette, color, ''))} 8%, ${
      theme.palette.neutral01[200]
    }  18%, ${transparentize(opacity, _get(theme.palette, color, ''))} 33%)`};
  ${({ delay }) => (delay && ` animation-delay: ${delay}s`) || ''};
  background-size: 800px 104px;
  border-radius: ${({ borderRadius }) => borderRadius}px;
`;

export default null;
