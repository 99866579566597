import { call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchPolicyIncapacityCauses } from 'api/policy.api';
import { addNotification } from 'store/notifications/notifications.actions';

import { getPoliciesEndpoint } from './policy.selectors';
import {
  policyIncapacityCausesFetchFail,
  policyIncapacityCausesFetchStart,
  policyIncapacityCausesFetchSuccess,
} from './policy.slice';

function* policyIncapacityCausesFetchFlow(
  action: ReturnType<typeof policyIncapacityCausesFetchStart>,
) {
  try {
    const { id } = action.payload;
    const path = yield select(getPoliciesEndpoint);
    const { data } = yield call(fetchPolicyIncapacityCauses, path, id);
    yield put(policyIncapacityCausesFetchSuccess({ data }));
  } catch (error) {
    yield put(
      addNotification({
        variant: 'error',
        message: 'general.error.text',
      }),
    );
    yield put(policyIncapacityCausesFetchFail({ error }));
  }
}

function* policySaga() {
  yield takeLatest(policyIncapacityCausesFetchStart, policyIncapacityCausesFetchFlow);
}

export default policySaga;
