import styled from 'styled-components';

import { LinkButton } from 'components/@common/Link';

export const Action = styled(LinkButton)`
  color: ${({ theme }) => theme.palette.neutral01[50]};
  text-decoration: none;
  font-weight: 700;
  &:hover {
    color: inherit;
  }
`;
