import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import Link from 'components/@common/Link';
import { CheckboxField } from 'components/@inputs/Checkbox';

const PrivacySettingsFields: React.FC = () => (
  <>
    <Box>
      <Typography variant="h5" color="textPrimary">
        <FormattedMessage id="profile.user.labels.personalisation" />
      </Typography>
    </Box>
    <Box>
      <CheckboxField
        label="profile.user.privacy.cookies"
        name="cookies.personal"
        disabled
        checked
      />
    </Box>
    <Box ml={8} mb={6}>
      <FormattedMessage id="profile.user.links.personalisation">
        {link => (
          <Link hasUnderline to={`${link}`} target="_blank">
            <FormattedMessage id="profile.user.privacy.more_info_cookies" />
          </Link>
        )}
      </FormattedMessage>
    </Box>
    <Box>
      <Typography variant="h5" color="textPrimary">
        <FormattedMessage id="profile.user.labels.cookies" />
      </Typography>
    </Box>
    <Box>
      <CheckboxField label="profile.user.privacy.analytics" name="cookies.analytics" />
    </Box>
    <Box ml={8} mb={8}>
      <FormattedMessage id="profile.user.links.cookies">
        {link => (
          <Link hasUnderline to={`${link}`} target="_blank">
            <FormattedMessage id="profile.user.privacy.more_info_analytics" />
          </Link>
        )}
      </FormattedMessage>
    </Box>
  </>
);

export default PrivacySettingsFields;
