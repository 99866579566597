import { transparentize } from 'polished';
import styled from 'styled-components';

export const FileWrap = styled.div`
  margin-top: ${({ theme }) => theme.spacing(6)}px;
  padding: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(4)}px`};
  background: ${({ theme }) => transparentize(0.8, theme.palette.neutral01[300])};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
`;

export default null;
