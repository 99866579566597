import { connect } from 'react-redux';
import { EmptyActionCreator, SimpleActionCreator } from 'redux-act';

import {
  claimSubmissionEmployeesFetchStart,
  claimSubmissionEmployeesReset,
  claimSubmissionSetSelectedPolicy,
} from 'store/claimSubmission/claimSubmission.actions';
import {
  getClaimSubmissionPolicies,
  getEmployees,
  getEmployeesAreLoading,
  getEmployeesError,
  getEmployeesMeta,
  getSelectedPolicy,
} from 'store/claimSubmission/claimSubmission.selectors';
import {
  ClaimSubmissionEmployeesState,
  ClaimSubmissionPolicy,
  ClaimSubmissionPolicyState,
  EmployeesFetchPayload,
} from 'store/claimSubmission/types';
import { Store } from 'types/store/store.types';

import ClaimSubmissionEmployeeAdvancedSearch from './ClaimSubmissionEmployeeAdvancedSearch';

export interface ContainerProps {
  employees: ClaimSubmissionEmployeesState['data'];
  isLoading: ClaimSubmissionEmployeesState['isLoading'];
  error: ClaimSubmissionEmployeesState['error'];
  fetchEmployees: SimpleActionCreator<EmployeesFetchPayload>;
  resetEmployees: EmptyActionCreator;
  policies: ClaimSubmissionPolicyState['data'];
  meta: ClaimSubmissionEmployeesState['meta'];
  closeModal: () => void;
  selectedPolicy: ClaimSubmissionPolicy;
}

const mapStateToProps = (state: Store) => ({
  policies: getClaimSubmissionPolicies(state),
  selectedPolicy: getSelectedPolicy(state),
  employees: getEmployees(state),
  isLoading: getEmployeesAreLoading(state),
  error: getEmployeesError(state),
  meta: getEmployeesMeta(state),
});
const mapDispatchToProps = {
  resetEmployees: claimSubmissionEmployeesReset,
  fetchEmployees: claimSubmissionEmployeesFetchStart as any, //TODO: remove typecasting here because store domain is not converted to ts so it sees it as an emptyactioncreator but it is not
  setSelectedPolicy: claimSubmissionSetSelectedPolicy,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimSubmissionEmployeeAdvancedSearch);
