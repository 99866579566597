import { ReactComponent as UIcoAansprakelijkheid } from 'assets/svg/u_ico_aansprakelijkheid.svg';
import { ReactComponent as UIcoArbeidsOngeschiktheid } from 'assets/svg/u_ico_arbeidsongeschiktheid.svg';
import { ReactComponent as UIcoCyber } from 'assets/svg/u_ico_cyber.svg';
import { ReactComponent as UIcoFallback } from 'assets/svg/u_ico_fallback.svg';
import { ReactComponent as UIcoFinancial } from 'assets/svg/u_ico_financial.svg';
import { ReactComponent as UIcoLAansprakelijkheid } from 'assets/svg/u_ico_l_aansprakelijkheid.svg';
import { ReactComponent as UIcoLArbeidsOngeschiktheid } from 'assets/svg/u_ico_l_arbeidsongeschiktheid.svg';
import { ReactComponent as UIcoLCyber } from 'assets/svg/u_ico_l_cyber.svg';
import { ReactComponent as UIcoLFallback } from 'assets/svg/u_ico_l_fallback.svg';
import { ReactComponent as UIcoLFinancial } from 'assets/svg/u_ico_l_financial.svg';
import { ReactComponent as UIcoLMarine } from 'assets/svg/u_ico_l_marine.svg';
import { ReactComponent as UIcoLMaterialdamageinsurance } from 'assets/svg/u_ico_l_materialdamageinsurance 1.svg';
import { ReactComponent as UIcoLPeople } from 'assets/svg/u_ico_l_people.svg';
import { ReactComponent as UIcoLProfessionalactivities } from 'assets/svg/u_ico_l_professionalactivities.svg';
import { ReactComponent as UIcoLSchade } from 'assets/svg/u_ico_l_schade.svg';
import { ReactComponent as UIcoLTransportCmr } from 'assets/svg/u_ico_l_transport_crm.svg';
import { ReactComponent as UIcoLVerhiclesFleet } from 'assets/svg/u_ico_l_vehicles_fleet.svg';
import { ReactComponent as UIcoMarine } from 'assets/svg/u_ico_marine.svg';
import { ReactComponent as UIcoMaterialdamageinsurance } from 'assets/svg/u_ico_materialdamageinsurance.svg';
import { ReactComponent as UIcoPeople } from 'assets/svg/u_ico_people.svg';
import { ReactComponent as UIcoSProfessionalactivities } from 'assets/svg/u_ico_s_professionalactivities.svg';
import { ReactComponent as UIcoSchade } from 'assets/svg/u_ico_schade.svg';
import { ReactComponent as UIcoTransport } from 'assets/svg/u_ico_transport_crm.svg';
import { ReactComponent as UIcoVerhiclesFleet } from 'assets/svg/u_ico_verhicles_fleet.svg';

import { DEFAULT_PARAMS } from 'constants/default.queryparams';

const TYPE_INTL_KEY = 'claims.filter.dropdowns';

export const TYPES: { [index: string]: { icon: React.FC; iconLarge: React.FC } } = {
  ALL: {
    icon: UIcoSchade,
    iconLarge: UIcoLSchade,
  },
  DEFAULT: {
    icon: UIcoFallback,
    iconLarge: UIcoLFallback,
  },
  PEOPLE: {
    icon: UIcoPeople,
    iconLarge: UIcoLPeople,
  },
  FLEET: {
    icon: UIcoVerhiclesFleet,
    iconLarge: UIcoLVerhiclesFleet,
  },
  MATERIAL: {
    icon: UIcoMaterialdamageinsurance,
    iconLarge: UIcoLMaterialdamageinsurance,
  },
  CMR: {
    icon: UIcoTransport,
    iconLarge: UIcoLTransportCmr,
  },
  MARINE: {
    icon: UIcoMarine,
    iconLarge: UIcoLMarine,
  },
  LIABILITY: {
    icon: UIcoAansprakelijkheid,
    iconLarge: UIcoLAansprakelijkheid,
  },
  CYBER: {
    icon: UIcoCyber,
    iconLarge: UIcoLCyber,
  },
  FINANCIAL: {
    icon: UIcoFinancial,
    iconLarge: UIcoLFinancial,
  },
  OCCUPATION: {
    icon: UIcoSProfessionalactivities,
    iconLarge: UIcoLProfessionalactivities,
  },
  IC: {
    icon: UIcoArbeidsOngeschiktheid,
    iconLarge: UIcoLArbeidsOngeschiktheid,
  },
};

export const STATUSES: { [index: string]: { intlKey: string; badgeColor: string } } = {
  OPEN: { intlKey: `${TYPE_INTL_KEY}.open`, badgeColor: 'default' },
  REOPENED: {
    intlKey: `${TYPE_INTL_KEY}.reopened`,
    badgeColor: 'default',
  },
  CLOSED: { intlKey: `${TYPE_INTL_KEY}.closed`, badgeColor: 'success' },
  PENDING: { intlKey: `${TYPE_INTL_KEY}.pending`, badgeColor: 'warning' },
  ACTION_REQUIRED: {
    intlKey: `${TYPE_INTL_KEY}.action_required`,
    badgeColor: 'alert',
  },
  ACCEPTED: {
    intlKey: `${TYPE_INTL_KEY}.accepted`,
    badgeColor: 'default',
  },
  REJECTED: {
    intlKey: `${TYPE_INTL_KEY}.rejected`,
    badgeColor: 'warning',
  },
  WITHOUT_PAYMENT: {
    intlKey: `${TYPE_INTL_KEY}.without_payment`,
    badgeColor: 'default',
  },
  INTREATMENT: {
    intlKey: `${TYPE_INTL_KEY}.in_treatment`,
    badgeColor: 'default',
  },
};

export const QUERY_PARAMS = {
  STARTED_AT_MIN: 'startedAtMin',
  START_AT_MAX: 'startedAtMax',
  CLAIM_CATEGORY_ID: 'claimCategoryId',
  CLAIM_STATUS: 'claimStatus',
  POLICY_ID: 'policyId',
  RISK_ID: 'riskId',
  ...DEFAULT_PARAMS,
};

export const FIELD = {
  text: 'TEXT',
};

export const HIDDEN_FILTERS = ['policyId', 'riskId'];
