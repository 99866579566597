import React, { useEffect, useRef } from 'react';

import { Box } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

import {
  AffiliationPolicy,
  AffiliationTerminationReason,
} from 'store/affiliationSubmission/affiliationSubmission.types';

import useRouter from 'utils/useRouter';

import { FlowType } from 'pages/affiliations/Create/AffiliationsCreate';
import AffiliationCardContent from 'pages/affiliations/Create/AffiliationsCreateStepper/Steps/AffiliationStep/AffiliationCardContent';

import { CheckboxField } from 'components/@inputs/Checkbox';
import { CheckboxTile, CheckboxTileGroupField } from 'components/@inputs/CheckboxTiles';
import { DatePickerInputField } from 'components/@inputs/DatePicker';
import { SelectField } from 'components/@inputs/Select';
import InfoBanner from 'components/InfoBanner';
import Notification from 'components/Notification';

interface Props {
  affiliations?: AffiliationPolicy[];
  terminationReasons: AffiliationTerminationReason[];
  isEdit?: boolean;
  affiliationsLoading?: boolean;
  flowType?: FlowType;
}

export type AffiliationFormValues = {
  affiliations?: { [id: string]: AffiliationPolicy };
  startDate?: string;
  endDate?: string;
  reason?: {
    label: string;
    value: string;
  };
  isBelated?: boolean;
  belatedDate?: string;
};

const AffiliationDataForm: React.FC<Props> = ({
  affiliations = [],
  terminationReasons,
  affiliationsLoading,
  isEdit = false,
}) => {
  const hasAffiliationStartDateRestrictions =
    affiliations.filter(affiliation => !!affiliation?.attributes?.minAffiliationStartDate).length >
    0;
  const { values, setFieldValue } = useFormikContext<AffiliationFormValues>();
  const setFieldValueRef = useRef(setFieldValue);
  const isBelated = values.isBelated;

  const { routerState, push } = useRouter();

  const handleBackToDetail = () => {
    push(routerState.prev.pathname);
  };

  useEffect(() => {
    if (!isBelated) {
      setFieldValueRef.current('belatedDate', null);
    }
  }, [isBelated]);

  return (
    <>
      {!isEdit && (
        <Box mb={8}>
          {affiliations.length > 0 || affiliationsLoading ? (
            <>
              <CheckboxTileGroupField
                fullWidth
                isRequired
                isLoading={affiliationsLoading}
                name="affiliations"
                label="affiliations.add.step.affiliation.affiliation_policy_label"
              >
                {affiliations?.map((affiliation, i) => (
                  <CheckboxTile
                    data-test-id={`affiliations.add.step.affiliation_${i}`}
                    key={affiliation.id}
                    name={affiliation.id}
                    value={affiliation}
                  >
                    <AffiliationCardContent affiliation={affiliation} />
                  </CheckboxTile>
                ))}
              </CheckboxTileGroupField>
            </>
          ) : (
            <Notification
              description="affiliations.add.affiliation.empty_notification.description"
              actions={['affiliations.add.back_to_detail_button']}
              onActionClick={handleBackToDetail}
              canClose={false}
              variant="warning"
              id={1}
              fullWidth
              message="affiliations.add.affiliation.empty_notification.title"
            />
          )}
        </Box>
      )}
      {hasAffiliationStartDateRestrictions && (
        <Box mb={8}>
          <InfoBanner isOpen>
            <FormattedMessage id="affiliations.add.step.affiliation.min_start_date_warning" />
          </InfoBanner>
        </Box>
      )}

      {(affiliationsLoading || affiliations.length > 0 || isEdit) && (
        <>
          <Box mb={8}>
            <DatePickerInputField
              name="startDate"
              label="affiliations.add.step.affiliation.start_date_label"
              isFillable
              inputWidth={{ xs: '100%', md: '50%' }}
              fullWidth
              isRequired
              isRangeSelect={false}
              backdropProps={{
                zIndex: 1499,
              }}
            />
          </Box>
          <Box mb={8} width={{ xs: '100%', md: '50%' }}>
            <DatePickerInputField
              name="endDate"
              label="affiliations.add.step.affiliation.end_date_label"
              isFillable
              fullWidth
              isRangeSelect={false}
              backdropProps={{
                zIndex: 1499,
              }}
            />
          </Box>
          {values.endDate && (
            <Box mb={8} width={{ xs: '100%', md: '50%' }}>
              <SelectField
                name="reason"
                label="affiliations.add.step.affiliation.reason_label"
                isRequired
                fullWidth
                options={terminationReasons.map(reason => ({
                  label: reason.attributes.title,
                  value: reason.id,
                }))}
              />
            </Box>
          )}
          <Box mb={8}>
            <CheckboxField
              name="isBelated"
              isRequired
              label="affiliations.add.step.affiliation.is_belated_label"
            />
          </Box>
          {values.isBelated && (
            <Box mb={8} width={{ xs: '100%', md: '50%' }}>
              <DatePickerInputField
                name="belatedDate"
                label="affiliations.add.step.affiliation.is_belated_date_label"
                isFillable
                fullWidth
                isRequired
                isRangeSelect={false}
                backdropProps={{
                  zIndex: 1499,
                }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default AffiliationDataForm;
