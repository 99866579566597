import React from 'react';

import MuiInput from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

const errorStyle = css`
  border: 2px solid ${({ theme }) => theme.palette.accent02[400]};
`;

const focusStyle = css`
  border: 2px solid ${({ theme }) => theme.palette.brand01[300]};
`;

const disabledStyle = css`
  background: ${({ theme }) => transparentize(0.6, theme.palette.neutral01[300])};

  input {
    color: ${({ theme }) => theme.palette.neutral01[500]};
  }
`;

const highlightValueStyle = css`
  background: ${({ theme }) => theme.palette.accent01[100]};
`;

const autoHeightStyle = css`
  flex-wrap: wrap;
  input {
    width: auto;
  }
`;

const defaultStyle = css`
  height: ${({ multiline }) => (multiline ? 'auto' : '48px')};
  input {
    width: 100%;
  }
`;

const invertedStyle = css`
  background-color: ${({ theme }) => theme.palette.brand01[600]};
  border-color: ${({ theme }) => theme.palette.brand01[600]};
  input {
    &:placeholder {
      color: ${({ theme }) => theme.palette.neutral01[50]};
    }
    &::-webkit-input-placeholder {
      color: ${({ theme }) => theme.palette.neutral01[50]};
    }
    &:-moz-placeholder {
      color: ${({ theme }) => theme.palette.neutral01[50]};
    }
    &::-moz-placeholder {
      color: ${({ theme }) => theme.palette.neutral01[50]};
    }
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.palette.neutral01[50]};
    }
    &.active {
      border-color: ${({ theme }) => theme.palette.brand01[600]};
    }
  }
  path {
    stroke: ${({ theme }) => theme.palette.neutral01[50]};
  }
`;

export const BottomLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.57;
  color: ${({ theme, hasError }) =>
    hasError ? theme.palette.accent02[400] : theme.palette.neutral01[500]};
  margin-top: 2px;
  margin-top: 2px;
`;

export const Input = styled(
  ({
    focus,
    shouldFitContainer,
    width,
    minWidth,
    hasError,
    withInputHighlight,
    hasValue,
    autoHeight,
    inverted,
    ...rest
  }) => <MuiInput {...rest} />,
)`
  && {
    color: ${({ theme }) => theme.palette.neutral01[600]};
    line-height: 1.5;
    padding: ${({ padding }) => padding || '8px 16px'};
    min-height: 48px;
    overflow: hidden;
    width: ${({ width }) => width && `${width}px`};
    min-width: ${({ minWidth }) => minWidth && `${minWidth}px`};
    border-radius: 4px;
    background: ${({ theme }) => theme.palette.neutral01[50]};
    border: 2px solid ${({ theme }) => theme.palette.neutral01[300]};
    transition: all 150ms ease-in-out;
    align-items: center;
    ${({ autoHeight }) => (autoHeight ? autoHeightStyle : defaultStyle)};
    input {
      padding: 0;
      height: 24px;
      text-overflow: ellipsis;
      &::-ms-clear {
        display: none;
      }
      &::-webkit-input-placeholder {
        text-overflow: ellipsis;
        opacity: 1;
        color: ${({ theme }) => theme.palette.neutral01[500]};
        line-height: 1.5;
        font-weight: 400;
      }
      &:-moz-placeholder {
        text-overflow: ellipsis;
        opacity: 1;
        color: ${({ theme }) => theme.palette.neutral01[500]};
        line-height: 1.5;
        font-weight: 400;
      }
      &::-moz-placeholder {
        text-overflow: ellipsis;
        opacity: 1;
        color: ${({ theme }) => theme.palette.neutral01[500]};
        line-height: 1.5;
        font-weight: 400;
      }
      &:-ms-input-placeholder {
        text-overflow: ellipsis;
        opacity: 1;
        color: ${({ theme }) => theme.palette.neutral01[500]};
        line-height: 1.5;
        font-weight: 400;
      }
    }

    input:placeholder {
      color: ${({ theme }) => theme.palette.neutral01[500]};
      line-height: 1.5;
      font-weight: 400;
    }

    &.text-input-field--focused {
      ${({ hasError }) => (!hasError && focusStyle) || ''};
    }

    ${({ shouldFitContainer }) => (shouldFitContainer && `width: 100%`) || ''};
    ${({ hasValue, hasError, withInputHighlight, disabled }) => {
      if (hasError) {
        // error style
        return errorStyle;
      } else if (hasValue && withInputHighlight) {
        return highlightValueStyle;
      } else if (disabled) {
        return disabledStyle;
      } else {
        return null;
      }
    }};
    ${({ inverted }) => (inverted ? invertedStyle : '')};
  }
`;

export const InputAdornmentStyled = styled(InputAdornment)`
  color: ${({ theme }) => theme.palette.brand01[200]};
  font-weight: normal;
  ${({ position }) =>
    (position === 'start' && `margin-right: 10px`) || (position === 'end' && `margin-left: 10px`)};
`;
