import { ApiInstanceType } from './';
import { LocationRespsonse, LocationStreetRespsonse } from './types/locations.types';

class LocationsApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchLocations(path: string, search: string) {
    return this.api
      .get<LocationRespsonse>(path, {
        params: { q: search },
      })
      .then(response => response.data);
  }

  fetchLocationStreets(path: string, search: string) {
    return this.api
      .get<LocationStreetRespsonse>(path, {
        params: { q: search },
      })
      .then(response => response.data);
  }
}

export default LocationsApi;
