import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ShowModalPayload } from './types';

export type ModalState = {
  currentModal?: ShowModalPayload['modalProps'];
  isOpen: boolean;
};

export const INITIAL_STATE: ModalState = {
  currentModal: undefined,
  isOpen: false,
};

const insurerSlice = createSlice({
  name: 'modal',
  initialState: INITIAL_STATE,
  reducers: {
    modalShow: (state, { payload }: PayloadAction<ShowModalPayload>) => {
      state.isOpen = true;
      state.currentModal = payload.modalProps;
    },
    modalHide: state => {
      state.isOpen = false;
      state.currentModal = undefined;
    },
  },
});

export const { modalShow, modalHide } = insurerSlice.actions;

export default insurerSlice.reducer;
