import React from 'react';

import { Box } from '@material-ui/core';

import { SkeletonLine } from 'components/Loaders';

const SKELETON_WIDTHS = [160, 320, 240];

const DynamicDetailsLoading: React.FC = () => (
  <>
    {new Array(3).fill(null).map((_, i) => (
      <Box mb={6} key={i}>
        <SkeletonLine duration={2} marginBottom={22} maxWidth={112} height={18} />
        {SKELETON_WIDTHS.map((width, j) => (
          <Box mt={4} display="flex" key={j}>
            <Box mr={10} minWidth={72}>
              <SkeletonLine duration={2} maxWidth={72} height={16} opacity={0.5} />
            </Box>
            <SkeletonLine duration={2} maxWidth={width} height={16} opacity={0.5} />
          </Box>
        ))}
      </Box>
    ))}
  </>
);

export default DynamicDetailsLoading;
