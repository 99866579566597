import React from 'react';

import { Box, Grid } from '@material-ui/core';

import { DynamicField } from '../types';
import HintTooltip from './HintTooltip';

interface Props {
  field: DynamicField;
}

const Field: React.FC<Props> = ({ field }) => {
  if (!field.title && field.values?.filter(Boolean)?.length <= 0) return null;
  return (
    <>
      <Box mb={4} lineHeight={1.5}>
        <Grid container spacing={3}>
          <Grid item xs={5} md={5}>
            <Box pr={2} color="brand01.400">
              {field.title}
              {field.hint && <HintTooltip hint={field.hint} />}
            </Box>
          </Grid>
          <Grid item xs={7} md={7}>
            <Box whiteSpace="pre-wrap" color="neutral01.600">
              {field.values.map((value, i) => (
                <div key={`value${i}`}>{value}</div>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Field;
