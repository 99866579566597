import React from 'react';

import { Box, Grid } from '@material-ui/core';
import { ReactComponent as UIcoExternalLink } from 'assets/svg/u_ico_external_link.svg';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { analyticsService, authService } from 'services';
import { getPublicEndpoints } from 'store/endpoint/endpoint.selectors';
import { UserData } from 'store/user/user.types';

import { redirect } from 'utils/helpers';
import useFeatures from 'utils/useFeatureToggle';

import Button from 'components/@common/Button';
import { InputField } from 'components/@inputs/TextField';
import { SummaryField } from 'components/FormSummary';
import Icon from 'components/Icon';

interface Props {
  user?: UserData;
}

const LoginSettingsFields: React.FC<Props> = ({ user }) => {
  const endpoints = useSelector(getPublicEndpoints);

  const handleRedirectToIdentity = () => {
    analyticsService.events.trackIdentityRedirect();
    redirect(endpoints?.links?.identityPlatform, '_blank');
  };

  const handleChangePassword = () => {
    const url = authService.okta?.options?.url;
    redirect(`${url}/enduser/settings`);
  };

  const { isFeatureEnabled } = useFeatures();

  if (!isFeatureEnabled('identity')) {
    return (
      <Box mt={1}>
        <Box mb={8}>
          <InputField
            label="profile.user.labels.email"
            bottomLabel="profile.user.labels.email.bottom_label"
            name="email"
            isRequired
            disabled
            fullWidth
          />
        </Box>
        <Button onClick={handleChangePassword}>
          <FormattedMessage id="profile.settings.edit_password" />
          <Box clone mt={-1 / 2}>
            <Icon spacing={4} margin="left" color="neutral01.50">
              <UIcoExternalLink />
            </Icon>
          </Box>
        </Button>
      </Box>
    );
  }
  if (!user) {
    return null;
  }
  return (
    <Box mt={1} mb={8}>
      <Box mb={6}>
        <SummaryField label="profile.user.labels.name" nowrap value={user.attributes.firstName} />
        <SummaryField label="profile.user.labels.surname" nowrap value={user.attributes.lastName} />
        <SummaryField label="profile.user.labels.email" nowrap value={user.attributes.email} />
        <SummaryField label="profile.user.labels.password" nowrap value="*****" />
      </Box>
      <Box bgcolor="neutral01.100" px={6} py={4}>
        <Grid container spacing={4} alignItems="center">
          <Grid item sm={12} md={8}>
            <FormattedMessage id="profile.settings.login_data.managed_by.text" />
          </Grid>
          <Box clone textAlign={{ md: 'right' }}>
            <Grid item sm={12} md={4}>
              <Button onClick={handleRedirectToIdentity}>
                <FormattedMessage id="profile.settings.login_data.managed_by.button.text" />
                <Box clone mt={-1 / 2}>
                  <Icon spacing={4} margin="left" color="neutral01.50">
                    <UIcoExternalLink />
                  </Icon>
                </Box>
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default LoginSettingsFields;
