import React from 'react';

import { Box } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { EmploymentCondition } from 'store/claimSubmission/types';

import { NumberField } from 'components/@inputs/Number';
import { SelectField } from 'components/@inputs/Select';

export type AdditionalInfoFormValues = {
  requiresSalaryData?: boolean;
  requiresIdentificationNumber?: boolean;
  identificationNumber?: string;
  employmentPercentage?: string;
  employmentConditions?: EmploymentCondition[];
  grossSalary?: string;
  wageType?: string;
  employmentCondition?: {
    value: string;
    label: string;
  };
};

interface Props {
  wageType?: string;
  wageDecimals?: number;
  employmentConditions?: {
    key: string;
    title: string;
  }[];
}

const AdditionalInfoForm: React.FC<Props> = ({ wageType, wageDecimals, employmentConditions }) => {
  const { initialValues } = useFormikContext<AdditionalInfoFormValues>();

  return (
    <>
      {initialValues.requiresIdentificationNumber && (
        <Box mb={8}>
          <NumberField
            isRequired
            name="identificationNumber"
            label="claims.add.step.tpa_gi_employee.ssn.label"
            fullWidth
            format="##.##.##-###.##"
            mask="_"
            bottomLabel="claims.add.step.tpa_gi_employee.ssn.bottom_label"
          />
        </Box>
      )}
      {initialValues.requiresSalaryData && (
        <>
          <Box mb={8} width={{ xs: '100%', md: '50%' }}>
            <NumberField
              name="employmentPercentage"
              fieldType="percentage"
              placeholder="general.percentage_input.placeholder"
              fullWidth
              isRequired
              label="affiliations.add.step.additional_info.employment_percentage_label"
              startAdornment="%"
            />
          </Box>
          <Box width={{ xs: '100%', md: '50%' }} mb={8}>
            <NumberField
              name="grossSalary"
              label={
                wageType === 'MONTHLY'
                  ? 'general.gross_wage.text'
                  : 'general.gross_wage_hourly.text'
              }
              decimalScale={wageDecimals}
              fullWidth
              isRequired
              startAdornment="€"
            />
          </Box>
          {employmentConditions && employmentConditions.length > 0 && (
            <Box width={{ xs: '100%', md: '50%' }} mb={8}>
              <SelectField
                name="employmentCondition"
                fullWidth
                isRequired
                menuPortalTarget={document.body}
                options={employmentConditions.map(condition => ({
                  label: condition.title,
                  value: condition.key,
                }))}
                placeholder="claims.tpa_gi_employment_condition.placeholder"
                label="claims.tpa_gi_employment_condition.label"
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default AdditionalInfoForm;
