import features from 'config/features';

const useFeatures = () => {
  const isFeatureEnabled = (feature: keyof typeof features) => {
    return features[feature];
  };

  return { isFeatureEnabled, features };
};

export default useFeatures;
