import _get from 'lodash/get';

// STEPPER
export const getCurrentStep = state => _get(state, 'claimSubmission.stepper.currentStep');
export const getCompletedSteps = state => _get(state, 'claimSubmission.stepper.completedSteps');
export const getDisabledSteps = state => _get(state, 'claimSubmission.stepper.disabledSteps');
export const getFlowIsCompleted = state => _get(state, 'claimSubmission.stepper.flowCompleted');
export const getFlowStartTime = state => _get(state, 'claimSubmission.stepper.flowStart');

// TILES
export const getClaimSubmissionTiles = state => _get(state, 'claimSubmission.tiles.data');
export const getClaimSubmissionTilesAreLoading = state =>
  _get(state, 'claimSubmission.tiles.isLoading');
export const getClaimSubmissionTilesError = state => _get(state, 'claimSubmission.tiles.error');

// ALLOWED FILE TYPES
export const getClaimSubmissionFileConfig = state =>
  _get(state, 'endpoints.private.data.attributes.claimSubmissionConfiguration', {});

// SUBMISSION PARTNERS
export const getClaimSubmissionPartners = state => _get(state, 'claimSubmission.partners.data');
export const getClaimSubmissionPartnersLoading = state =>
  _get(state, 'claimSubmission.partners.isLoading');
export const getClaimSubmissionPartnersError = state =>
  _get(state, 'claimSubmission.partners.error');

// SUBMISSION POLICIES
export const getClaimSubmissionPolicies = state => _get(state, 'claimSubmission.policies.data');
export const getClaimSubmissionPoliciesMeta = state => _get(state, 'claimSubmission.policies.meta');
export const getSelectedPolicy = state => _get(state, 'claimSubmission.policies.selected');
export const getClaimSubmissionPoliciesLoading = state =>
  _get(state, 'claimSubmission.policies.isLoading');
export const getClaimSubmissionPoliciesError = state =>
  _get(state, 'claimSubmission.policies.error');

// REPORTING CATEGORIES
export const getReportingCategories = state =>
  _get(state, 'claimSubmission.reportingCategories.data');

// DAMAGE CATEGORIES
export const getDamageCategories = state =>
  _get(state, 'claimSubmission.damageCategories.data', []);
export const getDamageCategoriesAreLoading = state =>
  _get(state, 'claimSubmission.damageCategories.isLoading');
export const getDamageCategoriesError = state =>
  _get(state, 'claimSubmission.damageCategories.error');

// EMPLOYEES (TPA-GI)
export const getEmployees = state => _get(state, 'claimSubmission.employees.data');
export const getEmployeesMeta = state => _get(state, 'claimSubmission.employees.meta');
export const getEmployeesAreLoading = state => _get(state, 'claimSubmission.employees.isLoading');
export const getEmployeesError = state => _get(state, 'claimSubmission.employees.error');

// EMPLOYMENT (TPA-GI)
export const getEmployment = state => _get(state, 'claimSubmission.employment.data');
export const getEmploymentIsLoading = state => _get(state, 'claimSubmission.employment.isLoading');
export const getEmploymentError = state => _get(state, 'claimSubmission.employment.error');

// CLAIM SUBMISSION
export const getClaimSubmissionIsUpdating = state => _get(state, 'claimSubmission.isUpdating');
export const getClaimSubmissionIsCreating = state => _get(state, 'claimSubmission.isCreating');
export const getClaimSubmissionError = state => _get(state, 'claimSubmission.updateError');
export const getClaimSubmission = state => _get(state, 'claimSubmission.submission');
export const getClaimSubmissionSummary = state => _get(state, 'claimSubmission.summary');
// ENDPOINTS
export const getClaimSubmissionEndpoint = state =>
  _get(state, 'endpoints.private.data.relationships.claimSubmissions.links.self');

export const getClaimSubmissionTilesEndpoint = state =>
  _get(state, 'endpoints.private.data.relationships.claimSubmissionTiles.links.self');

export const getDamageCategoriesEndpoint = state =>
  _get(state, 'endpoints.private.data.relationships.claimSubmissionDamageCategories.links.self');

export const getEmployeesEndpoint = state => _get(state, 'claimSubmission.employees.endpoint');
