import React from 'react';

interface Context {
  activeId: string;
  handleChange: (id: string) => void;
  tabs: string[];
  anchorEl?: Element | ((element: Element) => Element) | null;
  handleOpenMobileTabMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseMobileTabMenu: () => void;
  hasMounted: string[];
}

export const tabContext = React.createContext<Context>({
  activeId: '',
  handleChange: () => {},
  tabs: [],
  anchorEl: undefined,
  handleOpenMobileTabMenu: () => {},
  handleCloseMobileTabMenu: () => {},
  hasMounted: [],
});
