import React, { Fragment, useRef, useState } from 'react';

import { Box } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

import { LinkButton } from 'components/@common/Link';
import File from 'components/File';
import Icon from 'components/Icon';
import { UIcoUpload } from 'components/Icons';

import { DropZone, Placeholder } from './FileUpload.styled';

export interface FileUploadProps {
  id?: string;
  name?: string;
  disabled?: boolean;
  value?: File[];
  placeholder?: string;
  multiple?: boolean;
  withPreview?: boolean;
  onChange?: (files: File[], type: 'add' | 'delete', addedOrRemovedFiles: File[]) => void;
  accept?: string[];
}

export interface FileUploadState {
  highlight: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  disabled,
  id,
  name,
  accept = [],
  onChange = () => {},
  value = [],
  placeholder = 'general.dropzone.placeholder',
  multiple = true,
  withPreview = true,
}) => {
  const intl = useIntl();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [highlight, setHighlight] = useState<FileUploadState['highlight']>(false);

  const fileListToArray = (files: FileList | never[]): File[] => {
    return multiple ? [...value, ...Array.from(files)] : Array.from(files);
  };

  const handleDragover = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (disabled) return;
    setHighlight(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (disabled) return;
    setHighlight(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    setHighlight(false);
    e.preventDefault();
    if (disabled) return;
    const { files } = e.dataTransfer;
    onChange(fileListToArray(files), 'add', Array.from(files));
  };

  const handleOpenFileDialog = () => {
    if (disabled) {
      return;
    }
    if (fileInputRef && fileInputRef.current) fileInputRef.current.click();
  };

  const handleFileDelete = (file: File) => {
    onChange(
      value.filter(prevFile => prevFile !== file),
      'delete',
      [file],
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled || !((e.target.files?.length || 0) > 0)) return;
    const files = e.target.files || [];
    onChange(fileListToArray(files), 'add', Array.from(files));
    e.target.value = '';
  };

  const filesTranslation = intl.formatMessage(
    { id: 'general.dropzone.files' },
    { multiple: multiple ? 1 : 0 },
  );

  return (
    <>
      <DropZone
        highlight={highlight}
        disabled={disabled}
        onDragOver={handleDragover}
        onDragLeave={handleDragLeave}
        onDragEnd={handleDragLeave}
        onClick={handleOpenFileDialog}
        onDrop={handleDrop}
      >
        <Box component="span" display="none">
          <input
            id={id}
            name={name}
            data-test-id={name}
            type="file"
            multiple={multiple}
            onChange={handleChange}
            disabled={disabled}
            ref={fileInputRef}
            accept={accept.map(extension => `.${extension.toLowerCase()}`).join(', ')}
          />
        </Box>
        <Box display="flex" alignItems="center" alignContent="center">
          <Box bgcolor="brand01.800" borderRadius="50%" mr={6} clone>
            <Icon color="brand01.300">
              <UIcoUpload />
            </Icon>
          </Box>
          <Box display="flex" flexDirection="column">
            <Placeholder highlight={highlight}>
              <FormattedMessage
                id={highlight ? 'general.dropzone.placeholder_dragover' : placeholder}
                values={{
                  filePluralSingle: filesTranslation,
                }}
              />
            </Placeholder>
            {!highlight && (
              <Box>
                <LinkButton>
                  <FormattedMessage
                    id="general.dropzone.select_files_button"
                    values={{ filePluralSingle: filesTranslation }}
                  />
                </LinkButton>
              </Box>
            )}
          </Box>
        </Box>
      </DropZone>
      {withPreview && value.length > 0 && (
        <Box marginTop={6}>
          {value.map((file, i) => {
            return (
              <Fragment key={`${file.name}${i}`}>
                <File
                  file={file}
                  onDeleteFileClick={handleFileDelete}
                  onRetryUploadClick={handleFileDelete}
                />
              </Fragment>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default FileUpload;
