import apiManager from './axios/apiManager';

export const fetchDocuments = (path, params) =>
  apiManager
    .get(path, {
      params,
    })
    .then(response => response.data);

export default null;

export const fetchDownloadDocument = path =>
  apiManager
    .get(path, {
      cache: {
        maxAge: 0,
        ignoreCache: true,
      },
      responseType: 'blob',
    })
    .then(response => response);
