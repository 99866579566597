import React from 'react';

import { ErrorTrace } from 'types/store/store.types';

import File from 'components/File';

const getType = (progress: number, isUploading: boolean, error: ErrorTrace) => {
  if (error) {
    return 'error';
  }
  if (isUploading) {
    return 'uploading';
  }
  if (progress === 100 && !isUploading) {
    return 'success';
  }
  return 'default';
};

export interface Props {
  file: File;
  onRetry?: (file: File) => void;
  progress: number;
  isUploading: boolean;
  error: ErrorTrace;
  uploadAttempts: number;
}

const UploadFile: React.FC<Props> = ({
  file,
  progress,
  error,
  isUploading,
  onRetry,
  uploadAttempts,
}) => {
  return (
    <File
      file={file}
      progress={progress}
      onRetryUploadClick={onRetry}
      type={getType(progress, isUploading, error)}
      showRetryButton={uploadAttempts !== 3}
    />
  );
};

export default UploadFile;
