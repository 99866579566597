import React, { useState } from 'react';

import { Box, Collapse, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import DateFormat from 'components/Date';

import { ContainerProps } from './AffiliationsCreateContextContainer';

interface Props {
  canCollapse?: boolean;
  title: string;
}

const AffiliationsCreateContext: React.FC<Props & ContainerProps> = ({
  canCollapse,
  flowContext,
  title,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!!canCollapse);
  const handleHeaderClick = () => {
    if (canCollapse) {
      setIsCollapsed(!isCollapsed);
    }
  };
  if (!flowContext) {
    return null;
  }
  return (
    <Box
      borderRadius={{ xs: 4, lg: 2 }}
      borderColor={{ sm: 'neutral01.300', lg: 'transparent' }}
      border={1}
      bgcolor="neutral01.50"
      mb={4}
    >
      <Box
        style={{ cursor: canCollapse ? 'pointer' : 'auto' }}
        px={4}
        py={3}
        onClick={handleHeaderClick}
        clone
      >
        <Typography variant="h5" component="h5" color="primary">
          <FormattedMessage id={title} />
        </Typography>
      </Box>
      <Collapse in={!isCollapsed} timeout="auto">
        <Box mt={4} px={4} pb={3}>
          <Typography variant="h6" color="textPrimary" component="h6" paragraph>
            {[flowContext.firstName, flowContext.lastName].filter(Boolean).join(' ')}
          </Typography>

          {flowContext.birthDate && (
            <Typography variant="body1" color="textSecondary" component="div">
              <DateFormat format="DD/MM/YYYY" date={flowContext.birthDate} />
            </Typography>
          )}
          <Box clone style={{ textTransform: 'uppercase' }}>
            <Typography variant="body1" color="textSecondary" component="div" gutterBottom>
              <FormattedMessage
                id={`insurant.type.${flowContext.insurantType?.value?.toLocaleLowerCase() ??
                  'main'}`}
              />
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default AffiliationsCreateContext;
