import React from 'react';

import useFilter, { UseFilterOptions } from 'hooks/useFilter/useFilter';
import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import { PartnerContact } from 'store/partner/partner.types';
import { StoreDefaultState } from 'types/store/store.types';

import Avatar from 'components/Avatar';
import { UIcoKlanten } from 'components/Icons';
import { useMedia } from 'components/Media';
import OverflowTooltip from 'components/OverflowTooltip';
import OverViewTable from 'components/OverviewTable';

type RowType = PartnerContact;

interface Props extends StoreDefaultState {
  rows?: RowType[];
  onFilterChange: UseFilterOptions['onFilterCallback'];
  meta?: {
    count: number;
  };
}

interface CellProps {
  row: RowType;
}

const ContactsTable: React.FC<Props> = ({ rows, isLoading, error, meta, onFilterChange }) => {
  const media = useMedia();
  const { handlePageChange, handleSortChange, handleMaxPerPageChange, filter } = useFilter({
    disablePersist: true,
    onFilterCallback: onFilterChange,
  });
  const NameCell: React.FC<CellProps> = ({ row }) => {
    const firstLastName = [row.attributes.lastName, row.attributes?.firstName].filter(Boolean);
    return (
      <Avatar
        verticalAlign="center"
        title={firstLastName.join(' ')}
        subTitle={media.small && row.attributes.email}
      />
    );
  };

  const OverflowCell: React.FC<CellProps & { selector: string }> = ({ row, selector }) => (
    <OverflowTooltip>{_get(row, selector)}</OverflowTooltip>
  );

  return (
    <>
      <OverViewTable<RowType>
        config={[
          {
            value: 'attributes.firstName',
            headerLabel: <FormattedMessage id="clients.details.contacts.table.head.name" />,
            sortKey: 'display_name',
            isSortable: true,
            cellRender: row => <NameCell row={row} />,
          },
          {
            value: 'attributes.function',
            headerLabel: <FormattedMessage id="clients.details.contacts.table.head.role" />,
            sortKey: 'function',
            isSortable: true,
            maxWidth: 150,
            hideOnBreakpoints: ['small'],
            cellRender: row => <OverflowCell row={row} selector="attributes.function" />,
          },
          {
            value: 'attributes.email',
            headerLabel: <FormattedMessage id="clients.details.contacts.table.head.email" />,
            sortKey: 'email',
            hideOnBreakpoints: ['small'],
            isSortable: true,
            cellRender: row => <OverflowCell row={row} selector="attributes.email" />,
          },
          {
            value: 'attributes.phone',
            headerLabel: <FormattedMessage id="clients.details.contacts.table.head.telephone" />,
            sortKey: 'phone',
            hideOnBreakpoints: ['small'],
            isSortable: true,
            cellRender: row => <OverflowCell row={row} selector="attributes.phone" />,
          },
        ]}
        data={rows}
        isLoading={isLoading}
        error={error}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleMaxPerPageChange}
        onRequestSort={handleSortChange}
        page={filter['p'] || 1}
        orderBy={filter['o']}
        maxPerPage={filter['maxPerPage']}
        emptyIcon={<UIcoKlanten />}
        totalRows={_get(meta, 'count', 0)}
        isRowClickable={() => false}
      />
    </>
  );
};

export default ContactsTable;
