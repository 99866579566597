import React from 'react';

// tslint:disable:space-in-parens
const componentPromise = import(
  './ClaimReportsCreateContainer' /* webpackChunkName: "claimReportsCreate", webpackPreload: true */
);
const Component = React.lazy(() => componentPromise);
// tslint:enable

export default Component;
