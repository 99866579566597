// @flow strict

import React from 'react';

import { AppBar, Box } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';

import { UIcoMeer } from 'components/Icons';

import NavItem from '../components/NavItem';
import { HeaderWrap, NavItemWrap } from './HeaderMobile.styled';
import MenuFlyOut from './MenuFlyOut';

interface HeaderMobileProps {
  isUserMenuOpen: boolean;
  onChange: (event: React.MouseEvent, value: string) => void;
  maxItems?: number;
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({
  isUserMenuOpen,
  onChange,
  maxItems = 4,
  children,
}) => {
  const childArray = React.Children.toArray(children);
  const visibleItems = childArray.slice(0, maxItems);
  const flyOutItems = childArray.slice(maxItems, childArray.length);

  return (
    <>
      <AppBar>
        <HeaderWrap>
          {visibleItems.map((child, i) => (
            <NavItemWrap key={i}>{child}</NavItemWrap>
          ))}
          <NavItemWrap onClick={(event: React.MouseEvent) => onChange(event, 'MORE')}>
            <Box>
              <NavItem label="general.menu.more" icon={<UIcoMeer />} />
            </Box>
          </NavItemWrap>
        </HeaderWrap>
        <Drawer
          onClose={(evt: React.MouseEvent) => onChange(evt, 'MORE')}
          anchor="bottom"
          open={isUserMenuOpen}
        >
          <MenuFlyOut onMenuItemClick={onChange}>{flyOutItems}</MenuFlyOut>
        </Drawer>
      </AppBar>
      {/* copy of the toolbar so it can preserve it's space for the fixed item */}
    </>
  );
};

export default HeaderMobile;
