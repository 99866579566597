import { connect } from 'react-redux';

import { authLogoutStart } from 'store/auth/auth.slice';
import { getImpersonate } from 'store/impersonate/impersonate.selectors';
import { systemStart } from 'store/system/system.actions';
import { getSystemError, isSystemBooting } from 'store/system/system.selectors';
import { Store } from 'types/store/store.types';

import System from './System';

const mapStateToProps = (state: Store) => ({
  isLoading: isSystemBooting(state),
  error: getSystemError(state),
  impersonate: getImpersonate(state),
});

const mapDispatchToProps = {
  startSystem: systemStart,
  logout: authLogoutStart,
};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type MapDispatchProps = typeof mapDispatchToProps;

export type ContainerProps = MapStateProps & MapDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(System);
