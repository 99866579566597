import _get from 'lodash/get';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import env from 'config';

import { deleteUserTutorial } from 'api/tutorial.api';
import { ROUTES } from 'constants/store';
import { analyticsService } from 'services';

import { getUserTutorials } from '../user/user.selectors';
import {
  tutorialDeleteFail,
  tutorialDeleteStart,
  tutorialDeleteSuccess,
  tutorialOpen,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './tutorial.actions';

export function* checkTutorial(type) {
  if (_get(env, 'ENABLE_TUTORIALS') === 'true') {
    const userTutorials = yield select(getUserTutorials);
    let currentTutorial = _get(userTutorials, `${type}`, []);

    if (type === ROUTES.CLAIMS) {
      const claimTutorial = _get(userTutorials, `${type}`, []);
      const claimSubmissionTutorial = _get(userTutorials, ROUTES.CLAIM_CREATE, []);
      currentTutorial = [...claimTutorial, ...claimSubmissionTutorial];
    }

    if (_get(currentTutorial, 'length') > 0) {
      currentTutorial.forEach(tutorial => {
        analyticsService.events.trackOnboardingTutorial(
          _get(tutorial, 'id'),
          _get(tutorial, 'attributes.key'),
        );
      });
      yield put(
        tutorialOpen({
          tutorialType: type,
          tutorialData: currentTutorial,
        }),
      );
    }
  }
}

function* tutorialDeleteFlow(action) {
  const path = action.payload;
  try {
    yield call(deleteUserTutorial, path);
    yield put(tutorialDeleteSuccess());
  } catch (error) {
    yield put(tutorialDeleteFail({ error }));
  }
}
// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

function* tutorialSaga() {
  yield takeLatest(tutorialDeleteStart.getType(), tutorialDeleteFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default tutorialSaga;
