import React from 'react';

import { Box, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import { ClaimSubmissionPolicy } from 'store/claimSubmission/types';

import { hasFormErrors } from 'utils/helpers';

import Button from 'components/@common/Button';
import DateInputField from 'components/@inputs/DateInput/DateInputField';
import NumberField from 'components/@inputs/Number/NumberField';
import { SelectField } from 'components/@inputs/Select';
import { InputField } from 'components/@inputs/TextField';

import { ContainerProps } from './ClaimSubmissionEmployeeAdvancedSearchContainer';
import { ValidationSchema } from './SearchForm.validation';

export type FormValues = {
  name: string;
  surName: string;
  personnelNumber: string;
  birthDate: string;
  identificationNumber?: number;
  policy?: {
    label: string;
    value: string;
    meta?: { [key: string]: any };
  };
  onSearch?: () => {};
};

interface Props {
  policies?: ContainerProps['policies'];
  selectedPolicy?: ContainerProps['selectedPolicy'];
  isLoading?: ContainerProps['isLoading'];
  onSearch: (values: FormValues) => void;
}

const SearchForm: React.FC<Props> = ({ policies, selectedPolicy, onSearch, isLoading }) => {
  const handleSubmit = (values: FormValues) => {
    onSearch(values);
  };
  const getPolicySelectItem = (item: ClaimSubmissionPolicy) => {
    return {
      label: `${_get(item, 'attributes.policyNumber')} | ${_get(item, 'attributes.title')}`,
      value: item.id,
      meta: { ..._get(item, 'relationships'), id: item.id },
    };
  };
  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
      validateOnMount
      initialValues={{
        name: '',
        surName: '',
        personnelNumber: '',
        birthDate: '',
        identificationNumber: undefined,
        policy: selectedPolicy && getPolicySelectItem(selectedPolicy),
      }}
    >
      {({ errors }) => {
        return (
          <Box clone display="flex" flexDirection="column" flex="1 auto" overflow="hidden">
            <Form>
              <Box p={4} flex="1 auto" overflow="auto">
                <Box mb={8}>
                  <SelectField
                    isRequired
                    label="claims.add.step.tpa_gi_employee.policy.label"
                    name="policy"
                    options={(policies || []).map(policy => getPolicySelectItem(policy))}
                    fullWidth
                  />
                </Box>
                <Box mb={8}>
                  <Grid container spacing={4}>
                    <Grid item sm={12} md={6}>
                      <InputField
                        isRequired
                        name="surName"
                        label="claims.add.step.tpa_gi_employee.sur_name.label"
                        fullWidth
                      />
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <InputField
                        isRequired
                        name="name"
                        fullWidth
                        label="claims.add.step.tpa_gi_employee.name.label"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={8}>
                  <NumberField
                    isRequired
                    name="personnelNumber"
                    label="claims.add.step.tpa_gi_employee.personnel_number.label"
                    fullWidth
                    thousandSeparator=""
                    fixedDecimalScale={false}
                    allowNegative={false}
                    decimalScale={0}
                  />
                </Box>
                <Box mb={8}>
                  <DateInputField
                    name="birthDate"
                    label="claims.add.step.tpa_gi_employee.birth_date.label"
                  />
                </Box>
                <Box mb={8}>
                  <NumberField
                    isRequired
                    name="identificationNumber"
                    label="claims.add.step.tpa_gi_employee.ssn.label"
                    fullWidth
                    format="##.##.##-###.##"
                    mask="_"
                    bottomLabel="claims.add.step.tpa_gi_employee.ssn.bottom_label"
                  />
                </Box>
              </Box>

              <Box
                flex="0 auto"
                display="fex"
                padding={4}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Button
                  disabled={isLoading || hasFormErrors(errors)}
                  variation="secondary"
                  type="submit"
                >
                  <FormattedMessage id="claims.add.step.tpa_gi_employee.advanced_search.modal.search_button" />
                </Button>
              </Box>
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
};

export default SearchForm;
