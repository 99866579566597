import React from 'react';

import { Box, DialogActions, DialogContent } from '@material-ui/core';
import { ReactComponent as UIcoFeedbackError } from 'assets/svg/u_ico_feedback_error.svg';
import { ReactComponent as UIcoFeedbackSucces } from 'assets/svg/u_ico_feedback_succes.svg';
import { FormattedMessage } from 'react-intl';

import Button from 'components/@common/Button';
import { H3 } from 'components/@typography/Titles';
import Icon from 'components/Icon';

import { ActionButton } from '../Modal.styled';
import { ContentWrap, IconCircle } from './FlowSubmissionStatus.styled';
interface Props {
  status?: 'success' | 'error';
  title?: string;
  buttonText?: string | { id: string; values: { [index: string]: string } };
  description?: string | { id: string; values: { [index: string]: string } };
  iconSize?: number;
  iconColor?: string;
  closeModal?: () => void;
  onButtonClick?: () => void;
  actions?: React.ReactNode[];
}

const FlowSubmissionStatus: React.FC<Props> = ({
  status,
  title,
  buttonText,
  description,
  iconSize = 32,
  onButtonClick = () => {},
  closeModal = () => {},
  actions = [],
}) => {
  const descriptionIntl = typeof description === 'string' ? { id: description } : description;
  const buttonTextIntl = typeof buttonText === 'string' ? { id: buttonText } : buttonText;
  return (
    <>
      <DialogContent>
        <Box mb={5} mt={6} display="flex" alignItems="center">
          <IconCircle size={iconSize}>
            <Icon color={status === 'success' ? 'accent01.400' : 'accent02.400'} size={iconSize}>
              {status === 'success' ? <UIcoFeedbackSucces /> : <UIcoFeedbackError />}
            </Icon>
          </IconCircle>

          <H3 color="brand01.400">
            <FormattedMessage id={title} />
          </H3>
        </Box>

        <ContentWrap>
          <FormattedMessage {...descriptionIntl} />
        </ContentWrap>
      </DialogContent>
      <DialogActions>
        <ActionButton>
          <Button
            onClick={() => {
              closeModal();
              onButtonClick();
            }}
            fullWidth={false}
          >
            <FormattedMessage {...buttonTextIntl} />
          </Button>
        </ActionButton>
        {actions.map(action => (
          <ActionButton>{action}</ActionButton>
        ))}
      </DialogActions>
    </>
  );
};

export default FlowSubmissionStatus;
