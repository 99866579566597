import React from 'react';

import { Box, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { Params } from 'redux-first-router';

import TextInputField from 'components/@inputs/TextField/TextInputField';
import FormikAutoSave from 'components/FormikAutoSave';

interface Props {
  initialValues: Params;
  onFilterChange: (filters: Params) => void;
}

const Filter: React.FC<Props> = props => {
  const { initialValues, onFilterChange } = props;

  return (
    <Box p={{ xs: 2, md: 6 }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={values => {
          onFilterChange(values);
        }}
      >
        <>
          <FormikAutoSave debounce={200} />
          <Grid container spacing={4}>
            <Grid item md={6} sm={12}>
              <TextInputField
                fullWidth
                name="q"
                fieldType="search"
                placeholder="clients.details.contacts_sidebar.filter.placeholder"
              />
            </Grid>
          </Grid>
        </>
      </Formik>
    </Box>
  );
};

export default Filter;
