import { useEffect } from 'react';

import { useFormikContext } from 'formik';

import { eventEmitter } from 'services';

import { hasFormErrors } from './helpers';

const useStepperForm = <T extends {}>(
  saveForm = (_values: T) => {},
  persist = true,
  submitWithWarnings?: (values: T) => void,
) => {
  const { submitForm, errors, values } = useFormikContext<T>();
  const canContinue = hasFormErrors(errors);

  const handleNextStepClick = () => {
    if (!canContinue) {
      if (submitWithWarnings) {
        /*
         we need to explicitly submit with own function parameter because formik cannot distinguish between warnings and errors.
         Formik always runs validation and triggers the onSubmit or not and there is no way for us to force it to submit
        */
        submitWithWarnings(values);
      } else {
        submitForm();
      }
    }
  };

  useEffect(() => {
    eventEmitter.on('stepChange', () => {
      if (persist) {
        saveForm(values);
      }
    });
  }, [values, saveForm, persist]);

  return { handleNextStepClick, canContinue };
};

export default useStepperForm;
