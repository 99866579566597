import { useDispatch } from 'react-redux';

import { modalHide, modalShow } from 'store/modal/modal.slice';
import { ShowModalPayload } from 'store/modal/types';

const useModal = () => {
  const dispatch = useDispatch();
  const showModal = (options: ShowModalPayload) => {
    dispatch(modalShow(options));
  };
  const hideModal = () => {
    dispatch(modalHide());
  };

  return {
    showModal,
    hideModal,
  };
};

export default useModal;
