import React from 'react';

import { Formik } from 'formik';
import { connect } from 'react-redux';

import { getAffiliationSubmissionFileConfig } from 'store/affiliationSubmission/affiliationSubmission.selectors';
import { Store } from 'types/store/store.types';

import DocumentsStep from './DocumentsStep';
import { ValidationSchema } from './DocumentsStep.validation';

export type FormValues = {
  files?: File[];
  remark?: string;
};

type Props = {
  onNextStepClick: (values: FormValues) => void;
  initialValues: FormValues;
};
type ChildProps = React.ComponentProps<typeof DocumentsStep>;

const DocumentsStepContainer: React.FC<Props & ContainerProps & ChildProps> = ({
  onNextStepClick,
  initialValues,
  fileConfig,
  saveForm,
}) => {
  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={values => {
        onNextStepClick(values);
      }}
    >
      <DocumentsStep fileConfig={fileConfig} saveForm={saveForm} />
    </Formik>
  );
};

const mapStateToProps = (state: Store) => ({
  fileConfig: getAffiliationSubmissionFileConfig(state),
});
const mapDispatchToProps = {};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type MapDispatchProps = typeof mapDispatchToProps;
export type ContainerProps = MapStateProps & MapDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsStepContainer);
