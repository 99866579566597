import React from 'react';

import { DynamicSection } from '../../types';
import HintTooltip from '../HintTooltip';
import { Table, TableBody, TableCell, TableHead, TableRow } from './DynamicTable.styled';
interface Props {
  fields: DynamicSection['fields'];
  headers: DynamicSection['headers'];
}

const DynamicTable: React.FC<Props> = ({ fields, headers = [] }) => {
  return (
    <Table>
      {headers && (
        <TableHead>
          <TableRow>
            {headers.map(head => (
              <TableCell variant="head" key={`${head}`}>
                {head}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {fields.map((field, i) => {
          const [titleHead, ...rest] = headers;
          return (
            <TableRow key={field.key ?? `row${i}`}>
              <>
                <TableCell data-title={titleHead}>
                  {field?.title}
                  {field?.hint && <HintTooltip hint={field?.hint} />}
                </TableCell>
                {field.values?.map((cell, i) => (
                  <TableCell key={`cell${rest?.[i]}`} data-title={rest?.[i]}>
                    {cell}
                  </TableCell>
                ))}
              </>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default DynamicTable;
