import { createAction } from 'redux-act';

export const clientFetchStart = createAction('CLIENT_FETCH_START');
export const clientFetchSuccess = createAction('CLIENT_FETCH_SUCCESS');
export const clientFetchFail = createAction('CLIENT_FETCH_FAIL');

export const clientSearchStart = createAction('CLIENT_SEARCH_START');
export const clientSearchSuccess = createAction('CLIENT_SEARCH_SUCCESS');
export const clientSearchFail = createAction('CLIENT_SEARCH_FAIL');

export const clientFetchByIdStart = createAction('CLIENT_FETCH_BY_ID_START');
export const clientFetchByIdSuccess = createAction('CLIENT_FETCH_BY_ID_SUCCESS');
export const clientFetchByIdFail = createAction('CLIENT_FETCH_BY_ID_FAIL');

export const groupFetchStart = createAction('GROUP_FETCH_START');
export const groupFetchSuccess = createAction('GROUP_FETCH_SUCCESS');
export const groupFetchFail = createAction('GROUP_FETCH_FAIL');

export const groupSearchStart = createAction('GROUP_SEARCH_START');
export const groupSearchSuccess = createAction('GROUP_SEARCH_SUCCESS');
export const groupSearchFail = createAction('GROUP_SEARCH_FAIL');

export const groupSelectStart = createAction('GROUP_SELECT_START');
export const groupSelectSuccess = createAction('GROUP_SELECT_SUCCESS');
export const groupSelectFail = createAction('GROUP_SELECT_FAIL');

export const clientSelectStart = createAction('PARTNER_SELECT_START');
export const clientSelectSuccess = createAction('PARTNER_SELECT_SUCCESS');
export const clientSelectFail = createAction('PARTNER_SELECT_FAIL');

export const clientReportingCategoriesFetchStart = createAction(
  'CLIENT_REPORTING_CATEGORIES_FETCH_START',
);
export const clientReportingCategoriesFetchSuccess = createAction(
  'CLIENT_REPORTING_CATEGORIES_FETCH_SUCCESS',
);
export const clientReportingCategoriesFetchFail = createAction(
  'CLIENT_REPORTING_CATEGORIES_FETCH_FAIL',
);

export const clientResetSelectedIdsStart = createAction('CLIENT_RESET_SELECTED_IDS_START');
export const clientResetSelectedIds = createAction('CLIENT_RESET_SELECTED_IDS');

export const addClientsToState = createAction('ADD_CLIENTS_TO_STATE');

// (-- APPEND ACTIONS HERE --) !!! do not move this comment !!!
