import _get from 'lodash/get';

// STEPPER
export const getCurrentStep = state => _get(state, 'fileSubmission.currentStep');
export const getCompletedSteps = state => _get(state, 'fileSubmission.completedSteps');

// RECENT RECIPIENTS
export const getFileSubmissionRecentRecipients = state =>
  _get(state, 'fileSubmission.recentRecipients');
export const getFileSubmissionRecentRecipientsIsLoading = state =>
  _get(state, 'fileSubmission.isRecentRecipientsLoading');
export const getFileSubmissionRecentRecipientsError = state =>
  _get(state, 'fileSubmission.recentRecipientsError');

// FILE CONFIGURATION
export const getFileSubmissionFileConfig = state =>
  _get(state, 'endpoints.private.data.attributes.fileTransferConfiguration', {});

// PARTNERS
export const getFileSubmissionPartnersIsLoading = state =>
  _get(state, 'fileSubmission.partners.isLoading');
export const getFileSubmissionPartnersError = state => _get(state, 'fileSubmission.partners.error');
export const getFileSubmissionPartnersData = state => _get(state, 'fileSubmission.partners.data');

// PARTNER CONTACTS
export const getFileSubmissionPartnerContactsIsLoading = state =>
  _get(state, 'fileSubmission.partnerContacts.isLoading');
export const getFileSubmissionPartnerContactsError = state =>
  _get(state, 'fileSubmission.partnerContacts.error');
export const getFileSubmissionPartnerContactsData = state =>
  _get(state, 'fileSubmission.partnerContacts.data');
export const getFileSubmissionPartnerDomain = state =>
  _get(state, 'fileSubmission.data.attributes.domain');

// CREATE
export const getFileSubmissionIsCreating = state => _get(state, 'fileSubmission.isCreating');
export const getFileSubmissionIsUpdating = state => _get(state, 'fileSubmission.isUpdating');
export const getFileSubmissionError = state => _get(state, 'fileSubmission.error');
export const getFileSubmissionData = state => _get(state, 'fileSubmission.data');
export const getFilesReadyForUpload = state => _get(state, 'fileSubmission.files');
export const getFlowCompleted = state => _get(state, 'fileSubmission.flowCompleted');

// ENDPOINTS
export const getFileSubmissionEndpoint = state =>
  _get(state, 'endpoints.private.data.relationships.fileTransfers.links.self');
export const getFileSubmissionRecentRecipientsEndpoint = state =>
  _get(state, 'endpoints.private.data.relationships.fileTransferRecipients.links.self');

export const getFileSubmissionUpdateEndpoint = state =>
  _get(state, 'fileSubmission.data.links.self');

export const getFileSubmissionPartnersEndpoint = state =>
  _get(state, 'endpoints.private.data.relationships.fileTransferPartners.links.self');
