import React from 'react';

import { Link } from '@material-ui/core';
import useFilter, { UseFilterOptions } from 'hooks/useFilter/useFilter';
import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import { CategoryListIds, QueryParams } from 'constants/user.constants';
import { ExternalUserData } from 'services/Api/types/users.types';
import { StoreDefaultState } from 'types/store/store.types';

import { UIcoKlanten } from 'components/Icons';
import { useMedia } from 'components/Media';
import OverViewTable from 'components/OverviewTable';

import { OverflowCell } from '../DefaultCells';
import { ActionsCell, NameCell, StatusCell } from './Cells';

export type RowType = ExternalUserData;

export interface Props {
  rows?: RowType[];
  isLoading?: StoreDefaultState['isLoading'];
  error?: StoreDefaultState['error'];
  onRowClick?: (event: React.MouseEvent, row: RowType) => void;
  meta?: {
    count: number;
  };
  category?: string;
  disablePersist?: boolean;
  onFilterChange?: UseFilterOptions['onFilterCallback'];
  isRowClickable?: boolean;
  emptyText?: React.ReactNode;
  onActionClick?: (row: RowType) => void;
  showActions?: boolean;
  withExport?: boolean;
  onExportResults?: () => void;
  isExporting?: boolean;
}

const UsersTable: React.FC<Props> = ({
  rows,
  isLoading,
  error,
  meta,
  category,
  emptyText,
  onFilterChange = () => {},
  onRowClick = () => {},
  disablePersist = false,
  isRowClickable = true,
  onActionClick = () => {},
  showActions = false,
  withExport = true,
  isExporting,
  onExportResults = () => {},
}) => {
  const media = useMedia();

  const { handlePageChange, handleSortChange, handleMaxPerPageChange, filter } = useFilter({
    disablePersist: disablePersist,
    onFilterCallback: onFilterChange,
  });

  return (
    <OverViewTable<RowType>
      config={[
        {
          value: 'attributes.name',
          headerLabel: <FormattedMessage id="users.table.head.name" />,
          sortKey: 'first_name',
          isSortable: true,
          cellRender: row => <NameCell row={row} />,
        },
        {
          value: 'attributes.email',
          headerLabel: <FormattedMessage id="users.table.head.email" />,
          sortKey: 'email',
          isSortable: true,
          cellRender: row => <OverflowCell<RowType> row={row} selector="attributes.email" />,
          hideOnBreakpoints: ['small'],
        },
        {
          value: 'attributes.status',
          width: 130,
          headerLabel: !media.small && category === CategoryListIds.MyUsers && (
            <FormattedMessage id="users.table.head.status" />
          ),
          hideWhen: () => showActions && media.small,
          cellRender: row => <StatusCell row={row} category={category} />,
          isSortable: false,
        },
        {
          value: 'actions',
          width: 230,
          headerLabel: <FormattedMessage id="users.table.head.actions" />,
          cellRender: row => <ActionsCell onActionClick={onActionClick} row={row} />,
          isSortable: false,
          hideWhen: () => !showActions,
        },
      ]}
      data={rows}
      isLoading={isLoading}
      error={error}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleMaxPerPageChange}
      onRequestSort={handleSortChange}
      page={filter[QueryParams.PAGE] || 1}
      orderBy={filter[QueryParams.ORDER]}
      maxPerPage={filter[QueryParams.MAX_PER_PAGE]}
      totalRows={_get(meta, 'count', 0)}
      onRowClick={onRowClick}
      emptyIcon={<UIcoKlanten />}
      emptyText={emptyText}
      isRowClickable={() => isRowClickable}
      CustomPaginationRightComponent={
        rows &&
        rows?.length > 0 &&
        withExport && (
          <Link component="button" disabled={isExporting} onClick={onExportResults}>
            <FormattedMessage
              id={
                isExporting ? 'claims.table.pagination.exporting' : 'claims.table.pagination.export'
              }
            />
          </Link>
        )
      }
    />
  );
};

export default UsersTable;
