import styled from 'styled-components';

export const Wrap = styled.div`
  flex: 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentWrap = styled.div`
  min-height: ${({ theme }) => theme.spacing(30)}px;
`;

export const IconCircle = styled.div<{ size: number }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${({ size }) => size - 2}px;
  height: ${({ size }) => size - 2}px;
  margin-right: ${({ theme }) => theme.spacing(4)}px;
`;
