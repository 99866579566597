import React, { PureComponent } from 'react';

import { Input } from 'components/@inputs';
import Icon from 'components/Icon';
import { UIcoSluiten, UIcoZoek } from 'components/Icons';

class SearchField extends PureComponent {
  render() {
    const { value, onClearClick, inverted = false, ...rest } = this.props;
    return (
      <Input
        {...rest}
        inverted={inverted}
        startAdornment={
          <Icon>
            <UIcoZoek />
          </Icon>
        }
        value={value}
        endAdornment={
          value && (
            <Icon onClick={onClearClick} cursor="pointer">
              <UIcoSluiten />
            </Icon>
          )
        }
      />
    );
  }
}

export default SearchField;
