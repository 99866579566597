import React, { Fragment, PureComponent } from 'react';

import { MultiValueLabel, ValueCounter, ValueWrap } from './ValueContainer.styled';

class ValueContainer extends PureComponent {
  render() {
    const {
      children,
      selectProps: { placeholder, value, inputValue, isMulti },
      hasValue,
    } = this.props;

    return (
      <ValueWrap>
        {isMulti ? (
          <Fragment>
            <MultiValueLabel hasValues={hasValue || inputValue}>
              {inputValue ? '' : value && value.length === 1 ? value[0].label : placeholder}
            </MultiValueLabel>
            {/* children[1] => input component from react-select => for search feature */}
            <div>{children[1]}</div>
            {hasValue && value.length > 1 && <ValueCounter> {value.length} </ValueCounter>}
          </Fragment>
        ) : (
          children
        )}
      </ValueWrap>
    );
  }
}
export default ValueContainer;
