import React from 'react';

import { TextFieldProps } from '@material-ui/core';
import { ActionMeta } from 'react-select';

import { Label } from 'components/@inputs';
import Icon from 'components/Icon';
import { UIcoPijlOnder } from 'components/Icons';

import { Select, SelectArrow, SelectPlaceholder, SelectWrap } from './NativeSelect.styled';

export type Option = {
  value: string;
  label: React.ReactNode;
};

export interface NativeSelectgProps {
  placeholder?: string;
  variant?: TextFieldProps['variant'];
  hasError?: boolean;
  isOptional?: boolean;
  label?: React.ReactNode;
  isDisabled?: boolean;
  getOptionValue?: (option: Option) => string;
  options?: Option[];
  value?: Option;
  onChange: (option: Option, action?: ActionMeta) => void;
}
const NativeSelect: React.FC<NativeSelectgProps> = ({
  options = [],
  placeholder,
  value,
  getOptionValue = option => option?.value,
  isDisabled,
  label,
  isOptional,
  hasError,
  variant,
  onChange = () => {},
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { selectedIndex } = event.target;
    const currentOption = options[selectedIndex - 1];
    if (currentOption) {
      onChange(options[selectedIndex - 1]);
    }
  };

  return (
    <>
      {label && (
        <Label isOptional={isOptional} hasError={hasError} hasValue={!!value}>
          {label}
        </Label>
      )}
      <SelectWrap>
        {!(value && getOptionValue(value)) && <SelectPlaceholder>{placeholder}</SelectPlaceholder>}

        <Select
          variant={variant}
          disabled={isDisabled}
          value={value && getOptionValue(value)}
          onChange={handleChange}
        >
          <option hidden value={undefined} />
          {options.map((option, i) => (
            <option key={i} value={getOptionValue(option)}>
              {option.label}
            </option>
          ))}
        </Select>
        <SelectArrow>
          <Icon size={20} cursor="pointer" spacing={4} color="brand01.200">
            <UIcoPijlOnder />
          </Icon>
        </SelectArrow>
      </SelectWrap>
    </>
  );
};

export default NativeSelect;
