import React from 'react';

import Icon from 'components/Icon';
import { UIcoError, UIcoInfo } from 'components/Icons';

import { types } from './constants';
import { Wrap } from './InfoBanner.styled';

const getInfoIcon = type => {
  let Ico = UIcoError;
  switch (type) {
    case types.SUCCESS:
      Ico = UIcoError;
      break;
    case types.WARNING:
      Ico = UIcoError;
      break;
    case types.ERROR:
      Ico = UIcoError;
      break;
    default:
      Ico = UIcoInfo;
  }
  return (
    <Icon color="brand01.400" spacing={12}>
      <Ico />
    </Icon>
  );
};

const InfoBanner = ({ children, type = types.DEFAULT, isOpen = false, ...rest }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Wrap type={type} {...rest}>
      {getInfoIcon(type)}
      {children}
    </Wrap>
  );
};

export default InfoBanner;
