import { END, eventChannel } from 'redux-saga';
import { all, call, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { uploadFile } from 'api/file.api';

import {
  fileUploadFail,
  fileUploadProgress,
  fileUploadStart,
  fileUploadSuccess,
  initFileUpload,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './file.actions';

function createUploader(path, file) {
  let emit;

  const channel = eventChannel(emitter => {
    emit = emitter;
    return () => {};
  });

  const uploadProgressCallback = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };

  const uploadPromise = uploadFile(path, file, uploadProgressCallback);

  return [uploadPromise, channel];
}

function* watchUploadProgress(channel, file, groupName, index) {
  while (true) {
    const progress = yield take(channel);
    yield put(fileUploadProgress({ file, progress, groupName, index }));
  }
}

function* fileUploadFlow(action) {
  const { file, path, groupName, index } = action.payload;
  try {
    const [uploadPromise, channel] = yield call(createUploader, path, file);
    yield fork(watchUploadProgress, channel, file, groupName, index);

    const result = yield call(() => uploadPromise);
    yield put(fileUploadSuccess({ file, data: result.data, groupName, index }));
  } catch (error) {
    yield put(fileUploadFail({ error, file, groupName, index }));
  }
}

function* fileUploadInitFlow(action) {
  const { files, path, groupName } = action.payload;
  yield all(files.map((file, index) => put(fileUploadStart({ file, path, groupName, index }))));
}
// (-- APPEND SAGA TEMPLATE HERE --) !!! do not move this comment !!!

function* fileSaga() {
  yield takeLatest(initFileUpload.getType(), fileUploadInitFlow);
  yield takeEvery(fileUploadStart.getType(), fileUploadFlow);
  // (-- APPEND SAGA ACTION MAPPING HERE --) !!! do not move this comment !!!
}

export default fileSaga;
