import { createReducer } from 'redux-act';

import { STEP_IDS } from 'constants/fileSubmission';

import {
  filesSubmissionRecentRecipientsFetchFail,
  filesSubmissionRecentRecipientsFetchStart,
  filesSubmissionRecentRecipientsFetchSuccess,
  fileSubmissionCreateFail,
  fileSubmissionCreateStart,
  fileSubmissionCreateSuccess,
  fileSubmissionPartnerContactsFetchFail,
  fileSubmissionPartnerContactsFetchStart,
  fileSubmissionPartnerContactsFetchSuccess,
  fileSubmissionPartnersFetchFail,
  fileSubmissionPartnersFetchStart,
  fileSubmissionPartnersFetchSuccess,
  fileSubmissionUpdateFail,
  fileSubmissionUpdateStart,
  fileSubmissionUpdateSuccess,
  goToNextStep,
  goToStep,
  resetRecipientValidation,
  resetStepper,
  setNewStep,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './fileSubmission.actions';

export const INITIAL_STATE = {
  isRecentRecipientsLoading: false,
  recentRecipientsError: null,
  recentRecipients: null,
  recipientData: null,
  currentStep: STEP_IDS.STEP_DOMAIN,
  isCreating: false,
  isUpdating: false,
  error: null,
  data: null,
  completedSteps: [],
  disabledSteps: [],
  flowCompleted: false,
  partners: {
    isLoading: false,
    data: {},
    error: null,
  },
  partnerContacts: {
    isLoading: false,
    data: {},
    error: null,
  },
};

const REDUCERS = {
  [filesSubmissionRecentRecipientsFetchStart]: state =>
    Object.assign({}, state, {
      isRecentRecipientsLoading: true,
      recentRecipientsError: null,
    }),
  [filesSubmissionRecentRecipientsFetchSuccess]: (state, { data }) =>
    Object.assign({}, state, {
      isRecentRecipientsLoading: false,
      recentRecipientsError: null,
      recentRecipients: data,
    }),
  [filesSubmissionRecentRecipientsFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      isRecentRecipientsLoading: false,
      recentRecipientsError: error || 'unknown error',
    }),
  [resetRecipientValidation]: state =>
    Object.assign({}, state, {
      isRecipientValid: null,
      isRecentRecipientsLoading: false,
      lastRecipientChecked: '',
      recipientData: null,
    }),
  [fileSubmissionCreateStart]: state =>
    Object.assign({}, state, {
      isCreating: true,
      error: null,
    }),
  [fileSubmissionCreateSuccess]: (state, { data }) =>
    Object.assign({}, state, {
      isCreating: false,
      error: null,
      data,
    }),
  [fileSubmissionCreateFail]: (state, { error }) =>
    Object.assign({}, state, {
      isCreating: false,
      error: error || 'unknown error',
      data: null,
    }),
  [goToStep]: state => Object.assign({}, state, {}),
  [setNewStep]: (state, { stepId, newCompleted }) =>
    Object.assign({}, state, {
      currentStep: stepId,
      completedSteps: newCompleted,
    }),
  [resetStepper]: state =>
    Object.assign({}, state, {
      ...INITIAL_STATE,
    }),
  [goToNextStep]: state => Object.assign({}, state, {}),
  [fileSubmissionUpdateStart]: state =>
    Object.assign({}, state, {
      isUpdating: true,
      error: null,
    }),
  [fileSubmissionUpdateSuccess]: (state, { data }) =>
    Object.assign({}, state, {
      isUpdating: false,
      error: null,
      data,
      flowCompleted: true,
    }),
  [fileSubmissionUpdateFail]: (state, { error }) =>
    Object.assign({}, state, {
      isUpdating: false,
      error: error || 'unknown error',
      data: null,
    }),
  [fileSubmissionPartnersFetchStart]: state =>
    Object.assign({}, state, {
      partners: {
        isLoading: true,
        error: null,
      },
    }),
  [fileSubmissionPartnersFetchSuccess]: (state, { data }) =>
    Object.assign({}, state, {
      partners: {
        isLoading: false,
        error: null,
        data,
      },
    }),
  [fileSubmissionPartnersFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      partners: {
        isLoading: false,
        error: error || 'unknown error',
      },
    }),
  [fileSubmissionPartnerContactsFetchStart]: state =>
    Object.assign({}, state, {
      partnerContacts: {
        isLoading: true,
        error: null,
      },
    }),
  [fileSubmissionPartnerContactsFetchSuccess]: (state, { data }) =>
    Object.assign({}, state, {
      partnerContacts: {
        isLoading: false,
        error: null,
        data,
      },
    }),
  [fileSubmissionPartnerContactsFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      partnerContacts: {
        isLoading: false,
        error: error || 'unknown error',
      },
    }),
  // (-- APPEND REDUCER ACTION MAPPING HERE --) !!! do not move this comment !!!
};

export default createReducer(REDUCERS, INITIAL_STATE);
