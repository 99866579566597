import {
  UpdateUserStartPayload,
  UserBookmarksResponse,
  UserFavoritePartnersResponse,
  UserResponse,
  UserStatsResponse,
  UserTutorialsResponse,
  UserUpdatesPublicationTypeResponse,
  UserUpdatesResponse,
  UserUpdateDetailResponse,
} from 'store/user/user.types';

import { ApiInstanceType } from './';
class UserApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchUser = (path: string) => this.api.get<UserResponse>(path).then(response => response.data);

  updateUser = (path: string, data: UpdateUserStartPayload) =>
    this.api.put<UserResponse>(path, data).then(response => response.data);

  fetchUserTutorials = (path: string) =>
    this.api.get<UserTutorialsResponse>(path).then(response => response.data);

  fetchUserBookmarks = (path: string) =>
    this.api.get<UserBookmarksResponse>(path).then(response => response.data);

  fetchUserUpdates = (path: string, filters: { [index: string]: string }) =>
    this.api
      .get<UserUpdatesResponse>(path, {
        params: filters,
      })
      .then(response => response.data);

  fetchUserUpdate = (path: string) =>
    this.api.get<UserUpdateDetailResponse>(path).then(response => response.data);

  fetchUserUpdatePublicationType = (path: string) =>
    this.api.get<UserUpdatesPublicationTypeResponse>(path).then(response => response.data);

  fetchUserUpdatePublicationDownloadUrl = (path: string) =>
    this.api
      .get<Blob>(path, {
        cache: {
          maxAge: 0,
          ignoreCache: true,
        },
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/pdf',
        },
        responseType: 'blob',
      })
      .then(response => response);

  fetchUserFavoritePartners = (path: string) =>
    this.api.get<UserFavoritePartnersResponse>(path).then(response => response.data);

  deleteUserFavoritePartner = (path: string) =>
    this.api.delete(path).then(response => response.data);

  updateUserFavoritePartner = (path: string) => this.api.put(path).then(response => response.data);

  checkUserEmail = (path: string, email: string) =>
    this.api.put(path, { data: { attributes: { email } } }).then(response => response);

  fetchUserStats = (path: string) =>
    this.api.get<UserStatsResponse>(path).then(response => response.data);
}

export default UserApi;
