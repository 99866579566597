import styled from 'styled-components';

export const StepperDesktopWrap = styled.div`
  display: none;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
  }
`;

export const StepperMobileWrap = styled.div`
  display: block;
  ${({ theme }) => theme.breakpoints.up('md')} {
    display: none;
  }
`;
