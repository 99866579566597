import { createReducer } from 'redux-act';

import {
  tutorialClose,
  tutorialDeleteFail,
  tutorialDeleteStart,
  tutorialDeleteSuccess,
  tutorialOpen,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)
} from './tutorial.actions';

export const INITIAL_STATE = {
  tutorialType: null,
  tutorialData: [],
  isDeleting: false,
  deleteError: null,
};

const REDUCERS = {
  [tutorialOpen]: (state, { tutorialType, tutorialData }) =>
    Object.assign({}, state, {
      tutorialType,
      tutorialData: [...tutorialData],
    }),
  [tutorialClose]: state =>
    Object.assign({}, state, {
      tutorialType: null,
      tutorialData: [],
    }),
  [tutorialDeleteStart]: state =>
    Object.assign({}, state, {
      isDeleting: true,
      error: null,
    }),
  [tutorialDeleteSuccess]: state =>
    Object.assign({}, state, {
      isDeleting: false,
      error: null,
    }),
  [tutorialDeleteFail]: (state, { error }) =>
    Object.assign({}, state, {
      isDeleting: false,
      deleteError: error || 'unknown error',
    }),
  // (-- APPEND REDUCER ACTION MAPPING HERE --) !!! do not move this comment !!!
};

export default createReducer(REDUCERS, INITIAL_STATE);
