import React from 'react';

import useInsurers from 'hooks/useInsurers/useInsurers';

interface Props {
  ids: string[];
}

const InsurerCell: React.FC<Props> = ({ ids = [] }) => {
  const { getInsurersByIds } = useInsurers();
  const insurers = getInsurersByIds(ids);
  const insurantNames = insurers.map(insurer => insurer?.attributes.name);
  return <>{insurantNames.length > 0 ? insurantNames.join(', ') : '-'}</>;
};

export default InsurerCell;
