import { createSelector } from '@reduxjs/toolkit';

import { ROUTES } from 'constants/store';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { getUserModules } from 'store/user/user.selectors';
import { Store } from 'types/store/store.types';

import { affiliationTerminationReasonAdapter } from './affiliationSubmission.slice';

export const getAffiliationSubmissionCompanies = (state: Store) =>
  state.affiliationSubmission.companies.data;

export const getClientIdsUserCanAcces = createSelector(
  [getUserModules],
  modules => (modules ?? []).find(module => module?.key === ROUTES.HC_AFFILIATIONS)?.partnerIds,
);

export const getAffiliationClientsFilteredByUserRights = createSelector(
  [getAffiliationSubmissionCompanies, getClientIdsUserCanAcces],
  (data, clientIds) => data?.filter(client => clientIds?.includes(client.id)),
);

export const getAffiliationSubmissionCompaniesIsLoading = (state: Store) =>
  state.affiliationSubmission.companies.isLoading;
export const getAffiliationSubmissionCompaniesError = (state: Store) =>
  state.affiliationSubmission.companies.error;

export const getAffiliationSubmissionStatus = (state: Store) => state.affiliationSubmission.status;
export const getAffiliationSubmissionError = (state: Store) => state.affiliationSubmission.error;
export const getAffiliationSubmissionData = (state: Store) => state.affiliationSubmission.data;
export const getAffiliationSubmissionMeta = (state: Store) => state.affiliationSubmission.meta;

export const getFlowContext = (state: Store) => state.affiliationSubmission.flowContext;

export const getAffiliationSubmissionAffiliations = (state: Store) =>
  state.affiliationSubmission.affiliations.data;
export const getAffiliationSubmissionAffiliationsIsLoading = (state: Store) =>
  state.affiliationSubmission.affiliations.isLoading;
export const getAffiliationSubmissionAffiliationsError = (state: Store) =>
  state.affiliationSubmission.affiliations.error;

export const affiliationTerminationReasonsSelector = affiliationTerminationReasonAdapter.getSelectors<
  Store
>(state => state.affiliationSubmission.terminationReasons);

export const getAffiliationTerminationReasons = affiliationTerminationReasonsSelector.selectAll;
export const getAffiliationReason = affiliationTerminationReasonsSelector.selectById;

export const getAffiliationPoliciesEndpoint = (state: Store): string =>
  getEndpoint(state, 'hcAffiliatePolicies');

export const getAffiliationSubmissionFileConfig = (state: Store) =>
  state.endpoints.private.data?.attributes?.hcAffiliationsConfiguration;
