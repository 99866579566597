import React from 'react';

import { Box, Grid, Hidden } from '@material-ui/core';
import { Formik } from 'formik';
import { Params } from 'redux-first-router';

import { analyticsService } from 'services';
import { Category } from 'store/categories/categories.types';
import { DocumentsContext } from 'store/document/document.types';

import { DatePickerInputField } from 'components/@inputs/DatePicker';
import { SelectField } from 'components/@inputs/Select';
import TextInputField from 'components/@inputs/TextField/TextInputField';
import FormikAutoSave from 'components/FormikAutoSave';

interface Props {
  initialValues: Params;
  onFilterChange: (filters: Params) => void;
  categories?: { [index: string]: Category };
  context: DocumentsContext;
}

const Filter: React.FC<Props> = ({ initialValues, onFilterChange, categories, context }) => (
  <Box p={{ xs: 2, md: 6 }}>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => {
        if (values.q) {
          analyticsService.events.trackGenericFileSearch(context, values.q);
        }
        onFilterChange(values);
      }}
    >
      <>
        <FormikAutoSave debounce={200} />
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <TextInputField
              fullWidth
              name="q"
              fieldType="search"
              placeholder="general.documents.filter.search.placeholder"
            />
          </Grid>
          <Hidden smDown>
            <Grid item md={3}>
              <SelectField
                fullWidth
                name="category_ids"
                hasHeader
                isMulti
                options={Object.values(categories || {}).map(category => ({
                  value: category.id,
                  label: category.attributes.title,
                }))}
                placeholder="general.documents.filter.categories.placeholder"
              />
            </Grid>
            <Grid item md={3}>
              <DatePickerInputField
                fullWidth
                name="documentDate"
                placeholder="documents.filter.date.placeholder"
                isRangeSelect
                withFooter={false}
              />
            </Grid>
          </Hidden>
        </Grid>
      </>
    </Formik>
  </Box>
);

export default Filter;
