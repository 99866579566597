import React from 'react';

import { Box } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { SummaryField, SummaryTitle } from 'components/FormSummary';

import { StepperState } from '../../AffiliationsCreateStepper';
import AffiliationCardContent from '../AffiliationStep/AffiliationCardContent';

interface Props {
  onEditClick: () => void;
}

const AffiliationsSummary: React.FC<Props & StepperState['stepAffiliation']> = ({
  onEditClick,
  ...values
}) => {
  const intl = useIntl();
  return (
    <Box mb={8}>
      <SummaryTitle title="affiliations.add.step.affiliation.title" onEditClick={onEditClick} />
      <Box mb={6}>
        {Object.values(values.affiliations || {}).map(affiliation => (
          <Box
            key={affiliation.id}
            bgcolor="brand01.100"
            border="1px solid"
            borderColor="neutral01.300"
            mt={2}
            px={4}
            py={3}
            borderRadius={4}
          >
            <AffiliationCardContent affiliation={affiliation} />
          </Box>
        ))}
      </Box>
      <SummaryField
        label="affiliations.add.step.affiliation.start_date_label"
        value={values.startDate}
        type="date"
      />
      <SummaryField
        label="affiliations.add.step.affiliation.end_date_label"
        value={values.endDate}
        type="date"
      />
      {values.endDate && (
        <SummaryField
          label="affiliations.add.step.affiliation.reason_label"
          value={values.reason?.label}
        />
      )}
      <SummaryField
        label="affiliations.add.step.affiliation.is_belated_label"
        value={intl.formatMessage({ id: `general.label.${values.isBelated ? 'yes' : 'no'}` })}
      />
      {values.isBelated && (
        <SummaryField
          label="affiliations.add.step.affiliation.is_belated_date_label"
          value={values.belatedDate}
          type="date"
        />
      )}
    </Box>
  );
};

export default AffiliationsSummary;
