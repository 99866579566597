import React, { useRef, useState } from 'react';

import _get from 'lodash/get';

import { BACKSPACE } from 'utils/keyCodes';

import Chip from 'components/@common/Chip';

import { ChipWrap, VisibilyHiddenInput } from './TagInput.styled';

interface Props {
  item: string;
  onRemove: (item: string) => void;
}

const TagInputChip: React.FC<Props> = ({ item, onRemove }) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const toggelFocus = () => {
    setIsFocused(!isFocused);
    if (!isFocused && typeof _get(inputRef, 'current.focus') == 'function') {
      // dunno why but ts still bitches that it could be null
      const currentRef = inputRef.current as any;
      currentRef.focus();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === BACKSPACE) {
      if (isFocused) {
        onRemove(item);
      }
    }
  };

  return (
    <ChipWrap ref={inputRef} id={item}>
      <VisibilyHiddenInput
        tabIndex={-12}
        onKeyUp={handleKeyUp}
        aria-hidden={true}
        onBlur={toggelFocus}
        value={item}
        readOnly
      />
      <Chip onClick={toggelFocus} onRemove={() => onRemove(item)} isFocused={isFocused} key={item}>
        {item}
      </Chip>
    </ChipWrap>
  );
};

export default TagInputChip;
