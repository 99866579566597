const loadAnalytics = options => {
  let gaAddress = 'https://www.google-analytics.com/analytics.js';

  if (options && options.debug) {
    gaAddress = 'https://www.google-analytics.com/analytics_debug.js';
  }

  /* eslint-disable */
  (function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function() {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', gaAddress, 'ga');
  /* eslint-enable */
};

export default loadAnalytics;
