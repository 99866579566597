import { getCategoriesForEntity } from 'store/categories/categories.selectors';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';
import { Store } from 'types/store/store.types';

// FILES
export const getSharedFiles = (state: Store) => state.dataShare.data;
export const getSharedFilesLoading = (state: Store) => state.dataShare.status === 'loading';
export const getSharedFilesError = (state: Store) => state.dataShare.error;
export const getSharedFilesMeta = (state: Store) => state.dataShare.meta;

// FILE CATEGORIES
export const getDataShareCategories = (state: Store) =>
  getCategoriesForEntity(state, 'sharedFileCategory');

// DOWNLOAD
export const getSharedFileDownloading = (state: Store) => state.dataShare.status === 'downloading';
export const getSharedFileDeleting = (state: Store) => state.dataShare.status === 'deleting';
// ENDPOINTS
export const getFilesEndpoint = (state: Store) => getEndpoint(state, 'sharedFiles');
