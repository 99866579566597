import React, { Fragment } from 'react';

import _get from 'lodash/get';

import { LinkButton } from 'components/@common/Link';
import { H2, H3 } from 'components/@typography/Titles';
import Icon from 'components/Icon';
import { UIcoPijlLinks } from 'components/Icons';

import { StepperProps, StepperStep } from '../Stepper';
import {
  BackLink,
  Counter,
  StepContent,
  StepCount,
  StepDescription,
  StepperHeaderWrap,
} from './StepperMobile.styled';

let prevSteps: StepperStep[] = [];

const StepperMobile: React.FC<StepperProps> = ({
  steps,
  mainTitle,
  activeStep,
  onEditClick,
  topContent,
}) => {
  const currentStep = steps.find(step => step.id === activeStep) || steps[0];
  const index = steps.indexOf(currentStep);
  if (_get(prevSteps[prevSteps.length - 1], 'id') !== activeStep) {
    prevSteps.push(currentStep);
  }

  return (
    <Fragment>
      <StepperHeaderWrap>
        <BackLink data-test-id="stepper-back-link-icon">
          <LinkButton
            hasUnderline={false}
            onClick={() => {
              onEditClick(_get(prevSteps[prevSteps.length - 2], 'id'));
              prevSteps = prevSteps.slice(0, prevSteps.indexOf(prevSteps[prevSteps.length - 2]));
            }}
            disabled={index === 0}
          >
            <Icon color={index === 0 ? 'neutral01.500' : 'brand01.400'} size={24} spacing={4}>
              <UIcoPijlLinks />
            </Icon>
          </LinkButton>
        </BackLink>

        <H2 color="brand01.400" centered>
          {mainTitle}
        </H2>
        <Counter>
          <StepCount>{index + 1}</StepCount> / {steps.length}
        </Counter>
      </StepperHeaderWrap>
      {currentStep && (
        <Fragment>
          {currentStep.description && <StepDescription>{currentStep.description}</StepDescription>}
          <StepContent>
            {topContent}
            <H3 color="brand01.300" marginBottom={16}>
              {currentStep.label}
            </H3>
            {currentStep.content}
          </StepContent>
        </Fragment>
      )}
    </Fragment>
  );
};

export default StepperMobile;
