import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  .notistack.notistack-container-bottom-right {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 100%;
      right: 0;
      bottom: ${({ theme }) => theme.mixins.headerHeight}px;
    }
  }
`;
