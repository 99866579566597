import React from 'react';

import { connect } from 'react-redux';

import { getFlowContext } from 'store/affiliationSubmission/affiliationSubmission.selectors';
import { Store } from 'types/store/store.types';

import AffiliationsCreateContext from './AffiliationsCreateContext';

type ChildProps = React.ComponentProps<typeof AffiliationsCreateContext>;

const AffiliationsCreateContextContainer: React.FC<ContainerProps & ChildProps> = props => {
  return <AffiliationsCreateContext {...props} />;
};

const mapStateToProps = (state: Store) => ({
  flowContext: getFlowContext(state),
});
const mapDispatchToProps = {};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type MapDispatchProps = typeof mapDispatchToProps;
export type ContainerProps = MapStateProps & MapDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(AffiliationsCreateContextContainer);
