// --plop_insert_route_import--
import { ROUTES } from 'constants/store';

import AffiliationsCreate from 'pages/affiliations/Create';
import AffiliationDetail from 'pages/affiliations/Detail';
import AffiliationsOverview from 'pages/affiliations/Overview';
import BulletinboardDetail from 'pages/bulletinboard/Detail';
import BulletinboardOverview from 'pages/bulletinboard/Overview';
import ClaimReportsCreate from 'pages/claimreports/Create';
import ClaimReportsOverview from 'pages/claimreports/Overview';
import ClaimDocuments from 'pages/claims/ClaimDocuments';
import ClaimCreate from 'pages/claims/Create';
import ClaimDetail from 'pages/claims/Detail';
import ClaimOverview from 'pages/claims/Overview';
import ClientsDetail from 'pages/clients/Detail';
import ClientsOverview from 'pages/clients/Overview';
import DashboardOverview from 'pages/dashboard/Overview';
import DocumentsCreate from 'pages/documents/Create';
import DocumentsOverview from 'pages/documents/Overview';
import ImpersonateCallback from 'pages/impersonate/Callback';
import InvoiceDetail from 'pages/invoices/Detail';
import InvoiceOverview from 'pages/invoices/Overview';
import PolicyDetail from 'pages/policies/Detail';
import PolicyOverview from 'pages/policies/Overview';
import UserSettings from 'pages/profile/UserSettings';
import PublicationsOverview from 'pages/publications/Overview';
import RiskDetail from 'pages/risks/Detail';
import VehicleCertificatesSearch from 'pages/vehicleCertificates/Search';

// TODO: refactor to remove moduleName and base it on the ROUTES constant

export const moduleNames = {
  dashboard: 'dashboard',
  claims: 'claims',
  claimsDetail: 'claimsDetail',
  claimsCreate: 'claimSubmission',
  invoices: 'invoices',
  invoicesDetail: 'invoicesDetail',
  policies: 'insurance',
  policiesDetail: 'insuranceDetail',
  riskDetail: 'riskDetail',
  settings: 'settings',
  documents: 'datacom',
  documentsCreate: 'documentsSubmission',
  clients: 'clients',
  clientsDetail: 'clientsDetail',
  bulletinboard: 'bulletinboard',
  bulletinboardDetail: 'bulletinboardDetail',
  claimReportsCreate: 'claimReportsCreate',
};

export default {
  [ROUTES.DASHBOARD]: DashboardOverview,
  [ROUTES.POLICIES]: PolicyOverview,
  [ROUTES.POLICY_DETAIL]: PolicyDetail,
  [ROUTES.RISK_DETAIL]: RiskDetail,
  [ROUTES.CLAIMS]: ClaimOverview,
  [ROUTES.CLAIM_CREATE]: ClaimCreate,
  [ROUTES.CLAIM_DOCUMENTS]: ClaimDocuments,
  [ROUTES.CLAIM_DETAIL]: ClaimDetail,
  [ROUTES.DATASHARE]: DocumentsOverview,
  [ROUTES.DATASHARE_CREATE]: DocumentsCreate,
  [ROUTES.SETTINGS]: UserSettings,
  [ROUTES.PARTNERS]: ClientsOverview,
  [ROUTES.PARTNERS_DETAIL]: ClientsDetail,
  [ROUTES.INVOICES]: InvoiceOverview,
  [ROUTES.INVOICE_DETAIL]: InvoiceDetail,
  [ROUTES.HC_AFFILIATIONS]: AffiliationsOverview,
  [ROUTES.IMPERSONATE]: ImpersonateCallback,
  [ROUTES.VEHICLE_CERTIFICATES]: VehicleCertificatesSearch,
  [ROUTES.HC_AFFILIATIONS_CREATE]: AffiliationsCreate,
  [ROUTES.HC_AFFILIATIONS_DETAIL]: AffiliationDetail,
  [ROUTES.CLAIM_REPORTS]: ClaimReportsOverview,
  [ROUTES.CLAIM_REPORTS_CREATE]: ClaimReportsCreate,
  [ROUTES.PUBLICATIONS]: PublicationsOverview,
  [ROUTES.BULLETINBOARD]: BulletinboardOverview,
  [ROUTES.BULLETINBOARD_DETAIL]: BulletinboardDetail,
  // --plop_insert_route--
};
