import React from 'react';

import Highlighter from 'react-highlight-words';

import { HighlightMark } from './HighlightSearch.styled';

interface Props {
  search?: string;
  text: string;
}

const HighlightSearch: React.FC<Props> = ({ text, search = '' }) => {
  return (
    <Highlighter
      searchWords={search.split(/\s/).filter(word => word)}
      autoEscape
      textToHighlight={text}
      highlightTag={({ children }) => <HighlightMark>{children}</HighlightMark>}
    />
  );
};

export default HighlightSearch;
