import React from 'react';

import { Formik } from 'formik';
import { connect } from 'react-redux';

import { AddressFormValidation, AddressFormValues } from 'pages/affiliations/Forms';

import AddressStep from './AddressStep';

type ChildProps = React.ComponentProps<typeof AddressStep>;

export type FormValues = AddressFormValues;
type Props = {
  onNextStepClick: (values: FormValues) => void;
  initialValues: FormValues;
};

const AddressStepContainer: React.FC<Props & ContainerProps & ChildProps> = ({
  onNextStepClick,
  initialValues,
  saveForm,
}) => {
  return (
    <Formik<FormValues>
      validateOnMount
      validationSchema={AddressFormValidation}
      initialValues={initialValues}
      onSubmit={values => {
        onNextStepClick(values);
      }}
    >
      <AddressStep saveForm={saveForm} />
    </Formik>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

type MapStateProps = ReturnType<typeof mapStateToProps>;
type MapDispatchProps = typeof mapDispatchToProps;
export type ContainerProps = MapStateProps & MapDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddressStepContainer);
