import React from 'react';

import { Box, Tooltip, TooltipProps } from '@material-ui/core';

import { useMedia } from 'components/Media';

import { Count, Content } from './IdsTooltip.styled';
interface Props {
  ids?: string | string[];
  placement?: TooltipProps['placement'];
  separator?: string;
  includeFirst?: boolean;
}

const IdsTooltip: React.FC<Props> = ({
  ids,
  placement = 'top',
  separator = ', ',
  includeFirst = false,
}) => {
  const media = useMedia();
  if (!ids) {
    return null;
  }
  const idsArray = typeof ids === 'string' ? [ids] : ids;

  const idsCount = idsArray.length;
  const handleCountClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  if (media.small) {
    return (
      <Box display="inline-flex" alignItems="center">
        <Box flex="1 1 auto">{idsArray[0]}</Box>

        {idsCount > 1 && (
          <>
            <Count>+{idsCount - 1}</Count>
          </>
        )}
      </Box>
    );
  }

  return (
    <Box display="inline-flex" alignItems="center">
      <Box flex="1 1 auto">{idsArray[0]}</Box>

      {idsCount > 1 && (
        <>
          <Tooltip
            arrow
            placement={placement}
            title={
              <Content>
                {includeFirst ? idsArray.join(separator) : idsArray.slice(1).join(separator)}
              </Content>
            }
          >
            <Count onClick={handleCountClick}>+{idsCount - 1}</Count>
          </Tooltip>
        </>
      )}
    </Box>
  );
};

export default IdsTooltip;
