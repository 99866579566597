import React from 'react';

import _fill from 'lodash/fill';

import { randomIntFromInterval } from 'utils/helpers';

import { SkeletonLine } from 'components/Loaders';

import { SkeletonLineWrap } from './TagList.styled';
interface Props {
  numberOfLoadingTags?: number;
  spacing: number;
}

const TagListLoader: React.FC<Props> = ({ numberOfLoadingTags = 7, spacing }) => (
  <>
    {_fill(Array(numberOfLoadingTags), null).map((item, key) => (
      <SkeletonLineWrap spacing={spacing} key={key}>
        <SkeletonLine minWidth={randomIntFromInterval(50, 150)} height={18} />
      </SkeletonLineWrap>
    ))}
  </>
);

export default TagListLoader;
