import React, { useRef } from 'react';

import Downshift, { Actions, ControllerStateAndHelpers } from 'downshift';
import _get from 'lodash/get';
import { useIntl } from 'react-intl';

import { LinkButton } from 'components/@common/Link';
import { TextInput as Input } from 'components/@inputs/TextField';
import Icon from 'components/Icon';
import { UIcoZoek } from 'components/Icons';
import { IconLoader } from 'components/Loaders';
import Popper from 'components/Popper';

import { DropdownMenu } from './components';

export interface DropdownProps<Item> {
  id?: string;
  name?: string;
  disabled?: boolean;
  isValid?: boolean;
  isLoading?: boolean;
  isValidating?: boolean;
  itemToString?: (item: Item) => string;
  itemToOption?: (item: Item, inputValue: string) => React.ReactNode;
  onChange?: (item: Item, helpers?: ControllerStateAndHelpers<Item>) => void;
  onInputChange?: (value: string) => void;
  onEdit?: (item: Item) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: Item;
  options?: Item[];
  recentOptions?: Item[];
  ListItemLoader?: React.ReactNode;
  Footer?: React.ReactNode;
  hasError?: boolean;
  minInputLength?: number;
  placeholder?: string;
  inputValue?: string;
  readOnlyOnSelection?: boolean;
  withSearchIcon?: boolean;
}

const Dropdown = <T extends any>({
  id,
  name,
  value,
  inputValue = '',
  isValid,
  disabled,
  isLoading,
  isValidating,
  hasError,
  Footer,
  onBlur,
  placeholder,
  withSearchIcon = true,
  readOnlyOnSelection = true,
  itemToOption = item => <div>dit is {`${item}`}</div>,
  recentOptions = [],
  onEdit = () => {},
  onInputChange = () => {},
  options = [],
  itemToString = () => '',
  onChange = () => {},
  ListItemLoader = () => <div>Loading...</div>,
  minInputLength = 2,
}: DropdownProps<T>) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLDivElement>(null);
  const checkPopperShow = () => {
    if (!isValid || !readOnlyOnSelection) {
      if (inputValue && inputValue.length >= minInputLength) {
        return inputValue.length >= minInputLength && _get(options, 'length', -1) > 0;
      }
      return !isLoading && (recentOptions || []).length > 0;
    }
    return false;
  };

  const handleInputChange = (openMenu: Actions<T>['openMenu']) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.persist();
    onInputChange(e.target.value);
    openMenu();
  };

  const handleChange = (item: T, helpers: ControllerStateAndHelpers<T>) => {
    if (!isValidating && item) {
      onChange(item, helpers);
    }
  };

  return (
    <Downshift
      data-test-id="vbrbEmailSuggestions"
      itemToString={(item: T) => (item ? itemToString(item) : '')}
      inputValue={inputValue}
      onSelect={handleChange}
      selectedItem={value}
    >
      {({ getInputProps, isOpen, getItemProps, highlightedIndex, openMenu, getMenuProps }) => {
        const {
          id: _id,
          value: _val,
          onBlur: handleBlur,
          onChange: _oc,
          ...rest
        } = getInputProps();
        return (
          <div>
            <div ref={inputRef}>
              <Input
                id={id}
                name={name}
                data-test-id={name}
                onFocus={() => openMenu()}
                variant={readOnlyOnSelection && isValid ? 'filled' : undefined}
                value={inputValue}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  onBlur && onBlur(e);
                  handleBlur(e);
                }}
                disabled={disabled}
                onChange={!isValid || !readOnlyOnSelection ? handleInputChange(openMenu) : () => {}}
                readOnly={isValid && readOnlyOnSelection}
                autoComplete="false"
                placeholder={placeholder && intl.formatMessage({ id: placeholder })}
                startAdornment={
                  withSearchIcon && (
                    <Icon color="brand01.300">
                      <UIcoZoek />
                    </Icon>
                  )
                }
                endAdornment={
                  isLoading || isValidating ? (
                    <IconLoader size={18} color="brand01.300" />
                  ) : (
                    isValid &&
                    readOnlyOnSelection && (
                      <LinkButton onClick={() => value && onEdit(value)}>
                        {intl.formatMessage({ id: 'general.edit.text' })}
                      </LinkButton>
                    )
                  )
                }
                inputProps={{
                  ...rest,
                }}
              />
            </div>
            {checkPopperShow() && (
              <Popper
                withArrow={false}
                open={isOpen}
                anchorEl={inputRef.current}
                backdropProps={{ invisible: true }}
                width={`${inputRef.current && inputRef.current.clientWidth}px`}
                maxHeight={300}
              >
                <DropdownMenu
                  highlightedIndex={highlightedIndex}
                  selectedItem={value}
                  isLoading={isLoading}
                  error={hasError}
                  withHeader={!inputValue}
                  data={inputValue ? options : recentOptions}
                  getItemProps={getItemProps}
                  itemRender={itemToOption}
                  inputValue={inputValue}
                  ListItemLoader={ListItemLoader}
                  getMenuProps={getMenuProps}
                  Footer={Footer}
                />
              </Popper>
            )}
          </div>
        );
      }}
    </Downshift>
  );
};

export default Dropdown;
