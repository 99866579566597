import styled from 'styled-components';

export const BottomLabel = styled.div<{ error?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.57;
  color: ${({ theme, error }) =>
    error ? theme.palette.accent02[400] : theme.palette.neutral01[500]};
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
`;
