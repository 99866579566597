import React from 'react';

import { Box } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { locales } from 'services/i18n/config';

import useFeatures from 'utils/useFeatureToggle';

import { SelectField } from 'components/@inputs/Select';
import { InputField } from 'components/@inputs/TextField';

interface Props {
  hideFields?: string[];
}

const PeronalSettingsFields: React.FC<Props> = ({ hideFields = [] }) => {
  const intl = useIntl();
  const { isFeatureEnabled } = useFeatures();

  const languages = locales.map(lang => ({
    value: lang,
    label: intl.formatMessage({
      id: `profile.settings.language.${lang}`,
    }),
  }));

  return (
    <Box mt={1}>
      {!hideFields.includes('firstName') && (
        <Box mb={8}>
          <InputField
            disabled={isFeatureEnabled('identity')}
            label="profile.user.labels.name"
            name="firstName"
            isRequired
            fullWidth
          />
        </Box>
      )}
      {!hideFields.includes('lastName') && (
        <Box mb={8}>
          <InputField
            disabled={isFeatureEnabled('identity')}
            label="profile.user.labels.surname"
            name="lastName"
            isRequired
            fullWidth
          />
        </Box>
      )}
      <Box mb={8}>
        <InputField label="profile.user.labels.function" name="function" fullWidth />
      </Box>
      <Box mb={8}>
        <InputField label="profile.user.labels.telephone" name="phoneNumber" fullWidth />
      </Box>
      <Box mb={8}>
        <SelectField
          label="profile.settings.language.label"
          name="language"
          options={languages}
          isRequired
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default PeronalSettingsFields;
