import { useCallback, useRef } from 'react';

export const useGetLatest = <T>(obj: T) => {
  const ref = useRef<T>(obj);
  ref.current = obj;
  return useCallback(() => ref.current, []);
};

// https://github.com/tannerlinsley/react-table/blob/master/src/publicUtils.js#L163

export const useAsyncDebounce = <T extends any[], ReturnType extends unknown>(
  defaultFn: (...args: T) => PromiseLike<ReturnType> | ReturnType,
  defaultWait: number = 0,
) => {
  const debounceRef = useRef<{
    promise?: Promise<ReturnType>;
    resolve: (value?: ReturnType) => void;
    reject: (reason: unknown) => void;
    timeout?: number;
  }>({
    resolve: () => {},
    reject: () => {},
  });

  const getDefaultFn = useGetLatest<typeof defaultFn>(defaultFn);
  const getDefaultWait = useGetLatest<number>(defaultWait);

  return useCallback(
    async (...args) => {
      if (!debounceRef.current.promise) {
        debounceRef.current.promise = new Promise((resolve, reject) => {
          debounceRef.current.resolve = resolve;
          debounceRef.current.reject = reject;
        });
      }

      if (debounceRef.current.timeout) {
        clearTimeout(debounceRef.current.timeout);
      }

      debounceRef.current.timeout = setTimeout(async () => {
        delete debounceRef.current.timeout;
        try {
          const result = await getDefaultFn()(...(args as T));
          debounceRef.current.resolve(result);
        } catch (err) {
          debounceRef.current.reject(err);
        } finally {
          delete debounceRef.current.promise;
        }
      }, getDefaultWait());

      return debounceRef.current.promise;
    },
    [getDefaultFn, getDefaultWait],
  );
};
