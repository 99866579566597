import React, { useEffect, useRef } from 'react';

import { Box } from '@material-ui/core';
import { useFormikContext } from 'formik';
import usePrevious from 'hooks/usePrevious/usePrevious';
import _isEqual from 'lodash/isEqual';

import { EmploymentCondition } from 'store/claimSubmission/types';

import { DatePickerInputField } from 'components/@inputs/DatePicker';
import { NumberField } from 'components/@inputs/Number';
import { SelectField } from 'components/@inputs/Select';

export type SalaryDataFormValues = {
  employmentPercentage?: string;
  employmentConditions?: EmploymentCondition[];
  grossSalary?: string;
  employmentCondition?: {
    value: string;
    label: string;
  };
  startDate?: string;
  isCurrent?: boolean;
};

interface Props {
  wageType: string;
  wageDecimals: number;
  employmentConditions?: {
    key: string;
    title: string;
  }[];
  minStartDate?: Date;
}

const SalaryDataForm: React.FC<Props> = ({
  wageType,
  wageDecimals,
  employmentConditions,
  minStartDate,
}) => {
  const { values, setFieldValue } = useFormikContext<SalaryDataFormValues>();
  const setFieldValueRef = useRef(setFieldValue);
  const prevValues = usePrevious<SalaryDataFormValues>(values);
  useEffect(() => {
    const { startDate: _startDate, ...restValues } = values;
    const { startDate: _prevStartdate, ...restPrevValues } = prevValues ?? {};
    if (!_isEqual(restValues, restPrevValues) && Object.values(restPrevValues).length > 0) {
      setFieldValueRef.current('startDate', null);
    }
  }, [prevValues, values]);
  return (
    <>
      <Box mb={8} width={{ xs: '100%', md: '50%' }}>
        <NumberField
          name="employmentPercentage"
          fieldType="percentage"
          placeholder="general.percentage_input.placeholder"
          fullWidth
          isRequired
          label="affiliations.add.step.additional_info.employment_percentage_label"
          startAdornment="%"
        />
      </Box>
      <Box width={{ xs: '100%', md: '50%' }} mb={8}>
        <NumberField
          name="grossSalary"
          label={
            wageType === 'MONTHLY' ? 'general.gross_wage.text' : 'general.gross_wage_hourly.text'
          }
          decimalScale={wageDecimals}
          fullWidth
          isRequired
          startAdornment="€"
        />
      </Box>
      {employmentConditions && employmentConditions.length > 0 && (
        <Box width={{ xs: '100%', md: '50%' }} mb={8}>
          <SelectField
            name="employmentCondition"
            fullWidth
            isRequired
            menuPortalTarget={document.body}
            options={employmentConditions.map(condition => ({
              label: condition.title,
              value: condition.key,
            }))}
            placeholder="claims.tpa_gi_employment_condition.placeholder"
            label="claims.tpa_gi_employment_condition.label"
          />
        </Box>
      )}
      <Box mb={8}>
        <DatePickerInputField
          name="startDate"
          label="affiliations.edit.salary.start_date_label"
          isFillable
          inputWidth={{ xs: '100%', md: '50%' }}
          fullWidth
          isRequired
          isRangeSelect={false}
          backdropProps={{
            zIndex: 1499,
          }}
          disableBefore={minStartDate}
        />
      </Box>
    </>
  );
};

export default SalaryDataForm;
