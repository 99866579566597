import React, { Fragment, PureComponent } from 'react';

import Grow from '@material-ui/core/Grow';

import Backdrop from 'components/Backdrop';
import withMedia from 'components/Media';

import { PopperContent, PopperStyled } from './Popper.styled';

class Popper extends PureComponent {
  static defaultProps = {
    placement: 'bottom-start',
    open: false,
    withBackDrop: true,
    isFullScreenOnMobile: false,
    withArrow: true,
    transition: true,
    backdropProps: {
      invisible: false,
    },
    onBackdropClick: () => {},
  };
  getOffset = anchorEl => {
    if (!anchorEl) return 0;
    const element = anchorEl.getBoundingClientRect();
    return element.top + element.height + 9;
  };
  render() {
    const {
      placement,
      children,
      open,
      withArrow,
      backdropProps,
      onBackdropClick,
      withBackDrop,
      isFullScreenOnMobile,
      media,
      maxHeight,
      ...rest
    } = this.props;

    return (
      <Fragment>
        <PopperStyled
          isFullScreenOnMobile={isFullScreenOnMobile}
          placement={placement}
          open={open}
          media={media}
          offset={this.getOffset(rest.anchorEl)}
          {...rest}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={150}>
              <PopperContent
                data-test-id="tooltip-modal"
                maxHeight={maxHeight}
                className="popperContent"
                withArrow={withArrow}
                placement={placement}
              >
                {children}
              </PopperContent>
            </Grow>
          )}
        </PopperStyled>
        {open && withBackDrop && (
          <Backdrop {...backdropProps} onClick={onBackdropClick} open={open} />
        )}
      </Fragment>
    );
  }
}
export default withMedia(Popper);
