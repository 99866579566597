import { connect } from 'react-redux';

import { fileUploadStart, initFileUpload } from 'store/file/file.actions';
import {
  getFailedFiles,
  getIsFileUploadCompleted,
  getUploadedFiles,
} from 'store/file/file.selectors';

import FileUploader from './FileUploader';

const mapStateToProps = state => ({
  isCompleted: getIsFileUploadCompleted(state),
  uploadedFiles: getUploadedFiles(state),
  failedFiles: getFailedFiles(state),
});
const mapDispatchToProps = {
  uploadFile: fileUploadStart,
  initFileUpload,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
