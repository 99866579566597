import { AxiosInstance } from 'axios';

import config from 'config/env';

import AnalyticsService from './Analytics';
import RestApiService from './Api';
import axiosApi from './Api/axios';
import AuthService from './Auth';
import ErrorTrackingService from './ErrorTracking';
import EventEmitter from './EventEmitter';

/**
 * javascript / typescript don't support singletons
 * so we create a constant per service that contains our instance of this service
 * -
 * if you need to use a service somewhere (e.g. a saga) you can import it from here
 */
// export let apiService: IRestApiService;
export let eventEmitter: EventEmitter;
export let analyticsService: AnalyticsService;
export let apiService: RestApiService<AxiosInstance>;
export let authService: AuthService;
export let errorTrackingService: ErrorTrackingService;
/**
 * initialize services
 */
export function initServices() {
  eventEmitter = new EventEmitter();
  analyticsService = new AnalyticsService(config.ANALYTICS_GOOGLE_TRACKING_ID);
  apiService = new RestApiService<AxiosInstance>({ api: axiosApi });
  errorTrackingService = new ErrorTrackingService(config.APPLICATION_INSIGHTS_KEY);
}

export function initAuthService(issuer: string, oktaClientId: string, isPkce = true) {
  if (!issuer || !oktaClientId) {
    throw new Error('cannot initialize auth service');
  } else {
    authService = new AuthService(issuer, oktaClientId, isPkce);
  }
}
