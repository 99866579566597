import styled from 'styled-components';

export const BottomLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.57;
  color: ${({ theme, hasError }) =>
    hasError ? theme.palette.accent02[400] : theme.palette.neutral01[500]};
  margin-top: 2px;
`;

export default null;
