import React from 'react';

import { FormattedMessage } from 'react-intl';

import Icon from 'components/Icon';
import { UIcoSchade } from 'components/Icons';

import { InnerText, InnerWrap, Wrap } from './Placeholders.styled';

export interface Props {
  customText?: React.ReactNode;
  customIcon?: React.ReactNode;
  iconSize?: number;
  type?: 'inline' | 'block' | 'page';
  maxWidth?: string;
}

const NoDataPlaceholder: React.FC<Props> = ({
  customText = <FormattedMessage id="general.nodata.text" />,
  customIcon = <UIcoSchade />,
  iconSize = 64,
  type = 'block',
  maxWidth = '300px',
}) => (
  <Wrap context={type}>
    <InnerWrap context={type}>
      <Icon size={iconSize} color="brand01.200">
        {customIcon}
      </Icon>
      <InnerText maxWidth={maxWidth} context={type}>
        {customText}
      </InnerText>
    </InnerWrap>
  </Wrap>
);

export default NoDataPlaceholder;
