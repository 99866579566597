import { Box } from '@material-ui/core';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { types } from './constants';

const warningStyle = css`
  background-color: ${({ theme }) => theme.palette.accent02[50]};
  color: ${({ theme }) => theme.palette.neutral01[50]};
`;

const alertStyle = css`
  background-color: ${({ theme }) => transparentize(0.2, theme.palette.accent02[300])};
  color: ${({ theme }) => theme.palette.neutral01[50]};
`;

const successStyle = css`
  background-color: ${({ theme }) => transparentize(0.8, theme.palette.accent01[300])};
  color: ${({ theme }) => transparentize(0.2, theme.palette.accent01[300])};
`;

const defaultStyle = css`
  color: ${({ theme }) => theme.palette.brand01[300]};
  line-height: 1.5;
  font-weight: 400;
  background-color: ${({ theme }) => theme.palette.brand01[800]};
`;

export const Wrap = styled(Box)`
  display: flex;
  padding: ${({ theme }) => theme.spacing(3)}px;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme, margin }) =>
    margin ?? `0 ${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: ${({ theme, margin }) => margin ?? `0 0 ${theme.spacing(4)}px 0`};
  }

  ${({ type }) => {
    switch (type) {
      case types.ERROR:
        return alertStyle;
      case types.WARNING:
        return warningStyle;
      case types.SUCCESS:
        return successStyle;
      default:
        return defaultStyle;
    }
  }};
`;

export default null;
