import { UserFilters } from 'hooks/useUsers/useUsers';

import { ApiInstanceType } from './';
import { ExternalUserResponse, ExternalUsersResponse } from './types/users.types';
class UserApi {
  private _api: ApiInstanceType;

  constructor(api: ApiInstanceType) {
    this._api = api;
  }

  get api() {
    return this._api;
  }

  set api(api) {
    this._api = api;
  }

  fetchExternalUsers = (path: string, filters: UserFilters) =>
    this.api
      .get<ExternalUsersResponse>(path, {
        params: filters,
      })
      .then(response => response.data);

  fetchExternalUserById = (path: string, id: string) =>
    this.api.get<ExternalUserResponse>(`${path}/${id}`).then(response => response.data);

  exportUsers = (path: string, filters: UserFilters) =>
    this.api
      .get<Blob>(path, {
        cache: {
          maxAge: 0,
          ignoreCache: true,
        },
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        params: filters,
      })
      .then(response => response);
}

export default UserApi;
