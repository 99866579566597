import React from 'react';

import _get from 'lodash/get';
import { AutoSizer } from 'react-virtualized';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from 'components/@common/Table';
import { useMedia } from 'components/Media';
import Placeholder from 'components/Placeholders';

import HeadCell from './HeadCell';
import { TableWrap } from './OverviewTable.styled';
import { OverviewTableConfigItem, OverviewTableProps } from './OverviewTable.types';

const OverviewTable = <IData extends {}>({
  config,
  isLoading,
  error,
  page,
  maxPerPage,
  totalRows,
  onPageChange,
  onRowsPerPageChange,
  CustomPaginationRightComponent,
  emptyText,
  emptyIcon,
  orderBy,
  withRowHighlight,
  minHeight,
  data = [],
  onRowClick = () => {},
  onRequestSort = () => {},
  isRowClickable = () => true,
  isRowGreyedOut = () => false,
  withPagination = true,
  paginationProps = {},
}: OverviewTableProps<IData>) => {
  const media = useMedia();

  const getCellContent = (row: IData, cell: OverviewTableConfigItem<IData>) => {
    if (cell.cellRender) {
      return cell.cellRender({ ...row });
    }
    return _get(row, cell.value, cell.type !== 'date' && '-');
  };

  const isCellHidden = (cell: OverviewTableConfigItem<IData>, row?: IData) => {
    let hide = false;
    if (cell.hideOnBreakpoints) {
      const shouldCollapse = cell.hideOnBreakpoints.filter(breakpoint => media[breakpoint]);
      hide = shouldCollapse.length > 0;
    }
    if (cell.hideWhen) {
      hide = hide || cell.hideWhen(row);
    }
    return hide;
  };

  const handleRowClick = (row: IData) => (event: React.MouseEvent) => {
    if (isRowClickable(row)) {
      onRowClick(event, row);
    }
  };

  return (
    <TableWrap minHeight={minHeight} data-test-id="overview-table-container">
      <AutoSizer
        style={{ display: 'flex', flex: '1 auto', flexDirection: 'column' }}
        disableWidth
        disableHeight={media.small}
      >
        {({ height }) => {
          return (
            <>
              <Table component="div">
                <TableHead>
                  <TableRow>
                    {config.map((cell, i) => (
                      <HeadCell
                        key={i}
                        hidden={isCellHidden(cell)}
                        initialOrder={
                          orderBy ? (orderBy.split(':')[1] as 'asc' | 'desc') : undefined
                        }
                        initialOrderBy={orderBy && orderBy.split(':')[0]}
                        onRequestSort={onRequestSort}
                        cell={cell}
                      />
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody
                  style={{
                    maxHeight: height ? height - 113 : 'none',
                    overflowY: 'auto',
                  }}
                  component="div"
                >
                  <Placeholder
                    data={data}
                    isLoading={isLoading}
                    noDataProps={{
                      customIcon: emptyIcon,
                      customText: emptyText,
                    }}
                    error={error}
                  >
                    {data.map((row, index) => (
                      <TableRow
                        key={index}
                        enableHover={!!isRowClickable(row)}
                        withRowHighlight={!!withRowHighlight}
                        onClick={handleRowClick(row)}
                      >
                        {config.map((cell, i) => {
                          const cellProps = cell?.cellProps;
                          return (
                            !isCellHidden(cell, row) && (
                              <TableCell
                                isGreyedOut={!!isRowGreyedOut(row)}
                                {...cellProps}
                                onClick={(e: React.MouseEvent) =>
                                  cellProps?.onClick && cellProps?.onClick(e, row)
                                }
                                type={cell.type}
                                style={{ width: `${cell.width}px` }}
                                key={`td${i}`}
                              >
                                {getCellContent(row, cell)}
                              </TableCell>
                            )
                          );
                        })}
                      </TableRow>
                    ))}
                  </Placeholder>
                </TableBody>
                {withPagination && (
                  <div>
                    <TablePagination
                      onPageChange={onPageChange}
                      onRowsPerPageChange={onRowsPerPageChange}
                      rowsPerPage={maxPerPage}
                      isSmall={media.small}
                      page={page}
                      count={totalRows}
                      CustomRightComponent={CustomPaginationRightComponent}
                      {...paginationProps}
                    />
                  </div>
                )}
              </Table>
            </>
          );
        }}
      </AutoSizer>
    </TableWrap>
  );
};

export default OverviewTable;
