import { useSelector } from 'react-redux';

import { getUser } from 'store/user/user.selectors';

const useCurrentUser = () => {
  const user = useSelector(getUser);
  return {
    user,
  };
};

export default useCurrentUser;
