import React from 'react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const withRedux = (Component: any, { store, persistor, initApp }: any, Loader: any = null) =>
  class Enhance extends React.Component {
    public render() {
      return (
        <Provider store={store}>
          <PersistGate loading={Loader} persistor={persistor} onBeforeLift={initApp}>
            <Component {...this.props} />
          </PersistGate>
        </Provider>
      );
    }
  };

export default withRedux;
