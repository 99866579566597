import { call, put, select, takeLatest } from 'redux-saga/effects';

import env from 'config';

import { fetchVehicleCertificates } from 'api/vehicleCertificates.api';
import { getEndpoint } from 'store/endpoint/endpoint.selectors';

import { getNormalizedJsonApi } from 'utils/jsonapi';

import { searchFail, searchStart, searchSuccess } from './vehicleCertificates.slice';
import {
  RiskCategory,
  Vehicle,
  VehicleCertificate,
  VehicleCertificateResponse,
} from './vehicleCertificates.types';

function* vehicleCertificatesSearchFlow({ payload }: ReturnType<typeof searchStart>) {
  try {
    const endpoint = yield select(getEndpoint, 'vehicleCertificates');
    const path = endpoint || `${env.API_BASE}/api/vehicle_certificates`;
    const resp = yield call(fetchVehicleCertificates, path, { q: payload.search });

    const { entities, result } = getNormalizedJsonApi<
      VehicleCertificateResponse,
      {
        vehicleCertificate: VehicleCertificate;
        vehicle: Vehicle;
        riskCategory: RiskCategory;
      }
    >(resp);

    yield put(
      searchSuccess({
        data: entities.vehicleCertificate,
        result: result.vehicleCertificate,
        vehiclesData: entities.vehicle,
        vehiclesResult: result.vehicle,
        riskCategories: entities.riskCategory,
      }),
    );
  } catch (error) {
    yield put(searchFail({ error }));
  }
}

function* vehicleCertificatesSaga() {
  yield takeLatest(searchStart, vehicleCertificatesSearchFlow);
}

export default vehicleCertificatesSaga;
