import React from 'react';

import { Toolbar, ToolbarProps } from '@material-ui/core';
import styled, { css } from 'styled-components';

import Popper from 'components/Popper';

type HeaderWrapProps = ToolbarProps & {
  $solid: boolean;
  $inverted: boolean;
};

export const HeaderWrap = styled(({ $solid, $inverted, ...rest }: HeaderWrapProps) => (
  <Toolbar {...rest} />
))`
  && {
    transition: background-color 0.3s;
    align-items: baseline;
    ${({ $solid, $inverted, theme }) => {
      if ($solid) {
        return `
        background-color: ${$inverted ? theme.palette.brand01[400] : theme.palette.neutral01[50]};
        box-shadow: ${theme.shadows[1]};
        `;
      }
    }}
  }
`;

export const HeaderRight = styled.div`
  margin-left: auto;
  padding-right: ${({ theme }) => theme.spacing(1)};
`;

export const invertedSVGStyle = css`
  color: ${({ theme }) => theme.palette.brand01[600]};
  stroke: ${({ theme }) => theme.palette.brand01[600]};
`;

export const ProfileButton = styled.button<{ solid: boolean; inverted: boolean }>`
  outline: 0;
  background: 0;
  border: 0;
  padding: 0;
  min-height: ${({ theme }) => theme.mixins.headerHeight}px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  margin-left: ${({ theme }) => theme.spacing(6)}px;
  cursor: pointer;
  & path {
    fill: ${({ theme, solid, inverted }) =>
      solid && inverted ? theme.palette.accent01[50] : theme.palette.brand01[400]};
    stroke: ${({ theme, solid, inverted }) =>
      solid && inverted ? theme.palette.accent01[50] : theme.palette.brand01[400]};
  }
`;

export const HeaderItems = styled.div`
  height: ${({ theme }) => theme.mixins.headerHeight}px;
  flex: 1 auto;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
`;

export const NavItemWrap = styled.div<{ isHidden?: boolean; $inverted: boolean }>`
  height: ${({ theme }) => theme.mixins.headerItemHeight}px;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  font-weight: 700;
  margin-right: ${({ theme }) => theme.spacing(4)}px;
  white-space: nowrap;
  transition: all 0.3s ease-out;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'inline-block')};
  cursor: pointer;
  ${({ $inverted, theme }) => ($inverted ? `color: ${theme.palette.neutral01[50]};` : '')}
  & > * {
    height: 100%;
    color: ${({ theme }) => theme.palette.neutral01[500]};
    text-decoration: none;
    padding-left: ${({ theme }) => theme.spacing(1)}px;
    padding-right: ${({ theme }) => theme.spacing(2)}px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid transparent;
    padding: ${({ theme }) => theme.spacing(3)}px;
    ${({ $inverted, theme }) => ($inverted ? `color: ${theme.palette.neutral01[50]};` : '')}
    & path {
      color: ${({ theme }) => theme.palette.brand01[600]};
      stroke: ${({ theme }) => theme.palette.brand01[600]};
      ${({ $inverted }) => ($inverted ? invertedSVGStyle : '')}
    }
    &.active {
      font-weight: 700;
      color: ${({ theme }) => theme.palette.brand01[300]};
      background-color: ${({ theme }) => theme.palette.neutral01[50]};
      box-shadow: 0px 4px 11px rgba(22, 65, 148, 0.08);
      border-radius: ${({ theme }) => theme.spacing(2)}px;
      & span svg path {
        color: ${({ theme }) => theme.palette.brand01[300]};
        stroke: ${({ theme }) => theme.palette.brand01[300]};
        &:hover {
          color: ${({ theme }) => theme.palette.brand01[500]};
          stroke: ${({ theme }) => theme.palette.brand01[500]};
        }
      }
    }
  }
  &:hover {
    div {
      color: ${({ theme }) => theme.palette.brand01[500]};
    }

    span svg path {
      color: ${({ theme }) => theme.palette.brand01[500]};
      stroke: ${({ theme }) => theme.palette.brand01[500]};
    }
  }
`;

export const PopperStyled = styled(Popper)<{ offset?: number }>`
  && {
    z-index: 99999;
    margin-top: -${({ theme }) => theme.spacing(2)}px;
    margin-right: ${({ offset, theme }) => theme.spacing(offset)}px;
    border-radius: ${({ theme }) => theme.spacing(2)}px;
    ${NavItemWrap} {
      display: block;
      margin: ${({ theme }) => theme.spacing(0, 2)};
      & > * {
        border: 0;
        &.active {
          border: 0;
        }
      }
    }
  }
`;

export const HeaderItemsInner = styled.div`
  display: inline-flex;
`;

export const MoreItem = styled(NavItemWrap)`
  position: relative;
  cursor: pointer;
  height: calc(100% - 3px);
  margin-right: 0;
  display: inline-flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0, 6)};
  & > span {
    padding: 0;
    height: auto;
  }
  &:hover {
    color: ${({ theme }) => theme.palette.brand01[500]};
  }
  &:before {
    position: absolute;
    width: 1px;
    background: ${({ theme }) => theme.palette.neutral01[400]};
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    content: '';
  }
`;
