import React from 'react';

import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogTitle,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import _get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/Icon';
import { UIcoSluiten } from 'components/Icons';

import { IconWrap } from './Modal.styled';

export interface ModalProps
  extends Pick<MuiDialogProps, 'disableEnforceFocus' | 'hideBackdrop' | 'fullScreen' | 'maxWidth'> {
  isOpen?: boolean;
  closeModal?: () => void;
  headerIntlKey?: string;
  withClose?: boolean;
  disableEnforceFocus?: boolean;
  hideBackdrop?: boolean;
  icon?: React.ReactNode;
  titleColor?: TypographyProps['color'];
  backdropColor?: string;
}

const Modal: React.FC<ModalProps> = ({
  icon,
  titleColor = 'secondary',
  isOpen = false,
  closeModal = () => {},
  headerIntlKey,
  withClose = true,
  backdropColor,
  children,
  ...rest
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MuiDialog
      BackdropProps={{
        style: backdropColor
          ? {
              backgroundColor: _get(theme.palette, backdropColor),
            }
          : {},
      }}
      open={isOpen}
      disableEnforceFocus={true}
      maxWidth="lg"
      fullScreen={fullScreen}
      onClose={withClose ? () => closeModal() : undefined}
      style={{
        bottom: fullScreen ? 56 : 0,
      }}
      {...rest}
    >
      <DialogTitle disableTypography>
        {icon}
        {headerIntlKey && (
          <Typography component="h1" variant="h2" color={titleColor}>
            <FormattedMessage id={headerIntlKey} />
          </Typography>
        )}
        {withClose && (
          <IconWrap hasUnderline={false} onClick={() => closeModal()}>
            <Icon size={24} color="brand01.200">
              <UIcoSluiten />
            </Icon>
          </IconWrap>
        )}
      </DialogTitle>

      {children}
    </MuiDialog>
  );
};

export default Modal;
