import React from 'react';

import { Box } from '@material-ui/core';

import { InputField } from 'components/@inputs/TextField';

export type ContactDataFormValues = {
  emailProfessional?: string;
  phoneMobile?: string;
  phoneLandline?: string;
};

const ContactDataForm: React.FC = () => (
  <>
    <Box marginBottom={8}>
      <InputField
        name="emailProfessional"
        label="affiliations.add.step.contact.email_professional"
        fullWidth
      />
    </Box>
    <Box mb={8}>
      <InputField
        name="phoneMobile"
        label="affiliations.add.step.contact.mobile_phone_label"
        fullWidth
      />
    </Box>
    <Box mb={8}>
      <InputField
        name="phoneLandline"
        label="affiliations.add.step.contact.phone_label"
        fullWidth
      />
    </Box>
  </>
);

export default ContactDataForm;
