import React, { Fragment, PureComponent } from 'react';

import { FormattedMessage } from 'react-intl';

import { Label } from 'components/@inputs';

import { BottomLabel, Input, InputAdornmentStyled } from './TextField.styled';
// TODO: deprecate component
export default class TextField extends PureComponent {
  static defaultProps = {
    label: '',
    bottomLabel: '',
    placeholder: '',
    disabled: false,
    hasError: false,
    shouldFitContainer: false,
    type: 'text',
    value: '',
    onChange: () => {
      // default prop
    },
    onFocus: () => {
      // default prop
    },
    onClick: () => {
      // default prop
    },
    onAdornmentClick: () => {
      // default prop
    },
    multiline: false,
    customRenderEndAdornment: false,
    customRenderStartAdornment: false,
    autoHeight: false,
  };

  renderAdornment = (adornment, position) => {
    const { onAdornmentClick } = this.props;
    return adornment ? (
      <InputAdornmentStyled
        onClick={() => onAdornmentClick(adornment, position)}
        position={position}
        component="span"
      >
        {adornment}
      </InputAdornmentStyled>
    ) : null;
  };

  render() {
    const {
      id,
      disabled,
      shouldFitContainer,
      startAdornment,
      endAdornment,
      label,
      bottomLabel,
      type,
      onAdornmentClick,
      onFocus,
      onClick,
      onChange,
      value,
      placeholder,
      hasError,
      multiline,
      withCharCount,
      maxChars,
      isOptional,
      withInputHighlight,
      customRenderStartAdornment,
      customRenderEndAdornment,
      hasValue,
      autoHeight,
      labelColor,
      inverted,
      ...rest
    } = this.props;
    const val = maxChars ? value.substring(0, maxChars) : value;

    const handleChange = event => {
      if (maxChars) {
        event.target.value = event.target.value.substring(0, maxChars);
      }
      onChange(event);
    };
    return (
      <Fragment>
        {label && (
          <Label
            hasError={hasError}
            hasValue={!!value}
            color={labelColor}
            htmlFor={id}
            isOptional={isOptional}
          >
            {label}
          </Label>
        )}
        <Input
          id={id}
          className="text-input-field"
          classes={{ focused: 'text-input-field--focused' }}
          disabled={disabled}
          disableUnderline
          multiline={multiline}
          placeholder={placeholder}
          value={val}
          type={type}
          shouldFitContainer={shouldFitContainer}
          onFocus={onFocus}
          onChange={handleChange}
          hasError={hasError}
          withInputHighlight={withInputHighlight}
          hasValue={hasValue}
          autoHeight={autoHeight}
          inverted={inverted}
          startAdornment={
            customRenderStartAdornment
              ? startAdornment
              : this.renderAdornment(startAdornment, 'start')
          }
          endAdornment={
            customRenderStartAdornment ? endAdornment : this.renderAdornment(endAdornment, 'end')
          }
          {...rest}
        />
        {withCharCount && maxChars && (
          <BottomLabel>
            {
              <FormattedMessage
                id="general.chararcters.count"
                values={{ length: val.length, max: maxChars }}
              />
            }
          </BottomLabel>
        )}
        {bottomLabel && <BottomLabel hasError={hasError}>{bottomLabel}</BottomLabel>}
      </Fragment>
    );
  }
}
