import React from 'react';

import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import Button from 'components/@common/Button';
import { H3 } from 'components/@typography/Titles';
import FileUploader from 'components/FileUploader';

type UploadCompleteFiles = {
  failedFiles: { [key: string]: File };
  uploadedFiles: { [key: string]: File };
};

interface Props {
  files: {
    [context: string]: File[] | undefined | null;
  };
  uploadEndpoint: string;
  onUploadComplete: (files: { [key: string]: File[] }) => void;
}

const FileUploadStep: React.FC<Props> = ({ onUploadComplete, uploadEndpoint, files }) => {
  const prepareFilesForSubmit = (files: UploadCompleteFiles['uploadedFiles']) =>
    Object.keys(files).reduce((acc: { [index: string]: File[] }, key) => {
      const context = key.split('.')[0];
      if (!acc[context]) acc[context] = [];
      acc[context] = [...acc[context], files[key]];
      return acc;
    }, {});

  const handleFileUploadComplete = (files: UploadCompleteFiles) => {
    const { uploadedFiles } = files;
    onUploadComplete(prepareFilesForSubmit(uploadedFiles));
  };

  const handleSubmitAnyWay = (uploadedFiles: UploadCompleteFiles['uploadedFiles']) => {
    onUploadComplete(prepareFilesForSubmit(uploadedFiles));
  };

  return (
    <Box padding={4}>
      <H3 marginBottom={24}>
        <FormattedMessage id="claims.add.step.upload_files.title" />
      </H3>
      <Box mb={6}>
        <FormattedMessage id="claim.add.step.upload_files.description" />
      </Box>
      <FileUploader
        onFileUploadComplete={handleFileUploadComplete}
        uploadPath={uploadEndpoint}
        groupFiles={files}
      >
        {({
          failedFiles,
          uploadedFiles,
          isCompleted,
        }: UploadCompleteFiles & { isCompleted: boolean }) => {
          return (
            Object.values(failedFiles).length > 0 &&
            isCompleted && (
              <Box mt={6}>
                <Button onClick={() => handleSubmitAnyWay(uploadedFiles)}>
                  <FormattedMessage id="affiliations.add.step.upload.submit_anyway" />
                </Button>
              </Box>
            )
          );
        }}
      </FileUploader>
    </Box>
  );
};

export default FileUploadStep;
