import { DEFAULT_PARAMS } from 'constants/default.queryparams';

export const QUERY_PARAMS = {
  DIRECTION: 'direction',
  DOMAINS: 'domains',
  ONLY_MY_FILES: 'onlyMyFiles',
  CATEGORY_IDS: 'categoryIds',
  DATE_MIN: 'dateMin',
  DATE_MAX: 'dateMax',
  FILE_NAME: 'fileName',
  FILE_TYPE: 'fileType',
  SENDER: 'sender',
  RECIPIENT: 'recipient',
  FILE_SIZE: 'fileSize',
  DOMAIN: 'domain',
  TRANSFER_TIME: 'transferTime',
  ...DEFAULT_PARAMS,
};

export const DOMAINS = ['GENERAL', 'BOAR', 'EB', 'HC'];
