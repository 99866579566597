import dayjs from 'dayjs';
import * as Yup from 'yup';

import { AffiliationPolicy } from 'store/affiliationSubmission/affiliationSubmission.types';

const minAffiliationStartDateMessageId = 'affiliations.add.stepp.affiliation.errors.min_start_date';

const AffiliationFormValidation = (birthDate: string, isMainInsurant: boolean = false) => {
  return Yup.object().shape({
    affiliations: Yup.object()
      .test('', 'general.errors.required', (value = {}) => {
        return Object.values(value).length > 0;
      })
      .required('general.errors.required'),

    startDate: Yup.date().when(['affiliations'], function(
      affiliationsObject: { [id: string]: AffiliationPolicy } = {},
    ) {
      const affiliations = Object.values(affiliationsObject);
      const mostRecentAffiliationMinDate = affiliations
        .filter(affiliation => !!affiliation?.attributes?.minAffiliationStartDate)
        .sort((a, b) =>
          dayjs(a?.attributes?.minAffiliationStartDate).isAfter(
            dayjs(b?.attributes?.minAffiliationStartDate),
          )
            ? -1
            : 1,
        )[0]?.attributes?.minAffiliationStartDate;

      const dateToValidate = mostRecentAffiliationMinDate ?? birthDate;
      if (!dateToValidate) {
        return Yup.date()
          .typeError('general.errors.required')
          .required('general.errors.required');
      }

      return Yup.date()
        .typeError('general.errors.required')
        .required('general.errors.required')
        .min(dayjs(dateToValidate).toDate(), params => ({
          level: mostRecentAffiliationMinDate && isMainInsurant ? 'error' : 'warning',
          id: mostRecentAffiliationMinDate
            ? minAffiliationStartDateMessageId
            : 'general.errors.date.min',
          values: {
            ...params,
            date: dayjs(new Date(params.min)).format('DD/MM/YYYY'),
          },
        }));
    }),
    endDate: Yup.string().when(['startDate'], (date: string) => {
      const dateChecked = dayjs(date).isValid() ? dayjs(date).toDate() : undefined;
      return Yup.date()
        .nullable()
        .typeError('general.errors.date.invalid')
        .min(dayjs(dateChecked).toDate(), params => ({
          id: 'general.errors.date.min',
          values: {
            ...params,
            date: dayjs(new Date(params.min)).format('DD/MM/YYYY'),
          },
        }));
    }),
    reason: Yup.object().when('endDate', {
      is: val => !!val,
      then: Yup.object().required('general.errors.required'),
    }),
    isBelated: Yup.boolean(),
    belatedDate: Yup.date().when(['isBelated', 'startDate'], (isBelated: string, date: string) => {
      if (isBelated) {
        const dateChecked = dayjs(date).isValid() ? dayjs(date).toDate() : undefined;
        return Yup.date()
          .typeError('general.errors.date.invalid')
          .required('general.errors.required')
          .max(
            dayjs(dateChecked)
              .subtract(1, 'day')
              .toDate(),
            params => ({
              id: 'general.errors.date.max',
              values: {
                ...params,
                date: dayjs(new Date(params.max)).format('DD/MM/YYYY'),
              },
            }),
          );
      }
      return Yup.date().nullable();
    }),
  });
};

export { AffiliationFormValidation, minAffiliationStartDateMessageId };
