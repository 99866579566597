import { createSelector } from '@reduxjs/toolkit';

import { Store } from 'types/store/store.types';

import { insurantsAdapter } from './affiliations.slice';
import { InsurantData } from './affiliations.types';

export const insurantsSelector = insurantsAdapter.getSelectors<Store>(state => state.affiliations);
export const getInsurantsState = (state: Store) => state.affiliations;
export const getInsurentsResult = (state: Store) => state.affiliations.result;
export const getInsurants = createSelector(
  [getInsurentsResult, (state: Store) => state],
  (results, state) =>
    results.reduce((acc: InsurantData[], id) => {
      const insurant = insurantsSelector.selectById(state, id);
      if (insurant) {
        acc.push(insurant);
      }
      return acc;
    }, []),
);
export const getInsurantsMeta = (state: Store) => state.affiliations.meta;
export const getInsurantsIsLoading = (state: Store) => state.affiliations.status === 'loading';
export const getInsurantsError = (state: Store) => state.affiliations.error;
