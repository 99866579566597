import { createAction } from 'redux-act';

export const claimSubmissionTilesFetchStart = createAction('CLAIM_SUBMISSION_TILES_FETCH_START');
export const claimSubmissionTilesFetchSuccess = createAction(
  'CLAIM_SUBMISSION_TILES_FETCH_SUCCESS',
);
export const claimSubmissionTilesFetchFail = createAction('CLAIM_SUBMISSION_TILES_FETCH_FAIL');
export const reportingCategoriesFetchSuccess = createAction('REPORTING_CATEGORIES_FETCH_SUCCESS');
export const damageCategoriesFetchSuccess = createAction('DAMAGE_CATEGORIES_FETCH_SUCCESS');
export const claimSubmissionUpdateStart = createAction('UPDATE_CLAIM_SUBMISSION_START');
export const claimSubmissionUpdateSuccess = createAction('UPDATE_CLAIM_SUBMISSION_SUCCESS');
export const claimSubmissionUpdateFail = createAction('UPDATE_CLAIM_SUBMISSION_FAIL');
export const claimSubmissionPartnersFetchStart = createAction(
  'CLAIM_SUBMISSION_PARTNERS_FETCH_START',
);
export const claimSubmissionPartnersFetchSuccess = createAction(
  'CLAIM_SUBMISSION_PARTNERS_FETCH_SUCCESS',
);
export const claimSubmissionPartnersFetchFail = createAction(
  'CLAIM_SUBMISSION_PARTNERS_FETCH_FAIL',
);
export const claimSubmissionPoliciesFetchStart = createAction(
  'CLAIM_SUBMISSION_POLICIES_FETCH_START',
);
export const claimSubmissionPoliciesFetchSuccess = createAction(
  'CLAIM_SUBMISSION_POLICIES_FETCH_SUCCESS',
);
export const claimSubmissionPoliciesFetchFail = createAction(
  'CLAIM_SUBMISSION_POLICIES_FETCH_FAIL',
);

export const claimSubmissionSetSelectedPolicy = createAction(
  'CLAIM_SUBMISSION_SET_SELECTED_POLICY',
);

export const claimSubmissionCreateStart = createAction('CLAIM_SUBMISSION_CREATE_START');
export const claimSubmissionCreateSuccess = createAction('CLAIM_SUBMISSION_CREATE_SUCCESS');
export const claimSubmissionCreateFail = createAction('CLAIM_SUBMISSION_CREATE_FAIL');
export const goToStep = createAction('CLAIM_SUBMISSION_GO_TO_STEP');
export const setNewStep = createAction('CLAIM_SUBMISSION_SET_NEW_STEP');
export const resetStepper = createAction('CLAIM_SUBMISSION_RESET_STEPPER');
export const goToNextStep = createAction('CLAIM_SUBMISSION_GO_TO_NEXT_STEP');
export const disableStep = createAction('CLAIM_SUBMISSION_DISABLE_STEP');
export const resetDisabledSteps = createAction('CLAIM_SUBMISSION_RESET_DISABLED_STEPS');

export const claimSubmissionEmployeesFetchStart = createAction(
  'CLAIM_SUBMISSION_EMPLOYEES_FETCH_START',
);
export const claimSubmissionEmployeesFetchSuccess = createAction(
  'CLAIM_SUBMISSION_EMPLOYEES_FETCH_SUCCESS',
);
export const claimSubmissionEmployeesFetchFail = createAction(
  'CLAIM_SUBMISSION_EMPLOYEES_FETCH_FAIL',
);

export const claimSubmissionEmploymentFetchStart = createAction(
  'CLAIM_SUBMISSION_EMPLOYMENT_FETCH_START',
);
export const claimSubmissionEmploymentFetchSuccess = createAction(
  'CLAIM_SUBMISSION_EMPLOYMENT_FETCH_SUCCESS',
);
export const claimSubmissionEmploymentFetchFail = createAction(
  'CLAIM_SUBMISSION_EMPLOYMENT_FETCH_FAIL',
);

export const claimSubmissionEmployeesReset = createAction('CLAIM_SUBMISSION_EMPLOYEES_RESET');
// (-- APPEND ACTIONS HERE --) !!! do not move this comment !!!
