import { createSelector } from '@reduxjs/toolkit';

import { getCategoriesForEntity } from 'store/categories/categories.selectors';
import { Store } from 'types/store/store.types';

import { Document } from './document.types';

export const getDocumentsIsLoading = (state: Store) => state.document.isLoading;
export const getDocumentsError = (state: Store) => state.document.error;
export const getDocumentIsDownloading = (state: Store) => state.document.isDownloading;
export const getDocumentEndpoint = (state: Store) =>
  state.endpoints.private?.data?.relationships?.documents?.links?.self;

export const getResults = (state: Store) => state.document.result;
export const getRecentResults = (state: Store) => state.document.recentResult;
export const getDocumentById = (state: Store, id: string) => state.document.data?.[id];
export const getDocumentsMeta = (state: Store) => state.document.meta;
export const getDocumentCategories = (state: Store) =>
  getCategoriesForEntity(state, 'documentCategory');
export const getDocuments = createSelector(
  [getResults, (state: Store) => state],
  (results, state) =>
    results.reduce((acc: Document[], id) => {
      const document = getDocumentById(state, id);
      if (document) {
        acc.push(document);
      }
      return acc;
    }, []),
);

export const getRecentDocuments = createSelector(
  [getRecentResults, (state: Store) => state],
  (results, state) =>
    results.reduce((acc: Document[], id) => {
      const document = getDocumentById(state, id);
      if (document) {
        acc.push(document);
      }
      return acc;
    }, []),
);

export const getDocumentsDownloading = (state: Store) => state.document.documentsDownloading;
