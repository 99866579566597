import React, { Fragment } from 'react';

import { InputLabelProps } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { InputLabelstyled, LabelWrap } from './Label.styled';

export interface Props extends InputLabelProps {
  hasValue?: boolean;
  hasError?: boolean;
  isOptional?: boolean;
  whiteSpace?: 'nowrap' | 'initial';
  isBold?: boolean;
}

const Label: React.FC<Props> = ({
  hasValue = false,
  hasError = false,
  whiteSpace = 'initial',
  isOptional,
  children,
  ...rest
}) => {
  return (
    <LabelWrap>
      <InputLabelstyled whiteSpace={whiteSpace} hasError={hasError} hasValue={hasValue} {...rest}>
        {children}
        {isOptional && (
          <Fragment>
            &nbsp;
            <FormattedMessage id="general.label.optional" />
          </Fragment>
        )}
      </InputLabelstyled>
    </LabelWrap>
  );
};

export default Label;
