import React, { useEffect, useRef, useState } from 'react';

import { Hidden } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import Stepper from 'components/@common/Stepper';
import { LoadingPlaceholder } from 'components/Placeholders';

import { FlowType, FlowTypeConfig } from '../AffiliationsCreate';
import AffiliationsCreateContext from '../AffiliationsCreateContext';
import { STEP_IDS } from '../steps.constants';
import { ContainerProps } from './AffiliationsCreateStepperContainer';
import { initialFormState } from './initialFormState';
import {
  AdditionalInfoFormValues,
  AdditionalInfoStep,
  AddressFormValues,
  AddressStep,
  AffiliationFormValues,
  AffiliationStep,
  BaseDataFormValues,
  BaseDataStep,
  CompanyFormValues,
  CompanyStep,
  ContactFormValues,
  ContactStep,
  DocumentsFormValues,
  DocumentsStep,
  FileUploadStep,
  SummaryStep,
} from './Steps';

export type StepperState = {
  stepCompany: CompanyFormValues;
  stepBaseData: BaseDataFormValues;
  stepAddress: AddressFormValues;
  stepContact: ContactFormValues;
  stepAffiliation: AffiliationFormValues;
  stepDocuments: DocumentsFormValues;
  stepAdditionalInfo: AdditionalInfoFormValues;
};

type Props = {
  onSubmit: (values: StepperState) => void;
  values?: StepperState;
  flowType: FlowType;
  flowTypeConfig: FlowTypeConfig;
};

const AffiliationsCreateStepper: React.FC<Omit<
  ContainerProps,
  'updateInsurant' | 'restartRequest'
> &
  Props> = ({
  fetchCompanies,
  locale,
  onSubmit,
  data,
  flowTypeConfig,
  resetFileUpload,
  values = {},
  flowType,
}) => {
  const {
    STEP_COMPANY,
    STEP_BASE_DATA,
    STEP_ADDRESS,
    STEP_CONTACT,
    STEP_AFFILIATION,
    STEP_DOCUMENTS,
    STEP_SUMMARY,
    STEP_ADDITIONAL_INFO,
    STEP_FILE_UPLOAD,
  } = STEP_IDS;

  useEffect(() => {
    resetFileUpload();
    if (flowType === 'MAIN') {
      fetchCompanies();
    }
  }, [fetchCompanies, flowType, resetFileUpload]);

  const initialHidden = flowTypeConfig[flowType].initialHiddenSteps;

  const currentStepIds = Object.values(STEP_IDS).filter(value => !initialHidden.includes(value));

  const inititalStep = Object.values(currentStepIds)[0];
  const initialValues = useRef({ ...initialFormState(locale), ...values });
  const [formValues, setFormValues] = useState<StepperState>(initialValues.current);
  const [activeStep, setActiveStep] = useState(inititalStep);
  const [completedSteps, setCompletedSteps] = useState<string[]>([]);
  const [hiddenSteps, setHiddenSteps] = useState<string[]>(initialHidden);

  const setForm = <T extends {}>(key: keyof StepperState, values?: T) => {
    const newForm = {
      ...formValues,
      [key]: values,
    };
    if (key === 'stepCompany') {
      newForm.stepBaseData.reportingCategories = [];
    }
    setFormValues(newForm);
    return newForm;
  };

  const checkAdditionalInfoNeeded = (affiliations: AffiliationFormValues['affiliations']) =>
    Object.values(affiliations || {}).reduce(
      (
        acc: Pick<
          AdditionalInfoFormValues,
          | 'requiresIdentificationNumber'
          | 'requiresSalaryData'
          | 'employmentConditions'
          | 'wageType'
        >,
        affiliation,
      ) => {
        if (affiliation.attributes.requiresIdentificationNumber) {
          acc.requiresIdentificationNumber = true;
        }
        if (affiliation.attributes.requiresSalaryData) {
          acc.requiresSalaryData = true;
          acc.employmentConditions = affiliation.attributes.employmentConditions;
          acc.wageType = affiliation.attributes.wageType;
        }
        return acc;
      },
      {
        requiresIdentificationNumber: false,
        requiresSalaryData: false,
      },
    );
  const getNextSteps = (step: string) => {
    const arrSteps = Object.values(STEP_IDS);
    const index = Object.values(arrSteps).indexOf(step);
    return arrSteps.slice(0, index);
  };

  const handleAffiliationNextStepClick = (values: AffiliationFormValues) => {
    let nextStep = STEP_ADDITIONAL_INFO;
    const needsAdditionalInfo = checkAdditionalInfoNeeded(values.affiliations);
    if (
      !needsAdditionalInfo.requiresIdentificationNumber &&
      !needsAdditionalInfo.requiresSalaryData
    ) {
      nextStep = STEP_DOCUMENTS;
      if (!hiddenSteps.includes(STEP_ADDITIONAL_INFO)) {
        setHiddenSteps(Array.from(new Set([...hiddenSteps, STEP_ADDITIONAL_INFO])));
      }
    } else if (hiddenSteps.includes(STEP_ADDITIONAL_INFO)) {
      setHiddenSteps(initialHidden.filter(step => step !== STEP_ADDITIONAL_INFO));
    }
    setFormValues({
      ...formValues,
      stepAffiliation: values,
      stepAdditionalInfo: {
        ...formValues.stepAdditionalInfo,
        ...needsAdditionalInfo,
      },
    });

    setCompletedSteps(getNextSteps(nextStep));
    setActiveStep(nextStep);
  };

  const handleNextStepClick = <T extends {}>(nextStep: string) => (values: T) => {
    setForm(activeStep as keyof StepperState, values);
    if (nextStep === STEP_SUMMARY) {
      setHiddenSteps(Array.from(new Set([...hiddenSteps, ...getNextSteps(nextStep)])));
    }
    setCompletedSteps(getNextSteps(nextStep));
    setActiveStep(nextStep);
  };

  const handleEditClick = (step: string, resetHiddenSteps: boolean = false) => {
    const newCompleted = getNextSteps(step);
    if (resetHiddenSteps) {
      setHiddenSteps(
        hiddenSteps.includes(STEP_ADDITIONAL_INFO)
          ? initialHidden.filter(hide => hide !== STEP_ADDITIONAL_INFO)
          : initialHidden,
      );
    }
    setCompletedSteps(newCompleted);
    setActiveStep(step);
  };

  if (activeStep === STEP_FILE_UPLOAD) {
    return (
      <FileUploadStep
        onUploadComplete={() => onSubmit(formValues)}
        files={{
          [STEP_DOCUMENTS]: formValues.stepDocuments.files,
        }}
        uploadEndpoint={`${data?.links?.files}`}
      />
    );
  }

  return (
    <Stepper
      activeStep={activeStep}
      completedSteps={completedSteps}
      hideSteps={hiddenSteps}
      onEditClick={handleEditClick}
      topContent={
        <Hidden lgUp>
          <AffiliationsCreateContext
            canCollapse
            title={flowTypeConfig[flowType].contextTitle ?? ''}
          />
        </Hidden>
      }
      mainTitle={<FormattedMessage id={flowTypeConfig[flowType].title} />}
      steps={[
        {
          id: STEP_COMPANY,
          value: (
            <>
              {[
                formValues.stepCompany?.client?.attributes?.name,
                formValues.stepCompany?.client?.attributes?.clientNumber,
              ]
                .filter(Boolean)
                .join(' | ')}
            </>
          ),
          label: <FormattedMessage id="affiliations.add.step.company.title" />,
          content: (
            <CompanyStep
              saveForm={values => {
                setForm('stepCompany', values);
              }}
              onNextStepClick={handleNextStepClick(STEP_BASE_DATA)}
              initialValues={formValues.stepCompany}
            />
          ),
        },
        {
          id: STEP_BASE_DATA,
          value: activeStep !== STEP_COMPANY && (
            <>
              {[
                [formValues.stepBaseData?.firstName, formValues.stepBaseData?.lastName]
                  .filter(Boolean)
                  .join(' '),
                formValues.stepBaseData?.personnelNumber,
              ]
                .filter(Boolean)
                .join(' | ')}
            </>
          ),
          label: <FormattedMessage id="affiliations.add.step.base_data.title" />,
          content: data ? (
            <BaseDataStep
              onNextStepClick={handleNextStepClick(
                flowType === 'MAIN' ? STEP_ADDRESS : STEP_AFFILIATION,
              )}
              data={data}
              initialValues={formValues.stepBaseData}
              flowType={flowType}
              saveForm={values => {
                setForm('stepBaseData', values);
              }}
            />
          ) : (
            <LoadingPlaceholder />
          ),
        },
        {
          id: STEP_ADDRESS,
          label: <FormattedMessage id="affiliations.add.step.address.title" />,
          content: (
            <AddressStep
              onNextStepClick={handleNextStepClick(STEP_CONTACT)}
              saveForm={values => {
                setForm('stepAddress', values);
              }}
              initialValues={formValues.stepAddress}
            />
          ),
        },
        {
          id: STEP_CONTACT,
          label: <FormattedMessage id="affiliations.add.step.contact.title" />,
          content: (
            <ContactStep
              onNextStepClick={handleNextStepClick(STEP_AFFILIATION)}
              saveForm={values => {
                setForm('stepContact', values);
              }}
              initialValues={formValues.stepContact}
            />
          ),
        },
        {
          id: STEP_AFFILIATION,
          label: <FormattedMessage id="affiliations.add.step.affiliation.title" />,
          content: (
            <AffiliationStep
              onNextStepClick={handleAffiliationNextStepClick}
              saveForm={values => {
                setForm('stepAffiliation', values);
              }}
              birthDate={(formValues.stepBaseData?.birthDate || data?.attributes?.birthDate) ?? ''}
              initialValues={formValues.stepAffiliation}
              flowType={flowType}
              isMainInsurant={!data?.relationships?.mainInsurant?.data?.id}
            />
          ),
        },
        {
          id: STEP_ADDITIONAL_INFO,
          label: <FormattedMessage id="affiliations.add.step.additional_info.title" />,
          content: (
            <AdditionalInfoStep
              onNextStepClick={handleNextStepClick(STEP_DOCUMENTS)}
              saveForm={values => {
                setForm('stepAdditionalInfo', values);
              }}
              initialValues={formValues.stepAdditionalInfo}
            />
          ),
        },
        {
          id: STEP_DOCUMENTS,
          label: <FormattedMessage id="affiliations.add.step.documents.title" />,
          content: (
            <DocumentsStep
              saveForm={values => {
                setForm('stepDocuments', values);
              }}
              onNextStepClick={handleNextStepClick(STEP_SUMMARY)}
              initialValues={formValues.stepDocuments}
            />
          ),
        },
        {
          id: STEP_SUMMARY,
          label: <FormattedMessage id="affiliations.add.step.summary.title" />,
          content: (
            <SummaryStep
              flowType={flowType}
              formValues={formValues}
              onEditClick={step => handleEditClick(step, true)}
              onNextStepClick={() => handleNextStepClick(STEP_FILE_UPLOAD)({})}
              submitButtonText={flowTypeConfig[flowType].submitButtonText}
            />
          ),
        },
      ]}
    />
  );
};
export default AffiliationsCreateStepper;
