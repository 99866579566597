import { createReducer } from 'redux-act';

import { STEP_IDS } from 'constants/claimSubmission';

import {
  claimSubmissionCreateFail,
  claimSubmissionCreateStart,
  claimSubmissionCreateSuccess,
  claimSubmissionEmployeesFetchFail,
  claimSubmissionEmployeesFetchStart,
  claimSubmissionEmployeesFetchSuccess,
  claimSubmissionEmployeesReset,
  claimSubmissionEmploymentFetchFail,
  claimSubmissionEmploymentFetchStart,
  claimSubmissionEmploymentFetchSuccess,
  claimSubmissionPartnersFetchFail,
  claimSubmissionPartnersFetchStart,
  claimSubmissionPartnersFetchSuccess,
  claimSubmissionPoliciesFetchFail,
  claimSubmissionPoliciesFetchStart,
  claimSubmissionPoliciesFetchSuccess,
  claimSubmissionSetSelectedPolicy,
  claimSubmissionTilesFetchFail,
  claimSubmissionTilesFetchStart,
  claimSubmissionTilesFetchSuccess,
  claimSubmissionUpdateFail,
  claimSubmissionUpdateStart,
  claimSubmissionUpdateSuccess,
  damageCategoriesFetchSuccess,
  disableStep,
  goToNextStep,
  goToStep,
  reportingCategoriesFetchSuccess,
  resetDisabledSteps,
  resetStepper,
  setNewStep,
  // (-- APPEND ACTION IMPORT MAPPING HERE --)s
} from './claimSubmission.actions';

const initialStep = STEP_IDS.STEP_RISK;

export const INITIAL_STATE = {
  isUpdating: false,
  updateError: null,
  submission: null,
  isCreating: false,
  summary: {
    risk: '',
    riskSubtype: '',
    partner: '',
    policy: '',
    type: '',
  },
  tiles: {
    data: null,
    error: null,
    isLoading: false,
  },
  reportingCategories: {
    data: null,
  },
  damageCategories: {
    data: undefined,
  },
  partners: {
    data: null,
    error: null,
    isLoading: false,
  },
  policies: {
    data: null,
    error: null,
    isLoading: false,
    meta: null,
    selected: null,
  },
  employees: {
    endpoint: null,
    data: null,
    isLoading: false,
    error: null,
    meta: null,
  },
  employment: {
    endpoint: null,
    data: null,
    isLoading: false,
    error: null,
  },
  stepper: {
    currentStep: initialStep,
    completedSteps: [],
    disabledSteps: [],
    flowCompleted: false,
    flowStart: null,
  },
};

const REDUCERS = {
  // TILES (DRILLDOWN)
  [claimSubmissionTilesFetchStart]: state =>
    Object.assign({}, state, {
      tiles: {
        ...state.tiles,
        isLoading: true,
        error: null,
      },
    }),
  [claimSubmissionTilesFetchSuccess]: (state, { data }) =>
    Object.assign({}, state, {
      tiles: {
        ...state.tiles,
        isLoading: false,
        error: null,
        data,
      },
    }),
  [claimSubmissionTilesFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      tiles: {
        ...state.tiles,
        isLoading: false,
        error: error || 'unknown error',
      },
    }),
  // PARTNER FETCH (DRILLDOWN)
  [claimSubmissionPartnersFetchStart]: state =>
    Object.assign({}, state, {
      partners: {
        ...state.partners,
        isLoading: true,
        error: null,
      },
    }),
  [claimSubmissionPartnersFetchSuccess]: (state, { data }) =>
    Object.assign({}, state, {
      partners: {
        isLoading: false,
        error: null,
        data,
      },
    }),
  [claimSubmissionPartnersFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      partners: {
        ...state.partners,
        isLoading: false,
        error: error || 'unknown error',
      },
    }),
  // POLICIES FETCH (DRILLDOWN)
  [claimSubmissionPoliciesFetchStart]: state =>
    Object.assign({}, state, {
      policies: {
        ...state.policies,
        isLoading: true,
        error: null,
        meta: null,
        selected: null,
      },
    }),
  [claimSubmissionPoliciesFetchSuccess]: (state, { data, meta }) =>
    Object.assign({}, state, {
      policies: {
        ...state.policies,
        isLoading: false,
        error: null,
        data,
        meta,
      },
    }),
  [claimSubmissionPoliciesFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      policies: {
        ...state.policies,
        isLoading: false,
        error: error || 'unknown error',
        meta: null,
      },
    }),
  [claimSubmissionSetSelectedPolicy]: (state, { policy }) =>
    Object.assign({}, state, {
      policies: {
        ...state.policies,
        selected: policy,
      },
    }),
  // CLAIM CREATE
  [claimSubmissionCreateStart]: state =>
    Object.assign({}, state, {
      isCreating: true,
      updateError: null,
    }),
  [claimSubmissionCreateSuccess]: (state, { data, summary, employeePath }) =>
    Object.assign({}, state, {
      isCreating: false,
      updateError: null,
      submission: { ...data },
      summary,
      employees: {
        ...state.employees,
        endpoint: employeePath,
      },
    }),
  [claimSubmissionCreateFail]: (state, { error }) =>
    Object.assign({}, state, {
      isCreating: false,
      updateError: error || 'unknown error',
      submission: null,
    }),
  // REPORTING CATEOGRIES (MAIN-FLOW)
  [reportingCategoriesFetchSuccess]: (state, { data }) =>
    Object.assign({}, state, {
      reportingCategories: {
        data,
      },
    }),
  // DAMAGE CATEOGRIES (MAIN-FLOW - BUILDINGS)
  [damageCategoriesFetchSuccess]: (state, { data }) =>
    Object.assign({}, state, {
      damageCategories: {
        data,
      },
    }),
  // CLAIM UPDATE
  [claimSubmissionUpdateStart]: state =>
    Object.assign({}, state, {
      isUpdating: true,
      updateError: null,
    }),
  [claimSubmissionUpdateSuccess]: (state, { isEndOfFlow }) =>
    Object.assign({}, state, {
      isUpdating: false,
      updateError: null,
      stepper: {
        ...state.stepper,
        flowCompleted: isEndOfFlow,
      },
    }),
  [claimSubmissionUpdateFail]: (state, { error }) =>
    Object.assign({}, state, {
      isUpdating: false,
      updateError: error || 'unknown error',
    }),
  // EMPLOYEES FETCH (TPGI MAIN FLOW)
  [claimSubmissionEmployeesFetchStart]: state =>
    Object.assign({}, state, {
      employees: {
        ...state.employees,
        isLoading: true,
        error: null,
        data: null,
      },
    }),
  [claimSubmissionEmployeesFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      employees: {
        ...state.employees,
        isLoading: false,
        error: error || 'unknown error',
      },
    }),
  [claimSubmissionEmployeesFetchSuccess]: (state, { data, meta }) =>
    Object.assign({}, state, {
      employees: {
        ...state.employees,
        data,
        meta,
        isLoading: false,
        error: null,
      },
    }),
  [claimSubmissionEmployeesReset]: state =>
    Object.assign({}, state, {
      employees: {
        ...INITIAL_STATE.employees,
        endpoint: state.employees.endpoint,
      },
    }),
  // EMPLOYMENT FETCH (TPGI MAIN FLOW)
  [claimSubmissionEmploymentFetchStart]: state =>
    Object.assign({}, state, {
      employment: {
        ...state.employment,
        isLoading: true,
        error: null,
        data: null,
      },
    }),
  [claimSubmissionEmploymentFetchFail]: (state, { error }) =>
    Object.assign({}, state, {
      employment: {
        ...state.employment,
        isLoading: false,
        error: error || 'unknown error',
      },
    }),
  [claimSubmissionEmploymentFetchSuccess]: (state, { data, infoDate }) =>
    Object.assign({}, state, {
      employment: {
        ...state.employment,
        data: { ...data, infoDate },
        isLoading: false,
        error: null,
      },
    }),
  // STEPPER GENERAL STATE
  [goToStep]: state => Object.assign({}, state, {}),
  [setNewStep]: (state, { stepId, newCompleted }) =>
    Object.assign({}, state, {
      stepper: {
        ...state.stepper,
        currentStep: stepId,
        completedSteps: newCompleted,
      },
    }),
  [resetStepper]: state =>
    Object.assign({}, state, {
      ...INITIAL_STATE,
      tiles: {
        ...state.tiles,
      },
      partners: {
        ...state.partners,
      },
      policies: {
        ...state.policies,
      },
      stepper: {
        ...INITIAL_STATE.stepper,
        flowStart: new Date().getTime(),
      },
    }),
  [goToNextStep]: state => Object.assign({}, state, {}),
  [disableStep]: (state, { stepId }) => {
    let newDisabled = [];
    if (state.stepper.disabledSteps.indexOf(stepId) === -1) {
      newDisabled = [stepId];
    }
    return Object.assign({}, state, {
      stepper: {
        ...state.stepper,
        disabledSteps: [...state.stepper.disabledSteps, ...newDisabled],
      },
    });
  },
  [resetDisabledSteps]: state =>
    Object.assign({}, state, {
      stepper: {
        ...state.stepper,
        disabledSteps: [],
      },
    }),
  // (-- APPEND REDUCER ACTION MAPPING HERE --) !!! do not move this comment !!!
};

export default createReducer(REDUCERS, INITIAL_STATE);
