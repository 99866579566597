import React from 'react';

import { connect } from 'react-redux';

import { getUserAbilities } from 'store/user/user.selectors';
import { Store } from 'types/store/store.types';

import Can, { Props } from './Can';

const mapStateToProps = (state: Store) => ({
  userAbilities: getUserAbilities(state),
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export type ContainerProps = StateProps & DispatchProps & { children: React.ReactNode };

const CanContainer: React.FC<ContainerProps & Props> = props => {
  return <Can {...props} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(CanContainer);
